const columnsProduct = [
  {
    title: 'STT',
    key: 'number',
    align: 'center',
    width: 50,
    fixed: 'left',
  },
  {
    title: 'Tên sản phẩm',
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    width: 250,
  },
  {
    title: 'Mã sản phẩm',
    dataIndex: 'code',
    fixed: 'left',
  },
  {
    title: 'Mã ERP',
    dataIndex: 'ecommerce_barcode',
  },
  {
    title: 'QR code',
    key: 'qr_code',
  },
  {
    title: 'Nhóm hàng',
    key: 'group',
  },
  {
    title: 'Loại hàng',
    key: 'type',
  },
  {
    title: 'Xuất xứ',
    dataIndex: 'origin',
  },
  {
    title: 'Mã hàng/Barcode',
    dataIndex: 'barcode',
  },
  {
    title: 'Hình ảnh',
    key: 'image',
  },
  {
    title: 'Số lượng có thể xuất',
    key: 'available_quantity',
    align: 'center',
    width: 100,
  },
  {
    title: 'Số lượng hiện tại',
    key: 'current_quantity',
    align: 'center',
    width: 100,
  },
  {
    title: 'Giá tiền',
    dataIndex: 'price',
    key: 'price',
    align: 'center',
  },
  {
    title: 'Cbm (cm³)',
    dataIndex: 'cbm',
    key: 'cbm',
    align: 'center',
  },
  {
    title: 'D.Tích mặt phẳng (cm²)',
    dataIndex: 'area',
    key: 'area',
    align: 'center',
  },
  {
    title: 'Quy cách',
    dataIndex: 'convention',
  },
  {
    title: 'Đơn vị tính',
    key: 'unit',
  },
  {
    title: 'Đơn vị nhỏ nhất',
    key: 'smallest_unit',
  },
  {
    title: 'Kho',
    key: 'warehouse',
  },
  {
    title: 'Hạn sử dụng',
    key: 'exp',
  },
  {
    title: 'Ngày sản xuất',
    key: 'mfg',
  },
  {
    title: 'Doanh nghiệp',
    key: 'customer',
  },
  {
    title: 'Khả dụng/không khả dụng',
    key: 'available',
  },
  {
    title: '',
    key: 'action',
  },
]

export default columnsProduct
