import React from 'react'
import { Button } from 'antd'
import { VerticalAlignTopOutlined } from '@ant-design/icons'
import { _addLog } from 'utils'
import { ACTION } from 'consts'

import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

import { useDispatch } from 'react-redux'

import style from './export-csv.module.scss'

//apis
import { saveActionFile } from 'apis/action'
import { uploadFileTypeBlob } from 'apis/upload'

export default function Export({
  text = 'Xuất excel',
  exportData,
  fileName = '',
  actionName = 'Xuất file excel',
}) {
  const dispatch = useDispatch()

  const FILE_TYPE =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const FILE_EXTENSION = '.xlsx'

  const exportToCSV = async (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: FILE_TYPE })

    //lưu action export excel
    const url = await uploadFileTypeBlob(data, fileName + FILE_EXTENSION)
    const body = {
      type: 'Export File',
      action_name: actionName,
      file_name: fileName + FILE_EXTENSION,
      links: [url],
    }
    await saveActionFile(body)

    FileSaver.saveAs(data, fileName + FILE_EXTENSION)
  }

  return (
    <Button
      onClick={async () => {
        try {
          dispatch({ type: ACTION.LOADING, data: true })
          const data = await exportData()
          await exportToCSV(data, fileName)
          dispatch({ type: ACTION.LOADING, data: false })
        } catch (error) {
          console.log(error)
          _addLog(JSON.stringify(error))
          dispatch({ type: ACTION.LOADING, data: false })
        }
      }}
      icon={<VerticalAlignTopOutlined />}
      type="primary"
      className={style['btn']}
    >
      {text}
    </Button>
  )
}
