import React from 'react'
import QR from 'qrcode-base64'

const PrintQRCodeBucket = ({ record }) => {
  var imgData = QR.drawImg(record.hotname || '', {
    typeNumber: 4,
    errorCorrectLevel: 'M',
    size: 500,
  })

  const hotnameSplit = record.hotname.split('-')
  const numberBucket = hotnameSplit[hotnameSplit.length - 1]
  const codeBucket = hotnameSplit[1]

  return (
    <div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          height: '100vh',
          justifyContent: 'space-between',
          paddingLeft: 7,
        }}
      >
        <div
          style={{
            width: '65px',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img src={imgData} alt="" style={{ objectFit: 'contain', width: 65, height: 65 }} />
        </div>
        <div
          style={{
            width: 'calc(100vw - 70px)',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <div style={{ fontSize: 16 }}>{record.hotname || ''}</div>
          <div style={{ fontSize: 55, fontWeight: 800, lineHeight: '25px', marginLeft: 8 }}>
            {numberBucket || ''}
          </div>
          <div style={{ fontSize: 30, fontStyle: 'italic', fontWeight: 600 }}>
            {codeBucket || ''}
          </div>
        </div>
      </div>
      <div style={{ 'page-break-after': 'always' }}></div>
    </div>
  )
}
class ComponentPrint extends React.Component {
  render() {
    const { records } = this.props
    let printingPages = []
    for (const record of records) {
      const tempTemplate = <PrintQRCodeBucket record={record} />
      printingPages.push(tempTemplate)
    }
    return <div>{printingPages}</div>
  }
}

function QRCodePrinter({ records, componentRef }) {
  return (
    <>
      <div style={{ display: 'none' }}>
        <ComponentPrint records={records} ref={componentRef} />
      </div>
    </>
  )
}

export default QRCodePrinter
