const columnsProduct = [
  {
    key: 'number',
    title: 'STT',
    align: 'center',
    width: 50,
  },
  {
    key: 'employee_code',
    title: 'Mã nhân viên',
    dataIndex: 'code',
  },
  {
    key: 'username',
    title: 'Tên tài khoản',
    dataIndex: 'username',
  },
  {
    title: 'Tên nhân viên',
    dataIndex: 'fullname',
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'phone',
  },
  {
    key: 'address',
    title: 'Địa chỉ',
    align: 'center',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    key: 'role',
    title: 'Vai trò',
    dataIndex: 'role_id',
  },
  {
    key: 'warehouse',
    title: 'Kho làm việc',
    dataIndex: 'warehouse_id',
  },
  {
    key: 'create_date',
    title: 'Ngày tạo',
    dataIndex: 'create_date',
    align: 'center'
  },
  {
    key: 'action',
    title: 'Hành động',
    align: 'center'
  },
]

export default columnsProduct
