const columns = [
  {
    key: 'stt',
    title: 'STT',
    dataIndex: 'stt',
    align: 'center',
    width: 50,
  },
  {
    key: 'fee_name',
    title: 'Tên chi phí',
    dataIndex: 'fee_name',
    align: 'center',
  },
  {
    title: 'Nhóm chi phí',
    dataIndex: 'fee_type',
    align: 'center',
  },
  {
    key: 'fee',
    title: 'Đơn giá',
    align: 'center',
    dataIndex: 'fee',
  },
  {
    key: 'unit',
    title: 'Đơn vị áp dụng',
    align: 'center',
  },
  {
    key: 'fee_exceeding',
    title: 'Đơn giá vượt mức',
    align: 'center',
  },
  {
    dataIndex: 'exceeding_quantity',
    title: 'SL áp dụng vượt mức',
    align: 'center',
  },
  {
    key: 'default_import',
    title: 'Mặc định sử dụng trên phiếu nhập',
    align: 'center',
    width: 100,
  },
  {
    key: 'default_export',
    title: 'Mặc định sử dụng trên phiếu xuất',
    align: 'center',
    width: 100,
  },
  {
    key: 'create_date',
    title: 'Ngày tạo',
    dataIndex: 'create_date',
    align: 'center',
    width: 100,
  },
  {
    key: 'action',
    title: 'Hành động',
    width: 60,
    align: 'center',
  },
]
export default columns
