import React, { useState, useEffect, useRef } from 'react'
import styles from './reportInventory.module.scss'
import { SIZE_TABLE, POSITION_TABLE, PAGE_SIZE_OPTIONS } from 'consts'
import moment from 'moment'
import {
  filterOptionSelect,
  formatCash,
  _addLog,
  saveFiltersToLocalStorage,
  getFileName,
} from 'utils'
import { saveAs } from 'file-saver'

// antd
import { Col, Input, Row, Select, Table, Space, Button } from 'antd'

//icons
import { ReloadOutlined, VerticalAlignTopOutlined } from '@ant-design/icons'

//components
import SuffixIconCustom from 'components/suffixIconCustom'
import SettingColumns from 'components/setting-columns'
import reportInventoryColumns from './columns'
import FilterDate from 'components/filter-date'

//apis
import { getCategories } from 'apis/categories-product'
import { exportReportExportOrder, getReportExportOrder } from 'apis/report'
import { getAllCustomer } from 'apis/customer'
import { getWarehouses } from 'apis/warehouse'

const { Option } = Select
export default function ReportExportOrder() {
  const typingTimeoutRef = useRef()
  const KEY_FILTER = 'reportExportOrderFilters'
  const filters = localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))

  const [columns, setColumns] = useState([])
  const [reportExportInventory, setReportExportInventory] = useState([])
  const [loading, setLoading] = useState(false)
  const [paramsFilter, setParamsFilter] = useState(
    filters ? { ...filters } : { page: 1, page_size: 100, today: true }
  )
  const [inventoryCount, setInventoryCount] = useState(0)
  const [groups, setGroups] = useState([])
  const [types, setTypes] = useState([])
  const [customers, setCustomers] = useState([])
  const [warehouses, setWarehouses] = useState([])

  const [valueSearch, setValueSearch] = useState(
    filters ? filters.order_customer_order_code || filters.product_barcode || '' : ''
  )
  const [optionSearch, setOptionSearch] = useState(
    filters
      ? (filters.order_customer_order_code && 'order_customer_order_code') ||
          (filters.product_barcode && 'product_barcode') ||
          'order_customer_order_code'
      : 'order_customer_order_code'
  )

  const clearFilters = () => {
    setValueSearch('')
    setParamsFilter({ page: 1, page_size: 20, today: true })
  }

  const _getWarehouses = async () => {
    try {
      const res = await getWarehouses()
      if (res.status === 200) setWarehouses(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const onFilter = (attribute = '', value = '') => {
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter, page: 1 })
  }

  const renderNewColumns = (column) => {
    if (column.key === 'stt')
      return {
        ...column,
        render: (text, record, index) =>
          (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
      }

    if (column.key == 'code_ecommerce')
      return {
        ...column,
        render: (text, record) => record.code_ecommerce,
      }

    if (column.key === 'order_create_date')
      return {
        ...column,
        render: (text, record) =>
          record.order_create_date && moment(record.order_create_date).format('DD/MM/YYYY HH:mm'),
      }
    if (column.key === 'product_export_date')
      return {
        ...column,
        render: (text, record) =>
          record.product_export_date &&
          moment(record.product_export_date).format('DD/MM/YYYY HH:mm'),
      }
    if (column.key === 'product_length')
      return {
        ...column,
        render: (text, record) => formatCash(record.product_length || 0),
      }
    if (column.key === 'product_width')
      return {
        ...column,
        render: (text, record) => formatCash(record.product_width || 0),
      }
    if (column.key === 'product_height')
      return {
        ...column,
        render: (text, record) => formatCash(record.product_height || 0),
      }
    if (column.key === 'product_cbm')
      return {
        ...column,
        render: (text, record) => formatCash(record.product_cbm || 0),
      }
    if (column.key === 'product_area')
      return {
        ...column,
        render: (text, record) => formatCash(record.product_area || 0),
      }
    if (column.key === 'product_export_quantity')
      return {
        ...column,
        render: (text, record) => formatCash(record.product_export_quantity || 0),
      }
    if (column.key === 'product_conversion_quantity')
      return {
        ...column,
        render: (text, record) => formatCash(record.product_conversion_quantity || 0),
      }
    if (column.key === 'product_conversion_require_quantity')
      return {
        ...column,
        render: (text, record) => formatCash(record.product_conversion_require_quantity || 0),
      }

    return column
  }

  const _selectOption = (value) => {
    setOptionSearch(value)
    delete paramsFilter[optionSearch]
    if (valueSearch) setParamsFilter({ ...paramsFilter, [value]: valueSearch, page: 1 })
  }

  const _onSearch = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value
      if (value) paramsFilter[optionSearch] = value
      else delete paramsFilter[optionSearch]
      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 650)
  }

  const _getGroups = async () => {
    try {
      const res = await getCategories({ group: true, detach: true, get_all: true })
      if (res.status === 200) setGroups(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _getTypes = async () => {
    try {
      const res = await getCategories({ type: true, detach: true, get_all: true })
      console.log(res)
      if (res.status === 200) setTypes(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _getReportExportInventoryToExport = async (getAll = false) => {
    try {
      let query = {}

      if (getAll) {
        const queries = [
          'today',
          'yesterday',
          'this_week',
          'last_week',
          'this_month',
          'last_month',
          'this_year',
          'last_year',
          'from_date',
          'to_date',
        ]

        queries.map((key) => {
          if (Object.keys(paramsFilter).includes(key)) query[key] = paramsFilter[key]
        })
      } else query = { ...paramsFilter }

      delete query.page
      delete query.page_size
      setLoading(true)
      const res = await exportReportExportOrder(query)
      setLoading(false)
      if (res.status === 200) {
        const link = res.data.data
        if (link) saveAs(link, getFileName(link))
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const _getReportExportInventory = async () => {
    try {
      setLoading(true)
      const res = await getReportExportOrder({ ...paramsFilter })
      setLoading(false)
      if (res.status === 200) {
        setInventoryCount(res.data.count || 0)
        setReportExportInventory(res.data.data)
      }
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
      setLoading(false)
    }
  }

  const _getCustomers = async () => {
    try {
      const res = await getAllCustomer({ get_all: true })
      if (res.status === 200) setCustomers(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  useEffect(() => {
    _getReportExportInventory()
    saveFiltersToLocalStorage(paramsFilter, KEY_FILTER)
  }, [paramsFilter])

  useEffect(() => {
    _getGroups()
    _getTypes()
    _getCustomers()
    _getWarehouses()
  }, [])

  return (
    <div className={styles['reportInventory']}>
      <Row className="page-title" justify="space-between" style={{ marginBottom: '25px' }}>
        <h3>Báo cáo xuất hàng</h3>
        <Space>
          <Button type="primary" icon={<ReloadOutlined />} onClick={_getReportExportInventory}>
            Làm mới
          </Button>
          <Button
            onClick={() => _getReportExportInventoryToExport()}
            icon={<VerticalAlignTopOutlined />}
            type="primary"
            className="btn-export"
          >
            Xuất theo bộ lọc
          </Button>
          <Button
            onClick={() => _getReportExportInventoryToExport(true)}
            icon={<VerticalAlignTopOutlined />}
            type="primary"
            className="btn-export"
          >
            Xuất toàn bộ
          </Button>
          <SettingColumns
            columns={columns}
            columnsRender={reportInventoryColumns}
            setColumns={setColumns}
            nameColumn="columnsReportExportWarehouse"
          />
        </Space>
      </Row>
      <div className={styles['reportInventory-content']}>
        <Row gutter={15} justify="space-between" style={{ marginBottom: 15 }}>
          <Col span={10}>
            <Input.Group
              compact
              style={{
                width: '100%',
                boxShadow: '0px 2px 6px rgba(19, 216, 229, 0.13)',
                marginRight: 30,
              }}
            >
              <Input
                value={valueSearch}
                onChange={_onSearch}
                allowClear
                style={{ width: '65%' }}
                placeholder="Tìm kiếm theo tiêu chí"
              />
              <Select
                defaultValue="makhachhang"
                suffixIcon={<SuffixIconCustom />}
                style={{ width: '35%' }}
                onChange={_selectOption}
                value={optionSearch}
              >
                <Option value="product_barcode">Mã sản phẩm</Option>
                <Option value="order_customer_order_code">Số phiếu</Option>
              </Select>
            </Input.Group>
          </Col>

          <Col lg={4}>
            <Select
              value={paramsFilter['warehouse_id'] || undefined}
              onChange={(value) => onFilter('warehouse_id', value)}
              showSearch
              allowClear
              placeholder="Lọc theo kho"
              style={{ width: '100%' }}
              dropdownClassName="dropdown-select-custom"
              suffixIcon={<SuffixIconCustom />}
            >
              {warehouses.map((warehouse, index) => (
                <Select.Option key={index} value={warehouse.warehouse_id}>
                  {warehouse.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={5}>
            <Select
              suffixIcon={<SuffixIconCustom />}
              allowClear
              filterOption={filterOptionSelect}
              optionFilterProp="children"
              showSearch
              style={{ width: '100%' }}
              placeholder="Lọc theo doanh nghiệp"
              value={paramsFilter['customer_id'] || undefined}
              onChange={(value) => onFilter('customer_id', value)}
            >
              {customers.map((customer, index) => (
                <Select.Option value={customer.customer_id || ''} key={index}>
                  {customer.fullname || ''}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={4}>
            <FilterDate
              allowClear={false}
              paramsFilter={paramsFilter}
              setParamsFilter={setParamsFilter}
            />
          </Col>
        </Row>
        <Row gutter={15} style={{ marginBottom: 15 }}>
          <Col span={5}>
            <Select
              suffixIcon={<SuffixIconCustom />}
              placeholder="Lọc theo nhóm hàng"
              style={{ width: '100%' }}
              allowClear
              showSearch
              optionFilterProp="children"
              value={paramsFilter.group_id}
              onChange={(value) => onFilter('group_id', value)}
            >
              {groups.map((group, index) => (
                <Select.Option value={group.category_id} key={index}>
                  {group.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={5}>
            <Select
              suffixIcon={<SuffixIconCustom />}
              style={{ width: '100%' }}
              placeholder="Lọc theo loại hàng"
              allowClear
              showSearch
              optionFilterProp="children"
              value={paramsFilter.type_id}
              onChange={(value) => onFilter('type_id', value)}
            >
              {types.map((type, index) => (
                <Select.Option value={type.category_id} key={index}>
                  {type.name}
                </Select.Option>
              ))}
            </Select>
          </Col>

          <Col>
            <Button
              danger
              type="primary"
              onClick={clearFilters}
              style={{ display: Object.keys(paramsFilter).length <= 3 && 'none' }}
            >
              Xóa bộ lọc
            </Button>
          </Col>
        </Row>
      </div>
      <Table
        style={{ width: '100%' }}
        scroll={{ x: 3500, y: '65vh' }}
        loading={loading}
        size={SIZE_TABLE}
        dataSource={reportExportInventory}
        columns={columns.map((column) => renderNewColumns(column))}
        pagination={{
          position: POSITION_TABLE,
          current: paramsFilter.page,
          pageSize: paramsFilter.page_size,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          total: inventoryCount,
          showQuickJumper: true,
          onChange: (page, pageSize) =>
            setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize }),
        }}
        summary={() => (
          <Table.Summary fixed="bottom">
            <Table.Summary.Row></Table.Summary.Row>
          </Table.Summary>
        )}
        sticky
      />
    </div>
  )
}
