import React, { useState, useEffect, useRef } from 'react'
import delay from 'delay'
import styles from './transport-transfer.module.scss'
import { SIZE_TABLE, POSITION_TABLE, PAGE_SIZE_OPTIONS, PERMISSIONS } from 'consts'
import { formatCash, compare, _addLog, saveFiltersToLocalStorage, filterOptionSelect } from 'utils'
import KeyboardEventHandler from 'react-keyboard-event-handler'
import { useSelector } from 'react-redux'

//components
import columns from './columns'
import TableExpand from './TableExpand'
import SuffixIconCustom from 'components/suffixIconCustom'
import ButtonPrintAllExpense from './ButtonPrintAllExpense'
import Permission from 'components/permission'
import FilterDate from 'components/filter-date'

//ants
import {
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
  ReloadOutlined,
  SearchOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import {
  Button,
  Col,
  Input,
  Row,
  Select,
  Table,
  Modal,
  Form,
  Space,
  Upload,
  notification,
  Tag,
  Spin,
  Popconfirm,
  Tooltip,
  Popover,
} from 'antd'

//apis
import { getAllCustomer } from 'apis/customer'
import {
  getExportOrders,
  updateExportOrders,
  updateExportOrdersComplete,
  statusListExportOrder,
} from 'apis/export-order'
import { getShippingCompany } from 'apis/shipping-company'
import { uploadFile } from 'apis/upload'

export default function TransportTransfer() {
  const scanModalRef = useRef()
  const scanRef = useRef()
  const searchRef = useRef()
  const [formScan] = Form.useForm()
  const [formScanModal] = Form.useForm()
  const KEY_FILTER = 'transportTransferFilters'
  const filters = localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))

  const districts = useSelector((state) => state.address.districts)
  const provinces = useSelector((state) => state.address.provinces)
  //const wards = useSelector((state) => state.address.wards)

  const [loading, setLoading] = useState(false)
  const [loadingScan, setLoadingScan] = useState(false)
  const [dataCustomer, setDataCustomer] = useState([])
  const [dataExportOrder, setDataExportOrder] = useState([])
  const [exportOrderCount, setExportOrderCount] = useState(0)
  const [dataShippingCompany, setDataShippingCompany] = useState([])
  const [selectKeys, setSelectKeys] = useState([])
  const [isScan, setIsScan] = useState(true)
  const [isScanPackage, setIsScanPackage] = useState(true)
  const [ordersScan, setOrdersScan] = useState([])
  const [statusList, setStatusList] = useState([])
  const [warningConfirm, setWarningConfirm] = useState('')
  const [paramsFilter, setParamsFilter] = useState(
    filters ? { ...filters } : { page: 1, page_size: 20, is_transported: true }
  )

  const [valueSearch, setValueSearch] = useState(filters ? filters.search || '' : '')

  const _getShippingCompany = async () => {
    try {
      const res = await getShippingCompany()
      if (res.status === 200) {
        const shipping = res.data.data
        setDataShippingCompany(shipping)
      }
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _onSearch = (e) => {
    e.target.value = String(e.target.value).replace(/#/gi, '')
    setValueSearch(e.target.value)
    if (searchRef.current) {
      clearTimeout(searchRef.current)
    }
    searchRef.current = setTimeout(() => {
      const value = e.target.value
      if (value) paramsFilter.search = value
      else delete paramsFilter.search
      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 650)
  }

  const _getOrderDetail = async (orderId = '') => {
    try {
      const res = await getExportOrders({ order_id: orderId })
      if (res.status === 200) {
        const order = res.data.data && res.data.data.length && res.data.data[0]
        if (order) return order
      }

      return null
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const _onScan = async (obj) => {
    try {
      const { code } = obj
      if (code) {
        setLoading(true)
        const res = await getExportOrders({ ...paramsFilter, package_code: code })
        if (res.status === 200) {
          const data = res.data.data
          if (data && data.length === 1) {
            const order = await _getOrderDetail(data[0].order_id)
            if (order) {
              ordersScan.push({ ...order, packagesScan: [code] })
              setOrdersScan([...ordersScan])
              await delay(200)
            }
          } else notification.warning({ message: 'Không tìm thấy kiện hàng này!' })
        }
        setLoading(false)
        formScan.resetFields()
        if (scanModalRef && scanModalRef.current) scanModalRef.current.focus()
      }
    } catch (error) {
      _addLog(JSON.stringify(error))
      setLoading(false)
      formScan.resetFields()
    }
  }

  const _onScanPackage = async (obj) => {
    try {
      const { code } = obj
      if (code) {
        setLoadingScan(true)
        const resPackage = await getExportOrders({ ...paramsFilter, package_code: code })
        if (resPackage.status === 200) {
          const data =
            resPackage.data.data && resPackage.data.data.length && resPackage.data.data[0]

          if (data) {
            const order = ordersScan.find((o) => o.code === data.code)
            if (order) {
              const packageScan = order.packagesScan.find((packageCode) => packageCode === code)
              if (packageScan)
                notification.warning({
                  message: 'Bạn đã quét kiện hàng này rồi!',
                  key: 'scan-error',
                })
              else {
                const indexOrder = ordersScan.findIndex((o) => o.code === order.code)
                if (indexOrder !== -1) {
                  ordersScan[indexOrder].packagesScan.push(code)
                  setOrdersScan([...ordersScan])
                }
              }
            } else {
              const orderDetail = await _getOrderDetail(data.order_id || '')
              if (orderDetail) ordersScan.push({ ...orderDetail, packagesScan: [code] })
              setOrdersScan([...ordersScan])
            }
          } else notification.warning({ message: 'Không tìm thấy kiện hàng này!' })
        } else notification.warning({ message: 'Không tìm thấy kiện hàng này!' })
        setLoadingScan(false)
        formScanModal.resetFields()
        if (scanModalRef && scanModalRef.current) scanModalRef.current.focus()
      }
    } catch (error) {
      _addLog(`Scan package: ${JSON.stringify(error)}`)
      setLoadingScan(false)
      formScanModal.resetFields()
      if (scanModalRef && scanModalRef.current) scanModalRef.current.focus()
    }
  }

  const _getCustomers = async () => {
    try {
      const res = await getAllCustomer()
      if (res.status === 200) {
        const uniqueFullnameData = res.data.data.filter(
          (item, index, self) => index === self.findIndex((t) => t.fullname === item.fullname)
        )
        setDataCustomer(uniqueFullnameData)
      }
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _getStatusList = async () => {
    try {
      const res = await statusListExportOrder()
      if (res.status === 200) setStatusList(res.data.data.status || [])
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _filterByOption = (attribute = '', value = '') => {
    paramsFilter.page = 1

    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]

    setParamsFilter({ ...paramsFilter })
  }
  const _uploadFile = async (file, record) => {
    try {
      setLoading(true)
      const url = await uploadFile(file)
      if (url) {
        const body = { ...record, shipping_info: { ...record.shipping_info, shipping_bill: [url] } }
        _updateExportOrders(record.order_id, body)
      }
      setLoading(false)
    } catch (error) {
      _addLog(`Upload file: ${JSON.stringify(error)}`)
      setLoading(false)
      console.log(error)
    }
  }

  const _removeFile = async (record) => {
    try {
      const body = { ...record, shipping_info: { ...record.shipping_info, shipping_bill: [] } }
      _updateExportOrders(record.order_id, body)
    } catch (error) {
      _addLog(`Delete file: ${JSON.stringify(error)}`)
      setLoading(false)
      console.log(error)
    }
  }

  const ModalUploadBill = () => {
    const [visible, setVisible] = useState(false)
    const [image, setImage] = useState([])
    const [loading, setLoading] = useState(false)

    const toggle = () => setVisible(!visible)

    const _uploadFile = async (file) => {
      try {
        setLoading(true)
        const url = await uploadFile(file)
        if (url) setImage([...image, url])
        setLoading(false)
      } catch (error) {
        _addLog(`Upload bill: ${JSON.stringify(error)}`)
        setLoading(false)
        console.log(error)
      }
    }

    useEffect(() => {
      if (visible) setImage([])
    }, [visible])

    return (
      <>
        <Button onClick={toggle} type="primary">
          Cập nhật biên lai
        </Button>
        <Modal
          visible={visible}
          width={500}
          okText="Cập nhật"
          cancelText="Đóng"
          title="CẬP NHẬT BIÊN LAI"
          onCancel={toggle}
          confirmLoading={loading}
          onOk={async () => {
            const body = { order_id: selectKeys, shipping_bill: image }
            _updateExportOrdersComplete(body)
          }}
        >
          <div>
            <div>Hình ảnh</div>
            <Upload
              data={_uploadFile}
              name="avatar"
              listType="picture-card"
              className="bill-uploader"
              showUploadList={false}
            >
              {image && image.length ? (
                <img src={image[0]} alt="avatar" style={{ width: '100%' }} />
              ) : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Tải lên</div>
                </div>
              )}
            </Upload>
          </div>
        </Modal>
      </>
    )
  }

  const HandlerKeyboard = () => {
    return (
      <KeyboardEventHandler
        handleKeys={['f1']}
        onKeyEvent={(key, e) => {
          switch (key) {
            case 'f1': {
              if (scanRef && scanRef.current) scanRef.current.focus()
              break
            }

            default:
              break
          }
        }}
      />
    )
  }

  const _getExportOrders = async (params = paramsFilter, isTranfering = false) => {
    //isTranfering dùng để gọi tất cả đơn hàng bỏ page và pagesize, ko set data vào table
    if (isTranfering) {
      delete params.page
      delete params.page_size
    }

    try {
      setLoading(true)
      const transport_status = ['WAITING', 'PROCESSING', 'WAITING_FOR_SHIPPING'].join('-')
      const res = await getExportOrders({ ...params, transport_status })

      if (res.status === 200) {
        const listPromise = res.data.data.map(async (order) => {
          const resOrder = await _getOrderDetail(order.order_id)
          return resOrder
        })

        const orders = await Promise.all(listPromise)
        const ordersNew = orders.filter((order) => order)

        if (!isTranfering) {
          setDataExportOrder(ordersNew)
          setExportOrderCount(res.data.count)
        }

        setLoading(false)
        return ordersNew
      }
      setLoading(false)
      return []
    } catch (error) {
      _addLog(JSON.stringify(error))
      setLoading(false)
      return []
    }
  }

  const _updatePrintCount = async (records = []) => {
    try {
      setLoading(true)
      const listPromise = records.map(async (record) => {
        const body = { print_count: record.print_count !== undefined ? record.print_count + 1 : 1 }
        const res = await updateExportOrders(record.order_id, body)
        return res
      })
      await Promise.all(listPromise)
      _getExportOrders()
      setLoading(false)
    } catch (error) {
      _addLog(`Update export order: ${JSON.stringify(error)}`)
      setLoading(false)
    }
  }

  const _updateExportOrders = async (order_id, body, getExport = true) => {
    body.processing = 'is_transported'
    try {
      setLoading(true)
      const res = await updateExportOrders(order_id, body)

      if (res.status === 200) {
        if (res.data.success) {
          if (getExport) _getExportOrders()
          setLoading(false)
          return res.data.data
        }
      }
      setLoading(false)
      return []
    } catch (error) {
      _addLog(`Update export order: ${JSON.stringify(error)}`)
      setLoading(false)
      return []
    }
  }

  const _updateExportOrdersComplete = async (body) => {
    try {
      setLoading(true)
      const res = await updateExportOrdersComplete(body)
      setLoading(false)
      if (res.status === 200) {
        if (res.data.success) {
          _getExportOrders()
          setSelectKeys([])
          notification.success({ message: 'Cập nhật thành công' })
        } else
          notification.error({ message: res.data.message || 'Cập nhật thất bại, vui lòng thử lại' })
      } else
        notification.error({ message: res.data.message || 'Cập nhật thất bại, vui lòng thử lại' })
      setLoading(false)
    } catch (error) {
      _addLog(`Update export order complete: ${JSON.stringify(error)}`)
      setLoading(false)
      console.log(error)
    }
  }

  const ModalTransferOrders = ({
    id,
    txtBtn = 'Xác nhận bàn giao toàn bộ',
    title = 'Xác nhận bàn giao toàn bộ đơn hàng',
  }) => {
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const toggle = () => setVisible(!visible)

    const [imageBill, setImageBill] = useState('')
    const [imageSignature, setSignature] = useState('')

    const _uploadFile = async (file, type = 'bill') => {
      try {
        setLoading(true)
        const url = await uploadFile(file)
        if (url) {
          if (type === 'bill') setImageBill(url)
          else setSignature(url)
        }
        setLoading(false)
      } catch (error) {
        _addLog(`Upload file: ${JSON.stringify(error)}`)
        setLoading(false)
        console.log(error)
      }
    }

    const onTransferOrders = async () => {
      const orders = await _getExportOrders(paramsFilter, true)
      const ids = orders.map((item) => item.order_id)
      const body = {
        order_id: id ? id : ids,
        transport_status: 'COMPLETE',
        shipping_bill: imageBill || '',
        shipping_employee_signature: imageSignature || '',
      }
      toggle()
      _updateExportOrdersComplete(body)
    }

    useEffect(() => {
      if (!visible) {
        setSignature('')
        setImageBill('')
      }
    }, [visible])

    return (
      <>
        <Button
          type="primary"
          onClick={async () => {
            if (dataExportOrder && dataExportOrder.length) toggle()
            else notification.warning({ message: 'Chưa có đơn hàng để bàn giao' })
          }}
        >
          {txtBtn}
        </Button>
        <Modal
          footer={
            <Row justify="end">
              <Space>
                <Button>Đóng</Button>
                <Button
                  disabled={imageBill && imageSignature ? false : true}
                  type="primary"
                  onClick={onTransferOrders}
                >
                  Bàn giao
                </Button>
              </Space>
            </Row>
          }
          visible={visible}
          onCancel={toggle}
          title={title}
        >
          <Row gutter={20}>
            <Col xl={12}>
              <div>
                <div>Hình ảnh chữ ký</div>
                <Upload
                  name="avatar"
                  listType="picture-card"
                  showUploadList={false}
                  data={(file) => _uploadFile(file, 'signature')}
                >
                  {imageSignature ? (
                    <img src={imageSignature} alt="avatar" style={{ width: '100%' }} />
                  ) : (
                    <div>
                      {loading ? <LoadingOutlined /> : <PlusOutlined />}
                      <div style={{ marginTop: 8 }}>Tải lên</div>
                    </div>
                  )}
                </Upload>
              </div>
            </Col>
            <Col xl={12}>
              <div>
                <div>Hình ảnh biên lai</div>
                <Upload
                  name="avatar"
                  listType="picture-card"
                  showUploadList={false}
                  data={(file) => _uploadFile(file)}
                >
                  {imageBill ? (
                    <img src={imageBill} alt="avatar" style={{ width: '100%' }} />
                  ) : (
                    <div>
                      {loading ? <LoadingOutlined /> : <PlusOutlined />}
                      <div style={{ marginTop: 8 }}>Tải lên</div>
                    </div>
                  )}
                </Upload>
              </div>
            </Col>
          </Row>
          {id && (
            <div>
              Danh sách đơn hàng bàn giao:{' '}
              <b style={{ fontSize: 17 }}>{id.map((i) => `#${i}`).join(', ')}</b>
            </div>
          )}
        </Modal>
      </>
    )
  }

  useEffect(() => {
    _getCustomers()
    _getStatusList()
    _getShippingCompany()
  }, [])

  useEffect(() => {
    _getExportOrders()
    saveFiltersToLocalStorage(paramsFilter, KEY_FILTER)
  }, [paramsFilter])

  return (
    <div className={styles['transport-transfer']}>
      <HandlerKeyboard />
      <Modal
        closable={false}
        style={{ top: 20 }}
        width="70%"
        footer={
          <Row justify="end">
            <Popconfirm
              onConfirm={() => {
                setOrdersScan([])
                if (scanRef && scanRef.current) scanRef.current.focus()
              }}
              title="Bạn có muốn hủy bỏ các thao tác quét kiện hàng vừa rồi không?"
            >
              <Button danger>Hủy bỏ</Button>
            </Popconfirm>
            <Button
              onClick={async () => {
                setLoadingScan(true)
                const listPromise = ordersScan.map(async (order) => {
                  let body = {}
                  if (order.packagesScan.length === order.packages.length) {
                    body.transport_status = 'WAITING_FOR_SHIPPING'
                  } else {
                    body.transport_status = 'REPORT'
                    body.transport_reason = 'Đơn hàng không đủ kiện hàng'
                  }
                  const res = await _updateExportOrders(order.order_id, body)
                  return res
                })
                await Promise.all(listPromise)
                setLoadingScan(false)
                setOrdersScan([])
                if (scanRef && scanRef.current) scanRef.current.focus()
              }}
            >
              Lưu
            </Button>
            <Popconfirm
              onCancel={() => setWarningConfirm('')}
              onConfirm={async () => {
                setLoadingScan(true)
                const listPromise = ordersScan.map(async (order) => {
                  let body = { transport_status: 'COMPLETE' }
                  const res = await _updateExportOrders(order.order_id, body)
                  return res
                })
                await Promise.all(listPromise)
                setLoadingScan(false)
                setOrdersScan([])
                setWarningConfirm('')
                if (scanRef && scanRef.current) scanRef.current.focus()
              }}
              title={
                warningConfirm ? (
                  <div style={{ color: 'red' }}>{warningConfirm}</div>
                ) : (
                  'Bạn có muốn bàn giao vận chuyển các đơn hàng này không?'
                )
              }
            >
              <Button
                type="primary"
                onClick={() => {
                  const ordersError = ordersScan.filter(
                    (order) => order.packagesScan.length !== order.packages.length
                  )
                  if (ordersError.length) {
                    setWarningConfirm(
                      `Đơn hàng ${ordersError
                        .map((o) => o.code)
                        .join(', ')} chưa đủ kiện hàng, bạn có muốn bàn giao vận chuyển không?`
                    )
                  } else setWarningConfirm('')
                }}
              >
                Lưu và bàn giao vận chuyển
              </Button>
            </Popconfirm>
          </Row>
        }
        visible={ordersScan.length ? true : false}
        title="Quét kiện hàng để kiểm tra đơn hàng"
      >
        <Row justify="center">{loadingScan && <Spin />}</Row>

        <div>
          {!isScanPackage ? (
            <h1 style={{ marginBottom: 0 }}>
              Máy quét đang tắt,{' '}
              <a
                style={{ color: 'red' }}
                onClick={() => scanModalRef && scanModalRef.current && scanModalRef.current.focus()}
              >
                bấm vào đây để bật lại máy quét
              </a>
            </h1>
          ) : (
            <h1 style={{ color: 'red', marginBottom: 0 }}>* Bắt đầu quét kiện hàng</h1>
          )}

          <div>
            <div style={{ color: 'red' }}>* Có thể nhập mã kiện hàng và nhấn phím Enter để tìm</div>
            <Form form={formScanModal} onFinish={_onScanPackage}>
              <Form.Item name="code">
                <Input
                  style={{ marginBottom: 5 }}
                  ref={scanModalRef}
                  autoFocus
                  onFocus={() => setIsScanPackage(true)}
                  onBlur={() => setIsScanPackage(false)}
                />
              </Form.Item>
            </Form>
          </div>

          <div>
            <h2>
              <div>Danh sách đơn hàng đang kiểm tra:</div>
              <div style={{ maxHeight: '50vh', overflowY: 'auto' }}>
                {ordersScan.map((order, index) => (
                  <Row
                    wrap={false}
                    align="middle"
                    style={{
                      color: order.packagesScan.length === order.packages.length ? 'green' : 'red',
                    }}
                  >
                    <div>- #{order.code}: Bạn đã quét được </div>
                    <b style={{ margin: '0px 5px' }}>
                      {order.packagesScan.length}/{order.packages.length}
                    </b>
                    <div style={{ marginRight: 5 }}>kiện hàng</div>
                    {order.packagesScan.length === order.packages.length ? (
                      <div>(Đã quét đủ kiện hàng)</div>
                    ) : (
                      <div>(Chưa quét đủ kiện hàng)</div>
                    )}
                    <div style={{ color: 'red', marginLeft: 10, cursor: 'pointer' }}>
                      <DeleteOutlined
                        onClick={() => {
                          const ordersScanNew = [...ordersScan]
                          ordersScanNew.splice(index, 1)
                          setOrdersScan([...ordersScanNew])
                          setIsScanPackage(true)
                          if (scanModalRef && scanModalRef.current) scanModalRef.current.focus()
                        }}
                      />
                    </div>
                  </Row>
                ))}
              </div>
            </h2>
          </div>
        </div>
      </Modal>
      <Row align="middle" justify="space-between" wrap={false}>
        <Space size={'large'}>
          <h3>Đợi bàn giao vận chuyển</h3>
          <div>
            <div style={{ fontSize: 16 }}>Lọc theo đơn vị vận chuyển</div>
            <Select
              onChange={(value) => _filterByOption('shipping_company_id', value)}
              placeholder="Lọc theo đơn vị vận chuyển"
              showSearch
              style={{ minWidth: 250, marginBottom: 8 }}
              filterOption={filterOptionSelect}
              value={paramsFilter['shipping_company_id'] || ''}
              suffixIcon={<SuffixIconCustom size="default" />}
              allowClear
            >
              <Select.Option value="">Tất cả</Select.Option>
              {dataShippingCompany.map((item, index) => (
                <Select.Option key={index} value={item.shipping_company_id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Space>
        <Space>
          {dataExportOrder.length ? (
            <ButtonPrintAllExpense
              _updateExportOrders={_updatePrintCount}
              _getExportOrders={_getExportOrders}
              text="In toàn bộ phiếu xuất hàng kiêm biên bản bàn giao"
            />
          ) : (
            ''
          )}

          <Permission permissions={[PERMISSIONS.cap_nhat_ban_giao_van_chuyen]}>
            <ModalTransferOrders />
          </Permission>
        </Space>
      </Row>
      <hr />

      <h1
        style={{
          marginTop: 10,
          marginBottom: 5,
          fontSize: 20,
          lineHeight: 1,
          fontWeight: 700,
          color: 'red',
        }}
      >
        {isScan ? '* Bắt đầu quét kiện hàng' : '* Máy quét đang tắt, nhấn F1 để bật máy quét'}
      </h1>

      <Row
        wrap={false}
        gutter={8}
        style={{ marginBottom: 15 }}
        className={styles['employee-management-input-group']}
      >
        <Col span={7}>
          <Form form={formScan} onFinish={_onScan}>
            <div>
              <Form.Item name="code" style={{ marginBottom: 2 }}>
                <Input
                  onFocus={() => setIsScan(true)}
                  onBlur={() => setIsScan(false)}
                  autoFocus
                  ref={scanRef}
                  allowClear
                  enterButton
                  placeholder={
                    isScan
                      ? 'Bắt đầu quét kiện hàng'
                      : 'Nhấn vào đây để bắt đầu quét kiện hàng (F1)'
                  }
                  style={{ width: '100%', boxShadow: '0px 2px 6px rgba(19, 216, 229, 0.13)' }}
                />
              </Form.Item>
              <div style={{ color: 'red', lineHeight: 1 }}>
                * Có thể nhập mã kiện hàng và nhấn phím Enter để tìm
              </div>
            </div>
          </Form>
        </Col>
        <Col span={7}>
          <Input
            allowClear
            prefix={<SearchOutlined />}
            placeholder="Tìm kiếm theo mã đơn hàng/số phiếu"
            value={valueSearch}
            onChange={_onSearch}
          />
        </Col>
        <Col span={5}>
          <Select
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
            }
            style={{ width: '100%' }}
            value={paramsFilter['customer_id'] || undefined}
            onChange={(value) => _filterByOption('customer_id', value)}
            placeholder="Lọc theo doanh nghiệp"
            suffixIcon={<SuffixIconCustom size="default" />}
          >
            {dataCustomer.map((item, index) => (
              <Select.Option key={index} value={item.customer_id}>
                {item.fullname}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={5}>
          <FilterDate paramsFilter={paramsFilter} setParamsFilter={setParamsFilter} />
        </Col>
      </Row>
      <Row justify="space-between" style={{ marginBottom: 5 }}>
        <ButtonPrintAllExpense
          _getExportOrders={_getExportOrders}
          text="In biên bản bàn giao hàng loạt"
          printShippingCode={true}
        />

        <Space>
          {selectKeys.length ? (
            <>
              <ButtonPrintAllExpense
                _updateExportOrders={_updatePrintCount}
                tableRecords={dataExportOrder.filter((order) =>
                  selectKeys.includes(order.order_id)
                )}
                text="In phiếu xuất hàng kiêm biên bản bàn giao được chọn"
              />
              <ModalTransferOrders
                title="Xác nhận bàn giao đơn hàng đã chọn"
                txtBtn="Bàn giao các đơn hàng đã chọn"
                id={selectKeys}
              />
              <ModalUploadBill />
            </>
          ) : (
            ''
          )}
          <Button onClick={() => _getExportOrders()} type="primary" icon={<ReloadOutlined />}>
            Làm mới
          </Button>
        </Space>
      </Row>
      <div>
        <Table
          rowKey="order_id"
          expandable={{
            expandedRowRender: (record) => {
              return (
                <TableExpand
                  dataShippingCompany={dataShippingCompany}
                  provinces={provinces}
                  districts={districts}
                  ModalTransferOrders={ModalTransferOrders}
                  record={record}
                  _updateExportOrders={_updateExportOrders}
                />
              )
            },
          }}
          rowSelection={{
            selectedRowKeys: selectKeys,
            onChange: (keys) => setSelectKeys(keys),
          }}
          pagination={{
            position: POSITION_TABLE,
            current: paramsFilter.page,
            pageSize: paramsFilter.page_size,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            total: exportOrderCount,
            showQuickJumper: true,
            onChange: (page, pageSize) =>
              setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize }),
          }}
          scroll={{ x: 1900, y: '65vh' }}
          loading={loading}
          dataSource={dataExportOrder}
          columns={columns.map((column) => {
            if (column.key === 'code') {
              return {
                ...column,
                sorter: (a, b) => compare(a, b, 'code'),
                render: (text, record) => '#' + text,
              }
            }
            if (column.key === 'customer_order_code') {
              return {
                ...column,
                sorter: (a, b) => compare(a, b, 'customer_order_code'),
                render: (text, record) => `#${record.customer_order_code}`,
              }
            }

            if (column.key === 'sender') {
              return {
                ...column,
                sorter: (a, b) => compare(a.customer_info, b.customer_info, 'fullname'),
                render: (customer_info) => customer_info && customer_info.fullname,
              }
            }
            if (column.key === 'to_name') {
              return {
                ...column,
                sorter: (a, b) => compare(a.shipping_info, b.shipping_info, 'to_name'),
                render: (text) => text.to_name || '',
              }
            }
            if (column.key === 'fullname') {
              //Tên khách hàng
              return {
                ...column,
                sorter: (a, b) => compare(a.customer_info, b.customer_info, 'fullname'),
                render: (customer_info) => customer_info && customer_info.fullname,
              }
            }
            if (column.key === 'shipping_code') {
              return {
                ...column,
                sorter: (a, b) => compare(a.shipping_info, b.shipping_info, 'shipping_code'),
                render: (shipping_info) => {
                  return shipping_info.shipping_code || ''
                },
              }
            }
            if (column.key === 'cod') {
              return {
                ...column,
                sorter: (a, b) => compare(a.shipping_info, b.shipping_info, 'cod'),
                render: (shipping_info) => {
                  return formatCash(shipping_info.cod) + ' VNĐ'
                },
              }
            }
            if (column.key === 'shipping_company_name') {
              return {
                ...column,
                sorter: (a, b) =>
                  compare(a.shipping_info, b.shipping_info, 'shipping_company_name'),
                render: (shipping_info) => {
                  return shipping_info?.shipping_company_name
                },
              }
            }
            if (column.key === 'car_nummber') {
              return {
                ...column,
                render: (text, record) => {
                  return record.shipping_info && record.shipping_info.car_number
                },
              }
            }
            if (column.key === 'transport_status') {
              return {
                ...column,
                sorter: (a, b) => compare(a, b, 'transport_status'),
                render: (text, record) => {
                  const status = statusList.find((s) => s.value === record.transport_status)
                  return (
                    status && (
                      <Row wrap={false} align="middle">
                        <Tag
                          color={
                            (status.value === 'WAITING' && 'warning') ||
                            (status.value === 'PROCESSING' && 'processing') ||
                            (status.value === 'REPORT' && 'error') ||
                            (status.value === 'WAITING_FOR_SHIPPING' && 'cyan') ||
                            (status.value === 'COMPLETE' && 'success')
                          }
                        >
                          {status.label}
                        </Tag>
                        {status.value === 'REPORT' && (
                          <Tooltip title={record.transport_reason || ''}>
                            <WarningOutlined style={{ color: 'red' }} />
                          </Tooltip>
                        )}
                      </Row>
                    )
                  )
                },
              }
            }
            if (column.key === 'print_count') {
              return {
                ...column,
                render: (text, record) => formatCash(record.print_count || 0),
              }
            }
            if (column.key === 'bill') {
              return {
                ...column,
                render: (text, record) => {
                  const shippingBill =
                    record.shipping_info && typeof record.shipping_info.shipping_bill === 'string'
                      ? [record.shipping_info.shipping_bill]
                      : record.shipping_info.shipping_bill
                  return (
                    shippingBill &&
                    shippingBill.map(
                      (img) =>
                        img && (
                          <Popover
                            placement="right"
                            content={<img src={img} style={{ height: 400, width: 400 }} alt="" />}
                          >
                            <img alt="" src={img} style={{ width: 40, height: 40 }} />
                          </Popover>
                        )
                    )
                  )
                },
              }
            }
            // if (column.key === 'action') {
            //   return {
            //     ...column,
            //     render: (text, record) => {
            //       const urls = record.shipping_info && record.shipping_info.shipping_bill
            //       const fileList =
            //         urls && urls.length
            //           ? urls.map((url, index) => {
            //               const fileNames = urls[0].split('/')
            //               const fileName = fileNames[fileNames.length - 1]
            //               return { uid: index, name: fileName, status: 'done', url: url }
            //             })
            //           : []
            //       return (
            //         <Space size="small" direction="vertical">
            //           <ButtonPrintAllExpense
            //             _updateExportOrders={_updatePrintCount}
            //             tableRecords={[record]}
            //             text="In phiếu xuất hàng kiêm biên bản bàn giao"
            //           />
            //           <Space>
            //             <Permission permissions={[PERMISSIONS.cap_nhat_ban_giao_van_chuyen]}>
            //               <Upload
            //                 fileList={fileList}
            //                 data={(file) => _uploadFile(file, record)}
            //                 onRemove={() => _removeFile(record)}
            //                 className="bill-upload"
            //                 listType="picture-card"
            //               >
            //                 <div>
            //                   <PlusOutlined />
            //                   <div style={{ marginTop: 0 }}>Biên lai</div>
            //                 </div>
            //               </Upload>
            //             </Permission>
            //           </Space>
            //         </Space>
            //       )
            //     },
            //   }
            // }
            return column
          })}
          size={SIZE_TABLE}
          summary={() => (
            <Table.Summary fixed="bottom">
              <Table.Summary.Row></Table.Summary.Row>
            </Table.Summary>
          )}
          sticky
        />
      </div>
    </div>
  )
}
