const warehouseColumns = [
  {
    title: 'STT',
    key: 'stt',
    align: 'center',
    width: 50,
  },
  {
    title: 'Tên kho',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: 'Ký hiệu',
    dataIndex: 'hotname',
    align: 'center',
    width: 100,
  },
  {
    title: 'Địa chỉ kho',
    key: 'address',
    dataIndex: 'address',
  },
  {
    title: 'Số điện thoại',
    key: 'phone',
  },
  {
    title: 'Số lượng kệ hàng',
    key: 'shelve_quantity',
    dataIndex: 'shelve_quantity',
    align: 'center',
  },
  {
    title: 'Số lượng ô chứa',
    key: 'bucket_quantity',
    dataIndex: 'bucket_quantity',
    align: 'center',
  },
  {
    title: 'Số lượng thùng đựng',
    key: 'basket_quantity',
    dataIndex: 'basket_quantity',
    align: 'center',
  },
  {
    title: 'Ngày tạo',
    key: 'create_date',
    dataIndex: 'create_date',
    align: 'center',
  },
  {
    title: 'Trạng thái',
    key: 'active',
    dataIndex: 'active',
    align: 'center',
  },
  {
    title: 'Cập nhật trạng thái',
    key: 'update_status',
    align: 'center',
  },
  {
    title: 'Hành động',
    key: 'addShelve',
    align: 'center',
  },
]

export default warehouseColumns
