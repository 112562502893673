import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './unit.module.scss'
import { _addLog, saveFiltersToLocalStorage } from 'utils'
import moment from 'moment'

//consts
import { PAGE_SIZE_OPTIONS, PERMISSIONS, POSITION_TABLE, ROUTES, SIZE_TABLE } from 'consts'

//components
import SettingColumns from 'components/setting-columns'
import columnsUnit from './columns'
import FilterDate from 'components/filter-date'
import Permission from 'components/permission'
import ModalUnitForm from './modal-unit-form'

//apis
import { getUnits, deleteUnits } from 'apis/unit-product'

//antd
import { Row, Button, Input, Space, Table, notification, Col, Popconfirm } from 'antd'
import { DeleteOutlined, EditOutlined, ReloadOutlined } from '@ant-design/icons'

export default function EmployeeManagement() {
  const KEY_FILTER = 'unitFilters'
  const filters = localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))
  const typingTimeoutRef = useRef(null)

  const [columns, setColumns] = useState([])
  const [units, setUnits] = useState([])

  const [countUnit, setCountUnit] = useState(0)
  const [loading, setLoading] = useState(false)

  const [paramsFilter, setParamsFilter] = useState(
    filters ? { ...filters } : { page: 1, page_size: 20 }
  )
  const [valueSearch, setValueSearch] = useState(filters ? filters.name || '' : '')

  const _deleteUnit = async (id) => {
    try {
      const res = await deleteUnits({ unit_id: [id] })
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Xóa quy cách thành công!' })
          _getUnits()
        } else notification.error({ message: res.data.message || 'Xóa quy cách thất bại!' })
      } else notification.error({ message: res.data.message || 'Xóa quy cách thất bại!' })
    } catch (error) {
      console.log(error)
      _addLog(`Delete unit: ${JSON.stringify(error)}`)
    }
  }

  const _search = (e) => {
    const value = e.target.value
    setValueSearch(value)

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }

    typingTimeoutRef.current = setTimeout(() => {
      if (value) paramsFilter.name = value
      else delete paramsFilter.name
      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 650)
  }

  const _getUnits = async () => {
    try {
      setLoading(true)
      const res = await getUnits(paramsFilter)
      setLoading(false)
      if (res.status === 200) {
        setUnits(res.data.data)
        setCountUnit(res.data.count)
      }
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    _getUnits()
    saveFiltersToLocalStorage(paramsFilter, KEY_FILTER)
  }, [paramsFilter])

  return (
    <div className={styles['employee-management']}>
      <Row className="page-title" justify="space-between" style={{ marginBottom: 15 }}>
        <h3>Quản lý quy cách sản phẩm</h3>
        <Space>
          <Button onClick={_getUnits} type="primary" icon={<ReloadOutlined />}>
            Làm mới
          </Button>
          <SettingColumns
            columnsRender={columnsUnit}
            columns={columns}
            setColumns={setColumns}
            nameColumn="ColumnsEmployeeManagement"
          />
          <ModalUnitForm reload={_getUnits}>
            <Permission permissions={[PERMISSIONS.them_quy_cach_san_pham]}>
              <Button className={styles['btn-add']} type="primary">
                Thêm quy cách
              </Button>
            </Permission>
          </ModalUnitForm>
        </Space>
      </Row>
      <Row gutter={20} style={{ marginBottom: 25 }}>
        <Col span={9} style={{ display: 'flex', flexDirection: 'column', gap: '25px' }}>
          <Input.Search
            style={{ width: '100%' }}
            allowClear
            enterButton
            value={valueSearch}
            onChange={_search}
            placeholder="Tìm kiếm theo tên"
          />
        </Col>
        <Col span={6}>
          <FilterDate paramsFilter={paramsFilter} setParamsFilter={setParamsFilter} />
        </Col>
      </Row>
      <Table
        scroll={{ y: '65vh' }}
        style={{ width: '100%' }}
        dataSource={units}
        columns={columns.map((column) => {
          if (column.key === 'number')
            return {
              ...column,
              render: (text, record, index) =>
                (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
            }
          if (column.key === 'create_date')
            return {
              ...column,
              render: (text) => {
                const content = moment(text).format('DD/MM/YYYY hh:mm')
                return <div style={{ paddingRight: 5 }}>{content}</div>
              },
            }
          if (column.key === 'username')
            return {
              ...column,
              render: (text, record) => (
                <Link
                  to={{ pathname: ROUTES.CREATE_EMPLOYEE, state: record }}
                  style={{ color: '#0017E3' }}
                >
                  {text}
                </Link>
              ),
            }
          if (column.key === 'action')
            return {
              ...column,
              render: (text, record) => (
                <Space size="middle">
                  <Permission permissions={[PERMISSIONS.xoa_quy_cach_san_pham]}>
                    <Popconfirm
                      placement="topRight"
                      title="Bạn có muốn xóa quy cách này không?"
                      onConfirm={() => _deleteUnit(record.unit_id)}
                      okText="Đồng ý"
                      cancelText="Từ chối"
                    >
                      <DeleteOutlined style={{ color: 'red', fontSize: 18, cursor: 'pointer' }} />
                    </Popconfirm>
                  </Permission>
                  <Permission permissions={[PERMISSIONS.cap_nhat_quy_cach_san_pham]}>
                    <ModalUnitForm record={record} reload={_getUnits}>
                      <EditOutlined style={{ color: 'green', fontSize: 18, cursor: 'pointer' }} />
                    </ModalUnitForm>
                  </Permission>
                </Space>
              ),
            }

          return column
        })}
        pagination={{
          total: countUnit,
          current: paramsFilter.page,
          pageSize: paramsFilter.page_size,
          position: POSITION_TABLE,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          showQuickJumper: true,
          onChange: (page, pageSize) => {
            setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize })
          },
        }}
        size={SIZE_TABLE}
        loading={loading}
        summary={() => (
          <Table.Summary fixed="bottom">
            <Table.Summary.Row></Table.Summary.Row>
          </Table.Summary>
        )}
        sticky
      />
    </div>
  )
}
