import { EscPos } from '@tillpos/xml-escpos-helper'

export default function printToThermalPrinter(xml = '', body = {}, host = '192.168.0.122') {
  // const dataPrint = EscPos.getBufferFromTemplate(xml, body)
  const bodyPrint = {
    xml: xml,
    body: body,
    // dataPrint: dataPrint,
    host: host,
    port: '9100',
  }
  window.api.send('toMain', bodyPrint)
}
