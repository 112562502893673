import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useDispatch } from 'react-redux'
import { filterOptionSelect, formatCash, _addLog } from 'utils'
import styles from 'views/storage-fee/storagefee.module.scss'

//components
import Permission from 'components/permission'

//antd
import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  Row,
  Form,
  Input,
  notification,
  InputNumber,
  Space,
  Checkbox,
  Select,
  Table,
  Popconfirm,
} from 'antd'

//consts
import { ACTION, PERMISSIONS } from 'consts'

//apis
import { createStorageFee, updateStorageFee } from 'apis/storage-fee'
import { getExportOrders } from 'apis/export-order'
import { getOrders } from 'apis/order'
import {
  getImportOrderFeeDefault,
  getExportOrderFeeDefault,
  createFeeInOrder,
} from 'apis/fee-in-order'
import { getExpense } from 'apis/expense'
import { getFees } from 'apis/fee'
import { getStorageFees } from 'apis/storage-fee'
import { enumFeeGroup } from 'apis/enum'

export default function AddFeeToOrder() {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const queries = new URLSearchParams(location.search)
  const orderId = queries.get('order_id')
  const typeOrder = queries.get('type')

  const [order, setOrder] = useState({})
  const [feesTable, setFeesTable] = useState([])
  const [loading, setLoading] = useState(false)
  const [feeGroup, setFeeGroup] = useState([])
  const [selectedRowsKey, setSelectedRowsKey] = useState([])

  const columns = [
    {
      title: 'STT',
      width: 40,
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Loại phát sinh',
      width: 230,
      render: (text, record, index) => (
        <Select
          onChange={async (value) => {
            const feesTableNew = [...feesTable]
            feesTableNew[index] = {}
            feesTableNew[index].group = value
            feesTableNew[index].group_info = record.group_info || {}

            const fees = await _getFees(value)
            feesTableNew[index].fees = fees

            setFeesTable([...feesTableNew])
          }}
          value={record.group || undefined}
          filterOption={filterOptionSelect}
          style={{ width: '100%' }}
          placeholder="Chọn loại phát sinh"
        >
          {feeGroup.map((value) => (
            <Select.Option value={value.value} key={value.value}>
              {value.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      width: 280,
      title: 'Tên chi phí',
      render: (text, record, index) => (
        <Select
          filterOption={filterOptionSelect}
          style={{ width: '100%' }}
          placeholder="Chọn tên chi phí"
          showSearch
          value={record.fee_id || record.expense_id || record.storage_fee_id || undefined}
          onChange={(value) => {
            const objFee = record.fees.find(
              (fee) =>
                value === fee.fee_id || value === fee.expense_id || value === fee.storage_fee_id
            )

            if (objFee) {
              const feesTableNew = [...feesTable]
              feesTableNew[index] = { ...feesTableNew[index], ...objFee }
              setFeesTable([...feesTableNew])
            }
          }}
        >
          {record.fees &&
            record.fees.map((value) => (
              <Select.Option
                value={value.fee_id || value.expense_id || value.storage_fee_id}
                key={value._id}
              >
                {value.fee_name || value.expense_name}
              </Select.Option>
            ))}
        </Select>
      ),
    },
    {
      width: '20%',
      title: 'Đơn giá',
      render: (text, record, index) =>
        record.group && (
          <InputNumber
            style={{ width: '100%' }}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            onBlur={(e) => {
              const value = e.target.value.replaceAll(',', '')
              if (!value) {
                const feesTableNew = [...feesTable]
                feesTableNew[index].fee = 0
                feesTableNew[index].payment_value = 0
                setFeesTable([...feesTableNew])
              }
            }}
            onChange={(value) => {
              if (value) {
                const feesTableNew = [...feesTable]
                feesTableNew[index].fee = +value
                feesTableNew[index].payment_value = +value * +(record.unit_quantity || 0)
                setFeesTable([...feesTableNew])
              }
            }}
            value={record.fee || 0}
            placeholder="Nhập đơn giá"
            min={0}
          />
        ),
    },
    {
      width: '10%',
      title: 'Số lượng',
      render: (text, record, index) =>
        record.group && (
          <InputNumber
            style={{ width: '100%' }}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            onBlur={(e) => {
              const value = e.target.value.replaceAll(',', '')
              if (!value) {
                const feesTableNew = [...feesTable]
                feesTableNew[index].unit_quantity = 0
                feesTableNew[index].payment_value = 0
                setFeesTable([...feesTableNew])
              }
            }}
            onChange={(value) => {
              if (value) {
                const feesTableNew = [...feesTable]
                feesTableNew[index].unit_quantity = +value
                feesTableNew[index].payment_value = +value * +(record.fee || 0)
                setFeesTable([...feesTableNew])
              }
            }}
            value={record.unit_quantity || 0}
            placeholder="Nhập số lượng"
            min={0}
          />
        ),
    },
    {
      width: '15%',
      title: 'Thành tiền',
      render: (text, record, index) =>
        record.group && (
          <InputNumber
            style={{ width: '100%' }}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            onBlur={(e) => {
              const value = e.target.value.replaceAll(',', '')
              if (!value) {
                const feesTableNew = [...feesTable]
                feesTableNew[index].payment_value = 0
                setFeesTable([...feesTableNew])
              }
            }}
            onChange={(value) => {
              if (value) {
                const feesTableNew = [...feesTable]
                feesTableNew[index].payment_value = +value
                setFeesTable([...feesTableNew])
              }
            }}
            value={record.payment_value || 0}
            placeholder="Nhập thành tiền"
            min={0}
          />
        ),
    },
    {
      title: '',
      width: 35,
      render: (text, record, index) => (
        <Popconfirm
          onConfirm={() => {
            const feesTableNew = [...feesTable]
            feesTableNew.splice(index, 1)
            setFeesTable([...feesTableNew])
          }}
          title="Bạn có muốn xóa chi phí này không?"
        >
          <DeleteOutlined style={{ color: 'red', fontSize: 18, cursor: 'pointer' }} />
        </Popconfirm>
      ),
    },
  ]

  const handleDeleteFees = () => {
    const feesTableNew = [...feesTable]

    selectedRowsKey.map((key) => {
      const feeIndex = feesTableNew.findIndex((e) => e._id === key)
      if (feeIndex !== -1) feesTableNew.splice(feeIndex, 1)
    })

    setFeesTable([...feesTableNew])
  }

  const _getFees = async (type = '') => {
    try {
      if (type) {
        let res
        if (type === 'expense-fee') res = await getExpense({ get_all: true })
        if (type === 'storage-fee') res = await getStorageFees({ get_all: true })
        if (type === 'warehouse-fee') res = await getFees({ get_all: true })

        if (res.status === 200) if (res.data.success) return res.data.data
      }

      return []
    } catch (error) {
      console.log(error)
      return []
    }
  }

  const _getFeeDefault = async () => {
    try {
      setLoading(true)
      let res
      if (typeOrder === 'export') res = await getExportOrderFeeDefault({ order_id: orderId })
      else res = await getImportOrderFeeDefault({ order_id: orderId })
      setLoading(false)

      if (res.status === 200) {
        const data = [...res.data.data.expenses, ...res.data.data.fees]

        const feesPromise = data.map(async (e) => {
          const res = await _getFees(e.group)
          return { fees: [...res], _id: e._id }
        })

        const fees = await Promise.all(feesPromise)

        const feesNew = data.map((e) => {
          const feeFind = fees.find((fee) => fee._id === e._id)
          if (feeFind) return { ...e, fees: feeFind.fees }
          return { ...e, fees: [] }
        })

        setFeesTable([...feesNew])
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
      dispatch({ type: 'LOADING', data: false })
    }
  }

  const _getFeeGroup = async () => {
    try {
      const res = await enumFeeGroup({ get_all: true })
      if (res.status === 200) setFeeGroup(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const _getOrderDetail = async () => {
    try {
      dispatch({ type: 'LOADING', data: true })
      let res
      if (typeOrder === 'export') res = await getExportOrders({ order_id: orderId })
      else res = await getOrders({ order_id: orderId })
      dispatch({ type: 'LOADING', data: false })
      if (res.status === 200) {
        const order = res.data.data && res.data.data.length === 1 && res.data.data[0]
        if (order) setOrder(order)
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: 'LOADING', data: false })
    }
  }

  const _addFee = async () => {
    try {
      const body = {
        warehouse_id: order.warehouse_id,
        customer_id: order.customer_id,
        import_order_id: typeOrder === 'import' ? +orderId : null,
        export_order_id: typeOrder === 'export' ? +orderId : null,
        fee_infos: feesTable
          .filter((fee) => fee.group)
          .map((fee) => {
            delete fee.fees
            return {
              ...fee,
              group: fee.group,
              unit_quantity: fee.unit_quantity || 0,
              payment_value: fee.payment_value || 0,
            }
          }),
      }

      dispatch({ type: ACTION.LOADING, data: true })
      const res = await createFeeInOrder(body)
      dispatch({ type: ACTION.LOADING, data: false })

      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Thêm chi phí vào đơn hàng thành công!' })
          history.goBack()
        } else
          notification.error({ message: res.data.message || 'Thêm chi phí vào đơn hàng thất bại!' })
      } else
        notification.error({ message: res.data.message || 'Thêm chi phí vào đơn hàng thất bại!' })
    } catch (error) {
      _addLog(`Create or update other fee: ${JSON.stringify(error)}`)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  useEffect(() => {
    if (!orderId) history.goBack()
    else {
      _getOrderDetail()
      _getFeeDefault()
    }

    _getFeeGroup()
  }, [])

  return (
    <div>
      <Row className="page-title" justify="space-between">
        <h3 style={{ cursor: 'pointer' }} onClick={() => history.goBack()}>
          <ArrowLeftOutlined style={{ marginRight: '10px' }} />
          Thêm chi phí vào đơn hàng {typeOrder === 'import' ? 'nhập' : 'xuất'} <b>#{order.code}</b>
        </h3>
        <Space size="large">
          <Button
            type="primary"
            danger
            style={{ minWidth: '100px' }}
            onClick={() => history.goBack()}
          >
            Hủy
          </Button>

          <Button
            style={{ minWidth: '100px' }}
            onClick={_addFee}
            className={styles['storage-fee-add-button--orange']}
          >
            Thêm
          </Button>
        </Space>
      </Row>
      <Row gutter={30}>
        <Col span={24}>
          <br />
          <Row justify="space-between" align="middle">
            <b>Danh sách chi phí</b>
            <Space>
              {selectedRowsKey.length ? (
                <Popconfirm
                  onConfirm={handleDeleteFees}
                  title="Bạn có muốn xóa các chi phí đã chọn không?"
                >
                  <Button danger type="primary">
                    Xóa hàng loạt
                  </Button>
                </Popconfirm>
              ) : (
                ''
              )}

              <Button
                onClick={() => {
                  const feesTableNew = [...feesTable]
                  feesTableNew.unshift({ fees: [], group: '' })
                  setFeesTable([...feesTableNew])
                }}
                type="primary"
                style={{ marginBottom: 2 }}
              >
                Thêm chi phí
              </Button>
            </Space>
          </Row>
          <Table
            rowSelection={{
              selectedRowKeys: selectedRowsKey,
              onChange: (keys) => setSelectedRowsKey(keys),
            }}
            rowKey="_id"
            loading={loading}
            dataSource={feesTable}
            columns={columns}
            size="small"
            pagination={false}
          />
        </Col>
      </Row>
    </div>
  )
}
