import React, { useEffect, useState, useRef } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import {
  formatCash,
  _addLog,
  saveFiltersToLocalStorage,
  handleGetDataExportExcel,
  getFileName,
} from 'utils'
import { ACTION, PAGE_SIZE_OPTIONS, PERMISSIONS, ROUTES } from 'consts'
import styles from './positioninput.module.scss'
import { useHotkeys } from 'react-hotkeys-hook'

// antd
import {
  Col,
  Row,
  Button,
  Input,
  Select,
  Badge,
  Table,
  Typography,
  Modal,
  notification,
  Space,
  Popconfirm,
  Upload,
  Popover,
} from 'antd'
import { DeleteOutlined, InboxOutlined, LoadingOutlined, ReloadOutlined } from '@ant-design/icons'

//components
import SuffixIconCustom from 'components/suffixIconCustom'
import Export from 'components/export-csv'
import ModalFees from 'components/modal-fees'
import FilterDate from 'components/filter-date'
import ModalConfirmDelete from 'components/modal-confirm-delete'
import Permission from 'components/permission'

//apis
import { getAllCustomer } from 'apis/customer'
import { deleteImportOrders, getOrders, updateOrder } from 'apis/order'
import { getFees } from 'apis/fee'
import { uploadFile } from 'apis/upload'
import QrCodeProduct from 'components/qr-code-product'

const { Text } = Typography
export default function PositionInput() {
  const dispatch = useDispatch()
  const KEY_FILTER = 'positionInputFilters'
  const filters = localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))
  const typingTimeoutRef = useRef()
  const inputScan = useRef(null)

  const [orders, setOrders] = useState([])
  const [fees, setFees] = useState([])
  const [paramsFilter, setParamsFilter] = useState(
    filters
      ? { ...filters }
      : {
          page: 1,
          page_size: 10,
          is_imported: true,
          time_receive: new Date().getHours(),
          import_status: 'WAITING',
        }
  )
  const [valueSearch, setValueSearch] = useState(filters ? filters.search || '' : '')
  const [valueSearchCode, setValueSearchCode] = useState(filters ? filters.search || '' : '')

  const [customerData, setCustomerData] = useState([])
  const [countOrderOverTime, setCountOrderOverTime] = useState([])
  const [loading, setLoading] = useState(false)
  const [countOrder, setCountOrder] = useState(0)
  const history = useHistory()
  const [isScannerVisible, setIsScannerVisible] = useState(false)
  useHotkeys('f1', () => {
    if (inputScan && inputScan.current) inputScan.current.focus()
  })
  const indicators = [
    {
      title: 'Chờ nhập hàng',
      bgColor: '#FFCE62',
    },
    {
      title: 'Đang nhập hàng',
      bgColor: '#13D8E5',
    },
    {
      title: 'Đã nhập hàng',
      bgColor: '#79CF4F',
    },
    {
      title: 'Có sự cố',
      bgColor: '#D63F3F',
    },
  ]

  const columns = [
    {
      dataIndex: 'order_id',
      render: (data, record) => {
        return (
          <Row>
            <Col>
              <Row style={{ fontWeight: 500 }}>
                <Text
                  style={{
                    color: record?.import_status !== 'COMPLETE' && '#0017E3',
                    cursor: record?.import_status !== 'COMPLETE' && 'pointer',
                  }}
                  onClick={() => {
                    // if (record?.import_status !== 'COMPLETE') {
                    //   _handleCheckStatus(record)

                    // }
                    history.push({ pathname: ROUTES.PRODUCT_IMPORT_DETAIL, state: record.order_id })
                  }}
                >
                  #{record?.code ? record?.code : record?.order_id}
                </Text>
              </Row>
              <Row>Số phiếu: #{record.customer_order_code}</Row>
              <Row>Doanh nghiệp: {record?.customer_info?.fullname}</Row>
              <Row>
                <BillOrder record={record} />
              </Row>
            </Col>
          </Row>
        )
      },
      width: '35%',
    },
    {
      dataIndex: 'employee_name',
      render: (data, record) => {
        return (
          <Row justify="start" style={{ alignItems: 'flex-end' }}>
            <Col>
              <Row>
                <QrCodeProduct value={record.code} />
              </Row>
              <Row style={{ display: record?.import_status !== 'WAITING' ? 'flex' : 'none' }}>
                <div>Nhập vào vị trí: {record?.import_employee_info?.fullname}</div>
              </Row>
              <Row>
                <Button
                  onClick={() =>
                    history.push({ pathname: ROUTES.PRODUCT_IMPORT_DETAIL, state: record.order_id })
                  }
                  type="primary"
                  style={{ backgroundColor: '#23A6A6', borderColor: '#23A6A6' }}
                >
                  {record?.import_status === 'COMPLETE' ? 'Xem đơn hàng' : 'Nhập hàng vào vị trí'}
                </Button>
              </Row>
            </Col>
          </Row>
        )
      },
      width: '30%',
      align: 'center',
    },
    {
      dataIndex: 'create_date',
      render: (data, record) => {
        return (
          <Row justify="end">
            <Col>
              <Row justify="end" style={{ fontWeight: 500 }}>
                Yêu cầu nhập kho lúc {moment(record?.create_date).format(' HH:mm')} ngày{' '}
                {moment(record?.create_date).format('DD/MM/YYYY')}
              </Row>
              {/* <Row justify="end">
                Số lượng yêu cầu nhập kho: {formatCash(record?.products?.length)}
              </Row> */}
              <Row justify="end">
                <Space>
                  {/* <ModalFees
                    fees={fees}
                    record={record}
                    reload={() => _getOrders()}
                    status={record.import_status}
                  /> */}
                  <Permission permissions={[PERMISSIONS.xoa_don_hang_nhap]}>
                    <ModalConfirmDelete
                      record={record}
                      onDelete={(note) => _deleteImport(record.order_id, note)}
                    >
                      <Button type="primary" icon={<DeleteOutlined />} danger />
                    </ModalConfirmDelete>
                  </Permission>
                </Space>
              </Row>
            </Col>
          </Row>
        )
      },
      width: '35%',
    },
  ]

  const _handleCheckStatus = async (order) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getOrders({ order_id: order.order_id })
      dispatch({ type: ACTION.LOADING, data: false })
      if (res.status === 200) {
        if (res.data.success) {
          const order = res.data.data && res.data.data.length === 1 && res.data.data[0]
          if (order) {
            if (order.import_status === 'WAITING') _handleInputDetail(order)
            else {
              order.import_status === 'PROCESSING'
                ? confirm(order)
                : order.import_status === 'REPORT' && _handleInputDetail(order, 'REPORT')
            }
          }
        }
      }
    } catch (error) {
      console.log(error)
      _addLog(`check status: ${JSON.stringify(error)}`)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const _deleteImport = async (id, note = '') => {
    try {
      setLoading(true)
      const res = await deleteImportOrders({ order_id: [id], note: note })
      setLoading(false)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Xóa đơn hàng thành công' })
          _getOrders()
        } else notification.error({ message: res.data.message || 'Xóa đơn hàng không thành công' })
      } else notification.error({ message: res.data.message || 'Xóa đơn hàng không thành công' })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const _getOrderScan = async (query = {}) => {
    try {
      setLoading(true)
      const res = await getOrders({ ...query })
      setLoading(false)

      if (res.status === 200) {
        const order = res.data.data && res.data.data.length === 1 && res.data.data[0]
        if (order) history.push({ pathname: ROUTES.PRODUCT_IMPORT_DETAIL, state: order.order_id })
        else
          notification.error({
            message: 'Không tìm thấy đơn hàng này!',
            duration: 5,
            key: 'scan-order',
          })
      }
      setValueSearch('')
    } catch (error) {
      _addLog(JSON.stringify(error))
      setLoading(false)
      console.log(error)
    }
  }

  const _getOrders = async () => {
    try {
      setLoading(true)
      const res = await getOrders(paramsFilter)
      if (res.status === 200) {
        if (res.data.success) {
          setCountOrder(res.data.count)
          setCountOrderOverTime(res.data.quantity_overtime)
          setOrders(res.data.data)
        }
      }
      setLoading(false)
    } catch (error) {
      _addLog(JSON.stringify(error))
      setLoading(false)
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _getCustomers = async () => {
    try {
      const res = await getAllCustomer()
      if (res.status === 200) {
        setCustomerData(res.data.data)
      }
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _getFees = async () => {
    try {
      const res = await getFees()
      if (res.status === 200) setFees(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _handleInputDetail = async (item, status = 'PROCESSING') => {
    const data = { import_status: status }
    dispatch({ type: ACTION.LOADING, data: true })
    try {
      const res = await updateOrder(item.order_id, data)
      if (res.status === 200) {
        history.push({ pathname: ROUTES.PRODUCT_IMPORT_DETAIL, state: item.order_id })
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
      _addLog(JSON.stringify(error))
    }
  }

  const confirm = (record) => {
    Modal.confirm({
      title: 'Đơn hàng này đang được thực hiện bởi người khác, bạn có muốn thao tác không ?',
      cancelText: 'Không',
      okText: 'Có',
      onOk: () => {
        _handleInputDetail(record)
      },
    })
  }

  const _onSearchCode = (e) => {
    setValueSearchCode(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value.replace('#', '')
      if (value) paramsFilter.search = value
      else delete paramsFilter.search

      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 650)
  }

  const _onSearch = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value.replace('#', '')
      _getOrderScan({ search: value })
    }, 650)
  }

  const _filterByOption = (attribute = '', value = '') => {
    paramsFilter.page = 1

    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter })
  }

  const _clearFilters = (tabReceive) => {
    Object.keys(paramsFilter).map((key) => delete paramsFilter[key])
    setValueSearch('')
    paramsFilter.page = 1
    paramsFilter.page_size = 20
    paramsFilter.time_receive = tabReceive
    paramsFilter.is_imported = true
    paramsFilter.import_status = 'WAITING'
    setParamsFilter({ ...paramsFilter })
  }

  const _updateOrder = async (id, body) => {
    try {
      setLoading(true)
      const res = await updateOrder(id, body)
      setLoading(false)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Cập nhật thành công' })
          _getOrders()
        } else
          notification.error({ message: res.data.message || 'Cập nhật thất bại, vui lòng thử lại' })
      } else
        notification.error({ message: res.data.message || 'Cập nhật thất bại, vui lòng thử lại' })
    } catch (error) {
      setLoading(false)
      console.log(error)
      _addLog(`Update order: ${JSON.stringify(error)}`)
    }
  }

  const BillOrder = ({ record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    const shippingBill =
      record && record.shipping_info
        ? record.shipping_info.shipping_employee_signature &&
          typeof record.shipping_info.shipping_employee_signature === 'string'
          ? [record.shipping_info.shipping_employee_signature]
          : record.shipping_info.shipping_employee_signature || []
        : []

    const [images, setImages] = useState([])
    const [imagesView, setImagesView] = useState([])
    const [loading, setLoading] = useState(false)

    const addFile = async (file) => {
      setLoading(true)
      const url = await uploadFile(file)
      setImages([url])
      const fileNames = url.split('/')
      const fileName = fileNames[fileNames.length - 1]
      setImagesView([
        { uid: imagesView.length, name: fileName, status: 'done', url: url, thumbUrl: url },
      ])
      setLoading(false)
    }

    const _removeFile = (file) => {
      const imagesNew = [...images]
      const imagesViewNew = [...imagesView]

      const indexImage = images.findIndex((url) => url === file.url)
      const indexImageView = imagesView.findIndex((f) => f.url === file.url)

      if (indexImage !== -1) imagesNew.splice(indexImage, 1)
      if (indexImageView !== -1) imagesViewNew.splice(indexImageView, 1)

      setImages([...imagesNew])
      setImagesView([...imagesViewNew])
    }

    useEffect(() => {
      if (visible) {
        setImages(shippingBill)
        setImagesView(
          shippingBill.map((image, index) => ({
            uid: index,
            name: getFileName(image),
            status: 'done',
            url: image,
            thumbUrl: image,
          }))
        )
      }
    }, [visible])

    return (
      <>
        <Modal
          footer={
            <Row justify="end">
              <Space>
                <Button style={{ minWidth: 100 }} onClick={toggle}>
                  Đóng
                </Button>
                <Button
                  onClick={async () => {
                    const body = {
                      ...record,
                      shipping_info: {
                        ...record.shipping_info,
                        shipping_employee_signature: images.length ? images[0] : '',
                      },
                    }
                    await _updateOrder(record.order_id, body)
                    toggle()
                  }}
                  style={{ minWidth: 100 }}
                  type="primary"
                >
                  Lưu
                </Button>
              </Space>
            </Row>
          }
          width={700}
          onCancel={toggle}
          title="Tải lên hình ảnh biên lai"
          visible={visible}
        >
          <Upload.Dragger
            accept="image/*"
            fileList={imagesView}
            listType="picture"
            data={addFile}
            onRemove={_removeFile}
            name="file"
            onChange={(info) => {
              if (info.file.status !== 'done') info.file.status = 'done'
            }}
          >
            <p className="ant-upload-drag-icon">
              {loading ? <LoadingOutlined /> : <InboxOutlined />}
            </p>
            <p className="ant-upload-text">Nhấp hoặc kéo thả vào khu vực này để tải lên</p>
          </Upload.Dragger>
        </Modal>

        <Space>
          <div>
            <Permission permissions={[PERMISSIONS.cap_nhat_danh_sach_xuat_hang]}>
              <Button
                type="primary"
                onClick={toggle}
                style={{ backgroundColor: '#377BFF', borderColor: '#377BFF' }}
              >
                Hình ảnh biên lai
              </Button>
            </Permission>
          </div>
          <div>
            {shippingBill && shippingBill.length ? (
              <Popover
                placement="right"
                content={
                  <img src={shippingBill[0]} style={{ height: '250px', width: '250px' }} alt="" />
                }
              >
                <img src={shippingBill[0]} style={{ height: '30px', width: '30px' }} alt="" />
              </Popover>
            ) : (
              ''
            )}
          </div>
        </Space>
      </>
    )
  }

  const _getOrderExport = async (query = {}) => {
    try {
      const data = await handleGetDataExportExcel(getOrders, { ...query })
      if (data === 200) {
        const dataExport = data.map((order, index) => {
          let order_template = {
            STT: index + 1,
            'Kho hàng': order?.warehouse_info?.code || order?.warehouse_info?.name || '',
            'Mã kho hàng': order?.warehouse_info?.code || '',
            'Mã đơn hàng': order?.code || order?.order_id || '',
            'Số lượng yêu cầu nhập kho': order?.products && order?.products?.length,
            'Khách hàng': order?.customer_info && order?.customer_info?.fullname,
            'Mã khách hàng': order?.customer_info && order?.customer_info?.code,
            'Nhân viên nhập hàng':
              order?.import_employee_info && order?.import_employee_info?.fullname,
            'Trạng thái đơn hàng':
              order?.import_status &&
              ((order?.import_status === 'WAITING' && 'Chờ nhập hàng') ||
                (order?.import_status === 'PROCESSING' && 'Đang nhập hàng') ||
                (order?.import_status === 'COMPLETE' && 'Đã nhập hàng') ||
                (order?.import_status === 'REPORT' && 'Có sự cố')),
            'Yêu cầu nhập kho ': order?.create_date
              ? moment(order?.create_date).format('DD-MM-YYYY HH:mm:ss')
              : '',
          }
          console.log('order_template', order_template)
          return order.products.length
            ? order.products.map((item) => {
                return {
                  ...order_template,
                  'Tên sản phẩm': item.name || '',
                  'Mã sản phẩm': item.barcode || item.code || '',
                  'Nhóm sản phẩm': item.group_id || '',
                  'Loại sản phẩm': item.type_id || '',
                  'Chiều dài': item.length || '',
                  'Chiều rộng': item.width || '',
                  'Chiều cao': item.height || '',
                  'Khối lượng': item.cbm || '',
                  'Số lượng': item.import_quantity || '',
                  'Quy cách': item.unit_info ? item.unit_info.name : '',
                  'Giá trị': formatCash(item.price || 0),
                  'Hạn sử dụng':
                    item.expiry_info && item.expiry_info.exp
                      ? moment(item.expiry_info.exp).format('DD-MM-YYYY HH:mm')
                      : '',
                  'Ngày sản xuất':
                    item.expiry_info && item.expiry_info.mfg
                      ? moment(item.expiry_info.mfg).format('DD-MM-YYYY HH:mm')
                      : '',
                  'Ghi chú': item.note || '',
                }
              })
            : {
                ...order_template,
                'Tên sản phẩm': '',
                'Mã sản phẩm': '',
                'Nhóm sản phẩm': '',
                'Loại sản phẩm': '',
                'Chiều dài': '',
                'Chiều rộng': '',
                'Chiều cao': '',
                'Khối lượng': '',
                'Số lượng': '',
                'Quy cách': '',
                'Giá trị': '',
                'Hạn sử dụng': '',
                'Ngày sản xuất': '',
                'Ghi chú': '',
              }
        })
        return dataExport.flat(2)
      }
      return []
    } catch (error) {
      console.log(error)
      _addLog(`export excel export order: ${JSON.stringify(error)}`)
    }
  }

  const _onChangeTable = (value) => {
    delete paramsFilter.time_receive
    paramsFilter.time_receive = parseInt(value)
    paramsFilter.page = 1
    setParamsFilter({ ...paramsFilter })
  }

  useEffect(() => {
    _getOrders()
    saveFiltersToLocalStorage(paramsFilter, KEY_FILTER)
  }, [paramsFilter])

  useEffect(() => {
    _getCustomers()
    _getFees()
  }, [])

  return (
    <div className={styles['product-import']}>
      <Row className="page-title" justify="space-between" style={{ marginBottom: '25px' }}>
        <h3>Danh sách đơn hàng đợi nhập vị trí</h3>
        <h3 style={{ fontWeight: 600 }}>
          {isScannerVisible ? 'Bắt đầu quét mã đơn hàng' : 'Máy quét đang tắt (F1 để bật máy quét)'}
        </h3>

        <Permission permissions={[PERMISSIONS.tao_phieu_nhap_kho_thu_cong]}>
          <Link to={ROUTES.IMPORT_WAREHOUSE_CREATE}>
            <Button style={{ backgroundColor: '#377BFF', borderColor: '#377BFF' }} type="primary">
              Thêm phiếu nhập kho thủ công
            </Button>
          </Link>
        </Permission>
      </Row>
      <Row gutter={[20, 15]} style={{ marginBottom: 20 }}>
        <Col span={7}>
          <Input.Search
            ref={inputScan}
            autoFocus
            allowClear
            enterButton
            value={valueSearch}
            onChange={_onSearch}
            onFocus={() => setIsScannerVisible(true)}
            onBlur={() => setIsScannerVisible(false)}
            placeholder="Nhấn vào đây để quét mã tìm kiếm đơn hàng (F1)"
            style={{ width: '100%', boxShadow: '0px 2px 6px rgba(19, 216, 229, 0.13)' }}
          />
        </Col>
        <Col span={5}>
          <Select
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
            }
            style={{ width: '100%' }}
            value={paramsFilter['customer_id'] || undefined}
            onChange={(value) => _filterByOption('customer_id', value)}
            placeholder="Lọc theo doanh nghiệp"
            suffixIcon={<SuffixIconCustom size="default" />}
          >
            {customerData.map((item, index) => (
              <Select.Option key={index} value={item?.customer_id}>
                {item?.fullname}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={5}>
          <Select
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
            }
            style={{ width: '100%' }}
            placeholder="Lọc theo trạng thái"
            value={paramsFilter['import_status'] || ''}
            onChange={(value) => _filterByOption('import_status', value)}
            suffixIcon={<SuffixIconCustom size="default" />}
          >
            <Select.Option value="">Tất cả</Select.Option>
            <Select.Option value="WAITING">Chờ nhập vào vị trí</Select.Option>
            <Select.Option value="PROCESSING">Đang nhập vào vị trí</Select.Option>
            <Select.Option value="COMPLETE">Đã nhập vào vị trí</Select.Option>
            <Select.Option value="REPORT">Có sự cố</Select.Option>
          </Select>
        </Col>
        <Col span={5}>
          <FilterDate paramsFilter={paramsFilter} setParamsFilter={setParamsFilter} />
        </Col>
        <Col span={7}>
          <Input.Search
            allowClear
            value={valueSearchCode}
            onChange={_onSearchCode}
            placeholder="Tìm kiếm đơn hàng theo mã"
            style={{ width: '100%', boxShadow: '0px 2px 6px rgba(19, 216, 229, 0.13)' }}
          />
        </Col>
        <Col span={2}>
          <Button
            className={styles['btn-clear']}
            onClick={() => _clearFilters(paramsFilter.time_receive)}
            type="primary"
            style={{
              display: Object.keys(paramsFilter).length <= 5 && 'none',
            }}
          >
            Xóa bộ lọc
          </Button>
        </Col>
      </Row>
      <Row justify="space-between">
        <div style={{ display: 'flex', gap: '10px', margin: '10px 0 0 10px' }}>
          {indicators.map((indicator, index) => {
            return (
              <div key={index} style={{ display: 'flex', gap: '5px' }}>
                <div
                  style={{
                    width: '15px',
                    height: '15px',
                    borderRadius: '50%',
                    backgroundColor: indicator?.bgColor,
                  }}
                ></div>
                <span style={{ lineHeight: '15px' }}>{indicator?.title}</span>
              </div>
            )
          })}
        </div>
        <Space style={{ marginBottom: 10 }}>
          <Export
            text="Xuất theo bộ lọc"
            actionName="Xuất excel danh sách đơn hàng đợi nhập vào vị trí"
            fileName="Danh sách đơn hàng nhập hàng"
            exportData={() => _getOrderExport(paramsFilter)}
          />
          <Export
            text="Xuất toàn bộ"
            actionName="Xuất excel danh sách đơn hàng đợi nhập vào vị trí"
            fileName="Danh sách đơn hàng nhập hàng"
            exportData={() => _getOrderExport()}
          />
          <Button onClick={_getOrders} type="primary" icon={<ReloadOutlined />}>
            Làm mới
          </Button>
        </Space>
      </Row>
      <Row>
        <div className={styles['product-import-content']}>
          <Row>
            {countOrderOverTime.map((item, index) => (
              <Col span={2} style={{ margin: '10px 0', textAlign: 'center' }} key={index}>
                <Badge count={item.order_quantity} overflowCount="none">
                  <Button
                    type={index === paramsFilter.time_receive ? 'primary' : ''}
                    onClick={() => _onChangeTable(index)}
                  >
                    {item?.time_receive}
                  </Button>
                </Badge>
              </Col>
            ))}
          </Row>
          <Table
            loading={loading}
            size="small"
            showHeader={false}
            columns={columns}
            rowClassName={(record) => styles[record?.import_status]}
            dataSource={orders}
            pagination={{
              current: paramsFilter.page,
              pageSize: paramsFilter.page_size,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              showQuickJumper: true,
              onChange: (page, pageSize) => {
                paramsFilter.page = page
                paramsFilter.page_size = pageSize
                setParamsFilter({ ...paramsFilter })
              },
              total: countOrder,
            }}
          />
        </div>
      </Row>
    </div>
  )
}
