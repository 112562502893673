import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ROUTES } from 'consts'
import { formatCash, _addLog } from 'utils'

//antd
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  notification,
  Table,
  InputNumber,
  Space,
  List,
  Switch,
} from 'antd'
import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons'

// apis
import { getExportOrders, updateExportOrders, scanExportOrder } from 'apis/export-order'
import { getProducts } from 'apis/product'

export default function ExportScanProduct() {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const formRef = useRef(null)

  const [scanItem, setScanItem] = useState([])
  const [isHiddenInputScan, setIsHiddenInputScan] = useState(false)
  const { order_id, order_code, bucket, filters } = location.state || {}

  const _getOrderFromID = async (order_id) => {
    try {
      const res = await getExportOrders({ order_id })
      if (res.data.success) return res.data.data[0]
      return res.data.message
    } catch (err) {
      _addLog(JSON.stringify(err))
      console.log(err)
    }
  }
  /*
   *  Hàm getOrderStatus trả về status của đơn hàng
   *  - COMPLETE khi đã quét hết sản phẩm
   *  - WAITING khi còn đang thực hiện
   *
   */
  const _getOrderStatus = (value) => {
    let tmp = { ...value }
    console.log(tmp)
    let isComplete = true
    Object.keys(tmp).forEach((e) => {
      if (tmp[e].export_quantity < tmp[e].require_quantity) {
        isComplete = false
      }
    })
    if (!isComplete) {
      return { export_status: 'WAITING' }
    } else {
      return { export_status: 'COMPLETE', processing: 'is_exported' }
    }
  }

  const _removeProduct = (productId) => {
    let tmp = [...scanItem]
    tmp.splice(scanItem.map((e) => e.product_id).indexOf(productId), 1)
    setScanItem(tmp)
    formRef.current.focus()
  }
  const columns = [
    {
      title: 'STT',
      align: 'center',
      width: 50,
      render: (data, record, index) => index + 1,
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: 'Nhóm hàng',
      dataIndex: 'group_info',
      align: 'center',
      key: 'group',
      render: (data) => data?.name || '',
    },
    {
      title: 'Loại hàng',
      dataIndex: 'type_info',
      key: 'type',
      align: 'center',
      render: (data) => data?.name || '',
    },
    {
      title: 'Mã hàng/Barcode',
      dataIndex: 'barcode',
      align: 'center',
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'images',
      align: 'center',
      render: (images, record) =>
        images?.length && <img src={images[0]} alt="" style={{ width: 50, height: 50 }} />,
    },
    {
      title: 'Giá tiền',
      dataIndex: 'price',
      key: 'price',
      align: 'center',
      render: (data) => formatCash(data) + ' VND',
    },
    {
      title: 'Cbm (cm³)',
      dataIndex: 'cbm',
      align: 'center',
      render: (data) => formatCash(data),
    },
    {
      title: 'D.Tích mặt phẳng',
      dataIndex: 'area',
      align: 'center',
      render: (data) => formatCash(data),
    },
    {
      title: 'Quy cách',
      dataIndex: 'unit_info',
      key: 'unit_info',
      align: 'center',
      render: (data) => data?.name || '',
    },
    {
      title: 'Ô kệ',
      align: 'center',
      render: () => location.state && location.state.bucket && location.state.bucket.hotname,
    },
    {
      title: 'Số lượng yêu cầu xuất',
      align: 'center',
      render: (text, record) => formatCash(record.require_quantity || 0),
    },
    {
      title: 'Số lượng đã xuất',
      align: 'center',
      render: (text, record) => formatCash(record.export_quantity || 0),
    },
    {
      title: 'Số lượng xuất kho',
      key: 'quantity',
      align: 'center',
    },
    {
      key: 'action',
      align: 'center',
      render(data) {
        return (
          <DeleteOutlined
            style={{ color: 'red', cursor: 'pointer' }}
            onClick={() => _removeProduct(data.product_id)}
          />
        )
      },
    },
  ]
  const _onHandleQuantityProduct = async (value, product) => {
    const valueNew = value.replaceAll(',', '')
    const indexProduct = scanItem.findIndex((p) => p.product_id === product.product_id)
    if (indexProduct !== -1) {
      scanItem[indexProduct].export_quantity = +valueNew
      //await _onHandleImport(bucketScan, scanItem) cập nhật real time
    }
    setScanItem([...scanItem])
    _reScan()
  }

  const _esc = () => {
    history.push({ pathname: ROUTES.PRODUCT_EXPORT, state: location.state?.filters })
  }

  const _reScan = () => {
    formRef.current.focus()
    form.resetFields()
  }

  const _onClickConfirm = async () => {
    form.resetFields()
    formRef.current?.focus()

    try {
      dispatch({ type: 'LOADING', data: true })
      const order = await _getOrderFromID(order_id)

      if (order.order_id) {
        let tmp = order.products.reduce((a, b) => ((a[b.product_id] = b), a), {})
        for (let i = 0; i < scanItem.length; i++) {
          if (tmp[scanItem[i].product_id]) {
            if (
              tmp[scanItem[i].product_id].export_quantity + parseInt(scanItem[i].export_quantity) >
              tmp[scanItem[i].product_id].require_quantity
            ) {
              notification.warning({
                message: 'Số lượng sản phẩm xuất đang nhiều hơn số lượng yêu cầu',
              })
              dispatch({ type: 'LOADING', data: false })
              return
            }
            // else if (
            //   tmp[scanItem[i].product_id].export_quantity + parseInt(scanItem[i].export_quantity) ==
            //   tmp[scanItem[i].product_id].require_quantity
            // ) {
            //   console.log('tổng số lượng sản phẩm bằng số lượng yêu cầu');
            //   console.log('tổng', tmp[scanItem[i].product_id].export_quantity + parseInt(scanItem[i].export_quantity));
            //   console.log('yêu cầu', tmp[scanItem[i].product_id].require_quantity);
            // }
            else {
              tmp[scanItem[i].product_id] = {
                ...tmp[scanItem[i].product_id],
                export_quantity:
                  tmp[scanItem[i].product_id].export_quantity +
                  parseInt(scanItem[i].export_quantity),
              }
            }
          } else {
            notification.error({ message: 'Sản phẩm không tồn tại trong đơn hàng' })
            dispatch({ type: 'LOADING', data: false })
            return
          }
        }

        const body = {
          order_id: order_id,
          warehouse_id: bucket.warehouse_id,
          bucket_id: bucket.bucket_id,
          scans: scanItem.map((e) => ({
            product_id: e.product_id,
            export_quantity: +e.export_quantity,
          })),
        }
        const res = await scanExportOrder(body)
        console.log(res)
        if (res.status === 200) {
          if (res.data.success) {
            notification.success({ message: 'Xác nhận quét sản phẩm thành công' })
            history.push({ pathname: ROUTES.PRODUCT_EXPORT, state: { filters } })
            // const ordernew = await _getOrderFromID()
            // console.log('ordernew', ordernew);
          } else
            notification.error({
              message: res.data.message || 'Xác nhận quét sản phẩm thất bại, vui lòng thử lại!',
            })
        } else
          notification.error({
            message: res.data.message || 'Xác nhận quét sản phẩm thất bại, vui lòng thử lại!',
          })
      }
      dispatch({ type: 'LOADING', data: false })
    } catch (err) {
      form.resetFields()
      formRef.current?.focus()
      dispatch({ type: 'LOADING', data: false })
      console.log(err)
    }
  }

  const _getProductFromBarcode = async (barcode) => {
    try {
      const res = await getProducts({ barcode, customer_id: location.state.record.customer_id })
      if (res.status === 200) if (res.data.data && res.data.data.length) return res.data.data

      return null
    } catch (err) {
      _addLog(`Confirm scan products: ${JSON.stringify(err)}`)
      console.log(err)
      return null
    }
  }

  const _onFinish = async (value) => {
    form.resetFields()
    formRef.current.focus()

    try {
      if (!value.barcode) return

      dispatch({ type: 'LOADING', data: true })

      // lấy thông tin sản phẩm
      const products = await _getProductFromBarcode(value.barcode)
      if (!products) {
        notification.warning({ message: 'Không tìm thấy sản phẩm này' })
        dispatch({ type: 'LOADING', data: false })
        return
      }

      const productsInRecord = (location.state.record && location.state.record.products) || []

      const productInOrder = productsInRecord.find((p) => p.barcode == value.barcode)

      if (!productInOrder) {
        notification.warning({ message: 'Sản phẩm này không nằm trong đơn hàng cần xuất vị trí' })
        dispatch({ type: 'LOADING', data: false })
        return
      }

      const productIndex = scanItem.findIndex((e) => e.barcode == productInOrder.barcode)
      if (productIndex !== -1) {
        scanItem[productIndex].export_quantity += 1
        setScanItem([...scanItem])
      } else setScanItem([...scanItem, { ...productInOrder, export_quantity: 1 }])

      dispatch({ type: 'LOADING', data: false })
    } catch (err) {
      _addLog(JSON.stringify(err))
      dispatch({ type: 'LOADING', data: false })
      console.log(err)
    }
  }
  useEffect(() => {
    formRef.current.focus()
  }, [scanItem])

  useEffect(() => {
    if (!location.state) {
      history.push(ROUTES.PRODUCT_EXPORT)
    }
  }, [])
  return (
    <div>
      <Row className="page-title" justify="space-between" style={{ marginBottom: '25px' }}>
        <h3 onClick={() => history.push(ROUTES.PRODUCT_EXPORT)} style={{ cursor: 'pointer' }}>
          <ArrowLeftOutlined /> Quét mã sản phẩm của đơn hàng #{order_code}
        </h3>
        <Space size="large">
          {/* <Button
            type="primary"
            onClick={() => {
              history.push({
                pathname: ROUTES.PRODUCT_EXPORT_SCAN_BUCKET,
                state: {
                  type: 'bucket',
                  order_id: location.state.order_id,
                  order_code: location.state.code,
                  order_bucket: bucketsList[record.order_id],
                  order_status: record.export_status,
                  processing: record.processing,
                  buckets: orderBuckets || [],
                  filters,
                },
              })
            }}
          >
            Đóng gói và quét ô kệ tiếp theo (F2)
          </Button> */}
          <div style={{ display: 'flex' }}>
            <div>Nhập mã thủ công</div>
            <Switch
              checked={isHiddenInputScan}
              onChange={(value) => {
                formRef.current.focus()
                form.resetFields()
                setIsHiddenInputScan(value)
              }}
              style={{ marginLeft: 5 }}
            />
          </div>
        </Space>
      </Row>
      <div>
        <Row justify="space-between">
          <Col>
            <h3 style={{ color: 'red', fontWeight: 'bold' }}>Bắt đầu quét mã sản phẩm...</h3>
          </Col>
          <Col span={8}>
            <Form onFinish={_onFinish} form={form} layout="vertical">
              <Form.Item
                name="barcode"
                label="Nhập mã sản phẩm và enter"
                style={{ opacity: !isHiddenInputScan && 0 }}
              >
                <Input
                  onKeyDown={(e) => {
                    if (e.key === 'Escape') _esc()
                    if (e.key === ' ' && scanItem.length) _onClickConfirm()
                  }}
                  autoFocus
                  ref={formRef}
                  style={{ width: '100%' }}
                  placeholder="Nhập mã sản phẩm và enter"
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>

        {/* <List
          size="small"
          style={{ maxWidth: 300 }}
          header={<h4>Danh sách ô kệ cần quét</h4>}
          bordered
          dataSource={location.state ? location.state.buckets : []}
          renderItem={(item) => <List.Item>{item.hotname}</List.Item>}
        /> */}

        <Row justify="center">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="barcode-read"
            role="img"
            width="300"
            style={{ color: '#f5f5f5' }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
            class="svg-inline--fa fa-barcode-read fa-w-20"
          >
            <path
              fill="currentColor"
              d="M184 128h-48c-4.4 0-8 3.6-8 8v240c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V136c0-4.4-3.6-8-8-8zm-40 320H64v-80c0-8.8-7.2-16-16-16H16c-8.8 0-16 7.2-16 16v128c0 8.8 7.2 16 16 16h128c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm104-320h-16c-4.4 0-8 3.6-8 8v240c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V136c0-4.4-3.6-8-8-8zM16 160h32c8.8 0 16-7.2 16-16V64h80c8.8 0 16-7.2 16-16V16c0-8.8-7.2-16-16-16H16C7.2 0 0 7.2 0 16v128c0 8.8 7.2 16 16 16zm392-32h-48c-4.4 0-8 3.6-8 8v240c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V136c0-4.4-3.6-8-8-8zm-96 0h-16c-4.4 0-8 3.6-8 8v240c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V136c0-4.4-3.6-8-8-8zM624 0H496c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h80v80c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V16c0-8.8-7.2-16-16-16zm0 352h-32c-8.8 0-16 7.2-16 16v80h-80c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h128c8.8 0 16-7.2 16-16V368c0-8.8-7.2-16-16-16zm-112 24V136c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v240c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8z"
              class=""
            ></path>
          </svg>
        </Row>

        <Table
          size="small"
          style={{ margin: '20px 0' }}
          pagination={false}
          columns={columns.map((e) => {
            if (e.key === 'quantity')
              return {
                ...e,
                render(data, record, index) {
                  return (
                    <InputNumber
                      style={{ width: '50%' }}
                      value={record.export_quantity}
                      min={1}
                      max={record.require_quantity}
                      onBlur={(e) => _onHandleQuantityProduct(e.target.value, record)}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    />
                  )
                },
              }
            return e
          })}
          dataSource={scanItem}
        />

        <Row justify="end">
          <Col>
            <Space>
              <Button type="primary" size="large" danger onClick={_esc}>
                Đóng (esc)
              </Button>
              <Button
                disabled={scanItem.length ? false : true}
                type="primary"
                size="large"
                onClick={_onClickConfirm}
              >
                Xác nhận (space)
              </Button>
            </Space>
          </Col>
        </Row>
      </div>
    </div>
  )
}
