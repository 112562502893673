import React, { useEffect } from 'react'
import styles from './login.module.scss'
import { Button, Checkbox, Form, Input, notification } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ACTION, ROUTES, TYPE_DEPLOY, VERSION_APP } from 'consts'

import { login } from 'apis/auth'
import { _addLog } from 'utils'

export default function Login() {
  const history = useHistory()
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const _login = async (dataForm) => {
    await form.validateFields()

    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await login(dataForm)
      if (res.status === 200) {
        if (res.data.success) {
          dispatch({ type: ACTION.LOGIN, data: res.data.data })
          history.push(ROUTES.DASHBOARD)
        } else
          notification.error({
            message: res.data.message || 'Đăng nhập thất bại, vui lòng thử lại!!',
          })
      } else
        notification.error({
          message: res.data.message || 'Đăng nhập thất bại, vui lòng thử lại!!',
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
      _addLog(`Login: ${JSON.stringify(error)}`)
    }
  }

  useEffect(() => {
    if (localStorage.getItem('accessToken')) history.push(ROUTES.DASHBOARD)
  }, [])

  return (
    <div className={styles['login']}>
      <img
        src="https://admin-order.s3.ap-northeast-1.wasabisys.com/2021/11/03/3eb89c6b-8d3c-4fed-b4b8-e7e5aefb7888/627%20%5BConverted%5D-01%201.png"
        alt=""
      />
      <div className={styles['login-form']}>
        <h3 align="center">
          {process.env.REACT_APP_API_ENDPOINT_DEV === 'https://api-sgl.cpos.vn/api'
            ? 'SAND BOX'
            : 'LIVE'}
        </h3>
        <h2 align="center">Đăng nhập</h2>
        <Form form={form} onFinish={_login} layout="vertical" requiredMark={false}>
          <Form.Item
            name="username"
            label="Tên tài khoản"
            rules={[{ required: true, message: 'Vui lòng nhập tài khoản' }]}
          >
            <Input
              autoFocus
              size="large"
              placeholder="Nhập tài khoản"
              prefix={<UserOutlined />}
              style={{ borderRadius: 5 }}
            />
          </Form.Item>
          <Form.Item
            name="password"
            label="Mật khẩu"
            rules={[{ required: true, message: 'Vui lòng nhập mật khẩu' }]}
          >
            <Input.Password
              size="large"
              placeholder="Nhập mật khẩu"
              prefix={<LockOutlined />}
              style={{ borderRadius: 5 }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              style={{
                backgroundColor: 'black',
                borderColor: 'black',
                marginTop: 20,
                width: '100%',
              }}
            >
              Đăng nhập
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className={styles['login-footer']}>Version: {VERSION_APP}</div>
    </div>
  )
}
