import { get, post, patch, destroy } from './axiosClient'

export const getOrders = (query) => get('/importorder', query)
export const addOrder = (body) => post('/importorder/create', { ...body, from_platform: 'SGL' })
export const updateOrder = (id, body) =>
  patch('/importorder/update/' + id, { ...body, from_platform: 'SGL' })
export const scanImport = (body) => post('/importorder/scanimport', body)
export const deleteImportOrders = (body) =>
  destroy('/importorder/delete', { ...body, from_platform: 'SGL' })

export const autoImportOrder = (body) => post('/importorder/file/auto-import', body)
export const autoCheck = (body) => post('/importorder/auto-check', body)
export const autoImportLayout = (body) => post('/importorder/auto-import-layout', body)
export const fixImportLayout = (body) => post('/importorder/fix-import-layout', body)
