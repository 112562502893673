import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Col, notification, Row, Tree } from 'antd'
import { isAcceptPermissions, removeAccents } from 'utils'
import listTreeData from './tree-data'
import { _addLog } from 'utils'
import { ACTION, PERMISSIONS } from 'consts'

//apis
import { updateRole } from 'apis/role'

export default function PermissionForm({ search, role, _getRoles }) {
  const list = [...listTreeData]
  const halfTreeData = (list.length - 1) / 2
  const firstHalf = list.slice(0, halfTreeData)
  const secondHalf = list.slice(halfTreeData, list.length)

  const [rolePermission, setRolePermission] = useState(role.permissions)
  const [checkedKeys, setCheckedKeys] = useState(role.permissions)
  const dispatch = useDispatch()

  const _handleSubmit = async (checkedKeys) => {
    const body = { ...role, permissions: checkedKeys }
    let res
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      res = await updateRole(role.role_id, body)
      if (res.status === 200) {
        setRolePermission(rolePermission.filter((e) => e !== checkedKeys))
        _getRoles()
        notification.success({
          message: 'Cập nhật vai trò thành công!',
          duration: 4,
          key: 'update-success',
        })
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      notification.error({ message: 'Cập nhật vai trò thất bại!' })
      _addLog(`Update role: ${JSON.stringify(error)}`)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }
  return (
    <div
      style={{
        overflowY: 'auto',
        maxHeight: 500,
      }}
    >
      <Row>
        {[firstHalf, secondHalf].map((data) => (
          <Col span={12}>
            <Tree
              checkable
              treeData={data.map((item) => ({
                ...item,
                title: (
                  <div>
                    {search &&
                    item.title.includes(
                      removeAccents(search.replaceAll(' ', '_').toLowerCase())
                    ) ? (
                      <mark style={{ backgroundColor: '#00D3E0' }}>{item.title}</mark>
                    ) : (
                      item.title
                    )}
                  </div>
                ),
              }))}
              checkedKeys={checkedKeys}
              checkStrictly
              defaultExpandAll
              disabled={!isAcceptPermissions(PERMISSIONS.cap_nhat_phan_quyen)}
              selectable={false}
              onCheck={(value, e) => {
                setCheckedKeys(value.checked)
                _handleSubmit(value.checked)
              }}
            />
          </Col>
        ))}
      </Row>
    </div>
  )
}
