import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { _addLog } from 'utils'

// antd
import { ArrowLeftOutlined, EditOutlined, PlusOutlined, LoadingOutlined } from '@ant-design/icons'
import { Input, Row, Form, Col, Button, Space, notification, Select, Upload } from 'antd'

// consts
import { PERMISSIONS, regexPhone, ROUTES } from 'consts'

//components
import { updateShippingCompany, addShippingCompany } from 'apis/shipping-company'
import SuffixIconCustom from 'components/suffixIconCustom'

//apis
import { uploadFile } from 'apis/upload'
import { getDistrict, getProvince, getWard } from 'apis/address'

//style
import style from './shipping-company-add.module.scss'
import Permission from 'components/permission'

export default function ShippingCompanyAdd() {
  const history = useHistory()
  const location = useLocation()
  const [createForm] = Form.useForm()

  const [imgUpload, setImgUpload] = useState('')
  const [loading, setLoading] = useState(false)

  const [dataProvince, setDataProvince] = useState([])
  const [dataDistrict, setDataDistrict] = useState([])
  const [dataWard, setDataWard] = useState([])

  const [valueProvince, setValueProvince] = useState('Lào Cai')
  const [valueDistrict, setValueDistrict] = useState('Huyện Xi Ma Cai')

  const createAndUpdateShippingCompany = async (value) => {
    // console.log('value', value);
    try {
      value.logo = imgUpload ? [imgUpload] : ''
      const res = await (history.location.state
        ? updateShippingCompany(history.location.state.shippingcompany_id, value)
        : addShippingCompany(value))
      // console.log('res', res.data.data);
      if (res.data.success) {
        notification.success({
          message: history.location.state
            ? 'Cập nhật đơn vị vận chuyển thành công'
            : 'Tạo đơn vị vận chuyển thành công',
        })
        history.push(ROUTES.SHIPPING_COMPANY)
      } else {
        notification.error({
          message:
            res.data.message ||
            (history.location.state
              ? 'Cập nhật đơn vị vận chuyển thất bại'
              : 'Tạo đơn vị vận chuyển thất bại'),
        })
      }
    } catch (error) {
      _addLog(`Create or update shipping company: ${JSON.stringify(error)}`)
      console.log(error)
      notification.error({
        message: history.location.state
          ? 'Cập nhật đơn vị vận chuyển thất bại'
          : 'Tạo đơn vị vận chuyển thất bại',
      })
    }
  }

  const hanldeChangImg = async (info) => {
    if (info.file.status !== 'done') info.file.status = 'done'
    setLoading(true)
    try {
      const urlImg = await uploadFile(info.file.originFileObj)
      if (urlImg) {
        setLoading(false)
        setImgUpload(urlImg)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      _addLog(`Upload image: ${JSON.stringify(error)}`)
    }
  }

  const _getProvince = async () => {
    try {
      const res = await getProvince()
      if (res.status === 200) {
        if (res.data.success) {
          const data = res.data.data
          setDataProvince(data)
          if (!history.location.state) {
            if (data.length) {
              createForm.setFieldsValue({ province: data[0].province_name })
            }
          }
        }
      }
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _getDistrict = async (value) => {
    try {
      if (value) {
        const res = await getDistrict(value)
        if (res.status === 200) {
          const data = res.data.data
          setDataDistrict(data)
          setValueDistrict(data[0]?.district_name)
          if (!history.location.state) {
            if (data.length) {
              createForm.setFieldsValue({ district: data[0]?.district_name })
            }
          }
        }
      }
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _getWard = async (value) => {
    try {
      const res = await getWard(value)
      if (res.status === 200) {
        const data = res.data.data
        setDataWard(data)
        if (!history.location.state) {
          if (data.length) {
            createForm.setFieldsValue({ ward: data[0].ward_name })
          }
        }
      }
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  useEffect(() => {
    if (location.state) {
      createForm.setFieldsValue(location.state)
      setImgUpload(location.state.logo[0])

      const { province, district } = location.state
      setValueProvince(province)
      setValueDistrict(district)
    }
    _getProvince()
  }, [])

  useEffect(() => {
    _getDistrict({ province_name: valueProvince })
    _getWard({ province_name: valueProvince })
  }, [valueProvince])

  useEffect(() => {
    _getWard({ district_name: valueDistrict })
  }, [valueDistrict])

  return (
    <div style={{ minHeight: '520px' }}>
      <Row
        onClick={() => history.push(ROUTES.SHIPPING_COMPANY)}
        align="middle"
        style={{
          fontSize: 17,
          cursor: 'pointer',
          paddingBottom: 12,
          borderBottom: '0.75px solid #BBBCBD',
          marginBottom: '20px',
        }}
      >
        <ArrowLeftOutlined />
        <p style={{ marginBottom: 0, marginLeft: 9, fontWeight: 600 }}>
          {location.state ? 'Cập nhật đơn vị vận chuyển' : 'Tạo đơn vị vận chuyển'}
        </p>
      </Row>
      <Form
        style={{ margin: '0 50px' }}
        layout="vertical"
        form={createForm}
        onFinish={createAndUpdateShippingCompany}
      >
        <Row justify="space-between" gutter={100}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Tên đơn vị vận chuyển"
              rules={[{ required: true, message: 'Vui lòng nhập tên đơn vị vận chuyển' }]}
            >
              <Input allowClear placeholder="Nhập tên đơn vị vận chuyển" />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  type: 'email',
                  message: 'Vui lòng nhập email đúng định dạng',
                },
                { required: true, message: 'Vui lòng nhập email đơn vị vận chuyển' },
              ]}
            >
              <Input allowClear placeholder="Nhập email đơn vị vận chuyển" />
            </Form.Item>
            <Form.Item
              label="Số điện thoại"
              name="phone"
              rules={[
                {
                  pattern: regexPhone,
                  message: 'Số điện thoại không đúng định dạng',
                },
                {
                  required: true,
                  message: 'Vui lòng nhập số điện thoại đơn vị vận chuyển',
                },
              ]}
            >
              <Input
                allowClear
                maxLength={15}
                style={{ width: '100%' }}
                placeholder="Nhập Số điện thoại đơn vị vận chuyển"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="logo" label="Hình ảnh" className={style['item-avatar']}>
              <Upload
                maxCount={1}
                name="logo"
                className={style['avatar-uploader']}
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                onChange={hanldeChangImg}
                showUploadList={{ showRemoveIcon: false, status: 'done' }}
              >
                <div style={{ width: 150, height: 150, backgroundColor: '#ccc' }}>
                  {imgUpload ? (
                    <img src={imgUpload} alt="avatar" style={{ width: '150px', heigh: '150px' }} />
                  ) : (
                    <div style={{ textAlign: 'center', paddingTop: '65px' }}>
                      {loading ? (
                        <LoadingOutlined style={{ fontSize: 20 }} />
                      ) : (
                        <PlusOutlined style={{ fontSize: 20 }} />
                      )}
                    </div>
                  )}
                </div>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between" gutter={100}>
          <Col span={12}>
            <Form.Item
              name="address"
              label={<label style={{ fontSize: 16, fontWeight: 500 }}>Địa chỉ</label>}
            >
              <Input allowClear placeholder="Nhập địa chỉ đơn vị vận chuyển" />
            </Form.Item>
            <Form.Item
              name="province"
              label={<label style={{ fontSize: 16, fontWeight: 500 }}>Tỉnh/Thành phố</label>}
              rules={[{ required: true, message: 'Vui lòng chọn tỉnh/thành phố' }]}
            >
              <Select
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: '100%' }}
                placeholder="Chọn tỉnh/thành phố"
                suffixIcon={<SuffixIconCustom />}
                onChange={(value) => setValueProvince(value)}
              >
                {dataProvince.map((item, index) => (
                  <Select.Option key={index} value={item.province_name}>
                    {item.province_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="district"
              label={<label style={{ fontSize: 16, fontWeight: 500 }}>Quận/Huyện</label>}
              rules={[{ required: true, message: 'Vui lòng chọn quận/huyện' }]}
            >
              <Select
                allowClear
                showSearch
                filterOption={(inputValue, option) => {
                  return option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                }}
                style={{ width: '100%' }}
                placeholder="Chọn quận/huyện"
                suffixIcon={<SuffixIconCustom />}
                value={valueDistrict}
                onChange={(value) => setValueDistrict(value)}
              >
                {dataDistrict.map((item, index) => (
                  <Select.Option key={index} value={item.district_name}>
                    {item.district_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="ward"
              style={{ width: '100%' }}
              label={<label style={{ fontSize: 16, fontWeight: 500 }}>Phường/Xã</label>}
              rules={[{ required: true, message: 'Vui lòng chọn phường/xã' }]}
            >
              <Select
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Chọn phường/xã"
                suffixIcon={<SuffixIconCustom />}
              >
                {dataWard.map((item, index) => (
                  <Select.Option key={index} value={item.ward_name}>
                    {item.ward_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginTop: 30 }} gutter={100}>
          <Col span={12}></Col>
          <Col span={12} style={{ display: 'flex', justifyContent: 'end' }}>
            <Space size={'large'}>
              <Button
                danger
                type="primary"
                icon={<EditOutlined />}
                style={{
                  minWidth: '100px',
                }}
                onClick={() => history.goBack()}
              >
                Hủy
              </Button>
              <Permission
                permissions={
                  location.state
                    ? [PERMISSIONS.cap_nhat_don_vi_van_chuyen]
                    : [PERMISSIONS.tao_don_vi_van_chuyen]
                }
              >
                <Button
                  className={style['shipping-company-button--orange']}
                  style={{
                    minWidth: '100px',
                  }}
                  htmlType="submit"
                >
                  {history.location.state ? 'Lưu' : 'Tạo'}
                </Button>
              </Permission>
            </Space>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
