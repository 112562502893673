import { useEffect, useState } from 'react'
import styles from '../export-product.module.scss'
import { useHistory } from 'react-router-dom'
import { IMAGE_DEFAULT, PAGE_SIZE_OPTIONS, PERMISSIONS, PRINTER, ROUTES } from 'consts'
import { useDispatch, useSelector } from 'react-redux'
import { getFileName, _addLog } from 'utils'

import {
  Table,
  Collapse,
  Row,
  Col,
  Button,
  Tag,
  Badge,
  Modal,
  notification,
  Space,
  Upload,
  Popover,
  Popconfirm,
  Tooltip,
} from 'antd'
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  InboxOutlined,
  DeleteOutlined,
} from '@ant-design/icons'

//apis
import { findBucket, deleteExportOrders, cancelBaskets } from 'apis/export-order'
import { updateBasket } from 'apis/basket'
import { getPrint } from 'apis/print'
import { enumExport } from 'apis/enum'
import { uploadFile } from 'apis/upload'
import { getFees } from 'apis/fee'

// components
import ModalReasonOrder from 'components/modal-reason-order'
import printToThermalPrinter from 'components/thermal-printer/print'
import { barcode } from 'components/thermal-printer/templates'
import Permission from 'components/permission'
import ModalOptionsPrint from 'components/modal-opions-print'
import ModalFees from 'components/modal-fees'
import ZoomImage from 'components/zoom-image'
import ModalConfirmDelete from 'components/modal-confirm-delete'
import QrCodeProduct from 'components/qr-code-product'

export default function TabsTime({
  data,
  loading,
  updateExport,
  exportOrdersChecked,
  setExportOrdersChecked,
  setFilters,
  timeTabs,
  filters,
  reload,
  countOrder = 0,
}) {
  const [tableLoading, settableLoading] = useState(loading)
  const [bucketsList, setBucketsList] = useState({})
  const [tabkey, setTabkey] = useState(0)
  const [showConfirmModal, setShowConfirmModal] = useState({ visible: false, state: {} })
  const [reasons, setReasons] = useState([])
  const history = useHistory()
  const dispatch = useDispatch()
  const dataUser = useSelector((state) => state.user)
  const permissionUpdate = dataUser?.role_info?.permissions?.find(
    (e) => e === PERMISSIONS.cap_nhat_danh_sach_xuat_hang
  )
  const [fees, setFees] = useState([])

  //  hàm getBucketProduct được gọi khi xem chi tiết đơn hàng, lưu lại các vị trí và các sản phẩm trong order
  const _getBucketProduct = async (order_ids) => {
    try {
      const newID = order_ids[order_ids.length - 1]
      if (newID && !bucketsList[newID]) {
        const res = await findBucket({ order_id: newID })
        if (res.data.success) {
          let tmp = { ...bucketsList, [newID]: res.data.data }
          setBucketsList(tmp)
        }
      }

      setExportOrdersChecked(order_ids)
    } catch (err) {
      _addLog(JSON.stringify(err))
      console.log(err)
    }
  }

  const _getPrint = async (params) => {
    try {
      const res = await getPrint(params)
      if (res.status === 200) {
        return res.data.data[0]
      }
      return ''
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
      return ''
    }
  }

  const _deleteExport = async (id, note = '') => {
    try {
      const res = await deleteExportOrders({ order_id: [id], note: note })
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Xóa đơn hàng thành công' })
          reload()
        } else notification.error({ message: res.data.message || 'Xóa đơn hàng không thành công' })
      } else notification.error({ message: res.data.message || 'Xóa đơn hàng không thành công' })
    } catch (err) {
      console.log(err)
    }
  }

  const _findOrderBucket = async (params) => {
    try {
      const res = await findBucket(params)
      if (res.status === 200) {
        return res.data.data.bucket_map
      } else return []
    } catch (err) {
      _addLog(JSON.stringify(err))
      console.log(err)
      return []
    }
  }

  const _removeBasket = async (orderId = '', basketId = '', basketIds = []) => {
    try {
      const basketIdsUpdate = basketIds.filter((id) => id !== basketId)
      dispatch({ type: 'LOADING', data: true })
      await updateBasket(basketId, { order_waiting: false })
      await updateExport(orderId, { basket_ids: basketIdsUpdate })
      dispatch({ type: 'LOADING', data: false })
    } catch (err) {
      dispatch({ type: 'LOADING', data: false })
      _addLog(`Delete basket${JSON.stringify(err)}`)
      notification.error({ message: 'Xóa đơn thùng đựng không thành công' })
      console.log(err)
    }
  }

  const _getOrderReasons = async () => {
    try {
      const res = await enumExport()
      if (res.data.success) {
        setReasons(res.data.data?.report_reason || [])
      }
    } catch (err) {
      _addLog(JSON.stringify(err))
      console.log(err)
    }
  }

  const _cancelBaskets = async (code) => {
    try {
      const body = { order_code: code }
      const res = await cancelBaskets(body)
      if (res.status === 200) {
        if (res.data.success) {
          if (reload) reload()
          notification.success({ message: 'Xóa liên kết đơn hàng khỏi các thùng đựng thành công' })
        } else notification.error({ message: res.data.message })
      } else notification.error({ message: res.data.message })
    } catch (err) {
      _addLog(JSON.stringify(err))
      console.log(err)
    }
  }

  const _getFees = async () => {
    try {
      const res = await getFees()
      if (res.status === 200) setFees(res.data.data)
    } catch (err) {
      _addLog(JSON.stringify(err))
      console.log(err)
    }
  }

  const _rescanBaskets = async (id, IDList) => {
    try {
      dispatch({ type: 'LOADING', data: true })

      const res = await Promise.all(
        IDList.map((e) => {
          return updateBasket(e, { order_waiting: false })
        })
      )
      if (res.reduce((a, b) => a && b.data.success, true)) {
        updateExport(id, { basket_ids: [] })
        history.push({
          pathname: ROUTES.PRODUCT_EXPORT_SCAN,
          state: {
            type: 'basket',
            order_id: data.order_id,
            order_status: data.export_status,
            processing: data.processing,
            buckets: bucketsList,
            filters,
          },
        })
      }
      dispatch({ type: 'LOADING', data: false })
    } catch (err) {
      dispatch({ type: 'LOADING', data: false })

      notification.error({ message: 'Quét lại không thành công' })
      console.log(err)
    }
  }

  const _printBarcode = async (record) => {
    const params = {
      warehouse_id: record.warehouse_id,
      name: PRINTER.XUAT_KHO,
    }
    const dataPrinter = await _getPrint(params)
    printToThermalPrinter(
      barcode,
      {
        barcode: record.barcode,
        title: 'SP: ' + record.slug_name.replaceAll('-', ' '),
      },
      dataPrinter && dataPrinter.ip
    )
  }

  const BillOrder = ({ record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    const shippingBill =
      record && record.shipping_info
        ? record.shipping_info.shipping_employee_signature &&
          typeof record.shipping_info.shipping_employee_signature === 'string'
          ? [record.shipping_info.shipping_employee_signature]
          : record.shipping_info.shipping_employee_signature || []
        : []

    const [images, setImages] = useState([])
    const [imagesView, setImagesView] = useState([])
    const [loading, setLoading] = useState(false)

    const addFile = async (file) => {
      setLoading(true)
      const url = await uploadFile(file)
      setImages([url])
      const fileNames = url.split('/')
      const fileName = fileNames[fileNames.length - 1]
      setImagesView([
        { uid: imagesView.length, name: fileName, status: 'done', url: url, thumbUrl: url },
      ])
      setLoading(false)
    }

    const _removeFile = (file) => {
      const imagesNew = [...images]
      const imagesViewNew = [...imagesView]

      const indexImage = images.findIndex((url) => url === file.url)
      const indexImageView = imagesView.findIndex((f) => f.url === file.url)

      if (indexImage !== -1) imagesNew.splice(indexImage, 1)
      if (indexImageView !== -1) imagesViewNew.splice(indexImageView, 1)

      setImages([...imagesNew])
      setImagesView([...imagesViewNew])
    }

    useEffect(() => {
      if (visible) {
        setImages(shippingBill)
        setImagesView(
          shippingBill.map((image, index) => ({
            uid: index,
            name: getFileName(image),
            status: 'done',
            url: image,
            thumbUrl: image,
          }))
        )
      }
    }, [visible])

    return (
      <>
        <Modal
          footer={
            <Row justify="end">
              <Space>
                <Button style={{ minWidth: 100 }} onClick={toggle}>
                  Đóng
                </Button>
                <Button
                  onClick={async () => {
                    const body = {
                      ...record,
                      shipping_info: {
                        ...record.shipping_info,
                        shipping_employee_signature: images.length ? images[0] : '',
                      },
                    }
                    await updateExport(record.order_id, body)
                    toggle()
                  }}
                  style={{ minWidth: 100 }}
                  type="primary"
                >
                  Lưu
                </Button>
              </Space>
            </Row>
          }
          width={700}
          onCancel={toggle}
          title="Tải lên hình ảnh biên lai"
          visible={visible}
        >
          <Upload.Dragger
            accept="image/*"
            fileList={imagesView}
            listType="picture"
            data={addFile}
            onRemove={_removeFile}
            name="file"
            onChange={(info) => {
              if (info.file.status !== 'done') info.file.status = 'done'
            }}
          >
            <p className="ant-upload-drag-icon">
              {loading ? <LoadingOutlined /> : <InboxOutlined />}
            </p>
            <p className="ant-upload-text">Nhấp hoặc kéo thả vào khu vực này để tải lên</p>
          </Upload.Dragger>
        </Modal>

        <Space>
          <div>
            <Permission permissions={[PERMISSIONS.cap_nhat_danh_sach_xuat_hang]}>
              <Button
                type="primary"
                onClick={toggle}
                style={{ backgroundColor: '#377BFF', borderColor: '#377BFF' }}
              >
                Hình ảnh biên lai
              </Button>
            </Permission>
          </div>
          <div>
            {shippingBill && shippingBill.length ? (
              <Popover
                placement="right"
                content={
                  <img src={shippingBill[0]} style={{ height: '250px', width: '250px' }} alt="" />
                }
              >
                <img src={shippingBill[0]} style={{ height: '30px', width: '30px' }} alt="" />
              </Popover>
            ) : (
              ''
            )}
          </div>
        </Space>
      </>
    )
  }

  const columns = [
    {
      key: 1,
      render(data, record) {
        return (
          <Row>
            <Col>
              {record.code && <Row>#{record.code}</Row>}
              <Row>Số phiếu: #{record.customer_order_code}</Row>
              {record.shipping_info && (
                <div>
                  <Row>Mã vận đơn: {record?.shipping_info?.shipping_code}</Row>
                  <Row>ĐVVC: {record?.shipping_info?.shipping_company_name}</Row>
                  {record.shipping_info.car_number && (
                    <div style={{ fontSize: 16.5, fontWeight: 700 }}>
                      Biển số xe: {record.shipping_info.car_number}
                    </div>
                  )}
                </div>
              )}
              <div>Nguồn: {record.platform}</div>
              <BillOrder record={record} />
            </Col>
          </Row>
        )
      },
    },
    {
      key: 2,
      render(data, record) {
        return (
          <Row>
            <Col>
              {/* <ModalFees
                type="export"
                fees={fees}
                record={record}
                status={record.export_status}
                reload={() => reload()}
              /> */}
              <Row>Doanh nghiệp: {record.customer_info && record.customer_info.fullname}</Row>
              {record.export_status != 'WAITING' && (
                <Row>
                  Lấy hàng: {record.export_employee_info && record.export_employee_info.fullname}
                </Row>
              )}
              <Row>
                <QrCodeProduct value={record.code} />
              </Row>
            </Col>
          </Row>
        )
      },
    },
    {
      key: 3,
      render(data, record) {
        if (data.export_status !== 'COMPLETE')
          return (
            <div>
              <Permission permissions={[PERMISSIONS.cap_nhat_danh_sach_xuat_hang]}>
                <Space size="small">
                  <Button
                    type="primary"
                    className={styles['btn-scan']}
                    style={{ marginRight: 10 }}
                    onClick={async () => {
                      dispatch({ type: 'LOADING', data: true })
                      const orderBuckets = await _findOrderBucket({ order_id: data.order_id })
                      dispatch({ type: 'LOADING', data: false })

                      const state = {
                        pathname: ROUTES.PRODUCT_EXPORT_SCAN,
                        state: {
                          type: 'basket',
                          record: record,
                          order_code: data.code,
                          order_id: data.order_id,
                          order_status: data.export_status,
                          processing: data.processing,
                          default_basket: data.basket_info,
                          buckets: orderBuckets || [],
                          filters,
                        },
                      }

                      if (data.export_status === 'PROCESSING') {
                        Modal.confirm({
                          icon: <ExclamationCircleOutlined />,
                          content: (
                            <h3>
                              Đơn hàng này đang được thực hiện bởi người khác, bạn có muốn thao tác
                              không ?
                            </h3>
                          ),
                          okText: 'Có',
                          cancelText: 'Không',
                          onOk: () => history.push(state),
                        })
                      } else history.push(state)
                    }}
                  >
                    Bắt đầu xuất hàng
                  </Button>
                  {data.basket_ids.length ? (
                    <Button
                      type="primary"
                      style={{ marginRight: 10 }}
                      onClick={async () => {
                        const orderBuckets = await _findOrderBucket({ order_id: data.order_id })

                        const pushRouter = () =>
                          history.push({
                            pathname: ROUTES.PRODUCT_EXPORT_SCAN_BUCKET,
                            state: {
                              type: 'bucket',
                              record: record,
                              order_code: data.code,
                              order_id: data.order_id,
                              order_bucket: orderBuckets,
                              order_status: data.export_status,
                              processing: data.processing,
                              buckets: orderBuckets || [],
                              filters,
                            },
                          })

                        if (data.export_status === 'PROCESSING') {
                          Modal.confirm({
                            icon: <ExclamationCircleOutlined />,
                            content: (
                              <h3>
                                Đơn hàng này đang được thực hiện bởi người khác, bạn có muốn thao
                                tác không ?
                              </h3>
                            ),
                            okText: 'Có',
                            cancelText: 'Không',
                            onOk: () => pushRouter(),
                          })
                        } else pushRouter()
                      }}
                    >
                      Quét xuất vị trí
                    </Button>
                  ) : (
                    ''
                  )}

                  <ModalReasonOrder
                    reasons={reasons}
                    onReport={(reason) =>
                      updateExport(record.order_id, {
                        export_status: 'REPORT',
                        export_reason: reason,
                      })
                    }
                  />
                </Space>
              </Permission>
              <div style={{ marginTop: 5 }}>
                <div style={{ display: data.basket_info && !data.basket_info.length && 'none' }}>
                  Danh sách thùng đựng{' '}
                  <Popconfirm
                    onConfirm={() => _cancelBaskets(data.code)}
                    title="Bạn có muốn xóa liên kết đơn hàng khỏi các thùng đựng không?"
                  >
                    <Button size="small" type="primary" danger>
                      Xóa liên kết
                    </Button>
                  </Popconfirm>
                </div>
                <Row>
                  {data.basket_info &&
                    data.basket_info.map((e) => (
                      <Tag
                        color="#FF6731"
                        style={{ padding: '4px 15px' }}
                        closable={permissionUpdate} // có permission thì đc close
                        onClose={() => _removeBasket(data.order_id, e.basket_id, data.basket_ids)}
                      >
                        {e.code}
                      </Tag>
                    ))}
                </Row>
              </div>
            </div>
          )
        else
          return (
            <div style={{ marginTop: 5 }}>
              <div style={{ display: data.basket_info && !data.basket_info.length && 'none' }}>
                Danh sách thùng đựng
              </div>
              <Space size="small">
                {data.basket_info &&
                  data.basket_info.map((e) => (
                    <Tag color="#FF6731" style={{ padding: '4px 15px' }}>
                      {e.code}
                    </Tag>
                  ))}
              </Space>
            </div>
          )
      },
    },
    {
      key: 4,
      width: 20,
      render(data, record) {
        return (
          <Permission permissions={[PERMISSIONS.xoa_don_hang_xuat]}>
            <ModalConfirmDelete
              record={record}
              onDelete={(note) => _deleteExport(record.order_id, note)}
            >
              <Button type="primary" icon={<DeleteOutlined />} danger />
            </ModalConfirmDelete>
          </Permission>
        )
      },
    },
  ]

  useEffect(() => {
    settableLoading(loading)
  }, [loading])

  useEffect(() => {
    _getOrderReasons()
    _getFees()
  }, [])
  return (
    <>
      <Row justify="space-between" style={{ margin: '10px 0' }}>
        {timeTabs.slice(0, 12).map((nameTab, index) => {
          return (
            <Badge count={nameTab.order_quantity} offset={[0, 0]} overflowCount="none">
              <Button
                disabled={loading}
                type={filters.time_receive == index ? 'primary' : ''}
                onClick={() => {
                  setTabkey(index)
                  setFilters((e) => {
                    e.page = 1
                    return { ...e, time_receive: index }
                  })
                }}
              >
                {nameTab.time_receive}
              </Button>
            </Badge>
          )
        })}
      </Row>
      <Row justify="space-between" style={{ margin: '20px 0' }}>
        {timeTabs.slice(12).map((nameTab, index) => {
          return (
            <Badge key={index} count={nameTab.order_quantity} offset={[0, 0]} overflowCount="none">
              <Button
                disabled={loading}
                type={filters.time_receive == index + 12 ? 'primary' : ''}
                onClick={() => {
                  setTabkey(index + 12)
                  setFilters((e) => {
                    e.page = 1
                    return { ...e, time_receive: index + 12 }
                  })
                }}
              >
                {nameTab.time_receive}
              </Button>
            </Badge>
          )
        })}
      </Row>
      <Table
        rowKey="order_id"
        size="small"
        showHeader={false}
        columns={columns}
        loading={tableLoading}
        rowSelection={{
          selectedRowKeys: exportOrdersChecked,
          onChange: _getBucketProduct,
        }}
        expandable={{
          expandedRowRender: (record) => (
            <>
              {bucketsList[record.order_id] && bucketsList[record.order_id].bucket_map.length ? (
                <b style={{ fontSize: 16 }}>Danh sách ô kệ cần xuất hàng</b>
              ) : (
                ''
              )}
              <Collapse
                defaultActiveKey={Array.from(
                  Array(
                    bucketsList[record.order_id]
                      ? bucketsList[record.order_id].bucket_map.length
                      : 0
                  ).keys()
                )}
                style={{ marginBottom: 10 }}
              >
                {bucketsList[record.order_id] &&
                  bucketsList[record.order_id]?.bucket_map?.map((e, index) => (
                    <Collapse.Panel key={index} header={`Ô kệ: ${e.hotname}`}>
                      <Table
                        size="small"
                        columns={[
                          {
                            title: 'STT',
                            align: 'center',
                            width: 50,
                            render(data, record, index) {
                              return index + 1
                            },
                          },
                          {
                            title: 'Mã sản phẩm',
                            render: (text, record) =>
                              record?.barcode ? record?.barcode : record?.code,
                          },
                          {
                            title: 'Tên sản phẩm',
                            dataIndex: 'name',
                          },
                          {
                            title: 'Hình ảnh',
                            dataIndex: 'images',
                            render(data) {
                              return (
                                <ZoomImage
                                  image={(data && data[0] && IMAGE_DEFAULT) || IMAGE_DEFAULT}
                                >
                                  <img
                                    alt=""
                                    src={(data && data[0] && IMAGE_DEFAULT) || IMAGE_DEFAULT}
                                    width="50"
                                    height="50"
                                  />
                                </ZoomImage>
                              )
                            },
                          },
                          {
                            title: 'Số lượng cần xuất',
                            dataIndex: 'require_export_quantity',
                            align: 'center',
                          },
                          {
                            title: 'Số lượng đã xuất',
                            dataIndex: 'export_quantity',
                            align: 'center',
                          },
                          {
                            title: 'QR code',
                            align: 'center',
                            render: (text, record) => <QrCodeProduct value={record.barcode} />,
                          },
                          {
                            title: '',
                            render: (text, record) => (
                              <ModalOptionsPrint
                                printWithInternet={() => _printBarcode(record)}
                                records={[{ ...record, code: record.barcode || record.code }]}
                              >
                                <Button type="primary" className={styles['btn-print']}>
                                  In barcode sản phẩm
                                </Button>
                              </ModalOptionsPrint>
                            ),
                          },
                        ]}
                        dataSource={e.products}
                        pagination={false}
                      />
                    </Collapse.Panel>
                  ))}
              </Collapse>
            </>
          ),
          expandIconColumnIndex: -1,
          expandedRowKeys: exportOrdersChecked,
        }}
        rowClassName={(record) => styles[record.export_status]}
        dataSource={data}
        pagination={{
          current: filters.page,
          pageSize: filters.page_size,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          showQuickJumper: true,
          onChange: (page, pageSize) => {
            filters.page = page
            filters.page_size = pageSize
            setFilters({ ...filters })
          },
          total: countOrder,
        }}
      />
    </>
  )
}
