const columns = [
  {
    title: 'STT',
    key: 'stt',
    align: 'center',
    width: 50,
  },
  {
    key: 'logo',
    title: 'Logo',
    dataIndex: 'logo',
    align: 'center',
  },
  {
    key: 'name',
    title: 'Tên đối tác',
    dataIndex: 'name',
  },
  {
    key: 'customer',
    title: 'Doanh nghiệp',
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'phone',
    align: 'center',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    key: 'address',
    title: 'Địa chỉ',
  },
  {
    title: 'Ngày tạo',
    key: 'date',
    dataIndex: 'create_date',
    align: 'center',
  },
  {
    key: 'action',
    title: '',
    dataIndex: 'create_date',
    align: 'center',
  },
]

export default columns
