import React, { useState, useEffect, useRef } from 'react'
import styles from './product.module.scss'
import QRCode from 'react-qr-code'
import {
  ROUTES,
  PAGE_SIZE_OPTIONS,
  ACTION,
  SIZE_TABLE,
  PRINTER,
  PERMISSIONS,
  IMAGE_DEFAULT,
} from 'consts'
import { useHistory, Link } from 'react-router-dom'
import {
  filterOptionSelect,
  formatCash,
  _addLog,
  saveFiltersToLocalStorage,
  handleGetDataExportExcel,
  isAcceptPermissions,
  getFileName,
} from 'utils'
import moment from 'moment-timezone'
import { useDispatch, useSelector } from 'react-redux'

//components
import style from './product.module.scss'
import SuffixIconCustom from 'components/suffixIconCustom'
import columnsProduct from './columns'
import SettingColumns from 'components/setting-columns'
import Permissions from 'components/permission'
import exportExcel from 'components/export-csv/export-csv'
import BackToTop from 'components/back-to-top'
import ImportFile from 'components/import-csv'
import printToThermalPrinter from 'components/thermal-printer/print'
import { barcode } from 'components/thermal-printer/templates'
import ModalOptionsPrint from 'components/modal-opions-print'
import FilterDate from 'components/filter-date'
import ZoomImage from 'components/zoom-image'
import QrCodeProduct from 'components/qr-code-product'

//antd
import {
  Row,
  Popconfirm,
  Button,
  Input,
  Select,
  Space,
  Table,
  Col,
  notification,
  Tooltip,
  Switch,
  Modal,
  DatePicker,
} from 'antd'

//icons
import { PrinterOutlined, DeleteOutlined, ReloadOutlined, WarningOutlined } from '@ant-design/icons'

//apis
import {
  getProducts,
  deleteProducts,
  updateProduct,
  updateBarcodeProduct,
  exportByFilter,
  exportByAll,
  exportErrorByMerchant,
  exportHistoryInventory,
  reportInventoryBucket,
} from 'apis/product'
import { getWarehouses } from 'apis/warehouse'
import { getAllCustomer } from 'apis/customer'
import { getCategories } from 'apis/categories-product'
import { uploadProduct } from 'apis/upload'
import { getPrint } from 'apis/print'
import { saveAs } from 'file-saver'

export default function Products() {
  const history = useHistory()
  const typingTimeoutRef = useRef()
  const dispatch = useDispatch()
  const KEY_FILTER = 'productFilters'
  const filters = localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))

  const [products, setProducts] = useState([])
  const [statistical, setStatistical] = useState(null)
  const [countProduct, setCountProduct] = useState(0)

  const [groups, setGroups] = useState([])
  const [types, setTypes] = useState([])
  const [warehouses, setWarehouses] = useState([])
  const [customers, setCustomers] = useState([])
  const [customerId, setCustomerId] = useState('')

  const [paramsFilter, setParamsFilter] = useState(
    filters ? { ...filters } : { page: 1, page_size: 100 }
  )
  const [valueSearch, setValueSearch] = useState(
    filters ? filters.name || filters.barcode || '' : ''
  )
  const [optionSearch, setOptionSearch] = useState(
    filters ? (filters.name && 'name') || (filters.barcode && 'barcode') || 'barcode' : 'barcode'
  )

  const [selectRows, setSelectRows] = useState([])
  const [columns, setColumns] = useState([])
  const [loading, setLoading] = useState(false)

  const [visibleDownload, setVisibleDownload] = useState(false)
  const [inputBarcode, setInputBarcode] = useState('')
  const [loadingHistory, setLoadingHistory] = useState(false)

  const [visibleError, setVisibleError] = useState(false)
  const [visibleBucket, setVisibleBucket] = useState(false)
  const [customerIdBucket, setCustomerIdBucket] = useState('')
  const [customerIdError, setCustomerIdError] = useState('')

  const dataUser = useSelector((state) => state.user)

  const _getPrint = async (params) => {
    try {
      const res = await getPrint(params)
      if (res.status === 200) {
        return res.data.data[0]
      }
      return ''
    } catch (error) {
      console.log(error)
      _addLog(JSON.stringify(error))
      return ''
    }
  }
  const _printBarcode = async () => {
    const productsToPrint = products.filter((e) => selectRows.indexOf(e.product_id) >= 0)
    if (productsToPrint.length) {
      const firstProduct = productsToPrint[0]
      if (firstProduct.locations && firstProduct.locations.warehouse_id) {
        let warehouseId = firstProduct.locations.warehouse_id
        if (!warehouseId || warehouseId === -1) warehouseId = dataUser.warehouse_id
        const params = { warehouse_id: warehouseId, name: PRINTER.SAN_PHAM }
        //In QR Code với thông tin ip phụ thuộc vào sản phẩm select đầu tiên hoặc thông tin từ user; nếu không có mặc định in với ip ở component print
        const dataPrinter = await _getPrint(params)
        productsToPrint.forEach((product) =>
          printToThermalPrinter(
            barcode,
            {
              barcode: product.barcode,
              title: 'SP: ' + product.slug_name.replace(/-{1,}/g, ' '),
            },
            dataPrinter && dataPrinter.ip
          )
        )
      }
    }
  }

  const _clearFilters = () => {
    Object.keys(paramsFilter).map((key) => delete paramsFilter[key])
    setValueSearch('')
    paramsFilter.page = 1
    paramsFilter.page_size = 20
    setParamsFilter({ ...paramsFilter })
  }

  const _selectOption = (value) => {
    setOptionSearch(value)
    delete paramsFilter[optionSearch]

    if (valueSearch) {
      //lấy giá trị select mới để lọc lại dữ liệu
      setParamsFilter({ ...paramsFilter, [value]: valueSearch, page: 1 })
    }
  }

  const _updateProduct = async (body, id) => {
    try {
      setLoading(true)
      const res = await updateProduct(body, id)
      setLoading(false)

      if (res.status === 200) {
        if (res.data.success) {
          _getProducts()
          notification.success({ message: 'Cập nhật thành công' })
        } else
          notification.error({ message: res.data.message || 'Cập nhật thất bại, vui lòng thử lại' })
      } else
        notification.error({ message: res.data.message || 'Cập nhật thất bại, vui lòng thử lại' })
    } catch (error) {
      setLoading(false)
      _addLog(`Update product: ${JSON.stringify(error)}`)
      console.log(error)
    }
  }

  const _onSearch = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value
      if (value) paramsFilter[optionSearch] = value
      else delete paramsFilter[optionSearch]
      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 650)
  }

  const _filterByOption = (attribute = '', value = '') => {
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter, page: 1 })
  }

  const _getProducts = async () => {
    try {
      setLoading(true)
      setSelectRows([])
      setProducts([])
      const res = await getProducts({
        ...paramsFilter,
        inventory_info: true,
        expiry_detach: true,
        unit_info: true,
      })
      if (res.status === 200) {
        setProducts(res.data.data)
        setStatistical(res.data.statis_products)
        setCountProduct(res.data.count)
      }
      setLoading(false)
    } catch (error) {
      _addLog(JSON.stringify(error))
      setLoading(false)
      console.log(error)
    }
  }

  const _deleteProducts = async (id) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await deleteProducts({ product_id: [id] })
      // console.log('res', res);
      dispatch({ type: ACTION.LOADING, data: false })
      if (res.status === 200) {
        if (res.data.success) {
          setSelectRows([])
          setParamsFilter({ ...paramsFilter })
          notification.success({ message: 'Xóa sản phẩm thành công' })
        } else
          notification.error({
            message: res.data.mess || res.data.message || 'Xóa sản phẩm thất bại',
          })
      } else
        notification.error({
          message: res.data.mess || res.data.message || 'Xóa sản phẩm thất bại',
        })
    } catch (error) {
      _addLog(`Delete product: ${JSON.stringify(error)}`)
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const ModalExportByMerchant = ({ children }) => {
    const [customerId, setCustomerId] = useState()
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    const _exportExcel = async () => {
      setLoading(true)
      const dataExport = await _getProductsExport({ customer_id: customerId })
      await exportExcel(
        dataExport,
        'Danh sách sản phẩm ' + moment(new Date()).format('DD-MM-YYYY'),
        'Xuất excel danh sách sản phẩm theo doanh nghiệp'
      )
      setLoading(false)
      toggle()
    }

    useEffect(() => {
      if (!visible) setCustomerId()
    }, [visible])

    return (
      <>
        <div onClick={toggle}>{children}</div>
        <Modal
          title="Chọn doanh nghiệp để xuất excel"
          onCancel={toggle}
          visible={visible}
          maskClosable={false}
          footer={
            <Row justify="end">
              <Space>
                <Button onClick={toggle}>Đóng</Button>
                <Button
                  disabled={customerId ? false : true}
                  type="primary"
                  loading={loading}
                  onClick={() => _exportExcel()}
                >
                  Xuất
                </Button>
              </Space>
            </Row>
          }
        >
          <Select
            filterOption={filterOptionSelect}
            optionFilterProp="children"
            showSearch
            size="large"
            value={customerId}
            onChange={setCustomerId}
            style={{ width: '100%' }}
            placeholder="Chọn doanh nghiệp"
          >
            {customers.map((customer, index) => (
              <Select.Option value={customer.customer_id || ''} key={index}>
                {customer.fullname || ''}
              </Select.Option>
            ))}
          </Select>
        </Modal>
      </>
    )
  }

  const _getProductsExport = async (query = {}) => {
    try {
      const dataExport = await handleGetDataExportExcel(getProducts, { ...query })
      if (dataExport) {
        return dataExport.map((data, index) => {
          return {
            STT: index + 1,
            'Tên sản phẩm(*)': data.name || '',
            'Mã sản phẩm(*)': data.barcode || data.code || '',
            'Mã ERP': data.ecommerce_barcode || '',
            'Nhóm sản phẩm': data.group_info ? data.group_info.name : '',
            'Loại sản phẩm': data.type_info ? data.type_info.name : '',
            'Hình ảnh': data.images ? data.images.join(', ') : '',
            'Chiều dài(cm)(*)': data.length || '',
            'Chiều rộng(cm)(*)': data.width || '',
            'Chiều cao(cm)(*)': data.height || '',
            'Khối lượng (kg)(*)': data.weight || '',
            'Quy cách': data.convention || '',
            'Đơn vị tính': data.unit_info ? data.unit_info.name : '',
            'Đơn vị nhỏ nhất': data.smallest_unit || 0,
            'Giá trị': formatCash(data.price || 0),
            'Xuất xứ': data.origin || '',
            'Ngày sản xuất':
              data.locations && data.locations.mfg
                ? moment(data.locations.mfg).format('DD-MM-YYYY HH:mm')
                : '',
            'Hạn sử dụng':
              data.locations && data.locations.exp
                ? moment(data.locations.exp).format('DD-MM-YYYY HH:mm')
                : '',
            // 'Mã khách hàng': data.customer_info ? data.customer_info.fullname || '' : '',
            // 'Tên khách hàng': data.customer_info ? data.customer_info.code || '' : '',
            'Ghi chú': data.note || '',
            'Số lượng hiện tại': data.locations.available_quantity || '',
            'Số lượng có thể xuất': data.locations.available_quantity || '',
          }
        })
      }
      return []
    } catch (error) {
      console.log(error)
      _addLog(`Export product: ${JSON.stringify(error)}`)
      return []
    }
  }

  const _getWarehouses = async () => {
    try {
      const res = await getWarehouses({ get_all: true })
      if (res.status === 200) setWarehouses(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _getCustomers = async () => {
    try {
      const res = await getAllCustomer({ get_all: true })
      if (res.status === 200) setCustomers(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  //nhóm hàng
  const _getGroups = async () => {
    try {
      const res = await getCategories({ group: true, detach: true, get_all: true })
      if (res.status === 200) setGroups(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  //loại hàng
  const _getTypes = async () => {
    try {
      const res = await getCategories({ type: true, detach: true, get_all: true })
      if (res.status === 200) setTypes(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _onDownloadHistoryInventory = async () => {
    try {
      setLoadingHistory(true)
      var response = await exportHistoryInventory({
        barcode: inputBarcode,
      })
      setLoadingHistory(false)
      if (response && response.status == 200) {
        window.open(response.data.data)
      } else {
        notification.error({ message: response.data?.message ?? 'Có lỗi xảy ra, vui lòng thử lại' })
      }
    } catch (e) {
      setLoadingHistory(false)
    }
  }

  const _onDownloadReportBucket = async () => {
    try {
      if (!customerIdBucket) {
        notification.error({ message: 'Vui lòng chọn khách hàng' })
        return
      }
      setLoadingHistory(true)
      var response = await reportInventoryBucket({ customer_id: customerIdBucket })
      setLoadingHistory(false)
      if (response && response.status == 200) {
        window.open(response.data.data)
      } else {
        notification.error({ message: response.data?.message ?? 'Có lỗi xảy ra, vui lòng thử lại' })
      }
    } catch (e) {
      setLoadingHistory(false)
    }
  }

  const _onDownloadErrorMerchant = async () => {
    try {
      setLoadingHistory(true)
      var response = await exportErrorByMerchant({
        customer_id: Number(customerIdError),
      })
      setLoadingHistory(false)
      if (response && response.status == 200) {
        window.open(response.data.data)
      } else {
        notification.error({ message: response.data?.message ?? 'Có lỗi xảy ra, vui lòng thử lại' })
      }
    } catch (e) {
      setLoadingHistory(false)
    }
  }

  useEffect(() => {
    _getWarehouses()
    _getCustomers()
    _getGroups()
    _getTypes()
  }, [])

  useEffect(() => {
    _getProducts()
    saveFiltersToLocalStorage(paramsFilter, KEY_FILTER)
  }, [paramsFilter])

  return (
    <div>
      <BackToTop />

      <Row className="page-title" justify="space-between" align="middle">
        <h3>Sản phẩm</h3>
        <Modal
          title="Chọn doanh nghiệp để xuất excel"
          onCancel={(e) => {
            setVisibleError(false)
          }}
          visible={visibleError}
          maskClosable={false}
          footer={null}
        >
          <Select
            filterOption={filterOptionSelect}
            optionFilterProp="children"
            showSearch
            size="large"
            value={customerIdError}
            onChange={setCustomerIdError}
            style={{ width: '100%' }}
            placeholder="Chọn doanh nghiệp"
          >
            {customers.map((customer, index) => (
              <Select.Option value={customer.customer_id || ''} key={index}>
                {customer.fullname || ''}
              </Select.Option>
            ))}
          </Select>

          <Button
            loading={loadingHistory}
            disabled={loadingHistory}
            onClick={(e) => {
              _onDownloadErrorMerchant()
            }}
            type={'primary'}
            style={{ marginTop: 20 }}
          >
            <div>{'Tải xuống'}</div>
          </Button>
        </Modal>
        <div>
          <Modal
            title="Điền mã sản phẩm"
            onCancel={(e) => {
              setVisibleDownload(false)
            }}
            footer={null}
            visible={visibleDownload}
          >
            <Col style={{ marginTop: 20 }}>
              <Input
                value={inputBarcode}
                onChange={(e) => {
                  setInputBarcode(e.target.value)
                }}
                style={{ width: '100%' }}
                placeholder="điền barcode sản phẩm"
              />

              <Button
                loading={loadingHistory}
                disabled={loadingHistory}
                onClick={(e) => {
                  _onDownloadHistoryInventory()
                }}
                type={'primary'}
                style={{ width: 160, marginTop: 10 }}
              >
                <div>{'Tải'}</div>
              </Button>
            </Col>
          </Modal>
          <Row>
            <Space>
              <ModalOptionsPrint
                printWithInternet={_printBarcode}
                records={selectRows.map((item) => {
                  const product = products.find((product) => product.product_id === item)
                  return product || {}
                })}
              >
                <Button
                  className={style['btn-print']}
                  style={{
                    visibility: !selectRows.length && 'hidden',
                    backgroundColor: '#238800',
                    borderColor: '#238800',
                  }}
                  // onClick={_printBarcode}
                  icon={<PrinterOutlined />}
                  type="primary"
                >
                  In qrcode
                </Button>
              </ModalOptionsPrint>

              <Button
                disabled={loadingHistory}
                loading={loadingHistory}
                type="primary"
                onClick={() => {
                  setVisibleBucket(true)
                }}
              >
                <div>{'Tải báo cáo ô kệ'}</div>
              </Button>

              <Button
                type="primary"
                onClick={() => {
                  setVisibleError(true)
                }}
              >
                <div>{'Tải lệch tồn'}</div>
              </Button>

              <Button
                type="primary"
                onClick={() => {
                  setVisibleDownload(true)
                }}
              >
                <div>{'Tải lịch sử nhập/xuất'}</div>
              </Button>
              <Permissions permissions={[PERMISSIONS.giao_dien_quan_ly_xuat_nhap]}>
                <Button
                  type="primary"
                  onClick={() => history.push(ROUTES.MANAGEMENT_IMPORT_EXPORT)}
                >
                  Lịch sử nhập file
                </Button>
              </Permissions>

              <Permissions permissions={[PERMISSIONS.tao_san_pham]}>
                <ImportFile
                  keyForm={{ customer_id: customerId }}
                  upload={uploadProduct}
                  reload={_getProducts}
                  reset={() => setCustomerId('')}
                  action_name={'Nhập sản phẩm bằng file excel'}
                  title={
                    <Row align="middle" wrap={false}>
                      <b>Nhập sản phẩm bằng file excel</b>
                      <div style={{ marginLeft: 20 }}>
                        <div style={{ fontSize: 13 }}>Chọn doanh nghiệp</div>
                        <Select
                          filterOption={filterOptionSelect}
                          optionFilterProp="children"
                          showSearch
                          size="small"
                          value={customerId}
                          onChange={setCustomerId}
                          style={{ width: 250 }}
                          placeholder="Chọn doanh nghiệp"
                        >
                          {customers.map((customer, index) => (
                            <Select.Option value={customer.customer_id || ''} key={index}>
                              {customer.fullname || ''}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                    </Row>
                  }
                  fileTemplated="https://s3.ap-northeast-1.wasabisys.com/admin-order/2022_09_14_122627b2-c69e-4104-a1f2-1987ff755b73_file_import_san_pham.xlsx"
                />
              </Permissions>
              {/* <ModalExportByMerchant>
                <Button
                  type="primary"
                  style={{ backgroundColor: '#79CF4F', borderColor: '#79CF4F' }}
                >
                  Xuất theo doanh nghiệp
                </Button>
              </ModalExportByMerchant> */}
              <Button
                onClick={async () => {
                  setLoading(true)
                  const res = await exportByFilter(paramsFilter)
                  if (res.status === 200) {
                    const link = res.data.data
                    if (link) saveAs(link, getFileName(link))
                  }
                  setLoading(false)
                }}
                type="primary"
                style={{ backgroundColor: '#79CF4F', borderColor: '#79CF4F' }}
              >
                Xuất theo bộ lọc
              </Button>
              <Popconfirm
                onConfirm={async () => {
                  setLoading(true)
                  const dataExport = await _getProductsExport()
                  await exportExcel(
                    dataExport,
                    'Danh sách sản phẩm ' + moment(new Date()).format('DD-MM-YYYY'),
                    'Xuất excel danh sách sản phẩm toàn bộ'
                  )
                  setLoading(false)
                }}
                title="Xác nhận xuất sản phẩm toàn bộ"
              >
                <Button
                  type="primary"
                  style={{ backgroundColor: '#79CF4F', borderColor: '#79CF4F' }}
                >
                  Xuất toàn bộ
                </Button>
              </Popconfirm>
            </Space>
          </Row>
        </div>
      </Row>
      <Modal
        title="Chọn doanh nghiệp để xuất excel"
        onCancel={(e) => {
          setVisibleBucket(false)
        }}
        visible={visibleBucket}
        maskClosable={false}
        // onOk={(e) => {
        //   _onDownloadReportBucket(true)
        // }}
        footer={null}
      >
        <Select
          filterOption={filterOptionSelect}
          optionFilterProp="children"
          showSearch
          size="large"
          value={customerIdBucket}
          onChange={setCustomerIdBucket}
          style={{ width: '100%' }}
          placeholder="Chọn doanh nghiệp"
        >
          {customers.map((customer, index) => (
            <Select.Option value={customer.customer_id || ''} key={index}>
              {customer.fullname || ''}
            </Select.Option>
          ))}
        </Select>

        <Button
          loading={loadingHistory}
          disabled={loadingHistory}
          onClick={(e) => {
            _onDownloadReportBucket(true)
          }}
          type={'primary'}
          style={{ marginTop: 20 }}
        >
          <div>{'Tải xuống'}</div>
        </Button>
      </Modal>
      <Row style={{ marginTop: 15 }} justify="space-between" wrap={false}>
        <Col lg={8}>
          <Input.Group compact>
            <Input
              style={{ width: '65%' }}
              enterButton
              allowClear
              value={valueSearch}
              onChange={_onSearch}
              placeholder="Tìm kiếm sản phẩm"
            />
            <Select
              style={{ width: '35%' }}
              suffixIcon={<SuffixIconCustom />}
              value={optionSearch}
              onChange={(value) => _selectOption(value)}
            >
              <Select.Option value="name">Tên sản phẩm</Select.Option>
              <Select.Option value="barcode">Mã hàng</Select.Option>
              <Select.Option value="ecommerce_barcode">Mã ERP</Select.Option>
            </Select>
          </Input.Group>
        </Col>
        <Col lg={5}>
          <FilterDate paramsFilter={paramsFilter} setParamsFilter={setParamsFilter} />
        </Col>
        <Col lg={5}>
          <Select
            value={paramsFilter['type_id'] || undefined}
            onChange={(value) => _filterByOption('type_id', value)}
            allowClear
            showSearch
            filterOption={filterOptionSelect}
            placeholder="Lọc theo loại hàng"
            style={{ width: '100%' }}
            dropdownClassName="dropdown-select-custom"
            suffixIcon={<SuffixIconCustom />}
          >
            {types.map((type, index) => (
              <Select.Option value={type.category_id} key={index}>
                {type.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col lg={5}>
          <Select
            value={paramsFilter['group_id'] || undefined}
            onChange={(value) => _filterByOption('group_id', value)}
            allowClear
            showSearch
            filterOption={filterOptionSelect}
            placeholder="Lọc theo nhóm hàng"
            style={{ width: '100%' }}
            dropdownClassName="dropdown-select-custom"
            suffixIcon={<SuffixIconCustom />}
          >
            {groups.map((group, index) => (
              <Select.Option value={group.category_id} key={index}>
                {group.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row wrap={false} style={{ width: '100%', marginTop: 16 }} justify="space-between">
        <Col lg={8}>
          <Select
            value={paramsFilter['sort'] || undefined}
            allowClear
            onChange={(value) => _filterByOption('sort', value)}
            placeholder="Sắp xếp theo số lượng trong kho"
            style={{ width: '100%' }}
            dropdownClassName="dropdown-select-custom"
            suffixIcon={<SuffixIconCustom />}
          >
            <Select.Option value="highest_inventory">Tăng dần</Select.Option>
            <Select.Option value="lowest_inventory">Giảm dần</Select.Option>
          </Select>
        </Col>
        <Col lg={5}>
          <Select
            value={paramsFilter['customer_id'] || undefined}
            onChange={(value) => _filterByOption('customer_id', value)}
            showSearch
            filterOption={filterOptionSelect}
            allowClear
            placeholder="Lọc theo doanh nghiệp"
            style={{ width: '100%' }}
            dropdownClassName="dropdown-select-custom"
            suffixIcon={<SuffixIconCustom />}
          >
            {customers.map((customer, index) => (
              <Select.Option key={index} value={customer.customer_id}>
                {customer.fullname}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col lg={5}>
          <Select
            value={paramsFilter['warehouse_id'] || undefined}
            onChange={(value) => _filterByOption('warehouse_id', value)}
            showSearch
            filterOption={filterOptionSelect}
            allowClear
            placeholder="Lọc theo kho"
            style={{ width: '100%' }}
            dropdownClassName="dropdown-select-custom"
            suffixIcon={<SuffixIconCustom />}
          >
            {warehouses.map((warehouse, index) => (
              <Select.Option key={index} value={warehouse.warehouse_id}>
                {warehouse.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col lg={5}>
          <Select
            value={paramsFilter['exp_date_sort'] || undefined}
            onChange={(value) => _filterByOption('exp_date_sort', value)}
            showSearch
            filterOption={filterOptionSelect}
            allowClear
            placeholder="Sắp xếp theo ngày hết hạn"
            style={{ width: '100%' }}
            dropdownClassName="dropdown-select-custom"
            suffixIcon={<SuffixIconCustom />}
          >
            <Select.Option value="min">Gần nhất</Select.Option>
            <Select.Option value="max">Xa nhất</Select.Option>
          </Select>
        </Col>
      </Row>
      <Row wrap={false} style={{ width: '100%', marginTop: 16 }}>
        <Col lg={8}>
          <DatePicker
            value={paramsFilter['exp_date'] ? moment(paramsFilter['exp_date']) : undefined}
            onChange={(value, dateString) => _filterByOption('exp_date', dateString)}
            style={{ width: '100%' }}
            placeholder="Lọc sản phẩm theo ngày"
          />
        </Col>
        <Col lg={5}>
          <Button
            className={style['btn-clear']}
            onClick={_clearFilters}
            type="danger"
            style={{
              marginLeft: 15,
              display: Object.keys(paramsFilter).length <= 2 && 'none',
            }}
          >
            Xóa bộ lọc
          </Button>
        </Col>
      </Row>

      <Row justify="space-between" style={{ margin: '20px 0' }} wrap={false}>
        <div className={styles['card-statistical']}>
          <p style={{ marginBottom: 5 }}>Số lượng barcode trong kho</p>
          <h4>{statistical ? formatCash(+statistical.sum_quantity) : 0}</h4>
        </div>

        <div className={styles['card-statistical']}>
          <p style={{ marginBottom: 5, textAlign: 'center' }}>Tổng số kiện hàng</p>
          <Row justify="center" style={{ width: '100%' }}>
            <h4>{statistical ? formatCash(+statistical.sum_unit) : 0}</h4>
          </Row>
        </div>
        <div className={styles['card-statistical']}>
          <p style={{ marginBottom: 5 }}>Tổng số CBM</p>
          <h4>{statistical ? formatCash(statistical.sum_cbm.toFixed(0)) : 0} cm² </h4>
        </div>
      </Row>
      <Row justify="space-between" style={{ marginBottom: 10 }}>
        {/* <div>
          <Permissions permissions={[PERMISSIONS.cap_nhat_ma_san_pham]}>
            <ImportFile
              txt="Cập nhật mã hàng/barcode bằng excel"
              colorCode="#79CF4F"
              keyForm={{ customer_id: customerId }}
              upload={updateBarcodeProduct}
              reload={_getProducts}
              reset={() => setCustomerId('')}
              action_name="Cập nhật mã hàng/barcode sản phẩm bằng file excel"
              title={
                <Row align="middle" wrap={false}>
                  <b>Cập nhật mã hàng/barcode bằng file excel</b>
                  <div style={{ marginLeft: 20 }}>
                    <div style={{ fontSize: 13 }}>Chọn doanh nghiệp</div>
                    <Select
                      filterOption={filterOptionSelect}
                      optionFilterProp="children"
                      showSearch
                      size="small"
                      value={customerId}
                      onChange={setCustomerId}
                      style={{ width: 250 }}
                      placeholder="Chọn doanh nghiệp"
                    >
                      {customers.map((customer, index) => (
                        <Select.Option value={customer.customer_id || ''} key={index}>
                          {customer.fullname || ''}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </Row>
              }
              fileTemplated="https://s3.ap-northeast-1.wasabisys.com/admin-order/2022/07/01/d53ca99d-4196-4789-a50b-dddb90e09184/ChangeBarcodeSGL.xlsx"
            />
          </Permissions>
        </div> */}
        <Space style={{ marginBottom: 7 }}>
          <SettingColumns
            columnsRender={columnsProduct}
            columns={columns}
            setColumns={setColumns}
            nameColumn="columnsProduct"
          />
          <Permissions permissions={[PERMISSIONS.tao_san_pham]}>
            <Button
              className={style['btn-create']}
              onClick={() => history.push(ROUTES.PRODUCT_DETAIL)}
              type="primary"
            >
              Tạo sản phẩm
            </Button>
          </Permissions>
          <Button onClick={_getProducts} type="primary" icon={<ReloadOutlined />}>
            Làm mới
          </Button>
        </Space>
      </Row>
      <Table
        loading={loading}
        rowKey="product_id"
        dataSource={products}
        rowSelection={{
          rowSelection: selectRows,
          onChange: (keys) => setSelectRows(keys),
        }}
        rowClassName={(product) => {
          if (product.locations.exp) {
            const thisDay = moment().tz('Asia/Ho_Chi_Minh')
            const exp = moment(moment(product.locations.exp).format('YYYY-MM-DD')).tz(
              'Asia/Ho_Chi_Minh'
            )
            if (moment(exp).diff(thisDay, 'days') < 10) return 'bg-orange'
          }
          return ''
        }}
        style={{ width: '100%' }}
        scroll={{ x: 2600, y: '66vh' }}
        columns={columns.map((column) => {
          if (column.key === 'number')
            return {
              ...column,
              render: (text, record, index) =>
                (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
            }
          if (column.key === 'qr_code')
            return {
              ...column,
              render: (text, record) => <QrCodeProduct value={record.barcode} />,
            }
          if (column.key === 'name')
            return {
              ...column,
              render: (text, record) =>
                isAcceptPermissions([PERMISSIONS.xem_san_pham]) ? (
                  <Link
                    style={{ color: '#0017E3' }}
                    to={{ pathname: ROUTES.PRODUCT_DETAIL, state: record }}
                  >
                    {text}
                  </Link>
                ) : (
                  <b>{text}</b>
                ),
            }
          if (column.key === 'group')
            return {
              ...column,
              render: (text, record) => record.group_info && record.group_info.name,
            }
          if (column.key === 'type')
            return {
              ...column,
              render: (text, record) => record.type_info && record.type_info.name,
            }
          if (column.key === 'price') return { ...column, render: (text) => formatCash(+text) }
          if (column.key === 'available_quantity')
            return {
              ...column,
              render: (text, record) =>
                record.locations && record.locations.available_quantity
                  ? formatCash(+record.locations.available_quantity)
                  : 0,
            }
          if (column.key === 'current_quantity')
            return {
              ...column,
              render: (text, record) =>
                record.locations && record.locations.current_quantity
                  ? formatCash(+record.locations.current_quantity)
                  : 0,
            }
          if (column.key === 'unit')
            return {
              ...column,
              render: (text, record) => record.unit_info && record.unit_info.name,
            }
          if (column.key === 'smallest_unit')
            return {
              ...column,
              render: (text, record) => formatCash(record.smallest_unit || 0),
            }
          if (column.key === 'warehouse')
            return {
              ...column,
              render: (text, record) =>
                record.locations &&
                record.locations.warehouse_info &&
                (record.locations.warehouse_info.name || ''),
            }
          if (column.key === 'exp')
            return {
              ...column,
              render: (text, record) => {
                const timeDateNow = new Date().getTime()
                const timeDateExpire = record.locations
                  ? new Date(record.locations.exp).getTime()
                  : 0
                const isExpired = timeDateNow > timeDateExpire
                const isExpire = Math.abs(timeDateNow - timeDateExpire) < 3600000 * 24
                return record.locations && record.locations.exp ? (
                  <Row align="middle">
                    {moment(record.locations.exp).format('DD-MM-YYYY HH:mm')}{' '}
                    {(isExpired || isExpire) && (
                      <Tooltip
                        title={
                          (isExpired && 'Đã hết hạn sử dụng') || (isExpire && 'Sắp hết hạn sử dụng')
                        }
                      >
                        <WarningOutlined style={{ color: 'red', marginLeft: 5 }} />
                      </Tooltip>
                    )}
                  </Row>
                ) : (
                  ''
                )
              },
            }
          if (column.key === 'mfg')
            return {
              ...column,
              render: (text, record) =>
                record.locations && record.locations.mfg
                  ? moment(record.locations.mfg).format('DD-MM-YYYY HH:mm')
                  : '',
            }
          if (column.key === 'customer')
            return {
              ...column,
              render: (text, record) => record.customer_info && record.customer_info.fullname,
            }
          if (column.key === 'available')
            return {
              ...column,
              render: (text, record) => (
                <Row align="middle" wrap={false}>
                  <div style={{ marginRight: 7 }}>
                    {record.active ? 'Khả dụng' : 'Không khả dụng'}
                  </div>
                  <Permissions permissions={[PERMISSIONS.cap_nhat_san_pham]}>
                    <Switch
                      onChange={(checked) => _updateProduct({ active: checked }, record.product_id)}
                      checked={record.active}
                    />
                  </Permissions>
                </Row>
              ),
            }

          if (column.key === 'image')
            return {
              ...column,
              render: (text, record) => (
                <ZoomImage image={record.images[0] || IMAGE_DEFAULT}>
                  <img
                    style={{ width: 50, height: 50 }}
                    alt=""
                    src={record.images[0] || IMAGE_DEFAULT}
                  />
                </ZoomImage>
              ),
            }
          if (column.key === 'action')
            return {
              ...column,
              render: (text, record) => (
                <Permissions permissions={[PERMISSIONS.xoa_san_pham]}>
                  <Popconfirm
                    placement="topRight"
                    onConfirm={() => _deleteProducts(record.product_id)}
                    title="Bạn có muốn xóa sản phẩm này không?"
                    okText="Đồng ý"
                    cancelText="Từ chối"
                  >
                    <DeleteOutlined style={{ color: 'red', fontSize: 18 }} />
                  </Popconfirm>
                </Permissions>
              ),
            }
          return column
        })}
        size={SIZE_TABLE}
        pagination={{
          current: paramsFilter.page,
          pageSize: paramsFilter.page_size,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          showQuickJumper: true,
          onChange: (page, pageSize) => {
            setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize })
          },
          total: countProduct,
        }}
        summary={() => (
          <Table.Summary fixed="bottom">
            <Table.Summary.Row></Table.Summary.Row>
          </Table.Summary>
        )}
        sticky
      />
    </div>
  )
}
