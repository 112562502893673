const columns = [
  {
    key: 'stt',
    title: 'STT',
    dataIndex: 'stt',
    align: 'center',
    width: 50,
  },
  {
    title: 'Mã doanh nghiệp',
    dataIndex: 'code',
    align: 'center',
  },
  {
    key: 'fullname',
    title: 'Tên doanh nghiệp',
    dataIndex: 'fullname',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'phone',
    align: 'center',
  },
  {
    key: 'address',
    title: 'Địa chỉ',
    dataIndex: 'address',
  },
  {
    title: 'Loại doanh nghiệp',
    dataIndex: 'type',
    key: 'type',
    align: 'center',
  },
  {
    title: 'Kho sử dụng',
    key: 'warehouse',
    align: 'center',
  },
  {
    key: 'active',
    title: 'Trạng thái',
    dataIndex: 'active',
    align: 'center',
  },
  {
    key: 'fees',
    title: 'Chi phí',
    dataIndex: 'fees',
    align: 'center',
  },
  {
    key: 'create_date',
    title: 'Ngày tạo',
    align: 'center',
  },
  {
    key: 'action',
    title: 'Hành động',
    align: 'center',
  },
]

export default columns
