import React, { useEffect, useState } from 'react'

//antd
import { Modal, notification, Form, Input } from 'antd'

//apis
import { createUnit, updateUnit } from 'apis/unit-product'
import { _addLog } from 'utils'

export default function ModalUnitForm({ record, children, reload }) {
  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)

  const _createOrUpdateUnit = async () => {
    try {
      setLoading(true)
      const body = form.getFieldsValue()

      let res
      if (record) res = await updateUnit(record.unit_id, body)
      else res = await createUnit(body)
      setLoading(false)

      if (res.status === 200) {
        if (res.data.success) {
          toggle()
          if (reload) reload()
          notification.success({ message: `${record ? 'Cập nhật' : 'Thêm'} quy cách thành công` })
        } else
          notification.error({
            message:
              res.data.message ||
              `${record ? 'Cập nhật' : 'Thêm'} quy cách thất bại, vui lòng thử lại`,
          })
      } else
        notification.error({
          message:
            res.data.message ||
            `${record ? 'Cập nhật' : 'Thêm'} quy cách thất bại, vui lòng thử lại`,
        })
    } catch (error) {
      setLoading(false)
      console.log(error)
      _addLog(`Create or update unit: ${JSON.stringify(error)}`)
    }
  }

  useEffect(() => {
    if (!visible) form.resetFields()
    else {
      if (record) form.setFieldsValue({ ...record })
    }
  }, [visible])

  return (
    <>
      <div onClick={toggle}>{children}</div>
      <Modal
        title={`${record ? 'Cập nhật' : 'Thêm'} quy cách ${record ? `#${record.code}` : ''}`}
        onOk={_createOrUpdateUnit}
        confirmLoading={loading}
        visible={visible}
        onCancel={toggle}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Tên quy cách"
            rules={[{ message: 'Vui lòng nhập tên quy cách', required: true }]}
          >
            <Input allowClear placeholder="Nhập tên quy cách" />
          </Form.Item>
          <Form.Item
            name="conversion_quantity"
            label="Số lượng quy đổi"
            rules={[{ message: 'Vui lòng nhập số lượng quy đổi', required: true }]}
          >
            <Input allowClear placeholder="Nhập số lượng quy đổi" />
          </Form.Item>
          <Form.Item
            name="representation_string"
            label="Kí tự đại diện đơn vị tính"
            rules={[{ message: 'Vui lòng nhập kí tự đại diện', required: true }]}
          >
            <Input allowClear placeholder="Nhập kí tự đại diện" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
