//lib
import { Link, useHistory } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import SuffixIconCustom from 'components/suffixIconCustom'
import { _addLog, saveFiltersToLocalStorage, filterOptionSelect, isAcceptPermissions } from 'utils'

//style
import styles from './shippingCompany.module.scss'

// antd
import { Col, Row, Button, Input, Table, notification, Select, Popconfirm, Space } from 'antd'
import { POSITION_TABLE, ROUTES, PAGE_SIZE_OPTIONS, SIZE_TABLE, PERMISSIONS } from 'consts'
import { CloseCircleOutlined, DeleteOutlined, ReloadOutlined } from '@ant-design/icons'

// component
import SettingColumnsButton from 'components/setting-columns'
import BackToTop from 'components/back-to-top'
import shippingCompanyColumns from './shippingCompanyColumns'
import Permission from 'components/permission'

//apis
import { getShippingCompany, deleteShippingCompany } from 'apis/shipping-company'
import { getAllCustomer } from 'apis/customer'

const { Option } = Select
export default function ShippingCompany() {
  const KEY_FILTER = 'shippingCompanyFilters'
  const filters = localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))
  const typingTimeoutRef = useRef(null)
  const history = useHistory()

  const [paramsFilter, setParamsFilter] = useState(
    filters ? { ...filters } : { page: 1, page_size: 10 }
  )

  const [customers, setCustomers] = useState([])
  const [shippingCompanyData, setShippingCompanyData] = useState([])
  const [loading, setLoading] = useState(false)
  const [maxRecord, setMaxRecord] = useState(0)
  const [columns, setColumns] = useState([])

  const [valueSearch, setValueSearch] = useState(
    filters ? filters.name || filters.email || filters.phone || '' : ''
  )
  const [valueSelect, setValueSelect] = useState(
    filters
      ? (filters.name && 'name') ||
          (filters.email && 'email') ||
          (filters.phone && 'phone') ||
          'name'
      : 'name'
  )

  const _getCustomers = async () => {
    try {
      const res = await getAllCustomer({ get_all: true })
      if (res.status === 200) setCustomers(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _filterByOption = (attribute = '', value = '') => {
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter, page: 1 })
  }

  const _geShippings = async () => {
    try {
      setLoading(true)
      const res = await getShippingCompany(paramsFilter)
      if (res.status === 200) {
        setShippingCompanyData(res.data.data)
        setMaxRecord(res.data.count)
      }
      setLoading(false)
    } catch (error) {
      _addLog(JSON.stringify(error))
      setLoading(false)
      console.log(error)
    }
  }

  const _removeShippingCompany = async (id) => {
    try {
      setLoading(true)
      const res = await deleteShippingCompany({ shippingcompany_id: [id] })
      if (res.data.success) {
        notification.success({ message: 'Xoá đơn vị vận chuyển thành công' })
        _geShippings()
      } else {
        notification.error({
          message: res.data.message || 'Xóa đơn vị vận chuyển thất bại',
        })
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      notification.error({ message: 'Xóa đơn vị vận chuyển thất bại' })
      setLoading(false)
      _addLog(`Delete shipping company: ${JSON.stringify(error)}`)
    }
  }

  const _search = (e) => {
    setValueSearch(e.target.value)

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }

    typingTimeoutRef.current = setTimeout(async () => {
      const value = e.target.value
      if (value) {
        //khi search hoac filter thi reset page ve 1
        paramsFilter.page = 1
        paramsFilter[valueSelect] = value
      } else {
        delete paramsFilter[valueSelect]
      }
      setParamsFilter({ ...paramsFilter })
    }, 450)
  }

  const _select = (value) => {
    delete paramsFilter[valueSelect]
    setValueSelect(value)
    if (valueSearch) {
      paramsFilter.page = 1

      //lấy giá trị select mới để lọc lại dữ liệu
      setParamsFilter({
        ...paramsFilter,
        [value]: valueSearch,
      })
    }
  }

  useEffect(() => {
    _getCustomers()
  }, [])

  useEffect(() => {
    _geShippings()
    saveFiltersToLocalStorage(paramsFilter, KEY_FILTER)
  }, [paramsFilter])

  const renderColumns = {
    stt: {
      render(data, record, index) {
        return (paramsFilter.page - 1) * paramsFilter.page_size + index + 1
      },
    },
    logo: {
      render(data) {
        return data ? <img src={data} alt="logo" style={{ width: '50px', height: '50px' }} /> : ''
      },
    },
    name: {
      render(data, record) {
        return isAcceptPermissions([PERMISSIONS.cap_nhat_don_vi_van_chuyen]) ? (
          <Link
            to={{ pathname: ROUTES.SHIPPING_COMPANY_ADD, state: record }}
            style={{ color: '#0017E3' }}
          >
            {data}
          </Link>
        ) : (
          <b>{data}</b>
        )
      },
    },
    date: {
      render(text, data) {
        return data.create_date && moment(data.create_date).format('DD/MM/YYYY')
      },
    },
    action: {
      render(data, record) {
        return (
          <Permission permissions={[PERMISSIONS.xoa_don_vi_van_chuyen]}>
            <Popconfirm
              placement="topRight"
              title="Bạn có muốn xóa đơn vị vận chuyển này không?"
              onConfirm={() => _removeShippingCompany(record.shippingcompany_id)}
              okText="Đồng ý"
              cancelText="Từ chối"
            >
              <Button danger type="primary">
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Permission>
        )
      },
    },
    customer: {
      render(text, record) {
        return record.customer_info && record.customer_info.fullname
      },
    },
    address: {
      render(data, record) {
        return (
          <span>
            {record?.address || record?.ward || record?.district || record?.province
              ? `${record?.address}, ${record?.ward}, ${record?.district}, ${record?.province}`
              : ''}
          </span>
        )
      },
    },
  }

  return (
    <div className={styles['shipping-company']}>
      <BackToTop />
      <Row className="page-title" justify="space-between">
        <h3>Đơn vị vận chuyển</h3>
        <Space>
          <Button onClick={_geShippings} type="primary" icon={<ReloadOutlined />}>
            Làm mới
          </Button>
          <SettingColumnsButton
            columns={columns}
            columnsRender={shippingCompanyColumns}
            setColumns={setColumns}
            nameColumn="columnsShipping"
          />
          <Permission permissions={[PERMISSIONS.tao_don_vi_van_chuyen]}>
            <Button
              type="primary"
              className={styles['shipping-company-button--blue']}
              onClick={() => history.push(ROUTES.SHIPPING_COMPANY_ADD)}
            >
              Tạo đơn vị vận chuyển
            </Button>
          </Permission>
        </Space>
      </Row>
      <div className={styles['shipping-company-content']}>
        <Row gutter={15} style={{ margin: '20px 0 25px 0' }}>
          <Col span={12}>
            <Input.Group compact>
              <Input
                allowClear
                enterButton
                placeholder="Tìm kiếm theo"
                style={{ boxShadow: '0px 2px 6px rgba(19, 216, 229, 0.13)', width: '70%' }}
                onChange={_search}
                value={valueSearch}
              />
              <Select
                value={valueSelect}
                style={{ width: '30%' }}
                suffixIcon={<SuffixIconCustom />}
                className={styles['report-filter-group-item']}
                onChange={_select}
              >
                <Option value="name">Tên đối tác</Option>
                <Option value="email">Email</Option>
                <Option value="phone">Số điện thoại</Option>
              </Select>
            </Input.Group>
          </Col>
          <Col span={7}>
            <Select
              value={paramsFilter['customer_id'] || undefined}
              onChange={(value) => _filterByOption('customer_id', value)}
              showSearch
              filterOption={filterOptionSelect}
              allowClear
              placeholder="Lọc theo doanh nghiệp"
              style={{ width: '100%' }}
              dropdownClassName="dropdown-select-custom"
              suffixIcon={<SuffixIconCustom />}
            >
              {customers.map((customer, index) => (
                <Select.Option key={index} value={customer.customer_id}>
                  {customer.fullname}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Table
          size={SIZE_TABLE}
          rowKey="shippingcompany_id"
          loading={loading}
          columns={columns.map((column) => {
            if (renderColumns[column.key]) {
              return { ...column, ...renderColumns[column.key] }
            }
            return column
          })}
          scroll={{ y: '70vh' }}
          dataSource={shippingCompanyData}
          className={styles['table']}
          style={{ width: '100%' }}
          pagination={{
            position: POSITION_TABLE,
            current: paramsFilter.page,
            pageSize: paramsFilter.page_size,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            total: maxRecord,
            showQuickJumper: true,
            onChange: (page, pageSize) => {
              setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize })
            },
          }}
        />
      </div>
    </div>
  )
}
