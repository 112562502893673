import React, { useEffect, useRef, useState } from 'react'
import styles from './warehouse.module.scss'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { _addLog, saveFiltersToLocalStorage, isAcceptPermissions } from 'utils'

// import ant
import {
  Button,
  Select,
  Row,
  Col,
  Table,
  Tag,
  notification,
  Space,
  Input,
  Popconfirm,
  Switch,
  Modal,
} from 'antd'
import { CloseCircleOutlined, DeleteOutlined, ReloadOutlined } from '@ant-design/icons'

//components
import SuffixIconCustom from 'components/suffixIconCustom'
import warehouseColumns from './warehouse-columns'
import SettingColumns from 'components/setting-columns'
import { PAGE_SIZE_OPTIONS, PERMISSIONS, POSITION_TABLE, ROUTES, SIZE_TABLE } from 'consts'
import BackToTop from 'components/back-to-top'
import printToThermalPrinter from 'components/thermal-printer/print'
import { barcode } from 'components/thermal-printer/templates'
import ModalOptionsPrint from 'components/modal-opions-print'
import Permission from 'components/permission'
import FilterDate from 'components/filter-date'

//apis
import { deleteWarehouse, getWarehouses, updateWarehouse } from 'apis/warehouse'
import { getTheShelve, addTheShelve, deleteTheShelve } from 'apis/shelve'
import { getWard, getProvince, getDistrict } from 'apis/address'

const { Option } = Select
export default function Warehouse() {
  const history = useHistory()
  const KEY_FILTER = 'warehouseFilters'
  const filters = localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))

  const [paramsFilter, setParamsFilter] = useState(
    filters
      ? { ...filters }
      : {
          page: 1,
          page_size: 10,
          shelve_info: true,
          // bucket_info: true,
          basket_info: true,
          active: true,
        }
  )
  const [warehouseData, setWarehouseData] = useState([])
  const [warehouseOptions, setWarehouseOptions] = useState([])

  const typingTimeoutRef = useRef()
  const [valueSearch, setValueSearch] = useState(filters ? filters.name || '' : '')

  const [maxRecord, setMaxRecord] = useState(0)
  const [columns, setColumns] = useState([])
  const [tableLoading, setTableLoading] = useState(false)

  const [theShelveData, setTheShelveData] = useState([])
  const [shelveDelete, setShelveDelete] = useState()

  const [valueProvince, setValueProvince] = useState()
  const [valueDistrict, setValueDistrict] = useState()

  const [dataProvince, setDataProvince] = useState([])
  const [dataDistrict, setDataDistrict] = useState([])
  const [dataWard, setDataWard] = useState([])
  const [visibleShelve, setVisibleShelve] = useState(false)
  const [itemShelveSelected, setItemShelveSelected] = useState()
  const [hotnameValue, setHotnameValue] = useState('')

  const _getWarehouses = async () => {
    try {
      setTableLoading(true)
      const res = await getWarehouses(paramsFilter)
      if (res.data.success) {
        setWarehouseData(res.data.data)
        setMaxRecord(res.data.count)
        // set dữ liệu cho chọn ký hiệu kho
        if (Object.keys(paramsFilter).length === 5) setWarehouseOptions(res.data.data)
      }
      setTableLoading(false)
    } catch (error) {
      _addLog(JSON.stringify(error))
      setTableLoading(false)
      console.log(error)
    }
  }

  const _getTheShelve = async () => {
    try {
      const res = await getTheShelve()
      if (res.data.success) {
        setTheShelveData(res.data.data)
      }
    } catch (err) {
      _addLog(JSON.stringify(err))
      console.log(err)
    }
  }

  const _deleteWarehouseOrShelve = async (id) => {
    try {
      let res
      if (!shelveDelete) {
        res = await deleteWarehouse({ warehouse_id: [id] })
      } else if (shelveDelete) {
        res = await deleteTheShelve({ shelve_id: [shelveDelete.shelve_id] })
      }
      if (res.data.success) {
        notification.success({
          message: shelveDelete ? 'Xóa kệ hàng thành công' : 'Xóa kho hàng thành công',
        })

        setShelveDelete()
        _getTheShelve()
        _getWarehouses()
      } else {
        notification.error({
          message:
            res.data.message || (shelveDelete ? 'Xóa kệ hàng thất bại' : 'Xóa kho hàng thất bại'),
        })
      }
    } catch (err) {
      _addLog(`Delete warehouse or shelve: ${JSON.stringify(err)}`)
      console.log(err)
      notification.error({
        message: shelveDelete ? 'Xóa kệ hàng thất bại' : 'Xóa kho hàng thất bại',
      })
    }
  }

  const _addShelve = async () => {
    try {
      const res = await addTheShelve({
        ...itemShelveSelected,
        warehouse_id: itemShelveSelected.warehouse_id,
        parameter_id: -1,
        shelve_quantity: 1,
        hotname: hotnameValue,
      })
      setVisibleShelve(false)

      if (res.status === 200) {
        if (res.data.success) {
          _getTheShelve()
          _getWarehouses()

          notification.success({
            message: 'Tạo kệ hàng thành công',
          })
        } else {
          notification.error({
            message: 'Tạo kệ hàng thất bại',
          })
        }
      } else {
        notification.error({
          message: 'Tạo kệ hàng thất bại',
        })
      }
    } catch (err) {
      notification.error({ message: 'Tạo kệ hàng thất bại' })
    }
  }

  const _clearFilters = () => {
    setValueSearch('')
    setValueProvince()
    setValueDistrict()

    Object.keys(paramsFilter).map((key) => delete paramsFilter[key])
    paramsFilter.page = 1
    paramsFilter.page_size = 10
    paramsFilter.shelve_info = true
    paramsFilter.bucket_info = true
    paramsFilter.basket_info = true
    setParamsFilter({ ...paramsFilter })
  }

  const _onSearch = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value

      //khi search hoac filter thi reset page ve 1
      paramsFilter.page = 1

      if (value) paramsFilter.name = value
      else delete paramsFilter.name

      setParamsFilter({ ...paramsFilter })
    }, 650)
  }

  const _filterByOption = (attribute = '', value = '') => {
    paramsFilter.page = 1

    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]

    setParamsFilter({ ...paramsFilter })
  }

  const _updateWarehouse = async (record) => {
    try {
      const body = { active: !record.active }
      const res = await updateWarehouse(record.warehouse_id, body)
      if (res.status === 200) {
        if (res.data.success) {
          _getWarehouses()
          notification.success({ message: 'Cập nhật trạng thái thành công' })
        } else notification.error({ message: res.data.message || 'Cập nhật trạng thái thất bại' })
      } else notification.error({ message: res.data.message || 'Cập nhật trạng thái thất bại' })
    } catch (error) {
      _addLog(`Update warehouse: ${JSON.stringify(error)}`)
      console.log(error)
    }
  }

  const _printBarcodeAll = (shelves) => {
    shelves.map((shelve) =>
      printToThermalPrinter(barcode, {
        barcode: shelve.code,
        title: 'Ke hang: ' + shelve.hotname?.replace(/-{1,}/g, ' '),
      })
    )
  }

  const _printBarcode = (shelve) => {
    if (shelve)
      printToThermalPrinter(barcode, {
        barcode: shelve.code,
        title: 'Ke hang: ' + shelve.hotname?.replace(/-{1,}/g, ' '),
      })
  }

  const expandedRowRender = (data) => {
    let dataShelve = []
    if (data.warehouse_id) {
      dataShelve = theShelveData.filter((item) => item.warehouse_id === data.warehouse_id)
    }
    return (
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <ModalOptionsPrint
          printWithInternet={() => _printBarcodeAll(dataShelve)}
          records={dataShelve}
        >
          <Button
            style={{ marginBottom: 10 }}
            type="primary"
            // onClick={() => _printBarcodeAll(dataShelve)}
          >
            In QR Code hàng loạt
          </Button>
        </ModalOptionsPrint>

        <Table
          dataSource={dataShelve}
          columns={shelveColumns.map((column) => {
            if (column.key === 'stt')
              return { ...column, render: (text, record, index) => index + 1 }
            if (column.key === 'create_date')
              return {
                ...column,
                render: (text) => text && moment(text).format('DD/MM/YYYY'),
              }
            if (column.key === 'delete')
              return {
                ...column,
                render: (data, record) => (
                  <Space>
                    <ModalOptionsPrint
                      printWithInternet={() => _printBarcode(record)}
                      records={[record]}
                    >
                      <Button type="primary">In QR Code</Button>
                    </ModalOptionsPrint>

                    <Permission permissions={[PERMISSIONS.xoa_kho_hang]}>
                      <Popconfirm
                        title="Bạn có muốn xóa kệ hàng này không?"
                        cancelText="Từ chối"
                        okText="Đồng ý"
                        onConfirm={_deleteWarehouseOrShelve}
                      >
                        <Button
                          type="primary"
                          danger
                          onClick={(e) => {
                            setShelveDelete(record)
                            e.stopPropagation()
                          }}
                        >
                          <DeleteOutlined />
                        </Button>
                      </Popconfirm>
                    </Permission>
                  </Space>
                ),
              }
            return column
          })}
          pagination={false}
        />
      </div>
    )
  }

  const renderColumns = {
    stt: {
      render(data, record, index) {
        return (paramsFilter.page - 1) * paramsFilter.page_size + index + 1
      },
    },
    name: {
      render(data, record) {
        return isAcceptPermissions([PERMISSIONS.cap_nhat_kho_hang]) ? (
          <span
            style={{ color: '#0017E3', cursor: 'pointer' }}
            onClick={() => history.push({ pathname: ROUTES.WAREHOUSE_ADD, state: record })}
          >
            {data}
          </span>
        ) : (
          <b>{data}</b>
        )
      },
    },
    address: {
      render(data, record) {
        return record?.address || record?.ward || record?.district || record?.province
          ? `${record?.address}, ${record?.ward}, ${record?.district}, ${record?.province}`
          : ''
      },
    },
    shelves: {
      render(data) {
        return <span style={{ color: '#0017E3' }}>{data}</span>
      },
    },
    baskets: {
      render(data) {
        return <span style={{ color: '#0017E3' }}>{data}</span>
      },
    },
    phone: {
      render(data, record) {
        return record.phone || ''
      },
    },
    create_date: {
      render(data) {
        return moment(data).format('DD/MM/YYYY')
      },
    },
    active: {
      render(data) {
        return data ? (
          <Tag color="green">Đang hoạt động</Tag>
        ) : (
          <Tag color="red">Không hoạt động</Tag>
        )
      },
    },
    update_status: {
      render(text, record) {
        return (
          <Switch
            disabled={!isAcceptPermissions([PERMISSIONS.cap_nhat_kho_hang])}
            checked={record.active}
            onChange={() => _updateWarehouse(record)}
          />
        )
      },
    },
    addShelve: {
      render(data, record) {
        return (
          <Permission permissions={[PERMISSIONS.tao_ke_hang]}>
            <Button
              className={styles['warehouse-table-btn--create-shelve']}
              onClick={() => {
                setItemShelveSelected(record)
                setVisibleShelve(true)
              }}
            >
              Tạo kệ hàng
            </Button>
          </Permission>
        )
      },
    },
  }

  const shelveColumns = [
    {
      key: 'stt',
      title: 'STT',
      dataIndex: 'stt',
      align: 'center',
      width: 50,
    },
    {
      title: 'Ký hiệu',
      dataIndex: 'hotname',
      align: 'center',
    },
    {
      title: 'Số lượng ô chứa',
      dataIndex: 'ochua',
      align: 'center',
    },
    {
      key: 'create_date',
      title: 'Ngày tạo',
      dataIndex: 'create_date',
      align: 'center',
    },
    {
      key: 'delete',
      align: 'center',
    },
  ]

  const _getDataProvince = async () => {
    try {
      const res = await getProvince()
      if (res.status === 200) setDataProvince(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _getDataDistrict = async (params) => {
    try {
      let res
      if (params) {
        res = await getDistrict(params)
      } else {
        res = await getDistrict()
      }
      if (res.status === 200) setDataDistrict(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _getDataWard = async (params) => {
    try {
      let res
      if (params) {
        res = await getWard(params)
      } else {
        res = await getWard()
      }
      if (res.status === 200) setDataWard(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  useEffect(() => {
    if (valueProvince) {
      _getDataDistrict({ province_name: valueProvince })
      _getDataWard({ province_name: valueProvince })
    } else {
      _getDataDistrict()
      _getDataWard()
    }
  }, [valueProvince])

  useEffect(() => {
    if (valueDistrict) {
      _getDataWard({ district_name: valueDistrict })
    } else {
      _getDataWard()
    }
  }, [valueDistrict])

  useEffect(() => {
    _getTheShelve()
    _getDataProvince()
  }, [])

  useEffect(() => {
    _getWarehouses()
    saveFiltersToLocalStorage(paramsFilter, KEY_FILTER)
  }, [paramsFilter])

  return (
    <div className={styles['warehouse']}>
      <BackToTop />
      <Modal
        onCancel={(e) => {
          setVisibleShelve(false)
        }}
        footer={null}
        visible={visibleShelve}
      >
        <div>{'Vui lòng điền ký hiệu dãy kệ'}</div>
        <Input
          value={hotnameValue}
          onChange={(e) => {
            setHotnameValue(e.target.value)
          }}
        />
        <Row style={{ marginTop: 20 }} justify={'center'}>
          <Button
            onClick={(e) => {
              _addShelve()
            }}
          >
            <div>{'Tạo'}</div>
          </Button>
        </Row>
      </Modal>
      <div className="page-title">
        <h3>Quản lý kho hàng</h3>
        <Space>
          <Button onClick={_getWarehouses} type="primary" icon={<ReloadOutlined />}>
            Làm mới
          </Button>
          <SettingColumns
            width={600}
            columns={columns}
            columnsRender={warehouseColumns}
            setColumns={setColumns}
            nameColumn="warehouseColumns"
          />
          <Permission permissions={[PERMISSIONS.tao_kho_hang]}>
            <Button
              className={styles['warehouse-btn-create']}
              onClick={() => history.push(ROUTES.WAREHOUSE_ADD)}
            >
              Tạo kho hàng
            </Button>
          </Permission>
        </Space>
      </div>
      <Row style={{ marginBottom: 20, marginTop: 20 }} gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <Input.Search
            value={valueSearch}
            allowClear
            onChange={_onSearch}
            enterButton
            placeholder="Tìm kiếm tên kho"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <Select
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={paramsFilter['hotname'] || undefined}
            onChange={(value) => _filterByOption('hotname', value)}
            style={{ width: '100%' }}
            placeholder="Lọc theo ký hiệu kho"
            suffixIcon={<SuffixIconCustom />}
          >
            {warehouseOptions.map((warehouse, index) => (
              <Select.Option key={index} value={warehouse.hotname}>
                {warehouse.hotname}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <FilterDate paramsFilter={paramsFilter} setParamsFilter={setParamsFilter} />
        </Col>

        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <Select
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(value) => {
              setValueProvince(value)
              _filterByOption('province', value)
            }}
            value={paramsFilter['province'] || undefined}
            className={styles['customer-filter-group-item']}
            style={{ width: '100%' }}
            placeholder="Lọc theo tỉnh/thành"
            suffixIcon={<SuffixIconCustom />}
          >
            {dataProvince.map((item, index) => (
              <Option key={index} value={item.province_name}>
                {item.province_name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <Select
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(value) => {
              setValueDistrict(value)
              _filterByOption('district', value)
            }}
            value={paramsFilter['district'] || undefined}
            className={styles['customer-filter-group-item']}
            style={{ width: '100%' }}
            placeholder="Lọc theo quận/huyện"
            suffixIcon={<SuffixIconCustom />}
          >
            {dataDistrict.map((item, index) => (
              <Option key={index} value={item.district_name}>
                {item.district_name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <Select
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(value) => _filterByOption('ward', value)}
            value={paramsFilter['ward'] || undefined}
            className={styles['customer-filter-group-item']}
            style={{ width: '100%' }}
            placeholder="Lọc theo phường/xã"
            suffixIcon={<SuffixIconCustom />}
          >
            {dataWard.map((item, index) => (
              <Option key={index} value={item.ward_name}>
                {item.ward_name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <Select
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(value) => {
              if (value !== undefined) paramsFilter.active = value
              else delete paramsFilter.active

              setParamsFilter({ ...paramsFilter })
            }}
            value={paramsFilter['active']}
            className={styles['customer-filter-group-item']}
            style={{ width: '100%' }}
            placeholder="Lọc theo trạng thái"
            suffixIcon={<SuffixIconCustom />}
          >
            <Select.Option value={true}>Đang hoạt động</Select.Option>
            <Select.Option value={false}>Không hoạt động</Select.Option>
          </Select>
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <Button
            danger
            type="primary"
            icon={<CloseCircleOutlined />}
            style={{
              display: Object.keys(paramsFilter).length <= 5 && 'none',
            }}
            onClick={_clearFilters}
          >
            Xóa bộ lọc
          </Button>
        </Col>
      </Row>
      <Table
        rowKey="warehouse_id"
        size={SIZE_TABLE}
        loading={tableLoading}
        expandable={{
          expandedRowRender,
        }}
        columns={columns.map((e) => {
          if (renderColumns[e.key]) {
            return { ...e, ...renderColumns[e.key] }
          }
          return e
        })}
        dataSource={warehouseData}
        pagination={{
          position: POSITION_TABLE,
          current: paramsFilter.page,
          pageSize: paramsFilter.page_size,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          total: maxRecord,
          showQuickJumper: true,
          onChange: (page, pageSize) => {
            paramsFilter.page = page
            paramsFilter.page_size = pageSize
            setParamsFilter({ ...paramsFilter })
          },
        }}
        className={styles['warehouse-table']}
      />
    </div>
  )
}
