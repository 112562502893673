import moment from 'moment'
import S3 from 'aws-sdk/clients/s3'
import AWS from 'aws-sdk'

import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import { post } from './axiosClient'

/* config upload S3 */
const wasabiEndpoint = new AWS.Endpoint(process.env.REACT_APP_S3_URL)
const ENDPOINT_URL_IMAGE = `https://s3.ap-northeast-1.wasabisys.com/${process.env.REACT_APP_BUCKET_NAME}/`
const upload = new S3({
  endpoint: wasabiEndpoint,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
})
/* config upload S3 */

export const uploadFile = async (file) => {
  // try {
  //   if (!file) return ''
  //   let formData = new FormData()
  //   formData.append('file', file)
  //   const res = await post('/upload/single', formData)
  //   if (res.status === 200) if (res.data.data) return res.data.data
  //   return ''
  // } catch (error) {
  //   return ''
  // }

  try {
    const _d = moment(new Date()).format('YYYY/MM/DD') + '/' + uuidv4()

    if (!file) return ''

    // let fileName = file.name.split('.')
    // let fileNameUpload = _d + '/' + fileName.toString().replaceAll(',', '.')
    // fileNameUpload = fileNameUpload.replace(/[&#+()$~%'":*?<>{}]/g, '')
    // const fileNameFinal = fileNameUpload.replaceAll(' ', '')

    // await upload
    //   .putObject({
    //     Body: file,
    //     Bucket: process.env.REACT_APP_BUCKET_NAME,
    //     Key: fileNameFinal,
    //     ACL: 'public-read',
    //   })
    //   .promise()

    // return ENDPOINT_URL_IMAGE + fileNameFinal

    let formData = new FormData()
    formData.append('file', file)
    const res = await post('/upload/single', formData)
    if (res.status === 200) {
      if (res.data.success) {
        return res.data.data
      } else {
        return ''
      }
    }

    return ''
  } catch (error) {
    console.log(error)
    return ''
  }
}

export const uploadFiles = async (files) => {
  // try {
  //   if (!files) return []
  //   let formData = new FormData()
  //   files.map((file) => formData.append('files', file))
  //   const res = await post('/upload/multi', formData)
  //   if (res.status === 200) if (res.data.data) return res.data.data
  //   return []
  // } catch (error) {
  //   return []
  // }

  try {
    const _d = moment(new Date()).format('YYYY/MM/DD') + '/' + uuidv4()
    if (!files) return []
    // let arrayFileName = []
    // const promises = files.map(async (file) => {
    //   let fileName = file.name.split('.')
    //   let fileNameUpload = _d + '/' + fileName.toString().replaceAll(',', '.')
    //   fileNameUpload = fileNameUpload.replace(/[&#+()$~%'":*?<>{}]/g, '')
    //   const fileNameFinal = fileNameUpload.replaceAll(' ', '')
    //   arrayFileName.push(fileNameFinal)
    //   const res = upload
    //     .putObject({
    //       Body: file,
    //       Bucket: process.env.REACT_APP_BUCKET_NAME,
    //       Key: fileNameFinal,
    //       ACL: 'public-read',
    //     })
    //     .promise()
    //   return res
    // })
    // await Promise.all(promises)
    // let listUrl = arrayFileName.map((name) => ENDPOINT_URL_IMAGE + name)
    // return listUrl || []

    let formData = new FormData()
    files.map((file) => formData.append('files', file))
    const res = await post('/upload/multi', formData)
    if (res.status === 200) if (res.data.success) return res.data.data
    return []
  } catch (error) {
    console.log(error)
    return []
  }
}

export const uploadImportOrder = (formData) => post('/importorder/file/import', formData)

export const uploadProduct = (formData) => post('/product/file/import', formData)

export const uploadFileTypeBlob = async (file, name) => {
  try {
    await upload
      .putObject({
        Body: file,
        Bucket: process.env.REACT_APP_BUCKET_NAME,
        Key: name,
        ACL: 'public-read',
      })
      .promise()

    return ENDPOINT_URL_IMAGE + name
  } catch (error) {
    console.log(error)
    return ''
  }
}
