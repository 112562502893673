import { useEffect, useState } from 'react'
//styles
import styles from './transport-transfer.module.scss'

//components
import ModalPersonalInfo from './ModalPersonalInfo'
import TableProducts from './TableProducts'
import SuffixIconCustom from 'components/suffixIconCustom'
import Permission from 'components/permission'
import ZoomImage from 'components/zoom-image'

//libs
import { formatCash, _addLog } from 'utils'

//ants
import {
  Button,
  Col,
  Modal,
  Row,
  Select,
  Table,
  Form,
  Space,
  Input,
  Popconfirm,
  Collapse,
} from 'antd'

//const
import { IMAGE_DEFAULT, PERMISSIONS, SIZE_TABLE } from 'consts'

export default function TableExpand({
  record,
  _updateExportOrders,
  ModalTransferOrders,
  provinces = [],
  wards = [],
  districts = [],
  dataShippingCompany = [],
}) {
  const [isModalVisible, setIsModalVisible] = useState(false) // Đơn vị vận chuyển
  const [isModalVisible1, setIsModalVisible1] = useState(false) // Nhân viên giao
  const [isModalVisible2, setIsModalVisible2] = useState(false) // Thông tin cá nhân
  const [recordWTitle, setRecordWTitle] = useState({ title: '' }) // Thông tin cá nhân value
  const [expandedRows, setExpandedRows] = useState([])
  const [selectedShipping, setSelectedShipping] = useState({})

  const [form] = Form.useForm()
  const [formEmployee] = Form.useForm()
  const { shipping_info, expenses, packages } = record

  useEffect(() => {
    if (record.shipping_info.shipping_company_id) {
      form.setFieldsValue({ shippingcompany_id: record.shipping_info.shipping_company_id })
      const selectShippingCompany = dataShippingCompany.filter(
        (item) => item.shippingcompany_id === record.shipping_info.shipping_company_id
      )[0]
      setSelectedShipping(selectShippingCompany)
    }
  }, [isModalVisible])

  useEffect(() => {
    formEmployee.setFieldsValue(record.shipping_info.shipping_employee)
  }, [isModalVisible1])

  useEffect(() => {
    if (record.shipping_info.shipping_employee)
      formEmployee.setFieldsValue(record.shipping_info.shipping_employee)
  }, [record])

  const columnsExpense = [
    {
      title: 'Mã phụ liệu',
      dataIndex: 'expense_code',
    },
    {
      title: 'Tên phụ liệu',
      dataIndex: 'expense_name',
    },
    {
      title: 'Chi phí',
      render: (text, record) => formatCash(record.fee || 0),
    },
    {
      width: 400,
      title: 'Mô tả',
      dataIndex: 'description',
    },
  ]

  const columnsProduct = [
    {
      title: 'Mã sản phẩm',
      render: (text, record) => (record?.barcode ? record?.barcode : record?.code),
    },
    {
      title: 'Hình ảnh',
      render: (text, record) => (
        <ZoomImage
          image={(record.images && record.images.length && record.images[0]) || IMAGE_DEFAULT}
        >
          <img
            src={(record.images && record.images.length && record.images[0]) || IMAGE_DEFAULT}
            alt=""
            style={{ width: 45, height: 45 }}
          />
        </ZoomImage>
      ),
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
    },

    {
      title: 'Số lượng đóng gói',
      dataIndex: 'quantity_in_package',
    },
    {
      title: 'Giá tiền',
      render: (text, record) => formatCash(record.price) + ' VNĐ',
    },
    {
      title: 'Tổng tiền',
      render: (text, record) => formatCash(record.quantity_in_package * record.price) + ' VNĐ',
    },
  ]

  const _addressRender = (object, prefix) => {
    //prefix có thể là return, to, shipping_company
    const location = ['address', 'ward', 'district', 'province']
    const locationWPrefix = location.map((item) => {
      return (prefix ? `${prefix}_` : '') + item
    })
    let locationWPrefixVals = []
    locationWPrefix.forEach((item) => {
      if (object[item]) {
        locationWPrefixVals.push(object[item])
      }
    })
    return locationWPrefixVals.filter((item) => item).join(', ')
  }
  const _addressRenderSelect = () => {
    if (!selectedShipping) return ''
    const { address, ward, district, province } = selectedShipping
    const content = [address, ward, district, province]
    return content.filter((item) => item).join(', ')
  }
  const _onHandleSubmitShippingCompany = async () => {
    await form.validateFields()
    const formData = form.getFieldsValue()
    const selectShippingCompany = dataShippingCompany.filter(
      (item) => item.shippingcompany_id === formData.shippingcompany_id
    )[0]
    const { name, shippingcompany_id, phone, email, address, ward, district, province } =
      selectShippingCompany
    const shippingInfo = {
      shipping_company_id: shippingcompany_id,
      shipping_company_name: name,
      shipping_company_phone: phone,
      shipping_company_email: email,
      shipping_company_address: address,
      shipping_company_ward: ward,
      shipping_company_district: district,
      shipping_company_province: province,
    }
    const body = { shipping_info: { ...record.shipping_info, ...shippingInfo } }
    _updateExportOrders(record.order_id, body)
    setIsModalVisible(false)
  }

  const _onHandleReport = async () => {
    const body = { transport_status: 'REPORT' }
    _updateExportOrders(record.order_id, body)
  }

  const _updateShippingEmployee = () => {
    const formData = formEmployee.getFieldsValue()
    const body = { shipping_info: { ...record.shipping_info, shipping_employee: formData } }
    _updateExportOrders(record.order_id, body)
    setIsModalVisible1(false)
  }

  const _handleShippingChange = (value) => {
    const selectShippingCompany = dataShippingCompany.filter(
      (item) => item.shippingcompany_id === value
    )[0]
    setSelectedShipping(selectShippingCompany)
  }
  return (
    <div className={styles['transport-transfer-table-expand']}>
      <Modal
        visible={isModalVisible}
        width={800}
        footer={
          <Space>
            <Button
              type="primary"
              danger
              style={{ minWidth: 100 }}
              onClick={() => setIsModalVisible(false)}
            >
              Hủy
            </Button>
            <Button
              type="primary"
              style={{ minWidth: 100 }}
              onClick={_onHandleSubmitShippingCompany}
            >
              Lưu
            </Button>
          </Space>
        }
        title={<div style={{ textAlign: 'center' }}>ĐƠN VỊ VẬN CHUYỂN</div>}
        maskClosable={true}
        onCancel={() => setIsModalVisible(false)}
      >
        <Row gutter={32} style={{ marginBottom: '20px', minHeight: 80 }}>
          <Col span={8}>
            <Form form={form}>
              <Form.Item
                name="shippingcompany_id"
                rules={[{ required: true, message: 'Vui lòng chọn đơn vị vận chuyển' }]}
              >
                <Select
                  style={{ width: '100%' }}
                  placeholder="Chọn đơn vị vận chuyển"
                  suffixIcon={<SuffixIconCustom />}
                  onChange={_handleShippingChange}
                >
                  {dataShippingCompany.map((item, index) => {
                    return (
                      <Select.Option value={item.shippingcompany_id} key={index}>
                        {item.name}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Form>
            <div>
              <h4>Đơn vị vận chuyển: {selectedShipping && selectedShipping.name}</h4>
              <p style={{ marginBottom: '0' }}> {_addressRenderSelect()}</p>
              <p>Hotline: {selectedShipping && selectedShipping.phone}</p>
            </div>
          </Col>

          <Col span={8}>
            <h4>Người gửi: {shipping_info.return_name}</h4>
            <p style={{ marginBottom: '0' }}> {_addressRender(record?.customer_info)}</p>
            <p>{shipping_info.return_phone}</p>
          </Col>
          <Col span={8}>
            <h4>Người nhận: {shipping_info.to_name}</h4>
            <p style={{ marginBottom: '0' }}>{_addressRender(shipping_info, 'to')}</p>
            <p>{shipping_info.to_phone}</p>
          </Col>
        </Row>
      </Modal>

      <Modal
        visible={isModalVisible1}
        width={500}
        footer={
          <Space>
            <Button
              type="primary"
              danger
              style={{ minWidth: 100 }}
              onClick={() => {
                setIsModalVisible1(false)
              }}
            >
              Hủy
            </Button>
            <Button type="primary" style={{ minWidth: 100 }} onClick={_updateShippingEmployee}>
              Lưu
            </Button>
          </Space>
        }
        title={<div style={{ textAlign: 'center' }}>NHÂN VIÊN VẬN CHUYỂN</div>}
        maskClosable={true}
        onCancel={() => {
          setIsModalVisible1(false)
        }}
      >
        <Form
          form={formEmployee}
          className={styles['transport-transfer-form']}
          labelCol={{
            span: 5,
          }}
          style={{
            width: '90%',
            margin: '0 auto',
          }}
        >
          <Form.Item label="Họ và tên" name="employee_name">
            <Input placeholder="Nhập họ và tên" />
          </Form.Item>
          <Form.Item label="Code" name="employee_code">
            <Input placeholder="Nhập code nhân viên" />
          </Form.Item>
        </Form>
      </Modal>

      <ModalPersonalInfo
        provinces={provinces}
        wards={wards}
        districts={districts}
        record={recordWTitle}
        _updateExportOrders={_updateExportOrders}
        isModalVisible2={isModalVisible2}
        setIsModalVisible2={setIsModalVisible2}
      />

      <Row style={{ marginBottom: 20 }}>
        <Col span={8}>
          <div className={styles['transport-transfer-shipping-company']}>
            <div>
              <h4>Đơn vị vận chuyển: {shipping_info.shipping_company_name || ''}</h4>
              <p style={{ marginBottom: '0' }}>
                {_addressRender(shipping_info, 'shipping_company')}
              </p>
              <p>Hotline: {shipping_info.shipping_company_phone || ''}</p>
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div
            style={{
              width: '100%',
              height: '100%',
              paddingRight: '10px',
              cursor: 'pointer',
            }}
            onClick={() => {
              setRecordWTitle({ ...record, title: 'NGƯỜI GỬI' })
              setIsModalVisible2(true)
            }}
          >
            <div className={styles['transport-transfer-personal-info']}>
              <h4>Người gửi: {record.customer_info?.fullname}</h4>
              <p style={{ marginBottom: '0' }}> {_addressRender(record.customer_info)}</p>
              <p>{record.customer_info?.phone}</p>
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div
            style={{ width: '100%', height: '100%', cursor: 'pointer' }}
            onClick={() => {
              setRecordWTitle({ ...record, title: 'NGƯỜI NHẬN' })
              setIsModalVisible2(true)
            }}
          >
            <div className={styles['transport-transfer-personal-info']}>
              <h4>Người nhận: {shipping_info.to_name}</h4>
              <p style={{ marginBottom: '0' }}>{_addressRender(shipping_info, 'to')}</p>
              <p>{shipping_info.to_phone}</p>
            </div>
          </div>
        </Col>
      </Row>
      <Row style={{ marginBottom: '30px', width: '100%' }}>
        {/* <Table
          title={() => <b>DANH SÁCH PHỤ LIỆU</b>}
          rowKey="code"
          style={{ width: '100%' }}
          dataSource={expenses}
          columns={columns}
          size={SIZE_TABLE}
          pagination={false}
          expandable={{
            expandedRowRender: (record) => {
              return <TableProducts products={record.products} />
            },
            expandedRowKeys: expandedRows,
            onExpandedRowsChange: (rows) => setExpandedRows(rows),
          }}
        /> */}
        <b>DANH SÁCH KIỆN HÀNG</b>
        <div style={{ width: '100%' }}>
          {record.packages.map((pkg, index) => (
            <Collapse
              defaultActiveKey={Array.from(Array(record.packages.length).keys())}
              key={index}
              style={{ marginBottom: 10 }}
            >
              <Collapse.Panel key={index} header={<div>Mã kiện hàng: {pkg.package_code}</div>}>
                <Table
                  rowKey="product_id"
                  size="small"
                  columns={columnsProduct}
                  dataSource={pkg.products}
                  pagination={false}
                  expandable={{
                    defaultExpandAllRows: true,
                    expandedRowRender: (recordProduct) => {
                      return (
                        <div>
                          <b>DANH SÁCH PHỤ LIỆU</b>
                          <Table
                            dataSource={recordProduct.expense}
                            size="small"
                            pagination={false}
                            columns={columnsExpense}
                          />
                        </div>
                      )
                    },
                  }}
                />
              </Collapse.Panel>
            </Collapse>
          ))}
        </div>
      </Row>
      <Row justify="space-between">
        <Col>
          {shipping_info.shipping_employee && shipping_info.shipping_employee.employee_name && (
            <>
              <h4>Thông tin nhân viên ĐVVC</h4>
              <p style={{ marginBottom: 0 }}>{shipping_info.shipping_employee.employee_name}</p>
              <p>{shipping_info.shipping_employee.employee_code}</p>
            </>
          )}
        </Col>
        <Col style={{ display: 'flex', gap: '10px', marginRight: '20px' }}>
          <Permission permissions={[PERMISSIONS.cap_nhat_ban_giao_van_chuyen]}>
            <Space>
              <Button type="primary" onClick={() => setIsModalVisible1(true)}>
                Cập nhật nhân viên giao
              </Button>
              {ModalTransferOrders && (
                <ModalTransferOrders
                  title={`Xác nhận bàn giao đơn hàng #${record.code}`}
                  txtBtn="Xác nhận bàn giao"
                  id={[record.order_id]}
                />
              )}
            </Space>
          </Permission>
        </Col>
      </Row>
    </div>
  )
}
