import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

//apis
import { saveActionFile } from 'apis/action'
import { uploadFileTypeBlob } from 'apis/upload'

export default async function exportExcel(
  exportData,
  fileName = '',
  actionName = 'Xuất file excel'
) {
  if (exportData) {
    const FILE_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const FILE_EXTENSION = '.xlsx'

    const ws = XLSX.utils.json_to_sheet(exportData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: FILE_TYPE })

    //lưu action export excel
    const url = await uploadFileTypeBlob(data, fileName + FILE_EXTENSION)
    const body = {
      type: 'Export File',
      action_name: actionName,
      file_name: fileName + FILE_EXTENSION,
      links: [url],
    }
    await saveActionFile(body)

    FileSaver.saveAs(data, fileName + FILE_EXTENSION)
  }
}
