import React, { Component } from 'react'
class PrivacyPolicy extends Component {
  render() {
    return (
      <div style={{ backgroundColor: '#ECF2F3' }}>
        <div
          style={{
            padding: '50px 170px',
          }}
        >
          <h1 style={{ textAlign: 'center', fontWeight: 700, fontSize: 38 }}>Terms of service</h1>
          Terms of service Last updated: October 28, 2021 At SGL software (“SGL,” “we, “us”) we
          consider the privacy of the visitors to our website and the security of their personal
          information, to be extremely important. This Privacy Policy describes Our policies and
          procedures on the collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law protects You. We use Your
          Personal data to provide and improve the Service. By using the Service, You agree to the
          collection and use of information in accordance with this Privacy Policy. This Privacy
          Policy has been created with the help of the Privacy Policy Generator. Interpretation and
          Definitions Interpretation The words of which the initial letter is capitalized have
          meanings defined under the following conditions. The following definitions shall have the
          same meaning regardless of whether they appear in singular or in plural. Definitions For
          the purposes of this Privacy Policy: Account means a unique account created for You to
          access our Service or parts of our Service. Company (referred to as either “the Company”,
          “We”, “Us” or “Our” in this Agreement) refers to MIDEAS Co Jsc, 9 Hoa Cau. Cookies are
          small files that are placed on Your computer, mobile device or any other device by a
          website, containing the details of Your browsing history on that website among its many
          uses. Country refers to: Vietnam Device means any device that can access the Service such
          as a computer, a cellphone or a digital tablet. Personal Data is any information that
          relates to an identified or identifiable individual. Service refers to the Website.
          Service Provider means any natural or legal person who processes the data on behalf of the
          Company. It refers to third-party companies or individuals employed by the Company to
          facilitate the Service, to provide the Service on behalf of the Company, to perform
          services related to the Service or to assist the Company in analyzing how the Service is
          used. Usage Data refers to data collected automatically, either generated by the use of
          the Service or from the Service infrastructure itself (for example, the duration of a page
          visit). Website refers to Trợ lý thông minh SGL Bot, accessible from https://SGL.ai You
          means the individual accessing or using the Service, or the company, or other legal entity
          on behalf of which such individual is accessing or using the Service, as applicable.
          Collecting and Using Your Personal Data Types of Data Collected Personal Data While using
          Our Service, We may ask You to provide Us with certain personally identifiable information
          that can be used to contact or identify You. Personally identifiable information may
          include, but is not limited to: Email address First name and last name Phone number Sex
          Timezone Language Usage Data Usage Data Usage Data is collected automatically when using
          the Service. This usage data may include information such as your computer’s Internet
          Protocol address (IP address), browser type, browser version, the pages of our system you
          visit, date and time you visit, the time you spend on these sites, unique device
          identifiers and other diagnostic data. Tracking Technologies and Cookies We use cookies
          and similar tracking technologies to track activities on our systems and keep certain
          information. Cookies are files with small amounts of data that may include an anonymous
          unique identifier. Cookies are sent to the browser from a website and stored on your
          device. Tracking technologies used are signals, tags, and scripts to collect and track
          information and to improve and analyze Our Service. You can instruct your browser to
          refuse all cookies or to indicate when a cookie is being sent. However, if you do not
          accept cookies, you may not be able to use certain parts of our system. Examples of
          Cookies we use: – Session cookies: we use session cookies to operate our system. –
          Preference cookies: we use preference cookies to remember your preferences and various
          settings. – Security cookies: we use security cookies for security purposes. Use of Your
          Personal Data The Company may use Personal Data for the following purposes: To provide and
          maintain our Service, including to monitor the usage of our Service. To manage Your
          Account: to manage Your registration as a user of the Service. The Personal Data You
          provide can give You access to different functionalities of the Service that are available
          to You as a registered user. For the performance of a contract: the development,
          compliance and undertaking of the purchase contract for the products, items or services
          You have purchased or of any other contract with Us through the Service. To contact You:
          To contact You by email, telephone calls, SMS, or other equivalent forms of electronic
          communication, such as a mobile application’s push notifications regarding updates or
          informative communications related to the functionalities, products or contracted
          services, including the security updates, when necessary or reasonable for their
          implementation. To provide You with news, special offers and general information about
          other goods, services and events which we offer that are similar to those that you have
          already purchased or enquired about unless You have opted not to receive such information.
          To manage Your requests: To attend and manage Your requests to Us. For business transfers:
          We may use Your information to evaluate or conduct a merger, divestiture, restructuring,
          reorganization, dissolution, or other sale or transfer of some or all of Our assets,
          whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding,
          in which Personal Data held by Us about our Service users is among the assets transferred.
          For other purposes: We may use Your information for other purposes, such as data analysis,
          identifying usage trends, determining the effectiveness of our promotional campaigns and
          to evaluate and improve our Service, products, services, marketing and your experience. We
          may share Your personal information in the following situations: With Service Providers:
          We may share Your personal information with Service Providers to monitor and analyze the
          use of our Service, to contact You. For business transfers: We may share or transfer Your
          personal information in connection with, or during negotiations of, any merger, sale of
          Company assets, financing, or acquisition of all or a portion of Our business to another
          company. With business partners: We may share Your information with Our business partners
          to offer You certain products, services or promotions. With other users: when You share
          personal information or otherwise interact in the public areas with other users, such
          information may be viewed by all users and may be publicly distributed outside. With Your
          consent: We may disclose Your personal information for any other purpose with Your
          consent. Retention of Your Personal Data The Company will retain Your Personal Data only
          for as long as is necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply with our legal
          obligations (for example, if we are required to retain your data to comply with applicable
          laws), resolve disputes, and enforce our legal agreements and policies. The Company will
          also retain Usage Data for internal analysis purposes. Usage Data is generally retained
          for a shorter period of time, except when this data is used to strengthen the security or
          to improve the functionality of Our Service, or We are legally obligated to retain this
          data for longer time periods. Transfer of Your Personal Data Your information, including
          Personal Data, is processed at the Company’s operating offices and in any other places
          where the parties involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside of Your state,
          province, country or other governmental jurisdiction where the data protection laws may
          differ than those from Your jurisdiction. Your consent to this Privacy Policy followed by
          Your submission of such information represents Your agreement to that transfer. The
          Company will take all steps reasonably necessary to ensure that Your data is treated
          securely and in accordance with this Privacy Policy and no transfer of Your Personal Data
          will take place to an organization or a country unless there are adequate controls in
          place including the security of Your data and other personal information. Disclosure of
          Your Personal Data Business Transactions If the Company is involved in a merger,
          acquisition or asset sale, Your Personal Data may be transferred. We will provide notice
          before Your Personal Data is transferred and becomes subject to a different Privacy
          Policy. Law enforcement Under certain circumstances, the Company may be required to
          disclose Your Personal Data if required to do so by law or in response to valid requests
          by public authorities (e.g. a court or a government agency). Other legal requirements The
          Company may disclose Your Personal Data in the good faith belief that such action is
          necessary to: Comply with a legal obligation Protect and defend the rights or property of
          the Company Prevent or investigate possible wrongdoing in connection with the Service
          Protect the personal safety of Users of the Service or the public Protect against legal
          liability Security of Your Personal Data The security of Your Personal Data is important
          to Us, but remember that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially acceptable means to
          protect Your Personal Data, We cannot guarantee its absolute security. Children & Minor
          Privacy Our system does not address anyone under the age of 18 (“Minor”). We do not
          knowingly collect personally identifiable information from anyone under the age of 18. If
          you are a parent or guardian and you know that your child has provided us with personal
          data, please contact us. If we become aware that we have collected personal data from
          minor without verification of parental consent, we will take steps to remove that
          information from our servers. Links to Other Websites Our Service may contain links to
          other websites that are not operated by Us. If You click on a third party link, You will
          be directed to that third party’s site. We strongly advise You to review the Privacy
          Policy of every site You visit. We have no control over and assume no responsibility for
          the content, privacy policies or practices of any third party sites or services. Changes
          to this Privacy Policy We may update Our Privacy Policy from time to time. We will notify
          You of any changes by posting the new Privacy Policy on this page. We will let You know
          via email and/or a prominent notice on Our Service, prior to the change becoming effective
          and update the “Last updated” date at the top of this Privacy Policy. You are advised to
          review this Privacy Policy periodically for any changes. Changes to this Privacy Policy
          are effective when they are posted on this page. Contact Us If you have any questions
          about this Privacy Policy, You can contact us: By email: cskh@SGL.vn
          <p style={{ fontSize: 14, fontWeight: 700 }}>Hotline: 0931752925</p>
        </div>
      </div>
    )
  }
}

export default PrivacyPolicy
