import { get } from './axiosClient'

export const enumShipping = () => get('/enum/shipping')
export const enumTransport = () => get('/enum/transport')
export const enumPackage = () => get('/enum/package')
export const enumExport = () => get('/enum/export')
export const enumImport = () => get('/enum/import')
export const enumInventory = () => get('/enum/inventory')
export const enumExpense = () => get('/enum/expense-type')
export const enumFee = () => get('/enum/fee-type')
export const enumFeeGroup = () => get('/enum/fee-group')
export const enumPlatform = () => get('/enum/platform')
