import { get, patch, post, destroy } from './axiosClient'

export const getExportOrders = (query) => get('/exportorder', query)
export const findBucket = (query) => get('/exportorder/findbucket', query)
export const importFileOrder = (file) => post('/exportorder/file/import', file)
export const importFileOrderFromLazada = (body) =>
  post('/exportorder/file/import/lazada', { ...body, from_platform: 'SGL' })
export const importFileOrderFromTiki = (body) =>
  post('/exportorder/file/import/tiki', { ...body, from_platform: 'SGL' })
export const importFileOrderFromShoppe = (body) =>
  post('/exportorder/file/import/shopee', { ...body, from_platform: 'SGL' })
export const updateExportOrders = (order_id, body) =>
  patch(`/exportorder/update/${order_id}`, { ...body, from_platform: 'SGL' })
export const updateExportOrdersComplete = (body) =>
  patch(`/exportorder/bulkupdate`, { ...body, from_platform: 'SGL' })
export const scanExportOrder = (data) => post('/exportorder/scanexport', data)
export const statusListExportOrder = () => get('/enum/transport')
export const deleteExportOrders = (body) =>
  destroy('/exportorder/delete', { ...body, from_platform: 'SGL' })
export const getTrackingNumber = (query) => post('/ecom-order/link-tracking-number', query)
export const cancelBaskets = (body) => post('/exportorder/cancel-using-basket', body)

export const readyToShipLazada = (body) => post('/exportorder/ready-to-ship', body)
export const createGroupExport = (body) => post('/hour-po/create', body)

export const fixDoubleOrderExport = (body) => get('/exportorder/fix-double', body)
export const exportOrderProcessing = (query) => get('/exportorder/export-by-filter', query)

export const importFilePDfEcommerce = (body) => post('/exportorder/file/import/pdf', body)
export const autoExportOrder = (body) => post('/exportorder/auto', body)
