import { get } from './axiosClient'

export const getReport = (query) => get('/report/overview', query)
export const getReportInventory = (query) => get('/report/inventory', query)
export const getReportImportExportInventory = (query) => get('/report/import-export', query)
export const exportReportImportExportInventory = (query) =>
  get('/report/create-excel/import-export', query)
export const getReportInventoryDetail = (query) => get('/report/inventory-detail', query)
export const getReportImportInventory = (query) => get('/report/import', query)
export const getReportExportInventory = (query) => get('/report/export', query)

export const exportReportImportInventory = (query) =>
  get('/report/import-order/export-excel', query)
export const exportReportExportInventory = (query) =>
  get('/report/export-order/export-excel', query)
export const exportReportInventory = (query) => get('/report/inventory/export-excel', query)
export const exportReportInventoryDetail = (query) =>
  get('/report/inventory-detail/export-excel', query)

export const exportReportCheckOrder = (query) => get('/report/create-excel/check-order', query)
export const getReportCheckOrder = (query) => get('/report/check-order', query)

export const exportReportImportOrder = (query) => get('/report/create-excel/import-order', query)
export const getReportImportOrder = (query) => get('/report/import-order', query)

export const exportReportExportOrder = (query) => get('/report/create-excel/export-order', query)
export const getReportExportOrder = (query) => get('/report/export-order', query)

export const exportReportPackOrder = (query) => get('/report/create-excel/pack-order', query)
export const getReportPackOrder = (query) => get('/report/pack-order', query)

export const exportReportTransportOrder = (query) =>
  get('/report/create-excel/transport-order', query)
export const getReportTransportOrder = (query) => get('/report/transport-order', query)

export const reportMissingPackage = (query) => get('/report/missing-package', query)

export const exportListImportByFilter = (query) => get('/report/import-order/export-excel-by-filter', query)