import React, { useEffect, useState, useRef } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import {
  formatCash,
  _addLog,
  saveFiltersToLocalStorage,
  handleGetDataExportExcel,
  getFileName,
} from 'utils'
import { ACTION, PAGE_SIZE_OPTIONS, PERMISSIONS, ROUTES } from 'consts'
import styles from './auto-import.module.scss'
import { useHotkeys } from 'react-hotkeys-hook'
import ImportFile from 'components/import-csv'
// antd
import {
  Col,
  Row,
  Button,
  Input,
  Select,
  Badge,
  Table,
  Typography,
  Modal,
  notification,
  Space,
  Popconfirm,
  Upload,
  Popover,
} from 'antd'
import { DeleteOutlined, InboxOutlined, LoadingOutlined, ReloadOutlined } from '@ant-design/icons'

//components
import SuffixIconCustom from 'components/suffixIconCustom'
import Export from 'components/export-csv'
import ModalFees from 'components/modal-fees'
import FilterDate from 'components/filter-date'
import ModalConfirmDelete from 'components/modal-confirm-delete'
import Permission from 'components/permission'
import BackToTop from 'components/back-to-top'

//apis
import { getAllCustomer } from 'apis/customer'
import { autoImportOrder, autoCheck, autoImportLayout, fixImportLayout } from 'apis/order'
import { getWarehouses } from 'apis/warehouse'

export default function View() {
  const [customerId, setCustomerId] = useState()
  const [customers, setCustomers] = useState([])
  const [warehouse, setWarehouses] = useState([])
  const [warehousedId, setWarehouseId] = useState()
  const [nameOrder, setNameOrder] = useState('')
  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useState(false)

  const _getCustomers = async () => {
    try {
      const res = await getAllCustomer({ get_all: true })
      if (res.status === 200) setCustomers(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const onSubmitAuto = async (data) => {
    if (data) {
      if (!nameOrder) {
        notification.error({ message: 'Vui lòng điền tên phiếu' })
        return
      }

      if (!warehousedId) {
        notification.error({ message: 'Vui lòng chọn kho' })
        return
      }

      if (!customerId) {
        notification.error({ message: 'Vui lòng chọn khách hàng' })
        return
      }

      data.append('customer_order_code', nameOrder)
      data.append('warehouse_id', warehousedId)
      data.append('customer_id', customerId)

      var response = await autoImportOrder(data)
      if (response && response.status == 200) {
        if (response.data && response.data.data && response.data.data.customer_order_code) {
          var responseLayout = await autoImportLayout(data)
          if (responseLayout && responseLayout.data && responseLayout.data.errors?.length > 0) {
            notification.error({
              message: 'Tạo phiếu thất bại, vui lòng kiểm tra lại ô kệ hoặc tiêu đề cột',
            })
            setErrors(responseLayout.data.errors)
            return
          }
          await autoCheck({ customer_order_code: nameOrder })
          await fixImportLayout({ customer_order_code: response.data.data.customer_order_code })
          if (responseLayout && responseLayout.status == 200) {
            notification.success({ message: 'Đã tạo - kiểm hàng và nhập hàng xong' })
            if (responseLayout.data.errors) setErrors(responseLayout.data.errors)
            return { data: { success: true }, status: 200 }
          }
        }
      }
    }
  }

  const columnsProduct = [
    {
      title: 'Barcode',
      align: 'center',
      width: 50,
      fixed: 'left',
      render: (value, record) => {
        return <div>{record.barcode}</div>
      },
    },
    {
      title: 'Mã ô kệ',
      key: 'name',
      fixed: 'left',
      width: 250,
      render: (value, record) => {
        return <div>{record.bucket}</div>
      },
    },
  ]

  const _getWarehouses = async () => {
    try {
      const res = await getWarehouses({ get_all: true })
      if (res.status === 200) setWarehouses(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getCustomers()
    _getWarehouses()
  }, [])

  return (
    <div>
      <div>
        <BackToTop />
        <Row className="page-title" justify="space-between" style={{ marginBottom: '25px' }}>
          <h3>Kiểm và nhập tự động</h3>
        </Row>
        <div>{'V2.0'}</div>
        <div style={{ width: 300 }}>
          <Select
            //filterOption={filterOptionSelect}
            optionFilterProp="children"
            showSearch
            size="large"
            value={customerId}
            onChange={(e) => {
              setCustomerId(e)
            }}
            style={{ width: 300 }}
            placeholder="Chọn doanh nghiệp"
          >
            {customers.map((customer, index) => (
              <Select.Option value={customer.customer_id || ''} key={index}>
                {customer.fullname || ''}
              </Select.Option>
            ))}
          </Select>

          <Select
            value={warehousedId}
            onChange={(value) => {
              setWarehouseId(value)
            }}
            showSearch
            allowClear
            placeholder="Lọc theo kho"
            style={{ width: '100%', marginTop: 20 }}
            dropdownClassName="dropdown-select-custom"
            suffixIcon={<SuffixIconCustom />}
          >
            {warehouse.map((warehouse, index) => (
              <Select.Option key={index} value={warehouse.warehouse_id}>
                {warehouse.name}
              </Select.Option>
            ))}
          </Select>
          <Input
            placeholder="Tên phiếu nhập"
            style={{ width: 250, height: 40, marginTop: 20 }}
            value={nameOrder}
            onChange={(e) => {
              setNameOrder(e.target.value)
            }}
          />

          <ImportFile
            disable_noti={true}
            loading={loading}
            style={{ marginTop: 20 }}
            upload={onSubmitAuto}
            action_name={'Nhập sản phẩm bằng file excel'}
            fileTemplated="http://admin.saigonlogistics.vn/assets/mau_import_auto.xlsx"
          />
          <div style={{ color: 'red', fontWeight: 'bold', marginTop: 10 }}>
            {'Phần mềm chỉ đọc được Sheet 1'}
          </div>
          <div>
            {'Sau khi bấm xác nhận sẽ tự động tạo phiếu - kiểm hàng - nhập hàng vào vị trí'}
          </div>
        </div>

        {errors.length > 0 && (
          <div style={{ color: 'red' }}>
            {'Danh sách những sản phẩm hệ thống không tìm thấy mã sản phẩm hoặc ô kệ'}
          </div>
        )}
        {errors.length > 0 && (
          <Table columns={columnsProduct} loading={loading} dataSource={errors} />
        )}
      </div>
    </div>
  )
}
