import React, { useEffect, useState, useRef } from 'react'
import jwt_decode from 'jwt-decode'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { ACTION, IMAGE_DEFAULT, ROUTES } from 'consts'
import { useHistory, useLocation } from 'react-router-dom'
import { filterOptionSelect, formatCash } from 'utils'

//components
import ZoomImage from 'components/zoom-image'

// antd
import { ArrowLeftOutlined, CheckCircleOutlined, PlusSquareOutlined } from '@ant-design/icons'
import {
  Button,
  Dropdown,
  Form,
  Row,
  Col,
  Select,
  Spin,
  Table,
  InputNumber,
  notification,
  Space,
  DatePicker,
  Input,
  Menu,
} from 'antd'

// apis
import { getWarehouses } from 'apis/warehouse'
import { getProducts } from 'apis/product'
import { getAllCustomer } from 'apis/customer'
import { addOrder, getOrders } from 'apis/order'
import { getExportOrders } from 'apis/export-order'

const { Option } = Select
export default function ImportWarehouseCreate() {
  const typingTimeoutRef = useRef()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const dataUser = useSelector((state) => state.user)
  const queries = new URLSearchParams(location.search)
  const orderId = queries.get('order_id')
  const orderExportId = queries.get('order_export_id')

  const [order, setOrder] = useState()
  const [orderExport, setOrderExport] = useState()
  const [loadingSelect, setLoadingSelect] = useState(false)
  const [dataTableProduct, setDataTableProduct] = useState([])
  const [warehouses, setWarehouses] = useState([])
  const [customers, setCustomers] = useState([])
  const [selectKeyProduct, setSelectKeyProduct] = useState([])
  const [products, setProducts] = useState([])
  const [valueOption, setValueOption] = useState('name')
  const [valueSearch, setValueSearch] = useState('')

  const [paramsFilter, setParamsFilter] = useState({
    inventory_info: true,
    unit_info: true,
    merge: true,
  })

  const _getProducts = async () => {
    try {
      setLoadingSelect(true)
      const res = await getProducts({ ...paramsFilter })
      if (res.status === 200) {
        if (res.data.success) {
          setProducts(res.data.data)
        }
      }
      setLoadingSelect(false)
    } catch (error) {
      console.log(error)
      setLoadingSelect(false)
    }
  }

  const _createOrder = async () => {
    try {
      if (dataTableProduct.length === 0) {
        notification.error({ message: 'Vui lòng chọn ít nhất 1 sản phẩm' })
        return false
      }

      dispatch({ type: ACTION.LOADING, data: true })
      const data = { ...form.getFieldsValue() }

      const body = {
        ...data,
        customer_order_code: data?.customer_order_code || '',
        products: dataTableProduct || [],
        note: data.note || '',
      }
      if (orderExportId) body.is_fix_inventory = true
      const res = await addOrder(body)
      dispatch({ type: ACTION.LOADING, data: false })
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Thêm phiếu nhập kho thành công' })
          history.goBack()
        } else notification.error({ message: res.data.message || 'Thêm phiếu nhập kho thất bại' })
      } else notification.error({ message: res.data.message || 'Thêm phiếu nhập kho thất bại' })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const _onHandleInputChange = (object = {}, product_id) => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const newTableProduct = dataTableProduct.map((item) => {
        if (item?.product_id === product_id) {
          if (object.type === 'require_quantity') return { ...item, require_quantity: object.value }
          if (object.type === 'exp') return { ...item, exp: object.value }
          if (object.type === 'mfg') return { ...item, mfg: object.value }
        }
        return item
      })
      setDataTableProduct(newTableProduct)
    }, 100)
  }

  const _getCustomers = async () => {
    try {
      const res = await getAllCustomer({ get_all: true })
      if (res.status === 200) {
        const customer = res.data.data && res.data.data.length && res.data.data[0]
        setCustomers(res.data.data)
        if (customer) form.setFieldsValue({ warehouse_id: customer.warehouse_id })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _getWarehouses = async () => {
    try {
      const res = await getWarehouses()
      if (res.status === 200) {
        setWarehouses(res.data.data)
        if (!order) {
          const warehouse = res.data.data.find(
            (e) => dataUser && e.warehouse_id === dataUser.warehouse_id
          )

          if (warehouse) form.setFieldsValue({ warehouse_id: warehouse.warehouse_id })
          else {
            if (res.data.data && res.data.data.length)
              form.setFieldsValue({ warehouse_id: res.data.data[0].warehouse_id })
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const columnsProduct = [
    {
      width: 50,
      title: 'STT',
      align: 'center',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
    },
    {
      title: 'Mã sản phẩm',
      render: (text, record) => (record.barcode ? record.barcode : record.code),
    },
    {
      title: 'Quy cách',
      dataIndex: 'convention',
    },
    {
      title: 'Đơn vị tính',
      render: (text, record) => record.unit_info && record.unit_info.name,
    },
    {
      title: 'Đơn vị nhỏ nhất',
      render: (text, record) => formatCash(record.smallest_unit || 0),
    },
    {
      title: 'Nhóm sản phẩm',
      dataIndex: 'group_info',
      render: (text) => text && text?.name,
    },
    {
      title: 'Loại sản phẩm',
      dataIndex: 'type_info',
      render: (text) => text && text?.name,
    },
    {
      title: 'Hình ảnh',
      dataIndex: `images`,
      render: (text) =>
        text && text.length ? (
          <ZoomImage image={text[0] || IMAGE_DEFAULT}>
            <img width={40} height={40} src={text[0] || IMAGE_DEFAULT} alt="" />
          </ZoomImage>
        ) : (
          ''
        ),
    },
    {
      title: 'Chiều rộng(cm)',
      dataIndex: `width`,
    },
    {
      title: 'Chiều cao(cm)',
      dataIndex: `height`,
    },
    {
      title: 'Cân nặng(g)',
      dataIndex: `weight`,
    },
    {
      key: 'export_quantity',
      width: 0,
    },
    {
      title: 'Số lượng nhập',
      dataIndex: `require_quantity`,
      render: (text, record) => (
        <InputNumber
          disabled={order && ['WAITING'].indexOf(order?.import_status) < 0}
          value={record?.require_quantity}
          min={1}
          onChange={(value) =>
            _onHandleInputChange({ type: 'require_quantity', value: value }, record.product_id)
          }
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
        />
      ),
      fixed: 'right',
    },
    {
      title: 'Ngày sản xuất',
      render: (expiry_info, record) => (
        <DatePicker
          disabled={order && ['WAITING'].indexOf(order?.import_status) < 0}
          defaultValue={expiry_info?.exp ? moment(expiry_info?.exp) : ''}
          onChange={(date) =>
            _onHandleInputChange({ type: 'mfg', value: moment(date).format() }, record.product_id)
          }
          style={{ width: '100%' }}
        />
      ),
      fixed: 'right',
    },
    {
      title: 'Hạn sử dụng',
      render: (expiry_info, record) => (
        <DatePicker
          disabled={order && ['WAITING'].indexOf(order?.import_status) < 0}
          defaultValue={expiry_info?.exp ? moment(expiry_info?.exp) : ''}
          onChange={(date) =>
            _onHandleInputChange({ type: 'exp', value: moment(date).format() }, record.product_id)
          }
          style={{ width: '100%' }}
        />
      ),
      fixed: 'right',
    },
  ]

  const _getOrderExportDetail = async () => {
    try {
      dispatch({ type: 'LOADING', data: true })
      const res = await getExportOrders({ order_id: orderExportId })
      dispatch({ type: 'LOADING', data: false })
      if (res.status === 200) {
        const order = res.data.data && res.data.data.length === 1 && res.data.data[0]
        if (order) {
          setOrderExport(order)
          setDataTableProduct(
            order.products.map((product) => ({
              ...product,
              require_quantity: product.export_quantity || 0,
            }))
          )
          form.setFieldsValue({ warehouse_id: order.warehouse_id || '', note: order.note || '' })
        } else {
          history.push(ROUTES.IMPORT_WAREHOUSE)
          notification.error({ message: 'Không tìm thấy đơn hàng này' })
        }
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: 'LOADING', data: false })
    }
  }

  const _getOrderDetail = async () => {
    try {
      dispatch({ type: 'LOADING', data: true })
      const res = await getOrders({ order_id: orderId })
      dispatch({ type: 'LOADING', data: false })
      if (res.status === 200) {
        const order = res.data.data && res.data.data.length === 1 && res.data.data[0]
        if (order) {
          setOrder(order)
          setDataTableProduct(order.products)
          form.setFieldsValue({
            warehouse_id: order.warehouse_id || '',
            note: order.note || '',
            customer_order_code: order.customer_order_code || '',
          })
        } else {
          history.push(ROUTES.IMPORT_WAREHOUSE)
          notification.error({ message: 'Không tìm thấy đơn hàng này' })
        }
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: 'LOADING', data: false })
    }
  }

  const deleteProductTable = () => {
    const dataTableNew = dataTableProduct.filter(
      (item) => !selectKeyProduct.includes(item.product_id)
    )
    setDataTableProduct(dataTableNew)
    setSelectKeyProduct([])
  }

  const _increaseQuantityProduct = (product) => {
    const index = dataTableProduct.findIndex((item) => item.product_id === product.product_id)
    if (index >= 0) {
      dataTableProduct[index].require_quantity = dataTableProduct[index].require_quantity + 1
      setDataTableProduct([...dataTableProduct])
    }
  }

  useEffect(() => {
    _getWarehouses()
    _getCustomers()

    if (orderId) _getOrderDetail()
    if (orderExportId) _getOrderExportDetail()
  }, [])

  useEffect(() => {
    _getProducts()
  }, [paramsFilter])

  const _handleSearchProduct = (e) => {
    const value = e.target.value
    setValueSearch(value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      if (value) {
        delete paramsFilter.name
        delete paramsFilter.code
        delete paramsFilter.barcode

        paramsFilter[valueOption] = value
        setParamsFilter({ ...paramsFilter })
      } else {
        setParamsFilter({
          inventory_info: true,
          unit_info: true,
          customer_id: (dataUser && dataUser.customer_id) || '',
          merge: true,
        })
      }
    }, 600)
  }

  const _HandleChangeValueOption = (value) => {
    delete paramsFilter.name
    delete paramsFilter.code
    delete paramsFilter.barcode
    setParamsFilter({ ...paramsFilter })
    setValueSearch('')
    setValueOption(value)
  }

  const menu = (
    <Menu style={{ maxHeight: 400, overflowX: 'auto' }}>
      <Menu.Item
        onClick={() => {
          window.open(ROUTES.PRODUCT_DETAIL)
        }}
      >
        <Space style={{ height: 50 }}>
          <div>
            <PlusSquareOutlined
              style={{
                fontSize: 20,
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                height: '100%',
              }}
            />
          </div>
          <div>Thêm sản phẩm mới</div>
        </Space>
      </Menu.Item>
      {loadingSelect ? (
        <Spin />
      ) : (
        products.map((data, index) => {
          const isAdded = dataTableProduct.find((product) => product.product_id === data.product_id)

          return (
            <Menu.Item
              key={index}
              onClick={() => {
                const findProduct = dataTableProduct.find(
                  (item) => item?.product_id === data.product_id
                )
                if (findProduct) {
                  // _increaseQuantityProduct(findProduct, dataTableProduct)
                  notification.warning({
                    message: 'Sản phẩm đã được thêm bên dưới',
                    key: 'add-product',
                    duration: 3,
                  })
                  return
                }
                data.require_quantity = 1
                setDataTableProduct([...dataTableProduct, data])
              }}
            >
              <Space>
                <div Style={{ width: 200 }}>
                  <img
                    src={(data?.images?.length && data?.images[0]) || IMAGE_DEFAULT}
                    alt=""
                    height={60}
                    width={60}
                  />
                </div>
                <div>
                  <div
                    style={{
                      width: 420,
                      display: '-webkit-box !important',
                      '-webkit-box-orient': 'vertical',
                      '-webkit-line-clamp': 1,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    Tên hàng: <b> {data?.name}</b>
                  </div>
                  <span>
                    Mã hàng/barcode:<b> {data?.barcode}</b>
                  </span>
                  {isAdded && (
                    <Row wrap={false} align="middle" style={{ color: 'green' }}>
                      Đã thêm <CheckCircleOutlined style={{ marginLeft: 6, fontSize: 15 }} />
                    </Row>
                  )}
                </div>
              </Space>
            </Menu.Item>
          )
        })
      )}
    </Menu>
  )

  return (
    <Form form={form} onFinish={_createOrder} layout="vertical">
      <div>
        <Row justify="space-between" className="page-title" wrap={false} align="middle">
          <Col>
            <Space onClick={() => history.goBack()} style={{ cursor: 'pointer' }}>
              <ArrowLeftOutlined />
              <span style={{ fontWeight: 700, fontSize: 20, color: '#223B4F' }}>
                {order
                  ? `Cập nhật phiếu nhập kho #${order?.code}`
                  : orderExport
                  ? `Thêm phiếu nhập kho từ phiếu xuất #${orderExport.code}`
                  : 'Thêm phiếu nhập kho'}
              </span>
            </Space>
          </Col>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              disabled={order && ['WAITING'].indexOf(order?.import_status) < 0}
            >
              Thêm phiếu
            </Button>
          </Col>
        </Row>
        <br />
        <Row gutter={[10, 10]}>
          <Col xs={24} xl={7}>
            <Form.Item
              name="warehouse_id"
              label="Tên kho"
              rules={[{ required: true, message: 'Vui lòng chọn kho' }]}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Chọn kho"
                filterOption={filterOptionSelect}
              >
                {warehouses.map((warehouse) => {
                  return (
                    <Option key={warehouse.warehouse_id} value={warehouse.warehouse_id}>
                      {warehouse.name}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} xl={11}>
            <h3 style={{ color: '#223B4F' }}>Danh sách sản phẩm</h3>
            <Dropdown trigger={['focus']} overlay={menu} overlayStyle={{ width: 515, height: 200 }}>
              <Input
                allowClear
                placeholder="Tìm kiếm sản phẩm"
                style={{ width: '71%' }}
                onChange={_handleSearchProduct}
                value={valueSearch}
              />
            </Dropdown>
            <Select
              value={valueOption}
              style={{ width: '29%' }}
              onChange={_HandleChangeValueOption}
            >
              <Select.Option value="name">Tên</Select.Option>
              <Select.Option value="barcode">Mã hàng/barcode</Select.Option>
            </Select>
          </Col>
          <Col xs={24} xl={6}>
            <Form.Item initialValue="" name="customer_order_code" label="Tên phiếu">
              <Input placeholder="Nhập tên phiếu" />
            </Form.Item>
          </Col>
          <Col xs={24} xl={7}>
            <Form.Item
              name="customer_id"
              label="Doanh nghiệp"
              rules={[{ required: true, message: 'Vui lòng chọn doanh nghiệp' }]}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Chọn doanh nghiệp"
                filterOption={filterOptionSelect}
              >
                {customers.map((customer) => {
                  return (
                    <Option key={customer.customer_id} value={customer.customer_id}>
                      {customer.fullname}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} xl={10}>
            <Form.Item name="note" label="Ghi chú">
              <Input.TextArea placeholder="Nhập khi chú" rows={2} />
            </Form.Item>
          </Col>
        </Row>
        <div style={{ marginBottom: 10, height: 32 }}>
          {selectKeyProduct && selectKeyProduct.length ? (
            <Button
              onClick={deleteProductTable}
              type="danger"
              style={{ borderRadius: 5, padding: '0 35px' }}
              disabled={order && ['WAITING'].indexOf(order?.import_status) < 0}
            >
              Xóa
            </Button>
          ) : (
            ''
          )}
        </div>
        <Table
          scroll={{ x: 1700, y: '50vh' }}
          rowKey="product_id"
          size="small"
          columns={columnsProduct.map((column) => {
            if (column.key === 'export_quantity' && orderExportId)
              return {
                ...column,
                title: 'Số lượng xuất',
                dataIndex: 'export_quantity',
                fixed: 'right',
                width: 100,
              }

            return column
          })}
          dataSource={dataTableProduct}
          pagination={false}
          rowSelection={{
            selectedRowKeys: selectKeyProduct,
            onChange: (keys) => {
              setSelectKeyProduct(keys)
            },
          }}
        />
      </div>
    </Form>
  )
}
