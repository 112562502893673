import React, { useRef, memo } from 'react'
import { useReactToPrint } from 'react-to-print'
import QR from 'qrcode-base64'
import { Row, Table, Button } from 'antd'
import { SIZE_TABLE } from 'consts'
import styles from './package.module.scss'

const PrintTemplate = memo(({ products = [], order, code = '' }) => {
  if (!order) return null

  var qrCodePackage = QR.drawImg(code, {
    typeNumber: 4,
    errorCorrectLevel: 'M',
    size: 500,
  })

  var qrCodeOrder = QR.drawImg(order.code, {
    typeNumber: 4,
    errorCorrectLevel: 'M',
    size: 500,
  })

  const splitPackage = code.split('KH')

  const numberPackage = splitPackage[splitPackage.length - 1] || ''

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      align: 'center',
      width: 30,
      render: (text, record, index) => index + 1,
    },
    {
      title: 'MÃ HÀNG',
      align: 'center',
      dataIndex: 'barcode',
      size: '10px',
      width: 70,
    },
    {
      title: 'TÊN HÀNG',
      align: 'center',
      dataIndex: 'name',
      width: '100%',
    },
    {
      title: 'SỐ LƯỢNG',
      align: 'center',
      dataIndex: 'quantity_in_package',
      width: 30,
    },
    {
      title: 'QUY CÁCH',
      align: 'center',
      dataIndex: 'convention',
    },
    {
      title: 'ĐƠN VỊ',
      align: 'center',
      render: (text, record) => record.unit_info && record.unit_info.name,
    },
  ]

  return (
    <div style={{ padding: 10 }}>
      <header className={styles['page-break']}></header>

      <Row justify="space-between" align="middle" wrap={false}>
        <div>
          <b>CÔNG TY TNHH KHO VẬN SÀI GÒN 0941074040</b>
          <p style={{ marginBottom: 0, fontWeight: 700 }}>Mã phiếu xuất: {order.code || ''}</p>
        </div>

        <img src={qrCodeOrder} alt="" style={{ width: 60, height: 60 }} />
      </Row>

      <div style={{ marginBottom: 20, width: '100%' }}>
        <h2 style={{ marginBottom: 0, fontWeight: 'bold' }}>PACKING LIST ({numberPackage})</h2>
        <br />
        <p style={{ marginBottom: 0, fontWeight: 700 }}>
          Người nhận: {order.shipping_info && order.shipping_info.to_name} -{' '}
          {order.shipping_info && order.shipping_info.to_phone}
        </p>
        <p style={{ marginBottom: 0, fontWeight: 700 }}>
          Địa chỉ nhận:{' '}
          {`${order.shipping_info && order.shipping_info.to_address}, 
          ${order.shipping_info && order.shipping_info.to_ward}, 
          ${order.shipping_info && order.shipping_info.to_district}, 
          ${order.shipping_info && order.shipping_info.to_country}.`}
        </p>
        <p style={{ marginBottom: 0, fontWeight: 700 }}>
          Địa chỉ giao:
          {`${order.warehouse_info && order.warehouse_info.address}, 
          ${order.warehouse_info && order.warehouse_info.ward}, 
          ${order.warehouse_info && order.warehouse_info.district}, 
          ${order.warehouse_info && order.warehouse_info.province}.`}
        </p>
      </div>

      <Row justify="space-between" align="middle" wrap={false}>
        <span style={{ margin: 0 }}>Mã kiện hàng: {code}</span>

        <img
          src={qrCodePackage}
          alt=""
          style={{ width: 47, height: 47, marginRight: 5, marginBottom: 8 }}
        />
      </Row>
      <Table
        bordered
        className={styles['table-product']}
        style={{ width: '100%' }}
        size={SIZE_TABLE}
        columns={columns}
        dataSource={[...products]}
        pagination={false}
      />
    </div>
  )
})

export class ComponentPrintBill extends React.Component {
  render() {
    const { pkgList, order } = this.props

    return (
      <div>
        {pkgList.map((pkg) => (
          <PrintTemplate
            key={pkg.package_code}
            products={pkg.products || []}
            code={pkg.package_code}
            order={order}
          />
        ))}
      </div>
    )
  }
}

const ButtonPrintBill = memo(({ pkgList, title, order, printRef, updatePackages }) => {
  const componentRef1 = useRef()
  const _handlePrint = async (e) => {
    e.stopPropagation()
    _print()
  }
  const _print = useReactToPrint({
    content: () => (printRef ? printRef.current : componentRef1.current),
    onAfterPrint: () => {
      if (updatePackages) updatePackages()
    },
  })

  return (
    <>
      <div style={{ display: 'none' }}>
        <ComponentPrintBill order={order} pkgList={pkgList} ref={printRef || componentRef1} />
      </div>
      <Button
        type="primary"
        onClick={_handlePrint}
        className={styles['btn-print']}
        id="btnPrintAll"
      >
        {title}
      </Button>
    </>
  )
})

export default ButtonPrintBill
