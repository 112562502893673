import { VERSION_APP } from 'consts'
import { addLog } from 'apis/log'
import jwt_decode from 'jwt-decode'

export function formatNumber(num, dot = '.') {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${dot}`)
}

export function formatCash(str) {
  if (str) return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  else return 0
}

export async function _addLog(content = '') {
  try {
    const res = await addLog({ content })
    if (res.status === 200) {
    }
  } catch (error) {
    console.log(error)
  }
}

//xoá dấu
export function removeAccents(text, removeSpace = false, spaceReplace = '') {
  if (removeSpace && typeof removeSpace != 'boolean') {
    throw new Error('Type of removeSpace input must be boolean!')
  }
  text = (text + '')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D')
  if (removeSpace) text = text.replace(/\s/g, spaceReplace)
  return text
}

export const tableSum = (arr, key) => {
  const getValue = (obj, key) => {
    try {
      return key.split('.').reduce((a, b) => {
        return a[b] || 0
      }, obj)
    } catch (e) {
      return 0
    }
  }
  try {
    return arr.reduce((a, b) => a + parseInt(getValue(b, key)), 0)
  } catch (err) {
    console.log(err)
    return 0
  }
}

export const clearBrowserCache = () => {
  let version = localStorage.getItem('version_app')
  if (version !== VERSION_APP) {
    if ('caches' in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name)
        })
      })

      // Makes sure the page reloads. Changes are only visible after you refresh.
      window.location.reload(true)
    }

    localStorage.clear()
    localStorage.setItem('version_app', VERSION_APP)
  }
}
export const compare = (a, b, key) => {
  return a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0
}
export const splitArray = (inputArray, perChunk = 6) => {
  var result = inputArray.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])

  const resultNew = result.map((r) => {
    const lengthItem = r.length
    if (lengthItem !== 6) for (let i = 0; i < 6 - lengthItem; i++) r.push('')
    return Object.assign({}, r)
  })

  return resultNew
}

export const filterOptionSelect = (input, option) =>
  removeAccents(option.children).toLowerCase().includes(removeAccents(input).toLowerCase())

export const saveFiltersToLocalStorage = (filters = {}, key = '') => {
  localStorage.setItem(key, JSON.stringify({ ...filters }))
}

export const handleGetDataExportExcel = async (api, query = {}, page = 1, records = []) => {
  if (api) {
    const res = await api({ ...query, page: page, page_size: 250 })
    if (res.status === 200) {
      const recordsNew = [...records, ...res.data.data]
      if (recordsNew.length < res.data.count) {
        return handleGetDataExportExcel(api, query, page + 1, recordsNew)
      } else {
        return recordsNew
      }
    }
  }
}

export const clearLocalStorage = () => {
  for (var key in localStorage) if (key !== 'version_app') localStorage.removeItem(key)
}

export const getFileName = (file = '') => {
  const nameStrings = file.split('/')
  const name = nameStrings[nameStrings.length - 1]
  return name || ''
}

export const getKeyName = (key = '') => {
  if (key) {
    const keyNew = key[0].toUpperCase() + key.substring(1)
    const keyName = keyNew.replaceAll('_', ' ')
    return keyName
  }
  return ''
}

export const isAcceptPermissions = (permissions = []) => {
  const accessToken = localStorage.getItem('accessToken')
  if (accessToken) {
    const dataUser = jwt_decode(accessToken)
    if (dataUser) {
      const permissionsUser = dataUser.role_info ? dataUser.role_info.permissions : []
      return permissionsUser.filter((permission) => permissions.includes(permission)).length
        ? true
        : false
    }
  }

  return false
}
