const columns = [
  {
    key: 'STT',
    title: 'STT',
    align: 'center',
    width: 50,
  },
  {
    title: 'Mã sản phẩm',
    key: 'barcode/code',
    align: 'center',
  },
  {
    title: 'Tên sản phẩm',
    dataIndex: 'name',
    align: 'center',
  },
  {
    key: 'image',
    title: 'Hình ảnh',
    dataIndex: 'images',
    align: 'center',
  },
  {
    key: 'require_quantity',
    title: 'SL yêu cầu',
    dataIndex: 'require_quantity',
    align: 'center',
  },
  {
    key: 'inventory_quantity',
    title: 'SL bàn giao',
    dataIndex: 'inventory_quantity',
    align: 'center',
  },
  {
    key: 'quantity',
    title: 'Nhập kho thực tế',
    dataIndex: 'import_details',
    align: 'center',
  },
  {
    key: 'CBM',
    title: 'CBM',
    dataIndex: 'cbm',
    align: 'center',
  },
  {
    key: 'area',
    title: 'S mặt phẳng',
    dataIndex: 'area',
    align: 'center',
  },
  {
    key: 'warehouse_id',
    title: 'Kho',
    dataIndex: '',
    align: 'center',
  },
  {
    key: 'position',
    title: 'Ô kệ',
    dataIndex: 'scan_position',
    align: 'center',
  },
  {
    key: 'print_barcode',
    title: '',
    dataIndex: 'print_barcode',
    align: 'center',
  },
]

export default columns
