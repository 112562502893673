import { PERMISSIONS, ROUTES } from 'consts'
import React from 'react'
import { notification } from 'antd'

import { Switch, Route, Redirect, HashRouter, BrowserRouter } from 'react-router-dom'
import Authentication from 'components/authentication'
//components
import Dashboard from './dashboard'
import NotFound from './not-found/notfound'
import Login from './login'
import Product from './product'
import ProductDetail from './product-detail'
import Warehouse from './warehouse'
import CreateWarehouse from './warehouse-add'
import Layout from 'components/Layout'
import Customer from './customer'
import Bucket from './bucket'
import EmployeesManagement from './employee-management'
import CreateEmployee from './create-employee'
import RoleManagement from './role-management'
import ExportProduct from './product-export'
import Ingredient from './ingredient'
import IngredientAdd from './ingredient-add'
import SettingBox from './setting-box'
import StorageFee from './storage-fee'
import OrderFee from './order-fee'
import StorageFeeAdd from './storage-fee-add'
import StorageCost from './storage-cost'
import StorageCostAdd from './storage-cost-add'
import AddStorageCostOfOrderFee from './order-fee/add-storage-cost'
import AddStorageOtherOfOrderFee from './order-fee/add-storage-other'
import ShippingCompany from './shipping-company'
import Shipping from './shipping'
import ShippingCompanyAdd from './shipping-company-add'
import ReportInventory from './report-inventory'
import ReportInventoryDetail from './report-inventory-detail'
import ReportImportWarehouse from './report-import-warehouse'
import ReportExportWarehouse from './report-export-warehouse'
import ReportProductChecking from './report-product-checking'
import ReportImportOrder from './report-import-order'
import ReportExportOrder from './report-export-order'
import ReportPackOrder from './report-pack-order'
import ReportTransportOrder from './report-transport-order'
import CustomerAdd from './customer-add'
import ActivityHistory from './activity-history'
import PrinterManagement from './printer-management'
import ManagementImportExport from './management-import-export'
import OrderCollectionExport from './order-collection-export'
import OrderCollectionImport from './order-collection-import'
import OrderDetail from './order-detail'
import AddFeeToOrder from './add-fee-to-order'
import ProductCheckDetail from './product-check-detail'
import ProductImportDetail from './product-import-detail'
import PositionInput from './position-input'
import ProductChecking from './product-checking'
import Package from './package'
import PackageScan from './package-scan'
import PackageECommerce from './package-e-commerce'
import PackageScanPackageECommerce from './package-scan-e-commerce'
import TransportTransfer from './transport-transfer'
import TransportTransferred from './transported-transfer'
import ProductExportScan from './product-export-scan'
import ProductExportList from './product-export-list'
import ProductImportList from './product-import-list'
import ProductImportScan from './product-import-scan'
// import ShelveImportScan from './shelve-import-scan'
import ExportScanProduct from './product-export-scan-product'
import ProductExportScanBucket from './product-export-scan-bucket'
import Privacy from './privacy'
import TermOfService from './term-of-service'
import Unit from './unit'
import PODeleteList from './po-delete-list'
import ImportWarehouseForm from './import-warehouse-form'
import ReportImportExport from './report-import-export'
import AutoImport from './auto-import'
import AutoExport from './auto-export'

const DEFINE_ROUTER = [
  {
    path: ROUTES.DASHBOARD,
    Component: () => <Dashboard />,
    title: 'Tổng quan',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.PRODUCT,
    Component: () => <Product />,
    title: 'Sản phẩm',
    permissions: [PERMISSIONS.giao_dien_san_pham],
    exact: true,
  },
  {
    path: ROUTES.UNIT,
    Component: () => <Unit />,
    title: 'Quản lý quy cách sản phẩm',
    permissions: [PERMISSIONS.giao_dien_quan_ly_quy_cach_san_pham],
    exact: true,
  },
  {
    path: ROUTES.PRODUCT_CHECK_DETAIL,
    Component: () => <ProductCheckDetail />,
    title: 'Chi tiết đơn hàng kiểm hàng',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.PRODUCT_IMPORT_DETAIL,
    Component: () => <ProductImportDetail />,
    title: 'Chi tiết đơn hàng nhập vào vị trí',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.PRODUCT_IMPORT_SCAN,
    Component: () => <ProductImportScan />,
    title: 'Quét nhập vào vị trí',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.AUTO_IMPORT,
    Component: () => <AutoImport />,
    title: 'Kiểm & Nhập tự động',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.AUTO_EXPORT,
    Component: () => <AutoExport />,
    title: 'Tạo & Xuất tự động',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.PRODUCT_DETAIL,
    Component: () => <ProductDetail />,
    title: 'Chi tiết sản phẩm',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.WAREHOUSE,
    Component: () => <Warehouse />,
    title: 'Quản lý kho hàng',
    permissions: [PERMISSIONS.giao_dien_kho_hang],
    exact: true,
  },
  {
    path: ROUTES.WAREHOUSE_ADD,
    Component: () => <CreateWarehouse />,
    title: 'Tạo kho hàng',
    permissions: [PERMISSIONS.tao_kho_hang],
    exact: true,
  },
  {
    path: ROUTES.BUCKET,
    Component: () => <Bucket />,
    title: 'Cài đặt ô chứa hàng',
    permissions: [PERMISSIONS.giao_dien_o_chua_hang],
    exact: true,
  },
  {
    path: ROUTES.ADD_FEE_TO_ORDER,
    Component: () => <AddFeeToOrder />,
    title: 'Thêm chi phí vào đơn hàng',
    permissions: [PERMISSIONS.them_chi_phi_vao_phieu_xuat, PERMISSIONS.them_chi_phi_vao_phieu_nhap],
    exact: true,
  },
  {
    path: ROUTES.CUSTOMERS,
    Component: () => <Customer />,
    title: 'Quản lý doanh nghiệp',
    permissions: [PERMISSIONS.giao_dien_quan_ly_doanh_nghiep],
    exact: true,
  },
  {
    path: ROUTES.CUSTOMERS_ADD,
    Component: () => <CustomerAdd />,
    title: 'Tạo doanh nghiệp',
    permissions: [PERMISSIONS.tao_doanh_nghiep],
    exact: true,
  },

  {
    path: ROUTES.EMPLOYEES_MANAGEMENT,
    Component: () => <EmployeesManagement />,
    title: 'Quản lí nhân viên',
    permissions: [PERMISSIONS.giao_dien_quan_ly_nhan_vien],
    exact: true,
  },
  {
    path: ROUTES.CREATE_EMPLOYEE,
    Component: () => <CreateEmployee />,
    title: 'Tạo nhân viên',
    permissions: [PERMISSIONS.tao_nhan_vien],
    exact: true,
  },
  {
    path: ROUTES.ROLE_MANAGEMENT,
    Component: () => <RoleManagement />,
    title: 'Quản lý phân quyền',
    permissions: [PERMISSIONS.giao_dien_quan_ly_phan_quyen],
    exact: true,
  },
  {
    path: ROUTES.PRODUCT_EXPORT,
    Component: () => <ExportProduct />,
    title: 'Xuất hàng',
    permissions: [PERMISSIONS.giao_dien_danh_sach_xuat_hang],
    exact: true,
  },
  {
    path: ROUTES.PRODUCT_EXPORT_SCAN,
    Component: () => <ProductExportScan />,
    title: 'Quét thùng đựng xuất hàng',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.PRODUCT_EXPORT_LIST,
    Component: () => <ProductExportList />,
    title: 'Danh sách phiếu xuất',
    permissions: [PERMISSIONS.giao_dien_danh_sach_xuat_hang],
    exact: true,
  },
  {
    path: ROUTES.REPORT_IMPORT_EXPORT,
    Component: () => <ReportImportExport />,
    title: 'Báo cáo nhập xuất',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.PRODUCT_IMPORT_LIST,
    Component: () => <ProductImportList />,
    title: 'Danh sách phiếu nhập',
    permissions: [PERMISSIONS.giao_dien_danh_sach_nhap_hang],
    exact: true,
  },

  {
    path: ROUTES.PRODUCT_EXPORT_SCAN_BUCKET,
    Component: () => <ProductExportScanBucket />,
    title: 'Quét ô kệ xuất hàng',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.PRODUCT_EXPORT_SCAN_PRODUCT,
    Component: () => <ExportScanProduct />,
    title: 'Quét sản phẩm xuất hàng',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.PACKAGE,
    Component: () => <Package />,
    title: 'Đóng gói',
    permissions: [PERMISSIONS.giao_dien_dong_goi],
    exact: true,
  },
  {
    path: ROUTES.PACKAGE_SCAN,
    Component: () => <PackageScan />,
    title: 'Quét đóng gói',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.PACKAGE_E_COMMERCE,
    Component: () => <PackageECommerce />,
    title: 'Đóng gói TMDT',
    permissions: [PERMISSIONS.giao_dien_dong_goi_tmdt],
    exact: true,
  },
  {
    path: ROUTES.PACKAGE_SCAN_E_COMMERCE,
    Component: () => <PackageScanPackageECommerce />,
    title: 'Quét đóng gói TMDT',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.INGREDIENT,
    Component: () => <Ingredient />,
    title: 'Danh sách phụ liệu',
    permissions: [PERMISSIONS.giao_dien_danh_sach_phu_lieu],
    exact: true,
  },
  {
    path: ROUTES.INGREDIENT_ADD,
    Component: () => <IngredientAdd />,
    title: 'Tạo phụ liệu',
    permissions: [PERMISSIONS.tao_phu_lieu],
    exact: true,
  },
  {
    path: ROUTES.SHIPPING_COMPANY,
    Component: () => <ShippingCompany />,
    title: 'Đơn vị vận chuyển',
    permissions: [PERMISSIONS.giao_dien_don_vi_van_chuyen],
    exact: true,
  },
  {
    path: ROUTES.SHIPPING,
    Component: () => <Shipping />,
    title: 'Chuyển hàng',
    permissions: [PERMISSIONS.giao_dien_chuyen_layout],
    exact: true,
  },
  {
    path: ROUTES.SHIPPING_COMPANY_ADD,
    Component: () => <ShippingCompanyAdd />,
    title: 'Tạo đơn vị vận chuyển',
    permissions: [PERMISSIONS.tao_don_vi_van_chuyen],
    exact: true,
  },
  {
    path: ROUTES.REPORT_IMPORT_WAREHOUSE,
    Component: () => <ReportImportWarehouse />,
    title: 'Báo cáo nhập kho',
    permissions: [PERMISSIONS.giao_dien_bao_cao_nhap_kho],
    exact: true,
  },
  {
    path: ROUTES.REPORT_EXPORT_WAREHOUSE,
    Component: () => <ReportExportWarehouse />,
    title: 'Báo cáo xuất kho',
    permissions: [PERMISSIONS.giao_dien_bao_cao_xuat_kho],
    exact: true,
  },
  {
    path: ROUTES.REPORT_PRODUCT_CHECKING,
    Component: () => <ReportProductChecking />,
    title: 'Báo cáo kiểm hàng',
    permissions: [PERMISSIONS.giao_dien_bao_cao_kiem_hang],
    exact: true,
  },
  {
    path: ROUTES.REPORT_IMPORT_ORDER,
    Component: () => <ReportImportOrder />,
    title: 'Báo cáo nhập hàng',
    permissions: [PERMISSIONS.giao_dien_bao_cao_nhap_hang],
    exact: true,
  },
  {
    path: ROUTES.REPORT_EXPORT_ORDER,
    Component: () => <ReportExportOrder />,
    title: 'Báo cáo xuất hàng',
    permissions: [PERMISSIONS.giao_dien_bao_cao_xuat_hang],
    exact: true,
  },
  {
    path: ROUTES.REPORT_PACK_ORDER,
    Component: () => <ReportPackOrder />,
    title: 'Báo cáo đóng gói',
    permissions: [PERMISSIONS.giao_dien_bao_cao_dong_goi],
    exact: true,
  },
  {
    path: ROUTES.REPORT_TRANSPORT_ORDER,
    Component: () => <ReportTransportOrder />,
    title: 'Báo cáo bàn giao vận chuyển',
    permissions: [PERMISSIONS.giao_dien_bao_cao_ban_giao_van_chuyen],
    exact: true,
  },
  {
    path: ROUTES.REPORT_INVENTORY_DETAIL,
    Component: () => <ReportInventoryDetail />,
    title: 'Báo cáo tồn kho chi tiết',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.SETTING_BOX,
    Component: () => <SettingBox />,
    title: 'Thùng đựng',
    permissions: [PERMISSIONS.giao_dien_thung_dung],
    exact: true,
  },
  {
    path: ROUTES.STORAGE_FEE,
    Component: () => <StorageFee />,
    title: 'Chi phí dịch vụ vận kho',
    permissions: [PERMISSIONS.giao_dien_chi_phi_kho],
    exact: true,
  },
  {
    path: ROUTES.ORDER_FEE,
    Component: () => <OrderFee />,
    title: 'Bảng kê chi phí',
    permissions: [PERMISSIONS.giao_dien_bang_ke_chi_phi],
    exact: true,
  },
  {
    path: ROUTES.STORAGE_FEE_ADD,
    Component: () => <StorageFeeAdd />,
    title: 'Tạo chi phí dịch vụ vận kho',
    permissions: [PERMISSIONS.tao_chi_phi_kho],
    exact: true,
  },
  {
    path: ROUTES.STORAGE_COST,
    Component: () => <StorageCost />,
    title: 'Chi phí lưu kho',
    permissions: [PERMISSIONS.giao_dien_chi_phi_luu_kho],
    exact: true,
  },
  {
    path: ROUTES.STORAGE_COST_ADD,
    Component: () => <StorageCostAdd />,
    title: 'Tạo chi phí lưu kho',
    permissions: [PERMISSIONS.tao_chi_phi_luu_kho],
    exact: true,
  },
  {
    path: ROUTES.ADD_STORAGE_FEE_OF_ORDER_FEE,
    Component: () => <AddStorageCostOfOrderFee />,
    title: 'Thêm chi phí lưu kho vào bảng kê chi phí',
    permissions: [PERMISSIONS.them_chi_phi_luu_kho_vao_bang_ke_chi_phi],
    exact: true,
  },
  {
    path: ROUTES.ADD_STORAGE_OTHER_OF_ORDER_FEE,
    Component: () => <AddStorageOtherOfOrderFee />,
    title: 'Thêm chi phí khác vào bảng kê chi phí',
    permissions: [PERMISSIONS.them_chi_phi_khac_vao_bang_ke_chi_phi],
    exact: true,
  },
  {
    path: ROUTES.ACTIVITY_HISTORY,
    Component: () => <ActivityHistory />,
    title: 'Lịch sử thao tác',
    permissions: [PERMISSIONS.giao_dien_lich_su_thao_tac],
    exact: true,
  },
  {
    path: ROUTES.PRINTER_MANAGEMENT,
    Component: () => <PrinterManagement />,
    title: 'Quản lý thiết bị in',
    permissions: [PERMISSIONS.giao_dien_quan_ly_thiet_bi_in],
    exact: true,
  },
  {
    path: ROUTES.MANAGEMENT_IMPORT_EXPORT,
    Component: () => <ManagementImportExport />,
    title: 'Lịch sử xuất/nhập file',
    permissions: [PERMISSIONS.giao_dien_quan_ly_xuat_nhap],
    exact: true,
  },
  {
    path: ROUTES.ORDER_COLLECTION_EXPORT,
    Component: () => <OrderCollectionExport />,
    title: 'Tổng hợp đơn hàng xuất',
    permissions: [PERMISSIONS.giao_dien_tong_hop_don_hang_xuat],
    exact: true,
  },
  {
    path: ROUTES.ORDER_COLLECTION_IMPORT,
    Component: () => <OrderCollectionImport />,
    title: 'Tổng hợp đơn hàng nhập',
    permissions: [PERMISSIONS.giao_dien_tong_hop_don_hang_nhap],
    exact: true,
  },
  {
    path: ROUTES.ORDER_DETAIL_IMPORT,
    Component: () => <OrderDetail />,
    title: 'Chi tiết đơn hàng nhập',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.ORDER_DETAIL_EXPORT,
    Component: () => <OrderDetail />,
    title: 'Chi tiết đơn hàng xuất',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.POSITION_INPUT,
    Component: () => <PositionInput />,
    title: 'Nhập vào vị trí',
    permissions: [PERMISSIONS.giao_dien_nhap_hang_vao_vi_tri],
    exact: true,
  },
  {
    path: ROUTES.PRODUCT_CHECKING,
    Component: () => <ProductChecking />,
    title: 'Kiểm hàng',
    permissions: [PERMISSIONS.giao_dien_kiem_hang],
    exact: true,
  },
  {
    path: ROUTES.TRANSPORT_TRANSFER,
    Component: () => <TransportTransfer />,
    title: 'Đợi bàn giao vận chuyển',
    permissions: [PERMISSIONS.giao_dien_ban_giao_van_chuyen],
    exact: true,
  },
  {
    path: ROUTES.TRANSPORTED_TRANSFER,
    Component: () => <TransportTransferred />,
    title: 'Đã bàn giao vận chuyển',
    permissions: [PERMISSIONS.giao_dien_da_ban_giao_van_chuyen],
    exact: true,
  },
  {
    path: ROUTES.PO_DELETE_LIST,
    Component: () => <PODeleteList />,
    title: 'Quản lý phiếu xóa',
    permissions: [PERMISSIONS.giao_dien_quan_ly_phieu_xoa],
    exact: true,
  },
  {
    path: ROUTES.IMPORT_WAREHOUSE_CREATE,
    Component: () => <ImportWarehouseForm />,
    title: 'Tạo phiếu nhập kho thủ công',
    permissions: [PERMISSIONS.tao_phieu_nhap_kho_thu_cong],
    exact: true,
  },
]

const AUTH_ROUTER = [
  {
    path: ROUTES.LOGIN,
    Component: () => <Login />,
    title: 'Đăng nhập',
    exact: true,
  },
  {
    path: ROUTES.PRIVACY,
    Component: () => <Privacy />,
    title: 'Privacy',
    exact: true,
  },
  {
    path: ROUTES.TERM_OF_SERVICE,
    Component: () => <TermOfService />,
    title: 'Term of service',
    exact: true,
  },
  {
    path: ROUTES.REPORT_INVENTORY,
    Component: () => <ReportInventory />,
    title: 'Báo cáo tồn kho tổng hợp',
    exact: true,
  },
]

export default function Views() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact={true}>
          <Redirect to={ROUTES.DASHBOARD} />
        </Route>

        {AUTH_ROUTER.map(({ Component, ...rest }, index) => (
          <Route {...rest} key={index}>
            <Component />
          </Route>
        ))}

        {DEFINE_ROUTER.map(({ Component, ...rest }, index) => (
          <Route {...rest} key={index}>
            <Authentication {...rest}>
              <Layout>
                <Component />
              </Layout>
            </Authentication>
          </Route>
        ))}

        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}
