import { ACTION } from 'consts'
import { clearLocalStorage } from 'utils'
import jwt_decode from 'jwt-decode'

const initialState = {}

var user = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.LOGIN: {
      const token = action.data.accessToken
      const reToken = action.data.refreshToken
      if (token) localStorage.setItem('accessToken', token)
      if (reToken) localStorage.setItem('refreshToken', reToken)

      const data = token && jwt_decode(token)

      return data ? { ...data } : {}
    }

    case ACTION.LOGOUT: {
      clearLocalStorage()
      return {}
    }

    default:
      return state
  }
}
export default user
