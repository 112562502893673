import React, { useState, useEffect } from 'react'
import moment from 'moment'

//antd
import { Select, DatePicker } from 'antd'
import { useTranslation } from 'react-i18next'
import SuffixIconCustom from 'components/suffixIconCustom'

const { RangePicker } = DatePicker
export default function FilterDate({
  placeholder = 'Lọc theo thời gian',
  paramsFilter = {},
  setParamsFilter,
  width = '100%',
  style,
  allowClear = true,
}) {
  const [isOpenSelect, setIsOpenSelect] = useState(false)
  const { t } = useTranslation()
  const toggleOpenSelect = () => setIsOpenSelect(!isOpenSelect)
  const [valueTime, setValueTime] = useState() //dùng để hiện thị value trong filter by time
  const [valueDateTimeSearch, setValueDateTimeSearch] = useState({})
  const [valueDateSearch, setValueDateSearch] = useState(null) //dùng để hiện thị date trong filter by date

  const PARAMS = [
    'create_from_date',
    'create_to_date',
    'create_today',
    'create_yesterday',
    'create_this_week',
    'create_last_week',
    'create_last_month',
    'create_this_month',
    'create_this_year',
    'create_last_year',
  ]

  //check có đang filter date hay không
  //nếu không thì reset value select date và select time
  const resetFilterDate = () => {
    const keysParamsFilter = Object.keys(paramsFilter)
    for (let i = 0; i < keysParamsFilter.length; i++)
      if (PARAMS.includes(keysParamsFilter[i])) {
        if (
          keysParamsFilter[i] === 'create_from_date' ||
          keysParamsFilter[i] === 'create_to_date'
        ) {
          setValueDateSearch([
            moment(paramsFilter.create_from_date),
            moment(paramsFilter.create_to_date),
          ])
          setValueTime(`${paramsFilter.create_from_date} -> ${paramsFilter.create_to_date}`)
        } else {
          let obj = {}
          obj[keysParamsFilter[i]] = true
          setValueDateTimeSearch(obj)
          setValueTime(keysParamsFilter[i])
        }

        return
      }
    setValueDateSearch(null)
    setValueDateTimeSearch({})
    setValueTime()
  }

  useEffect(() => {
    resetFilterDate()
  }, [paramsFilter])

  return (
    <Select
      style={{ width: width, ...style }}
      open={isOpenSelect}
      onBlur={() => {
        if (isOpenSelect) toggleOpenSelect()
      }}
      onClick={() => {
        if (!isOpenSelect) toggleOpenSelect()
      }}
      suffixIcon={<SuffixIconCustom size="default" />}
      allowClear={allowClear}
      showSearch
      placeholder={placeholder}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      value={valueTime}
      onChange={async (value) => {
        setValueTime(value)

        paramsFilter.page = 1

        //xoa params search date hien tai
        const p = Object.keys(valueDateTimeSearch)
        if (p.length) delete paramsFilter[p[0]]

        setValueDateSearch(null)
        delete paramsFilter.create_from_date
        delete paramsFilter.create_to_date

        if (isOpenSelect) toggleOpenSelect()

        if (value) {
          const searchDate = Object.fromEntries([[value, true]]) // them params search date moi

          setParamsFilter({ ...paramsFilter, ...searchDate })
          setValueDateTimeSearch({ ...searchDate })
        } else {
          setParamsFilter({ ...paramsFilter })
          setValueDateTimeSearch({})
        }
      }}
      dropdownRender={(menu) => (
        <>
          <RangePicker
            onFocus={() => {
              if (!isOpenSelect) toggleOpenSelect()
            }}
            onBlur={() => {
              if (isOpenSelect) toggleOpenSelect()
            }}
            value={valueDateSearch}
            onChange={(dates, dateStrings) => {
              //khi search hoac filter thi reset page ve 1
              paramsFilter.page = 1

              if (isOpenSelect) toggleOpenSelect()

              //nếu search date thì xoá các params date
              delete paramsFilter.create_today
              delete paramsFilter.create_yesterday
              delete paramsFilter.create_this_week
              delete paramsFilter.create_last_week
              delete paramsFilter.create_last_month
              delete paramsFilter.create_this_month
              delete paramsFilter.create_this_year
              delete paramsFilter.create_last_year

              //Kiểm tra xem date có được chọn ko
              //Nếu ko thì thoát khỏi hàm, tránh cash app
              //và get danh sách order
              if (!dateStrings[0] && !dateStrings[1]) {
                delete paramsFilter.create_from_date
                delete paramsFilter.create_to_date

                setValueDateSearch(null)
                setValueTime()
              } else {
                const dateFirst = dateStrings[0]
                const dateLast = dateStrings[1]
                setValueDateSearch(dates)
                setValueTime(`${dateFirst} -> ${dateLast}`)

                dateFirst.replace(/-/g, '/')
                dateLast.replace(/-/g, '/')

                paramsFilter.create_from_date = dateFirst
                paramsFilter.create_to_date = dateLast
              }

              setParamsFilter({ ...paramsFilter })
            }}
            style={{ width: '100%' }}
          />
          {menu}
        </>
      )}
    >
      <Select.Option value="create_today">Hôm nay</Select.Option>
      <Select.Option value="create_yesterday">Hôm qua</Select.Option>
      <Select.Option value="create_this_week">Tuần này</Select.Option>
      <Select.Option value="create_last_week">Tuần trước</Select.Option>
      <Select.Option value="create_this_month">Tháng này</Select.Option>
      <Select.Option value="create_last_month">Tháng trước</Select.Option>
      <Select.Option value="create_this_year">Năm nay</Select.Option>
      <Select.Option value="create_last_year">Năm trước</Select.Option>
    </Select>
  )
}
