import React, { useRef, useState } from 'react'
import { formatCash, splitArray } from 'utils'
import styles from './transport-transfer.module.scss'
import { useReactToPrint } from 'react-to-print'
import Barcode from 'react-barcode'
import QR from 'qrcode-base64'
import moment from 'moment'
import { SIZE_TABLE } from 'consts'

//antd
import { Col, Row, Table, Button, Divider } from 'antd'

const pageStyle = `
  @media print {
    @page {
      @bottom-center {
        content: "This is page " counter(page) " of " counter(pages) !important;
      }
    }
  }
  `
const getPageMargins = () => `@page { margin: 10px 0px !important; }`
const PrintTemplate = ({ records = [], isTransported = false }) => {
  if (!records.length) return null

  let finalWeight = 0
  let finalCbm = 0

  var imgDataTrackingNumber = QR.drawImg(records[0].customer_order_code)

  records.map((product) => {
    const totalWeight = product.products.reduce((acc, item) => {
      return acc + item.weight * item.export_quantity
    }, 0)
    const totalCbm = product.products.reduce((acc, item) => {
      return acc + Number(item.cbm) * Number(item.export_quantity || 1)
    }, 0)
    console.log('totalCbm', totalCbm)

    finalWeight += totalWeight
    finalCbm += totalCbm
    console.log('finalCbm', finalCbm)
  })

  const _addressRender = (object, prefix) => {
    //prefix có thể là return, to, shipping_company
    if (!object) return ''
    const location = ['address', 'ward', 'district', 'province']
    const locationWPrefix = location.map((item) => {
      return (prefix ? `${prefix}_` : '') + item
    })
    let locationWPrefixVals = []
    locationWPrefix.forEach((item) => {
      if (object[item]) {
        locationWPrefixVals.push(object[item])
      }
    })
    return locationWPrefixVals.filter((item) => item).join(', ')
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      align: 'center',
      width: 30,
      render: (text, record, index) => index + 1,
    },
    {
      width: 70,
      title: 'Mã hàng',
      dataIndex: 'barcode',
      align: 'center',
    },
    {
      width: '100%',
      title: 'Tên hàng',
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: 'Mã kiện hàng',
      dataIndex: 'package_code',
      align: 'center',
      render: (text, record) => {
        const code = record.package_code.split('-')
        return code[code.length - 1] || ''
      },
      width: 70,
    },
    {
      width: 30,
      title: 'Số lượng',
      dataIndex: 'quantity_in_package',
      align: 'center',
      render: (text) => formatCash(text || 0),
    },
    {
      width: 70,
      title: 'Quy cách',
      dataIndex: 'convention',
      align: 'center',
    },
    {
      width: 70,
      title: 'Đơn vị tính',
      align: 'center',
      render: (text, record) => record.unit_info && record.unit_info.name,
    },
    {
      width: 70,
      title: 'Ghi chú',
      dataIndex: 'note',
      align: 'center',
    },
  ]

  const columns1 = [
    {
      title: <span style={{ fontWeight: 400, fontSize: 14 }}>STT</span>,
      render: (text, record, index) => index + 1,
    },
    {
      title: <span style={{ fontWeight: 400, fontSize: 14 }}>1</span>,
      dataIndex: '0',
    },
    {
      title: <span style={{ fontWeight: 400, fontSize: 14 }}>2</span>,
      dataIndex: '1',
    },
    {
      title: <span style={{ fontWeight: 400, fontSize: 14 }}>3</span>,
      dataIndex: '2',
    },
    {
      title: <span style={{ fontWeight: 400, fontSize: 14 }}>4</span>,
      dataIndex: '3',
    },
    {
      title: <span style={{ fontWeight: 400, fontSize: 14 }}>5</span>,
      dataIndex: '4',
    },
    {
      title: <span style={{ fontWeight: 400, fontSize: 14 }}>6</span>,
      dataIndex: '5',
    },
  ]
  const columns2 = [
    {
      title: (
        <span style={{ fontWeight: 400, fontSize: 14 }}>Đại Diện Bên Giao (Ký, Ghi rõ họ tên)</span>
      ),
      align: 'center',
      render: () => <div style={{ height: 100 }}></div>,
    },
    {
      title: (
        <span style={{ fontWeight: 400, fontSize: 14 }}>
          Đại Diện Vận Chuyển (Ký, Ghi rõ họ tên)
        </span>
      ),
      render: () =>
        records.length &&
        records[0].shipping_info &&
        records[0].shipping_info.shipping_employee_signature &&
        isTransported && (
          <img
            width={140}
            height={100}
            src={records[0].shipping_info.shipping_employee_signature}
            alt=""
          />
        ),
      align: 'center',
    },
    {
      title: <span style={{ fontWeight: 400, fontSize: 14 }}>Đại Diện Giám Sát (nếu có)</span>,
      align: 'center',
    },
    {
      title: (
        <span style={{ fontWeight: 400, fontSize: 14 }}>Đại Diện Bên Nhận (Ký, Ghi rõ họ tên)</span>
      ),
      align: 'center',
    },
  ]

  return (
    <div className={styles['print-template']}>
      <Row gutter={20}>
        <Col span={16}>
          <b>CÔNG TY TNHH KHO VẬN SÀI GÒN</b>
          <p style={{ marginBottom: 0 }}>Email: info@saigonlogistics.com.vn</p>
          <p>Hotline: 0936049446</p>
          {records.length && records[0].shippingCodeList ? (
            <h2 style={{ marginBottom: 0, fontWeight: 'bold' }}>BIÊN BẢN BÀN GIAO HÀNG LOẠT</h2>
          ) : (
            <h2 style={{ marginBottom: 0, fontWeight: 'bold' }}>
              PHIẾU XUẤT HÀNG KIÊM BIÊN BẢN BÀN GIAO
            </h2>
          )}

          <span>
            Ngày đóng gói: &nbsp;
            {moment(new Date()).format('HH [giờ], mm [phút], [ngày] DD, [tháng] MM, [năm] YYYY')}
          </span>
        </Col>
        <Col span={6}>
          <img
            src="https://admin-order.s3.ap-northeast-1.wasabisys.com/2022/02/07/860da969-7cfa-4c1d-88af-6d99ab5099f5/sgl%20avatar.jpg"
            alt="sgl"
            style={{ width: '100%', height: 35, marginBottom: 20 }}
          />
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <div>
              {/* <img src={imgData} alt="" style={{ width: 40, height: 40 }} /> */}
              {/* <Barcode
                value={record.code}
                fontSize={16}
                textAlign="left"
                width={1}
                height={20}
                text={' '}
                textPosition="top"
                marginLeft={1}
                marginBottom={0}
              /> */}
              {/* <p style={{ marginBottom: 0, fontStyle: 'bold' }}>Mã phiếu:</p> */}
              {/* <p style={{ marginBottom: 0, fontStyle: 'bold' }}>{record.code}</p> */}
            </div>
            {/* <img
              src="https://s3.ap-northeast-1.wasabisys.com/admin-order/2022/02/08/7184fd15-326d-422c-a15c-e1110e30ed77/images.png"
              alt="printer"
              style={{ width: 50, height: 50 }}
            /> */}
          </div>
          {/* <img
            src="https://s3.ap-northeast-1.wasabisys.com/admin-order/2022/02/08/1ea0b950-4a80-45fa-b335-8b44642a5a0a/photo_2022-02-08_08-57-05.jpg"
            alt="arrow"
            style={{ width: 180, height: 30 }}
          /> */}
        </Col>
      </Row>
      <div>
        <div style={{ marginTop: 20 }}>
          <Divider />
          <Row style={{ marginBottom: 10 }} gutter={50}>
            <Col span={13}>
              <p style={{ marginBottom: 0, fontStyle: 'italic' }}>
                Bên giao: <span style={{ fontWeight: 'bold' }}>CÔNG TY TNHH KHO VẬN SÀI GÒN</span>
              </p>
              <p style={{ marginBottom: 0, fontStyle: 'italic' }}>
                Mã kho giao: {records[0].warehouse_info?.hotname}
              </p>
              <p style={{ marginBottom: 0, fontStyle: 'italic' }}>
                Nhân viên bàn giao: {records[0].transport_employee_info?.fullname}
              </p>
              {/* <p style={{ marginBottom: 0, fontStyle: 'italic' }}>
                    SĐT: {records[0].transport_employee_info?.phone}
                  </p> */}
            </Col>
            <Col span={10} style={{ borderLeft: '1px solid black', paddingLeft: 10 }}>
              {/* <p style={{ marginBottom: 0, fontStyle: 'italic' }}>
                    Bên nhận:&nbsp;
                    {records[0].shipping_info?.shipping_company_name}
                  </p> */}
              <p style={{ marginBottom: 0, fontWeight: 'bold', fontStyle: 'italic' }}>
                Khách hàng: {records[0].customer_info?.fullname} - {records[0].customer_info?.code}
              </p>
              <p style={{ marginBottom: 0, fontStyle: 'italic' }}>
                Người nhận: {records[0].shipping_info?.to_name}
              </p>
              <p style={{ marginBottom: 0, fontStyle: 'italic' }}>
                SĐT: {records[0].shipping_info?.to_phone}
              </p>
            </Col>
          </Row>
          <div>
            <div style={{ display: 'flex', gap: 30 }}>
              <p style={{ marginBottom: 0 }}>
                Số xe:{' '}
                {records[0].shipping_info && records[0].shipping_info.car_number
                  ? records[0].shipping_info.car_number
                  : '............................'}
              </p>
              <p style={{ marginBottom: 0 }}>Tài xế: ............................</p>
            </div>

            <div style={{ display: 'flex', gap: 30 }}>
              <span>Tổng thể tích (CBM): {formatCash(finalCbm || 0)} cm³</span>
              <span>Tổng khối lượng: {formatCash(finalWeight || 0)} kg</span>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ marginBottom: 0 }}>
              Giao từ địa chỉ: {_addressRender(records[0].warehouse_info)}.
            </span>
            <span style={{ marginBottom: 0 }}>
              Đến địa chỉ: {_addressRender(records[0].shipping_info, 'to')}
            </span>
            <span style={{ marginBottom: 0 }}>
              Ngày bàn giao: ........................, ngày............, tháng............,
              năm............
            </span>
          </div>

          {records.map((product) => {
            console.log(product)
            var imgData = QR.drawImg(product.code, {
              typeNumber: 4,
              errorCorrectLevel: 'M',
              size: 500,
            })

            let productsOfPackage = []
            var sum = 0
            product.packages.map((pkg) =>
              pkg.products.map((p) => {
                sum += p.cbm * p.quantity_in_package
                productsOfPackage.push({ ...p, package_code: pkg.package_code })
              })
            )
            return (
              <div style={{ marginTop: 20 }}>
                {product.shippingCodeList ? (
                  <>
                    <Table
                      className={styles['table-product']}
                      bordered
                      style={{ width: '100%' }}
                      size={SIZE_TABLE}
                      columns={columns1}
                      dataSource={splitArray(product.shippingCodeList)}
                      pagination={false}
                    />
                    <p
                      style={{
                        fontWeight: 'bold',
                        fontSize: 18,
                        display: 'flex',
                        marginBottom: 10,
                      }}
                    >
                      TỔNG SỐ {product.totalOrder} ĐƠN HÀNG
                    </p>
                    <p
                      style={{
                        fontWeight: 'bold',
                        fontSize: 18,
                      }}
                    >
                      (NẾU MUỐN IN RA BIÊN BẢN TỪNG PO THÌ TÌM PO ĐÓ BẤM VÀO SẼ RA CHI TIẾT SỐ LƯỢNG
                      HÀNG HÓA IN RA ĐƯỢC BIÊN BẢN GIẤY A4) CÒN TRÊN MBAPP NÀY CÓ THỂ SẼ IN PHIẾU
                      RA)
                    </p>
                  </>
                ) : (
                  <>
                    <Row justify="space-between" align="middle">
                      <img
                        src={imgDataTrackingNumber}
                        alt=""
                        style={{ width: 65, height: 65, marginRight: 5, marginBottom: 5 }}
                      />

                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ margin: 0 }}>Mã vận đơn: {product.customer_order_code}</span>
                        <span style={{ margin: 0 }}>Mã phiếu xuất: {product.code}</span>
                      </div>
                      <img
                        src={imgData}
                        alt=""
                        style={{ width: 65, height: 65, marginRight: 5, marginBottom: 5 }}
                      />
                    </Row>

                    <Table
                      bordered
                      className={styles['table-product']}
                      style={{ width: '100%', marginBottom: 5 }}
                      size={SIZE_TABLE}
                      columns={columns}
                      dataSource={productsOfPackage}
                      pagination={false}
                    />
                    <div style={{ fontWeight: 'bold', fontSize: 14.5, marginBottom: 20 }}>
                      <div>
                        Tổng mã sản phẩm:{' '}
                        {
                          productsOfPackage.filter(
                            (v, i, a) => a.findIndex((v2) => v2.product_id === v.product_id) === i
                          ).length
                        }
                      </div>
                      <div>Tổng SL kiện hàng: {formatCash(product.packages.length || 0)}</div>
                      <div>
                        Tổng SL sản phẩm:{' '}
                        {formatCash(
                          productsOfPackage.reduce(
                            (total, current) => total + current.quantity_in_package,
                            0
                          )
                        )}
                      </div>
                      <div>Tổng thể tích (CBM): {formatCash(sum)} cm³</div>
                    </div>
                  </>
                )}
              </div>
            )
          })}
        </div>
      </div>
      <Divider />
      <b>
        <div>
          <div>*** Lưu ý:</div>
          <div>
            - Đại diện bên giao, bên vận chuyển và bên nhận đã kiểm tra, ký xác nhận hàng hóa nguyên
            vẹn, không nứt vỡ và đầy đủ số lượng như danh sách bên trên.
          </div>
          <div>
            - Nếu có bất cứ vấn đề gì vui lòng gọi ngay <i>0936 049 446</i> trước khi ký nhận. SGL
            chỉ xử lý các vấn đề về hàng hóa trong vòng 24 giờ kể từ lúc nhận hàng. Sau thời gian
            trên xem như hàng hóa đã được giao nhận đủ. Người nhận hàng vui lòng ký, ghi rõ họ tên,
            đóng mộc giáp lai hoặc ký nháy mỗi trang.
          </div>
          <div>- SGL xin chân thành cảm ơn.</div>
        </div>
      </b>
      <Row>
        <Table
          bordered
          className={styles['table-product']}
          style={{ width: '100%' }}
          size={SIZE_TABLE}
          columns={columns2}
          dataSource={[{}]}
          pagination={false}
        />
      </Row>
      {/* <footer className={styles['page-break']}></footer> */}
    </div>
  )
}

const PrintTemplate1 = ({ imgUrl }) => {
  const getPageMargins = () => `@page { margin: 50px !important; }`
  return (
    <div className={styles['print-template']}>
      <style>{getPageMargins()}</style>
      <img src={imgUrl} width="100%" height="100%" alt="" />
      <footer className={styles['page-break']}></footer>
    </div>
  )
}
class ComponentPrintAllExpense extends React.Component {
  render() {
    const { records, isTransported } = this.props

    let printingPages = []
    for (const record of records) {
      let tempTemplate
      if (record.invoice_url) tempTemplate = <PrintTemplate1 imgUrl={record.invoice_url} />
      printingPages.push(tempTemplate)
    }
    const template = <PrintTemplate isTransported={isTransported} records={records} />
    printingPages.push(template)
    return <div>{printingPages}</div>
  }
}

function ButtonPrintAllExpense({
  _getExportOrders,
  _updateExportOrders,
  tableRecords,
  text,
  printShippingCode,
  isTransported = false,
}) {
  const [records, setRecords] = useState([])

  const componentRef1 = useRef()

  const _handlePrint = async () => {
    // nếu có table record thì truyền thẳng xuống dưới
    if (!tableRecords) {
      if (printShippingCode) {
        //nếu bản in có mã vận đơn
        //_getExportOrders lấy tất cả đơn hàng với filter có sẵn, bỏ page, page_size và ko set lại data bên dưới
        const data = await _getExportOrders(undefined, true)
        if (data && data.length === 0) {
          setRecords([])
        } else {
          if (_updateExportOrders) _updateExportOrders(data)
          const shippingCodeList = data
            .map((e) => {
              return e.shipping_info?.shipping_code || ''
            })
            .filter((e) => e)
          const shippingCodeRecord = { ...data[0], shippingCodeList, totalOrder: data.length }
          setRecords([shippingCodeRecord])
        }
      } else {
        const data = await _getExportOrders(undefined, true)
        if (_updateExportOrders) _updateExportOrders(data)
        setRecords(data)
      }
    } else if (_updateExportOrders) _updateExportOrders(tableRecords)
    _print()
  }

  const _print = useReactToPrint({
    content: () => componentRef1.current,
    removeAfterPrint: true,
    copyStyles: true,
    pageStyle: pageStyle,
  })

  return (
    <>
      <div style={{ display: 'none' }}>
        <ComponentPrintAllExpense
          isTransported={isTransported}
          records={(records.length && records) || tableRecords || []}
          ref={componentRef1}
        />
      </div>
      <Button className={styles['transport-transfer-button--green1']} onClick={_handlePrint}>
        {text}
      </Button>
    </>
  )
}

export default ButtonPrintAllExpense
