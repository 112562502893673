const columns = [
  {
    key: 'is_checked',
    dataIndex: 'is_checked',
  },
  {
    key: 'is_imported',
    dataIndex: 'is_imported',
  },
]
export default columns
