import { PERMISSIONS } from 'consts'

const foo = [
  {
    title: 'Tạo phiếu nhập kho',
    key: PERMISSIONS.tao_phieu_nhap_kho_thu_cong,
  },
  {
    title: 'Giao diện sản phẩm',
    key: PERMISSIONS.giao_dien_san_pham,
    children: [
      {
        title: 'Xem chi tiết sản phẩm',
        key: PERMISSIONS.xem_san_pham,
      },
      {
        title: 'Tạo sản phẩm',
        key: PERMISSIONS.tao_san_pham,
      },
      {
        title: 'Thêm ảnh sản phẩm',
        key: PERMISSIONS.them_anh_san_pham,
      },
      {
        title: 'Cập nhật sản phẩm',
        key: PERMISSIONS.cap_nhat_san_pham,
      },
      {
        title: 'Cập nhật mã hàng/barcode bằng file excel',
        key: PERMISSIONS.cap_nhat_ma_san_pham,
      },
      {
        title: 'Xóa sản phẩm',
        key: PERMISSIONS.xoa_san_pham,
      },
    ],
  },
  {
    title: 'Giao diện quản lý quy cách sản phẩm',
    key: PERMISSIONS.giao_dien_quan_ly_quy_cach_san_pham,
    children: [
      {
        title: 'Thêm quy cách sản phẩm',
        key: PERMISSIONS.them_quy_cach_san_pham,
      },
      {
        title: 'Cập nhật quy cách sản phẩm',
        key: PERMISSIONS.cap_nhat_quy_cach_san_pham,
      },
      {
        title: 'Xóa quy cách sản phẩm',
        key: PERMISSIONS.xoa_quy_cach_san_pham,
      },
    ],
  },
  {
    title: 'Giao diện tổng hợp đơn hàng xuất',
    key: PERMISSIONS.giao_dien_tong_hop_don_hang_xuat,
  },
  {
    title: 'Giao diện tổng hợp đơn hàng nhập',
    key: PERMISSIONS.giao_dien_tong_hop_don_hang_nhap,
  },
  {
    title: 'Xóa đơn hàng nhập',
    key: PERMISSIONS.xoa_don_hang_nhap,
  },
  {
    title: 'Xóa đơn hàng xuất',
    key: PERMISSIONS.xoa_don_hang_xuat,
  },
  {
    title: 'Giao diện quản lý phiếu xóa',
    key: PERMISSIONS.giao_dien_quan_ly_phieu_xoa,
  },
  {
    title: 'Giao diện quản lý doanh nghiệp',
    key: PERMISSIONS.giao_dien_quan_ly_doanh_nghiep,
    children: [
      {
        title: 'Tạo doanh nghiệp',
        key: PERMISSIONS.tao_doanh_nghiep,
      },
      {
        title: 'Cập nhật doanh nghiệp',
        key: PERMISSIONS.cap_nhat_doanh_nghiep,
      },
      {
        title: 'Xóa doanh nghiệp',
        key: PERMISSIONS.xoa_doanh_nghiep,
      },
    ],
  },
  {
    title: 'Giao diện danh sách phiếu nhập',
    key: PERMISSIONS.giao_dien_danh_sach_nhap_hang,
    children: [
      {
        title: 'Thêm chi phí vào phiếu nhập',
        key: PERMISSIONS.them_chi_phi_vao_phieu_nhap,
      },
    ],
  },
  {
    title: 'Giao diện kiểm hàng',
    key: PERMISSIONS.giao_dien_kiem_hang,
    children: [
      {
        title: 'Tạo phiếu nhập hàng bằng file excel',
        key: PERMISSIONS.tao_kiem_hang,
      },
      {
        title: 'Cập nhật biên lai phiếu nhập',
        key: PERMISSIONS.cap_nhat_bien_lai_phieu_nhap,
      },
    ],
  },
  // {
  //   title: 'Giao diện nhập hàng vào vị trí',
  //   key: PERMISSIONS.giao_dien_nhap_hang_vao_vi_tri,
  //   children: [
  //     {
  //       title: 'Cập nhật nhập hàng vào vị trí',
  //       key: PERMISSIONS.cap_nhat_nhap_hang_vao_vi_tri,
  //     },
  //   ],
  // },
  {
    title: 'Giao diện danh sách phiếu xuất',
    key: PERMISSIONS.giao_dien_danh_sach_xuat_hang,
    children: [
      {
        title: 'Tạo phiếu xuất bằng file excel',
        key: PERMISSIONS.tao_danh_sach_xuat_hang,
      },
      {
        title: 'Cập nhật danh sách xuất hàng',
        key: PERMISSIONS.cap_nhat_danh_sach_xuat_hang,
      },
      {
        title: 'Thêm chi phí vào phiếu xuất',
        key: PERMISSIONS.them_chi_phi_vao_phieu_xuat,
      },
    ],
  },
  {
    title: 'Giao diện đóng gói',
    key: PERMISSIONS.giao_dien_dong_goi,
  },
  {
    title: 'Giao diện đóng gói TMDT',
    key: PERMISSIONS.giao_dien_dong_goi_tmdt,
  },
  {
    title: 'Giao diện bàn giao vận chuyển',
    key: PERMISSIONS.giao_dien_ban_giao_van_chuyen,
    children: [
      {
        title: 'Cập nhật bàn giao vận chuyển',
        key: PERMISSIONS.cap_nhat_ban_giao_van_chuyen,
      },
    ],
  },
  {
    title: 'Giao diện đã bàn giao vận chuyển',
    key: PERMISSIONS.giao_dien_da_ban_giao_van_chuyen,
  },
  {
    title: 'Giao diện kho hàng',
    key: PERMISSIONS.giao_dien_kho_hang,
    children: [
      {
        title: 'Tạo kho hàng',
        key: PERMISSIONS.tao_kho_hang,
      },
      {
        title: 'Tạo kệ hàng',
        key: PERMISSIONS.tao_ke_hang,
      },
      {
        title: 'Cập nhật kho hàng',
        key: PERMISSIONS.cap_nhat_kho_hang,
      },
      {
        title: 'Xóa kho hàng',
        key: PERMISSIONS.xoa_kho_hang,
      },
    ],
  },
  {
    title: 'Giao diện ô chứa hàng',
    key: PERMISSIONS.giao_dien_o_chua_hang,
    children: [
      {
        title: 'Cập nhật ô chứa hàng',
        key: PERMISSIONS.cap_nhat_o_chua_hang,
      },
    ],
  },
  {
    title: 'Giao diện chuyển layout',
    key: PERMISSIONS.giao_dien_chuyen_layout,
  },
  {
    title: 'Giao diện thùng đựng',
    key: PERMISSIONS.giao_dien_thung_dung,
    children: [
      {
        title: 'Tạo thùng đựng',
        key: PERMISSIONS.tao_thung_dung,
      },
      {
        title: 'Cập nhật thùng đựng',
        key: PERMISSIONS.cap_nhat_thung_dung,
      },
      {
        title: 'Xóa thùng đựng',
        key: PERMISSIONS.xoa_thung_dung,
      },
    ],
  },
  {
    title: 'Giao diện danh sách phụ liệu',
    key: PERMISSIONS.giao_dien_danh_sach_phu_lieu,
    children: [
      {
        title: 'Tạo phụ liệu',
        key: PERMISSIONS.tao_phu_lieu,
      },
      {
        title: 'Cập nhật phụ liệu',
        key: PERMISSIONS.cap_nhat_phu_lieu,
      },
      {
        title: 'Xóa phụ liệu',
        key: PERMISSIONS.xoa_phu_lieu,
      },
    ],
  },
  {
    title: 'Giao diện chi phí dịch vụ vận kho',
    key: PERMISSIONS.giao_dien_chi_phi_kho,
    children: [
      {
        title: 'Tạo chi phí dịch vụ vận kho',
        key: PERMISSIONS.tao_chi_phi_kho,
      },
      {
        title: 'Cập nhật chi phí dịch vụ vận kho',
        key: PERMISSIONS.cap_nhat_chi_phi_kho,
      },
      {
        title: 'Xóa chi phí dịch vụ vận kho',
        key: PERMISSIONS.xoa_chi_phi_kho,
      },
    ],
  },
  {
    title: 'Giao diện chi phí lưu kho',
    key: PERMISSIONS.giao_dien_chi_phi_luu_kho,
    children: [
      {
        title: 'Tạo chi phí lưu kho',
        key: PERMISSIONS.tao_chi_phi_luu_kho,
      },
      {
        title: 'Cập nhật chi phí lưu kho',
        key: PERMISSIONS.cap_nhat_chi_phi_luu_kho,
      },
      {
        title: 'Xóa chi phí lưu kho',
        key: PERMISSIONS.xoa_chi_phi_luu_kho,
      },
    ],
  },
  {
    title: 'Giao diện bảng kê chi phí',
    key: PERMISSIONS.giao_dien_bang_ke_chi_phi,
    children: [
      {
        title: 'Kiểm duyệt bảng kê chi phí',
        key: PERMISSIONS.kiem_duyet_bang_ke_chi_phi,
      },
      {
        title: 'Xóa bảng kê chi phí',
        key: PERMISSIONS.xoa_bang_ke_chi_phi,
      },
      {
        title: 'Thêm chi phí lưu kho vào bảng kê chi phí',
        key: PERMISSIONS.them_chi_phi_luu_kho_vao_bang_ke_chi_phi,
      },
      {
        title: 'Thêm chi phí khác vào bảng kê chi phí',
        key: PERMISSIONS.them_chi_phi_khac_vao_bang_ke_chi_phi,
      },
    ],
  },
  {
    title: 'Giao diện đơn vị vận chuyển',
    key: PERMISSIONS.giao_dien_don_vi_van_chuyen,
    children: [
      {
        title: 'Tạo đơn vị vận chuyển',
        key: PERMISSIONS.tao_don_vi_van_chuyen,
      },
      {
        title: 'Cập nhật đơn vị vận chuyển',
        key: PERMISSIONS.cap_nhat_don_vi_van_chuyen,
      },

      {
        title: 'Xóa đơn vị vận chuyển',
        key: PERMISSIONS.xoa_don_vi_van_chuyen,
      },
    ],
  },
  {
    title: 'Giao diện quản lý nhân viên',
    key: PERMISSIONS.giao_dien_quan_ly_nhan_vien,
    children: [
      {
        title: 'Tạo nhân viên',
        key: PERMISSIONS.tao_nhan_vien,
      },
      {
        title: 'Cập nhật nhân viên',
        key: PERMISSIONS.cap_nhat_nhan_vien,
      },
      {
        title: 'Xóa nhân viên',
        key: PERMISSIONS.xoa_nhan_vien,
      },
    ],
  },
  {
    title: 'Giao diện quản lý phân quyền',
    key: PERMISSIONS.giao_dien_quan_ly_phan_quyen,
    children: [
      {
        title: 'Thêm vai trò',
        key: PERMISSIONS.tao_phan_quyen,
      },
      {
        title: 'Cập nhật phân quyền',
        key: PERMISSIONS.cap_nhat_phan_quyen,
      },
      {
        title: 'Xóa phân quyền',
        key: PERMISSIONS.xoa_phan_quyen,
      },
    ],
  },
  {
    title: 'Giao diện lịch sử thao tác',
    key: PERMISSIONS.giao_dien_lich_su_thao_tac,
  },
  {
    title: 'Giao diện lịch sử xuất/nhập file',
    key: PERMISSIONS.giao_dien_quan_ly_xuat_nhap,
  },
  {
    title: 'Giao diện quản lý thiết bị in',
    key: PERMISSIONS.giao_dien_quan_ly_thiet_bi_in,
    children: [
      {
        title: 'Cập nhật thiết bị in',
        key: PERMISSIONS.cap_nhat_thiet_bi_in,
      },
    ],
  },
  {
    title: 'Giao diện báo cáo tồn kho tổng hợp',
    key: PERMISSIONS.giao_dien_bao_cao_ton_kho_tong_hop,
  },
  {
    title: 'Giao diện báo cáo nhập kho',
    key: PERMISSIONS.giao_dien_bao_cao_nhap_kho,
  },
  {
    title: 'Giao diện báo cáo xuất kho',
    key: PERMISSIONS.giao_dien_bao_cao_xuat_kho,
  },
  {
    title: 'Giao diện báo cáo kiểm hàng',
    key: PERMISSIONS.giao_dien_bao_cao_kiem_hang,
  },
  {
    title: 'Giao diện báo cáo nhập hàng',
    key: PERMISSIONS.giao_dien_bao_cao_nhap_hang,
  },
  {
    title: 'Giao diện báo cáo xuất hàng',
    key: PERMISSIONS.giao_dien_bao_cao_xuat_hang,
  },
  {
    title: 'Giao diện báo cáo đóng gói',
    key: PERMISSIONS.giao_dien_bao_cao_dong_goi,
  },
  {
    title: 'Giao diện báo cáo bàn giao vận chuyển',
    key: PERMISSIONS.giao_dien_bao_cao_ban_giao_van_chuyen,
  },
]
export default foo
