const columns = [
  {
    key: 'STT',
    title: 'STT',
    align: 'center',
    width: 50,
  },
  {
    key: 'code/barcode',
    title: 'Mã sản phẩm',
    align: 'center',
  },
  {
    key: 'name',
    title: 'Tên sản phẩm',
    dataIndex: 'name',
    align: 'center',
  },
  {
    key: 'image',
    title: 'Hình ảnh',
    dataIndex: 'images',
    align: 'center',
  },
  {
    title: 'Đơn vị nhỏ nhất',
    key: 'smallest_unit',
    align: 'center',
  },
  {
    title: 'Quy cách',
    dataIndex: 'convention',
    align: 'center',
  },
  {
    title: 'Đơn vị tính',
    key: 'unit',
    align: 'center',
  },

  {
    key: 'require_quantity',
    title: 'Số lượng yêu cầu',
    dataIndex: 'require_quantity',
    align: 'center',
  },
  {
    key: 'inventory_quantity',
    title: 'Số lượng bàn giao',
    dataIndex: 'inventory_quantity',
    align: 'center',
  },
  {
    key: 'import_details',
    title: 'Nhập kho thực tế',
    dataIndex: 'import_details',
    align: 'center',
  },
  {
    key: 'CBM',
    title: 'CBM',
    dataIndex: 'cbm',
    align: 'center',
  },
  {
    key: 'area',
    title: 'S mặt phẳng',
    dataIndex: 'area',
    align: 'center',
  },
  {
    key: 'warehouse_id',
    title: 'Kho',
    dataIndex: '',
    align: 'center',
  },

  {
    key: 'print_barcode',
    title: '',
    dataIndex: 'print_barcode',
    align: 'center',
  },
]

export default columns
