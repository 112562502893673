import React, { useEffect, useRef, useState } from 'react'
import { _addLog } from 'utils'
import { Row, Col, Form, Input, Button, notification, Table, Popconfirm, Switch, Space } from 'antd'
import { useHotkeys } from 'react-hotkeys-hook'
import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons'
import { ROUTES } from 'consts'
import { useDispatch } from 'react-redux'
import moment from 'moment'

// apis
import { findBucket, updateExportOrders } from 'apis/export-order'
import { useHistory, useLocation } from 'react-router-dom'
import { getBaskets } from 'apis/basket'

export default function ProductExportScan() {
  const history = useHistory()
  const { order_id, type, order_status, processing, filters, default_basket, order_code, buckets } =
    history.location.state || {}

  const [scanItems, setScanItems] = useState([])
  const [isHiddenInputScan, setIsHiddenInputScan] = useState(false)
  const [form] = Form.useForm()
  const formRef = useRef(null)
  const dispatch = useDispatch()
  const location = useLocation()

  const getBasketFromCode = async (code) => {
    try {
      const res = await getBaskets({ code })
      if (res.data.success) return res.data.data && res.data.data.length === 1 && res.data.data[0]
      return null
    } catch (err) {
      _addLog(JSON.stringify(err))
      console.log(err)
      return null
    }
  }
  const _updateBasketsToOrder = async () => {
    const body = {
      basket_ids: scanItems.map((item) => item.basket_id),
      export_status: 'WAITING',
      processing: 'is_exported',
    }
    dispatch({ type: 'LOADING', data: true })
    const res = await updateExportOrders(order_id, body)
    dispatch({ type: 'LOADING', data: false })

    return res || {}
  }

  const _handleScanBaskets = async () => {
    try {
      if (scanItems.length === 0) {
        notification.error({ message: 'Vui lòng quét ít nhất 1 thùng đựng' })
        return
      }
      const res = await _updateBasketsToOrder()
      if (res.status === 200) {
        if (res.data.success) {
          history.push({ pathname: ROUTES.PRODUCT_EXPORT, state: { filters } })
          notification.success({ message: 'Hoàn tất lấy thùng đựng thành công' })
        }
      }
    } catch (error) {
      dispatch({ type: 'LOADING', data: false })
      _addLog(JSON.stringify(error))
    }
  }
  const _handleScanBuckets = async () => {
    try {
      const res = await _updateBasketsToOrder()
      dispatch({ type: 'LOADING', data: true })
      const bucketsList = await findBucket({ order_id })
      dispatch({ type: 'LOADING', data: false })
      if (res.status === 200) {
        history.push({
          pathname: ROUTES.PRODUCT_EXPORT_SCAN_BUCKET,
          state: {
            type: 'bucket-two',
            record: location.state?.record,
            order_code,
            order_id,
            order_bucket: bucketsList.data?.data?.bucket_map || {},
            default_basket: scanItems,
            order_status,
            processing,
            buckets,
            filters,
          },
        })
      }
    } catch (error) {
      dispatch({ type: 'LOADING', data: false })
      console.log(error)
    }
  }

  const onFinish = async (value) => {
    form.resetFields()
    formRef.current.focus()
    try {
      dispatch({ type: 'LOADING', data: true })
      const basket = await getBasketFromCode(value.barcode)
      dispatch({ type: 'LOADING', data: false })

      if (!basket)
        notification.error({ message: `Không tìm thấy thùng đựng ${value.barcode}`, duration: 4 })
      else {
        if (basket.order_waiting)
          notification.warning({
            message: `Thùng đựng ${value.barcode} đang được sử dụng, vui lòng quét chọn thùng đựng khác`,
            duration: 4,
          })
        else {
          const isHaveBasket = scanItems.find((e) => e.basket_id === basket.basket_id)
          if (isHaveBasket)
            notification.warning({
              message: `Bạn đã quét thùng đựng ${value.barcode} này!`,
              duration: 4,
            })
          else {
            let scanItemsNew = [...scanItems]
            scanItemsNew.unshift(basket)
            setScanItems(scanItemsNew)
            notification.success({ message: `Quét thùng đựng ${value.barcode} thành công` })
          }
        }
      }
    } catch (err) {
      _addLog(JSON.stringify(err))
      dispatch({ type: 'LOADING', data: false })
      notification.error({ message: 'Quét mã thất bại' })
      console.log(err)
    }
  }

  const focusInputScan = () => {
    setIsHiddenInputScan(!isHiddenInputScan)
    form.resetFields()
    formRef.current.focus()
  }

  useHotkeys('f2', () => {
    focusInputScan()
  })

  useHotkeys('f1', () => {
    if (scanItems.length) _handleScanBaskets()
  })

  useHotkeys('space', () => {
    if (scanItems.length) _handleScanBuckets()
  })

  useEffect(() => {
    if (default_basket) setScanItems(default_basket)
    formRef.current.focus()
  }, [])

  useEffect(() => {
    if (!history.location.state) history.push(ROUTES.PRODUCT_EXPORT)
  }, [])

  return (
    <div>
      <Row className="page-title" justify="space-between" style={{ marginBottom: '15px' }}>
        <h3
          onClick={() => history.push({ pathname: ROUTES.PRODUCT_EXPORT, state: { filters } })}
          style={{ cursor: 'pointer' }}
        >
          <ArrowLeftOutlined /> Quét {type == 'basket' ? 'thùng đựng' : 'mã ô kệ'} đơn hàng{' '}
          <b>#{order_code}</b>
        </h3>
        <div style={{ display: 'flex' }}>
          <div>Nhập mã thủ công (F2)</div>
          <Switch
            checked={isHiddenInputScan}
            style={{ marginLeft: 5 }}
            onChange={(e) => {
              setIsHiddenInputScan(e)
              form.resetFields()
              formRef.current.focus()
            }}
          />
        </div>
      </Row>
      <div>
        <Row>
          <Col span={8}>
            <Form
              onFinish={onFinish}
              form={form}
              layout="vertical"
              style={{ opacity: !isHiddenInputScan && 0 }}
            >
              <Form.Item name="barcode" label="Nhập mã thùng đựng và enter">
                <Input
                  onBlur={() => {
                    if (formRef && formRef.current) formRef.current.focus()
                  }}
                  ref={formRef}
                  autoFocus
                  onKeyDown={(e) => {
                    if (e.key === 'F1') _handleScanBaskets()
                    if (e.key === 'F2') focusInputScan()
                  }}
                  onChange={(e) => {
                    if (e.target.value === ' ') _handleScanBuckets()
                  }}
                  placeholder="Nhập mã thùng đựng và enter"
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>

        {/* <Row justify="center">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="barcode-read"
            role="img"
            width="300"
            style={{ color: '#f5f5f5' }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
            class="svg-inline--fa fa-barcode-read fa-w-20"
          >
            <path
              fill="currentColor"
              d="M184 128h-48c-4.4 0-8 3.6-8 8v240c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V136c0-4.4-3.6-8-8-8zm-40 320H64v-80c0-8.8-7.2-16-16-16H16c-8.8 0-16 7.2-16 16v128c0 8.8 7.2 16 16 16h128c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm104-320h-16c-4.4 0-8 3.6-8 8v240c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V136c0-4.4-3.6-8-8-8zM16 160h32c8.8 0 16-7.2 16-16V64h80c8.8 0 16-7.2 16-16V16c0-8.8-7.2-16-16-16H16C7.2 0 0 7.2 0 16v128c0 8.8 7.2 16 16 16zm392-32h-48c-4.4 0-8 3.6-8 8v240c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V136c0-4.4-3.6-8-8-8zm-96 0h-16c-4.4 0-8 3.6-8 8v240c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V136c0-4.4-3.6-8-8-8zM624 0H496c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h80v80c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V16c0-8.8-7.2-16-16-16zm0 352h-32c-8.8 0-16 7.2-16 16v80h-80c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h128c8.8 0 16-7.2 16-16V368c0-8.8-7.2-16-16-16zm-112 24V136c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v240c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8z"
              class=""
            ></path>
          </svg>
        </Row> */}
        <b style={{ fontSize: 18 }}>Danh sách thùng đựng</b>
        <Table
          style={{ marginBottom: 20 }}
          columns={[
            {
              title: 'STT',
              align: 'center',
              width: 50,
              render(data, record, index) {
                return index + 1
              },
            },
            {
              title: 'Mã thùng đựng',
              dataIndex: 'code',
            },
            {
              title: 'Tên thùng đựng',
              dataIndex: 'name',
            },
            {
              title: 'Thời gian cập nhật',
              dataIndex: 'last_update',
              render: (data) => data && moment(data).format('DD-MM-YYYY HH:mm'),
            },
            {
              title: 'Hành động',
              render: (text, record) => (
                <Popconfirm
                  placement="topRight"
                  title="Bạn có muốn xóa thùng đựng này không?"
                  onConfirm={() =>
                    setScanItems(scanItems.filter((e) => e.basket_id !== record.basket_id))
                  }
                  okText="Đồng ý"
                  cancelText="Từ chối"
                >
                  <DeleteOutlined style={{ color: 'red', fontSize: 20 }} />
                </Popconfirm>
              ),
            },
          ]}
          size="small"
          dataSource={scanItems}
          pagination={false}
        />
        <Row justify="end">
          <Col>
            <Space>
              <Button
                type="primary"
                size="large"
                disabled={scanItems.length === 0}
                style={{ marginLeft: 20 }}
                onClick={_handleScanBaskets}
              >
                Hoàn tất lấy thùng đựng (F1)
              </Button>
              <Button
                type="primary"
                size="large"
                disabled={scanItems.length === 0}
                style={{ marginLeft: 20 }}
                onClick={_handleScanBuckets}
              >
                Tiếp tục quét xuất hàng (Space)
              </Button>
            </Space>
          </Col>
        </Row>
      </div>
    </div>
  )
}
