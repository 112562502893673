const columns = [
  {
    key: 'stt',
    title: 'STT',
    dataIndex: 'stt',
    align: 'center',
    width: 50,
  },
  {
    title: 'Tên chi phí',
    dataIndex: 'fee_name',
  },
  {
    key: 'fee',
    title: 'Chi phí',
    dataIndex: 'fee',
  },
  {
    key: 'unit',
    title: 'Đơn vị',
    dataIndex: 'unit',
    align: 'center'
  },
]
export default columns
