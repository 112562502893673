const columns = [
  {
    key: 'is_received',
    dataIndex: 'is_received',
  },
  {
    key: 'is_exported',
    dataIndex: 'is_exported',
  },
  {
    key: 'is_packed',
    dataIndex: 'is_packed',
  },
  {
    key: 'is_transported',
    dataIndex: 'is_transported',
  },
  // {
  //   key: 'is_shipping',
  //   dataIndex: 'is_shipping',
  // },
]
export default columns
