const columnsBasket = [
  {
    key: 'stt',
    title: 'STT',
    align: 'center',
    width: 50,
  },
  {
    key: 'name_bucket',
    title: 'Tên thùng đựng',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: 'Mã thùng đựng',
    dataIndex: 'code',
    align: 'center',
  },
  {
    key: 'name',
    title: 'Tên kho',
    dataIndex: 'name',
  },
  {
    key: 'order_waiting',
    title: 'Trạng thái',
    dataIndex: 'order_waiting',
    align: 'center',
  },
  {
    title: 'Hóa đơn đang chờ',
    key: 'waiting',
    align: 'center',
  },
  {
    key: 'create_date',
    title: 'Ngày tạo',
    dataIndex: 'create_date',
    align: 'center',
  },
  {
    key: 'update',
    title: 'Tùy chọn',
    align: 'center',
  },
]
export default columnsBasket
