import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { ACTION } from 'consts'
import { clearBrowserCache } from 'utils'

//antd
import { notification } from 'antd'

//icons
import { InfoCircleOutlined } from '@ant-design/icons'

//components
import Views from './views'
import Loading from 'components/loading/Loading'

//apis
import { getDistrict, getProvince, getWard } from 'apis/address'
import { refresh } from 'apis/auth'

export default function App() {
  const dispatch = useDispatch()
  const KEY_NETWORK = 'notify error'
  const token = localStorage.getItem('accessToken')
  const reToken = localStorage.getItem('refreshToken')

  const openNotification = () =>
    notification.open({
      icon: <InfoCircleOutlined style={{ color: 'red' }} />,
      message: 'Không có kết nối, vui lòng kiểm tra và thử lại',
      description: <a onClick={() => window.location.reload()}>Tải lại</a>,
      duration: 0,
      closeIcon: <div></div>,
      key: KEY_NETWORK,
    })

  const _checkNetwork = () => {
    if (navigator.onLine) notification.close(KEY_NETWORK)
    else openNotification()

    window.addEventListener('offline', () => openNotification())
    window.addEventListener('online', () => window.location.reload())
  }

  const _receiveFromElectron = () => {
    window.api.receive('fromMain', (data) => {
      if (!data.isConnected)
        notification.error({ message: 'Kết nối máy in thất bại, vui lòng kiểm tra lại wifi' })
    })
  }

  const _getProvinces = async () => {
    try {
      const res = await getProvince()
      if (res.status === 200) dispatch({ type: 'SET_PROVINCES', data: res.data.data })
    } catch (error) {
      console.log(error)
    }
  }

  const _getDistricts = async () => {
    try {
      const res = await getDistrict()
      if (res.status === 200)
        if (res.status === 200) dispatch({ type: 'SET_DISTRICTS', data: res.data.data })
    } catch (error) {
      console.log(error)
    }
  }

  const _getWards = async () => {
    try {
      const res = await getWard()
      if (res.status === 200)
        if (res.status === 200) dispatch({ type: 'SET_WARDS', data: res.data.data })
    } catch (error) {
      console.log(error)
    }
  }

  const _refreshToken = async () => {
    try {
      if (reToken) {
        const res = await refresh(reToken)
        if (res.status === 200) {
          if (res.data.success) {
            dispatch({
              type: ACTION.LOGIN,
              data: {
                accessToken: res.data.data.accessToken,
                refreshToken: res.data.data.refreshToken,
              },
            })
          }
        }
      } else {
        if (token) dispatch({ type: ACTION.LOGIN, data: { accessToken: token } })
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getProvinces()
    _getDistricts()
    _getWards()
    clearBrowserCache()
    _checkNetwork()
    _refreshToken()

    // _receiveFromElectron()
  }, [])

  return (
    <>
      <Loading />
      <Views />
    </>
  )
}
