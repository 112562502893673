import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import styles from './export-product.module.scss'
import { PAGE_SIZE_OPTIONS, PERMISSIONS, ROUTES } from 'consts'
import { saveFiltersToLocalStorage, _addLog } from 'utils'
import { useHistory } from 'react-router-dom'

//components
import SuffixIconCustom from 'components/suffixIconCustom'
import FilterDate from 'components/filter-date'
import Permission from 'components/permission'
import ModalConfirmDelete from 'components/modal-confirm-delete'
import QrCodeProduct from 'components/qr-code-product'
import { getWarehouses } from 'apis/warehouse'
import { exportListImportByFilter } from 'apis/report'
// antd
import { Col, Row, Space, Input, Select, Button, Table, Collapse, notification } from 'antd'

//icons
import { DeleteOutlined, ReloadOutlined } from '@ant-design/icons'

// apis
import { deleteImportOrders, getOrders } from 'apis/order'
import { getAllCustomer } from 'apis/customer'
import { findBucket } from 'apis/export-order'

export default function ExportProduct() {
  const [exportOrdersList, setExportOrdersList] = useState([])
  const KEY_FILTER = 'productImportListFilters'
  const filterList =
    localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))
  const history = useHistory()
  const [customers, setCustomers] = useState([])
  const [valueSearch, setValueSearch] = useState(filterList ? filterList.search || '' : '')
  const [bucketsList, setBucketsList] = useState({})
  const [warehouses, setWarehouses] = useState([])
  const [loadingImportProcessing, setLoadingImportProcessing] = useState(false)

  const [filters, setFilters] = useState(
    filterList
      ? { ...filterList }
      : {
          page: 1,
          page_size: 10,
          is_imported: true,
          import_status: 'WAITING',
        }
  )
  const [countOrder, setCountOrder] = useState(0)
  const [loading, setLoading] = useState(false)
  const typingTimeoutRef = useRef(null)

  const indicators = [
    {
      title: 'Chờ nhập hàng',
      bgColor: '#FFCE62',
      order_status: 'WAITING',
    },
    {
      title: 'Đang nhập hàng',
      bgColor: '#80E9EF',
      order_status: 'PROCESSING',
    },
    {
      title: 'Đã nhập hàng',
      bgColor: '#79CF4F',
      order_status: 'COMPLETE',
    },
    {
      title: 'Có sự cố',
      bgColor: '#D63F40',
      order_status: 'REPORT',
    },
  ]

  const _getCustomers = async () => {
    try {
      const res = await getAllCustomer()
      if (res.status === 200) setCustomers(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))

      console.log(error)
    }
  }

  const _deleteImport = async (id, note = '') => {
    try {
      setLoading(true)
      const res = await deleteImportOrders({ order_id: [id], note: note })
      setLoading(false)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Xóa đơn hàng thành công' })
          _getExportOrders()
        } else notification.error({ message: res.data.message || 'Xóa đơn hàng không thành công' })
      } else notification.error({ message: res.data.message || 'Xóa đơn hàng không thành công' })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const _getWarehouses = async () => {
    try {
      const res = await getWarehouses()
      if (res.status === 200) setWarehouses(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  const onDownloadImportProcessing = async () => {
    try {
      setLoadingImportProcessing(true)
      const response = await exportListImportByFilter(filters)
      setLoadingImportProcessing(false)

      if (response && response.status == 200) {
        window.open(response.data.data)
      } else {
        notification.error({ message: 'Có lỗi xảy ra, vui lòng thử lại' })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _getExportOrders = async () => {
    try {
      setLoading(true)
      const res = await getOrders(filters)
      if (res.status === 200) {
        setCountOrder(res.data.count)
        setExportOrdersList(res.data.data)
      }
      setLoading(false)
    } catch (err) {
      _addLog(JSON.stringify(err))
      console.log(err)
      setLoading(false)
    }
  }

  const _search = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value
      if (value) filters.search = value
      else delete filters.search
      setFilters({ ...filters, page: 1 })
    }, 650)
  }

  //  hàm getBucketProduct được gọi khi xem chi tiết đơn hàng, lưu lại các vị trí và các sản phẩm trong order
  const _getBucketProduct = async (order_ids) => {
    try {
      const newID = order_ids[order_ids.length - 1]
      if (newID && !bucketsList[newID]) {
        const res = await findBucket({ order_id: newID })
        console.log(res)
        if (res.data.success) {
          let tmp = { ...bucketsList, [newID]: res.data.data }
          setBucketsList(tmp)
        }
      }
    } catch (err) {
      _addLog(JSON.stringify(err))
      console.log(err)
    }
  }

  const columns = [
    {
      key: 1,
      render(data, record) {
        return (
          <Row>
            <Col>
              {record.code && <Row>#{record.code}</Row>}
              <Row>Số phiếu: #{record.customer_order_code}</Row>
              {record.shipping_info && (
                <div>
                  <div>Mã vận đơn: {record?.shipping_info?.shipping_code}</div>
                  <div>ĐVVC: {record?.shipping_info?.shipping_company_name}</div>
                  {record.shipping_info.car_number && (
                    <div style={{ fontSize: 16.5, fontWeight: 700 }}>
                      Biển số xe: {record.shipping_info.car_number}
                    </div>
                  )}
                </div>
              )}
            </Col>
          </Row>
        )
      },
    },
    {
      key: 2,
      render(data, record) {
        return (
          <Row>
            <Col>
              <Row>{record.warehouse_info && record.warehouse_info.name}</Row>
              <Row>Doanh nghiệp: {record.customer_info && record.customer_info.fullname}</Row>
              {record.import_status != 'WAITING' && (
                <Row>
                  Nhập vào vị trí:{' '}
                  {record.import_employee_info && record.import_employee_info.fullname}
                </Row>
              )}
              <Space>
                <Permission permissions={[PERMISSIONS.them_chi_phi_vao_phieu_nhap]}>
                  <Button
                    onClick={() =>
                      history.push(
                        `${ROUTES.ADD_FEE_TO_ORDER}?order_id=${record.order_id}&type=import`
                      )
                    }
                    type="primary"
                  >
                    Thêm chi phí
                  </Button>
                </Permission>

                <Permission permissions={[PERMISSIONS.xoa_don_hang_nhap]}>
                  <ModalConfirmDelete
                    record={record}
                    onDelete={(note) => _deleteImport(record.order_id, note)}
                  >
                    <Button type="primary" icon={<DeleteOutlined />} danger />
                  </ModalConfirmDelete>
                </Permission>
              </Space>
            </Col>
          </Row>
        )
      },
    },
    {
      key: 2,
      width: 120,
      render(data, record) {
        return <QrCodeProduct value={record.code} />
      },
    },
  ]

  const _filterByOption = (attribute = '', value = '') => {
    filters.page = 1

    if (value) filters[attribute] = value
    else delete filters[attribute]

    setFilters({ ...filters })
  }

  useEffect(() => {
    _getCustomers()
    _getWarehouses()
  }, [])

  useEffect(() => {
    _getCustomers()
    _getExportOrders()
    saveFiltersToLocalStorage(filters, KEY_FILTER)
  }, [filters])

  return (
    <div className={styles['importProduct']}>
      <Row className="page-title" justify="space-between" style={{ marginBottom: 10 }}>
        <h3>Danh sách phiếu nhập</h3>

        <Space>
          <Button
            disabled={loadingImportProcessing}
            loading={loadingImportProcessing}
            onClick={(e) => {
              onDownloadImportProcessing()
            }}
            type="primary"
            color={'red'}
            icon={<ReloadOutlined />}
          >
            Xuất Excel theo bộ lọc
          </Button>
          <Button onClick={_getExportOrders} type="primary" icon={<ReloadOutlined />}>
            Làm mới
          </Button>
        </Space>
      </Row>

      <div className={styles['importProduct-content']}>
        <Row style={{ marginBottom: 20 }} gutter={20}>
          <Col span={6}>
            <Input.Search
              allowClear
              enterButton
              value={valueSearch}
              placeholder="Tìm kiếm theo mã đơn hàng"
              onChange={_search}
            />
          </Col>
          <Col span={7}>
            <Select
              allowClear
              style={{ width: '100%' }}
              placeholder="Lọc theo doanh nghiệp"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              suffixIcon={<SuffixIconCustom />}
              onChange={(value) => _filterByOption('customer_id', value)}
              value={filters['customer_id'] || undefined}
            >
              {customers.map((e) => (
                <Select.Option value={e.customer_id}>{e.fullname}</Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={5}>
            <Select
              value={filters['warehouse_id'] || undefined}
              onChange={(value) => _filterByOption('warehouse_id', value)}
              showSearch
              allowClear
              placeholder="Lọc theo kho"
              style={{ width: '100%' }}
              dropdownClassName="dropdown-select-custom"
              suffixIcon={<SuffixIconCustom />}
            >
              {warehouses.map((warehouse, index) => (
                <Select.Option key={index} value={warehouse.warehouse_id}>
                  {warehouse.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={5}>
            <Select
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
              }
              style={{ width: '100%' }}
              placeholder="Lọc theo trạng thái"
              suffixIcon={<SuffixIconCustom />}
              onChange={(value) => _filterByOption('import_status', value)}
              value={filters['import_status'] || undefined}
            >
              <Select.Option value="WAITING">Chờ nhập hàng</Select.Option>
              <Select.Option value="PROCESSING">Đang nhập hàng</Select.Option>
              <Select.Option value="COMPLETE">Đã nhập hàng</Select.Option>
              <Select.Option value="REPORT">Có sự cố</Select.Option>
            </Select>
          </Col>
          <Col span={5}>
            <FilterDate paramsFilter={filters} setParamsFilter={setFilters} />
          </Col>
        </Row>
        <Row justify="space-between">
          <Col>
            <div style={{ display: 'flex', gap: '10px', marginLeft: '10px', alignItems: 'center' }}>
              {indicators.map((indicator, index) => {
                return (
                  <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <div
                      style={{
                        width: '15px',
                        height: '15px',
                        backgroundColor: indicator.bgColor,
                        borderRadius: '50%',
                      }}
                    ></div>
                    <div>{indicator.title}</div>
                  </div>
                )
              })}
            </div>
          </Col>
        </Row>
      </div>
      <Table
        rowKey="order_id"
        size="small"
        showHeader={false}
        columns={columns}
        loading={loading}
        scroll={{ y: '70vh' }}
        rowClassName={(record) => styles[record.import_status]}
        dataSource={exportOrdersList}
        pagination={{
          current: filters.page,
          pageSize: filters.page_size,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          showQuickJumper: true,
          onChange: (page, pageSize) => {
            setFilters({ ...filters, page: page, page_size: pageSize })
          },
          total: countOrder,
        }}
      />
    </div>
  )
}
