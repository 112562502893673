import React, { useEffect, useState, useRef } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import {
  formatCash,
  _addLog,
  saveFiltersToLocalStorage,
  handleGetDataExportExcel,
  getFileName,
} from 'utils'
import { ACTION, PAGE_SIZE_OPTIONS, PERMISSIONS, ROUTES } from 'consts'
import styles from './auto-import.module.scss'
import { useHotkeys } from 'react-hotkeys-hook'
import ImportFile from 'components/import-csv'
// antd
import {
  Col,
  Row,
  Button,
  Input,
  Select,
  Badge,
  Table,
  Typography,
  Modal,
  notification,
  Space,
  Popconfirm,
  Upload,
  Popover,
} from 'antd'
import { DeleteOutlined, InboxOutlined, LoadingOutlined, ReloadOutlined } from '@ant-design/icons'

//components
import SuffixIconCustom from 'components/suffixIconCustom'
import Export from 'components/export-csv'
import ModalFees from 'components/modal-fees'
import FilterDate from 'components/filter-date'
import ModalConfirmDelete from 'components/modal-confirm-delete'
import Permission from 'components/permission'
import BackToTop from 'components/back-to-top'

//apis
import { getAllCustomer } from 'apis/customer'
import { autoCheck, autoImportLayout, fixImportLayout } from 'apis/order'
import { importFileOrder, autoExportOrder } from 'apis/export-order'
import { getWarehouses } from 'apis/warehouse'

export default function View() {
  const [customerId, setCustomerId] = useState()
  const [customers, setCustomers] = useState([])
  const [warehouse, setWarehouses] = useState([])
  const [warehousedId, setWarehouseId] = useState()
  const [nameOrder, setNameOrder] = useState('')
  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useState(false)

  const _getCustomers = async () => {
    try {
      const res = await getAllCustomer({ get_all: true })
      if (res.status === 200) setCustomers(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const onSubmitAuto = async (data) => {
    if (data) {
      if (!warehousedId) {
        notification.error({ message: 'Vui lòng chọn kho' })
        return
      }

      if (!customerId) {
        notification.error({ message: 'Vui lòng chọn khách hàng' })
        return
      }

      data.append('warehouse_id', warehousedId)
      data.append('customer_id', customerId)

      setLoading(true)
      var response = await importFileOrder(data)
      setLoading(false)
      if (response && response.status == 200 && response.data) {
        for (var i = 0; i < response.data.data.length; i++) {
          await autoExportOrder({
            customer_order_code: response.data.data[i]['Số phiếu(*)'],
          })
          notification.success({
            message: `Xuất tự động cho phiếu ${response.data.data[i]['Số phiếu(*)']}  thành công`,
          })
        }
      }
    }
  }

  const columnsProduct = [
    {
      title: 'Barcode',
      align: 'center',
      width: 50,
      fixed: 'left',
      render: (value, record) => {
        return <div>{record.barcode}</div>
      },
    },
    {
      title: 'Mã ô kệ',
      key: 'name',
      fixed: 'left',
      width: 250,
      render: (value, record) => {
        return <div>{record.bucket}</div>
      },
    },
  ]

  const _getWarehouses = async () => {
    try {
      const res = await getWarehouses({ get_all: true })
      if (res.status === 200) setWarehouses(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getCustomers()
    _getWarehouses()
  }, [])

  return (
    <div>
      <div>
        <BackToTop />
        <Row className="page-title" justify="space-between" style={{ marginBottom: '25px' }}>
          <h3>Tạo và xuất tự động</h3>
        </Row>
        <div style={{ width: 300 }}>
          <Select
            //filterOption={filterOptionSelect}
            optionFilterProp="children"
            showSearch
            size="large"
            value={customerId}
            onChange={(e) => {
              setCustomerId(e)
            }}
            style={{ width: 300 }}
            placeholder="Chọn doanh nghiệp"
          >
            {customers.map((customer, index) => (
              <Select.Option value={customer.customer_id || ''} key={index}>
                {customer.fullname || ''}
              </Select.Option>
            ))}
          </Select>

          <Select
            value={warehousedId}
            onChange={(value) => {
              setWarehouseId(value)
            }}
            showSearch
            allowClear
            placeholder="Lọc theo kho"
            style={{ width: '100%', marginTop: 20 }}
            dropdownClassName="dropdown-select-custom"
            suffixIcon={<SuffixIconCustom />}
          >
            {warehouse.map((warehouse, index) => (
              <Select.Option key={index} value={warehouse.warehouse_id}>
                {warehouse.name}
              </Select.Option>
            ))}
          </Select>

          <ImportFile
            disable_noti={true}
            loading={loading}
            style={{ marginTop: 20 }}
            upload={onSubmitAuto}
            action_name={'Xuất sản phẩm bằng file excel'}
            fileTemplated="https://s3.ap-northeast-1.wasabisys.com/saigonlogistics/2023/11/29/9f0cc0b2-3b4a-425e-b57a-55b29cc78e3b_1701234077618_saigonlogistics.xlsx"
          />
          <div style={{ color: 'red', fontWeight: 'bold', marginTop: 10 }}>
            {'Phần mềm chỉ đọc được Sheet 1'}
          </div>
          <div>
            {'Sau khi bấm xác nhận sẽ tự động tạo phiếu - xuất hàng & hoàn tất giai đoạn xuất'}
          </div>
        </div>
      </div>
    </div>
  )
}
