import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import Barcode from 'react-barcode'
import { Button } from 'antd'
import { Col, Row, Table } from 'antd'
import { SIZE_TABLE } from 'consts'
import styles from './productchecking.module.scss'
import { formatCash } from 'utils'
import QR from 'qrcode-base64'

const PrintTemplate = ({ record }) => {
  if (!record) return null

  var imgData = QR.drawImg(record.customer_order_code || '', {
    typeNumber: 4,
    errorCorrectLevel: 'M',
    size: 500,
  })

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      align: 'center',
      width: 50,
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Mã hàng',
      align: 'center',
      dataIndex: 'barcode',
      size: '10px',
    },
    {
      title: 'Tên hàng',
      align: 'center',
      dataIndex: 'name',
    },
    {
      title: 'SL Dự kiến',
      align: 'center',
      dataIndex: 'require_quantity',
    },
    {
      title: 'SL Thực',
      align: 'center',
      dataIndex: 'inventory_quantity',
    },
    {
      title: 'Ghi chú',
      align: 'center',
      dataIndex: 'note',
    },
  ]
  const columns1 = [
    {
      title: (
        <span style={{ fontWeight: 400, fontSize: 14 }}>Đại Diện Bên Giao (Ký, Ghi rõ họ tên)</span>
      ),
      align: 'center',
      render: () => <div style={{ height: 100 }}></div>,
    },
    {
      title: (
        <span style={{ fontWeight: 400, fontSize: 14 }}>
          Đại Diện Vận Chuyển (Ký, Ghi rõ họ tên)
        </span>
      ),
      align: 'center',
      render: () =>
        record.shipping_info &&
        record.shipping_info.shipping_employee_signature && (
          <img
            width={140}
            height={100}
            /*  style={{ border: '1px solid red' }} */
            src={record.shipping_info.shipping_employee_signature}
            alt=""
          />
        ),
    },
    {
      title: <span style={{ fontWeight: 400, fontSize: 14 }}>Đại Diện Giám Sát (nếu có)</span>,
      align: 'center',
    },
    {
      title: (
        <span style={{ fontWeight: 400, fontSize: 14 }}>Đại Diện Bên Nhận (Ký, Ghi rõ họ tên)</span>
      ),
      align: 'center',
    },
  ]
  const products = record.products || []
  const shippingInfo = record.shippingInfo || []

  const totalWeight = products.reduce((acc, item) => {
    return acc + item.weight * item.inventory_quantity
  }, 0)

  const totalCbm = products.reduce((acc, item) => {
    return acc + item.cbm * item.inventory_quantity
  }, 0)
  return (
    <div className="print-container" style={{ border: '1px solid #fff', margin: 50 }}>
      <header className={styles['page-break']}></header>
      <Row gutter={10} style={{ marginBottom: 20 }}>
        <Col span={9}>
          <Row gutter={10}>
            <Col span={6}>
              <div style={{ height: 50, marginBottom: 10 }}></div>
              <img
                src="https://s3.ap-northeast-1.wasabisys.com/admin-order/2022/02/08/7184fd15-326d-422c-a15c-e1110e30ed77/images.png"
                alt=""
                style={{ backgroundColor: 'red', width: '100%', height: 55 }}
              />
            </Col>
            <Col span={18}>
              <img
                src="https://admin-order.s3.ap-northeast-1.wasabisys.com/2022/02/07/860da969-7cfa-4c1d-88af-6d99ab5099f5/sgl%20avatar.jpg"
                alt=""
                style={{ backgroundColor: 'red', width: '100%', height: 35 }}
              />
              <img src={imgData} alt="" style={{ width: 40, height: 40 }} />

              {/* <Barcode
                value={record.code}
                width={1}
                height={20}
                text={' '}
                textPosition="top"
                marginLeft={1}
              /> */}
              <br />
              <b style={{ marginBottom: 0 }}>Số phiếu:</b>
              <br />
              <b style={{ marginBottom: 0 }}>{record.code}</b>
            </Col>
            <div>
              <img
                src="https://s3.ap-northeast-1.wasabisys.com/admin-order/2022/02/08/1ea0b950-4a80-45fa-b335-8b44642a5a0a/photo_2022-02-08_08-57-05.jpg"
                alt="arrow"
                style={{ width: '100%', height: 30 }}
              />
            </div>
          </Row>
        </Col>
        <Col span={15}>
          <div style={{ marginLeft: 10 }}>
            <b>CÔNG TY TNHH KHO VẬN SÀI GÒN</b>
            <p style={{ marginBottom: 0 }}>Email: info@saigonlogistics.com.vn</p>
            <p>Hotline: 0936049446</p>
          </div>
          <div>
            <h2 style={{ marginBottom: 0, fontWeight: 'bold' }}>BIÊN BẢN NHẬN HÀNG</h2>
            <span>Ngày bàn giao: .....giờ.....phút.....ngày.....tháng.....năm 2022 </span>
          </div>
        </Col>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Col span={12} className={styles['text-bill']}>
          <div>
            <i>Bên giao:</i>&emsp;
            <b>{record.customer_info && record.customer_info?.username}</b>
          </div>
          <div>
            <b>MSKH:&emsp;{record.customer_info && record.customer_info?.code}</b>
          </div>
          <div>
            <i>Người giao: {shippingInfo.shipping_employee?.employee_name || ''}</i>
          </div>
          <div>
            <i>SĐT: {shippingInfo.shipping_employee?.employee_phone || ''}</i>
          </div>
        </Col>
        <Col
          span={12}
          className={styles['text-bill']}
          style={{ borderLeft: '1px solid black', paddingLeft: 20 }}
        >
          <div>
            <i>Bên nhận:</i>&nbsp;
            <b>{record.warehouse_info && record.warehouse_info?.name}</b>
          </div>
          <div>
            <i>Mã kho nhận:</i>&emsp;
            <b>{record.warehouse_info && record.warehouse_info?.hotname}</b>
          </div>
          <div></div>
          <i>Người nhận: {shippingInfo.to_name || ''}</i>
          <div>
            <i>SĐT: {shippingInfo.to_phone || ''}</i>
          </div>
        </Col>
      </Row>
      <Row>
        <p style={{ margin: 0, width: '100%' }}>Số xe: ............................</p>
        <p style={{ margin: 0, width: '100%' }}>Tài xế: ............................</p>
        <p style={{ margin: 0, width: '100%' }}>
          Tổng khối lượng (CBM): <b>{formatCash(totalCbm) || '0'}</b>&nbsp; Tổng trọng lượng:{' '}
          <b>{formatCash(totalWeight) || '0'}</b>
        </p>
        <div>
          <i style={{ fontWeight: 'bold' }}>Giao từ địa chỉ: </i>&nbsp;
          {record.customer_info &&
            `${record.customer_info?.address}, 
                    ${record.customer_info?.ward}, 
                    ${record.customer_info?.district}, 
                    ${record.customer_info?.province}`}
          &ensp;<i style={{ fontWeight: 'bold' }}>đến địa chỉ: </i>&nbsp;
          {record.warehouse_info &&
            `${record.warehouse_info?.address}, 
                    ${record.warehouse_info?.ward}, 
                    ${record.warehouse_info?.district}, 
                    ${record.warehouse_info?.province}.`}
        </div>
      </Row>
      <span style={{ margin: 0 }}>PO nhập: {record.customer_order_code}</span>
      <img src={imgData} alt="" style={{ width: 40, height: 40, marginLeft: 4 }} />
      {/* <Barcode
        value={record.customer_order_code}
        width={1}
        height={20}
        text={' '}
        marginBottom={0.5}
        marginTop={0.1}
        textPosition="top"
      /> */}
      <Table
        bordered
        className={styles['table-product']}
        style={{ width: '100%' }}
        size={SIZE_TABLE}
        columns={columns}
        dataSource={products}
        pagination={false}
      />
      <Row>
        <Col span={16}>
          <h3 align="center">
            <b>TỔNG SỐ</b>
          </h3>
        </Col>
        <Col span={8}>
          <h3 align="center">
            <b>{products.reduce((pre, curr) => pre + curr?.inventory_quantity, 0)}&nbsp; kiện</b>
          </h3>
        </Col>
      </Row>
      <b style={{ margin: '20px 0' }}>
        Lưu ý: Hai bên đã cùng đồng kiểm và xác nhận đủ số lượng và tình trạng hàng hóa như danh
        sách bên trên.
      </b>
      <Row>
        <Table
          bordered
          className={styles['table-product']}
          style={{ width: '100%' }}
          size={SIZE_TABLE}
          columns={columns1}
          dataSource={[{}]}
          pagination={false}
        />
      </Row>
    </div>
  )
}

export class ComponentPrintBill extends React.Component {
  render() {
    const { details } = this.props
    let printingPages = []
    for (const record of details) {
      const tempTemplate = <PrintTemplate record={record} />
      printingPages.push(tempTemplate)
    }
    return <div style={{ border: '1px solid #fff' }}>{printingPages}</div>
  }
}

function ButtonPrintBill({ orders, text }) {
  const componentRef1 = useRef()
  const _handlePrint = async () => {
    _print()
  }
  const _print = useReactToPrint({
    content: () => componentRef1.current,
  })
  return (
    <>
      <div style={{ display: 'none' }}>
        <ComponentPrintBill details={orders} ref={componentRef1} />
      </div>
      <Button type="primary" onClick={_handlePrint} className={styles['btn-print']}>
        {text}
      </Button>
    </>
  )
}

export default ButtonPrintBill
