import { useEffect, useState } from 'react'
import { EditOutlined, LeftOutlined } from '@ant-design/icons'
import { useHistory, useLocation } from 'react-router-dom'
import { ACTION, IMAGE_DEFAULT, PERMISSIONS } from 'consts'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { formatCash, _addLog } from 'utils'

//components
import ZoomImage from 'components/zoom-image'
import ModalUpdateShipping from 'components/modal-update-shipping'
import Permission from 'components/permission'

//antd
import { Row, Col, Button, Space, Table, BackTop, Tag, Tooltip } from 'antd'

//apis
import { getOrders } from 'apis/order'
import { getExportOrders } from 'apis/export-order'
import { getShippingCompany } from 'apis/shipping-company'

export default function OrderDetail() {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const queries = new URLSearchParams(location.search)
  const status = queries.get('status')
  const typeOrder = queries.get('type')
  const orderId = queries.get('order_id')

  const [orderDetail, setOrderDetail] = useState({})
  const [shippings, setShippings] = useState([])

  const _getShippings = async () => {
    try {
      const res = await getShippingCompany({ get_all: true })
      if (res.status === 200) setShippings(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _getOrder = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      let res
      if (typeOrder === 'export') res = await getExportOrders({ order_id: orderId })
      else res = await getOrders({ order_id: orderId })
      dispatch({ type: ACTION.LOADING, data: false })
      if (res.status === 200) {
        const order = res.data.data && res.data.data.length && res.data.data[0]
        if (order) setOrderDetail(order)
        else history.goBack()
      }
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const renderReason = () => {
    if (status) {
      switch (status) {
        case 'is_received': {
          if (orderDetail.receive_status === 'REPORT') return orderDetail.receive_reason
          break
        }

        case 'is_checked': {
          if (orderDetail.check_status === 'REPORT') return orderDetail.check_reason
          break
        }

        case 'is_imported': {
          if (orderDetail.import_status === 'REPORT') return orderDetail.import_reason
          break
        }

        default:
          return ''
      }
    }
  }

  const renderTitle = () => {
    if (status) {
      switch (status) {
        case 'is_received': {
          if (orderDetail.receive_status === 'WAITING') return 'chờ tiếp nhận'
          if (orderDetail.receive_status === 'PROCESSING') return 'đang tiếp tiếp nhận'
          if (orderDetail.receive_status === 'COMPLETE') return 'đã tiếp nhận'
          if (orderDetail.receive_status === 'REPORT') return 'tiếp nhận có sự cố'
          break
        }

        case 'is_exported': {
          if (orderDetail.export_status === 'WAITING') return 'chờ xuất vị trí'
          if (orderDetail.export_status === 'PROCESSING') return 'đang xuất vị trí'
          if (orderDetail.export_status === 'COMPLETE') return 'đã xuất vị trí'
          if (orderDetail.export_status === 'REPORT') return 'xuất vị trí có sự cố'
          break
        }

        case 'is_packed': {
          if (orderDetail.pack_status === 'WAITING') return 'chờ đóng gói'
          if (orderDetail.pack_status === 'PROCESSING') return 'đang đóng gói'
          if (orderDetail.pack_status === 'COMPLETE') return 'đã đóng gói'
          if (orderDetail.pack_status === 'REPORT') return 'đóng gói có sự cố'
          break
        }

        case 'is_transported': {
          if (orderDetail.transport_status === 'WAITING') return 'chờ bàn giao vận chuyển'
          if (orderDetail.transport_status === 'PROCESSING') return 'đang bàn giao vận chuyển'
          if (orderDetail.transport_status === 'COMPLETE') return 'đã bàn giao vận chuyển'
          if (orderDetail.transport_status === 'REPORT') return 'bàn giao vận chuyển có sự cố'
          break
        }

        case 'is_checked': {
          if (orderDetail.check_status === 'WAITING') return 'chờ kiểm hàng'
          if (orderDetail.check_status === 'PROCESSING') return 'đang kiểm hàng'
          if (orderDetail.check_status === 'COMPLETE') return 'đã kiểm hàng'
          if (orderDetail.check_status === 'REPORT') return 'kiểm hàng có sự cố'
          break
        }

        case 'is_imported': {
          if (orderDetail.import_status === 'WAITING') return 'chờ nhập vào vị trí'
          if (orderDetail.import_status === 'PROCESSING') return 'đang nhập vào vị trí'
          if (orderDetail.import_status === 'COMPLETE') return 'đã nhập vào vị trí'
          if (orderDetail.import_status === 'REPORT') return 'nhập vào vị trí có sự cố'
          break
        }

        default:
          return ''
      }
    }
  }

  const [columns, setColumns] = useState([
    {
      width: 50,
      title: 'STT',
      render: (text, record, index) => index + 1,
    },
    {
      width: 80,
      title: 'Hình ảnh',
      render: (text, record) => (
        <ZoomImage
          image={(record.images && record.images[0]) || record.image || '' || IMAGE_DEFAULT}
        >
          <img
            alt=""
            style={{ width: 70, height: 70 }}
            src={(record.images && record.images[0]) || record.image || '' || IMAGE_DEFAULT}
          />
        </ZoomImage>
      ),
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
    },
    {
      title: 'Mã hàng',
      dataIndex: 'barcode',
    },
    {
      title: 'Ngày sản xuất',
      render: (text, record) => record.mfg && moment(record.mfg).format('DD/MM/YYYY'),
      width: 100,
    },
    {
      title: 'Hạn sử dụng',
      render: (text, record) => record.exp && moment(record.exp).format('DD/MM/YYYY'),
      width: 100,
    },
    {
      width: 140,
      title: 'Quy cách',
      dataIndex: 'convention',
    },
  ])

  useEffect(() => {
    if (Object.keys(orderDetail).length) {
      let objColumns
      if (typeOrder === 'export') {
        objColumns = [
          {
            width: 70,
            title: 'SL yêu cầu',
            render: (text, record) => formatCash(record.require_quantity || 0),
          },
          {
            width: 70,
            title: 'SL xuất vị trí',
            render: (text, record) => formatCash(record.export_quantity || 0),
          },
          {
            width: 70,
            title: 'SL đóng gói',
            render: (text, record) => {
              let quantityPackaged = 0
              orderDetail.packages.map((pkg) =>
                pkg.products.map((p) => {
                  if (p.product_id === record.product_id)
                    quantityPackaged += p.quantity_in_package || 0
                })
              )
              return formatCash(quantityPackaged || 0)
            },
          },
          {
            width: 540,
            title: 'Danh sách kiện hàng',
            render: (text, record) => {
              const packagesOfProduct = orderDetail.packages.filter((pkg) => {
                const productFind = pkg.products.find((e) => e.product_id === record.product_id)
                return productFind ? true : false
              })

              return (
                <Space wrap={true}>
                  {packagesOfProduct.map((pkg) => {
                    const arrName = pkg.package_code.split('-')
                    return (
                      <Tag>
                        {arrName[arrName.length - 1] || ''}
                        {pkg.create_date &&
                          ` - ${moment(pkg.create_date || '').format('DD/MM/YYYY HH:mm')}`}
                      </Tag>
                    )
                  })}
                </Space>
              )
            },
          },
        ]
      } else {
        objColumns = [
          {
            width: 70,
            title: 'SL yêu cầu',
            render: (text, record) => formatCash(record.require_quantity || 0),
          },
          {
            width: 70,
            title: 'SL kiểm đếm',
            render: (text, record) => formatCash(record.inventory_quantity || 0),
          },
          {
            width: 70,
            title: 'SL nhập kho',
            render: (text, record) =>
              record.import_details
                ? formatCash(
                    record.import_details.reduce((acc, curr) => acc + curr.import_quantity, 0)
                  )
                : 0,
          },
        ]
      }
      setColumns([...columns, ...objColumns])
    }
  }, [orderDetail])

  useEffect(() => {
    _getShippings()

    if (orderId) _getOrder()
    else history.goBack()
  }, [])

  return (
    <div>
      <BackTop />
      <Row justify="space-between">
        <Col style={{ fontSize: 20 }}>
          <a onClick={() => history.goBack()} style={{ color: '#000' }}>
            <LeftOutlined /> Chi tiết đơn hàng {renderTitle()}{' '}
            <b style={{ fontSize: 24 }}>#{orderDetail.code}</b>
          </a>
        </Col>
        <Col>
          <Space>
            {orderDetail.shipping_info?.image_invoice && (
              <Button type="primary" target="blank" href={orderDetail.shipping_info?.image_invoice}>
                Xem chi tiết đơn hàng
              </Button>
            )}
          </Space>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col span={24}>
          <div style={{ backgroundColor: '#fff', padding: '1rem' }}>
            <div>
              <div style={{ fontSize: 16 }}>
                <div style={{ marginBottom: 10 }}>
                  {renderReason() ? (
                    <b style={{ color: 'red' }}>Lý do sự cố: {renderReason()}</b>
                  ) : (
                    ''
                  )}
                </div>

                <Row
                  style={{
                    display: orderDetail?.shipping_info?.shipping_company_name ? '' : 'none',
                  }}
                >
                  Đơn vị vận chuyển:{' '}
                  <b style={{ marginLeft: 4 }}>
                    {orderDetail?.shipping_info?.shipping_company_name}
                  </b>{' '}
                  {typeOrder === 'export' && (
                    <Permission permissions={[PERMISSIONS.cap_nhat_don_vi_van_chuyen]}>
                      <Tooltip title="Cập nhật đơn vị vận chuyển">
                        <ModalUpdateShipping
                          reload={_getOrder}
                          shippings={shippings}
                          record={orderDetail}
                          type="shipping_info"
                        >
                          <EditOutlined
                            style={{
                              marginLeft: 8,
                              fontSize: 18,
                              cursor: 'pointer',
                              color: 'green',
                            }}
                          />
                        </ModalUpdateShipping>
                      </Tooltip>
                    </Permission>
                  )}
                </Row>
                <div
                  style={{
                    display: orderDetail?.shipping_info?.car_number ? '' : 'none',
                  }}
                >
                  Biển số xe: <b>{orderDetail?.shipping_info?.car_number}</b>
                </div>
                <Row
                  style={{
                    display: orderDetail?.shipping_info?.shipping_company_name ? '' : 'none',
                  }}
                >
                  Mã vận đơn:{' '}
                  <b style={{ marginLeft: 4 }}>{orderDetail?.shipping_info?.shipping_code}</b>{' '}
                  {typeOrder === 'export' && (
                    <Permission permissions={[PERMISSIONS.cap_nhat_don_vi_van_chuyen]}>
                      <Tooltip title="Cập nhật mã vận đơn">
                        <ModalUpdateShipping
                          reload={_getOrder}
                          shippings={shippings}
                          record={orderDetail}
                          type="shipping_code"
                        >
                          <EditOutlined
                            style={{
                              marginLeft: 8,
                              fontSize: 18,
                              cursor: 'pointer',
                              color: 'green',
                            }}
                          />
                        </ModalUpdateShipping>
                      </Tooltip>
                    </Permission>
                  )}
                </Row>
                <div style={{ display: typeOrder === 'export' ? '' : 'none' }}>
                  Tiền thu hộ: <b>{formatCash(orderDetail?.shipping_info?.cod || 0)}</b>
                </div>
                <div>
                  Số phiếu:{' '}
                  <b style={{ fontSize: 21, lineHeight: 1 }}>{orderDetail?.customer_order_code}</b>
                </div>
                <div>
                  Mã phiếu: <b>{orderDetail?.code}</b>
                </div>
                {orderDetail.from_platform && (
                  <div>
                    Nguồn: <b>{orderDetail.from_platform}</b>
                  </div>
                )}
                {status === 'is_checked' && typeOrder === 'import' && (
                  <div>
                    {orderDetail.check_date && (
                      <div>
                        Thời gian kiểm hàng:{' '}
                        <b>{moment(orderDetail.check_date).format('DD-MM-YYYY HH:mm')}</b>
                      </div>
                    )}
                    {orderDetail.check_employee_info && (
                      <div>
                        Người kiểm hàng: <b>{orderDetail.check_employee_info.fullname}</b>
                      </div>
                    )}
                  </div>
                )}
                {status === 'is_imported' && typeOrder === 'import' && (
                  <div>
                    {orderDetail.import_date && (
                      <div>
                        Thời gian nhập hàng:{' '}
                        <b>{moment(orderDetail.import_date).format('DD-MM-YYYY HH:mm')}</b>
                      </div>
                    )}
                    {orderDetail.import_employee_info && (
                      <div>
                        Người nhập hàng: <b>{orderDetail.import_employee_info.fullname}</b>
                      </div>
                    )}
                  </div>
                )}
                <br />
                <div style={{ fontSize: 18, fontWeight: 600 }}>Thông tin người gửi</div>
                <div style={{ fontSize: 15, fontWeight: 700 }}>
                  Đơn vị: {orderDetail?.customer_info?.fullname} -{' '}
                  {orderDetail?.customer_info?.code}
                </div>
                <div style={{ fontSize: 15 }}>
                  Người nhận: {orderDetail?.shipping_info?.to_name}
                </div>
                <div style={{ fontSize: 15 }}>
                  Địa chỉ:{' '}
                  {`${orderDetail?.shipping_info?.to_address || ''}${
                    orderDetail?.shipping_info?.to_ward
                      ? ', ' + orderDetail?.shipping_info.to_ward
                      : ''
                  }${
                    orderDetail?.shipping_info?.to_district
                      ? ', ' + orderDetail?.shipping_info?.to_district
                      : ''
                  }${
                    orderDetail?.shipping_info?.to_province
                      ? ', ' + orderDetail?.shipping_info.to_province
                      : ''
                  }`}
                </div>
                <div style={{ fontSize: 15 }}>
                  Điện thoại: {orderDetail?.shipping_info?.to_phone}
                </div>
                <div style={{ color: 'red', fontSize: 15 }}>
                  Ghi chú đơn hàng: {orderDetail?.note || ''}
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div>
        <div style={{ fontSize: 18, fontWeight: 700 }}>
          Danh sách sản phẩm ({orderDetail?.products?.length})
        </div>
        <Table
          scroll={{ y: '60vh', x: 'max-content' }}
          dataSource={orderDetail.products || []}
          size="small"
          columns={columns}
          pagination={false}
          summary={() => (
            <Table.Summary fixed="bottom">
              <Table.Summary.Row></Table.Summary.Row>
            </Table.Summary>
          )}
          sticky
        />
      </div>
    </div>
  )
}
