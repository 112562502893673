import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import moment from 'moment'

//styles
import styles from './productimportdetail.module.scss'

//consts
import { ACTION, IMAGE_DEFAULT, PERMISSIONS, PRINTER, ROUTES, SIZE_TABLE } from 'consts'

//components
import columns from './columns'
import printToThermalPrinter from 'components/thermal-printer/print'
import { barcode } from 'components/thermal-printer/templates'
import { formatCash, _addLog } from 'utils'
import ModalReason from 'components/modal-reason-order'
import ModalOptionsPrint from 'components/modal-opions-print'
import ZoomImage from 'components/zoom-image'
import Permission from 'components/permission'
import QrCodeProduct from 'components/qr-code-product'

//ants
import {
  Table,
  Button,
  Row,
  Col,
  notification,
  InputNumber,
  Popconfirm,
  Input,
  Space,
  Tooltip,
} from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'

//apis
import { getOrders, updateOrder } from 'apis/order'
import { getPrint } from 'apis/print'
import { getWarehouses } from 'apis/warehouse'
import { enumImport } from 'apis/enum'

export default function ProductCheckDetail() {
  const typingTimeoutRef = useRef(null)

  const [reasons, setReasons] = useState([])
  const history = useHistory()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [orderData, setOrderData] = useState({})
  const [productsData, setProductsData] = useState([])
  const [productsDataSearch, setProductsDataSearch] = useState([])
  const [valueSearch, setValueSearch] = useState('')
  const [isScannerVisible, setIsScannerVisible] = useState(false)
  const [dataWarehouses, setDataWarehouses] = useState([])

  const _getOrderImport = async (id) => {
    setLoading(true)
    try {
      if (id) {
        const res = await getOrders({ order_id: id })
        if (res.status === 200) {
          if (res.data.success) {
            if (res.data.data && res.data.data.length) {
              const data = res.data.data[0]
              const products = data.products
              setOrderData(data)
              setProductsData(products)
              setProductsDataSearch(products)
            }
          } else notification.error({ message: 'Không tìm thấy đơn hàng' })
        } else notification.error({ message: 'Không tìm thấy đơn hàng' })
      }
      setLoading(false)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
      setLoading(false)
    }
  }

  const _getWarehouses = async () => {
    try {
      const res = await getWarehouses()
      if (res.status === 200) {
        setDataWarehouses(res.data.data)
      }
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _handleBack = async (params) => {
    dispatch({ type: ACTION.LOADING, data: true })

    // nếu trạng thái đơn hàng đang kiểm tra thì chuyển về đang chờ
    let orderUpdate = { ...params }
    if (orderUpdate.check_status === 'PROCESSING') {
      orderUpdate.check_status = 'WAITING'
    }

    try {
      const res = await updateOrder(orderUpdate.order_id, orderUpdate)
      if (res.status === 200) {
        localStorage.removeItem('idOrderCheck')
        history.push({ pathname: ROUTES.PRODUCT_CHECKING })
      } else {
        notification.error({
          message: 'Trở về thất bại',
        })
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
      notification.error({ message: 'Trở về thất bại' })
      _addLog(JSON.stringify(error))
    }
  }

  const _handleCheckOrder = async (id) => {
    dispatch({ type: ACTION.LOADING, data: true })
    try {
      const data = { ...orderData }
      data.check_status = 'COMPLETE'
      data.processing = 'is_checked'
      const res = await updateOrder(id, data)
      if (res.data.success) {
        notification.success({
          message: 'Kiểm hàng thành công',
        })
        dispatch({ type: ACTION.LOADING, data: false })
        history.goBack()
      } else {
        notification.error({
          message: res.data.message || 'Kiểm hàng thất bại',
        })
        dispatch({ type: ACTION.LOADING, data: false })
      }
    } catch (error) {
      notification.error({
        message: 'Kiểm hàng thất bại',
      })
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
      _addLog(`Check order: ${JSON.stringify(error)}`)
    }
  }

  const _printBarcode = async (record) => {
    const params = {
      warehouse_id: orderData.warehouse_id,
      name: PRINTER.NHAP_KHO,
    }
    const dataPrinter = await _getPrint(params)
    printToThermalPrinter(
      barcode,
      {
        barcode: record.barcode,
        title: 'SP: ' + record.slug_name?.replace(/-{1,}/g, ' '),
      },
      dataPrinter && dataPrinter.ip
    )
  }

  const _onReport = async (reason) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const body = { check_status: 'REPORT', processing: 'is_checked', import_reason: reason }
      const res = await updateOrder(orderData.order_id, body)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Báo cáo đơn hàng thành công' })
          history.goBack()
        } else
          notification.error({
            message: res.data.message || 'Báo cáo đơn hàng thất bại, vui lòng thử lại',
          })
      } else
        notification.error({
          message: res.data.message || 'Báo cáo đơn hàng thất bại, vui lòng thử lại',
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      _addLog(`Report order: ${JSON.stringify(error)}`)
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const _handleQuantityInventory = async (params, record) => {
    setLoading(true)
    try {
      const inventoryQuantity = parseInt(
        params.target.value && params.target.value.replace(/\$\s?|(,*)/g, '')
      )
      const data = { ...orderData }

      data.products.forEach((product) => {
        if (product.barcode === record.barcode) {
          product.inventory_quantity = inventoryQuantity
        }
      })

      const res = await updateOrder(data.order_id, data)
      if (res.data.success) {
        _loadDataOrder()
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (error) {
      _addLog(`Set quantity inventory: ${JSON.stringify(error)}`)
      setLoading(false)
    }
  }

  const _search = (e) => {
    const value = e.target.value
    setValueSearch(value)

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }

    typingTimeoutRef.current = setTimeout(() => {
      if (value) {
        const product = productsData.find((p) => p.barcode == value)
        if (product) setProductsDataSearch([product])
        else setProductsDataSearch([])
      } else setProductsDataSearch(productsData)
    }, 550)
  }

  const _getEnumOrder = async () => {
    try {
      const res = await enumImport()
      if (res.status === 200) if (res.data.success) setReasons(res.data.data.report_reason)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _loadDataOrder = () => {
    let idOrderCheck = null
    if (history.location.state) {
      idOrderCheck = history.location.state
      localStorage.setItem('idOrderCheck', idOrderCheck)
    } else {
      idOrderCheck = localStorage.getItem('idOrderCheck')
    }
    _getOrderImport(idOrderCheck)
  }

  useEffect(() => {
    _getEnumOrder()
    _getWarehouses()
    _loadDataOrder()
  }, [])

  const _getPrint = async (params) => {
    try {
      const res = await getPrint(params)
      if (res.status === 200) {
        return res.data.data[0]
      }
      return ''
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
      return ''
    }
  }
  return (
    <div className={styles['product-check-detail']}>
      <Row className="page-title" justify="space-between" style={{ marginBottom: '25px' }}>
        <Col style={{ display: 'flex', gap: '15px' }}>
          <h3
            style={{ cursor: 'pointer' }}
            onClick={() => {
              // if (orderData.check_status === 'COMPLETE' || orderData.check_status === 'REPORT') {
              //   localStorage.removeItem('idOrderCheck')
              history.push({ pathname: ROUTES.PRODUCT_CHECKING })
              // } else _handleBack(orderData)
            }}
          >
            <ArrowLeftOutlined style={{ marginRight: '10px' }} />
            Chi tiết đơn hàng <b>#{orderData.code ? orderData.code : orderData.order_id}</b> (Kiểm
            hàng)
          </h3>
        </Col>
        {orderData.check_status === 'COMPLETE' ? (
          <h4 style={{ color: '#0017E3' }}>Đã kiểm hàng</h4>
        ) : (
          <Space>
            <ModalReason reasons={reasons} onReport={_onReport} />
            <Popconfirm
              title="Bạn có muốn hoàn tất kiểm hàng không?"
              onConfirm={() => _handleCheckOrder(orderData.order_id)}
              okText="Đồng ý"
              cancelText="Từ chối"
            >
              <Button type="primary">Hoàn tất kiểm hàng</Button>
            </Popconfirm>
          </Space>
        )}
      </Row>
      <Row style={{ marginBottom: 30 }} gutter={20}>
        <Col span={8}>
          <Input.Group compact className={styles['employee-management-input-item']}>
            <Input.Search
              autoFocus
              allowClear
              enterButton
              value={valueSearch}
              onChange={_search}
              placeholder="Tìm kiếm theo mã sản phẩm"
              onBlur={() => setIsScannerVisible(false)}
              onFocus={() => setIsScannerVisible(true)}
            />
          </Input.Group>
        </Col>
        <Col span={5}>
          <h4 style={{ lineHeight: '32px', marginBottom: 0 }}>
            {isScannerVisible ? 'Bắt đầu quét mã sản phẩm' : 'Máy quét đang tắt'}
          </h4>
        </Col>
      </Row>
      <Table
        loading={loading}
        style={{ marginBottom: '30px' }}
        dataSource={productsDataSearch}
        columns={columns.map((column) => {
          if (column.key === 'STT') {
            return {
              ...column,
              render: (text, record, index) => index + 1,
            }
          }
          if (column.key === 'image') {
            return {
              ...column,
              render: (text) =>
                text && (
                  <ZoomImage image={text[0] || IMAGE_DEFAULT}>
                    <img src={text[0] || IMAGE_DEFAULT} alt="" style={{ width: 50, height: 50 }} />
                  </ZoomImage>
                ),
            }
          }
          if (column.key === 'inventory_quantity') {
            return {
              ...column,
              render: (text, record) => (
                <InputNumber
                  //defaultValue={text && formatCash(record.inventory_quantity ?? 0)}
                  onBlur={(e) => _handleQuantityInventory(e, record)}
                  value={record && formatCash(record.inventory_quantity ?? 0)}
                  disabled={orderData.check_status === 'COMPLETE' ? true : false}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  min={0}
                />
              ),
            }
          }
          if (column.key === 'import_details') {
            return {
              ...column,
              render: (text, record) =>
                record.import_details
                  ? formatCash(
                      record?.import_details.reduce((acc, curr) => acc + curr?.quantity, 0)
                    )
                  : 0,
            }
          }
          if (column.key === 'require_quantity') {
            return {
              ...column,
              render: (text) => text && formatCash(text),
            }
          }
          if (column.key === 'CBM') {
            return {
              ...column,
              render: (text) => text && formatCash(text),
            }
          }
          if (column.key === 'unit')
            return {
              ...column,
              render: (text, record) => record.unit_info && record.unit_info.name,
            }
          if (column.key === 'smallest_unit')
            return {
              ...column,
              render: (text, record) => formatCash(record.smallest_unit || 0),
            }
          if (column.key === 'area') {
            return {
              ...column,
              render: (text) => text && formatCash(text),
            }
          }
          if (column.key === 'warehouse_id') {
            return {
              ...column,
              render: () => {
                const warehouse = dataWarehouses.find(
                  (e) => e.warehouse_id === orderData.warehouse_id
                )
                return warehouse && warehouse.name
              },
            }
          }
          if (column.key === 'exp') {
            return {
              ...column,
              render: (text) => text && moment(text).format('DD/MM/YYYY'),
            }
          }
          if (column.key === 'code/barcode') {
            return {
              ...column,
              render: (text, record) => (record?.barcode ? record?.barcode : record?.code),
            }
          }
          if (column.key === 'print_barcode') {
            return {
              ...column,
              render: (text, record) => {
                return (
                  <div>
                    <ModalOptionsPrint
                      printWithInternet={() => _printBarcode(record)}
                      records={[{ ...record, code: record.barcode || record.code }]}
                    >
                      <Button type="primary">In barcode sản phẩm</Button>
                    </ModalOptionsPrint>
                    <div style={{ marginTop: 5 }}>
                      <QrCodeProduct value={record.barcode} />
                    </div>
                  </div>
                )
              },
            }
          }
          return column
        })}
        pagination={false}
        size={SIZE_TABLE}
      />
    </div>
  )
}
