import React, { useEffect, useRef, useState } from 'react'
import { SIZE_TABLE, PAGE_SIZE_OPTIONS } from 'consts'
import moment from 'moment'
import { compare, _addLog, saveFiltersToLocalStorage } from 'utils'

//components
import SuffixIconCustom from 'components/suffixIconCustom'
import FilterDate from 'components/filter-date'

//antd
import { Input, Select, Table, Row, Col, Button } from 'antd'
import { ReloadOutlined } from '@ant-design/icons'

// apis
import { getActionFile } from 'apis/action'

export default function ManagementImportExport() {
  const KEY_FILTER = 'historyImportExportFilters'
  const filters = localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))

  const [paramsFilter, setParamsFilter] = useState(
    filters ? { ...filters } : { this_week: true, page: 1, page_size: 20 }
  )
  const [fileActionList, setFileActionList] = useState([])

  const [tableLoading, setTableLoading] = useState(false)
  const [searchKey, setSearchKey] = useState(
    filters
      ? (filters.file_name && 'file_name') || (filters.action_name && 'action_name') || 'file_name'
      : 'file_name'
  )
  const [totalRecord, setTotalRecord] = useState(0)
  const [valueSearch, setValueSearch] = useState(
    filters ? filters.file_name || filters.action_name || '' : ''
  )
  const [valueType, setValueType] = useState()

  const typingTimeoutRef = useRef()

  const _search = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      const value = e.target.value

      //khi search hoac filter thi reset page ve 1
      paramsFilter.page = 1
      delete paramsFilter.file_name
      delete paramsFilter.action_name
      if (value) paramsFilter[searchKey] = value
      else delete paramsFilter[searchKey]

      setParamsFilter({ ...paramsFilter })
    }, 450)
  }
  const _getFilesAction = async () => {
    try {
      setTableLoading(true)
      const res = await getActionFile(paramsFilter)
      if (res.data.success) {
        setFileActionList(res.data.data)
        setTotalRecord(res.data.count)
      }
      setTableLoading(false)
    } catch (err) {
      setTableLoading(false)
      console.log(err)
      _addLog(JSON.stringify(err))
    }
  }

  const columns = [
    {
      title: 'STT',
      align: 'center',
      render(data, record, index) {
        return (paramsFilter.page - 1) * paramsFilter.page_size + index + 1
      },
      width: 50,
    },
    {
      title: 'Tên file',
      dataIndex: 'file_name',
      sorter: (a, b) => compare(a, b, 'file_name'),
    },
    {
      title: 'Tên người dùng',
      dataIndex: 'creator_info',
      render(data, record) {
        return data && data.fullname
      },
      sorter: (a, b) => {
        if (!a.creator_info) {
          return 1
        }
        if (!b.creator_info) {
          return -1
        }
        return compare(a, b, 'creator_info.fullname')
      },
    },
    {
      title: 'Thời gian thao tác',
      dataIndex: 'create_date',
      render: (data) => moment(data).format('DD/MM/YYYY HH:mm:ss'),
      sorter: (a, b) => {
        const aMoment = a.create_date ? moment(a.create_date).unix() : 0
        const bMoment = b.create_date ? moment(b.create_date).unix() : 0
        return aMoment - bMoment
      },
    },
    {
      title: 'Thao tác',
      dataIndex: 'action_name',
      sorter: (a, b) => compare(a, b, 'action_name'),
    },
    {
      title: 'Hành động',
      dataIndex: 'links',
      render: (data) => (
        <a href={data[0]} download={true} style={{ color: '#0017E3' }}>
          Tải xuống
        </a>
      ),
    },
  ]

  const _clearFilters = () => {
    setValueType()
    setValueSearch()
    setSearchKey('file_name')
    setParamsFilter({ page: 1, page_size: 10, this_week: true })
  }

  useEffect(() => {
    _getFilesAction()
    saveFiltersToLocalStorage(paramsFilter, KEY_FILTER)
  }, [paramsFilter])

  return (
    <div>
      <Row className="page-title" justify="space-between">
        <h3>Lịch sử xuất/nhập file</h3>
        <Button onClick={_getFilesAction} type="primary" icon={<ReloadOutlined />}>
          Làm mới
        </Button>
      </Row>
      <Row gutter={20} style={{ marginBottom: 25, marginTop: 20 }}>
        <Col span={8}>
          <Input.Group compact style={{ width: '100%' }}>
            <Input
              allowClear
              enterButton
              placeholder="Tìm kiếm theo"
              style={{ width: '70%' }}
              onChange={_search}
              value={valueSearch}
            />
            <Select
              style={{ width: '30%' }}
              value={searchKey}
              onChange={(e) => setSearchKey(e)}
              suffixIcon={<SuffixIconCustom />}
            >
              <Select.Option value="file_name">Tên file</Select.Option>
              <Select.Option value="action_name">Thao tác</Select.Option>
            </Select>
          </Input.Group>
        </Col>
        <Col span={6}>
          <Select
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: '100%' }}
            allowClear
            placeholder="Lọc theo thao tác"
            suffixIcon={<SuffixIconCustom />}
            onChange={(e) => {
              setValueType(e)
              setParamsFilter({ ...paramsFilter, type: e })
            }}
            value={valueType}
          >
            <Select.Option value="Import File">Nhập file</Select.Option>
            <Select.Option value="Export File">Xuất file</Select.Option>
          </Select>
        </Col>
        <Col span={6}>
          <FilterDate paramsFilter={paramsFilter} setParamsFilter={setParamsFilter} />
        </Col>
        <Col span={4} style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            onClick={() => _clearFilters()}
            type="primary"
            danger
            style={{ display: Object.keys(paramsFilter).length <= 3 && 'none' }}
          >
            Xóa bộ lọc
          </Button>
        </Col>
      </Row>
      <Table
        size={SIZE_TABLE}
        scroll={{ y: '56vh' }}
        style={{ width: '100%' }}
        columns={columns}
        dataSource={fileActionList}
        loading={tableLoading}
        pagination={{
          current: paramsFilter.page,
          pageSize: paramsFilter.page_size,
          total: totalRecord,
          showQuickJumper: true,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          onChange: (page, page_size) => setParamsFilter({ ...paramsFilter, page, page_size }),
        }}
      />
    </div>
  )
}
