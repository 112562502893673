const columnsUnit = [
  {
    key: 'number',
    title: 'STT',
    align: 'center',
    width: 50,
  },
  {
    title: 'Mã',
    dataIndex: 'code',
  },
  {
    title: 'Tên',
    dataIndex: 'name',
  },
  {
    title: 'Số lượng quy đổi',
    dataIndex: 'conversion_quantity',
  },
  {
    title: 'Kí tự đại diện',
    dataIndex: 'representation_string',
  },
  {
    key: 'create_date',
    title: 'Ngày tạo',
    dataIndex: 'create_date',
    align: 'center',
  },
  {
    key: 'action',
    title: 'Hành động',
    align: 'center',
  },
]

export default columnsUnit
