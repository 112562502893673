

const initialState = {
  type: 'usb'
}

const print = (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_TYPE_PRINT': {
      return { ...state, type: action.data }
    }

    default:
      return state
  }
}
export default print
