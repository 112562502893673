import React, { useState, useRef, useEffect, useMemo } from 'react'
import { Link, useLocation, useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import styles from './layout.module.scss'
import { ACTION, OPEN_MERCHANT, PERMISSIONS, ROUTES, TYPE_DEPLOY } from 'consts'
import { _addLog } from 'utils'

//images
import avatarDefault from 'assets/images/avatar-default.png'
import logo from 'assets/images/logo.png'
import menu from 'assets/icons/menu.png'

//components
import Permission from 'components/permission'
import SuffixIconCustom from 'components/suffixIconCustom'

// antd
import {
  Avatar,
  Badge,
  Dropdown,
  Layout as AntLayout,
  Menu,
  Divider,
  Empty,
  Modal,
  Form,
  Select,
  Button,
  Input,
  Row,
  Space,
  Col,
  notification,
  Radio,
  Spin,
} from 'antd'

import {
  LayoutOutlined,
  SettingOutlined,
  UserOutlined,
  LogoutOutlined,
  FieldTimeOutlined,
  PrinterOutlined,
  FileSearchOutlined,
  InboxOutlined,
  BarChartOutlined,
  DashboardOutlined,
  ExportOutlined,
  LockOutlined,
  DeploymentUnitOutlined,
  BellFilled,
  DeleteOutlined,
  DollarOutlined,
} from '@ant-design/icons'

//icons
import { DBExport, DBImport, Edit, Product, Role, Struct, Users } from './icon'

//apis
import { getDistrict, getProvince, getWard } from 'apis/address'
import { getRoles } from 'apis/role'
import { getWarehouses } from 'apis/warehouse'
import { updateUser, getUsers } from 'apis/user'
import { getNoties, updateBulkNotification } from 'apis/notification'

export default function Layout({ children }) {
  const routeMatch = useRouteMatch()
  const bellRef = useRef(null)
  const dispatch = useDispatch()
  const location = useLocation()
  const WIDTH_MENU_OPEN = 235
  const WIDTH_MENU_CLOSE = 60

  const typePrint = useSelector((state) => state.print.type)
  const provinces = useSelector((state) => state.address.provinces)
  const wards = useSelector((state) => state.address.wards)
  const districts = useSelector((state) => state.address.districts)
  const dataUser = useSelector((state) => state.user)

  const isCollapsed = localStorage.getItem('collapsed')
    ? JSON.parse(localStorage.getItem('collapsed'))
    : false
  const [collapsed, setCollapsed] = useState(isCollapsed)
  const [openKeys, setOpenKeys] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)

  const [dataWarehouses, setDataWarehouses] = useState([])
  const [notifications, setNotifications] = useState([])
  const [countNotification, setCountNotification] = useState(0)
  const [pageNotification, setPageNotification] = useState(1)
  const [loadingNotification, setLoadingNotification] = useState(false)

  const [roleName, setRoleName] = useState('')

  const rootSubmenuKeys = ['export', 'import', 'location-setting', 'cost-setting', 'report']

  const toggleCollapse = () => {
    localStorage.setItem('collapsed', JSON.stringify(!collapsed))
    setCollapsed(!collapsed)
  }

  const toggleModalInfo = () => {
    if (!isModalVisible) form.setFieldsValue(dataUser)
    setIsModalVisible(!isModalVisible)
  }

  const [form] = Form.useForm()

  const _getRoles = async () => {
    try {
      const res = await getRoles()
      if (res.status === 200) {
        const roleFind = res.data.data.find((item) => item.role_id === dataUser.role_id)
        if (roleFind) setRoleName(roleFind.name)
      }
    } catch (error) {
      console.log(error)
      _addLog(`get roles: ${JSON.stringify(error)}`)
    }
  }
  const _getWarehouses = async () => {
    try {
      const res = await getWarehouses()
      if (res.status === 200) {
        setDataWarehouses(res.data.data)
      }
    } catch (error) {
      _addLog(`get warehouse: ${JSON.stringify(error)}`)
      console.log(error)
    }
  }

  const _updateUser = async () => {
    await form.validateFields()
    const formData = form.getFieldsValue()
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await updateUser(dataUser.user_id, formData)
      dispatch({ type: ACTION.LOADING, data: false })
      console.log('res', res)
      if (res.status === 200) {
        if (res.data.accessToken) {
          notification.success({ message: 'Cập nhật thông tin cá nhân thành công!' })
          dispatch({ type: ACTION.LOGIN, data: { accessToken: res.data.accessToken } })
          toggleModalInfo()
        } else {
          notification.error({
            message: res.data.message || 'Cập nhật thông tin cá nhân thất bại!',
          })
        }
      } else {
        notification.error({
          message: res.data.message || 'Cập nhật thông tin cá nhân thất bại!',
        })
      }
    } catch (error) {
      console.log(error)
      _addLog(`update user: ${JSON.stringify(error)}`)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1)
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys)
    } else {
      localStorage.setItem('openKey', latestOpenKey)
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }

  const _updateNotification = async () => {
    try {
      let notificationsWatched = []

      const body = {
        notify_id: notifications.map((noti) => {
          notificationsWatched.push({ ...noti, is_view: true })
          return noti.notify_id
        }),
        is_view: true,
      }
      if (body.notify_id && body.notify_id.length !== 0) {
        const res = await updateBulkNotification(body)
        if (res.status === 200) setNotifications([...notificationsWatched])
      }
    } catch (error) {}
  }

  const _getNotifications = async () => {
    try {
      setLoadingNotification(true)
      const res = await getNoties({ page: pageNotification, page_size: 20 })
      setLoadingNotification(false)
      if (res.status === 200) {
        setNotifications([...notifications, ...res.data.data])
        setCountNotification(res.data.count)
        setPageNotification(pageNotification + 1)
      }
    } catch (error) {
      setLoadingNotification(false)
      _addLog(`update notification: ${JSON.stringify(error)}`)
    }
  }

  const ModalModifyPassword = ({ children }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [form] = Form.useForm()

    const _resetPassword = async () => {
      try {
        await form.validateFields()
        const dataForm = form.getFieldsValue()
        if (dataForm.passwordNew !== dataForm.passwordNewAgain) {
          notification.error({ message: 'Xác nhận mật khẩu không đúng' })
          return
        }

        dispatch({ type: ACTION.LOADING, data: true })

        const body = { password: dataForm.passwordNew }
        const res = await updateUser(dataUser.user_id, body)

        if (res.status === 200) {
          if (res.data.success) {
            toggle()
            notification.success({ message: 'Đổi mật khẩu thành công' })
          } else
            notification.success({
              message: res.data.message || 'Đổi mật khẩu thất bại, vui lòng thử lại',
            })
        } else
          notification.error({
            message: res.data.message || 'Đổi mật khẩu thất bại, vui lòng thử lại',
          })

        dispatch({ type: ACTION.LOADING, data: false })
      } catch (err) {
        dispatch({ type: ACTION.LOADING, data: false })
        console.log(err)
      }
    }

    return (
      <>
        <div onClick={toggle}>{children}</div>
        <Modal
          onOk={_resetPassword}
          width={550}
          okText="Xác nhận"
          cancelText="Đóng"
          visible={visible}
          onCancel={() => {
            toggle()
            form.resetFields()
          }}
          title="Đổi mật khẩu"
        >
          <Form form={form} layout="vertical">
            <Form.Item
              label="Mật khẩu mới"
              name="passwordNew"
              rules={[{ message: 'Vui lòng nhập mật khẩu mới', required: true }]}
            >
              <Input.Password onPressEnter={_resetPassword} placeholder="Nhập mật khẩu mới" />
            </Form.Item>

            <Form.Item
              label="Xác nhận khẩu mới"
              name="passwordNewAgain"
              rules={[{ message: 'Vui lòng xác nhận mật khẩu mới', required: true }]}
            >
              <Input.Password onPressEnter={_resetPassword} placeholder="Xác nhận mật khẩu mới" />
            </Form.Item>
          </Form>
        </Modal>
      </>
    )
  }

  const accountMenu = (
    <Space
      style={{
        width: '250px',
        border: '1px solid rgb(230, 218, 218)',
        padding: '10px 20px',
        borderRadius: '15px',
      }}
      direction="vertical"
    >
      <Space style={{ color: '#223B4F', fontWeight: '600' }} onClick={toggleModalInfo}>
        <UserOutlined style={{ fontSize: '1rem', color: ' #223B4F' }} />
        Tài khoản của tôi
      </Space>
      <ModalModifyPassword>
        <Space style={{ color: '#223B4F', fontWeight: '600' }}>
          <LockOutlined style={{ fontSize: '1rem', color: ' #223B4F' }} />
          Đổi mật khẩu
        </Space>
      </ModalModifyPassword>
      <Link
        to={ROUTES.LOGIN}
        onClick={() => dispatch({ type: ACTION.LOGOUT })}
        style={{ color: '#223B4F', fontWeight: '600' }}
      >
        <Space>
          <ExportOutlined rotate={180} style={{ fontSize: '1rem', color: '#223B4F' }} />
          Đăng xuất
        </Space>
      </Link>
    </Space>
  )

  const dataEmpty = (
    <div className={styles['layout-noti-empty-card']}>
      <h3>Thông báo</h3>
      <Divider style={{ margin: '0 0 13px' }} />
      <Empty />
    </div>
  )
  const notiList =
    notifications.length > 0 ? (
      <div className={styles['layout-noti-list-wrapper']}>
        {notifications.map((noti, index) => (
          <div>
            <b>{noti.title}</b>
            <div>{noti.content}</div>
            <div>{moment(noti.create_date).format('DD/MM/YYYY HH:mm')}</div>
            <Divider style={{ margin: '10px 0' }} />
            {countNotification !== notifications.length && index === notifications.length - 1 && (
              <Row justify="center" align="middle">
                {loadingNotification ? (
                  <Spin />
                ) : (
                  <a style={{ fontSize: 18 }} onClick={_getNotifications}>
                    Xem thêm
                  </a>
                )}
              </Row>
            )}
          </div>
        ))}
      </div>
    ) : (
      dataEmpty
    )

  const MENUS = [
    {
      path: ROUTES.DASHBOARD,
      title: 'Tổng quan',
      permissions: [],
      icon: <DashboardOutlined />,
      pathsChild: [],
    },
    {
      path: ROUTES.PRODUCT,
      title: 'Sản phẩm',
      permissions: [PERMISSIONS.giao_dien_san_pham],
      icon: <Product />,
      pathsChild: [ROUTES.PRODUCT_DETAIL],
    },
    // {
    //   path: ROUTES.UNIT,
    //   title: 'Quản lý quy cách',
    //   permissions: [PERMISSIONS.giao_dien_quan_ly_quy_cach_san_pham],
    //   icon: <DeploymentUnitOutlined />,
    //   pathsChild: [],
    // },
    {
      path: ROUTES.CUSTOMERS,
      title: 'Quản lý doanh nghiệp',
      permissions: [PERMISSIONS.giao_dien_quan_ly_doanh_nghiep],
      icon: <Users />,
      pathsChild: [ROUTES.CUSTOMERS_ADD],
    },
    {
      path: ROUTES.PO_DELETE_LIST,
      title: 'Quản lý phiếu xóa',
      permissions: [PERMISSIONS.giao_dien_quan_ly_phieu_xoa],
      icon: <DeleteOutlined />,
      pathsChild: [],
    },
    {
      path: ROUTES.ORDER_COLLECTION_EXPORT,
      title: 'Tổng hợp đơn hàng xuất',
      permissions: [PERMISSIONS.giao_dien_tong_hop_don_hang_xuat],
      icon: <InboxOutlined />,
      pathsChild: [ROUTES.ORDER_DETAIL_EXPORT],
    },
    {
      path: ROUTES.ORDER_COLLECTION_IMPORT,
      title: 'Tổng hợp đơn hàng nhập',
      permissions: [PERMISSIONS.giao_dien_tong_hop_don_hang_nhap],
      icon: <InboxOutlined />,
      pathsChild: [ROUTES.ORDER_DETAIL_IMPORT],
    },
    {
      path: ROUTES.MANAGEMENT_IMPORT_EXPORT,
      title: 'Lịch sử xuất/nhập file',
      permissions: [PERMISSIONS.giao_dien_quan_ly_xuat_nhap],
      icon: <FileSearchOutlined />,
      pathsChild: [],
    },
    {
      title: 'Nhập hàng',
      path: 'import',
      permissions: [
        PERMISSIONS.giao_dien_kiem_hang,
        PERMISSIONS.giao_dien_nhap_hang_vao_vi_tri,
        PERMISSIONS.giao_dien_danh_sach_nhap_hang,
      ],
      icon: (
        <span className="anticon">
          <DBImport />
        </span>
      ),
      menuItems: [
        {
          path: ROUTES.PRODUCT_IMPORT_LIST,
          title: 'Danh sách phiếu nhập',
          permissions: [PERMISSIONS.giao_dien_danh_sach_nhap_hang],
          pathsChild: [ROUTES.ADD_FEE_TO_ORDER],
        },
        {
          path: ROUTES.PRODUCT_CHECKING,
          title: 'Kiểm hàng',
          permissions: [PERMISSIONS.giao_dien_kiem_hang],
          pathsChild: [ROUTES.PRODUCT_CHECK_DETAIL],
        },
        {
          path: ROUTES.POSITION_INPUT,
          title: 'Nhập hàng vào vị trí',
          permissions: [PERMISSIONS.giao_dien_nhap_hang_vao_vi_tri],
          pathsChild: [ROUTES.PRODUCT_IMPORT_DETAIL, ROUTES.PRODUCT_IMPORT_SCAN],
        },
        {
          path: ROUTES.AUTO_IMPORT,
          title: 'Kiểm & nhập tự động',
          permissions: [PERMISSIONS.giao_dien_nhap_hang_vao_vi_tri],
          pathsChild: [ROUTES.PRODUCT_IMPORT_DETAIL, ROUTES.PRODUCT_IMPORT_SCAN],
        },
      ],
    },
    {
      title: 'Xuất hàng',
      path: 'export',
      permissions: [
        PERMISSIONS.giao_dien_danh_sach_xuat_hang,
        PERMISSIONS.giao_dien_dong_goi,
        PERMISSIONS.giao_dien_ban_giao_van_chuyen,
        PERMISSIONS.giao_dien_da_ban_giao_van_chuyen,
      ],
      icon: (
        <span className="anticon">
          <DBExport />
        </span>
      ),
      menuItems: [
        {
          path: ROUTES.PRODUCT_EXPORT_LIST,
          title: 'Danh sách phiếu xuất',
          permissions: [PERMISSIONS.giao_dien_danh_sach_xuat_hang],
          pathsChild: [ROUTES.ADD_FEE_TO_ORDER],
        },
        {
          path: ROUTES.PRODUCT_EXPORT,
          title: 'Xuất hàng',
          permissions: [PERMISSIONS.giao_dien_danh_sach_xuat_hang],
          pathsChild: [
            ROUTES.PRODUCT_EXPORT_SCAN,
            ROUTES.PRODUCT_EXPORT_SCAN_BUCKET,
            ROUTES.PRODUCT_EXPORT_SCAN_PRODUCT,
          ],
        },
        {
          path: ROUTES.PACKAGE,
          title: 'Đóng gói',
          permissions: [PERMISSIONS.giao_dien_dong_goi],
          pathsChild: [ROUTES.PACKAGE_SCAN],
        },
        {
          path: ROUTES.PACKAGE_E_COMMERCE,
          title: 'Đóng gói TMDT',
          permissions: [PERMISSIONS.giao_dien_dong_goi_tmdt],
          pathsChild: [ROUTES.PACKAGE_SCAN_E_COMMERCE],
        },
        {
          path: ROUTES.TRANSPORT_TRANSFER,
          title: 'Đợi bàn giao vận chuyển',
          permissions: [PERMISSIONS.giao_dien_ban_giao_van_chuyen],
          pathsChild: [],
        },
        {
          path: ROUTES.TRANSPORTED_TRANSFER,
          title: 'Đã bàn giao vận chuyển',
          permissions: [PERMISSIONS.giao_dien_da_ban_giao_van_chuyen],
          pathsChild: [],
        },
        {
          path: ROUTES.AUTO_EXPORT,
          title: 'Tạo & Xuất tự động',
          permissions: [PERMISSIONS.giao_dien_danh_sach_xuat_hang],
          pathsChild: [],
        },
      ],
    },
    {
      path: 'location-setting',
      title: 'Cài đặt vị trí',
      permissions: [
        PERMISSIONS.giao_dien_kho_hang,
        PERMISSIONS.giao_dien_o_chua_hang,
        PERMISSIONS.giao_dien_thung_dung,
      ],
      icon: <LayoutOutlined />,
      menuItems: [
        {
          path: ROUTES.WAREHOUSE,
          title: 'Kho hàng',
          permissions: [PERMISSIONS.giao_dien_kho_hang],
          pathsChild: [ROUTES.WAREHOUSE_ADD],
        },
        {
          path: ROUTES.BUCKET,
          title: 'Ô chứa hàng',
          permissions: [PERMISSIONS.giao_dien_o_chua_hang],
          pathsChild: [],
        },
        {
          path: ROUTES.SHIPPING,
          title: 'Chuyển layout',
          permissions: [PERMISSIONS.giao_dien_chuyen_layout],
          pathsChild: [],
        },
        {
          path: ROUTES.SETTING_BOX,
          title: 'Thùng đựng',
          permissions: [PERMISSIONS.giao_dien_thung_dung],
          pathsChild: [],
        },
      ],
    },
    {
      path: 'cost-setting',
      title: 'Cài đặt chi phí',
      permissions: [PERMISSIONS.giao_dien_danh_sach_phu_lieu],
      icon: <SettingOutlined />,
      menuItems: [
        {
          path: ROUTES.INGREDIENT,
          title: 'Danh sách phụ liệu',
          permissions: [PERMISSIONS.giao_dien_danh_sach_phu_lieu],
          pathsChild: [ROUTES.INGREDIENT_ADD],
        },
      ],
    },
    {
      path: 'accountant',
      title: 'Kế toán',
      permissions: [
        PERMISSIONS.giao_dien_chi_phi_kho,
        PERMISSIONS.giao_dien_chi_phi_luu_kho,
        PERMISSIONS.giao_dien_bang_ke_chi_phi,
      ],
      icon: <DollarOutlined />,
      menuItems: [
        {
          path: ROUTES.STORAGE_FEE,
          title: 'Chi phí dịch vụ vận kho',
          permissions: [PERMISSIONS.giao_dien_chi_phi_kho],
          pathsChild: [ROUTES.STORAGE_FEE_ADD],
        },
        {
          path: ROUTES.STORAGE_COST,
          title: 'Chi phí lưu kho',
          permissions: [PERMISSIONS.giao_dien_chi_phi_luu_kho],
          pathsChild: [ROUTES.STORAGE_COST_ADD],
        },
        {
          path: ROUTES.ORDER_FEE,
          title: 'Bảng kê chi phí',
          permissions: [PERMISSIONS.giao_dien_bang_ke_chi_phi],
          pathsChild: [ROUTES.ADD_STORAGE_FEE_OF_ORDER_FEE, ROUTES.ADD_STORAGE_OTHER_OF_ORDER_FEE],
        },
      ],
    },
    {
      path: 'report',
      title: 'Báo cáo tồn kho',
      permissions: [
        PERMISSIONS.giao_dien_bao_cao_ton_kho_tong_hop,
        PERMISSIONS.giao_dien_bao_cao_nhap_kho,
        PERMISSIONS.giao_dien_bao_cao_xuat_kho,
        PERMISSIONS.giao_dien_bao_cao_kiem_hang,
        PERMISSIONS.giao_dien_bao_cao_nhap_hang,
        PERMISSIONS.giao_dien_bao_cao_xuat_hang,
        PERMISSIONS.giao_dien_bao_cao_dong_goi,
        PERMISSIONS.giao_dien_bao_cao_ban_giao_van_chuyen,
      ],
      icon: <BarChartOutlined />,
      menuItems: [
        {
          path: ROUTES.REPORT_INVENTORY,
          title: 'Báo cáo tồn kho tổng hợp',
          permissions: [PERMISSIONS.giao_dien_bao_cao_ton_kho_tong_hop],
          pathsChild: [],
        },
        // {
        //   path: ROUTES.REPORT_IMPORT_WAREHOUSE,
        //   title: 'Báo cáo nhập kho',
        //   permissions: [PERMISSIONS.giao_dien_bao_cao_nhap_kho],
        //   pathsChild: [],
        // },
        // {
        //   path: ROUTES.REPORT_EXPORT_WAREHOUSE,
        //   title: 'Báo cáo xuất kho',
        //   permissions: [PERMISSIONS.giao_dien_bao_cao_xuat_kho],
        //   pathsChild: [],
        // },
        {
          path: ROUTES.REPORT_PRODUCT_CHECKING,
          title: 'Báo cáo kiểm hàng',
          permissions: [PERMISSIONS.giao_dien_bao_cao_kiem_hang],
          pathsChild: [],
        },
        {
          path: ROUTES.REPORT_IMPORT_ORDER,
          title: 'Báo cáo nhập hàng',
          permissions: [PERMISSIONS.giao_dien_bao_cao_nhap_hang],
          pathsChild: [],
        },
        {
          path: ROUTES.REPORT_EXPORT_ORDER,
          title: 'Báo cáo xuất hàng',
          permissions: [PERMISSIONS.giao_dien_bao_cao_xuat_hang],
          pathsChild: [],
        },
        {
          path: ROUTES.REPORT_PACK_ORDER,
          title: 'Báo cáo đóng gói',
          permissions: [PERMISSIONS.giao_dien_bao_cao_dong_goi],
          pathsChild: [],
        },
        {
          path: ROUTES.REPORT_TRANSPORT_ORDER,
          title: 'BC bàn giao vận chuyển',
          permissions: [PERMISSIONS.giao_dien_bao_cao_ban_giao_van_chuyen],
          pathsChild: [],
        },
      ],
    },
    {
      path: ROUTES.REPORT_IMPORT_EXPORT,
      title: 'Báo cáo nhập xuất tồn',
      permissions: [PERMISSIONS.giao_dien_bao_cao_nhap_kho, PERMISSIONS.giao_dien_bao_cao_xuat_kho],
      icon: <BarChartOutlined />,
      pathsChild: [],
    },
    {
      path: ROUTES.SHIPPING_COMPANY,
      title: 'Đơn vị vận chuyển',
      permissions: [PERMISSIONS.giao_dien_don_vi_van_chuyen],
      icon: (
        <span className="anticon">
          <Struct />
        </span>
      ),
      pathsChild: [ROUTES.SHIPPING_COMPANY_ADD],
    },
    {
      path: ROUTES.EMPLOYEES_MANAGEMENT,
      title: 'Quản lý nhân viên',
      permissions: [PERMISSIONS.giao_dien_quan_ly_nhan_vien],
      icon: <UserOutlined />,
      pathsChild: [ROUTES.CREATE_EMPLOYEE],
    },
    {
      path: ROUTES.ROLE_MANAGEMENT,
      title: 'Quản lý phân quyền',
      permissions: [PERMISSIONS.giao_dien_quan_ly_phan_quyen],
      icon: (
        <span className="anticon">
          <Role />
        </span>
      ),
      pathsChild: [],
    },
    {
      path: ROUTES.ACTIVITY_HISTORY,
      title: 'Lịch sử thao tác',
      permissions: [PERMISSIONS.giao_dien_lich_su_thao_tac],
      icon: <FieldTimeOutlined />,
      pathsChild: [],
    },
    {
      path: ROUTES.PRINTER_MANAGEMENT,
      title: 'Quản lý thiết bị in',
      permissions: [PERMISSIONS.giao_dien_quan_ly_thiet_bi_in],
      icon: <PrinterOutlined />,
      pathsChild: [],
    },
  ]

  const renderMenuItems = (menu) => (
    <Permission permissions={menu.permissions} key={menu.path}>
      {menu.menuItems ? (
        <Menu.SubMenu key={menu.path} title={menu.title} icon={menu.icon}>
          {menu.menuItems.map((e) => (
            <Permission permissions={e.permissions}>
              <Menu.Item
                key={e.path}
                style={{
                  backgroundColor:
                    (location.pathname === e.path || e.pathsChild.includes(location.pathname)) &&
                    '#82E7F4',
                }}
              >
                <Link to={e.path}>{e.title}</Link>
              </Menu.Item>
            </Permission>
          ))}
        </Menu.SubMenu>
      ) : (
        <Menu.Item
          icon={menu.icon}
          key={menu.path}
          style={{
            backgroundColor:
              (location.pathname === menu.path || menu.pathsChild.includes(location.pathname)) &&
              '#82E7F4',
          }}
        >
          <Link to={menu.path}>{menu.title}</Link>
        </Menu.Item>
      )}
    </Permission>
  )

  const handleRenderMenus = useMemo(() => MENUS.map(renderMenuItems), [])

  const saveScrollTop = () => {
    setTimeout(() => {
      const scrollTopMenu = document.querySelector('#scrollTopMenu')
      const scrollTop = localStorage.getItem('scrollTop')
      if (scrollTopMenu && scrollTop) {
        scrollTopMenu.scrollTop = scrollTop
        localStorage.removeItem('scrollTop')
      }
    }, 250)
  }

  useEffect(() => {
    _getWarehouses()
    _getNotifications()

    saveScrollTop()
  }, [])

  useEffect(() => {
    if (dataUser && Object.keys(dataUser).length) _getRoles()
  }, [dataUser])

  useEffect(() => {
    if (localStorage.getItem('openKey')) setOpenKeys([localStorage.getItem('openKey')])
  }, [])

  return (
    <AntLayout style={{ minHeight: '100vh' }}>
      <AntLayout.Sider
        collapsedWidth={WIDTH_MENU_CLOSE}
        width={WIDTH_MENU_OPEN}
        collapsed={collapsed}
        style={{ backgroundColor: '#F0FEFF', height: '100vh', position: 'fixed' }}
      >
        <div className={styles['layout-logo']} style={{ display: collapsed && 'none' }}>
          <Link to={ROUTES.DASHBOARD}>
            <img src={logo} alt="logo-sgl" />
          </Link>
        </div>
        <Menu
          id="scrollTopMenu"
          style={{ height: `calc(100vh - ${collapsed ? 0 : 100}px)`, overflowY: 'auto' }}
          className={styles['side-menu']}
          mode="inline"
          theme="dark"
          onClick={(e) => {
            const scrollTopMenu = document.querySelector('#scrollTopMenu')
            if (scrollTopMenu) localStorage.setItem('scrollTop', scrollTopMenu.scrollTop)

            if (e.keyPath && e.keyPath.length === 1) localStorage.removeItem('openKey')
          }}
          selectedKeys={routeMatch.path}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
        >
          {handleRenderMenus}
          <Menu.Item icon={<LogoutOutlined rotate={180} />}>
            <Link to={ROUTES.LOGIN} onClick={() => dispatch({ type: ACTION.LOGOUT })}>
              Đăng xuất
            </Link>
          </Menu.Item>
        </Menu>
      </AntLayout.Sider>
      <AntLayout style={{ marginLeft: collapsed ? WIDTH_MENU_CLOSE : WIDTH_MENU_OPEN }}>
        <AntLayout.Header
          style={{
            position: 'fixed',
            zIndex: 100,
            width: `calc(100% - ${collapsed ? WIDTH_MENU_CLOSE : WIDTH_MENU_OPEN}px)`,
          }}
        >
          <div className={styles['layout-header']}>
            <div className={styles['expand-icon']}>
              <img
                style={{ width: 30, marginRight: 15 }}
                src={menu}
                alt=""
                onClick={toggleCollapse}
              />
              <Button type="primary">
                <a href={OPEN_MERCHANT} target="_blank">
                  Mở website Merchant
                </a>
              </Button>
            </div>
            <Row justify="center">
              <h3 style={{ fontWeight: 600 }}>
                {process.env.REACT_APP_API_ENDPOINT_DEV === 'https://api-sgl.cpos.vn/api'
                  ? 'SAND BOX'
                  : 'LIVE'}
              </h3>
            </Row>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {/* <Row wrap={false} align="middle">
                <div style={{ marginBottom: 10, marginRight: 5 }}>Tùy chọn in ấn: </div>
                <Radio.Group
                  onChange={(e) => dispatch({ type: 'CHANGE_TYPE_PRINT', data: e.target.value })}
                  value={typePrint}
                  style={{ marginRight: 25, marginBottom: 0 }}
                >
                  <Radio value="internet">In qua mạng</Radio>
                  <Radio value="usb">In qua USB</Radio>
                </Radio.Group>
              </Row> */}
              <div style={{ marginRight: 30, marginTop: 8 }}>
                <Dropdown
                  trigger={['click']}
                  overlay={notiList}
                  overlayClassName={styles['layout-noti-list']}
                  onClick={_updateNotification}
                >
                  <Badge
                    size="small"
                    offset={[-3, 3]}
                    count={notifications.filter((noti) => !noti.is_view).length}
                  >
                    <BellFilled
                      style={{ color: 'rgb(253, 170, 62)', fontSize: 25, cursor: 'pointer' }}
                    />
                  </Badge>
                </Dropdown>
              </div>

              <Dropdown
                overlayClassName={styles['layout-user']}
                overlay={accountMenu}
                trigger={['click']}
              >
                <a>
                  <Avatar src={avatarDefault} alt="" size={40} style={{ marginRight: '10px' }} />
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                      textTransform: 'capitalize',
                      color: 'black',
                      fontWeight: '600',
                    }}
                  >
                    {dataUser.fullname}
                  </span>
                </a>
              </Dropdown>
            </div>
          </div>
        </AntLayout.Header>
        <div className={styles['layout-container']}>{children}</div>

        <Modal
          title={<h2 style={{ textAlign: 'center' }}>Thông tin cá nhân</h2>}
          centered
          width={1000}
          footer={null}
          wrapClassName={styles['Layout-modal']}
          visible={isModalVisible}
          onCancel={toggleModalInfo}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={_updateUser}
            className={styles['Layout-form']}
          >
            <Row style={{ marginTop: 30 }}>
              <Col md={12} style={{ padding: '0 40px' }}>
                <Form.Item
                  name="username"
                  label="Tên tài khoản"
                  rules={[{ required: false, message: 'Vui lòng nhập tên tài khoản' }]}
                >
                  <Input disabled placeholder="Nhập tên tài khoản" />
                </Form.Item>
                <Form.Item
                  label="Vai trò"
                  rules={[{ required: true, message: 'Vui lòng chọn vai trò' }]}
                >
                  <Input disabled value={roleName} />
                </Form.Item>

                <Form.Item
                  name="fullname"
                  label="Họ và tên"
                  rules={[{ required: true, message: 'Vui lòng nhập họ và tên' }]}
                >
                  <Input allowClear placeholder="Nhập họ và tên" />
                </Form.Item>
                <Form.Item
                  name="warehouse_id"
                  label="Kho làm việc"
                  rules={[{ required: true, message: 'Vui lòng chọn kho làm việc' }]}
                >
                  <Select
                    style={{ width: '100%' }}
                    suffixIcon={<SuffixIconCustom />}
                    showSearch
                    allowClear
                    placeholder="Kho"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataWarehouses.map((warehouse) => {
                      return (
                        <Select.Option value={warehouse.warehouse_id}>
                          {warehouse.name}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={12} style={{ padding: '0 40px' }}>
                <Form.Item label="Số điện thoại" name="phone">
                  <Input
                    allowClear
                    maxLength={15}
                    style={{ width: '100%' }}
                    placeholder="Nhập Số điện thoại"
                  />
                </Form.Item>

                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      type: 'email',
                      required: false,
                      message: 'Vui lòng nhập đúng định dạng email',
                    },
                  ]}
                >
                  <Input placeholder="Nhập tên email" />
                </Form.Item>
                <Form.Item
                  name="address"
                  label="Địa chỉ"
                  rules={[{ message: 'Vui lòng nhập địa chỉ' }]}
                >
                  <Input placeholder="Nhập dịa chỉ" />
                </Form.Item>

                <Form.Item
                  name="province"
                  label={<label style={{ fontSize: 16, fontWeight: 500 }}>Tỉnh/Thành phố</label>}
                >
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: '100%' }}
                    placeholder="Chọn tỉnh/thành phố"
                    suffixIcon={<SuffixIconCustom />}
                  >
                    {provinces.map((province, index) => (
                      <Select.Option key={index} value={province.province_name}>
                        {province.province_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="district"
                  label={<label style={{ fontSize: 16, fontWeight: 500 }}>Quận/Huyện</label>}
                >
                  <Select
                    allowClear
                    showSearch
                    filterOption={(inputValue, option) => {
                      return option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                    }}
                    style={{ width: '100%' }}
                    placeholder="Chọn quận/huyện"
                    suffixIcon={<SuffixIconCustom />}
                  >
                    {districts.map((district, index) => (
                      <Select.Option key={index} value={district.district_name}>
                        {district.district_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="ward"
                  label={<label style={{ fontSize: 16, fontWeight: 500 }}>Phường/Xã</label>}
                >
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: '100%' }}
                    placeholder="Chọn phường/xã"
                    suffixIcon={<SuffixIconCustom />}
                  >
                    {wards.map((ward, index) => (
                      <Select.Option key={index} value={ward.ward_name}>
                        {ward.ward_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Row justify="end" align="middle" wrap={false} style={{ marginTop: 45 }}>
                  <Space size="large">
                    <Button
                      type="primary"
                      danger
                      style={{ minWidth: 100 }}
                      onClick={toggleModalInfo}
                    >
                      Hủy
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ backgroundColor: '#FF9C64', borderColor: '#FF9C64', minWidth: 100 }}
                    >
                      Lưu
                    </Button>
                  </Space>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal>
      </AntLayout>
    </AntLayout>
  )
}
