import React, { memo, useState } from 'react'
import QRCode from 'react-qr-code'

import { Modal, Row } from 'antd'

export default memo(function QrCodeProduct({ value = '' }) {
  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)

  return (
    <div>
      <Modal
        style={{ top: 30 }}
        visible={visible}
        onCancel={toggle}
        footer={null}
        title="Qr code sản phẩm"
      >
        <Row justify="center">
          <QRCode value={value} style={{ height: 340, width: 340 }} />
        </Row>
      </Modal>
      <div onClick={toggle}>
        <QRCode value={value} style={{ height: 45, width: 45, cursor: 'pointer' }} />
      </div>
    </div>
  )
})
