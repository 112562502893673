import React, { useState } from 'react'

import { Button, Modal, Radio, Space, Input } from 'antd'
import { WarningOutlined } from '@ant-design/icons'

export default function ModalReasonOrder({ reasons = [], onReport }) {
  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)
  const [reason, setReason] = useState('')
  const [reasonOther, setReasonOther] = useState('')

  return (
    <>
      <Button onClick={toggle} icon={<WarningOutlined />} type="primary" danger>
        Báo cáo
      </Button>
      <Modal
        title="Lý do báo cáo đơn hàng"
        width={800}
        visible={visible}
        onCancel={() => {
          console.log('onCancel');
          toggle()
          setReason('')
        }}
        okText="Đồng ý"
        cancelText="Đóng"
        onOk={() => {
          onReport(reasonOther || reason)
          toggle()
        }}
        okButtonProps={{ disabled: (reason !== '') ? false : true }}
      >
        <div style={{ marginBottom: 20 }}>
          <Radio.Group
            value={reason}
            onChange={(e) => {
              setReason(e.target.value)
              setReasonOther('')
            }}
          >
            <Space direction="vertical">
              {reasons.map((reason, index) => (
                <Radio value={reason.label} key={index}>
                  {reason.label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </div>

        {reason === 'Khác' && (
          <Input.TextArea
            onChange={(e) => setReasonOther(e.target.value)}
            rows={4}
            allowClear
            placeholder="Nhập lý do khác"
            value={reasonOther}
          />
        )}
      </Modal>
    </>
  )
}
