import React, { useEffect, useState } from 'react'

import { ACTION, PERMISSIONS, PRINTER, ROUTES } from 'consts'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import delay from 'delay'
import { filterOptionSelect, removeAccents, _addLog } from 'utils'

//components
import style from './product-detail.module.scss'
import SuffixIconCustom from 'components/suffixIconCustom'
import Permissions from 'components/permission'
import printToThermalPrinter from 'components/thermal-printer/print'
import { barcode } from 'components/thermal-printer/templates'
import ModalOptionsPrint from 'components/modal-opions-print'

//antd
import {
  Row,
  Checkbox,
  Col,
  Form,
  Input,
  Button,
  Select,
  Space,
  InputNumber,
  DatePicker,
  Upload,
  Spin,
  Badge,
  Popconfirm,
  notification,
  Dropdown,
  Menu,
  Tooltip,
  Modal,
} from 'antd'

//icons
import {
  ArrowLeftOutlined,
  PlusOutlined,
  CloseCircleOutlined,
  CloseCircleTwoTone,
  LoadingOutlined,
} from '@ant-design/icons'
import iconPrinter from 'assets/icons/icon-printer.png'

//apis
import { createProduct, getProducts, updateProduct } from 'apis/product'
import { uploadFile } from 'apis/upload'
import { getAllCustomer } from 'apis/customer'
import { getUnits, createUnit } from 'apis/unit-product'
import { getCategories, createCategory } from 'apis/categories-product'
import { getPrint } from 'apis/print'
import jwt_decode from 'jwt-decode'

export default function ProductDetail() {
  const [form] = Form.useForm()
  const history = useHistory()
  const location = useLocation()
  const dataUser = useSelector((state) => state.user)
  const dispatch = useDispatch()

  const PERMISSIONS_USER = dataUser?.role_info?.permissions || []

  const [loadingBtn, setLoadingBtn] = useState(false)
  const [loadingType, setLoadingType] = useState(false)
  const [loadingGroup, setLoadingGroup] = useState(false)
  const [loadingCustomer, setLoadingCustomer] = useState(false)

  const [nameUnitCreate, setNameUnitCreate] = useState('')
  const [nameTypeCreate, setNameTypeCreate] = useState('') //loại hàng
  const [nameGroupCreate, setNameGroupCreate] = useState('') //nhóm hàng
  const [nameProduct, setNameProduct] = useState('')

  const [customers, setCustomers] = useState([])
  const [units, setUnits] = useState([])
  const [groups, setGroups] = useState([])
  const [types, setTypes] = useState([])
  const [productDefault, setProductDefault] = useState(null)

  const [barcodeValue, setBarcodeValue] = useState('')
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [requiredImage, setRequiredImage] = useState(true)
  const [imagesUrl, setImagesUrl] = useState([])
  const [imageView, setImageView] = useState('')
  const [isAutoPrint, setIsAutoPrint] = useState(false)

  const context = localStorage.getItem('accessToken')
    ? jwt_decode(localStorage.getItem('accessToken'))
    : null

  const _createUnit = async () => {
    try {
      if (!nameUnitCreate) {
        notification.warning({ message: 'Vui lòng nhập tên quy cách' })
        return
      }
      setLoadingBtn(true)
      const res = await createUnit({ name: nameUnitCreate })
      if (res.status === 200) {
        if (res.data.success) {
          await _getUnits()
          notification.success({ message: 'Tạo quy cách thành công' })
          setNameUnitCreate('')
        } else
          notification.error({
            message: res.data.mess || res.data.message || 'Tạo quy cách thất bại',
          })
      } else
        notification.error({
          message: res.data.mess || res.data.message || 'Tạo quy cách thất bại',
        })
      setLoadingBtn(false)
    } catch (error) {
      console.log(error)
      setLoadingBtn(false)
      _addLog(`Create unit product: ${JSON.stringify(error)}`)
    }
  }

  const _createType = async (customerId) => {
    try {
      if (!nameTypeCreate) {
        notification.warning({ message: 'Vui lòng nhập tên loại hàng' })
        return
      }

      const dataForm = form.getFieldsValue()
      const typeFind = groups.find((e) => e.category_id === dataForm.group_id)

      if (!typeFind) {
        notification.warning({ message: 'Vui lòng chọn nhóm hàng trước' })
        return
      }

      setLoadingBtn(true)
      const res = await createCategory({
        name: nameTypeCreate,
        parent_id: typeFind.category_id,
        customer_id: customerId,
      })
      console.log(res)
      if (res.status === 200) {
        if (res.data.success) {
          await _getTypes()
          notification.success({ message: 'Tạo loại hàng thành công' })
          setNameTypeCreate('')
        } else
          notification.error({
            message: res.data.mess || res.data.message || 'Tạo loại hàng thất bại',
          })
      } else
        notification.error({
          message: res.data.mess || res.data.message || 'Tạo loại hàng thất bại',
        })
      setLoadingBtn(false)
    } catch (error) {
      console.log(error)
      setLoadingBtn(false)
      _addLog(`Create type product: ${JSON.stringify(error)}`)
    }
  }

  const _createGroup = async (customerId) => {
    try {
      setLoadingBtn(true)
      const res = await createCategory({ name: nameGroupCreate, customer_id: customerId })
      if (res.status === 200) {
        if (res.data.success) {
          await _getGroups()
          notification.success({ message: 'Tạo nhóm hàng thành công' })
          setNameGroupCreate('')
        } else
          notification.error({
            message: res.data.mess || res.data.message || 'Tạo nhóm hàng thất bại',
          })
      } else
        notification.error({
          message: res.data.mess || res.data.message || 'Tạo nhóm hàng thất bại',
        })
      setLoadingBtn(false)
    } catch (error) {
      console.log(error)
      setLoadingBtn(false)
      _addLog(`Create group product: ${JSON.stringify(error)}`)
    }
  }

  const generateBarcode = () => {
    let arrBarcode = []
    const dataForm = form.getFieldsValue()

    if (dataForm.merchant_barcode) arrBarcode.push(dataForm.merchant_barcode)
    if (dataForm.origin) arrBarcode.push(dataForm.origin)

    const unit = units.find((u) => u.unit_id === dataForm.unit_id)
    if (unit && unit.representation_string) arrBarcode.push(unit.representation_string)
    const barcodeProduct = arrBarcode.join('-')
    form.setFieldsValue({ barcode: barcodeProduct })
    setBarcodeValue(barcodeProduct)
  }

  const _createOrUpdateProduct = async () => {
    await form.validateFields()

    // if (!imagesUrl.length) {
    //   setRequiredImage(false)
    //   return
    // } else setRequiredImage(true)

    try {
      const dataForm = form.getFieldsValue()

      //validated date
      // if (new Date(dataForm.exp).getTime() < new Date(dataForm.mfg).getTime()) {
      //   notification.warning({ message: 'Ngày sản xuất phải nhỏ hơn ngày hạn sử dụng' })
      //   return
      // }

      // if ((dataForm.barcode + '').length > 13) {
      //   notification.warning({ message: 'Barcode phải nhỏ hơn 14 kí tự' })
      //   return
      // }

      dispatch({ type: ACTION.LOADING, data: true })

      const body = {
        ...dataForm,
        images: imagesUrl ? imagesUrl : [],
        price: dataForm.price || 0,
        // exp: dataForm.exp ? new Date(dataForm.exp) : '',
        mfg: dataForm.mfg ? new Date(dataForm.mfg) : '',
        min_quantity: dataForm.min_quantity || 0,
      }

      let res
      if (location.state) res = await updateProduct(body, location.state.product_id)
      else res = await createProduct(body)

      if (res.status === 200) {
        if (res.data.success) {
          if (isAutoPrint) {
            _printBarcode()
            await delay(500)
          }

          notification.success({
            message: `${location.state ? 'Cập nhật' : 'Tạo'} sản phẩm thành công!`,
          })

          history.push(ROUTES.PRODUCT)
        } else
          notification.error({
            message:
              res.data.message || `${location.state ? 'Cập nhật' : 'Tạo'} sản phẩm thất bại!`,
          })
      } else
        notification.error({
          message: res.data.message || `${location.state ? 'Cập nhật' : 'Tạo'} sản phẩm thất bại!`,
        })

      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
      _addLog(`Create or update product: ${JSON.stringify(error)}`)
    }
  }
  const _getPrint = async (params) => {
    try {
      const res = await getPrint(params)
      if (res.status === 200) {
        return res.data.data[0]
      }
      return ''
    } catch (error) {
      console.log(error)
      _addLog(JSON.stringify(error))
      return ''
    }
  }

  const ModalChooseCustomer = ({ children, type = '' }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [customerId, setCustomerId] = useState()

    useEffect(() => {
      if (!visible) setCustomerId()
    }, [visible])

    return (
      <>
        <div onClick={toggle}>{children}</div>
        <Modal
          zIndex={9999}
          visible={visible}
          onCancel={toggle}
          title={`Chọn doanh nghiệp để tạo ${type === 'type' ? 'loại hàng' : 'nhóm hàng'}`}
          okText="Xác nhận"
          onOk={async () =>
            type === 'type' ? await _createType(customerId) : await _createGroup(customerId)
          }
          okButtonProps={{ disabled: customerId ? false : true }}
        >
          <div>
            <Select
              dropdownStyle={{ zIndex: 10000 }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Chọn doanh nghiệp"
              style={{ width: '100%' }}
              dropdownClassName="dropdown-select-custom"
              suffixIcon={<SuffixIconCustom />}
              value={customerId}
              onChange={setCustomerId}
            >
              {customers.map((customer) => (
                <Select.Option value={customer.customer_id} key={customer.customer_id}>
                  {customer.fullname}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Modal>
      </>
    )
  }

  const _printBarcode = async () => {
    const dataForm = form.getFieldsValue()
    if (!dataForm.barcode) {
      notification.warning({ message: 'Vui lòng nhập barcode để in' })
      return
    }

    if (!dataForm.name) {
      notification.warning({ message: 'Vui lòng nhập tên sản phẩm để in' })
      return
    }

    if (dataForm.barcode.length > 13) {
      notification.warning({ message: 'Barcode phải nhỏ hơn 14 kí tự' })
      return
    }
    const params = {
      warehouse_id: dataUser.warehouse_id,
      name: PRINTER.NHAP_KHO,
    }

    const dataPrinter = await _getPrint(params)
    printToThermalPrinter(
      barcode,
      {
        barcode: dataForm.barcode,
        title: 'SP: ' + removeAccents(dataForm.name || ''),
      },
      dataPrinter && dataPrinter.ip
    )
  }

  const onUpdateImage = async (_imagesUrl) => {
    if (context.role_info.permissions.includes('them_anh_san_pham')) {
      var response = await updateProduct({ images: _imagesUrl }, location.state.product_id)
      if (response && response.status == 200) {
        notification.success({ message: 'Cập nhật ảnh thành công' })
      } else {
        notification.error({ message: response?.data?.message ?? 'Cập nhật ảnh thất bại' })
      }
    } else {
      notification.error({ message: 'Bạn không có quyền cập nhật ảnh sản phẩm' })
    }
  }

  const _getCustomers = async () => {
    try {
      setLoadingCustomer(true)
      const res = await getAllCustomer({ get_all: true })
      if (res.status === 200) {
        setCustomers(res.data.data)
        if (res.data.data && res.data.data.length) {
          if (!location.state) {
            form.setFieldsValue({ customer_id: res.data.data[0].customer_id })
          }
        }
      }
      setLoadingCustomer(false)
    } catch (error) {
      _addLog(JSON.stringify(error))
      setLoadingCustomer(false)
      console.log(error)
    }
  }

  const _getProductDefault = async () => {
    try {
      const res = await getProducts({ page: 1, page_size: 10 })
      if (res.status === 200 && res.data.data && res.data.data.length)
        setProductDefault(res.data.data[0])
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _getUnits = async () => {
    try {
      const res = await getUnits({ get_all: true })
      if (res.status === 200) {
        const unit = res.data.data
        setUnits(unit)
        // if (!location.state)
        //   if (unit && unit.length) form.setFieldsValue({ unit_id: unit[0].unit_id })
      }
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  //nhóm hàng
  const _getGroups = async () => {
    try {
      setLoadingGroup(true)
      const res = await getCategories({ group: true, detach: true, get_all: true })
      if (res.status === 200) {
        const group = res.data.data
        setGroups(group)
        if (!location.state)
          if (group && group.length) form.setFieldsValue({ group_id: group[0].category_id })
      }
      setLoadingGroup(false)
    } catch (error) {
      _addLog(JSON.stringify(error))
      setLoadingGroup(false)
      console.log(error)
    }
  }

  //loại hàng
  const _getTypes = async () => {
    try {
      setLoadingType(true)
      const res = await getCategories({ type: true, detach: true, get_all: true })
      console.log(res)
      if (res.status === 200) {
        const type = res.data.data
        setTypes(type)
        if (!location.state)
          if (type && type.length) form.setFieldsValue({ type_id: type[0].category_id })
      }
      setLoadingType(false)
    } catch (error) {
      _addLog(JSON.stringify(error))
      setLoadingType(false)
      console.log(error)
    }
  }

  useEffect(() => {
    _getUnits()
    _getGroups()
    _getTypes()
    _getProductDefault()
    _getCustomers()
  }, [])

  useEffect(() => {
    if (location.state) {
      if (location.state.images && location.state.images.length) {
        setImageView(location.state.images[0])
        setImagesUrl(location.state.images)
      }
      setNameProduct(location.state.name)
      setBarcodeValue(location.state.barcode || location.state.code)
      form.setFieldsValue({
        ...location.state,
        // exp: location.state.exp ? moment(location.state.exp) : '',
        mfg: location.state.mfg ? moment(location.state.mfg) : '',
      })
    }
  }, [])

  return (
    <div>
      <Row
        justify="space-between"
        style={{ paddingBottom: 12, borderBottom: '0.75px solid #BBBCBD' }}
      >
        <Row
          onClick={() => history.goBack()}
          wrap={false}
          align="middle"
          style={{ fontSize: 17, cursor: 'pointer' }}
        >
          <ArrowLeftOutlined />
          <p style={{ marginBottom: 0, marginLeft: 9, fontWeight: 600 }}>
            {location.state ? 'Cập nhật sản phẩm' : 'Tạo sản phẩm'}
          </p>
        </Row>
        <Checkbox
          style={{ fontWeight: 600, display: location.state && 'none' }}
          onChange={(e) => {
            if (e.target.checked) {
              if (productDefault) {
                if (productDefault.images && productDefault.images.length) {
                  setImageView(productDefault.images[0])
                  setImagesUrl(productDefault.images)
                }
                form.setFieldsValue({
                  ...productDefault,
                  // exp: moment(productDefault.exp),
                  mfg: moment(productDefault.mfg),
                })
              }
            } else {
              form.resetFields()
              setImageView('')
              setImagesUrl([])
            }
          }}
        >
          Điền sản phẩm gần nhất
        </Checkbox>
      </Row>
      <Form form={form} layout="vertical">
        <Row wrap={false} style={{ marginTop: 20 }}>
          <Col md={12} style={{ paddingLeft: 50, paddingRight: 50 }}>
            <Form.Item
              label="Tên sản phẩm"
              name="name"
              rules={[{ required: true, message: 'Vui lòng nhập tên sản phẩm' }]}
            >
              <Input
                allowClear
                value={nameProduct}
                onChange={(e) => setNameProduct(e.target.value)}
                placeholder="Nhập tên sản phẩm"
              />
            </Form.Item>

            <Form.Item label="Nhóm hàng" name="group_id">
              <Select
                loading={loadingGroup}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Chọn nhóm hàng"
                style={{ width: '100%' }}
                dropdownClassName="dropdown-select-custom"
                suffixIcon={<SuffixIconCustom />}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Permissions permissions={[PERMISSIONS.them_quy_cach_san_pham]}>
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                        <Input
                          value={nameGroupCreate}
                          placeholder="Nhập tên nhóm hàng"
                          onChange={(e) => setNameGroupCreate(e.target.value)}
                        />
                        <ModalChooseCustomer type="group">
                          <Button type="primary" style={{ marginLeft: 10 }}>
                            Tạo mới
                          </Button>
                        </ModalChooseCustomer>
                      </div>
                    </Permissions>
                  </div>
                )}
              >
                {groups.map((group, index) => (
                  <Select.Option value={group.category_id} key={index}>
                    {group.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Loại hàng" name="type_id">
              <Select
                loading={loadingType}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Chọn loại hàng"
                style={{ width: '100%' }}
                dropdownClassName="dropdown-select-custom"
                suffixIcon={<SuffixIconCustom />}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input
                        value={nameTypeCreate}
                        placeholder="Nhập tên loại hàng"
                        onChange={(e) => setNameTypeCreate(e.target.value)}
                      />
                      <ModalChooseCustomer type="type">
                        <Button type="primary" style={{ marginLeft: 10 }}>
                          Tạo mới
                        </Button>
                      </ModalChooseCustomer>
                    </div>
                  </div>
                )}
              >
                {types.map((type, index) => (
                  <Select.Option value={type.category_id} key={index}>
                    {type.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item initialValue="" style={{ width: '100%' }} label="Xuất xứ" name="origin">
              <Input
                // onChange={generateBarcode}
                allowClear
                style={{ width: '100%' }}
                placeholder="Nhập xuất xứ"
              />
            </Form.Item>
            <Form.Item
              style={{ width: '100%' }}
              label="Mã hàng Merchant (Barcode)"
              name="merchant_barcode"
              initialValue=""
            >
              <Input
                // onChange={generateBarcode}
                allowClear
                style={{ width: '100%' }}
                placeholder="Nhập mã hàng merchant"
              />
            </Form.Item>
            <Row wrap={false} align="middle">
              <Form.Item
                style={{ width: '100%' }}
                label="Mã hàng (Barcode)"
                name="barcode"
                rules={[{ required: true, message: 'Vui lòng nhập mã hàng (barcode)' }]}
              >
                <Input
                  placeholder="Nhập mã hàng (Barcode)"
                  disabled={
                    location.state
                      ? !PERMISSIONS_USER.includes(PERMISSIONS.cap_nhat_ma_san_pham)
                      : false
                  }
                  // readOnly
                  allowClear
                  value={barcodeValue}
                  onChange={(e) => setBarcodeValue(e.target.value)}
                  // style={{ width: '100%', pointerEvents: 'none' }}
                />
              </Form.Item>
              <ModalOptionsPrint
                printWithInternet={_printBarcode}
                records={[
                  {
                    code: barcodeValue,
                    barcode: barcodeValue,
                    name: form.getFieldsValue().name || '',
                    convention: form.getFieldsValue().convention || '',
                    unit_info: units.find(
                      (u) => u.unit_id === (form.getFieldsValue().unit_id || '')
                    ),
                  },
                ]}
              >
                {barcodeValue && (
                  <Tooltip title="In mã hàng">
                    <img
                      // onClick={_printBarcode}
                      src={iconPrinter}
                      style={{
                        width: 32,
                        height: 32,
                        cursor: 'pointer',
                        marginTop: 20,
                        marginLeft: 15,
                      }}
                      alt=""
                    />
                  </Tooltip>
                )}
              </ModalOptionsPrint>
            </Row>
            <Form.Item
              initialValue=""
              style={{ width: '100%' }}
              label="Mã ERP"
              name="ecommerce_barcode"
            >
              <Input style={{ width: '100%' }} placeholder="Nhập mã ERP" />
            </Form.Item>
            <div>
              <span style={{ fontWeight: 500, fontSize: 16 }}>
                <span style={{ color: 'red' }}>* </span>Kích thước (cm)
              </span>
              <Row wrap={false} justify="space-between">
                <Form.Item
                  style={{ width: '32%' }}
                  name="length"
                  rules={[{ required: true, message: 'Vui lòng nhập chiều dài' }]}
                >
                  <InputNumber
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    style={{ width: '100%' }}
                    min={0}
                    placeholder="Chiều dài"
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '32%' }}
                  name="width"
                  rules={[{ required: true, message: 'Vui lòng nhập chiều rộng' }]}
                >
                  <InputNumber
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    style={{ width: '100%' }}
                    min={0}
                    placeholder="Chiều rộng"
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '32%' }}
                  name="height"
                  rules={[{ required: true, message: 'Vui lòng nhập chiều cao' }]}
                >
                  <InputNumber
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    style={{ width: '100%' }}
                    min={0}
                    placeholder="Chiều cao"
                  />
                </Form.Item>
              </Row>
            </div>
            <Row wrap={false} justify="space-between" align="middle">
              <Form.Item
                initialValue={0}
                style={{ width: '40%' }}
                label="Trọng lượng (kg)"
                name="weight"
              >
                <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  min={0}
                  style={{ width: '100%' }}
                  placeholder="Trọng lượng"
                />
              </Form.Item>
              <Form.Item
                initialValue={1}
                style={{ width: '58%' }}
                label="Đơn vị nhỏ nhất"
                name="smallest_unit"
              >
                <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  style={{ width: '100%' }}
                  placeholder="Nhập đơn vị nhỏ nhất"
                />
              </Form.Item>
            </Row>
            <Row wrap={false} justify="space-between" align="middle">
              <Form.Item
                initialValue=""
                style={{ width: '40%' }}
                label="Quy cách"
                name="convention"
              >
                <Input style={{ width: '100%' }} placeholder="Nhập quy cách" />
              </Form.Item>
              <Form.Item
                style={{ width: '58%' }}
                label="Đơn vị tính"
                name="unit_id"
                rules={[{ required: true, message: 'Vui lòng chọn đơn vị tính' }]}
              >
                <Select
                  filterOption={filterOptionSelect}
                  showSearch
                  allowClear
                  placeholder="Chọn đơn vị tính"
                  style={{ width: '100%' }}
                  dropdownClassName="dropdown-select-custom"
                  suffixIcon={<SuffixIconCustom />}
                  // onChange={generateBarcode}
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                        <Input
                          value={nameUnitCreate}
                          onPressEnter={_createUnit}
                          placeholder="Nhập tên đơn vị tính"
                          onChange={(e) => setNameUnitCreate(e.target.value)}
                        />
                        <Button
                          loading={loadingBtn}
                          onClick={_createUnit}
                          type="primary"
                          style={{ marginLeft: 10 }}
                        >
                          Tạo mới
                        </Button>
                      </div>
                    </div>
                  )}
                >
                  {units.length ? (
                    units.map((unit, index) => (
                      <Select.Option value={unit.unit_id} key={index}>
                        {unit.name}{' '}
                        {unit.conversion_quantity
                          ? `- SL quy đổi: ${unit.conversion_quantity}`
                          : ''}
                      </Select.Option>
                    ))
                  ) : (
                    <Select.Option></Select.Option>
                  )}
                </Select>
              </Form.Item>
            </Row>
            <Row wrap={false} justify="space-between" align="middle">
              <Form.Item
                initialValue={0}
                style={{ width: '40%' }}
                label="Tồn kho tối thiểu"
                name="min_quantity"
                // rules={[{ required: true, message: 'Vui lòng nhập số lượng tồn kho' }]}
              >
                <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  min={0}
                  style={{ width: '100%' }}
                  placeholder="Nhập số lượng tồn kho"
                />
              </Form.Item>
              <Form.Item
                style={{ width: '58%' }}
                label="Doanh nghiệp"
                name="customer_id"
                rules={[{ required: true, message: 'Vui lòng chọn doanh nghiệp' }]}
              >
                <Select
                  disabled={location.state ? true : false}
                  notFoundContent={loadingCustomer ? <Spin /> : null}
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Chọn doanh nghiệp"
                  style={{ width: '100%' }}
                  dropdownClassName="dropdown-select-custom"
                  suffixIcon={<SuffixIconCustom />}
                >
                  {customers.map((customer, index) => (
                    <Select.Option value={customer.customer_id} key={index}>
                      {customer.fullname}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Row>
            <Row justify="space-between">
              <Form.Item initialValue={false} name="is_create_barcode" valuePropName="checked">
                <Checkbox>Sử dụng barcode SGL</Checkbox>
              </Form.Item>
              <Checkbox checked={isAutoPrint} onChange={(e) => setIsAutoPrint(e.target.checked)}>
                Tự động In QR Code
              </Checkbox>
            </Row>
          </Col>
          <Col md={12} style={{ paddingLeft: 50, paddingRight: 50 }}>
            <div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>
                  {/* <span style={{ color: 'red' }}>*</span> */}
                  Hình ảnh
                </span>
                {/* {!requiredImage && (
                  <span style={{ color: 'red' }}>Vui lòng chọn ít nhất 1 hình ảnh</span>
                )} */}
              </div>
              <div
                style={{
                  minWidth: '100%',
                  maxWidth: '100%',
                  borderRadius: 5,
                  borderColor: 'gray',
                  borderWidth: '1px',
                  minHeight: 400,
                  maxHeight: 400,
                  borderStyle: 'dashed',
                  marginBottom: 20,
                  overflow: 'hidden',
                }}
              >
                <img
                  src={imageView}
                  alt=""
                  style={{
                    width: '100%',
                    minHeight: 400,
                    maxHeight: 400,
                    objectFit: 'contain',
                  }}
                />
              </div>
              <Row justify="space-between" wrap={false} align="middle" style={{ width: '100%' }}>
                {imagesUrl.length !== 4 ? (
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    onChange={(info) => {
                      if (info.file.status !== 'done') info.file.status = 'done'
                    }}
                    data={async (file) => {
                      setLoadingUpload(true)
                      const imagesUrlNew = [...imagesUrl]
                      const url = await uploadFile(file)
                      setImageView(url)
                      imagesUrlNew.push(url)
                      setImagesUrl([...imagesUrlNew])
                      setRequiredImage(true)
                      setLoadingUpload(false)
                      onUpdateImage([...imagesUrlNew])
                    }}
                  >
                    <div>
                      {loadingUpload ? <LoadingOutlined /> : <PlusOutlined />}
                      <div style={{ marginTop: 8 }}>Tải ảnh lên</div>
                    </div>
                  </Upload>
                ) : (
                  ''
                )}
                {imagesUrl.map((url, index) => (
                  <Badge
                    count={
                      <Popconfirm
                        onConfirm={() => {
                          const imagesUrlNew = [...imagesUrl]
                          imagesUrlNew.splice(index, 1)
                          if (imagesUrlNew.length === 0) setImageView('')
                          else setImageView(imagesUrlNew[0])
                          setImagesUrl([...imagesUrlNew])
                          onUpdateImage([...imagesUrlNew])
                        }}
                        title="Bạn có muốn xóa ảnh này không ?"
                        okText="Đồng ý"
                        cancelText="Từ chối"
                      >
                        <CloseCircleTwoTone style={{ fontSize: 19 }} />
                      </Popconfirm>
                    }
                  >
                    <img
                      onClick={() => setImageView(url)}
                      src={url}
                      alt=""
                      style={{
                        width: 85,
                        height: 85,
                        cursor: 'pointer',
                        objectFit: 'cover',
                        borderRadius: 5,
                        overflow: 'hidden',
                      }}
                    />
                  </Badge>
                ))}
              </Row>
            </div>
            <Form.Item
              initialValue={0}
              style={{ marginTop: 8 }}
              label="Giá tiền (VND)"
              name="price"
            >
              <InputNumber
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                min={0}
                style={{ width: '100%' }}
                placeholder="Nhập giá tiền"
              />
            </Form.Item>
            <Row>
              {/* <Form.Item initialValue="" style={{ width: '100%' }} label="Ngày sản xuất" name="mfg">
                <DatePicker style={{ width: '100%' }} />
              </Form.Item> */}
              {/* <Form.Item
                initialValue=""
                style={{ width: '100%' }}
                label="Hạn sử dụng"
                name="exp"
                extra={
                  <span style={{ color: 'red' }}>
                    Hệ thống ưu tiên xuất kho sản phẩm có hạn sử dụng gần
                  </span>
                }
              >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item> */}
            </Row>
            <Row wrap={false} justify="space-between" style={{ marginTop: 19 }}>
              <Button
                onClick={() => history.goBack()}
                icon={<CloseCircleOutlined />}
                style={{ width: 130 }}
                type="primary"
                danger
              >
                Hủy
              </Button>
              <Permissions
                permissions={
                  location.state ? [PERMISSIONS.cap_nhat_san_pham] : [PERMISSIONS.tao_san_pham]
                }
              >
                <Button
                  className={style['btn-save']}
                  onClick={_createOrUpdateProduct}
                  style={{
                    width: 130,
                  }}
                  type="primary"
                >
                  {location.state ? 'Lưu' : 'Tạo sản phẩm'}
                </Button>
              </Permissions>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
