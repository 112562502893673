const inventoryColumns = [
  {
    key: 'stt',
    title: 'STT',
    width: 70,
    fixed: 'left',
  },
  {
    title: 'Mã đơn từ sàn',
    dataIndex: 'code_ecommerce',
    fixed: 'left',
    width: '6%',
  },
  {
    title: 'Số phiếu',
    dataIndex: 'order_customer_order_code',
    fixed: 'left',
    width: '6%',
  },
  {
    title: 'Mã đơn hàng',
    dataIndex: 'order_code',
  },
  {
    title: 'Tên doanh nghiệp',
    dataIndex: 'customer_name',
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'customer_phone',
  },
  {
    title: 'Email',
    dataIndex: 'customer_email',
  },
  {
    title: 'Địa chỉ',
    dataIndex: 'customer_address',
  },
  {
    title: 'Tên kho',
    dataIndex: 'warehouse_name',
  },
  {
    title: 'Địa chỉ kho',
    dataIndex: 'warehouse_address',
  },
  {
    title: 'Ngày tạo',
    key: 'order_create_date',
  },
  {
    title: 'Nhân viên kiểm đếm',
    dataIndex: 'check_employee_name',
  },
  {
    title: 'Thời điểm kiểm hàng',
    key: 'product_check_date',
  },
  {
    title: 'Mã sản phẩm',
    dataIndex: 'product_barcode',
  },
  {
    title: 'Tên sản phẩm',
    dataIndex: 'product_name',
  },
  {
    title: 'Nhóm sản phẩm',
    dataIndex: 'product_group',
  },
  {
    title: 'Loại hàng',
    dataIndex: 'product_type',
  },
  {
    title: 'Dài',
    key: 'product_length',
  },
  {
    title: 'Rộng',
    key: 'product_width',
  },
  {
    title: 'Cao',
    key: 'product_height',
  },
  {
    title: 'CBM',
    key: 'product_cbm',
  },
  {
    title: 'Diện tích sáu mặt',
    key: 'product_area',
  },
  {
    title: 'Quy cách',
    dataIndex: 'product_convention',
  },
  {
    title: 'Đơn vị tính',
    dataIndex: 'product_unit',
  },
  {
    title: 'Số lượng kiểm đếm',
    key: 'product_check_quantity',
  },
  {
    title: 'Số lượng theo đơn vị nhỏ nhất',
    key: 'product_conversion_quantity',
  },
  {
    title: 'Quy đổi số lượng',
    key: 'product_conversion_require_quantity',
  },
]

export default inventoryColumns
