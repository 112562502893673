import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import styles from './export-product.module.scss'
import moment from 'moment'
import { PERMISSIONS, ROUTES } from 'consts'
import { removeAccents, _addLog, saveFiltersToLocalStorage, handleGetDataExportExcel } from 'utils'
import { useHotkeys } from 'react-hotkeys-hook'

//components
import TabsTime from './components/TabsTime'
import SuffixIconCustom from 'components/suffixIconCustom'
import ImportFile from 'components/import-csv'
import Export from 'components/export-csv'
import Permission from 'components/permission'
import FilterDate from 'components/filter-date'

// antd
import {
  Col,
  Row,
  Space,
  Input,
  Select,
  notification,
  message,
  Button,
  DatePicker,
  Upload,
} from 'antd'

//icons
import { ReloadOutlined } from '@ant-design/icons'

// apis
import {
  findBucket,
  getExportOrders,
  importFileOrder,
  importFileOrderFromLazada,
  importFileOrderFromShoppe,
  importFileOrderFromTiki,
  updateExportOrders,
} from 'apis/export-order'
import { getAllCustomer } from 'apis/customer'
import { getWarehouses } from 'apis/warehouse'
import { uploadFiles } from 'apis/upload'

export default function ExportProduct() {
  const history = useHistory()
  const KEY_FILTER = 'productExportFilters'
  const filterList =
    localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))

  const [exportOrdersList, setExportOrdersList] = useState([])
  const [countOrder, setCountOrder] = useState(0)
  const [timeTabs, setTimeTabs] = useState([])
  const [scannerIsOn, setScannerIsOn] = useState(false)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [warehouse, setWarehouse] = useState()
  const [customer, setCustomer] = useState()
  const [warehouses, setWarehouses] = useState([])
  const [customers, setCustomers] = useState([])
  const [filesUpload, setFilesUpload] = useState([])
  const [valueSearch, setValueSearch] = useState(filterList ? filterList.search || '' : '')
  const [valueScan, setValueScan] = useState('')

  const [filters, setFilters] = useState(
    filterList
      ? { ...filterList }
      : {
          is_exported: true,
          page: 1,
          page_size: 20,
          time_receive: new Date().getHours(),
          export_status: 'WAITING',
        }
  )
  const [loading, setLoading] = useState(false)
  const [exportOrdersChecked, setExportOrdersChecked] = useState([])
  const typingTimeoutRef = useRef(null)
  const scanRef = useRef(null)
  const dispatch = useDispatch()

  const indicators = [
    {
      title: 'Chưa xuất vị trí',
      bgColor: '#FFCE62',
      order_status: 'WAITING',
    },
    {
      title: 'Đang thực hiện',
      bgColor: '#80E9EF',
      order_status: 'PROCESSING',
    },
    {
      title: 'Đã xuất vị trí',
      bgColor: '#79CF4F',
      order_status: 'COMPLETE',
    },
    {
      title: 'Có sự cố',
      bgColor: '#D63F40',
      order_status: 'REPORT',
    },
  ]

  const _getOrderScan = async (valueScan = '') => {
    try {
      setLoading(true)
      const res = await getExportOrders({ search: valueScan })
      if (res.data.success) {
        const order = res.data.data && res.data.data.length === 1 && res.data.data[0]

        const resBuckets = await findBucket({ order_id: order.order_id })

        if (order) {
          history.push({
            pathname: ROUTES.PRODUCT_EXPORT_SCAN,
            state: {
              type: 'basket',
              record: order,
              order_id: order?.order_id,
              order_code: order?.code,
              order_status: order?.export_status,
              processing: order?.processing,
              default_basket: order?.basket_info,
              buckets: resBuckets.status === 200 ? res.data.data.bucket_map : [],
              filters,
            },
          })
        } else notification.error({ message: `Không tìm thấy mã đơn hàng ${valueScan}` })

        setValueScan('')
      }
      setLoading(false)
    } catch (err) {
      console.log(err)
      _addLog(`Export export order: ${JSON.stringify(err)}`)
      setLoading(false)
    }
  }

  const _getExportOrders = async () => {
    try {
      setLoading(true)
      setExportOrdersList([])
      const res = await getExportOrders(filters)
      if (res.data.success) {
        setExportOrdersList(res.data.data)
        setTimeTabs(res.data.quantity_overtime)
        setCountOrder(res.data.count)
      }
      setLoading(false)
    } catch (err) {
      console.log(err)
      _addLog(`Export export order: ${JSON.stringify(err)}`)
      setLoading(false)
    }
  }

  const _onScan = (e) => {
    setValueScan(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value.replace('#', '')
      _getOrderScan(value)
    }, 500)
  }

  const _onSearch = (e) => {
    setValueSearch(String(e.target.value).replace(/#/gi, ''))
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value
      let tmp = { ...filters }
      setFilters({ ...tmp, search: value, page: 1 })
    }, 500)
  }

  const handleExportData = async (query = {}) => {
    try {
      const data = await handleGetDataExportExcel(getExportOrders, query)
      if (data) {
        const dataExport = data.map((order) => {
          let order_template = {
            'Mã đơn hàng': order.code || '',
            'Ngày đặt hàng': moment(order.create_date).format('YYYY-MM-DD HH:mm'),
            'Mã thùng chứa': (order.basket_info && order.basket_info[0]?.code) || '',
            'Tên doanh nghiệp': (order.customer_info && order.customer_info.fullname) || '',
            'Số điện thoại': (order.customer_info && order.customer_info.phone) || '',
            Email: (order.customer_info && order.customer_info.email) || '',
            'Địa chỉ': (order.customer_info && order.customer_info.address) || '',
            'Phường/Xã': (order.customer_info && order.customer_info.ward) || '',
            'Quận/huyện': (order.customer_info && order.customer_info.district) || '',
            'Tỉnh/Thành Phố': (order.customer_info && order.customer_info.province) || '',
            'Tên kho': order.warehouse_info && order.warehouse_info.name,
            'Địa chỉ kho':
              order.warehouse_info &&
              `${order.warehouse_info.address || ''}, ${order.warehouse_info.ward || ''}, ${
                order.warehouse_info.district || ''
              }, ${order.warehouse_info.province || ''}`,
            'Trạng thái đơn hàng': '',
            // 'Nhân viên quét xuất khỏi vị trí': order.export_employee_info.fullname || '',
            'Ngày giờ quét hàng khỏi vị trí': order.export_date
              ? moment(order.export_date).format('YYYY-MM-DD HH:mm')
              : '',
            // 'Nhân viên đóng gói': order.pack_employee_info.fullname || '',
            'Ngày giờ đóng gói đơn hàng': order.pack_date
              ? moment(order.pack_date).format('YYYY-MM-DD HH:mm')
              : '',
            // 'Nhân viên giao đơn vị vận chuyển': order.transport_employee_info.fullname || '',
            'Ngày giờ đóng gói đơn hàng': order.transport_date
              ? moment(order.transport_date).format('YYYY-MM-DD HH:mm')
              : '',

            'Mã vận đơn': (order.shipping_info && order.shipping_info.shipping_code) || '',
            'Đơn vị vận chuyển':
              (order.shipping_info && order.shipping_info.shipping_company) || '',
            COD: (order.shipping_info && order.shipping_info.cod) || '',
          }
          return order.expenses.length
            ? order.expenses.map((expense) => {
                return expense.products.length
                  ? expense.products.map((product) => {
                      return {
                        ...order_template,
                        'Mã kiện hàng': expense.code || '',
                        'Barcode/Qrcode': product.barcode || '',
                        'Tên sản phẩm': product.name || '',
                        'Nhóm hàng': product.group || '',
                        'Loại hàng': product.type || '',
                        'Số lượng': product.quantity || '',
                        'Tổng giá trị đơn hàng': order.total_cost || '',
                      }
                    })
                  : {
                      ...order_template,
                      'Mã kiện hàng': expense.code || '',
                      'Barcode/Qrcode': '',
                      'Tên sản phẩm': '',
                      'Nhóm hàng': '',
                      'Loại hàng': '',
                      'Số lượng': '',

                      'Tổng giá trị đơn hàng': order.total_cost || '',
                    }
              })
            : {
                ...order_template,
                'Mã kiện hàng': '',
                'Barcode/Qrcode': '',
                'Tên sản phẩm': '',
                'Nhóm hàng': '',
                'Loại hàng': '',
                'Số lượng': '',
                'Tổng giá trị đơn hàng': order.total_cost || '',
              }
        })
        return dataExport.flat(2)
      }
      return []
    } catch (err) {
      _addLog(`Export export order: ${JSON.stringify(err)}`)
      console.log(err)
      return []
    }
  }

  const UploadPdf = () => {
    const _uploadFiles = (files) => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current)
      }
      typingTimeoutRef.current = setTimeout(async () => {
        setLoadingUpload(true)
        const filesNew = files
          .filter((file) => file.originFileObj)
          .map(
            (file) =>
              new File([file.originFileObj], removeAccents(file.originFileObj.name, true, '_'), {
                type: file.originFileObj.type,
              })
          )
        const urls = await uploadFiles(filesNew)
        if (urls) setFilesUpload([...filesUpload, ...urls])
        setLoadingUpload(false)
      }, 350)
    }

    const _removeFile = (file) => {
      const filesUploadNew = [...filesUpload]
      const indexFilesUpload = filesUploadNew.findIndex((f) => f === file.url)
      if (indexFilesUpload !== -1) filesUploadNew.splice(indexFilesUpload, 1)
      setFilesUpload([...filesUploadNew])
    }

    return (
      <Upload
        multiple
        fileList={filesUpload.map((file, index) => {
          const fileNames = file.split('/')
          const fileName = fileNames[fileNames.length - 1]
          return { uid: index + '', name: fileName, status: 'done', url: file }
        })}
        maxCount={4}
        beforeUpload={(file) => {
          if (file.type !== 'application/pdf') {
            message.error(`${file.name} không phải là file pdf`)
          }
          return file.type === 'application/pdf' ? true : Upload.LIST_IGNORE
        }}
        onChange={(info) => {
          if (info.file.status !== 'removed') _uploadFiles(info.fileList)
          if (info.file.status !== 'done') info.file.status = 'done'
        }}
        onRemove={_removeFile}
      >
        <Space>
          <Button loading={loadingUpload}>Tải lên file pdf</Button>
          <div style={{ color: 'red' }}>Tối đa tải được 4 file pdf</div>
        </Space>
      </Upload>
    )
  }

  const updateExport = async (id, data) => {
    try {
      dispatch({ type: 'LOADING', data: true })
      const res = await updateExportOrders(id, data)
      if (res.data.success) {
        notification.success({ message: 'Cập nhật đơn hàng thành công' })
        _getExportOrders()
      } else {
        notification.error({ message: res.data.message || 'Cập nhật đơn hàng thất bại!' })
      }
      dispatch({ type: 'LOADING', data: false })
    } catch (err) {
      _addLog(`Update export order: ${JSON.stringify(err)}`)
      dispatch({ type: 'LOADING', data: false })
      console.log(err)
    }
  }

  const confirmExportOrders = async (data) => {
    try {
      const res = await updateExportOrders(data, {
        export_status: 'COMPLETE',
        processing: 'is_exported',
      })

      if (res.data.success) {
        notification.success({ message: 'Duyệt xuất kho thành công' })
        _getExportOrders()
      } else {
        notification.error({ message: res.data.message || 'Duyệt xuất kho không thành công' })
        return
      }
    } catch (err) {
      _addLog(`Confirm export order: ${JSON.stringify(err)}`)
      console.log(err)
    }
  }

  const _getCustomers = async () => {
    try {
      const res = await getAllCustomer()
      if (res.status === 200) setCustomers(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _getWarehouses = async () => {
    try {
      const res = await getWarehouses()
      if (res.status === 200) setWarehouses(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _clearFilters = (tabReceive) => {
    setValueSearch('')
    setFilters({
      is_exported: true,
      page: 1,
      page_size: 30,
      time_receive: tabReceive,
      export_status: 'WAITING',
    })
  }

  const _resetStateImport = () => {
    setCustomer()
    setWarehouse()
    setFilesUpload([])
  }

  const _filterByOption = (attribute = '', value = '') => {
    filters.page = 1

    if (value) filters[attribute] = value
    else delete filters[attribute]

    setFilters({ ...filters })
  }

  useHotkeys('f1', () => {
    if (scanRef && scanRef.current) scanRef.current.focus()
  })

  useEffect(() => {
    _getCustomers()
    _getWarehouses()
    scanRef.current.focus()
  }, [])

  useEffect(() => {
    _getExportOrders()
    saveFiltersToLocalStorage(filters, KEY_FILTER)
  }, [filters])

  return (
    <div className={styles['importProduct']}>
      <Row className="page-title" justify="space-between">
        <h3>Xuất hàng</h3>
        {/* <span>{scannerIsOn ? 'Vui lòng quét thùng đựng' : 'Máy quét đang tắt'}</span> */}
        <Space>
          <Button onClick={_getExportOrders} type="primary" icon={<ReloadOutlined />}>
            Làm mới
          </Button>
          <Permission permissions={[PERMISSIONS.tao_danh_sach_xuat_hang]}>
            <ImportFile
              action_name="Nhập excel đơn xuất hàng từ đơn hàng mẫu SGL"
              reset={_resetStateImport}
              colorCode="#12D8E5"
              width="70%"
              keyForm={{ warehouse_id: warehouse || '', customer_id: customer || '' }}
              txt="Nhập excel đơn hàng mẫu SGL"
              title={
                <Row wrap={false} align="middle">
                  <div style={{ marginRight: 20, fontWeight: 600 }}>
                    Nhập phiếu xuất kho bằng file excel từ SGL
                  </div>
                  <div style={{ marginRight: 15 }}>
                    <div style={{ fontSize: 13 }}>Chọn doanh nghiệp</div>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      value={customer}
                      onChange={(value) => {
                        const customer = customers.find((c) => c.customer_id === value)
                        if (customer) setWarehouse(customer.warehouse_id)
                        setCustomer(value)
                      }}
                      placeholder="Chọn doanh nghiệp"
                      size="small"
                      style={{ width: 250 }}
                      allowClear
                    >
                      {customers.map((customer, index) => (
                        <Select.Option key={index} value={customer.customer_id}>
                          {customer.fullname}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>

                  <div>
                    <div style={{ fontSize: 13 }}>Chọn kho</div>
                    <Select
                      value={warehouse}
                      onChange={setWarehouse}
                      placeholder="Chọn kho"
                      size="small"
                      style={{ width: 250 }}
                      allowClear
                    >
                      {warehouses.map((warehouse, index) => (
                        <Select.Option key={index} value={warehouse.warehouse_id}>
                          {warehouse.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </Row>
              }
              fileTemplated="https://s3.ap-northeast-1.wasabisys.com/saigonlogistics/2023/11/29/9f0cc0b2-3b4a-425e-b57a-55b29cc78e3b_1701234077618_saigonlogistics.xlsx"
              upload={importFileOrder}
              reload={_getExportOrders}
            />
          </Permission>
          <Export
            text="Xuất theo bộ lọc"
            actionName="Xuất excel danh sách đơn hàng xuất"
            exportData={() => handleExportData(filters)}
            fileName={'Danh sách xuất hàng ' + moment().format('DD-MM-YYYY')}
          />
          <Export
            text="Xuất toàn bộ"
            actionName="Xuất excel danh sách đơn hàng xuất"
            exportData={() => handleExportData()}
            fileName={'Danh sách xuất hàng ' + moment().format('DD-MM-YYYY')}
          />
        </Space>
      </Row>
      <Permission permissions={[PERMISSIONS.tao_danh_sach_xuat_hang]}>
        <Row justify="end">
          {/* <Space>
            <ImportFile
              action_name="Nhập excel đơn xuất hàng từ Shopee"
              reset={_resetStateImport}
              importPdf={<UploadPdf />}
              colorCode="#f53d2d"
              txt="Nhập excel từ Shopee"
              keyForm={{
                links: filesUpload || [],
                warehouse_id: warehouse || '',
                customer_id: customer || '',
              }}
              width="95%"
              title={
                <Row wrap={false} align="middle">
                  <div style={{ marginRight: 20, fontWeight: 600 }}>
                    Nhập phiếu xuất kho bằng file excel từ Shopee
                  </div>

                  <div style={{ marginRight: 15 }}>
                    <div style={{ fontSize: 13 }}>Chọn doanh nghiệp</div>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      value={customer}
                      onChange={setCustomer}
                      placeholder="Chọn doanh nghiệp"
                      size="small"
                      style={{ width: 250 }}
                      allowClear
                    >
                      {customers.map((customer, index) => (
                        <Select.Option key={index} value={customer.customer_id}>
                          {customer.fullname}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>

                  <div>
                    <div style={{ fontSize: 13 }}>Chọn kho</div>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      value={warehouse}
                      onChange={setWarehouse}
                      placeholder="Chọn kho"
                      size="small"
                      style={{ width: 250 }}
                      allowClear
                    >
                      {warehouses.map((warehouse, index) => (
                        <Select.Option key={index} value={warehouse.warehouse_id}>
                          {warehouse.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </Row>
              }
              fileTemplated="https://s3.ap-northeast-1.wasabisys.com/admin-order/2022/02/17/e56ff4df-4c85-43a7-89df-3c94c56eee8d/file_order_shopee.xlsx"
              upload={importFileOrderFromShoppe}
              reload={_getExportOrders}
            />
            <ImportFile
              action_name="Nhập excel đơn xuất hàng từ Tiki"
              reset={_resetStateImport}
              importPdf={<UploadPdf />}
              colorCode="#1A94FF"
              txt="Nhập excel từ Tiki"
              keyForm={{
                links: filesUpload || [],
                warehouse_id: warehouse || '',
                customer_id: customer || '',
              }}
              width="95%"
              title={
                <Row wrap={false} align="middle">
                  <div style={{ marginRight: 20, fontWeight: 600 }}>
                    Nhập phiếu xuất kho bằng file excel từ Tiki
                  </div>
                  <div style={{ marginRight: 15 }}>
                    <div style={{ fontSize: 13 }}>Chọn doanh nghiệp</div>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      value={customer}
                      onChange={setCustomer}
                      placeholder="Chọn doanh nghiệp"
                      size="small"
                      style={{ width: 250 }}
                      allowClear
                    >
                      {customers.map((customer, index) => (
                        <Select.Option key={index} value={customer.customer_id}>
                          {customer.fullname}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                  <div>
                    <div style={{ fontSize: 13 }}>Chọn kho</div>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      value={warehouse}
                      onChange={setWarehouse}
                      placeholder="Chọn kho"
                      size="small"
                      style={{ width: 250 }}
                      allowClear
                    >
                      {warehouses.map((warehouse, index) => (
                        <Select.Option key={index} value={warehouse.warehouse_id}>
                          {warehouse.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </Row>
              }
              fileTemplated="https://s3.ap-northeast-1.wasabisys.com/admin-order/2022/02/17/07471252-cd0c-4f1d-a7f4-fadf560ab749/file_order_tiki.xlsx"
              upload={importFileOrderFromTiki}
              reload={_getExportOrders}
            />
            <ImportFile
              action_name="Nhập excel đơn xuất hàng từ Lazada"
              reset={_resetStateImport}
              importPdf={<UploadPdf />}
              colorCode="#F57224"
              txt="Nhập excel từ Lazada"
              keyForm={{
                links: filesUpload || [],
                warehouse_id: warehouse || '',
                customer_id: customer || '',
              }}
              width="95%"
              title={
                <Row wrap={false} align="middle">
                  <div style={{ marginRight: 20, fontWeight: 600 }}>
                    Nhập phiếu xuất kho bằng file excel từ Lazada
                  </div>
                  <div style={{ marginRight: 15 }}>
                    <div style={{ fontSize: 13 }}>Chọn doanh nghiệp</div>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      value={customer}
                      onChange={setCustomer}
                      placeholder="Chọn doanh nghiệp"
                      size="small"
                      style={{ width: 250 }}
                      allowClear
                    >
                      {customers.map((customer, index) => (
                        <Select.Option key={index} value={customer.customer_id}>
                          {customer.fullname}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                  <div>
                    <div style={{ fontSize: 13 }}>Chọn kho</div>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      value={warehouse}
                      onChange={setWarehouse}
                      placeholder="Chọn kho"
                      size="small"
                      style={{ width: 250 }}
                      allowClear
                    >
                      {warehouses.map((warehouse, index) => (
                        <Select.Option key={index} value={warehouse.warehouse_id}>
                          {warehouse.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </Row>
              }
              fileTemplated="https://s3.ap-northeast-1.wasabisys.com/admin-order/2022/02/13/116c2c8e-4829-47ab-9a01-d406ec9820f9/don-hang.xlsx"
              upload={importFileOrderFromLazada}
              reload={_getExportOrders}
            />
          </Space> */}
        </Row>
      </Permission>
      <div className={styles['importProduct-content']}>
        <Row style={{ marginBottom: 20 }} gutter={[15, 10]}>
          <Col span={6}>
            <Input
              allowClear
              ref={scanRef}
              value={valueScan}
              placeholder={
                scannerIsOn ? 'Quét tìm mã đơn hàng' : 'Nhấn vào đây để bật máy quét (F1)'
              }
              onChange={_onScan}
              onFocus={(e) => setScannerIsOn(true)}
              onBlur={(e) => setScannerIsOn(false)}
            />
          </Col>
          <Col span={5}>
            <Select
              allowClear
              style={{ width: '100%' }}
              placeholder="Lọc theo doanh nghiệp"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              suffixIcon={<SuffixIconCustom />}
              onChange={(value) => _filterByOption('customer_id', value)}
              value={filters['customer_id'] || undefined}
            >
              {customers.map((e) => (
                <Select.Option value={e.customer_id}>{e.fullname}</Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={5}>
            <Select
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
              }
              style={{ width: '100%' }}
              placeholder="Lọc theo trạng thái"
              suffixIcon={<SuffixIconCustom />}
              onChange={(value) => _filterByOption('export_status', value)}
              value={filters['export_status'] || undefined}
            >
              <Select.Option value="WAITING">Chưa xuất vị trí</Select.Option>
              <Select.Option value="PROCESSING">Đang thực hiện</Select.Option>
              <Select.Option value="COMPLETE">Đã xuất vị trí</Select.Option>
              <Select.Option value="REPORT">Có sự cố</Select.Option>
            </Select>
          </Col>
          <Col span={5}>
            <FilterDate paramsFilter={filters} setParamsFilter={setFilters} />
          </Col>
          <Col span={3}>
            {Object.keys(filters).length > 5 && (
              <Button danger type="primary" onClick={() => _clearFilters(filters.time_receive)}>
                Xóa bộ lọc
              </Button>
            )}
          </Col>
          <Col span={11}>
            <Input.Search
              allowClear
              enterButton
              value={valueSearch}
              placeholder="Tìm kiếm theo mã đơn hàng"
              onChange={_onSearch}
            />
          </Col>
        </Row>
        <Row justify="space-between">
          <Col>
            <div style={{ display: 'flex', gap: '10px', marginLeft: '10px', alignItems: 'center' }}>
              {indicators.map((indicator, index) => {
                return (
                  <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <div
                      style={{
                        width: '15px',
                        height: '15px',
                        backgroundColor: indicator.bgColor,
                        borderRadius: '50%',
                      }}
                    ></div>
                    <div>{indicator.title}</div>
                  </div>
                )
              })}
            </div>
          </Col>
          <Col>
            <p style={{ fontSize: '0.88rem', marginBottom: 0 }}>
              * Đơn hàng sẽ tự động chuyển trạng thái hoàn thành khi xuất đủ số lượng sản phẩm
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className={styles['importProduct-content--tab']}>
              <TabsTime
                reload={() => _getExportOrders()}
                timeTabs={timeTabs || []}
                data={exportOrdersList}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                updateExport={updateExport}
                confirmExportOrders={confirmExportOrders}
                setExportOrdersChecked={setExportOrdersChecked}
                exportOrdersChecked={exportOrdersChecked}
                countOrder={countOrder}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
