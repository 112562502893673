const columns = [
  {
    key: 'stt',
    title: 'STT',
    dataIndex: 'stt',
    align: 'center',
    width: 50,
  },
  {
    key: 'fee_name',
    title: 'Tên chi phí',
    dataIndex: 'fee_name',
    align: 'center',
  },
  {
    key: 'fee',
    title: 'Đơn giá',
    align: 'center',
    dataIndex: 'fee',
  },
  {
    key: 'unit',
    title: 'Đơn vị áp dụng',
    align: 'center',
  },
  {
    dataIndex: 'min_quantity',
    title: 'CBM áp dụng tối thiểu',
    align: 'center',
  },
  {
    dataIndex: 'max_quantity',
    title: 'CBM áp dụng tối đa',
    align: 'center',
  },
  {
    dataIndex: 'description',
    title: 'Mô tả',
    align: 'center',
  },
  {
    key: 'create_date',
    title: 'Ngày tạo',
    dataIndex: 'create_date',
    align: 'center',
    width: 100,
  },
  {
    key: 'action',
    title: 'Hành động',
    width: 60,
    align: 'center',
  },
]
export default columns
