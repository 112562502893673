import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { _addLog } from 'utils'
import { PERMISSIONS } from 'consts'
import { useDispatch } from 'react-redux'
import styles from 'views/ingredient/ingredient.module.scss'

//components
import Permission from 'components/permission'

//antd
import { ArrowLeftOutlined } from '@ant-design/icons'
import {
  Input,
  Row,
  Form,
  Col,
  Button,
  InputNumber,
  notification,
  Space,
  Checkbox,
  Select,
} from 'antd'

//apis
import { createExpense, updateExpense } from 'apis/expense'
import { enumExpense } from 'apis/enum'

export default function IngredientAdd() {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const [isFeeExceeding, setIsFeeExceeding] = useState(false)
  const [units, setUnits] = useState([])

  const _getEnumExpense = async () => {
    try {
      const res = await enumExpense()
      if (res.status === 200) setUnits(res.data.data)
    } catch (error) {}
  }

  const _addOrUpdateExpense = async () => {
    await form.validateFields()
    const formData = form.getFieldsValue()

    if (formData.key.search(/[^a-zA-Z]+/) !== -1) {
      notification.error({ message: 'Vui lòng nhập phím tắt là ký tự chữ' })
      return
    }

    const body = {
      ...formData,
      coefficient: formData.coefficient || 0,
      minimum_fee: formData.minimum_fee || 0,
      tax_value: formData.tax_value || 0,
    }

    dispatch({ type: 'LOADING', data: true })
    let res
    if (location.state) res = await updateExpense(location.state.expense_id, body)
    else res = await createExpense(body)
    dispatch({ type: 'LOADING', data: false })
    try {
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({
            message: (location.state ? 'Cập nhật' : 'Tạo') + ' phụ liệu thành công!',
          })
          history.goBack()
        } else {
          notification.error({
            message:
              res.data.message || (location.state ? 'Cập nhật' : 'Tạo') + ' phụ liệu thất bại',
          })
        }
      } else {
        notification.error({
          message: res.data.message || (location.state ? 'Cập nhật' : 'Tạo') + ' phụ liệu thất bại',
        })
      }
    } catch (error) {
      dispatch({ type: 'LOADING', data: false })
      notification.error({ message: (location.state ? 'Cập nhật' : 'Tạo') + ' phụ liệu thất bại' })
      _addLog(`Add expense: ${JSON.stringify(error)}`)
    }
  }

  useEffect(() => {
    if (location.state) form.setFieldsValue(location.state)
    _getEnumExpense()
  }, [])

  return (
    <div style={{ paddingBottom: '20px' }}>
      <Row className="page-title" justify="space-between" style={{ marginBottom: '25px' }}>
        <Row align="middle" wrap={false}>
          <h3 style={{ cursor: 'pointer' }} onClick={() => history.goBack()}>
            <ArrowLeftOutlined style={{ marginRight: '10px' }} />
            {location.state ? `Cập nhật` : 'Tạo'} phụ liệu{' '}
            <b>{location.state && `#${location.state.expense_name}`}</b>
          </h3>
        </Row>
        <Space>
          <Button
            danger
            type="primary"
            style={{ minWidth: '100px' }}
            onClick={() => history.goBack()}
          >
            Hủy
          </Button>
          <Permission
            permissions={
              location.state ? [PERMISSIONS.cap_nhat_phu_lieu] : [PERMISSIONS.tao_phu_lieu]
            }
          >
            <Button
              type="primary"
              style={{ minWidth: '100px' }}
              onClick={_addOrUpdateExpense}
              className={styles['ingredient-button--orange']}
            >
              {location.state ? 'Lưu' : 'Tạo'}
            </Button>
          </Permission>
        </Space>
      </Row>
      <Form form={form} style={{ margin: '0 50px' }} layout="vertical">
        <Row gutter={100} style={{ marginBottom: 20 }}>
          <Col span={12}>
            <Form.Item
              name="expense_name"
              label="Tên chi phí"
              rules={[{ required: true, message: 'Vui lòng nhập tên chi phí' }]}
            >
              <Input allowClear placeholder="Nhập tên chi phí" />
            </Form.Item>
            <Form.Item
              name="fee"
              label="Đơn giá"
              rules={[{ required: true, message: 'Vui lòng nhập đơn giá' }]}
            >
              <InputNumber
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: '100%' }}
                min={0}
                placeholder="Nhập đơn giá"
              />
            </Form.Item>
            <Form.Item
              className={styles['title-input']}
              initialValue={0}
              name="minimum_fee"
              label="Đơn giá tối thiểu"
            >
              <InputNumber
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: '100%' }}
                min={0}
                placeholder="Nhập đơn giá tối thiểu"
              />
            </Form.Item>
            <Form.Item
              className={styles['title-input']}
              initialValue={0}
              name="coefficient"
              label="Hệ số cơ bản"
            >
              <InputNumber
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: '100%' }}
                placeholder="Nhập hệ số cơ bản"
              />
            </Form.Item>
            <Form.Item
              className={styles['title-input']}
              initialValue={0}
              name="tax_value"
              label="Thuế"
            >
              <InputNumber
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: '100%' }}
                placeholder="Nhập thuế"
              />
            </Form.Item>
            <div>
              <Checkbox
                style={{ marginTop: 15, fontSize: 16 }}
                onChange={(e) => setIsFeeExceeding(e.target.checked)}
                checked={isFeeExceeding}
              >
                Tính phí ngoài hạn mức
              </Checkbox>
              {isFeeExceeding && (
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="SL áp dụng vượt mức"
                      name="exceeding_quantity"
                      rules={[{ required: true, message: 'Vui lòng nhập SL áp dụng vượt mức' }]}
                    >
                      <InputNumber
                        allowClear
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        style={{ width: '100%' }}
                        min={0}
                        placeholder="Nhập SL áp dụng vượt mức"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Đơn giá vượt mức"
                      name="fee_exceeding"
                      rules={[{ required: true, message: 'Vui lòng nhập đơn giá vượt mức' }]}
                    >
                      <InputNumber
                        allowClear
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        style={{ width: '100%' }}
                        min={0}
                        placeholder="Nhập đơn giá vượt mức"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </div>
          </Col>
          <Col span={12}>
            <Form.Item
              name="unit"
              label="Đơn vị áp dụng"
              // rules={[{ required: true, message: 'Vui lòng chọn đơn vị áp dụng' }]}
            >
              <Select showSearch placeholder="Chọn đơn vị áp dụng">
                {units.map((unit) => (
                  <Select.Option key={unit.value} value={unit.value}>
                    {unit.name} {unit.extension ? `(${unit.extension})` : ''}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {/* <Form.Item
              name="fee_exceeding"
              label={
                <label style={{ fontWeight: '500', fontSize: '16px' }}>Chi phí vượt mức</label>
              }
            >
              <InputNumber
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: '100%' }}
                min={0}
                placeholder="Nhập chi phí vượt mức"
              />
            </Form.Item> */}
            <Form.Item name="description" label="Mô tả" className={styles['title-input']}>
              <Input.TextArea rows={2} allowClear placeholder="Nhập mô tả" />
            </Form.Item>
            <div>
              <Form.Item
                name="key"
                label="Phím tắt"
                className={styles['title-input']}
                rules={[{ required: true, message: 'Vui lòng nhập phím tắt' }]}
              >
                <Input maxLength={1} allowClear placeholder="Nhập phím tắt" />
              </Form.Item>
              <div style={{ color: 'red' }}>
                * Lưu ý: Nhập phím tắt là ký tự chữ, ví dụ: a, b, c,...
              </div>
            </div>
            <br />
            <Row justify="end">
              <Space>
                <Form.Item name="default_in_import_order" valuePropName="checked">
                  <Checkbox>Mặc định sử dụng trên phiếu nhập</Checkbox>
                </Form.Item>
                <Form.Item name="default_in_export_order" valuePropName="checked">
                  <Checkbox>Mặc định sử dụng trên phiếu xuất</Checkbox>
                </Form.Item>
              </Space>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
