import React, { useEffect, useState, useRef } from 'react'
import styles from './customers.module.scss'
import { PAGE_SIZE_OPTIONS, PERMISSIONS, POSITION_TABLE, ROUTES, SIZE_TABLE } from 'consts'
import { useHistory } from 'react-router-dom'
import { formatCash, _addLog, saveFiltersToLocalStorage, isAcceptPermissions } from 'utils'
import { useSelector } from 'react-redux'

// antd
import { Button, Col, Row, Select, Table, Space, Tag, notification, Input, Modal } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { DeleteOutlined, ReloadOutlined } from '@ant-design/icons'

//components
import SuffixIconCustom from 'components/suffixIconCustom'
import Permissions from 'components/permission'
import customerColumns from './customerColumns.js'
import feeCustomerColumns from './feeCustomerColumns.js'
import SettingColumnsButton from 'components/setting-columns'
import BackToTop from 'components/back-to-top'
import Permission from 'components/permission'
import FilterDate from 'components/filter-date'

//apis
import { getAllCustomer, deleteCustomer } from 'apis/customer'
import { getWard, getProvince, getDistrict } from 'apis/address'
import { getTypesCustomer } from 'apis/type-customer'
import { login } from 'apis/auth'
import moment from 'moment'

const { Option } = Select
export default function Customer() {
  const history = useHistory()
  const dataUser = useSelector((state) => state.user)
  const KEY_FILTER = 'customerFilters'
  const filters = localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))

  const [customers, setCustomers] = useState([])

  const [paramsFilter, setParamsFilter] = useState(
    filters ? { ...filters } : { page: 1, page_size: 100, type_info: true }
  )
  const [countCustomer, setCountCustomer] = useState(0)
  const [valueSearch, setValueSearch] = useState(filters ? filters.fullname || '' : '')
  const typingTimeoutRef = useRef(null)

  const [tableLoading, setTableLoading] = useState(false)
  const [columns, setColumns] = useState([])

  const [typesCustomer, setTypesCustomer] = useState([])

  const [valueProvince, setValueProvince] = useState()
  const [valueDistrict, setValueDistrict] = useState()

  const [dataProvince, setDataProvince] = useState([])
  const [dataDistrict, setDataDistrict] = useState([])
  const [dataWard, setDataWard] = useState([])

  const _getCustomers = async () => {
    try {
      setTableLoading(true)
      setCustomers([])
      const res = await getAllCustomer(paramsFilter)
      if (res.status === 200) {
        const data = res.data.data
        const counts = res.data.counts
        setCustomers(data)

        setCountCustomer(counts)
      }
      setTableLoading(false)
    } catch (error) {
      setTableLoading(false)
      console.log(error)
      _addLog(`get customers: ${JSON.stringify(error)}`)
    }
  }

  const _getDataProvince = async () => {
    try {
      const res = await getProvince()
      if (res.status === 200) setDataProvince(res.data.data)
    } catch (error) {
      console.log(error)
      _addLog(`get province: ${JSON.stringify(error)}`)
    }
  }

  const _getDataDistrict = async (params) => {
    try {
      let res
      if (params) {
        res = await getDistrict(params)
      } else {
        res = await getDistrict()
      }
      if (res.status === 200) setDataDistrict(res.data.data)
    } catch (error) {
      _addLog(`get district: ${JSON.stringify(error)}`)
      console.log(error)
    }
  }

  const _getDataWard = async (params) => {
    try {
      let res
      if (params) {
        res = await getWard(params)
      } else {
        res = await getWard()
      }
      if (res.status === 200) setDataWard(res.data.data)
    } catch (error) {
      console.log(error)
      _addLog(JSON.stringify(error))
    }
  }

  const _getTypesCustomer = async () => {
    try {
      const res = await getTypesCustomer()
      if (res.status === 200) setTypesCustomer(res.data.data)
    } catch (error) {
      console.log(error)
      _addLog(JSON.stringify(error))
    }
  }

  const ModalConfirmDelete = ({ record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)

    const _confirmPassword = async () => {
      try {
        if (!password) {
          notification.warning({ message: 'Vui lòng nhật mật khẩu' })
          return
        }
        const body = {
          username: dataUser && dataUser.username,
          password: password,
        }
        setLoading(true)
        const res = await login(body)
        if (res.status === 200) {
          if (res.data.success) {
            await _deleteRecord(record.customer_id)
            toggle()
          } else notification.error({ message: res.data.message || 'Sai mật khẩu' })
        } else notification.error({ message: res.data.message || 'Sai mật khẩu' })
        setLoading(false)
      } catch (error) {
        setLoading(false)
        _addLog(`Confirm password: ${JSON.stringify(error)}`)
      }
    }

    useEffect(() => {
      if (!visible) setPassword('')
    }, [visible])

    return (
      <>
        <DeleteOutlined onClick={toggle} style={{ fontSize: 18, color: 'red' }} />
        <Modal
          onCancel={toggle}
          visible={visible}
          title={
            <div>
              Nhập mật khẩu để xóa doanh nghiệp <b>#{record && record.code}</b>
            </div>
          }
          footer={
            <Row justify="end">
              <Space>
                <Button onClick={toggle}>Hủy</Button>
                <Button type="primary" onClick={_confirmPassword} loading={loading}>
                  Xóa
                </Button>
              </Space>
            </Row>
          }
        >
          <Input.Password
            autoFocus
            onPressEnter={_confirmPassword}
            allowClear
            placeholder="Nhập mật khẩu"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />

          <div style={{ color: 'red', marginTop: 15 }}>
            * Lưu ý: Chỉ có quản lí mới được xoá doanh nghiệp, hành động này sẽ lưu lại lịch sử
          </div>
        </Modal>
      </>
    )
  }

  const _deleteRecord = async (id) => {
    try {
      setTableLoading(true)
      const res = await deleteCustomer({ customer_id: [id] })
      setTableLoading(false)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Xóa doanh nghiệp thành công !' })
          _getCustomers()
        } else
          notification.error({
            message: res.data.message || 'Xóa doanh nghiệp thất bại !',
          })
      } else
        notification.error({
          message: res.data.message || 'Xóa doanh nghiệp thất bại !',
        })
    } catch (err) {
      setTableLoading(false)
      console.log(err)
      _addLog(`Delete cusomter: ${JSON.stringify(err)}`)
    }
  }

  useEffect(() => {
    _getDataProvince()
    _getTypesCustomer()
  }, [])

  useEffect(() => {
    if (valueProvince) {
      _getDataDistrict({ province_name: valueProvince })
      _getDataWard({ province_name: valueProvince })
    } else {
      _getDataDistrict()
      _getDataWard()
    }
  }, [valueProvince])

  useEffect(() => {
    if (valueDistrict) {
      _getDataWard({ district_name: valueDistrict })
    } else {
      _getDataWard()
    }
  }, [valueDistrict])

  useEffect(() => {
    _getCustomers()
    saveFiltersToLocalStorage(paramsFilter, KEY_FILTER)
  }, [paramsFilter])

  const _search = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      const value = e.target.value
      //khi search hoac filter thi reset page ve 1
      paramsFilter.page = 1
      if (value) paramsFilter.fullname = value
      else delete paramsFilter.fullname
      setParamsFilter({ ...paramsFilter })
    }, 450)
  }

  const ModalViewFee = ({ record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    return (
      <>
        <span style={{ color: '#0017E3', cursor: 'pointer' }} onClick={toggle}>
          Xem chi tiết
        </span>
        <Modal
          width={650}
          footer={null}
          title="Danh sách chi phí"
          visible={visible}
          onCancel={toggle}
        >
          <div className={styles['modal-table']}>
            <Table
              summary={() => (
                <Table.Summary fixed="bottom">
                  <Table.Summary.Row></Table.Summary.Row>
                </Table.Summary>
              )}
              sticky
              centered
              rowKey="fee_id"
              pagination={false}
              size={SIZE_TABLE}
              dataSource={record.fees}
              style={{ width: '100%' }}
              columns={feeCustomerColumns.map((column) => {
                if (column.key === 'stt')
                  return {
                    ...column,
                    render: (text, record, index) => index + 1,
                  }
                if (column.key === 'fee')
                  return {
                    ...column,
                    render: (text) => (text ? formatCash(text) : ''),
                  }
                if (column.key === 'unit')
                  return {
                    ...column,
                    render: (text) => (text ? <span> {text.toUpperCase()}</span> : ''),
                  }
                return column
              })}
            />
          </div>
        </Modal>
      </>
    )
  }

  const _clearFilters = () => {
    setValueSearch('')
    setParamsFilter({ page: 1, page_size: 20, type_info: true })
    setValueDistrict()
    setValueProvince()
  }

  const _filterByOption = (attribute = '', value = '') => {
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter, page: 1 })
  }

  return (
    <div className={styles['customer']}>
      <BackToTop />
      <Row className="page-title" justify="space-between" style={{ marginBottom: '25px' }}>
        <h3>Quản lý doanh nghiệp</h3>
        <Space>
          <SettingColumnsButton
            columnsRender={customerColumns}
            columns={columns}
            setColumns={setColumns}
            nameColumn="columnsCustomer"
          />
          <Permissions permissions={[PERMISSIONS.tao_doanh_nghiep]}>
            <Button
              className={styles['customer-btn--create-customer']}
              onClick={() => history.push(ROUTES.CUSTOMERS_ADD)}
            >
              Tạo doanh nghiệp
            </Button>
          </Permissions>
        </Space>
      </Row>
      <Content>
        <div className={styles['customer-filter-group']}>
          <Row gutter={20} style={{ marginBottom: 20 }}>
            <Col span={7}>
              <Input.Search
                allowClear
                value={valueSearch}
                onChange={_search}
                className={styles['customer-filter-group-item']}
                placeholder="Tìm kiếm theo tên doanh nghiệp"
                enterButton
              />
            </Col>
            <Col span={6}>
              <Select
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(value) => {
                  setValueProvince(value)
                  _filterByOption('province', value)
                }}
                value={paramsFilter['province'] || undefined}
                className={styles['customer-filter-group-item']}
                style={{ width: '100%' }}
                placeholder="Lọc theo tỉnh/thành"
                suffixIcon={<SuffixIconCustom />}
              >
                {dataProvince.map((item, index) => (
                  <Option key={index} value={item.province_name}>
                    {item.province_name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={6}>
              <Select
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(value) => {
                  setValueDistrict(value)
                  _filterByOption('district', value)
                }}
                value={paramsFilter['district'] || undefined}
                className={styles['customer-filter-group-item']}
                style={{ width: '100%' }}
                placeholder="Lọc theo quận/huyện"
                suffixIcon={<SuffixIconCustom />}
              >
                {dataDistrict.map((item, index) => (
                  <Option key={index} value={item?.district_name}>
                    {item?.district_name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={5}>
              <Select
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(value) => _filterByOption('ward', value)}
                value={paramsFilter['ward'] || undefined}
                className={styles['customer-filter-group-item']}
                style={{ width: '100%' }}
                placeholder="Lọc theo phường/xã"
                suffixIcon={<SuffixIconCustom />}
              >
                {dataWard.map((item, index) => (
                  <Option key={index} value={item?.ward_name}>
                    {item?.ward_name}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={7}>
              <FilterDate
                placeholder="Lọc theo thời gian tạo"
                paramsFilter={paramsFilter}
                setParamsFilter={setParamsFilter}
              />
            </Col>
            <Col span={6}>
              <Select
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(value) => _filterByOption('type_id', value)}
                value={paramsFilter['type_id'] || undefined}
                className={styles['customer-filter-group-item']}
                style={{ width: '100%' }}
                placeholder="Lọc theo loại doanh nghiệp"
                suffixIcon={<SuffixIconCustom />}
              >
                {typesCustomer.map((item, index) => (
                  <Option key={index} value={item.type_id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={11} style={{ display: 'flex', justifyContent: 'end' }}>
              <Space>
                <Button
                  className={styles['customer-btn-group--clear']}
                  onClick={_clearFilters}
                  type="primary"
                  style={{
                    display: Object.keys(paramsFilter).length <= 3 && 'none',
                  }}
                >
                  Xóa bộ lọc
                </Button>
                <Button onClick={_getCustomers} type="primary" icon={<ReloadOutlined />}>
                  Làm mới
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <Row justify="end"></Row>
        <Table
          summary={() => (
            <Table.Summary fixed="bottom">
              <Table.Summary.Row></Table.Summary.Row>
            </Table.Summary>
          )}
          sticky
          loading={tableLoading}
          columns={columns.map((column) => {
            if (column.key === 'active')
              return {
                ...column,
                render: (text, record) =>
                  record.active ? (
                    <Tag color="green">Active</Tag>
                  ) : (
                    <Tag color="magenta">Inactive</Tag>
                  ),
              }
            if (column.key === 'action')
              return {
                ...column,
                render: (text, record) => (
                  <Permission permissions={[PERMISSIONS.xoa_doanh_nghiep]}>
                    <ModalConfirmDelete record={record} />
                  </Permission>
                ),
              }
            if (column.key === 'fullname') {
              return {
                ...column,
                render: (text, record) =>
                  isAcceptPermissions([PERMISSIONS.cap_nhat_doanh_nghiep]) ? (
                    <span
                      style={{ color: '#0017E3', cursor: 'pointer' }}
                      onClick={() =>
                        history.push({ pathname: ROUTES.CUSTOMERS_ADD, state: record })
                      }
                    >
                      {text}
                    </span>
                  ) : (
                    <b>{text}</b>
                  ),
              }
            }

            if (column.key === 'type')
              return {
                ...column,
                render: (text, record) => record?.type_info && record?.type_info.name,
              }
            if (column.key === 'warehouse')
              return {
                ...column,
                render: (text, record) => record.warehouse_info && record.warehouse_info.name,
              }
            if (column.key === 'fees')
              return {
                ...column,
                render: (text, record) => <ModalViewFee record={record} />,
              }
            if (column.key === 'stt')
              return {
                ...column,
                render: (text, record, index) =>
                  (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
              }
            if (column.key === 'create_date')
              return {
                ...column,
                render: (text, record) =>
                  record.create_date && moment(record.create_date).format('DD-MM-YYYY'),
              }

            if (column.key === 'address')
              return {
                ...column,
                render: (text, record) =>
                  text || record?.ward || record?.district || record?.province
                    ? `${text}, ${record?.ward}, ${record?.district}, ${record?.province}`
                    : '',
              }
            return column
          })}
          scroll={{ y: '65vh' }}
          size={SIZE_TABLE}
          dataSource={customers}
          pagination={{
            position: POSITION_TABLE,
            current: paramsFilter.page,
            pageSize: paramsFilter.page_size,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            total: countCustomer,
            showQuickJumper: true,
            onChange: (page, pageSize) => {
              paramsFilter.page = page
              paramsFilter.page_size = pageSize

              setParamsFilter({ ...paramsFilter })
            },
          }}
        />
      </Content>
    </div>
  )
}
