const inventoryColumns = [
  {
    key: 'stt',
    title: 'STT',
    width: 70,
    fixed: 'flex',
  },
  {
    title: 'Số phiếu',
    dataIndex: 'customer_order_code',
    fixed: 'flex',
  },
  {
    title: 'Biên lai',
    key: 'shipping_bill',
  },
  {
    title: 'Ngày nhập kho',
    key: 'date',
  },
  {
    title: 'Số CT',
    dataIndex: 'code',
  },
  {
    title: 'Ngày CT',
    key: 'date_ct',
  },
  {
    title: 'Mã Doanh nghiệp',
    key: 'customer_code',
  },
  {
    title: 'Doanh nghiệp',
    key: 'customer_name',
  },
  {
    title: 'Địa chỉ',
    key: 'customer_address',
  },
  {
    title: 'Nội dung',
  },
  {
    title: 'Mã Loại CT',
    key: 'code_ct',
  },
  {
    title: 'Tên Loại CT',
    dataIndex: 'Tên Loại CT',
  },
  {
    title: 'Tài khoản',
    dataIndex: 'Tài khoản',
  },
  {
    title: 'Kho hàng',
    key: 'warehouse',
  },
  {
    title: 'Chi tiết',
    dataIndex: 'Chi tiết',
  },
  {
    title: 'Mã hàng',
    key: 'product_code',
  },
  {
    title: 'Tên hàng',
    key: 'product_name',
  },
  {
    title: 'Đơn vị',
    key: 'product_unit',
  },
  {
    title: 'Số lượng',
    key: 'product_quantity',
  },
  {
    title: 'Đơn giá',
    key: 'product_price',
  },
  {
    title: 'Số tiền',
    key: 'Số tiền',
  },
  {
    title: 'Thuế GTGT',
    dataIndex: 'Thuế GTGT',
  },
  {
    title: 'Cộng',
    dataIndex: 'Cộng',
  },
  {
    title: 'Thuế GTGT',
    dataIndex: 'Thuế GTGT',
  },
  {
    title: 'Nội dung',
    dataIndex: 'Nội dung',
  },
  {
    title: 'TK đối ứng',
    dataIndex: 'TK đối ứng',
  },
  {
    title: 'CT đối ứng',
    dataIndex: 'CT đối ứng',
  },
  {
    title: 'BP đối ứng',
    dataIndex: 'BP đối ứng',
  },
  {
    title: 'Hàng bán trả lại',
    dataIndex: 'Hàng bán trả lại',
  },
  {
    title: 'Đơn giá bán',
    dataIndex: 'Đơn giá bán',
  },
  {
    title: 'Tiền bán',
    dataIndex: 'Tiền bán',
  },
  {
    title: 'Loại hàng',
    key: 'type_code',
  },
  {
    title: 'Tên Loại hàng',
    key: 'type_name',
  },
  {
    title: 'Nhóm hàng',
    key: 'group_code',
  },
  {
    title: 'Tên Nhóm hàng',
    key: 'group_name',
  },
  {
    title: 'Bộ phận',
    dataIndex: 'Bộ phận',
  },
]

export default inventoryColumns
