import { useEffect, useState, useLayoutEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import styles from './package.module.scss'
import { useDispatch } from 'react-redux'
import { formatCash, _addLog, saveFiltersToLocalStorage, getFileName } from 'utils'
import moment from 'moment'
import { PAGE_SIZE_OPTIONS, ROUTES, PRINTER, ACTION, PERMISSIONS, IMAGE_DEFAULT } from 'consts'
import KeyboardEventHandler from 'react-keyboard-event-handler'
import { useReactToPrint } from 'react-to-print'
import delay from 'delay'
import printJS from 'print-js'

//antd
import {
  Input,
  Row,
  Col,
  Select,
  Button,
  Table,
  Collapse,
  Modal,
  Badge,
  Space,
  Popconfirm,
  notification,
  Spin,
  Popover,
  Upload,
} from 'antd'

//icons
import {
  DeleteOutlined,
  InboxOutlined,
  LoadingOutlined,
  PrinterOutlined,
  ReloadOutlined,
} from '@ant-design/icons'
import iconPrint from 'assets/icons/icon-printer.png'

//components
import SuffixIconCustom from 'components/suffixIconCustom'
import printToThermalPrinter from 'components/thermal-printer/print'
import { barcode } from 'components/thermal-printer/templates'
import ModalReasonOrder from 'components/modal-reason-order'
import ButtonPrintPackage from './ButtonPrintPackage'
import ModalOptionsPrint from 'components/modal-opions-print'
import Permission from 'components/permission'
import ModalFees from 'components/modal-fees'
import FilterDate from 'components/filter-date'
import { ComponentPrintBill } from './ButtonPrintPackage'
import ZoomImage from 'components/zoom-image'
import ModalConfirmDelete from 'components/modal-confirm-delete'
import QrCodeProduct from 'components/qr-code-product'

//apis
import {
  cancelBaskets,
  deleteExportOrders,
  getExportOrders,
  getTrackingNumber,
  updateExportOrders,
} from 'apis/export-order'
import { getAllCustomer } from 'apis/customer'
import { getExpense } from 'apis/expense'
import { getPrint } from 'apis/print'
import { enumPackage, enumPlatform } from 'apis/enum'
import { getFees } from 'apis/fee'
import { uploadFile } from 'apis/upload'
import { reportMissingPackage } from 'apis/report'

export default function Pack() {
  const typingTimeoutRef = useRef()
  const scanRef = useRef()
  const printRef = useRef()
  const history = useHistory()
  const dispatch = useDispatch()
  const KEY_FILTER = 'packageFilters'
  const ID_DIV_PRINT = 'print-tracking-number'
  const filters = localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))

  const [reasons, setReasons] = useState([])
  const [orders, setOrders] = useState([])
  const [order, setOrder] = useState({})
  const [orderExpend, setOrderExpend] = useState()
  const [customers, setCustomers] = useState([])
  const [expenses, setExpenses] = useState([])
  const [fees, setFees] = useState([])
  const [platforms, setPlatforms] = useState([])

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [paramsFilter, setParamsFilter] = useState(
    filters
      ? { ...filters }
      : {
          page: 1,
          page_size: 10,
          time_receive: new Date().getHours(),
          pack_status: 'WAITING',
        }
  )
  const [loading, setLoading] = useState(false)
  const [countOrder, setCountOrder] = useState(0)
  const [valueSearch, setValueSearch] = useState(filters ? filters.search || '' : '')
  const [valueScan, setValueScan] = useState('')

  const [countOrderOverTime, setCountOrderOverTime] = useState([])
  const [scannerIsOn, setScannerIsOn] = useState(false)

  const [packagesPrint, setPackagesPrint] = useState([])
  const [orderPrint, setOrderPrint] = useState({})
  const [loadingDownload, setLoadingDownload] = useState(false)

  const _print = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => {
      setPackagesPrint([])
    },
  })

  const handlePrint = async (pkgList = [], order = {}) => {
    setPackagesPrint(() => pkgList)
    setOrderPrint(() => order)
    await delay(100)
    _print()
    const packagesNew = order.packages.map((pkg) => {
      const pkgFind = pkgList.find((p) => p.package_code === pkg.package_code)
      if (pkgFind) return { ...pkg, is_print_bill: true }
      return { ...pkg }
    })

    await _updatePackageOfOrder(order.order_id, packagesNew)
    _getOrders()
  }

  const _filterByOption = (attribute = '', value = '') => {
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter, page: 1 })
  }

  const _onScan = (e) => {
    setValueScan(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      const value = e.target.value.replace('#', '')
      if (value) {
        try {
          setLoading(true)
          const resCode = await getExportOrders({ packed_scan: value })
          // const resBasket = await getExportOrders({ basket_code: value, is_packed: true })
          setLoading(false)

          if (resCode.status === 200)
            if (resCode.data.data && resCode.data.data.length) {
              const orderScan = await _getOrderDetail(resCode.data.data[0].order_id)
              if (orderScan)
                history.push({
                  pathname: ROUTES.PACKAGE_SCAN,
                  state: { order: orderScan, expense: {} },
                })
              return
            }

          // if (resBasket.status === 200)
          //   if (resBasket.data.data && resBasket.data.data.length) {
          //     const orderScan = await _getOrderDetail(resBasket.data.data[0].order_id)
          //     if (orderScan) {
          //       history.push({
          //         pathname: ROUTES.PACKAGE_SCAN,
          //         state: { order: orderScan, expense: {} },
          //       })
          //     }

          //     return
          //   }

          notification.error({
            message: `Không tìm thấy đơn hàng hoặc thùng đựng của mã #${value}`,
          })
          setValueScan('')
        } catch (error) {
          setLoading(false)
          _addLog(`Search export order: ${JSON.stringify(error)}`)
        }
      }
    }, 650)
  }

  const _onSearch = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      const value = e.target.value
      if (value) paramsFilter.search = value
      else delete paramsFilter.search
      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 650)
  }

  const HandlerKeyboard = () => {
    return (
      <KeyboardEventHandler
        handleKeys={['f1']}
        onKeyEvent={(key, e) => {
          switch (key) {
            case 'f1': {
              if (scanRef && scanRef.current) scanRef.current.focus()
              break
            }

            default:
              break
          }
        }}
      />
    )
  }

  const _cancelBaskets = async (code) => {
    try {
      const body = { order_code: code }
      const res = await cancelBaskets(body)
      if (res.status === 200) {
        if (res.data.success) {
          _getOrders()
          notification.success({ message: 'Xóa liên kết đơn hàng khỏi các thùng đựng thành công' })
        } else notification.error({ message: res.data.message })
      } else notification.error({ message: res.data.message })
    } catch (err) {
      _addLog(JSON.stringify(err))
      console.log(err)
    }
  }

  const BillOrder = ({ record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    const shippingBill =
      record && record.shipping_info
        ? record.shipping_info.shipping_bill &&
          typeof record.shipping_info.shipping_bill === 'string'
          ? [record.shipping_info.shipping_bill]
          : record.shipping_info.shipping_bill || []
        : []

    const [images, setImages] = useState([])
    const [imagesView, setImagesView] = useState([])
    const [loading, setLoading] = useState(false)

    const addFile = async (file) => {
      setLoading(true)
      const url = await uploadFile(file)
      setImages([url])
      const fileNames = url.split('/')
      const fileName = fileNames[fileNames.length - 1]
      setImagesView([
        { uid: imagesView.length, name: fileName, status: 'done', url: url, thumbUrl: url },
      ])
      setLoading(false)
    }

    const _removeFile = (file) => {
      const imagesNew = [...images]
      const imagesViewNew = [...imagesView]

      const indexImage = images.findIndex((url) => url === file.url)
      const indexImageView = imagesView.findIndex((f) => f.url === file.url)

      if (indexImage !== -1) imagesNew.splice(indexImage, 1)
      if (indexImageView !== -1) imagesViewNew.splice(indexImageView, 1)

      setImages([...imagesNew])
      setImagesView([...imagesViewNew])
    }

    useEffect(() => {
      if (visible) {
        setImages(shippingBill)
        setImagesView(
          shippingBill.map((image, index) => ({
            uid: index,
            name: getFileName(image),
            status: 'done',
            url: image,
            thumbUrl: image,
          }))
        )
      }
    }, [visible])

    return (
      <>
        <Modal
          footer={
            <Row justify="end">
              <Space>
                <Button style={{ minWidth: 100 }} onClick={toggle}>
                  Đóng
                </Button>
                <Button
                  onClick={async () => {
                    await _updateShippingBill(record.order_id, {
                      ...record,
                      shipping_info: { ...record.shipping_info, shipping_bill: images },
                    })
                    toggle()
                  }}
                  style={{ minWidth: 100 }}
                  type="primary"
                >
                  Lưu
                </Button>
              </Space>
            </Row>
          }
          width={700}
          onCancel={toggle}
          title="Tải lên hình ảnh biên lai"
          visible={visible}
        >
          <Upload.Dragger
            accept="image/*"
            fileList={imagesView}
            listType="picture"
            data={addFile}
            onRemove={_removeFile}
            name="file"
            onChange={(info) => {
              if (info.file.status !== 'done') info.file.status = 'done'
            }}
          >
            <p className="ant-upload-drag-icon">
              {loading ? <LoadingOutlined /> : <InboxOutlined />}
            </p>
            <p className="ant-upload-text">Nhấp hoặc kéo thả vào khu vực này để tải lên</p>
          </Upload.Dragger>
        </Modal>

        <Space>
          <div>
            <Permission permissions={[PERMISSIONS.cap_nhat_danh_sach_xuat_hang]}>
              <Button
                type="primary"
                onClick={toggle}
                style={{ backgroundColor: '#377BFF', borderColor: '#377BFF' }}
              >
                Hình ảnh biên lai
              </Button>
            </Permission>
          </div>
          <div>
            {shippingBill && shippingBill.length ? (
              <Popover
                placement="right"
                content={
                  <img src={shippingBill[0]} style={{ height: '250px', width: '250px' }} alt="" />
                }
              >
                <img src={shippingBill[0]} style={{ height: '30px', width: '30px' }} alt="" />
              </Popover>
            ) : (
              ''
            )}
          </div>
        </Space>
      </>
    )
  }

  const _printBarcodeRows = async (record) => {
    const params = {
      warehouse_id: record.warehouse_id,
      name: PRINTER.THUNG_DUNG,
    }
    const dataPrinter = await _getPrint(params)
    printToThermalPrinter(
      barcode,
      { barcode: record.code, title: 'Thung dung: ' + record.slug_name?.replace(/-{1,}/g, ' ') },
      dataPrinter && dataPrinter.ip
    )
  }

  const _deleteExport = async (id, note = '') => {
    try {
      const res = await deleteExportOrders({ order_id: [id], note: note })
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Xóa đơn hàng thành công' })
          _getOrders()
        } else notification.error({ message: res.data.message || 'Xóa đơn hàng không thành công' })
      } else notification.error({ message: res.data.message || 'Xóa đơn hàng không thành công' })
    } catch (err) {
      console.log(err)
    }
  }

  const ModalViewBasket = ({ record = {} }) => {
    const baskets = record.basket_info || []
    const [visible, setVisible] = useState(false)

    const columns = [
      {
        title: 'STT',
        render: (text, record, index) => index + 1,
        align: 'center',
        width: 50,
      },
      {
        title: 'Mã thùng đựng',
        dataIndex: 'code',
      },
      {
        title: 'Thời gian cập nhật',
        render: (text, record) =>
          record.last_update && moment(record.last_update).format('DD-MM-YYYY HH:mm'),
      },
      {
        width: 100,
        title: '',
        render: (text, record) => (
          <ModalOptionsPrint
            printWithInternet={() => _printBarcodeRows(record)}
            records={[record]}
            isQRCodeBox={true}
          >
            <img src={iconPrint} alt="" style={{ width: 25, height: 25, cursor: 'pointer' }} />
          </ModalOptionsPrint>
        ),
      },
    ]

    return (
      <>
        <Button className={styles['btn-list']} type="primary" onClick={() => setVisible(true)}>
          Danh sách thùng đựng
        </Button>
        <Modal
          style={{ top: 20 }}
          width={650}
          title="Danh sách thùng đựng"
          centered
          footer={null}
          visible={visible}
          onCancel={() => setVisible(false)}
        >
          <Popconfirm
            onConfirm={() => _cancelBaskets(record.code)}
            title="Bạn có muốn xóa liên kết đơn hàng khỏi các thùng đựng không?"
          >
            <Button type="primary" danger>
              Xóa liên kết
            </Button>
          </Popconfirm>
          <Table
            pagination={false}
            dataSource={baskets}
            columns={columns}
            size="small"
            style={{ width: '100%', marginTop: 10 }}
            scroll={{ y: '69vh' }}
          />
        </Modal>
      </>
    )
  }

  const columnsProductOfPackage = [
    {
      title: 'STT',
      render: (text, record, index) => index + 1,
      align: 'center',
      width: 50,
    },
    {
      title: 'Mã Sản phẩm',
      render: (text, record) => (record?.barcode ? record?.barcode : record?.code),
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
    },
    {
      key: 'warehouse',
      title: 'Kho',
      dataIndex: 'expiry_info',
      render: (text) => <>{text?.inventory_info?.warehouse_info?.name}</>,
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'images',
      render: (text) =>
        text &&
        text.length && (
          <ZoomImage image={text[0] || IMAGE_DEFAULT}>
            <img src={text[0] || IMAGE_DEFAULT} style={{ width: 50, height: 50 }} alt="" />
          </ZoomImage>
        ),
    },
    {
      title: 'Số lượng yêu cầu đóng gói',
      align: 'center',
      render: (text, record) =>
        record.require_quantity ? formatCash(record.require_quantity || 0) : 0,
    },
    {
      key: 'printBarcode',
    },
  ]

  const columnsProduct = [
    {
      title: 'STT',
      render: (text, record, index) => index + 1,
      align: 'center',
      width: 50,
    },
    {
      title: 'Mã Sản phẩm',
      render: (text, record) => (record?.barcode ? record?.barcode : record?.code),
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
    },
    {
      key: 'warehouse',
      title: 'Kho',
      dataIndex: 'expiry_info',
      render: (text) => <>{text?.inventory_info?.warehouse_info?.name}</>,
    },
    {
      title: 'ĐVT',
      render: (text, record) => record.unit_info && record.unit_info.name,
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'images',
      render: (text) =>
        text &&
        text.length && (
          <img src={text[0] || IMAGE_DEFAULT} style={{ width: 50, height: 50 }} alt="" />
        ),
    },
    {
      title: 'Số lượng yêu cầu xuất',
      align: 'center',
      render: (text, record) => formatCash(record.require_quantity || 0),
    },
    {
      title: 'Số lượng đã xuất kho',
      align: 'center',
      render: (text, record) => formatCash(record.export_quantity || 0),
    },
    {
      title: 'Số lượng đã đóng gói',
      align: 'center',
      render: (text, record) => formatCash(record.pack_quantity || 0),
    },
    {
      key: 'printBarcode',
    },
  ]

  const columnsExpense = [
    {
      title: 'STT',
      render: (text, record, index) => index + 1,
      align: 'center',
      width: 50,
    },
    {
      title: 'Mã phụ liệu',
      dataIndex: 'expense_code',
    },
    {
      title: 'Tên phụ liệu',
      dataIndex: 'expense_name',
    },
    {
      title: 'Chi phí',
      render: (text, record) => formatCash(record.fee || 0),
    },
    {
      width: 400,
      title: 'Mô tả',
      dataIndex: 'description',
    },
  ]

  const statusOrder = [
    {
      title: 'Chưa đóng gói',
      bgColor: '#FFCE62',
    },
    {
      title: 'Đang thực hiện',
      bgColor: '#80E9EF',
    },
    {
      title: 'Đã đóng gói',
      bgColor: '#79CF4F',
    },
    {
      title: 'Có sự cố',
      bgColor: '#D63F40',
    },
  ]

  const columnsOrder = [
    {
      render(data, record) {
        return (
          <div>
            <div>#{record.code}</div>
            <div>Số phiếu: #{record.customer_order_code}</div>
            <div>Mã vận đơn: {record.shipping_info && record?.shipping_info?.shipping_code}</div>
            {record.shipping_info && record.shipping_info.car_number && (
              <div style={{ fontSize: 16.5, fontWeight: 700 }}>
                Biển số xe: {record.shipping_info.car_number}
              </div>
            )}
            <div>Doanh nghiệp: {record.customer_info && record?.customer_info?.fullname}</div>
            <div>Nguồn: {record.platform}</div>
            <div>
              <BillOrder record={record} />
            </div>
            <div>
              {/* <ModalFees
                type="export"
                fees={fees}
                record={record}
                status={record.pack_status}
                reload={() => _getOrders()}
              /> */}
            </div>
          </div>
        )
      },
    },
    {
      render: (data, record) => (
        <Row>
          <QrCodeProduct value={record.code} />
        </Row>
      ),
    },
    {
      render: (data, record) => (
        <div style={{ display: record.pack_status === 'WAITING' && 'none' }}>
          Đóng gói: {record.pack_employee_info && record.pack_employee_info.fullname}
        </div>
      ),
    },
    {
      align: 'end',
      render(data, record) {
        return (
          <Space>
            <Space>
              <Button
                className={styles['btn-scan']}
                style={{ backgroundColor: '#EB7301', borderColor: '#EB7301' }}
                type="primary"
                onClick={async () => {
                  const orderScan = await _getOrderDetail(record.order_id)
                  if (orderScan)
                    history.push({
                      pathname: ROUTES.PACKAGE_SCAN,
                      state: { order: orderScan, expense: {} },
                    })
                }}
              >
                Bắt đầu đóng gói
              </Button>
              {record.pack_status !== 'COMPLETE' && (
                <Popconfirm
                  onConfirm={async () => {
                    if (record.pack_status === 'PROCESSING')
                      Modal.confirm({
                        title: 'Đơn hàng này đang có người thực hiện, bạn có muốn xác nhận không?',
                        onOk: () => _acceptPackageScan(record.order_id),
                      })
                    else _acceptPackageScan(record.order_id)
                  }}
                  title="Bạn có muốn xác nhận đóng gói đơn hàng này ?"
                  okText="Đồng ý"
                  cancelText="Từ chối"
                >
                  <Button className={styles['btn-confirm']} type="primary">
                    Xác nhận đóng gói
                  </Button>
                </Popconfirm>
              )}
              {record.pack_status !== 'COMPLETE' && (
                <div onClick={() => setOrder(record)}>
                  <ModalReasonOrder reasons={reasons} onReport={_onReport} />
                </div>
              )}
            </Space>
            <ModalViewBasket record={record} />

            <Button
              icon={<PrinterOutlined />}
              className={styles['btn-list']}
              type="primary"
              onClick={() => _getTrackingNumber(record)}
            >
              In vận đơn
            </Button>
            <Permission permissions={[PERMISSIONS.xoa_don_hang_xuat]}>
              <ModalConfirmDelete
                record={record}
                onDelete={(note) => _deleteExport(record.order_id, note)}
              >
                <Button type="primary" icon={<DeleteOutlined />} danger />
              </ModalConfirmDelete>
            </Permission>
          </Space>
        )
      },
    },
  ]

  const _onReport = async (reason) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const body = { pack_status: 'REPORT', processing: 'is_checked', package_reason: reason }

      const res = await updateExportOrders(order.order_id, body)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Báo cáo đơn hàng thành công' })
          history.goBack()
        } else
          notification.error({
            message: res.data.message || 'Báo cáo đơn hàng thất bại, vui lòng thử lại',
          })
      } else
        notification.error({
          message: res.data.message || 'Báo cáo đơn hàng thất bại, vui lòng thử lại',
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      _addLog(`report order: ${JSON.stringify(error)}`)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const _printBarcode = async (record, recordProduct) => {
    const params = {
      warehouse_id: record.warehouse_id,
      name: PRINTER.XUAT_KHO,
    }
    const dataPrinter = await _getPrint(params)
    printToThermalPrinter(
      barcode,
      {
        barcode: recordProduct.barcode,
        title: 'SP: ' + recordProduct.slug_name.replaceAll('-', ' '),
      },
      dataPrinter && dataPrinter.ip
    )
  }

  const _updatePackageOfOrder = async (id, pkgList = []) => {
    try {
      const body = { packages: pkgList }
      setLoading(true)
      await updateExportOrders(id, body)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const printTrackingNumberByHtml = (link = '', callback) => {
    const printEl = document.getElementById(ID_DIV_PRINT)
    if (printEl) {
      var iframeEl = document.createElement('iframe')
      iframeEl.src = link
      iframeEl.frameBorder = 0
      iframeEl.style.minHeight = '100vh'
      iframeEl.style.width = '100%'
      iframeEl.style.borderColor = 'none'
      iframeEl.style.outline = 'none'
      iframeEl.style.boxShadow = 'none'
      printEl.appendChild(iframeEl)
      printJS({
        printable: ID_DIV_PRINT,
        type: 'html',
        onPrintDialogClose: () => callback && callback(),
      })
      iframeEl.remove()
    }
  }

  const _getTrackingNumber = async (order = {}, callback, isCallback = false) => {
    const keyNoti = 'loading-get-tracking-number'
    try {
      let linkTrackingNumber = ''
      if (order.link_tracking_number.includes('wasabi'))
        linkTrackingNumber = order.link_tracking_number
      else {
        const body = {
          customer_order_code: order.customer_order_code,
          customer_id: order.customer_id,
        }
        notification.open({
          message: 'Đang thực hiện lấy mã vận đơn',
          key: keyNoti,
          icon: <Spin />,
          duration: 0,
        })
        const res = await getTrackingNumber(body)
        notification.close(keyNoti)
        if (res.status === 200) {
          if (res.data.success) {
            notification.success({ message: 'Lấy mã vận đơn thành công' })
            linkTrackingNumber = res.data.data
          } else notification.error({ message: res.data.message || 'Lấy mã vận đơn có lỗi xảy ra' })
        } else notification.error({ message: res.data.message || 'Lấy mã vận đơn có lỗi xảy ra' })
      }

      if (linkTrackingNumber) {
        printJS({
          printable: linkTrackingNumber,
          type: 'pdf',
          showModal: true,
          modalMessage: 'Đang in...',
          onPrintDialogClose: () => {
            if (callback) callback()
          },
          onError: () => {
            if (!isCallback) _getTrackingNumber(order, callback, true)
          },
        })
      } else {
        notification.error({
          message: 'In tem thất bại, vui lòng bấm in lại trên website Merchant',
          duration: 5,
        })
        if (callback) callback()
      }
    } catch (error) {
      notification.close(keyNoti)
      console.log(error)
    }
  }

  const _updateShippingBill = async (id, body) => {
    try {
      setLoading(true)
      const res = await updateExportOrders(id, body)
      setLoading(false)
      if (res.status === 200) {
        if (res.data.success) {
          _getOrders()
          notification.success({ message: 'Cập nhật hình ảnh biên lai thành công' })
        } else
          notification.error({
            message: res.data.message || 'Cập nhật hình ảnh biên lai thất bại, vui lòng thử lại',
          })
      } else
        notification.error({
          message: res.data.message || 'Cập nhật hình ảnh biên lai thất bại, vui lòng thử lại',
        })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const _acceptPackageScan = async (id) => {
    try {
      setLoading(true)
      const body = { pack_status: 'COMPLETE', processing: 'is_packed' }
      const res = await updateExportOrders(id, body)
      setLoading(false)
      if (res.status === 200) {
        if (res.data.success) {
          await _getTrackingNumber(res.data.data)
          _getOrders()
          notification.success({ message: 'Xác nhận đóng gói thành công' })
        } else
          notification.error({
            message: res.data.message || 'Xác nhận đóng gói thất bại, vui lòng thử lại',
          })
      } else
        notification.error({
          message: res.data.message || 'Xác nhận đóng gói thất bại, vui lòng thử lại',
        })
    } catch (error) {
      setLoading(false)
      _addLog(`accept package scan: ${JSON.stringify(error)}`)
      console.log(error)
    }
  }

  const _getPrint = async (params) => {
    try {
      const res = await getPrint(params)
      if (res.status === 200) {
        return res.data.data[0]
      }
      return ''
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
      return ''
    }
  }

  const _getOrderDetail = async (orderId) => {
    try {
      setLoading(true)
      const res = await getExportOrders({ order_id: orderId || selectedRowKeys[0] })
      setLoading(false)
      if (res.status === 200) {
        const order = res.data.data && res.data.data.length && res.data.data[0]
        if (order) {
          setOrderExpend(order)
          return order
        }
      }
      return null
    } catch (error) {
      _addLog(JSON.stringify(error))
      setLoading(false)
      console.log(error)
      return null
    }
  }

  const _getOrders = async () => {
    setLoading(true)
    setSelectedRowKeys([])
    try {
      const res = await getExportOrders({ ...paramsFilter, is_packed: true })
      if (res.status === 200) {
        setCountOrder(res.data.count)
        setCountOrderOverTime(res.data.quantity_overtime || [])
        setOrders(res.data.data)
      }
      setLoading(false)
    } catch (error) {
      _addLog(JSON.stringify(error))
      setLoading(false)
      console.log(error)
    }
  }

  const _getReasons = async () => {
    try {
      const res = await enumPackage()
      if (res.status === 200) if (res.data.data) setReasons(res.data.data.report_reason)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _getPlatforms = async () => {
    try {
      const res = await enumPlatform()
      if (res.status === 200) if (res.data.data) setPlatforms(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _getCustomers = async () => {
    try {
      const res = await getAllCustomer()
      if (res.status === 200) setCustomers(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _getExpenses = async () => {
    try {
      const res = await getExpense()
      if (res.status === 200) setExpenses(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _getFees = async () => {
    try {
      const res = await getFees()
      if (res.status === 200) setFees(res.data.data)
    } catch (err) {
      console.log(err)
      _addLog(JSON.stringify(err))
    }
  }

  const _onDownloadReportMissing = async () => {
    try {
      setLoadingDownload(true)
      var response = await reportMissingPackage()
      if (response && response.status == 200 && response.data) {
        window.open(response.data.data)
      }
      setLoadingDownload(false)
    } catch (e) {
      setLoadingDownload(false)
    }
  }

  useEffect(() => {
    if (selectedRowKeys.length) _getOrderDetail()
    else setOrderExpend()
  }, [selectedRowKeys])

  useEffect(() => {
    _getOrders()
    saveFiltersToLocalStorage(paramsFilter, KEY_FILTER)
  }, [paramsFilter])

  useEffect(() => {
    _getReasons()
    _getCustomers()
    _getExpenses()
    _getFees()
    _getPlatforms()
  }, [])

  return (
    <div className={styles['pack']}>
      <div id={ID_DIV_PRINT}></div>
      <HandlerKeyboard />
      <div style={{ display: 'none' }}>
        <ComponentPrintBill order={orderPrint} pkgList={packagesPrint} ref={printRef} />
      </div>
      <Row className="page-title" justify="space-between" style={{ marginBottom: '25px' }}>
        <h3>Đóng gói</h3>
        {/* <Button
          loading={loadingDownload}
          onClick={(e) => {
            _onDownloadReportMissing()
          }}
          type={'primary'}
        >
          <div>{'Tải báo cáo đóng gói thiếu'}</div>
        </Button> */}
        <h3 style={{ fontWeight: 700, color: 'red', fontSize: 20 }}>
          {scannerIsOn
            ? '* Bắt đầu quét thùng đựng hoặc đơn hàng'
            : '* Máy quét đang tắt, nhấn F1 để bật máy quét'}
        </h3>
      </Row>
      <Row gutter={[10, 10]}>
        <Col span={10}>
          <Input.Group compact className={styles['order-collection-filter-group-item']}>
            <Input
              ref={scanRef}
              autoFocus={true}
              value={valueScan}
              allowClear
              onChange={_onScan}
              onFocus={() => setScannerIsOn(true)}
              onBlur={() => setScannerIsOn(false)}
              style={{ width: '100%' }}
              placeholder={
                scannerIsOn
                  ? 'Bắt đầu quét thùng đựng hoặc đơn hàng'
                  : 'Nhấn vào đây để bắt đầu quét (F1)'
              }
            />
          </Input.Group>
        </Col>

        <Col span={5}>
          <Select
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
            }
            style={{ width: '100%' }}
            value={paramsFilter['customer_id'] || undefined}
            onChange={(value) => _filterByOption('customer_id', value)}
            placeholder="Lọc theo doanh nghiệp"
            suffixIcon={<SuffixIconCustom size="default" />}
          >
            {customers.map((item) => (
              <Select.Option key={item.customer_id} value={item.customer_id}>
                {item.fullname}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={4}>
          <Select
            allowClear
            showSearch
            style={{ width: '100%' }}
            placeholder="Lọc theo trạng thái"
            value={paramsFilter['pack_status'] || undefined}
            onChange={(value) => _filterByOption('pack_status', value)}
            suffixIcon={<SuffixIconCustom size="default" />}
          >
            <Select.Option value="WAITING">Chưa đóng gói</Select.Option>
            <Select.Option value="PROCESSING">Đang thực hiện</Select.Option>
            <Select.Option value="COMPLETE">Đã đóng gói</Select.Option>
            <Select.Option value="REPORT">Có sự cố</Select.Option>
          </Select>
        </Col>
        <Col span={5}>
          <FilterDate paramsFilter={paramsFilter} setParamsFilter={setParamsFilter} />
        </Col>
        <Col span={10}>
          <Input.Group compact className={styles['order-collection-filter-group-item']}>
            <Input
              style={{ width: '100%' }}
              value={valueSearch}
              allowClear
              onChange={_onSearch}
              placeholder="Tìm kiếm đơn hàng theo mã đơn hàng"
            />
            {/* <Select
              value={optionSearch}
              onChange={(value) => {
                delete paramsFilter[optionSearch]
                if (valueSearch) paramsFilter[value] = valueSearch
                setParamsFilter({ ...paramsFilter })
                setOptionSearch(value)
              }}
              style={{ width: '30%' }}
              suffixIcon={<SuffixIconCustom />}
            >
              <Select.Option value="code">Mã đơn hàng</Select.Option>
              <Select.Option value="basket_code">Mã thùng đựng</Select.Option>
            </Select> */}
          </Input.Group>
        </Col>
        <Col span={5}>
          <Select
            placeholder="Lọc theo nền tảng"
            style={{ width: '100%' }}
            allowClear
            value={paramsFilter['platform']}
            onChange={(value) => _filterByOption('platform', value)}
            suffixIcon={<SuffixIconCustom />}
          >
            {platforms.map((platform) => (
              <Select.Option value={platform.value} key={platform.value}>
                {platform.value}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row
        justify="space-between"
        align="middle"
        wrap={false}
        style={{ marginTop: 25, marginBottom: 10 }}
      >
        <div style={{ display: 'flex', gap: '10px', marginLeft: '10px', alignItems: 'center' }}>
          {statusOrder.map((status) => {
            return (
              <div
                key={status.title}
                style={{ display: 'flex', alignItems: 'center', marginRight: 15 }}
              >
                <div
                  style={{
                    width: '15px',
                    height: '15px',
                    backgroundColor: status.bgColor,
                    borderRadius: '50%',
                    marginRight: 4,
                  }}
                ></div>
                <span style={{ lineHeight: '20px' }}>{status.title}</span>
              </div>
            )
          })}
        </div>
        <Button onClick={_getOrders} type="primary" icon={<ReloadOutlined />}>
          Làm mới
        </Button>
      </Row>
      <div style={{ padding: '10px', border: 'solid 1px #02D0DD', borderRadius: 5 }}>
        <Row justify="space-between" style={{ margin: '10px 0' }}>
          {countOrderOverTime.slice(0, 12).map((item, index) => {
            return (
              <Badge key={item.time_receive} offset={[0, 0]} count={item.order_quantity}>
                <Button
                  type={paramsFilter.time_receive === index && 'primary'}
                  onClick={() => {
                    paramsFilter.time_receive = index
                    paramsFilter.page = 1
                    setParamsFilter({ ...paramsFilter })
                  }}
                >
                  {item.time_receive}
                </Button>
              </Badge>
            )
          })}
        </Row>
        <Row justify="space-between" style={{ margin: '20px 0' }}>
          {countOrderOverTime.slice(12).map((item, index) => {
            return (
              <Badge key={item.time_receive} offset={[0, 0]} count={item.order_quantity}>
                <Button
                  type={paramsFilter.time_receive === index + 12 && 'primary'}
                  onClick={() => {
                    paramsFilter.time_receive = index + 12
                    paramsFilter.page = 1
                    setParamsFilter({ ...paramsFilter })
                  }}
                >
                  {item.time_receive}
                </Button>
              </Badge>
            )
          })}
        </Row>
        <Table
          pagination={{
            current: paramsFilter.page,
            pageSize: paramsFilter.page_size,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            showQuickJumper: true,
            onChange: (page, pageSize) =>
              setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize }),
            total: countOrder,
          }}
          loading={loading}
          rowKey="order_id"
          size="small"
          showHeader={false}
          columns={columnsOrder}
          rowSelection={{
            selectedRowKeys,
            onSelect: (order) => {
              if (selectedRowKeys.length && order.order_id === selectedRowKeys[0])
                setSelectedRowKeys([])
              else setSelectedRowKeys([order.order_id])
            },
          }}
          expandable={{
            expandedRowRender: (record) => {
              return (
                orderExpend && (
                  <div key={record.order_id}>
                    <Row align="middle" justify="space-between" style={{ marginBottom: 9 }}>
                      <>
                        {orderExpend.packages.length ? (
                          <b style={{ lineHeight: '100%', paddingTop: 10 }}>
                            DANH SÁCH KIỆN HÀNG
                            <Space style={{ marginLeft: 20 }}>
                              <Row align="middle" wrap={false}>
                                <div
                                  style={{
                                    backgroundColor: '#55da6b',
                                    height: 15,
                                    width: 15,
                                    borderRadius: '50%',
                                    marginRight: 5,
                                  }}
                                ></div>
                                <div>Đã in tem</div>
                              </Row>
                              <Row align="middle" wrap={false}>
                                <div
                                  style={{
                                    backgroundColor: '#ffdb00',
                                    height: 15,
                                    width: 15,
                                    borderRadius: '50%',
                                    marginRight: 5,
                                  }}
                                ></div>
                                <div>Chưa in tem</div>
                              </Row>
                            </Space>
                          </b>
                        ) : (
                          <b style={{ lineHeight: '100%', paddingTop: 10 }}>
                            CHƯA CÓ KIỆN HÀNG NÀO
                          </b>
                        )}
                        <Button
                          style={{ backgroundColor: '#23A6A6', borderColor: '#23A6A6' }}
                          type="primary"
                          onClick={() => handlePrint(orderExpend.packages, orderExpend)}
                        >
                          In phiếu dán kiện hàng loạt
                        </Button>
                        {/* {orderExpend.packages.length ? (
                        <ButtonPrintPackage
                          updatePackages={async () => {
                            const packagesNew = orderExpend.packages.map((pkg) => ({
                              ...pkg,
                              is_print_bill: true,
                            }))
                            await _updatePackageOfOrder(record.order_id, packagesNew)
                            _getOrders()
                          }}
                          pkgList={orderExpend.packages || []}
                          order={record}
                          title="In phiếu dán kiện hàng loạt"
                        />
                      ) : (
                        ''
                      )} */}
                      </>
                    </Row>
                    {orderExpend.packages.map((pkg, index) => (
                      <Collapse key={pkg.package_code} style={{ marginBottom: 10 }}>
                        <Collapse.Panel
                          className={!pkg.is_print_bill ? 'panel-bg-orange' : 'panel-bg-green'}
                          key={index}
                          header={
                            <Row
                              align="middle"
                              justify="space-between"
                              wrap={false}
                              style={{ width: '100%' }}
                            >
                              <div>
                                <div>
                                  Mã kiện hàng: <b>{pkg.package_code}</b>
                                </div>
                                <div style={{ display: !pkg.create_date && 'none' }}>
                                  Thời gian đóng gói:{' '}
                                  <b>{moment(pkg.create_date).format('DD/MM/YYYY HH:mm')}</b>
                                </div>
                              </div>

                              <Button
                                style={{ backgroundColor: '#23A6A6', borderColor: '#23A6A6' }}
                                type="primary"
                                onClick={() => handlePrint([pkg], orderExpend)}
                              >
                                In phiếu dán kiện
                              </Button>
                            </Row>
                          }
                        >
                          <Table
                            rowKey="product_id"
                            size="small"
                            columns={columnsProductOfPackage}
                            dataSource={pkg.products}
                            pagination={false}
                            expandable={{
                              defaultExpandAllRows: true,
                              expandedRowRender: (recordProduct) => {
                                return (
                                  <div>
                                    <b>DANH SÁCH PHỤ LIỆU</b>
                                    <Table
                                      dataSource={recordProduct.expense}
                                      size="small"
                                      pagination={false}
                                      columns={columnsExpense}
                                    />
                                  </div>
                                )
                              },
                            }}
                          />
                        </Collapse.Panel>
                      </Collapse>
                    ))}
                    <div style={{ fontSize: 17, marginTop: 30 }}>
                      <Table
                        title={() => <b>DANH SÁCH SẢN PHẨM CẦN ĐÓNG GÓI</b>}
                        size="small"
                        columns={columnsProduct.map((column) => {
                          if (column.key === 'printBarcode')
                            return {
                              ...column,
                              render: (text, recordProduct) => (
                                <div>
                                  <ModalOptionsPrint
                                    printWithInternet={() => _printBarcode(record, recordProduct)}
                                    records={[
                                      {
                                        ...recordProduct,
                                        code: recordProduct.barcode || recordProduct.code,
                                      },
                                    ]}
                                  >
                                    <Button type="primary" className={styles['btn-print']}>
                                      In barcode SP
                                    </Button>
                                  </ModalOptionsPrint>
                                  <Row justify="center" style={{ marginTop: 5 }}>
                                    <QrCodeProduct value={record.barcode} />
                                  </Row>
                                </div>
                              ),
                            }
                          if (column.key === 'warehouse')
                            return {
                              ...column,
                              render: (text) => <>{text?.inventory_info?.warehouse_info?.name}</>,
                            }
                          return column
                        })}
                        dataSource={orderExpend.products || []}
                        pagination={false}
                        scroll={{ y: '55vh' }}
                      />
                    </div>
                  </div>
                )
              )
            },
            expandIconColumnIndex: -1,
            expandedRowKeys: selectedRowKeys,
          }}
          rowClassName={(record) => styles[record.pack_status.toLowerCase()]}
          dataSource={orders}
        />
      </div>
    </div>
  )
}
