import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useDispatch } from 'react-redux'
import { _addLog } from 'utils'
import styles from 'views/storage-fee/storagefee.module.scss'

//components
import Permission from 'components/permission'

//antd
import { ArrowLeftOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  Row,
  Form,
  Input,
  notification,
  InputNumber,
  Space,
  Checkbox,
  Select,
} from 'antd'

//consts
import { ACTION, PERMISSIONS } from 'consts'

//apis
import { createFee, updateFee } from 'apis/fee'
import { enumFee } from 'apis/enum'

export default function StorageFeeAdd() {
  const [form] = Form.useForm()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const [units, setUnits] = useState([])
  const [isFeeExceeding, setIsFeeExceeding] = useState(false)

  const _getEnumExpense = async () => {
    try {
      const res = await enumFee()
      if (res.status === 200) setUnits(res.data.data)
    } catch (error) {}
  }

  const _createOrUpdateFee = async () => {
    await form.validateFields()
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const dataForm = form.getFieldsValue()

      const body = {
        ...dataForm,
        default_in_export_order: dataForm.default_in_export_order || false,
        default_in_import_order: dataForm.default_in_import_order || false,
        description: dataForm.description || '',
        unit: dataForm.unit || '',
        minimum_fee: dataForm.minimum_fee || 0,
        tax_value: dataForm.tax_value || 0,
      }
      let res
      if (location.state) res = await updateFee(location.state.fee_id, body)
      else res = await createFee(body)

      if (res.status === 200) {
        notification.success({
          message: location.state
            ? 'Cập nhật chi phí dịch vụ kho thành công!'
            : 'Tạo chi phí dịch vụ kho thành công!',
        })
        history.goBack()
      } else
        notification.error({
          message:
            res.data.message ||
            (location.state
              ? 'Cập nhật chi phí dịch vụ kho thất bại!'
              : 'Tạo chi phí dịch vụ kho thất bại!'),
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      _addLog(`Create or update fee: ${JSON.stringify(error)}`)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  useEffect(() => {
    if (location.state) {
      if (location.state.fee_exceeding || location.state.exceeding_quantity) setIsFeeExceeding(true)
      form.setFieldsValue(location.state)
    }
    _getEnumExpense()
  }, [])

  return (
    <div>
      <Row className="page-title" justify="space-between" style={{ marginBottom: '25px' }}>
        <h3 style={{ cursor: 'pointer' }} onClick={() => history.goBack()}>
          <ArrowLeftOutlined style={{ marginRight: '10px' }} />
          {location.state ? `Cập nhật` : 'Tạo'} chi phí dịch vụ vận kho{' '}
          <b>{location.state && `#${location.state.fee_name}`}</b>
        </h3>
        <Space size="large">
          <Button
            type="primary"
            danger
            style={{ minWidth: '100px' }}
            onClick={() => history.goBack()}
          >
            Hủy
          </Button>
          <Permission
            permissions={
              location.state ? [PERMISSIONS.cap_nhat_chi_phi_kho] : [PERMISSIONS.tao_chi_phi_kho]
            }
          >
            <Button
              style={{ minWidth: '100px' }}
              onClick={() => _createOrUpdateFee()}
              className={styles['storage-fee-add-button--orange']}
            >
              {location.state ? 'Lưu' : 'Tạo'}
            </Button>
          </Permission>
        </Space>
      </Row>
      <Form form={form} layout="vertical" style={{ padding: '0 50px' }}>
        <Row gutter={100} style={{ marginBottom: '100px' }}>
          <Col span={12}>
            <Form.Item
              label="Tên chi phí"
              name="fee_name"
              rules={[{ required: true, message: 'Vui lòng nhập tên chi phí' }]}
            >
              <Input allowClear placeholder="Nhập tên chi phí" />
            </Form.Item>
            <Form.Item
              label="Đơn giá"
              name="fee"
              rules={[{ required: true, message: 'Vui lòng nhập đơn giá' }]}
            >
              <InputNumber
                allowClear
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: '100%' }}
                min={0}
                placeholder="Nhập đơn giá"
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng chọn đơn vị áp dụng' }]}
              name="unit"
              label="Đơn vị áp dụng"
            >
              <Select allowClear showSearch placeholder="Chọn đơn vị áp dụng">
                {units.map((unit) => (
                  <Select.Option key={unit.value} value={unit.value}>
                    {unit.name} {unit.extension ? `(${unit.extension})` : ''}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item initialValue={0} label="Đơn giá tối thiểu" name="minimum_fee">
              <InputNumber
                allowClear
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: '100%' }}
                min={0}
                placeholder="Nhập đơn giá tối thiểu"
              />
            </Form.Item>
            <Form.Item initialValue={0} label="Thuế" name="tax_value">
              <InputNumber
                allowClear
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: '100%' }}
                min={0}
                placeholder="Nhập thuế"
              />
            </Form.Item>

            <div>
              <Checkbox
                style={{ marginTop: 25, fontSize: 16 }}
                onChange={(e) => setIsFeeExceeding(e.target.checked)}
                checked={isFeeExceeding}
              >
                Tính phí ngoài hạn mức
              </Checkbox>
              {isFeeExceeding && (
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="SL áp dụng vượt mức"
                      name="exceeding_quantity"
                      rules={[{ required: true, message: 'Vui lòng nhập SL áp dụng vượt mức' }]}
                    >
                      <InputNumber
                        allowClear
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        style={{ width: '100%' }}
                        min={0}
                        placeholder="Nhập SL áp dụng vượt mức"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Đơn giá vượt mức"
                      name="fee_exceeding"
                      rules={[{ required: true, message: 'Vui lòng nhập đơn giá vượt mức' }]}
                    >
                      <InputNumber
                        allowClear
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        style={{ width: '100%' }}
                        min={0}
                        placeholder="Nhập đơn giá vượt mức"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </div>
          </Col>
          <Col span={12}>
            <Form.Item label="Nhóm chi phí" name="fee_type">
              <Input allowClear style={{ width: '100%' }} min={0} placeholder="Nhập nhóm chi phí" />
            </Form.Item>
            <Form.Item
              label={<lable style={{ fontWeight: 500, fontSize: 16 }}>Mô tả</lable>}
              name="description"
              style={{ color: 'red' }}
            >
              <Input.TextArea allowClear placeholder="Nhập mô tả " />
            </Form.Item>
            <Row justify="end">
              <Space>
                <Form.Item name="default_in_import_order" valuePropName="checked">
                  <Checkbox>Mặc định sử dụng trên phiếu nhập</Checkbox>
                </Form.Item>
                <Form.Item name="default_in_export_order" valuePropName="checked">
                  <Checkbox>Mặc định sử dụng trên phiếu xuất</Checkbox>
                </Form.Item>
              </Space>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
