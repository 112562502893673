import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { _addLog } from 'utils'

//antd
import { Row, Button, Input, Collapse, Popconfirm, notification, Empty } from 'antd'

//components
import CreateRole from './create-role'
import RoleTree from './role-tree'
import Permission from 'components/permission'

//apis
import { getRoles, deleteRole } from 'apis/role'

//consts
import { ACTION, FIXEDROLES, PERMISSIONS } from 'consts'
import { DeleteOutlined } from '@ant-design/icons'

export default function Role() {
  const [dataRoles, setDataRoles] = useState([])
  const [search, setSearch] = useState('')

  const dispatch = useDispatch()
  const _getRoles = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getRoles()
      const data = res.data.data.filter((role) => role.role_id !== -1 && role.role_id !== -2) // Không hiển thị admin
      if (res.status === 200) {
        setDataRoles(data)
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  useEffect(() => {
    _getRoles()
  }, [])
  const _deleteRole = async (roleId) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await deleteRole({ role_id: [roleId] })
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Xóa vai trò thành công!' })
          _getRoles()
        } else {
          notification.error({
            message: res.data.message || 'Xóa vai trò thất bại, vui lòng thử lại',
          })
        }
      } else {
        notification.error({
          message: res.data.message || 'Xóa vai trò thất bại, vui lòng thử lại',
        })
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      _addLog(`Delete role: ${JSON.stringify(error)}`)
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }
  return (
    <div>
      <Row className="page-title" justify="space-between" style={{ marginBottom: '25px' }}>
        <h3>Quản lý phân quyền</h3>
        <CreateRole _getRoles={_getRoles} dataRoles={dataRoles} />
      </Row>
      <Input.Search
        value={search}
        allowClear
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Tìm kiếm theo tên vai trò"
        enterButton
        style={{ width: 400 }}
      />
      <div style={{ marginTop: 15 }}>
        {dataRoles.length ? (
          <Collapse accordion>
            {dataRoles.map((role, index) => (
              <Collapse.Panel
                extra={
                  FIXEDROLES.indexOf(role.slug_name) === -1 && (
                    <Permission permissions={[PERMISSIONS.xoa_phan_quyen]}>
                      <Popconfirm
                        placement="topRight"
                        title="Bạn có muốn xóa vai trò này không?"
                        cancelText="Từ chối"
                        okText="Đồng ý"
                        onCancel={(e) => {
                          e.stopPropagation()
                        }}
                        onConfirm={(e) => {
                          e.stopPropagation()
                          _deleteRole(role.role_id)
                        }}
                      >
                        <Button type="primary" danger onClick={(e) => e.stopPropagation()}>
                          <DeleteOutlined />
                        </Button>
                      </Popconfirm>
                    </Permission>
                  )
                }
                header={
                  <div style={{ textTransform: 'uppercase', display: 'inline-block' }}>
                    {search && role.name.toLowerCase().includes(search.toLowerCase()) ? (
                      <mark style={{ backgroundColor: '#00D3E0' }}>{role.name}</mark>
                    ) : (
                      <b>{role.name}</b>
                    )}
                  </div>
                }
                key={index}
              >
                <RoleTree search={search} role={role} _getRoles={_getRoles} />
              </Collapse.Panel>
            ))}
          </Collapse>
        ) : (
          <Empty description={<span>Chưa có vai trò</span>}>
            <CreateRole txt="Thêm vai trò ngay" _getRoles={_getRoles} dataRoles={dataRoles} />
          </Empty>
        )}
      </div>
    </div>
  )
}
