//lib
import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { _addLog } from 'utils'

//antd
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Row, Col, Form, Input, Select, Button, notification, Space, Checkbox } from 'antd'

//components
import styles from './warehouse-add.module.scss'
import { ROUTES, ACTION, PERMISSIONS } from 'consts'
import SuffixIconCustom from 'components/suffixIconCustom'

//apis
import { createWarehouse, updateWarehouse } from 'apis/warehouse'
import { getWarehouses } from 'apis/warehouse'
import { getWard, getDistrict, getProvince } from 'apis/address'
import Permission from 'components/permission'

export default function CreateWarehouse() {
  const dispatch = useDispatch()
  const [warehouseRecently, setWarehouseRecently] = useState({})

  const [province, setProvince] = useState([])
  const [district, setDistrict] = useState([])
  const [ward, setWard] = useState([])

  const [valueProvince, setValueProvince] = useState('')
  const [valueDistrict, setValueDistrict] = useState('')

  const [createForm] = Form.useForm()
  const history = useHistory()

  const _createAndUpdateWarehouse = async (value) => {
    // console.log('value', value);
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      let res
      if (history.location.state) {
        res = await updateWarehouse(history.location.state.warehouse_id, value)
      } else if (warehouseRecently.warehouse_id) {
        res = await createWarehouse(value)
      } else {
        res = await createWarehouse(value)
      }

      if (res.data.success) {
        notification.success({
          message: history.location.state
            ? 'Cập nhật kho hàng thành công'
            : 'Tạo kho hàng thành công',
        })

        dispatch({ type: ACTION.LOADING, data: false })
        history.push(ROUTES.WAREHOUSE)
      } else {
        dispatch({ type: ACTION.LOADING, data: false })
        notification.error({
          message:
            res.data.message ||
            (history.location.state ? 'Cập nhật kho hàng thất bại' : 'Tạo kho hàng thất bại'),
        })
      }
    } catch (err) {
      _addLog(`Create or update warehouse: ${JSON.stringify(err)}`)
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(err)
      notification.error({
        message: history.location.state ? 'Cập nhật thất bại' : 'Tạo kho hàng thất bại',
      })
    }
  }

  const _getWarehousePre = async (e) => {
    const isChecked = e.target.checked
    try {
      if (isChecked) {
        const res = await getWarehouses()

        if (res.status === 200) {
          const data = res.data.data
          if (data && data.length) setWarehouseRecently(data[0])
        }
      } else {
        createForm.resetFields()
      }
    } catch (err) {
      _addLog(JSON.stringify(err))
      console.log(err)
      notification.error({
        message: 'Điền thông tin kho hàng thất bại',
      })
    }
  }

  const _getProvince = async () => {
    try {
      const res = await getProvince()
      if (res.status === 200) {
        if (res.data.success) {
          const data = res.data.data
          setProvince(data)
          if (!history.location.state) {
            if (data.length) {
              createForm.setFieldsValue({ province: data[0].province_name })
            }
          }
        }
      }
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _getDistrict = async (value) => {
    try {
      if (value) {
        const res = await getDistrict(value)
        if (res.status === 200) {
          if (res.data.success) {
            const data = res.data.data
            setDistrict(data)
            if (!history.location.state) {
              if (data.length) {
                createForm.setFieldsValue({ district: data[0].district_name })
              }
            }
          }
        }
      }
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _getWard = async (value) => {
    try {
      const res = await getWard(value)
      if (res.status === 200) {
        if (res.data.success) {
          const data = res.data.data
          setWard(data)
          if (!history.location.state) {
            if (data.length) {
              createForm.setFieldsValue({ ward: data[0].ward_name })
            }
          }
        }
      }
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  useEffect(() => {
    if (history.location.state) {
      const state = history.location.state
      createForm.setFieldsValue(state)
      const { province, district } = state
      setValueProvince(province)
      setValueDistrict(district)
    }
    _getProvince()
  }, [])

  useEffect(() => {
    _getDistrict({ province_name: valueProvince })
    _getWard({ province_name: valueProvince })
  }, [valueProvince])

  useEffect(() => {
    _getWard({ district_name: valueDistrict })
  }, [valueDistrict])

  useEffect(() => {
    if (warehouseRecently.warehouse_id) {
      createForm.setFieldsValue(warehouseRecently)
    }
  }, [warehouseRecently])

  return (
    <div className={styles['warehouse-add']}>
      <div className={styles['title']}>
        <Link to={ROUTES.WAREHOUSE}>
          <ArrowLeftOutlined /> {history.location.state ? 'Cập nhật kho hàng' : 'Tạo kho hàng'}
        </Link>
      </div>
      <div className={styles['warehouse-add-body']}>
        <Form
          layout="vertical"
          form={createForm}
          onFinish={_createAndUpdateWarehouse}
          style={{ padding: '0 50px' }}
        >
          <Row gutter={100}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Tên kho"
                rules={[{ required: true, message: 'Vui lòng nhập tên kho' }]}
              >
                <Input allowClear placeholder="Nhập tên kho" />
              </Form.Item>
              <Form.Item
                name="address"
                initialValue=""
                label={<label style={{ fontSize: 16, fontWeight: 500 }}>Địa chỉ</label>}
              >
                <Input placeholder="Nhập địa chỉ" allowClear />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: 'Vui lòng nhập số điện thoại liên hệ' }]}
                name="phone"
                label={<label style={{ fontSize: 16, fontWeight: 500 }}>Liên hệ</label>}
              >
                <Input placeholder="Nhập số điện thoại" allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="province"
                label={<label style={{ fontSize: 16, fontWeight: 500 }}>Tỉnh/ thành phố</label>}
              >
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Chọn Tỉnh/thánh phố"
                  suffixIcon={<SuffixIconCustom />}
                  onChange={(e) => setValueProvince(e)}
                >
                  {province.map((item, index) => (
                    <Select.Option key={index} value={item.province_name}>
                      {item.province_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="district"
                label={<label style={{ fontSize: 16, fontWeight: 500 }}>Quận/huyện</label>}
              >
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Chọn Quận/huyện"
                  suffixIcon={<SuffixIconCustom />}
                  onChange={(e) => setValueDistrict(e)}
                >
                  {district.map((item, index) => (
                    <Select.Option key={index} value={item.district_name}>
                      {item.district_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="ward"
                label={<label style={{ fontSize: 16, fontWeight: 500 }}>Phường/Xã</label>}
              >
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Chọn Phường/Xã"
                  suffixIcon={<SuffixIconCustom />}
                >
                  {ward.map((item, index) => (
                    <Select.Option key={index} value={item.ward_name}>
                      {item.ward_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" style={{ marginTop: 50 }}>
            <Checkbox onChange={_getWarehousePre} disabled={history.location.state ? true : false}>
              Điền thông tin từ kho trước đó
            </Checkbox>
            <Space size="large">
              <Button
                danger
                type="primary"
                style={{
                  minWidth: '100px',
                }}
                onClick={() => history.push(ROUTES.WAREHOUSE)}
              >
                Hủy
              </Button>

              <Permission
                permissions={
                  history.location.state
                    ? [PERMISSIONS.cap_nhat_kho_hang]
                    : [PERMISSIONS.tao_kho_hang]
                }
              >
                <Button className={styles['warehouse-add-body-btn--save']} htmlType="submit">
                  {history.location.state ? 'Lưu' : 'Tạo'}
                </Button>
              </Permission>
            </Space>
          </Row>
        </Form>
      </div>
    </div>
  )
}
