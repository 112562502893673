import { CaretDownOutlined } from '@ant-design/icons';

export default function SuffixIconCustom({ size = 'default' }) {
  const sizeMap = {
    small: {
      top: '-6px',
      right: '-10.5px',
      left: '-5.5px',
      bottom: '-16px',
    },
    default: {
      top: '-11px',
      right: '-11px',
      left: '-5.5px',
      bottom: '-21px',
    },
    large: {
      top: '-15px',
      right: '-10.5px',
      left: '-5.5px',
      bottom: '-25px',
    },
  };
  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          ...sizeMap[size],
          position: 'absolute',
          backgroundColor: '#13D8E5',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderBottomRightRadius: 5,
          borderTopRightRadius: 5,
        }}
      >
        <CaretDownOutlined style={{ color: 'white' }} />
      </div>
    </div>
  );
}
