import { get, post, patch, destroy } from './axiosClient'

export const getProducts = (query) => get('/product', query)
export const createProduct = (body) => post('/product/create', { ...body, from_platform: 'SGL' })
export const updateProduct = (body, id) =>
  patch('/product/update/' + id, { ...body, from_platform: 'SGL' })
export const deleteProducts = (body) =>
  destroy('/product/delete', { ...body, from_platform: 'SGL' })
export const updateBarcodeProduct = (formData) => post('/product/file/change-barcode', formData)

export const exportByFilter = (query) => get('/product/export-excel-filter', query)
export const exportByAll = (query) => get('/product/export-excel-all', query)

export const exportErrorByMerchant = (query) => get('/product/export-excel-error-inventory', query)
export const exportHistoryInventory = (query) => get('/product/excel-inventory-history', query)

export const reportInventoryBucket = (query) => get('/report//excel-inventory-bucket', query)
