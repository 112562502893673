import { Popover, Slider, Upload } from 'antd'
import React, { useState } from 'react'

export default function ZoomImage({ image = '', children }) {
  const [valueBox, setValueBox] = useState(400)
  const ContentZoomImage = (data) => {
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <img
          src={data}
          style={{ width: valueBox, height: valueBox, objectFit: 'contain' }}
          alt=""
          onClick={(e) => e.stopPropagation()}
        />
        {/* <Slider
          defaultValue={100}
          min={100}
          max={1000}
          onChange={(value) => setValueBox(value)}
          onClick={(e) => e.stopPropagation()}
        /> */}
      </div>
    )
  }

  return (
    <div>
      {image ? (
        <Popover style={{ top: 300 }} placement="top" content={ContentZoomImage(image)}>
          {children ? (
            children
          ) : (
            <img src={image} alt="" style={{ width: '100%', display: 'block', margin: '15px 0' }} />
          )}
        </Popover>
      ) : (
        // <img src={IMAGE_DEFAULT} alt="" style={{ width: '100%' }} />
        ' '
      )}
    </div>
  )
}
