import React, { useEffect, useRef, useState } from 'react'
import styles from './productCheckScan.module.scss'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { formatCash, _addLog } from 'utils'
import { ACTION, IMAGE_DEFAULT, ROUTES } from 'consts'
import moment from 'moment'

//antds
import {
  Row,
  Col,
  Form,
  InputNumber,
  notification,
  Table,
  Input,
  Switch,
  Modal,
  Button,
  DatePicker,
} from 'antd'
import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons'

//apis
import { getOrders, scanImport } from 'apis/order'
import { getProducts } from 'apis/product'
import { getBuckets } from 'apis/bucket'

export default function ProductImportScan() {
  const history = useHistory()
  const dispatch = useDispatch()
  const inputBucket = useRef(null)

  const [isHiddenInputScan, setIsHiddenInputScan] = useState(false)
  const [orderUpdate, setOrderUpdate] = useState({})
  const [CreateForm] = Form.useForm()
  const [productsScan, setProductsScan] = useState([])
  const [bucketScan, setBucketScan] = useState(null)
  const [productsOrder, setProductsOrder] = useState([])
  const [isFocusInputScan, setIsFocusInputScan] = useState(false)

  const _getBucket = async (params) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getBuckets(params)
      dispatch({ type: ACTION.LOADING, data: false })

      if (res.status === 200) {
        if (res.data.success) {
          const data = res.data.data
          if (data[0]) {
            setBucketScan(data[0])
            _onHandleImport(data[0])
          } else notification.error({ message: 'Không tìm thấy ô kệ, vui lòng quét lại' })
        } else
          notification.error({
            message: res.data.message || 'Không tìm thấy ô kệ, vui lòng quét lại',
          })
      } else
        notification.error({
          message: res.data.message || 'Không tìm thấy ô kệ, vui lòng quét lại',
        })

      reScan()
    } catch (err) {
      _addLog(JSON.stringify(err))
      console.log(err)
      dispatch({ type: ACTION.LOADING, data: false })
      reScan()
    }
  }

  const _getProduct = async (barcode) => {
    dispatch({ type: ACTION.LOADING, data: true })
    try {
      const res = await getProducts({ barcode })
      dispatch({ type: ACTION.LOADING, data: false })
      if (res.status === 200) {
        if (res.data.data.length) {
          return res.data.data[0]
        } else {
          return false
        }
      } else {
        return false
      }
    } catch (err) {
      console.log(err)
      _addLog(JSON.stringify(err))
      dispatch({ type: ACTION.LOADING, data: false })
      return false
    }
  }

  const _getOrderImport = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const idOrder = localStorage.getItem('idOrderImport')
      const res = await getOrders({ order_id: idOrder })
      dispatch({ type: ACTION.LOADING, data: false })

      if (res.status === 200) {
        if (res.data.success) {
          const data = res.data.data[0]
          setOrderUpdate(data)
          setProductsOrder(data.products)
        }
      }
    } catch (error) {
      _addLog(JSON.stringify(error))
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const _onScanProductBucket = async (value) => {
    if (value.barcode) {
      const p = await _getProduct(value.barcode)
      if (p) {
        const product = productsOrder.find((item) => item.barcode == value.barcode)
        if (product) {
          notification.success({
            key: 'scan-product',
            message: `Quét sản phẩm ${product.name} thành công`,
            duration: 3,
          })
          //nếu quét sản phẩm đã có rồi thì tăng số lượng lên 1
          const indexProductScan = productsScan.findIndex((item) => item.barcode == value.barcode)
          if (indexProductScan !== -1) {
            productsScan[indexProductScan].importQuantity =
              productsScan[indexProductScan].importQuantity + 1
            setProductsScan([...productsScan])
            reScan()
          } else {
            productsScan.push({ ...product, importQuantity: 1 })
            setProductsScan([...productsScan])
            reScan()
          }
        } else {
          notification.warning({
            message: 'Sản phẩm này không nằm trong danh sách cần nhập vào vị trí',
          })
          reScan()
        }
      } else {
        //quét mã ô kệ
        _getBucket({ code: value.barcode })
      }
    }
  }

  const _onHandleQuantityProduct = async (value, product) => {
    const valueNew = value.replaceAll(',', '')
    const indexProduct = productsScan.findIndex((p) => p.product_id === product.product_id)
    if (indexProduct !== -1) productsScan[indexProduct].importQuantity = +valueNew
    setProductsScan([...productsScan])
    reScan()
  }

  const _onUpdateDate = async (value, key, product) => {
    const indexProduct = productsScan.findIndex((p) => p.product_id === product.product_id)
    if (indexProduct !== -1) productsScan[indexProduct][key] = value
    setProductsScan([...productsScan])
    reScan()
  }

  const _onDeleteProduct = async (product) => {
    const indexProduct = productsScan.findIndex((p) => p.product_id === product.product_id)
    if (indexProduct !== -1) productsScan.splice(indexProduct, 1)
    setProductsScan([...productsScan])
    reScan()
  }

  const _updateScanImport = async (bucket) => {
    dispatch({ type: ACTION.LOADING, data: true })
    const body = {
      order_id: orderUpdate.order_id || '',
      warehouse_id: bucket.warehouse_id,
      bucket_id: bucket.bucket_id || '',
      scans: productsScan.map((e) => ({
        product_id: e.product_id || '',
        expiry_id: e.expiry_id || '',
        import_quantity: e.importQuantity || 0,
        mfg: e.mfg || '',
        exp: e.exp || '',
      })),
    }
    const res = await scanImport(body)
    dispatch({ type: ACTION.LOADING, data: false })

    if (res.status === 200) {
      notification.success({ message: 'Nhập vào vị trí thành công' })
      history.push(ROUTES.PRODUCT_IMPORT_DETAIL)
    } else notification.error({ message: res.data.message || 'Nhập vào vị trí thất bại' })
  }

  const reScan = () => {
    if (inputBucket && inputBucket.current) inputBucket.current.focus()
    CreateForm.resetFields()
  }

  const _onHandleImport = async (bucketScan = null) => {
    if (productsScan.length) {
      const sumCbm = productsScan.reduce(
        (total, current) => total + current.cbm * current.importQuantity,
        0
      )

      if (
        bucketScan.parameter_info &&
        sumCbm <= bucketScan.parameter_info.max_space - bucketScan.current_space
      )
        _updateScanImport(bucketScan)
      else
        Modal.confirm({
          title: 'Thể tích ô kệ không đủ, bạn có muốn tiếp tục hoàn tất quét nhập vị trí không?',
          onOk: () => _updateScanImport(bucketScan),
        })
    } else {
      notification.warning({
        message:
          (!bucketScan && 'Vui lòng quét ô kệ') ||
          (!productsScan.length && 'Vui lòng quét sản phẩm'),
      })
    }
  }

  const handleVisibleSwitch = () => {
    setIsHiddenInputScan(!isHiddenInputScan)
    reScan()
  }

  useEffect(() => {
    _getOrderImport()

    const product = history.location.state
    if (product) {
      if (product.product_id) setProductsScan([{ ...product, importQuantity: 1 }])
    } else history.push(ROUTES.PRODUCT_IMPORT_DETAIL)
  }, [])

  return (
    <div className={styles['product-check-scan']}>
      <Row className="page-title" justify="space-between" style={{ marginBottom: '25px' }}>
        <Col style={{ display: 'flex', gap: '15px' }}>
          <h3 style={{ cursor: 'pointer' }} onClick={() => history.goBack()}>
            <ArrowLeftOutlined style={{ marginRight: '10px' }} />
            Quét nhập vào vị trí của đơn hàng <b>#{orderUpdate.code || ''}</b>
          </h3>
        </Col>
        <Row>
          <div>Nhập mã thủ công</div>
          <Switch
            checked={isHiddenInputScan}
            onChange={handleVisibleSwitch}
            style={{ marginLeft: 5 }}
          />
        </Row>
      </Row>
      <Row justify="space-between" style={{ marginBottom: 20 }}>
        <Col span={8}>
          {bucketScan && (
            <>
              <h3>Tên ô kệ: {bucketScan?.hotname}</h3>
              <h3>Thể tích ô kệ: {formatCash(bucketScan?.parameter_info?.max_space)}</h3>
              <h3>Thể tích hiện tại: {formatCash(bucketScan?.current_space)}</h3>
              <br />
            </>
          )}

          <div>
            <h3>B1: Quét liên tiếp các sản phẩm</h3>
            <h3>B2: Quét ô kệ để hoàn tất nhập hàng vào vị trí</h3>
          </div>
        </Col>
        <Col span={8}>
          <div style={{ cursor: 'pointer' }} onClick={reScan}>
            <h3 style={{ fontSize: 20, fontWeight: 600, marginBottom: 3 }}>
              {isFocusInputScan
                ? 'Bắt đầu quét sản phẩm hoặc ô kệ'
                : 'Nhấn vào đây để bật máy quét'}
            </h3>
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="barcode-read"
              role="img"
              width="300"
              style={{ color: '#f5f5f5' }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
              class="svg-inline--fa fa-barcode-read fa-w-20"
            >
              <path
                fill="currentColor"
                d="M184 128h-48c-4.4 0-8 3.6-8 8v240c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V136c0-4.4-3.6-8-8-8zm-40 320H64v-80c0-8.8-7.2-16-16-16H16c-8.8 0-16 7.2-16 16v128c0 8.8 7.2 16 16 16h128c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm104-320h-16c-4.4 0-8 3.6-8 8v240c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V136c0-4.4-3.6-8-8-8zM16 160h32c8.8 0 16-7.2 16-16V64h80c8.8 0 16-7.2 16-16V16c0-8.8-7.2-16-16-16H16C7.2 0 0 7.2 0 16v128c0 8.8 7.2 16 16 16zm392-32h-48c-4.4 0-8 3.6-8 8v240c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V136c0-4.4-3.6-8-8-8zm-96 0h-16c-4.4 0-8 3.6-8 8v240c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V136c0-4.4-3.6-8-8-8zM624 0H496c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h80v80c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V16c0-8.8-7.2-16-16-16zm0 352h-32c-8.8 0-16 7.2-16 16v80h-80c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h128c8.8 0 16-7.2 16-16V368c0-8.8-7.2-16-16-16zm-112 24V136c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v240c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8z"
                class=""
              ></path>
            </svg>
          </div>
        </Col>
        <Col span={8}>
          <Form layout="vertical" form={CreateForm} onFinish={_onScanProductBucket}>
            <Form.Item
              name="barcode"
              label={<b style={{ fontSize: 17 }}>Nhập mã ô kệ hoặc mã sản phẩm và enter</b>}
              style={{ opacity: !isHiddenInputScan && 0 }}
            >
              <Input
                autoFocus
                onFocus={() => setIsFocusInputScan(true)}
                onBlur={() => setIsFocusInputScan(false)}
                ref={inputBucket}
                style={{ width: '100%' }}
                placeholder="Nhập mã ô kệ hoặc mã sản phẩm và enter"
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Table
        dataSource={productsScan}
        size="small"
        pagination={false}
        columns={[
          {
            title: 'STT',
            align: 'center',
            width: 50,
            render(text, record, index) {
              return index + 1
            },
          },
          {
            title: 'Mã sản phẩm',
            render: (text, record) => (record?.barcode ? record?.barcode : record?.code),
          },
          {
            title: 'Tên sản phẩm',
            dataIndex: 'name',
          },
          {
            title: 'Thể tích',
            dataIndex: 'cbm',
            align: 'center',
            render: (text) => text && formatCash(text || 0),
          },
          {
            title: 'Hình ảnh',
            dataIndex: 'images',
            align: 'center',
            render(text, record) {
              return (
                text && (
                  <img style={{ width: 60, height: 60 }} src={text[0] || IMAGE_DEFAULT} alt="" />
                )
              )
            },
          },
          {
            title: 'Số lượng đã kiểm',
            align: 'center',
            render: (text, record) => formatCash(record.inventory_quantity || 0),
          },
          {
            title: 'Số lượng đã nhập',
            align: 'center',
            render: (text, record) => formatCash(record.import_quantity || 0),
          },
          {
            title: 'Ngày sản xuất',
            align: 'center',
            render: (text, record) => (
              <DatePicker
                value={record.mfg && moment(record.mfg)}
                onChange={(value) => _onUpdateDate(moment(value).format(), 'mfg', record)}
                placeholder="Ngày hết hạn"
                style={{ width: 140 }}
              />
            ),
          },
          {
            title: 'Ngày hết hạn',
            align: 'center',
            render: (text, record) => (
              <DatePicker
                value={record.exp && moment(record.exp)}
                onChange={(value) => _onUpdateDate(moment(value).format(), 'exp', record)}
                placeholder="Ngày hết hạn"
                style={{ width: 140 }}
              />
            ),
          },
          {
            title: 'Số lượng cần nhập',
            align: 'center',
            render(text, record) {
              const InputQuantity = () => (
                <InputNumber
                  onPressEnter={(e) => _onHandleQuantityProduct(e.target.value, record)}
                  min={0}
                  defaultValue={record?.importQuantity}
                  onBlur={(e) => _onHandleQuantityProduct(e.target.value, record)}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  style={{ width: 150 }}
                  placeholder="Nhập số lượng và enter"
                />
              )
              return (
                <div>
                  <InputQuantity />
                  {record.inventory_quantity < record.importQuantity && (
                    <h3 style={{ color: 'red' }}>* Vượt quá số lượng cần nhập</h3>
                  )}
                  {record.inventory_quantity > record.importQuantity && (
                    <h3 style={{ color: 'red' }}>* Chưa đủ số lượng cần nhập</h3>
                  )}
                </div>
              )
            },
          },
          {
            title: '',
            render(text, record) {
              return (
                <Button
                  onClick={() => _onDeleteProduct(record)}
                  danger
                  type="primary"
                  icon={<DeleteOutlined />}
                />
              )
            },
          },
        ]}
      />
    </div>
  )
}
