import React, { useState, useEffect, useRef } from 'react'
import styles from './reportInventory.module.scss'
import { SIZE_TABLE, POSITION_TABLE, PAGE_SIZE_OPTIONS, IMAGE_DEFAULT } from 'consts'
import moment from 'moment'
import {
  filterOptionSelect,
  formatCash,
  _addLog,
  saveFiltersToLocalStorage,
  getFileName,
} from 'utils'
import { saveAs } from 'file-saver'

// antd
import { Col, Input, Row, Select, Table, Space, Button, Tag, Modal } from 'antd'

//icons
import { ReloadOutlined, VerticalAlignTopOutlined } from '@ant-design/icons'

//components
import SuffixIconCustom from 'components/suffixIconCustom'
import SettingColumns from 'components/setting-columns'
import reportInventoryColumns from './columns'
import FilterDateExport from 'components/filter-date-export'
import ZoomImage from 'components/zoom-image'

//apis
import { getCategories } from 'apis/categories-product'
import { exportReportExportInventory } from 'apis/report'
import { getUsers } from 'apis/user'
import { getAllCustomer } from 'apis/customer'
import { getExportOrders } from 'apis/export-order'

const { Option } = Select
export default function ReportInventory() {
  const typingTimeoutRef = useRef()
  const KEY_FILTER = 'reportExportWarehouseFilters'
  const filters = localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))

  const [columns, setColumns] = useState([])
  const [reportExportInventory, setReportExportInventory] = useState([])
  const [loading, setLoading] = useState(false)
  const [paramsFilter, setParamsFilter] = useState(
    filters ? { ...filters } : { page: 1, page_size: 20, export_today: true }
  )
  const [inventoryCount, setInventoryCount] = useState(0)
  const [groups, setGroups] = useState([])
  const [types, setTypes] = useState([])
  const [users, setUsers] = useState([])
  const [customers, setCustomers] = useState([])

  const [valueSearch, setValueSearch] = useState(
    filters ? filters.customer_order_code || filters.product_code || '' : ''
  )
  const [optionSearch, setOptionSearch] = useState(
    filters
      ? (filters.customer_order_code && 'customer_order_code') ||
          (filters.product_code && 'product_code') ||
          'customer_order_code'
      : 'customer_order_code'
  )

  const clearFilters = () => {
    setValueSearch('')
    setParamsFilter({ page: 1, page_size: 20, today: true })
  }

  const onFilter = (attribute = '', value = '') => {
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter, page: 1 })
  }

  const ModalProductsOfPackage = ({ record, children }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    const columnsProduct = [
      {
        title: 'STT',
        align: 'center',
        width: 50,
        fixed: 'left',
        render: (text, record, index) => index + 1,
      },
      {
        title: 'Tên sản phẩm',
        dataIndex: 'name',
        fixed: 'left',
        width: 250,
      },
      {
        title: 'Mã sản phẩm',
        dataIndex: 'code',
        fixed: 'left',
      },
      {
        title: 'Mã SP từ sàn thương mại',
        dataIndex: 'ecommerce_barcode',
      },
      {
        title: 'Nhóm hàng',
        render: (text, record) => record.group_info && record.group_info.name,
      },
      {
        title: 'Loại hàng',
        render: (text, record) => record.type_info && record.type_info.name,
      },
      {
        title: 'Mã hàng/Barcode',
        dataIndex: 'barcode',
      },
      {
        title: 'Hình ảnh',
        render: (text, record) => (
          <img style={{ width: 50, height: 50 }} alt="" src={record.images[0] || IMAGE_DEFAULT} />
        ),
      },
      {
        title: 'Giá tiền',
        align: 'center',
        render: (text, record) => formatCash(+record.price || 0),
      },
      {
        title: 'Cbm (cm³)',
        dataIndex: 'cbm',
        align: 'center',
      },
      {
        title: 'D.Tích mặt phẳng (cm²)',
        dataIndex: 'area',
        align: 'center',
      },
      {
        title: 'Quy cách',
        dataIndex: 'convention',
      },
      {
        title: 'Đơn vị tính',
        render: (text, record) => record.unit_info && record.unit_info.name,
      },
      {
        title: 'Đơn vị nhỏ nhất',
        render: (text, record) => formatCash(record.smallest_unit || 0),
      },
      {
        title: 'Kho',
        render: (text, record) =>
          record.locations &&
          record.locations.warehouse_info &&
          (record.locations.warehouse_info.name || ''),
      },
      {
        title: 'Doanh nghiệp',
        render: (text, record) => record.customer_info && record.customer_info.fullname,
      },
    ]

    return (
      <>
        <div onClick={toggle}>{children}</div>
        <Modal
          style={{ top: 20 }}
          width="85%"
          footer={null}
          visible={visible}
          onCancel={toggle}
          title={
            <div>
              Danh sách sản phẩm của kiện hàng <b>#{record.package_code}</b>
            </div>
          }
        >
          <Table
            scroll={{ x: 2000, y: '62vh' }}
            size="small"
            dataSource={record.products || []}
            columns={columnsProduct}
            pagination={{ pageSize: 50 }}
            summary={() => (
              <Table.Summary fixed="bottom">
                <Table.Summary.Row></Table.Summary.Row>
              </Table.Summary>
            )}
            sticky
          />
        </Modal>
      </>
    )
  }

  const renderNewColumns = (column) => {
    if (column.key === 'stt')
      return {
        ...column,
        render: (text, record, index) =>
          (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
      }
    if (column.key === 'date')
      return {
        ...column,
        render: (text, record) =>
          record.create_date && moment(record.create_date).format('DD/MM/YYYY HH:mm'),
      }
    if (column.key === 'date_ct')
      return {
        ...column,
        render: (text, record) =>
          record.export_date && moment(record.export_date).format('DD/MM/YYYY HH:mm'),
      }
    if (column.key === 'customer_code')
      return {
        ...column,
        render: (text, record) => record.customer_info && record.customer_info.code,
      }
    if (column.key === 'customer_name')
      return {
        ...column,
        render: (text, record) => record.customer_info && record.customer_info.fullname,
      }
    if (column.key === 'customer_address')
      return {
        ...column,
        render: (text, record) =>
          record.customer_info &&
          `${record.customer_info.address && `${record.customer_info.address}, `}${
            record.customer_info.ward && `${record.customer_info.ward}, `
          }${record.customer_info.district && `${record.customer_info.district}, `}${
            record.customer_info.province && `${record.customer_info.province}, `
          }`,
      }

    if (column.key === 'warehouse')
      return {
        ...column,
        render: (text, record) => record.warehouse_info && record.warehouse_info.name,
      }
    if (column.key === 'product_code')
      return {
        ...column,
        render: (text, record) => record.product_info && record.product_info.barcode,
      }
    if (column.key === 'product_name')
      return {
        ...column,
        render: (text, record) => record.product_info && record.product_info.name,
      }
    if (column.key === 'product_unit')
      return {
        ...column,
        render: (text, record) =>
          record.product_info &&
          record.product_info.unit_info &&
          record.product_info.unit_info.name,
      }
    if (column.key === 'product_quantity')
      return {
        ...column,
        render: (text, record) => (record.export_quantity ? formatCash(record.export_quantity) : 0),
      }
    if (column.key === 'product_price')
      return {
        ...column,
        render: (text, record) => record.product_info && formatCash(record.product_info.price || 0),
      }
    if (column.key === 'type_code')
      return {
        ...column,
        render: (text, record) =>
          record.product_info &&
          record.product_info.type_info &&
          record.product_info.type_info.code,
      }
    if (column.key === 'type_name')
      return {
        ...column,
        render: (text, record) =>
          record.product_info &&
          record.product_info.type_info &&
          record.product_info.type_info.name,
      }
    if (column.key === 'group_code')
      return {
        ...column,
        render: (text, record) =>
          record.product_info &&
          record.product_info.group_info &&
          record.product_info.group_info.code,
      }
    if (column.key === 'group_name')
      return {
        ...column,
        render: (text, record) =>
          record.product_info &&
          record.product_info.group_info &&
          record.product_info.group_info.name,
      }
    if (column.key === 'packages')
      return {
        ...column,
        width: 250,
        render: (text, record) =>
          record.packages &&
          record.packages.map((pkg) => (
            <ModalProductsOfPackage record={pkg}>
              <Tag style={{ cursor: 'pointer' }}>{pkg.package_code}</Tag>
            </ModalProductsOfPackage>
          )),
      }
    if (column.key === 'shipping_bill')
      return {
        ...column,
        width: 65,
        render: (text, record) => {
          const shipping_bill = record.shipping_info && record.shipping_info.shipping_bill

          const image = shipping_bill
            ? typeof shipping_bill === 'string'
              ? shipping_bill
              : shipping_bill[0] || ''
            : ''

          return (
            image && (
              <ZoomImage image={image}>
                <img src={image} alt="" style={{ width: 50, height: 50 }} />
              </ZoomImage>
            )
          )
        },
      }

    return column
  }

  const _selectOption = (value) => {
    setOptionSearch(value)
    delete paramsFilter[optionSearch]
    if (valueSearch) setParamsFilter({ ...paramsFilter, [value]: valueSearch, page: 1 })
  }

  const _onSearch = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value
      if (value) paramsFilter[optionSearch] = value
      else delete paramsFilter[optionSearch]
      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 650)
  }

  const _getGroups = async () => {
    try {
      const res = await getCategories({ group: true, detach: true })
      if (res.status === 200) setGroups(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _getTypes = async () => {
    try {
      const res = await getCategories({ type: true, detach: true })
      console.log(res)
      if (res.status === 200) setTypes(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _getUsers = async () => {
    try {
      const res = await getUsers()
      if (res.status === 200) setUsers(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _getReportExportInventoryToExport = async (getAll = false) => {
    try {
      let query = {}

      if (getAll) {
        const queries = [
          'export_today',
          'export_yesterday',
          'export_this_week',
          'export_last_week',
          'export_this_month',
          'export_last_month',
          'export_this_year',
          'export_last_year',
          'export_from_date',
          'export_to_date',
        ]

        queries.map((key) => {
          if (Object.keys(paramsFilter).includes(key)) query[key] = paramsFilter[key]
        })
      } else query = { ...paramsFilter }

      delete query.page
      delete query.page_size
      setLoading(true)
      const res = await exportReportExportInventory({
        ...query,
        is_packed: true,
        pack_status: 'COMPLETE',
      })
      setLoading(false)
      if (res.status === 200) {
        const link = res.data.data
        if (link) saveAs(link, getFileName(link))
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const _getReportExportInventory = async () => {
    try {
      setLoading(true)
      const res = await getExportOrders({
        ...paramsFilter,
        is_packed: true,
        pack_status: 'COMPLETE',
      })
      if (res.status === 200) {
        setInventoryCount(res.data.count || 0)
        let dataReport = []
        res.data.data.map((data) => {
          if (data.products && data.products.length)
            data.products.map((product) => dataReport.push({ ...data, product_info: product }))
          else dataReport.push({ ...data, product_info: {} })
        })
        setReportExportInventory(dataReport)
      }
      setLoading(false)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
      setLoading(false)
    }
  }

  const _getCustomers = async () => {
    try {
      const res = await getAllCustomer()
      if (res.status === 200) setCustomers(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  useEffect(() => {
    _getReportExportInventory()
    saveFiltersToLocalStorage(paramsFilter, KEY_FILTER)
  }, [paramsFilter])

  useEffect(() => {
    _getGroups()
    _getTypes()
    _getUsers()
    _getCustomers()
  }, [])

  return (
    <div className={styles['reportInventory']}>
      <Row className="page-title" justify="space-between" style={{ marginBottom: '25px' }}>
        <h3>Báo cáo xuất kho</h3>
        <Space>
          <Button type="primary" icon={<ReloadOutlined />} onClick={_getReportExportInventory}>
            Làm mới
          </Button>
          <Button
            onClick={() => _getReportExportInventoryToExport()}
            icon={<VerticalAlignTopOutlined />}
            type="primary"
            className="btn-export"
          >
            Xuất theo bộ lọc
          </Button>
          <Button
            onClick={() => _getReportExportInventoryToExport(true)}
            icon={<VerticalAlignTopOutlined />}
            type="primary"
            className="btn-export"
          >
            Xuất toàn bộ
          </Button>
          <SettingColumns
            columns={columns}
            columnsRender={reportInventoryColumns}
            setColumns={setColumns}
            nameColumn="columnsReportExportWarehouse"
          />
        </Space>
      </Row>
      <div className={styles['reportInventory-content']}>
        <Row gutter={15} justify="space-between" style={{ marginBottom: 15 }}>
          <Col span={10}>
            <Input.Group
              compact
              style={{
                width: '100%',
                boxShadow: '0px 2px 6px rgba(19, 216, 229, 0.13)',
                marginRight: 30,
              }}
            >
              <Input
                value={valueSearch}
                onChange={_onSearch}
                allowClear
                style={{ width: '65%' }}
                placeholder="Tìm kiếm theo tiêu chí"
              />
              <Select
                defaultValue="makhachhang"
                suffixIcon={<SuffixIconCustom />}
                style={{ width: '35%' }}
                onChange={_selectOption}
                value={optionSearch}
              >
                <Option value="product_code">Mã sản phẩm</Option>
                <Option value="customer_order_code">Số phiếu</Option>
              </Select>
            </Input.Group>
          </Col>

          <Col span={7}>
            <Select
              suffixIcon={<SuffixIconCustom />}
              style={{ width: '100%' }}
              placeholder="Lọc theo nhân viên xuất vị trí"
              allowClear
              showSearch
              optionFilterProp="children"
              value={paramsFilter.employee_id}
              onChange={(value) => onFilter('employee_id', value)}
            >
              {users.map((user, index) => (
                <Select.Option key={index} value={user.user_id}>
                  {user.fullname}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={7}>
            <FilterDateExport
              allowClear={false}
              paramsFilter={paramsFilter}
              setParamsFilter={setParamsFilter}
            />
          </Col>
        </Row>
        <Row gutter={15} style={{ marginBottom: 15 }}>
          <Col span={5}>
            <Select
              suffixIcon={<SuffixIconCustom />}
              placeholder="Lọc theo nhóm hàng"
              style={{ width: '100%' }}
              allowClear
              showSearch
              optionFilterProp="children"
              value={paramsFilter.group_id}
              onChange={(value) => onFilter('group_id', value)}
            >
              {groups.map((group, index) => (
                <Select.Option value={group.category_id} key={index}>
                  {group.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={5}>
            <Select
              suffixIcon={<SuffixIconCustom />}
              style={{ width: '100%' }}
              placeholder="Lọc theo loại hàng"
              allowClear
              showSearch
              optionFilterProp="children"
              value={paramsFilter.type_id}
              onChange={(value) => onFilter('type_id', value)}
            >
              {types.map((type, index) => (
                <Select.Option value={type.category_id} key={index}>
                  {type.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={7}>
            <Select
              suffixIcon={<SuffixIconCustom />}
              allowClear
              filterOption={filterOptionSelect}
              optionFilterProp="children"
              showSearch
              style={{ width: '100%' }}
              placeholder="Lọc theo doanh nghiệp"
              value={paramsFilter['customer_id'] || undefined}
              onChange={(value) => onFilter('customer_id', value)}
            >
              {customers.map((customer, index) => (
                <Select.Option value={customer.customer_id || ''} key={index}>
                  {customer.fullname || ''}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Button
              danger
              type="primary"
              onClick={clearFilters}
              style={{ display: Object.keys(paramsFilter).length <= 3 && 'none' }}
            >
              Xóa bộ lọc
            </Button>
          </Col>
        </Row>
      </div>
      <Table
        style={{ width: '100%' }}
        scroll={{ x: 4000, y: '65vh' }}
        loading={loading}
        size={SIZE_TABLE}
        dataSource={reportExportInventory}
        columns={columns.map((column) => renderNewColumns(column))}
        pagination={{
          position: POSITION_TABLE,
          current: paramsFilter.page,
          pageSize: paramsFilter.page_size,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          total: inventoryCount,
          showQuickJumper: true,
          onChange: (page, pageSize) =>
            setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize }),
        }}
        summary={() => (
          <Table.Summary fixed="bottom">
            <Table.Summary.Row></Table.Summary.Row>
          </Table.Summary>
        )}
        sticky
      />
    </div>
  )
}
