import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useDispatch } from 'react-redux'
import { _addLog } from 'utils'
import styles from 'views/storage-fee/storagefee.module.scss'

//components
import Permission from 'components/permission'

//antd
import { ArrowLeftOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  Row,
  Form,
  Input,
  notification,
  InputNumber,
  Space,
  Checkbox,
  Select,
} from 'antd'

//consts
import { ACTION, PERMISSIONS } from 'consts'

//apis
import { createStorageFee, updateStorageFee } from 'apis/storage-fee'

export default function StorageFeeAdd() {
  const [form] = Form.useForm()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const _createOrUpdateFee = async () => {
    await form.validateFields()
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const dataForm = form.getFieldsValue()

      console.log(location.state)
      let res
      if (location.state) res = await updateStorageFee(location.state.storage_fee_id, dataForm)
      else res = await createStorageFee(dataForm)

      if (res.status === 200) {
        notification.success({
          message: location.state
            ? 'Cập nhật chi phí dịch vụ kho thành công!'
            : 'Tạo chi phí dịch vụ kho thành công!',
        })
        history.goBack()
      } else
        notification.error({
          message:
            res.data.message ||
            (location.state
              ? 'Cập nhật chi phí dịch vụ kho thất bại!'
              : 'Tạo chi phí dịch vụ kho thất bại!'),
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      _addLog(`Create or update fee: ${JSON.stringify(error)}`)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  useEffect(() => {
    if (location.state) {
      form.setFieldsValue(location.state)
    }
  }, [])

  return (
    <div>
      <Row className="page-title" justify="space-between" style={{ marginBottom: '25px' }}>
        <h3 style={{ cursor: 'pointer' }} onClick={() => history.goBack()}>
          <ArrowLeftOutlined style={{ marginRight: '10px' }} />
          {location.state ? `Cập nhật` : 'Tạo'} chi phí lưu kho{' '}
          <b>{location.state && `#${location.state.fee_name}`}</b>
        </h3>
        <Space size="large">
          <Button
            type="primary"
            danger
            style={{ minWidth: '100px' }}
            onClick={() => history.goBack()}
          >
            Hủy
          </Button>
          <Permission
            permissions={
              location.state ? [PERMISSIONS.cap_nhat_chi_phi_kho] : [PERMISSIONS.tao_chi_phi_kho]
            }
          >
            <Button
              style={{ minWidth: '100px' }}
              onClick={() => _createOrUpdateFee()}
              className={styles['storage-fee-add-button--orange']}
            >
              {location.state ? 'Lưu' : 'Tạo'}
            </Button>
          </Permission>
        </Space>
      </Row>
      <Form form={form} layout="vertical" style={{ padding: '0 50px' }}>
        <Row gutter={100} style={{ marginBottom: '100px' }}>
          <Col span={12}>
            <Form.Item
              label="Tên chi phí"
              name="fee_name"
              rules={[{ required: true, message: 'Vui lòng nhập tên chi phí' }]}
            >
              <Input allowClear placeholder="Nhập tên chi phí" />
            </Form.Item>
            <Form.Item
              label="Đơn giá"
              name="fee"
              rules={[{ required: true, message: 'Vui lòng nhập đơn giá' }]}
            >
              <InputNumber
                allowClear
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: '100%' }}
                min={0}
                placeholder="Nhập đơn giá"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="CBM áp dụng tối thiểu"
              name="min_quantity"
              rules={[{ required: true, message: 'Vui lòng nhập CBM áp dụng tối thiểu' }]}
            >
              <InputNumber
                allowClear
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: '100%' }}
                min={0}
                placeholder="Nhập CBM áp dụng tối thiểu"
              />
            </Form.Item>
            <Form.Item
              label="CBM áp dụng tối đa"
              name="max_quantity"
              rules={[{ required: true, message: 'Vui lòng nhập CBM áp dụng tối đa' }]}
            >
              <InputNumber
                allowClear
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: '100%' }}
                min={0}
                placeholder="Nhập CBM áp dụng tối đa"
              />
            </Form.Item>
            <Form.Item
              label={<lable style={{ fontWeight: 500, fontSize: 16 }}>Mô tả</lable>}
              name="description"
              style={{ color: 'red' }}
            >
              <Input.TextArea allowClear placeholder="Nhập mô tả " />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
