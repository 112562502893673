import { useState } from 'react'
//styles
import styles from './transported-transfer.module.scss'
import ZoomImage from 'components/zoom-image'

//libs
import { formatCash } from 'utils'

//ants
import { Button, Col, Row, Table, Popconfirm, Collapse } from 'antd'

//const
import { IMAGE_DEFAULT, SIZE_TABLE } from 'consts'

export default function TableExpand({ record, _updateExportOrders }) {
  const { shipping_info, expenses } = record

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      align: 'center',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Tên phụ liệu',
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: 'Tổng tiền',
      dataIndex: 'total_cost',
      align: 'center',
      render: (text) => formatCash(text) + ' VND',
    },
  ]

  const columnsExpense = [
    {
      title: 'Mã phụ liệu',
      dataIndex: 'expense_code',
    },
    {
      title: 'Tên phụ liệu',
      dataIndex: 'expense_name',
    },
    {
      title: 'Chi phí',
      render: (text, record) => formatCash(record.fee || 0),
    },
    {
      width: 400,
      title: 'Mô tả',
      dataIndex: 'description',
    },
  ]

  const columnsProduct = [
    {
      title: 'Mã sản phẩm',
      render: (text, record) => (record?.barcode ? record?.barcode : record?.code),
    },
    {
      title: 'Hình ảnh',
      render: (text, record) => (
        <ZoomImage
          image={(record.images && record.images.length && record.images[0]) || IMAGE_DEFAULT}
        >
          <img
            src={(record.images && record.images.length && record.images[0]) || IMAGE_DEFAULT}
            alt=""
            style={{ width: 45, height: 45 }}
          />
        </ZoomImage>
      ),
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
    },

    {
      title: 'Số lượng đóng gói',
      dataIndex: 'quantity_in_package',
    },
    {
      title: 'Giá tiền',
      render: (text, record) => formatCash(record.price) + ' VNĐ',
    },
    {
      title: 'Tổng tiền',
      render: (text, record) => formatCash(record.quantity_in_package * record.price) + ' VNĐ',
    },
  ]

  const _addressRender = (prefix) => {
    //prefix có thể là return, to, shipping_company
    const location = ['address', 'ward', 'district', 'province']
    const locationWPrefix = location.map((item) => {
      return prefix + '_' + item
    })

    let locationWPrefixVals = []
    locationWPrefix.forEach((item) => {
      if (shipping_info[item]) {
        locationWPrefixVals.push(shipping_info[item])
      }
    })
    return locationWPrefixVals.filter((item) => item).join(', ')
  }

  const _onHandleReport = async () => {
    const body = { transport_status: 'REPORT' }
    _updateExportOrders(record.order_id, body)
  }

  return (
    <div className={styles['transport-transfer-table-expand']}>
      <Row style={{ marginBottom: 20 }}>
        <Col span={8}>
          <div className={styles['transport-transfer-shipping-company']}>
            <div>
              <h4>Đơn vị vận chuyển: {shipping_info.shipping_company_name || ''}</h4>
              <p style={{ marginBottom: '0' }}> {_addressRender('shipping_company')}</p>
              <p>Hotline: {shipping_info.shipping_company_phone || ''}</p>
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div
            style={{
              width: '100%',
              height: '100%',
              paddingRight: '10px',
              cursor: 'pointer',
            }}
          >
            <div className={styles['transport-transfer-personal-info']}>
              <h4>Người gửi: {shipping_info.return_name}</h4>
              <p style={{ marginBottom: '0' }}> {_addressRender('return')}</p>
              <p>{shipping_info.return_phone}</p>
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div style={{ width: '100%', height: '100%', cursor: 'pointer' }}>
            <div className={styles['transport-transfer-personal-info']}>
              <h4>Người nhận: {shipping_info.to_name}</h4>
              <p style={{ marginBottom: '0' }}>{_addressRender('to')}</p>
              <p>{shipping_info.to_phone}</p>
            </div>
          </div>
        </Col>
      </Row>

      <b>DANH SÁCH KIỆN HÀNG</b>
      <div style={{ width: '100%' }}>
        {record.packages.map((pkg, index) => (
          <Collapse
            defaultActiveKey={Array.from(Array(record.packages.length).keys())}
            key={index}
            style={{ marginBottom: 10 }}
          >
            <Collapse.Panel key={index} header={<div>Mã kiện hàng: {pkg.package_code}</div>}>
              <Table
                style={{ width: '100%' }}
                rowKey="product_id"
                size="small"
                columns={columnsProduct}
                dataSource={pkg.products}
                pagination={false}
                expandable={{
                  defaultExpandAllRows: true,
                  expandedRowRender: (recordProduct) => {
                    return (
                      <div>
                        <b>DANH SÁCH PHỤ LIỆU</b>
                        <Table
                          dataSource={recordProduct.expense}
                          size="small"
                          pagination={false}
                          columns={columnsExpense}
                        />
                      </div>
                    )
                  },
                }}
              />
            </Collapse.Panel>
          </Collapse>
        ))}
      </div>

      <Row justify="space-between">
        <Col>
          {shipping_info.shipping_employee && shipping_info.shipping_employee.employee_name && (
            <>
              <h4>Thông tin nhân viên ĐVVC</h4>
              <p style={{ marginBottom: 0 }}>{shipping_info.shipping_employee.employee_name}</p>
              <p>{shipping_info.shipping_employee.employee_code}</p>
            </>
          )}
        </Col>
        <Col style={{ display: 'flex', gap: '10px', marginRight: '20px' }}>
          {record.transport_status !== 'COMPLETE' && (
            <>
              <Button type="primary">Nhân viên giao</Button>
              <Button className={styles['transport-transfer-button--pink']}>
                Đơn vị vận chuyển
              </Button>

              <Popconfirm
                title="Bạn có muốn báo cáo đơn hàng này không ?"
                onConfirm={() => _onHandleReport()}
                okText="Đồng ý"
                cancelText="Từ chối"
              >
                <Button
                  style={{
                    minWidth: 100,
                    display: record.transport_status === 'REPORT' ? 'none' : '',
                  }}
                  className={styles['transport-transfer-button--darkred']}
                  onClick={(e) => e.preventDefault()}
                >
                  Báo cáo
                </Button>
              </Popconfirm>
            </>
          )}
        </Col>
      </Row>
    </div>
  )
}
