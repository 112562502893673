import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useDispatch } from 'react-redux'
import { filterOptionSelect, formatCash, _addLog } from 'utils'
import styles from 'views/storage-fee/storagefee.module.scss'

//components
import Permission from 'components/permission'
import FilterDate from 'components/filter-date'

//antd
import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  Row,
  Form,
  Input,
  notification,
  InputNumber,
  Space,
  Checkbox,
  Select,
  Radio,
  Spin,
  Table,
  Popconfirm,
} from 'antd'

//consts
import { ACTION, PERMISSIONS } from 'consts'

//apis
import { getAllCustomer } from 'apis/customer'
import { getWarehouses } from 'apis/warehouse'
import { getOrders } from 'apis/order'
import { getExportOrders } from 'apis/export-order'
import { createFeeInOrder, getStorageFeesDefault } from 'apis/fee-in-order'

export default function StorageFeeAdd() {
  const [form] = Form.useForm()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const typingTimeoutRef = useRef(null)

  const [customers, setCustomers] = useState([])
  const [warehouses, setWarehouses] = useState([])
  const [orders, setOrders] = useState([])
  const [feesTable, setFeesTable] = useState([])

  const [loadingOrder, setLoadingOrder] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const [typeOrder, setTypeOrder] = useState('')
  const [customerId, setCustomerId] = useState('')
  const [warehouseId, setWarehouseId] = useState('')
  const [paramsFilter, setParamsFilter] = useState({})

  const columns = [
    {
      title: 'STT',
      width: 60,
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Loại phát sinh',
      width: 230,
      render: (text, record) => record.group_info && record.group_info.name,
    },
    {
      width: 300,
      title: 'Tên chi phí',
      render: (text, record) => record.fee_name || record.expense_name || '',
    },
    {
      width: 240,
      title: 'Đơn giá',
      render: (text, record, index) => (
        <InputNumber
          style={{ width: '100%' }}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          onBlur={(e) => {
            const value = e.target.value.replaceAll(',', '')
            if (!value) {
              const feesTableNew = [...feesTable]
              feesTableNew[index].fee = 1
              feesTableNew[index].payment_value = 1
              setFeesTable([...feesTableNew])
            }
          }}
          onChange={(value) => {
            if (value) {
              const feesTableNew = [...feesTable]
              feesTableNew[index].fee = +value
              feesTableNew[index].payment_value = +value * +(record.fee || 0)
              setFeesTable([...feesTableNew])
            }
          }}
          value={record.fee || 1}
          placeholder="Nhập đơn giá"
          min={1}
        />
      ),
    },
    {
      width: 180,
      title: 'Số CBM cao nhất',
      render: (text, record, index) => (
        <InputNumber
          style={{ width: '100%' }}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          onBlur={(e) => {
            const value = e.target.value.replaceAll(',', '')
            if (!value) {
              const feesTableNew = [...feesTable]
              feesTableNew[index].unit_quantity = 0
              feesTableNew[index].payment_value = 0
              setFeesTable([...feesTableNew])
            }
          }}
          onChange={(value) => {
            if (value) {
              const feesTableNew = [...feesTable]
              feesTableNew[index].unit_quantity = +value
              feesTableNew[index].payment_value = +value * +(record.fee || 0)
              setFeesTable([...feesTableNew])
            }
          }}
          value={record.unit_quantity || 0}
          placeholder="Nhập số lượng"
          min={0}
        />
      ),
    },
    {
      title: 'Thành tiền',
      render: (text, record) => formatCash(record.payment_value || 0),
    },
    {
      title: '',
      width: 35,
      render: (text, record, index) => (
        <Popconfirm
          onConfirm={() => {
            const feesTableNew = [...feesTable]
            feesTableNew.splice(index, 1)
            setFeesTable([...feesTableNew])
          }}
          title="Bạn có muốn xóa chi phí này không?"
        >
          <DeleteOutlined style={{ color: 'red', fontSize: 18, cursor: 'pointer' }} />
        </Popconfirm>
      ),
    },
  ]

  const columnsDetail = [
    {
      title: 'STT',
      width: 60,
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Ngày',
      dataIndex: 'date',
    },
    {
      title: 'Trước CBM',
      dataIndex: 'before_cbm',
    },
    {
      title: 'Số CMB tăng',
      dataIndex: 'increase_cbm',
    },
    {
      title: 'Số CMB giảm',
      dataIndex: 'decrease_cbm',
    },
    {
      title: 'Sau CBM',
      dataIndex: 'after_cbm',
    },
  ]

  const _getStorageFeesDefault = async () => {
    try {
      setLoadingTable(true)
      const res = await getStorageFeesDefault({
        ...paramsFilter,
        customer_id: customerId,
        warehouse_id: warehouseId,
      })
      setLoadingTable(false)
      if (res.status === 200) setFeesTable(res.data.data)
      console.log(res)
    } catch (error) {
      setLoadingTable(false)
      console.log(error)
    }
  }

  const _getCustomers = async () => {
    try {
      const res = await getAllCustomer({ get_all: true })
      if (res.status === 200) setCustomers(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const _getOrders = async (search = '') => {
    try {
      setOrders([])
      setLoadingOrder(true)
      if (typeOrder === 'import') form.setFieldsValue({ import_order_id: undefined })
      else form.setFieldsValue({ export_order_id: undefined })
      let res
      if (typeOrder === 'import')
        res = await getOrders({ search: search, page: 1, page_size: 50, customer_id: customerId })
      else
        res = await getExportOrders({
          search: search,
          page: 1,
          page_size: 50,
          customer_id: customerId,
        })
      setLoadingOrder(false)
      console.log(res)
      if (res.status === 200) setOrders(res.data.data)
    } catch (error) {
      setLoadingOrder(false)
      console.log(error)
    }
  }

  const _getWarehouses = async () => {
    try {
      const res = await getWarehouses({ get_all: true })
      if (res.status === 200) setWarehouses(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const _addFee = async () => {
    await form.validateFields()
    try {
      const dataForm = form.getFieldsValue()

      if (feesTable.length === 0) {
        notification.error({ message: 'Vui lòng thời gian tính phí' })
        return
      }

      dispatch({ type: ACTION.LOADING, data: true })

      const body = {
        ...dataForm,
        import_order_id: null,
        export_order_id: null,
        fee_infos: feesTable.map((fee) => ({
          ...fee,
          unit_quantity: fee.unit_quantity || 0,
          payment_value: fee.payment_value || 0,
        })),
      }

      const res = await createFeeInOrder(body)

      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Thêm chi phí lưu kho thành công!' })
          history.goBack()
        } else notification.error({ message: res.data.message || 'Thêm chi phí lưu kho thất bại!' })
      } else notification.error({ message: res.data.message || 'Thêm chi phí lưu kho thất bại!' })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      _addLog(`Create or update other fee: ${JSON.stringify(error)}`)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  useEffect(() => {
    if (location.state) form.setFieldsValue(location.state)

    _getCustomers()
    _getWarehouses()
  }, [])

  useEffect(() => {
    if (warehouseId && customerId && Object.keys(paramsFilter).length) _getStorageFeesDefault()
  }, [warehouseId, customerId, paramsFilter])

  useEffect(() => {
    if (typeOrder && customerId) _getOrders()
  }, [typeOrder, customerId])

  return (
    <div>
      <Row className="page-title" justify="space-between" style={{ marginBottom: '25px' }}>
        <h3 style={{ cursor: 'pointer' }} onClick={() => history.goBack()}>
          <ArrowLeftOutlined style={{ marginRight: '10px' }} />
          Thêm chi phí lưu kho vào bảng kê chi phí
        </h3>
        <Space size="large">
          <Button
            type="primary"
            danger
            style={{ minWidth: '100px' }}
            onClick={() => history.goBack()}
          >
            Hủy
          </Button>

          <Button
            style={{ minWidth: '100px' }}
            onClick={_addFee}
            className={styles['storage-fee-add-button--orange']}
          >
            Lưu
          </Button>
        </Space>
      </Row>
      <Form form={form} layout="vertical" style={{ padding: '0 20px' }}>
        <Row gutter={40}>
          <Col span={12}>
            <Form.Item
              rules={[{ message: 'Vui lòng chọn doanh nghiệp', required: true }]}
              name="customer_id"
              label="Doanh nghiệp"
            >
              <Select
                onChange={setCustomerId}
                value={customerId}
                showSearch
                filterOption={filterOptionSelect}
                style={{ width: '100%' }}
                placeholder="Chọn doanh nghiệp"
              >
                {customers.map((customer) => (
                  <Select.Option value={customer.customer_id} key={customer.customer_id}>
                    {customer.fullname}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{ message: 'Vui lòng chọn kho phát sinh', required: true }]}
              name="warehouse_id"
              label="Kho phát sinh"
            >
              <Select
                value={warehouseId}
                onChange={setWarehouseId}
                showSearch
                filterOption={filterOptionSelect}
                style={{ width: '100%' }}
                placeholder="Chọn kho phát sinh"
              >
                {warehouses.map((warehouse) => (
                  <Select.Option value={warehouse.warehouse_id} key={warehouse.warehouse_id}>
                    {warehouse.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {/* <Col span={12}>
            <Row align="middle" style={{ marginTop: 10 }}>
              <Row style={{ fontWeight: 600, fontSize: 16, marginRight: 5 }}>
                Loại đơn phát sinh:{' '}
              </Row>
              <div>
                <Radio.Group onChange={(e) => setTypeOrder(e.target.value)} value={typeOrder}>
                  <Radio value="import">Đơn nhập</Radio>
                  <Radio value="export">Đơn xuất</Radio>
                </Radio.Group>
              </div>
            </Row>
            {typeOrder && (
              <Form.Item
                rules={[{ message: 'Vui lòng chọn đơn phát sinh', required: true }]}
                name={typeOrder === 'import' ? 'import_order_id' : 'export_order_id'}
                label="Đơn phát sinh"
              >
                <Select
                  notFoundContent={loadingOrder ? <Spin /> : null}
                  showSearch
                  filterOption={filterOptionSelect}
                  style={{ width: '100%' }}
                  placeholder="Tìm kiếm theo mã đơn"
                  onSearch={(value) => {
                    if (typingTimeoutRef.current) {
                      clearTimeout(typingTimeoutRef.current)
                    }
                    typingTimeoutRef.current = setTimeout(async () => {
                      _getOrders(value)
                    }, 650)
                  }}
                >
                  {orders.map((order) => (
                    <Select.Option order={order.order_id} key={order.order_id}>
                      {order.code}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col> */}
          <Col span={12}>
            <div>
              <Row wrap={false} style={{ fontSize: 16 }}>
                <div style={{ color: 'red', marginRight: 8 }}>*</div> Thời gian tính phí
              </Row>
              <FilterDate
                allowClear={false}
                placeholder="Chọn thời gian tính phí"
                paramsFilter={paramsFilter}
                setParamsFilter={setParamsFilter}
              />
            </div>
          </Col>
          {customerId && warehouseId && Object.keys(paramsFilter).length ? (
            <Col span={24}>
              <br />
              <b>Danh sách chi phí lưu kho</b>
              <Table
                rowKey="storage_fee_id"
                loading={loadingTable}
                dataSource={feesTable}
                columns={columns}
                size="small"
                pagination={false}
                expandable={{
                  expandedRowRender: (record) => (
                    <Table dataSource={record.details || []} columns={columnsDetail} size="small" />
                  ),
                }}
              />
            </Col>
          ) : (
            ''
          )}
        </Row>
      </Form>
    </div>
  )
}
