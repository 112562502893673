//lib
import { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import styles from './settingbox.module.scss'
import { _addLog, saveFiltersToLocalStorage, isAcceptPermissions } from 'utils'

//antd
import { Content } from 'antd/lib/layout/layout'
import {
  Button,
  notification,
  Popconfirm,
  Table,
  Tag,
  Modal,
  Form,
  Checkbox,
  Space,
  Select,
  InputNumber,
  Row,
  Col,
  Input,
  Tooltip,
} from 'antd'

//api
import { getBaskets, addBasket, updateBasket, deleteBasket } from 'apis/basket.js'
import { getWarehouses } from 'apis/warehouse.js'

//components
import eraser from 'assets/images/eraser.png'
import columnsBasket from './columnsBasket.js'
import { POSITION_TABLE, PAGE_SIZE_OPTIONS, SIZE_TABLE, PRINTER, PERMISSIONS } from 'consts'
import SettingColumnsButton from 'components/setting-columns'
import SuffixIconCustom from 'components/suffixIconCustom'
import BackToTop from 'components/back-to-top'
import FilterDate from 'components/filter-date'

import { DeleteOutlined, ReloadOutlined } from '@ant-design/icons'
import printToThermalPrinter from 'components/thermal-printer/print'
import { barcode } from 'components/thermal-printer/templates'
import { getPrint } from 'apis/print'
import Permission from 'components/permission'
import ModalOptionsPrint from 'components/modal-opions-print'

const { Option } = Select
export default function SettingBox() {
  const [createForm] = Form.useForm()
  const typingTimeoutRef = useRef()
  const KEY_FILTER = 'settingBoxFilters'
  const filters = localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))

  const [loading, setLoading] = useState(false)
  const [selectKeys, setSelectKeys] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [basketUpdate, setBasketUpdate] = useState()
  const [paramsFilter, setParamsFilter] = useState(
    filters ? { ...filters } : { page: 1, page_size: 10 }
  )
  const [basketsData, setBasketsData] = useState([])
  const [countBasket, setCountBasket] = useState(0)
  const [columns, setColumns] = useState([])

  const [warehouseData, setWarehouseData] = useState([])
  const [valueSearch, setValueSearch] = useState(filters ? filters.search || '' : '')

  const _getBaskets = async () => {
    try {
      setSelectKeys([])
      setLoading(true)
      const res = await getBaskets({ ...paramsFilter, warehouse_info: true })
      if (res.data.success) {
        setCountBasket(res.data.count)
        setBasketsData(res.data.data)
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const _getAllWarehouse = async () => {
    try {
      const res = await getWarehouses()
      if (res.data.success) {
        setWarehouseData(res.data.data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const _getBasketUpdate = (data) => {
    setBasketUpdate(data)
    setIsModalVisible(true)
  }

  const _deleteBasket = async (id) => {
    try {
      setLoading(true)
      const res = await deleteBasket({ basket_id: [id] })
      setLoading(false)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: res.data.message || 'Xóa thùng đựng thành công' })
          _getBaskets()
        } else notification.error({ message: res.data.message || 'Xóa thùng đựng thất bại' })
      } else notification.error({ message: res.data.message || 'Xóa thùng đựng thất bại' })
    } catch (err) {
      console.log(err)
      setLoading(true)
    }
  }

  const _onSearch = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value
      if (value) paramsFilter.order_search = value
      else delete paramsFilter.order_search
      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 650)
  }

  const _printBarcode = async (res) => {
    const params = {
      warehouse_id: res.warehouse_id || res[0].warehouse_id,
      name: PRINTER.THUNG_DUNG,
    }
    const dataPrinter = await _getPrint(params)
    if (basketUpdate) {
      // res update là 1 basket
      printToThermalPrinter(
        barcode,
        { barcode: res.code, title: 'Thung dung: ' + res.slug_name?.replace(/-{1,}/g, ' ') },
        dataPrinter && dataPrinter.ip
      )
    } else {
      // res create là mảng basket
      res.forEach((basket) => {
        printToThermalPrinter(
          barcode,
          {
            barcode: basket.code,
            title: 'Thung dung: ' + basket.slug_name?.replace(/-{1,}/g, ' '),
          },
          dataPrinter && dataPrinter.ip
        )
      })
    }
  }

  const _getPrint = async (params) => {
    try {
      const res = await getPrint(params)
      if (res.status === 200) {
        return res.data.data[0]
      }
      return ''
    } catch (error) {
      console.log(error)
      _addLog(JSON.stringify(error))
      return ''
    }
  }
  const _printBarcodeRows = async (record) => {
    const params = {
      warehouse_id: record.warehouse_id,
      name: PRINTER.THUNG_DUNG,
    }
    const dataPrinter = await _getPrint(params)
    printToThermalPrinter(
      barcode,
      { barcode: record.code, title: 'Thung dung: ' + record.slug_name?.replace(/-{1,}/g, ' ') },
      dataPrinter && dataPrinter.ip
    )
  }

  const _createAndUpdateBasket = async () => {
    await createForm.validateFields()
    try {
      const dataForm = createForm.getFieldValue()
      const res = await (basketUpdate
        ? updateBasket(basketUpdate.basket_id, dataForm)
        : addBasket(dataForm))
      if (res.status === 200) {
        if (res.data.success) {
          if (dataForm.autoBarcode) {
            _printBarcode(res.data.data)
          }
          setIsModalVisible(false)
          setBasketUpdate()
          createForm.resetFields()
          _getBaskets()
          notification.success({
            message: `${basketUpdate ? 'Cập nhật' : 'Tạo'} thùng đựng thành công`,
          })
        } else {
          setBasketUpdate()
          setIsModalVisible(false)
          notification.error({
            message:
              res.data.message ||
              `${basketUpdate ? 'Cập nhật' : 'Tạo'} thùng đựng thất bại, vui lòng thử lại`,
          })
        }
      } else {
        notification.error({
          message:
            res.data.message ||
            `${basketUpdate ? 'Cập nhật' : 'Tạo'} thùng đựng thất bại, vui lòng thử lại`,
        })
      }
    } catch (err) {
      console.log(err)
      setIsModalVisible(false)
    }
  }

  const _filterByOption = (attribute = '', value = '') => {
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter, page: 1 })
  }

  const _onDeleteFilter = () => {
    Object.keys(paramsFilter).map((key) => delete paramsFilter[key])
    setSelectKeys([])
    paramsFilter.page = 1
    paramsFilter.page_size = 20
    setParamsFilter({ ...paramsFilter })
  }

  const renderColumns = {
    stt: {
      render(data, record, index) {
        return (paramsFilter.page - 1) * paramsFilter.page_size + index + 1
      },
    },
    name_bucket: {
      render(data, record, index) {
        return (
          <span
            style={{ color: '#0017E3', cursor: 'pointer' }}
            onClick={() => {
              if (isAcceptPermissions([PERMISSIONS.cap_nhat_thung_dung])) _getBasketUpdate(record)
            }}
          >
            {data}
          </span>
        )
      },
    },
    order_waiting: {
      render(data, record) {
        return record.order_waiting ? (
          <Tag color="cyan">Đang sử dụng</Tag>
        ) : (
          <Tag color="green">Sẵn sàng</Tag>
        )
      },
    },
    waiting: {
      render(data, record) {
        return record.order_info && record.order_info.customer_order_code
      },
    },
    create_date: {
      render(date) {
        return moment(date).format('DD/MM/YYYY HH:mm')
      },
    },
    update: {
      render(data, record) {
        return (
          <Space>
            <ModalOptionsPrint
              printWithInternet={() => _printBarcodeRows(record)}
              records={[record]}
              isQRCodeBox={true}
            >
              <Button className={styles['setting-box-button-group-left--barcode']} type="primary">
                In QR Code
              </Button>
            </ModalOptionsPrint>

            <Permission permissions={[PERMISSIONS.xoa_thung_dung]}>
              <Popconfirm
                placement="topRight"
                title="Bạn có muốn xóa thùng đựng này không?"
                cancelText="Từ chối"
                okText="Đồng ý"
                onConfirm={() => _deleteBasket(record.basket_id)}
              >
                <DeleteOutlined style={{ color: 'red', fontSize: 18 }} />
              </Popconfirm>
            </Permission>
          </Space>
        )
      },
    },
    name: {
      render(data, record) {
        return <span>{record.warehouse_info && record.warehouse_info.name}</span>
      },
    },
  }

  useEffect(() => {
    _getAllWarehouse()
  }, [])

  useEffect(() => {
    _getBaskets()
    saveFiltersToLocalStorage(paramsFilter, KEY_FILTER)
  }, [paramsFilter])

  useEffect(() => {
    if (basketUpdate) {
      createForm.setFieldsValue(basketUpdate)
    } else {
      createForm.resetFields()
    }
  }, [basketUpdate])

  return (
    <div className={styles['setting-box']}>
      <BackToTop />
      <Row className="page-title" justify="space-between" style={{ marginBottom: '25px' }}>
        <h3>Thùng đựng</h3>
        <Space>
          {Object.keys(paramsFilter).length > 2 && (
            <Button danger type="primary" onClick={_onDeleteFilter}>
              <img src={eraser} alt="setting-box" />
              Xóa bộ lọc
            </Button>
          )}
          <Button onClick={_getBaskets} type="primary" icon={<ReloadOutlined />}>
            Làm mới
          </Button>
          <SettingColumnsButton
            columns={columns}
            columnsRender={columnsBasket}
            setColumns={setColumns}
            nameColumn="columnsBasket"
          />
          <Permission permissions={[PERMISSIONS.tao_thung_dung]}>
            <Button
              onClick={() => setIsModalVisible(true)}
              className={styles['setting-box-btn--create-basket']}
            >
              Tạo thùng đựng
            </Button>
          </Permission>
        </Space>
      </Row>
      <Content>
        <Row size="large" style={{ marginBottom: 10, width: '100%' }} gutter={20}>
          <Col span={8}>
            <Input
              allowClear
              value={valueSearch}
              onChange={_onSearch}
              placeholder="Tìm kiếm thùng đựng theo mã hàng hoặc mã PO"
            />
          </Col>
          <Col span={6}>
            <Select
              allowClear
              style={{ width: '100%' }}
              placeholder="Lọc theo tên kho"
              suffixIcon={<SuffixIconCustom />}
              onChange={(value) => _filterByOption('warehouse_id', value)}
              value={paramsFilter['warehouse_id'] || undefined}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {warehouseData.map((item, index) => {
                return (
                  <Option key={index} value={item.warehouse_id}>
                    {item.name}
                  </Option>
                )
              })}
            </Select>
          </Col>
          <Col span={6}>
            <FilterDate paramsFilter={paramsFilter} setParamsFilter={setParamsFilter} />
          </Col>
          <Col span={4}>
            <Select
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: '100%' }}
              placeholder="Lọc theo trạng thái"
              suffixIcon={<SuffixIconCustom />}
              onChange={(value) => _filterByOption('order_waiting', value)}
              value={paramsFilter['order_waiting'] || undefined}
            >
              <Option value={'false'}>Sẵn sàng</Option>
              <Option value={'true'}>Đang sử dụng</Option>
            </Select>
          </Col>
        </Row>
        <Table
          rowKey="basket_id"
          size={SIZE_TABLE}
          loading={loading}
          dataSource={basketsData}
          columns={columns.map((e) => {
            if (renderColumns[e.key]) {
              return { ...e, ...renderColumns[e.key] }
            }
            return e
          })}
          pagination={{
            position: POSITION_TABLE,
            current: paramsFilter.page,
            pageSize: paramsFilter.page_size,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            total: countBasket,
            showQuickJumper: true,
            onChange: (page, pageSize) => {
              paramsFilter.page = page
              paramsFilter.page_size = pageSize
              setParamsFilter({ ...paramsFilter })
            },
          }}
        />
      </Content>
      <Modal
        width={554}
        visible={isModalVisible}
        footer={null}
        closable={false}
        className={styles['modal']}
        onCancel={() => {
          setIsModalVisible(false)
          setBasketUpdate()
          createForm.resetFields()
        }}
      >
        <h1 className={styles['modal-title']}>
          {basketUpdate ? 'CẬP NHẬT THÙNG ĐỰNG' : 'TẠO THÙNG ĐỰNG'}
        </h1>
        <Form
          form={createForm}
          onFinish={_createAndUpdateBasket}
          name="basic"
          layout="vertical"
          autoComplete="off"
          className={styles['modal-form']}
        >
          <Form.Item
            wrapperCol={{
              offset: 1,
              span: 20,
            }}
            label="Tên thùng đựng"
            name="name"
            rules={[{ required: true, message: 'Vui lòng nhập tên thùng đựng' }]}
            style={{ marginLeft: '40px' }}
          >
            <Input style={{ width: '100%' }} placeholder="Nhập tên thùng đựng" />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 1,
              span: 20,
            }}
            label="Tên kho"
            name="warehouse_id"
            rules={[{ required: true, message: 'Vui lòng nhập tên kho' }]}
            style={{ marginLeft: '40px' }}
          >
            <Select allowClear placeholder="Chọn tên kho" suffixIcon={<SuffixIconCustom />}>
              {warehouseData.map((item, index) => (
                <Select.Option key={index} value={item.warehouse_id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {!basketUpdate && (
            <Form.Item
              wrapperCol={{
                offset: 1,
                span: 20,
              }}
              label="Số lượng thùng đựng"
              name="basket_quantity"
              rules={[{ required: true, message: 'Vui lòng nhập số lượng thùng đựng' }]}
              style={{ marginLeft: '40px' }}
            >
              <InputNumber
                max={100}
                min={1}
                style={{ width: '100%' }}
                placeholder="Nhập số lượng thùng đựng (tối đa 100 thùng đựng)"
              />
            </Form.Item>
          )}
          <Form.Item style={{ marginLeft: '40px' }} name="autoBarcode" valuePropName="checked">
            <Checkbox>
              <h3>Tự động In QR Code</h3>
            </Checkbox>
          </Form.Item>
          <div className={styles['modal-button-group']}>
            <Form.Item>
              <Button
                key="cancel"
                type="primary"
                onClick={() => {
                  setBasketUpdate()
                  setIsModalVisible(false)
                  createForm.resetFields()
                }}
                className={styles['modal-button--red']}
              >
                <img src={eraser} alt="modal" />
                Hủy
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                key="save-and-exit"
                type="primary"
                htmlType="submit"
                className={styles['modal-button--orange']}
              >
                {basketUpdate ? 'Cập nhật' : 'Tạo'}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  )
}
