import React from 'react'
import moment from 'moment'
import { Form, Row, Button, Input, notification, InputNumber, DatePicker, Modal } from 'antd'

//apis
import { transferInventory } from 'apis/inventory'

export default function Shipping() {
  const [form] = Form.useForm()

  const onShipping = async () => {
    await form.validateFields()
    Modal.confirm({
      title: 'Bạn có muốn chuyển mặt hàng này không?',
      onOk: () => handleShipping(),
    })
  }

  const handleShipping = async () => {
    try {
      const dataForm = form.getFieldsValue()
      const body = {
        export_bucket_code: dataForm.export_bucket_code,
        import_bucket_code: dataForm.import_bucket_code,
        products: [
          {
            barcode: dataForm.barcode,
            mfg: dataForm.mfg ? moment(dataForm.mfg).format('YYYY-MM-DD') : '',
            exp: dataForm.exp ? moment(dataForm.exp).format('YYYY-MM-DD') : '',
            required_quantity: dataForm.required_quantity,
          },
        ],
      }
      const res = await transferInventory(body)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Chuyển hàng thành công' })
          form.resetFields()
        } else
          notification.error({
            message: res.data.message || 'Chuyển hàng thất bại, vui lòng thử lại',
          })
      } else
        notification.error({
          message: res.data.message || 'Chuyển hàng thất bại, vui lòng thử lại',
        })
      //  form.resetFields()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <Row className="page-title" justify="space-between">
        <h3>Chuyển layout</h3>
      </Row>
      <br />
      <div>
        <Form layout="vertical" labelAlign="left" form={form}>
          <Form.Item
            rules={[{ message: 'Vui lòng nhập mã ô kệ', required: true }]}
            label="Chuyển từ ô kệ"
            name="export_bucket_code"
          >
            <Input placeholder="Nhập mã ô kệ" />
          </Form.Item>
          <Form.Item
            rules={[{ message: 'Vui lòng nhập mã hàng cần chuyển', required: true }]}
            label="Mã hàng (mã barcode)"
            name="barcode"
          >
            <Input placeholder="Nhập mã hàng" />
          </Form.Item>
          <Form.Item
            rules={[{ message: 'Vui lòng nhập số lượng cần chuyển', required: true }]}
            initialValue={1}
            label="Số lượng cần chuyển"
            name="required_quantity"
          >
            <InputNumber style={{ width: '100%' }} min={0} placeholder="Nhập số lượng" />
          </Form.Item>
          <Form.Item
            initialValue=""
            label={
              <div style={{ fontSize: 16 }}>
                Ngày sản xuất (nếu có) (ví dụ: 2022-10-10, ngày 10 tháng 10 năm 2022)
              </div>
            }
            name="mfg"
          >
            <DatePicker style={{ width: '100%' }} placeholder="Hạn sử dụng" />
          </Form.Item>

          <Form.Item
            initialValue=""
            label={
              <div style={{ fontSize: 16 }}>
                Hạn sử dụng (nếu có) (ví dụ: 2030-02-02, ngày 02 tháng 02 năm 2030)
              </div>
            }
            name="exp"
          >
            <DatePicker style={{ width: '100%' }} placeholder="Hạn sử dụng" />
          </Form.Item>
          <Form.Item
            rules={[{ message: 'Vui lòng nhập mã ô kệ', required: true }]}
            label="Chuyển đến ô kệ"
            name="import_bucket_code"
          >
            <Input placeholder="Nhập mã ô kệ" />
          </Form.Item>
          <br />
          <Button onClick={onShipping} style={{ minWidth: 150 }} size="large" type="primary">
            Chuyển
          </Button>
        </Form>
      </div>
    </div>
  )
}
