import React, { useEffect, useRef, useState } from 'react'
import styles from './productchecking.module.scss'
import moment from 'moment'
import { useHistory, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ACTION, PAGE_SIZE_OPTIONS, PERMISSIONS, ROUTES } from 'consts'
import {
  formatCash,
  _addLog,
  saveFiltersToLocalStorage,
  handleGetDataExportExcel,
  getFileName,
} from 'utils'
import { useReactToPrint } from 'react-to-print'
import delay from 'delay'

// antd
import {
  Col,
  Row,
  Button,
  Input,
  Select,
  Typography,
  Space,
  Table,
  Badge,
  Modal,
  notification,
  Upload,
  Popover,
  Popconfirm,
} from 'antd'

//icons
import { DeleteOutlined, InboxOutlined, LoadingOutlined, ReloadOutlined } from '@ant-design/icons'

//components
import SuffixIconCustom from 'components/suffixIconCustom'
import ImportFile from 'components/import-csv'
import Export from 'components/export-csv'
import BackToTop from 'components/back-to-top'
import { ComponentPrintBill } from './ButtonPrintBill'
import FilterDate from 'components/filter-date'
import Permission from 'components/permission'
import ModalFees from 'components/modal-fees'
import ModalConfirmDelete from 'components/modal-confirm-delete'
import QrCodeProduct from 'components/qr-code-product'

//apis
import { deleteImportOrders, getOrders, updateOrder } from 'apis/order'
import { uploadImportOrder, uploadFile } from 'apis/upload'
import { getAllCustomer } from 'apis/customer'
import { getWarehouses } from 'apis/warehouse'

const { Text } = Typography
export default function ProductChecking() {
  const history = useHistory()
  const KEY_FILTER = 'productCheckingFilters'
  const filters = localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))

  const [ordersPrint, setOrdersPrint] = useState([])
  const [orders, setOrders] = useState([])
  const [paramsFilter, setParamsFilter] = useState(
    filters
      ? { ...filters }
      : {
          page: 1,
          page_size: 10,
          is_checked: true,
          time_receive: new Date().getHours(),
          check_status: 'WAITING',
        }
  )
  const dispatch = useDispatch()
  const inputRef = useRef(null)
  const printRef = useRef(null)

  const [countOrder, setCountOrder] = useState(0)
  const [loading, setLoading] = useState(false)

  const [warehouse, setWarehouse] = useState()
  const [customer, setCustomer] = useState()
  const [customers, setCustomers] = useState([])
  const [warehouses, setWarehouses] = useState([])
  const [valueSearch, setValueSearch] = useState(filters ? filters.search || '' : '')
  const typingTimeoutRef = useRef()
  const [countOrderOverTime, setCountOrderOverTime] = useState([])
  const [isScannerVisible, setIsScannerVisible] = useState(false)

  const _print = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => {
      setOrdersPrint([])
    },
  })

  const handlePrint = async (orders = []) => {
    setOrdersPrint(orders)
    await delay(100)

    _print()
  }

  const BillOrder = ({ record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    const shippingBill =
      record && record.shipping_info
        ? record.shipping_info.shipping_bill &&
          typeof record.shipping_info.shipping_bill === 'string'
          ? [record.shipping_info.shipping_bill]
          : record.shipping_info.shipping_bill || []
        : []

    const [images, setImages] = useState([])
    const [imagesView, setImagesView] = useState([])
    const [loading, setLoading] = useState(false)

    const addFile = async (file) => {
      setLoading(true)
      const url = await uploadFile(file)
      setImages([url])
      const fileNames = url.split('/')
      const fileName = fileNames[fileNames.length - 1]
      setImagesView([
        { uid: imagesView.length, name: fileName, status: 'done', url: url, thumbUrl: url },
      ])
      setLoading(false)
    }

    const _removeFile = (file) => {
      const imagesNew = [...images]
      const imagesViewNew = [...imagesView]

      const indexImage = images.findIndex((url) => url === file.url)
      const indexImageView = imagesView.findIndex((f) => f.url === file.url)

      if (indexImage !== -1) imagesNew.splice(indexImage, 1)
      if (indexImageView !== -1) imagesViewNew.splice(indexImageView, 1)

      setImages([...imagesNew])
      setImagesView([...imagesViewNew])
    }

    useEffect(() => {
      if (visible) {
        setImages(shippingBill)
        setImagesView(
          shippingBill.map((image, index) => ({
            uid: index,
            name: getFileName(image),
            status: 'done',
            url: image,
            thumbUrl: image,
          }))
        )
      }
    }, [visible])

    return (
      <>
        <Modal
          footer={
            <Row justify="end">
              <Space>
                <Button style={{ minWidth: 100 }} onClick={toggle}>
                  Đóng
                </Button>
                <Button
                  onClick={async () => {
                    await _updateOrder(record.order_id, {
                      ...record,
                      shipping_info: { ...record.shipping_info, shipping_bill: images },
                    })
                    toggle()
                  }}
                  style={{ minWidth: 100 }}
                  type="primary"
                >
                  Lưu
                </Button>
              </Space>
            </Row>
          }
          width={700}
          onCancel={toggle}
          title="Tải lên hình ảnh biên lai"
          visible={visible}
        >
          <Upload.Dragger
            accept="image/*"
            fileList={imagesView}
            listType="picture"
            data={addFile}
            onRemove={_removeFile}
            name="file"
            onChange={(info) => {
              if (info.file.status !== 'done') info.file.status = 'done'
            }}
          >
            <p className="ant-upload-drag-icon">
              {loading ? <LoadingOutlined /> : <InboxOutlined />}
            </p>
            <p className="ant-upload-text">Nhấp hoặc kéo thả vào khu vực này để tải lên</p>
          </Upload.Dragger>
        </Modal>

        <Space>
          <div>
            <Permission permissions={[PERMISSIONS.cap_nhat_danh_sach_xuat_hang]}>
              <Button
                type="primary"
                onClick={toggle}
                style={{ backgroundColor: '#377BFF', borderColor: '#377BFF' }}
              >
                Hình ảnh biên lai
              </Button>
            </Permission>
          </div>
          <div>
            {shippingBill && shippingBill.length ? (
              <Popover
                placement="right"
                content={
                  <img src={shippingBill[0]} style={{ height: '250px', width: '250px' }} alt="" />
                }
              >
                <img src={shippingBill[0]} style={{ height: '30px', width: '30px' }} alt="" />
              </Popover>
            ) : (
              ''
            )}
          </div>
        </Space>
      </>
    )
  }

  const _deleteImport = async (id, note = '') => {
    try {
      setLoading(true)
      const res = await deleteImportOrders({ order_id: [id], note: note })
      setLoading(false)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Xóa đơn hàng thành công' })
          _getOrders()
        } else notification.error({ message: res.data.message || 'Xóa đơn hàng không thành công' })
      } else notification.error({ message: res.data.message || 'Xóa đơn hàng không thành công' })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const columns = [
    {
      dataIndex: 'order_id',
      render: (data, record) => {
        return (
          <Row>
            <Col>
              <Row style={{ fontWeight: 500 }}>
                <Text
                  disabled={record?.check_status === 'PROCESSING' ? true : false}
                  style={{ color: '#0017E3', cursor: 'pointer' }}
                  onClick={() => {
                    // _handleCheckStatus(record)
                    history.push({ pathname: ROUTES.PRODUCT_CHECK_DETAIL, state: record.order_id })
                  }}
                >
                  #{record?.code ? record?.code : record?.order_id}
                </Text>
              </Row>
              <Row>Số phiếu: #{record.customer_order_code}</Row>
              <Row>Doanh nghiệp: {record?.customer_info?.fullname}</Row>

              <Permission permissions={[PERMISSIONS.cap_nhat_bien_lai_phieu_nhap]}>
                <BillOrder record={record} />
              </Permission>
              <Row
                style={{
                  marginTop: 4,
                  fontSize: 12,
                  lineHeight: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  '-webkit-line-clamp': '2',
                  '-webkit-box-orient': 'vertical',
                  display: '-webkit-box',
                }}
              >
                Ghi chú: {record.note || ''}
              </Row>
            </Col>
          </Row>
        )
      },
      width: '35%',
    },
    {
      dataIndex: 'employee_name',
      render: (data, record) => {
        return (
          <Row style={{ display: record?.check_status !== 'WAITING' ? 'flex' : 'none' }}>
            <div>
              Kiểm đếm: {record?.check_employee_info && record?.check_employee_info?.fullname}
            </div>
          </Row>
        )
      },
      width: '30%',
      align: 'center',
    },
    {
      dataIndex: 'create_date',
      render: (data, record) => {
        return (
          <Row justify="end">
            <Col>
              <Row justify="end" style={{ fontWeight: 500 }}>
                Yêu cầu nhập kho lúc {moment(record?.create_date).format(' HH:mm')} ngày{' '}
                {moment(record?.create_date).format('DD/MM/YYYY')}
              </Row>
              {/* <Row justify="end">
                Số lượng yêu cầu nhập kho: {formatCash(record?.products?.length)}
              </Row> */}
              <Row justify="end">
                <Space>
                  {/* <ModalFees record={record} reload={() => _getOrders()} /> */}
                  <Button
                    type="primary"
                    style={{ backgroundColor: '#23A6A6', borderColor: '#23A6A6' }}
                    onClick={async () => {
                      const order = await _getOrderDetail(record.order_id)
                      if (order) handlePrint([order])
                    }}
                  >
                    In biên bản nhận hàng
                  </Button>
                  <Permission permissions={[PERMISSIONS.xoa_don_hang_nhap]}>
                    <ModalConfirmDelete
                      record={record}
                      onDelete={(note) => _deleteImport(record.order_id, note)}
                    >
                      <Button type="primary" icon={<DeleteOutlined />} danger />
                    </ModalConfirmDelete>
                  </Permission>
                </Space>
              </Row>
              <Row justify="end" style={{ marginTop: 8 }}>
                <QrCodeProduct value={record.code} />
              </Row>
            </Col>
          </Row>
        )
      },
      width: '35%',
    },
  ]

  const indicators = [
    {
      title: 'Chờ kiểm hàng',
      bgColor: '#FFCE62',
    },
    {
      title: 'Đang kiểm hàng',
      bgColor: '#13D8E5',
    },
    {
      title: 'Đã kiểm hàng',
      bgColor: '#79CF4F',
    },
    {
      title: 'Có sự cố',
      bgColor: '#D63F3F',
    },
  ]

  const _updateOrder = async (id, body) => {
    try {
      setLoading(true)
      const res = await updateOrder(id, body)
      setLoading(false)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Cập nhật thành công' })
          _getOrders()
        } else
          notification.error({ message: res.data.message || 'Cập nhật thất bại, vui lòng thử lại' })
      } else
        notification.error({ message: res.data.message || 'Cập nhật thất bại, vui lòng thử lại' })
    } catch (error) {
      setLoading(false)
      console.log(error)
      _addLog(`Update order: ${JSON.stringify(error)}`)
    }
  }

  const _getOrders = async () => {
    try {
      setLoading(true)
      const res = await getOrders({ ...paramsFilter })
      if (res.status === 200) {
        setCountOrder(res.data.count)
        setCountOrderOverTime(res.data?.quantity_overtime)
        setOrders(res.data.data)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
      _addLog(JSON.stringify(error))
    }
  }

  const _getCustomers = async () => {
    try {
      const res = await getAllCustomer()
      if (res.status === 200) setCustomers(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _getWarehouses = async () => {
    try {
      const res = await getWarehouses()
      if (res.status === 200) setWarehouses(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _getOrderExport = async (query = {}) => {
    try {
      const data = await handleGetDataExportExcel(getOrders, { ...query })
      if (data) {
        const dataExport = data.map((orders, index) => {
          let order_template = {
            STT: index + 1,
            'Mã đơn hàng': orders?.code || orders?.orders_id || '',
            'Khách hàng': orders?.customer_info && orders.customer_info?.fullname,
            'Mã khách hàng': orders?.customer_info && orders?.customer_info?.code,
            'Kho hàng': orders?.warehouse_info && orders?.warehouse_info?.name,
            'Mã kho hàng': orders?.warehouse_info && orders?.warehouse_info?.code,
            // 'Số lượng yêu cầu nhập kho': orders?.products && orders?.products?.length,
            'Nhân viên kiểm đếm':
              orders?.check_employee_info && orders?.check_employee_info?.fullname,
            'Trạng thái đơn hàng':
              orders?.check_status &&
              ((orders?.check_status === 'WAITING' && 'Chờ kiểm hàng') ||
                (orders?.check_status === 'PROCESSING' && 'Đang kiểm hàng') ||
                (orders?.check_status === 'COMPLETE' && 'Đã kiểm hàng') ||
                (orders?.check_status === 'REPORT' && 'Có sự cố')),
            'Yêu cầu nhập kho lúc': orders?.create_date
              ? moment(orders?.create_date).format('DD-MM-YYYY HH:mm:ss')
              : '',
          }

          return orders?.products?.length
            ? orders?.products?.map((item) => {
                return {
                  ...order_template,
                  'Tên sản phẩm': item?.name || '',
                  'Mã sản phẩm': item?.barcode || item?.code || '',
                  'Nhóm sản phẩm': item?.group_id || '',
                  'Loại sản phẩm': item?.type_id || '',
                  'Chiều dài': item?.length || '',
                  'Chiều rộng': item?.width || '',
                  'Chiều cao': item?.height || '',
                  'Khối lượng': item?.cbm || '',
                  'Số lượng': item?.import_quantity || '',
                  'Quy cách': item?.unit_info ? item?.unit_info?.name : '',
                  'Giá trị': formatCash(item?.price || 0),
                  'Hạn sử dụng':
                    item?.expiry_info && item?.expiry_info?.exp
                      ? moment(item?.expiry_info?.exp).format('DD-MM-YYYY HH:mm')
                      : '',
                  'Ngày sản xuất':
                    item?.expiry_info && item?.expiry_info?.mfg
                      ? moment(item?.expiry_info?.mfg).format('DD-MM-YYYY HH:mm')
                      : '',
                  'Ghi chú': item?.note || '',
                }
              })
            : {
                ...order_template,
                'Tên sản phẩm': '',
                'Mã sản phẩm': '',
                'Nhóm sản phẩm': '',
                'Loại sản phẩm': '',
                'Chiều dài': '',
                'Chiều rộng': '',
                'Chiều cao': '',
                'Khối lượng': '',
                'Số lượng': '',
                'Quy cách': '',
                'Giá trị': '',
                'Hạn sử dụng': '',
                'Ngày sản xuất': '',
                'Ghi chú': '',
              }
        })
        return dataExport.flat(2)
      }
      return []
    } catch (error) {
      console.log(error)
      _addLog(`Export order: ${JSON.stringify(error)}`)
      return []
    }
  }

  const _handleCheckStatus = async (data) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getOrders({ order_id: data.order_id })
      dispatch({ type: ACTION.LOADING, data: false })

      if (res.status === 200) {
        if (res.data.success) {
          const order = res.data.data[0]
          if (order) {
            if (order.check_status === 'COMPLETE' || order.check_status === 'REPORT')
              history.push({ pathname: ROUTES.PRODUCT_CHECK_DETAIL, state: order.order_id })
            else {
              if (order.check_status !== 'PROCESSING') _handleUpdateStatus(order)
              else confirm(order)
            }
          }
        }
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
      _addLog(`Handle check status order: ${JSON.stringify(error)}`)
    }
  }

  const _handleUpdateStatus = async (order) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const body = { check_status: 'PROCESSING' }
      const res = await updateOrder(order.order_id, body)
      dispatch({ type: ACTION.LOADING, data: false })
      if (res.status === 200)
        history.push({ pathname: ROUTES.PRODUCT_CHECK_DETAIL, state: order.order_id })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
      _addLog(`Handle check detail: ${JSON.stringify(error)}`)
    }
  }

  const confirm = (record) => {
    Modal.confirm({
      title: 'Đơn hàng này đang được thực hiện bởi người khác, bạn có muốn thao tác không ?',
      cancelText: 'Không',
      okText: 'Có',
      onOk: () => {
        _handleUpdateStatus(record)
      },
    })
  }

  const _onSearch = (e) => {
    setValueSearch(String(e.target.value).replace(/#/gi, ''))
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value
      if (value) paramsFilter.search = value
      else delete paramsFilter.search
      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 650)
  }

  const _filterByOption = (attribute = '', value = '') => {
    paramsFilter.page = 1

    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter })
  }

  const _clearFilters = (tabReceive) => {
    Object.keys(paramsFilter).map((key) => delete paramsFilter[key])
    setValueSearch('')
    paramsFilter.page = 1
    paramsFilter.page_size = 20
    paramsFilter.time_receive = tabReceive
    paramsFilter.is_checked = true
    paramsFilter.check_status = 'WAITING'
    setParamsFilter({ ...paramsFilter })
  }

  const _onChangeTable = (value) => {
    delete paramsFilter.time_receive
    paramsFilter.time_receive = parseInt(value)
    paramsFilter.page = 1
    setParamsFilter({ ...paramsFilter })
  }

  const _getOrderDetail = async (orderId = '') => {
    try {
      setLoading(true)
      const res = await getOrders({ order_id: orderId })
      setLoading(false)
      if (res.status === 200) {
        const order = res.data.data && res.data.data.length && res.data.data[0]
        if (order) {
          return order
        }
      }
      return null
    } catch (error) {
      setLoading(false)
      console.log(error)
      return null
    }
  }

  useEffect(() => {
    _getOrders()
    saveFiltersToLocalStorage(paramsFilter, KEY_FILTER)
  }, [paramsFilter])

  useEffect(() => {
    _getCustomers()
    _getWarehouses()
  }, [])

  return (
    <div className={styles['product-import']}>
      <BackToTop />
      <div style={{ display: 'none' }}>
        <ComponentPrintBill details={ordersPrint} ref={printRef} />
      </div>
      <Row className="page-title" justify="space-between">
        <h3>Danh sách đơn hàng cần kiểm tra số lượng</h3>

        <Space>
          <Button
            type="primary"
            style={{ backgroundColor: '#23A6A6', borderColor: '#23A6A6' }}
            onClick={async () => {
              const listPromise = orders.map(async (order) => {
                const res = await _getOrderDetail(order.order_id)
                return res
              })
              dispatch({ type: 'LOADING', data: true })
              const ordersNew = await Promise.all(listPromise)
              dispatch({ type: 'LOADING', data: false })

              handlePrint(ordersNew)
            }}
          >
            In biên bản hàng loạt ở trang này
          </Button>
          <Permission permissions={[PERMISSIONS.tao_kiem_hang]}>
            <ImportFile
              keyForm={{ warehouse_id: warehouse, customer_id: customer }}
              width="70%"
              reload={_getOrders}
              upload={uploadImportOrder}
              title={
                <Row wrap={false} align="middle">
                  <div style={{ marginRight: 20, fontWeight: 600 }}>
                    Nhập đơn kiểm hàng bằng excel
                  </div>
                  <div style={{ marginRight: 15 }}>
                    <div style={{ fontSize: 13 }}>Chọn doanh nghiệp</div>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      value={customer}
                      onChange={(value) => {
                        const customer = customers.find((c) => c.customer_id === value)
                        if (customer) setWarehouse(customer.warehouse_id)
                        setCustomer(value)
                      }}
                      placeholder="Chọn doanh nghiệp"
                      size="small"
                      style={{ width: 250 }}
                      allowClear
                    >
                      {customers.map((customer, index) => (
                        <Select.Option key={index} value={customer.customer_id}>
                          {customer.fullname}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                  <div>
                    <div style={{ fontSize: 13 }}>Chọn kho</div>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      value={warehouse}
                      onChange={setWarehouse}
                      placeholder="Chọn kho"
                      size="small"
                      style={{ width: 250 }}
                      allowClear
                    >
                      {warehouses.map((warehouse, index) => (
                        <Select.Option key={index} value={warehouse.warehouse_id}>
                          {warehouse.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </Row>
              }
              warning="Ngày sản xuất và hạn sử dụng nếu để trống sẽ lấy từ sản phẩm"
              fileTemplated="https://s3.ap-northeast-1.wasabisys.com/admin-order/2022/06/02/9b9f5999-bec6-4698-b42d-bc4496cbf0c5/ImportOrderSGL.xlsx"
              action_name="Nhập đơn kiểm hàng bằng file excel"
            />
          </Permission>
          <Permission permissions={[PERMISSIONS.tao_phieu_nhap_kho_thu_cong]}>
            <Link to={ROUTES.IMPORT_WAREHOUSE_CREATE}>
              <Button style={{ backgroundColor: '#377BFF', borderColor: '#377BFF' }} type="primary">
                Thêm phiếu nhập kho thủ công
              </Button>
            </Link>
          </Permission>
        </Space>
      </Row>
      <h4 style={{ marginBottom: 0, fontSize: 17, fontWeight: 600 }}>
        {isScannerVisible ? 'Bắt đầu quét tìm kiếm đơn hàng' : 'Máy quét đang tắt'}
      </h4>
      <Row gutter={10} style={{ marginBottom: '20px' }}>
        <Col span={7}>
          <Input.Search
            autoFocus
            allowClear
            enterButton
            ref={inputRef}
            onBlur={() => setIsScannerVisible(false)}
            onFocus={() => setIsScannerVisible(true)}
            value={valueSearch}
            onChange={_onSearch}
            placeholder={
              isScannerVisible ? 'Quét tìm kiếm mã đơn hàng' : 'Nhấn vào đây để quét đơn hàng'
            }
            style={{ width: '100%', boxShadow: '0px 2px 6px rgba(19, 216, 229, 0.13)' }}
          />
        </Col>
        <Col span={5}>
          <Select
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
            }
            style={{ width: '100%' }}
            value={paramsFilter['customer_id'] || undefined}
            onChange={(value) => _filterByOption('customer_id', value)}
            placeholder="Lọc theo doanh nghiệp"
            suffixIcon={<SuffixIconCustom size="default" />}
          >
            {customers.map((item, index) => (
              <Select.Option key={index} value={item.customer_id}>
                {item.fullname}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={5}>
          <Select
            allowClear
            style={{ width: '100%' }}
            placeholder="Lọc theo trạng thái"
            value={paramsFilter['check_status'] || ''}
            onChange={(value) => _filterByOption('check_status', value)}
            suffixIcon={<SuffixIconCustom size="default" />}
          >
            <Select.Option value="">Tất cả</Select.Option>
            <Select.Option value="WAITING">Chờ kiểm hàng</Select.Option>
            <Select.Option value="PROCESSING">Đang kiểm hàng</Select.Option>
            <Select.Option value="COMPLETE">Đã kiểm hàng</Select.Option>
            <Select.Option value="REPORT">Có sự cố</Select.Option>
          </Select>
        </Col>
        <Col span={5}>
          <FilterDate paramsFilter={paramsFilter} setParamsFilter={setParamsFilter} />
        </Col>

        <Col span={2}>
          <Button
            danger
            onClick={() => _clearFilters(paramsFilter.time_receive)}
            type="primary"
            style={{ display: Object.keys(paramsFilter).length <= 5 && 'none' }}
          >
            Xóa bộ lọc
          </Button>
        </Col>
      </Row>
      <Row justify="space-between">
        <div style={{ display: 'flex', gap: '10px', margin: '10px 0 0 10px' }}>
          {indicators.map((indicator, index) => {
            return (
              <div key={index} style={{ display: 'flex', gap: '5px' }}>
                <div
                  style={{
                    width: '15px',
                    height: '15px',
                    borderRadius: '50%',
                    backgroundColor: indicator.bgColor,
                  }}
                ></div>
                <span style={{ lineHeight: '15px' }}>{indicator.title}</span>
              </div>
            )
          })}
        </div>
        <Space style={{ marginBottom: 7 }}>
          <Export
            text="Xuất theo bộ lọc"
            actionName="Xuất excel danh sách đơn hàng cần kiểm hàng"
            fileName="Danh sách đơn hàng kiểm hàng"
            exportData={() => _getOrderExport(paramsFilter)}
          />
          <Export
            text="Xuất toàn bộ"
            actionName="Xuất excel danh sách đơn hàng cần kiểm hàng"
            fileName="Danh sách đơn hàng kiểm hàng"
            exportData={() => _getOrderExport()}
          />
          <Button onClick={_getOrders} type="primary" icon={<ReloadOutlined />}>
            Làm mới
          </Button>
        </Space>
      </Row>
      <Row>
        <div className={styles['product-import-content']}>
          <Row>
            {countOrderOverTime.map((item, index) => (
              <Col span={2} style={{ margin: '10px 0', textAlign: 'center' }} key={index}>
                <Badge count={item.order_quantity} overflowCount="none">
                  <Button
                    disabled={loading}
                    type={index === paramsFilter.time_receive ? 'primary' : ''}
                    onClick={() => _onChangeTable(index)}
                  >
                    {item.time_receive}
                  </Button>
                </Badge>
              </Col>
            ))}
          </Row>
          <Table
            loading={loading}
            size="small"
            showHeader={false}
            columns={columns}
            rowClassName={(record) => styles[record.check_status]}
            dataSource={orders}
            pagination={{
              current: paramsFilter.page,
              pageSize: paramsFilter.page_size,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              showQuickJumper: true,
              onChange: (page, pageSize) => {
                paramsFilter.page = page
                paramsFilter.page_size = pageSize
                setParamsFilter({ ...paramsFilter })
              },
              total: countOrder,
            }}
          />
        </div>
      </Row>
    </div>
  )
}
