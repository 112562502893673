import React, { useState, useEffect, useRef } from 'react'
import style from './customer-add.module.scss'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { formatCash, _addLog } from 'utils'
import moment from 'moment'
import { ROUTES, ACTION, regexPhone, PERMISSIONS } from 'consts'

//components
import SuffixIconCustom from 'components/suffixIconCustom'
import Permission from 'components/permission'

//antd
import {
  ArrowLeftOutlined,
  EditOutlined,
  InboxOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import {
  Input,
  Row,
  Form,
  Col,
  Button,
  Space,
  Select,
  notification,
  InputNumber,
  Modal,
  Upload,
  DatePicker,
} from 'antd'

//apis
import { addCustomer, updateCustomer } from 'apis/customer'
import { getTypesCustomer, createTypeCustomer } from 'apis/type-customer'
import { getWard, getDistrict, getProvince } from 'apis/address'
import { getFees } from 'apis/fee'
import { uploadFiles, uploadFile } from 'apis/upload'
import { getWarehouses } from 'apis/warehouse'

const { Option } = Select
export default function CustomerAdd() {
  const history = useHistory()
  const dispatch = useDispatch()
  const [createForm] = Form.useForm()

  const [loadingBtn, setLoadingBtn] = useState(false)
  const [nameTypeCreate, setNameTypeCreate] = useState('')
  const [typesCustomer, setTypesCustomer] = useState([])

  const [dataProvince, setDataProvince] = useState([])
  const [dataDistrict, setDataDistrict] = useState([])
  const [dataWard, setDataWard] = useState([])
  const [warehouses, setWarehouses] = useState([])

  const [valueProvince, setValueProvince] = useState('Lào Cai')
  const [valueDistrict, setValueDistrict] = useState('Huyện Xi Ma Cai')

  const [dataFee, setDataFee] = useState([])
  const [selectFees, setSelectFees] = useState([])

  const [filesUpload, setFilesUpload] = useState([])

  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)

  const typingTimeoutRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [imgUpload, setImgUpload] = useState('')

  const _createAndUpdateCustomer = async (value) => {
    value.files = filesUpload.length && filesUpload
    value.avatar = imgUpload
    const body = {
      ...value,
      fees: selectFees,
      tax_code: value.tax_code || '',
      email: value.email || '',
      legal_representative: value.legal_representative || '',
      contract_start_date: value.contract_start_date
        ? moment(value.contract_start_date).format('YYYY-MM-DD')
        : '',
      contract_end_date: value.contract_end_date
        ? moment(value.contract_end_date).format('YYYY-MM-DD')
        : '',
    }
    if (selectFees.length) body.fees = selectFees
    dispatch({ type: ACTION.LOADING, data: true })
    try {
      const res = await (history.location.state
        ? updateCustomer(history.location.state.customer_id, body)
        : addCustomer(body))
      console.log(res)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({
            message: history.location.state
              ? 'Cập nhật doanh nghiệp thành công'
              : 'Tạo doanh nghiệp thành công',
          })
          dispatch({ type: ACTION.LOADING, data: false })
          history.goBack()
        } else
          notification.error({
            message:
              res.data.message ||
              (history.location.state
                ? 'Cập nhật doanh nghiệp thất bại'
                : 'Tạo doanh nghiệp thất bại'),
          })
      } else
        notification.error({
          message:
            res.data.message ||
            (history.location.state
              ? 'Cập nhật doanh nghiệp thất bại'
              : 'Tạo doanh nghiệp thất bại'),
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      _addLog(`Add or update customer: ${JSON.stringify(error)}`)
    }
  }

  const _createTypeCustomer = async () => {
    try {
      if (!nameTypeCreate) {
        notification.warning({ message: 'Vui lòng nhập tên loại doanh nghiệp' })
        return
      }
      setLoadingBtn(true)
      const res = await createTypeCustomer({ name: nameTypeCreate })
      if (res.status === 200) {
        if (res.data.success) {
          await _getTypesCustomer()
          notification.success({ message: 'Thêm loại doanh nghiệp thành công!' })
          setNameTypeCreate('')
        } else
          notification.error({
            message: res.data.mess || res.data.message || 'Thêm loại doanh nghiệp thất bại!',
          })
      } else
        notification.error({
          message: res.data.mess || res.data.message || 'Thêm loại doanh nghiệp thất bại!',
        })

      setLoadingBtn(false)
    } catch (error) {
      setLoadingBtn(false)
      console.log(error)
      _addLog(`create type customer: ${JSON.stringify(error)}`)
    }
  }

  const _onChangeFee = (value) => {
    const selectFeesNew = dataFee.filter((fee) => value.includes(fee.fee_id))
    setSelectFees([...selectFeesNew])
  }

  const _editFee = (value, attribute, index) => {
    const selectFeesNew = [...selectFees]
    selectFeesNew[index][attribute] = value
    setSelectFees([...selectFeesNew])
  }

  const _getTypesCustomer = async () => {
    try {
      const res = await getTypesCustomer()
      if (res.status === 200) {
        const data = res.data.data
        setTypesCustomer(data)
        if (!history.location.state) {
          if (data.length) {
            createForm.setFieldsValue({ type_id: data[0].type_id })
          }
        }
      }
    } catch (error) {
      console.log(error)
      _addLog(JSON.stringify(error))
    }
  }

  const _getDataProvince = async () => {
    try {
      const res = await getProvince()
      if (res.status === 200) {
        setDataProvince(res.data.data)
        if (!history.location.state) {
          if (res.data.data.length) {
            createForm.setFieldsValue({ province: res.data.data[0].province_name })
          }
        }
      }
    } catch (error) {
      console.log(error)
      _addLog(JSON.stringify(error))
    }
  }

  const _getDataDistrict = async (value) => {
    try {
      if (value) {
        const res = await getDistrict(value)
        if (res.status === 200) {
          const data = res.data.data
          setDataDistrict(data)
          setValueDistrict(data[0]?.district_name)
          if (!history.location.state) {
            if (res.data.data.length) {
              createForm.setFieldsValue({ district: data[0]?.district_name })
            }
          }
        }
      }
    } catch (error) {
      console.log(error)
      _addLog(JSON.stringify(error))
    }
  }

  const _getDataWard = async (value) => {
    try {
      const res = await getWard(value)
      if (res.status === 200) {
        setDataWard(res.data.data)
        if (!history.location.state) {
          if (res.data.data.length) {
            createForm.setFieldsValue({ ward: res.data.data[0].ward_name })
          }
        }
      }
    } catch (error) {
      console.log(error)
      _addLog(JSON.stringify(error))
    }
  }

  const _getWarehouses = async () => {
    try {
      const res = await getWarehouses()
      if (res.status === 200) {
        const data = res.data.data
        setWarehouses(data)
        if (!history.location.state)
          if (data.length) createForm.setFieldsValue({ warehouse_id: data[0].warehouse_id })
      }
    } catch (error) {
      console.log(error)
      _addLog(JSON.stringify(error))
    }
  }

  const _getStorageFee = async () => {
    try {
      const res = await getFees()
      if (res.status === 200) {
        const data = res.data.data
        setDataFee(data)
        if (!history.location.state) {
          if (data.length) {
            createForm.setFieldsValue({ fees: data[0].fee_id })
            setSelectFees([data[0]])
          }
        }
      }
    } catch (error) {
      console.log(error)
      _addLog(JSON.stringify(error))
    }
  }

  const _handleUploadFiles = async (info) => {
    if (info.file.status !== 'done') info.file.status = 'done'

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }

    typingTimeoutRef.current = setTimeout(async () => {
      const files = info.fileList.map((item) => item.originFileObj)
      try {
        const urlFiles = await uploadFiles(files)
        if (urlFiles) {
          setFilesUpload(urlFiles)
        }
      } catch (error) {
        console.log(error)
        _addLog(JSON.stringify(error))
      }
    }, 1000)
  }

  const handleAvatar = async (info) => {
    try {
      setLoading(true)
      const urlImg = await uploadFile(info.file.originFileObj)
      if (urlImg) {
        setImgUpload(urlImg)
      }
    } catch (error) {
      console.log(error)
      _addLog(JSON.stringify(error))
    }
    setLoading(false)
  }

  useEffect(() => {
    const dataUpdate = history.location.state
    if (dataUpdate) {
      setSelectFees(dataUpdate.fees)
      createForm.setFieldsValue({
        ...dataUpdate,
        fees: dataUpdate.fees.map((e) => e.fee_id),
        contract_start_date: dataUpdate.contract_start_date
          ? moment(dataUpdate.contract_start_date)
          : '',
        contract_end_date: dataUpdate.contract_end_date ? moment(dataUpdate.contract_end_date) : '',
      })

      const { province, district } = dataUpdate
      setValueProvince(province)
      setValueDistrict(district)
      setImgUpload(dataUpdate.avatar || '')
    }

    _getDataProvince()
    _getTypesCustomer()
    _getStorageFee()
    _getWarehouses()
  }, [])

  useEffect(() => {
    _getDataDistrict({ province_name: valueProvince })
    _getDataWard({ province_name: valueProvince })
  }, [valueProvince])

  useEffect(() => {
    _getDataWard({ district_name: valueDistrict })
  }, [valueDistrict])

  return (
    <Form form={createForm} layout="vertical" onFinish={_createAndUpdateCustomer}>
      <Row
        align="middle"
        style={{
          fontSize: 17,
          paddingBottom: 12,
          borderBottom: '0.75px solid #BBBCBD',
          marginBottom: '20px',
          cursor: 'pointer',
        }}
        justify="space-between"
      >
        <Space onClick={() => history.goBack()}>
          <ArrowLeftOutlined style={{ color: '#000000' }} />
          <p style={{ marginBottom: 0, fontWeight: 600 }}>
            {history.location.state ? 'Cập nhật doanh nghiệp' : 'Tạo doanh nghiệp'}
          </p>
        </Space>
        <Space>
          <Permission
            permissions={
              history.location.state
                ? [PERMISSIONS.cap_nhat_doanh_nghiep]
                : [PERMISSIONS.tao_doanh_nghiep]
            }
          >
            <Button type="primary" className={style['btn-submit']} htmlType="submit">
              {history.location.state ? 'Lưu' : 'Tạo'}
            </Button>
          </Permission>
        </Space>
      </Row>
      <div style={{ padding: '0 50px' }}>
        <Row gutter={100}>
          <Col span={12}>
            <Form.Item
              name="username"
              label="Tên đăng nhập (Trên website merchant)"
              rules={[{ required: true, message: 'Vui lòng nhập tên đăng nhập' }]}
            >
              <Input
                disabled={history.location.state}
                allowClear
                placeholder="Nhập tên đăng nhập"
              />
            </Form.Item>
            {history.location.state ? (
              ''
            ) : (
              <Form.Item
                name="password"
                label="Mật khẩu"
                rules={[{ required: true, message: 'Vui lòng nhập mật khẩu' }]}
              >
                <Input.Password allowClear placeholder="Nhập mật khẩu" />
              </Form.Item>
            )}
            <Form.Item
              name="fullname"
              label="Tên doanh nghiệp"
              rules={[{ required: true, message: 'Vui lòng nhập tên doanh nghiệp' }]}
            >
              <Input allowClear placeholder="Nhập tên doanh nghiệp" />
            </Form.Item>
            <Form.Item
              name="email"
              label={<label style={{ fontSize: 16, fontWeight: 500 }}>Email</label>}
              rules={[{ type: 'email', message: 'Vui lòng nhập đúng định dạng email' }]}
            >
              <Input allowClear placeholder="Nhập tên email doanh nghiệp" />
            </Form.Item>
            <Form.Item
              name="phone"
              label="Số điện thoại"
              rules={[
                { required: true, message: 'Vui lòng nhập số điện thoại ' },
                { pattern: regexPhone, message: 'Vui lòng nhập đúng định dạng số điện thoại' },
              ]}
            >
              <Input
                allowClear
                stringMode
                placeholder="Nhập số điện thoại "
                style={{ width: '100%' }}
                onChange={(e) => {
                  const value = e.target.value
                  if (value) {
                    const areaCode = value.slice(0, 3)
                    let phone = value
                    if (areaCode === '+84') phone = `0${value.slice(3, value.length)}`
                    createForm.setFieldsValue({ phone: phone.replaceAll(' ', '') })
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name="type_id"
              label="Loại doanh nghiệp"
              rules={[{ required: true, message: 'Vui lòng nhập loại doanh nghiệp' }]}
            >
              <Select
                allowClear
                showSearch
                style={{ width: '100%' }}
                placeholder="Loại doanh nghiệp"
                suffixIcon={<SuffixIconCustom />}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input
                        onPressEnter={_createTypeCustomer}
                        value={nameTypeCreate}
                        onChange={(e) => setNameTypeCreate(e.target.value)}
                        placeholder="Nhập tên loại doanh nghiệp"
                      />
                      <Button
                        loading={loadingBtn}
                        onClick={_createTypeCustomer}
                        type="primary"
                        style={{ marginLeft: 7 }}
                      >
                        Tạo mới
                      </Button>
                    </div>
                  </div>
                )}
              >
                {typesCustomer.map((type, index) => (
                  <Option value={type.type_id} key={index}>
                    {type.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Row justify="space-between">
              <Row wrap={false} style={{ fontSize: 15.5, fontWeight: 500, marginBottom: 5 }}>
                <div style={{ color: 'red', marginRight: 5, fontSize: 12 }}>*</div>
                Chi phí doanh nghiệp
              </Row>
              <Button
                style={{ display: (!selectFees || selectFees.length === 0) && 'none' }}
                type="primary"
                size="small"
                onClick={toggle}
              >
                Cập nhật chi phí
              </Button>
              <Modal
                width={780}
                footer={
                  <Row justify="end">
                    <Button onClick={toggle}>Đóng</Button>
                  </Row>
                }
                title="Cập nhật chi phí đã chọn"
                visible={visible}
                onCancel={toggle}
              >
                {selectFees.map((item, index) => {
                  const InputFee = () => (
                    <InputNumber
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      style={{ width: '100%' }}
                      min={0}
                      onBlur={(e) => {
                        const value = e.target.value
                        if (value) {
                          const fee = value.replaceAll(',', '')
                          _editFee(+fee, 'fee', index)
                        } else _editFee(0, 'fee', index)
                      }}
                      defaultValue={item.fee || 0}
                      placeholder="Nhập chi phí mới"
                    />
                  )

                  const InputName = () => (
                    <Input
                      style={{ width: '100%' }}
                      onBlur={(e) => {
                        const value = e.target.value
                        if (value) _editFee(value, 'fee_name', index)
                        else _editFee('', 'fee_name', index)
                      }}
                      defaultValue={item.fee_name || ''}
                      placeholder="Nhập tên chi phí mới"
                    />
                  )
                  return (
                    <Row align="middle" gutter={[16, 16]} style={{ marginBottom: 19 }}>
                      <Col lg={10}>
                        <span style={{ fontSize: 14, fontWeight: 600 }}>{item.fee_name} :</span>
                      </Col>
                      <Col lg={7}>
                        <div>Chi phí</div>
                        <InputFee />
                      </Col>
                      {/* <Col lg={7}>
                          <div>Tên chi phí</div>
                          <InputName />
                        </Col> */}
                    </Row>
                  )
                })}
              </Modal>
            </Row>
            <Form.Item
              name="fees"
              rules={[{ required: true, message: 'Vui lòng chọn chi phí doanh nghiệp' }]}
            >
              <Select
                mode="multiple"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: '100%' }}
                placeholder="Chi phí doanh nghiệp"
                suffixIcon={<SuffixIconCustom />}
                onChange={_onChangeFee}
              >
                {dataFee.map((item, index) => {
                  return (
                    <Select.Option key={index} value={item.fee_id}>
                      {item.fee_name || ''} - {formatCash(item.fee || 0)} {item.unit || ''}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={<label style={{ fontSize: 16, fontWeight: 500 }}>Kho sử dụng</label>}
              name="warehouse_id"
              rules={[{ required: true, message: 'Vui lòng chọn kho sử dụng' }]}
            >
              <Select
                allowClear
                showSearch
                style={{ width: '100%' }}
                placeholder="Chọn kho sử dụng"
                optionFilterProp="children"
              >
                {warehouses.map((warehouse, index) => {
                  return (
                    <Select.Option key={index} value={warehouse.warehouse_id}>
                      {warehouse.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="avatar"
              label={<label style={{ fontSize: 16, fontWeight: 500 }}>Ảnh đại diện</label>}
              className={style['item-avatar']}
            >
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                onChange={handleAvatar}
              >
                {imgUpload ? (
                  <img src={imgUpload} alt="avatar" style={{ width: '100%' }} />
                ) : (
                  <div>
                    {loading ? <LoadingOutlined /> : <PlusOutlined />}
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                )}
              </Upload>
            </Form.Item>
            <Form.Item
              name="address"
              label={<label style={{ fontSize: 16, fontWeight: 500 }}>Địa chỉ</label>}
            >
              <Input allowClear placeholder="Nhập địa chỉ doanh nghiệp" />
            </Form.Item>
            <Form.Item
              name="province"
              label={<label style={{ fontSize: 16, fontWeight: 500 }}>Tỉnh/Thành phố</label>}
            >
              <Select
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: '100%' }}
                placeholder={
                  <label style={{ fontSize: 16, fontWeight: 500 }}>Chọn tỉnh/thành phố</label>
                }
                suffixIcon={<SuffixIconCustom />}
                onChange={(e) => setValueProvince(e)}
              >
                {dataProvince.map((item, index) => (
                  <Select.Option key={index} value={item.province_name}>
                    {item.province_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="district"
              label={<label style={{ fontSize: 16, fontWeight: 500 }}>Quận/Huyện</label>}
            >
              <Select
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: '100%' }}
                placeholder="Chọn quận/huyện"
                suffixIcon={<SuffixIconCustom />}
                value="Huyện Xi Ma Cai"
                onChange={(e) => setValueDistrict(e)}
              >
                {dataDistrict.map((item, index) => (
                  <Select.Option key={index} value={item.district_name}>
                    {item.district_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="ward"
              label={<label style={{ fontSize: 16, fontWeight: 500 }}>Phường/Xã</label>}
            >
              <Select
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: '100%' }}
                placeholder="Chọn phường/xã"
                suffixIcon={<SuffixIconCustom />}
              >
                {dataWard.map((item, index) => (
                  <Select.Option key={index} value={item.ward_name}>
                    {item.ward_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.Item style={{ width: '100%' }} label="Tài liệu (hồ sơ, hợp đồng,...)" name="files">
            <Upload.Dragger
              style={{ width: '100%' }}
              name="files"
              multiple={true}
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              onChange={_handleUploadFiles}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Nhấn hoặc kéo tệp vào khu vục này để tải lên</p>
            </Upload.Dragger>
          </Form.Item>
        </Row>

        <Row gutter={[30, 10]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item name="tax_code" label="Mã số thuế">
              <Input allowClear placeholder="Nhập mã số thuế" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item name="legal_representative" label="Đại diện pháp luật">
              <Input allowClear placeholder="Đại diện pháp luật" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item name="contract_start_date" label="Ngày ký hợp đồng">
              <DatePicker style={{ width: '100%' }} placeholder="Chọn ngày ký hợp đồng" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item name="contract_end_date" label="Ngày kết thúc hợp đồng">
              <DatePicker style={{ width: '100%' }} placeholder="Chọn ngày kết thúc hợp đồng" />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Form>
  )
}
