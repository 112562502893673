import React from 'react'
import styles from './notfound.module.scss'

import { useHistory } from 'react-router-dom'
import { ROUTES } from 'consts'

export default function PageNotFound() {
  const history = useHistory()
  return (
    <div className={styles['notfound-container']}>
      <div className={styles['notfound']}>
        <div className={styles['notfound-404']}>
          <h1>404</h1>
          <h2>Page not found</h2>
        </div>
        <a onClick={() => history.push(ROUTES.DASHBOARD)}>Back go home</a>
      </div>
    </div>
  )
}
