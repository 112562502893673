import { useEffect, useRef, useState } from 'react'
//antd
import { Button, Input, Modal, Row, Table, Select, Col, notification, Form, Space } from 'antd'
import { DeleteOutlined, ReloadOutlined } from '@ant-design/icons'
import { _addLog } from 'utils'
//styles
import styles from './printer.module.scss'
//components
import columns from './columns'
import SuffixIconCustom from 'components/suffixIconCustom'
//apis
import { getPrint, updatePrint } from 'apis/print'
import { getWarehouses } from 'apis/warehouse'
//consts
import { PAGE_SIZE_OPTIONS, PERMISSIONS, POSITION_TABLE, SIZE_TABLE } from 'consts'
import printToThermalPrinter from 'components/thermal-printer/print'
import { printTest } from 'components/thermal-printer/templates'
import Permission from 'components/permission'
import ModalOptionsPrint from 'components/modal-opions-print'

export default function PrinterManagement() {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isModalVisible1, setIsModalVisible1] = useState(false)
  const [dataWarehouses, setDataWarehouses] = useState([])
  const [valueSearch, setValueSearch] = useState('')
  const [paramsFilter, setParamsFilter] = useState({ page: 1, page_size: 15 })

  const [dataPrints, setDataPrints] = useState([])
  const [countPrints, setCountPrints] = useState(0)
  const [printId, setPrintId] = useState()
  const [loading, setLoading] = useState(false)
  const typingTimeoutRef = useRef(null)
  const [form] = Form.useForm()
  const _search = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      const value = e.target.value

      //khi search hoac filter thi reset page ve 1
      paramsFilter.page = 1

      if (value) paramsFilter.name = value
      else delete paramsFilter.name

      setParamsFilter({ ...paramsFilter })
    }, 450)
  }

  const _showModal = () => {
    setIsModalVisible(true)
  }
  const _showModal1 = () => {
    setIsModalVisible1(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleCancel1 = () => {
    setIsModalVisible1(false)
  }

  const _getPrints = async () => {
    try {
      setLoading(true)
      const res = await getPrint(paramsFilter)
      if (res.status === 200) {
        setDataPrints(res.data.data)
        setCountPrints(res.data.count)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      _addLog(JSON.stringify(error))
    }
  }

  const _updatePrint = async () => {
    const formData = form.getFieldsValue()
    Object.keys(formData).forEach((item) => (formData[item] = formData[item].trim()))
    try {
      setLoading(true)
      const res = await updatePrint(printId, formData)
      if (res.status === 200) {
        setIsModalVisible(false)
        notification.success({ message: 'Cập nhật thành công!' })
        _getPrints()
        setIsModalVisible(false)
      } else {
        notification.error({
          message: res.data.message || 'Cập nhật thất bại!',
        })
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      _addLog(`Update print: ${JSON.stringify(error)}`)
      setLoading(false)
    }
  }
  const _getWarehouses = async () => {
    try {
      const res = await getWarehouses()
      if (res.status === 200) {
        setDataWarehouses(res.data.data)
      }
    } catch (error) {
      _addLog(JSON.stringify(error))
    }
  }

  useEffect(() => {
    _getWarehouses()
    _getPrints()
  }, [])

  useEffect(() => {
    _getPrints()
  }, [paramsFilter])

  const _clearFilter = () => {
    setValueSearch('')
    setParamsFilter({ page: 1, page_size: 10 })
  }

  const _printTest = (ip) => {
    printToThermalPrinter(printTest, { barcode: 1234, title: 'In thu' }, ip)
  }

  return (
    <div className={styles['printer-management']}>
      <Row className="page-title" justify="space-between" style={{ marginBottom: '25px' }}>
        <h3>Quản lý thiết bị in</h3>
        <Space>
          <Button onClick={_getPrints} type="primary" icon={<ReloadOutlined />}>
            Làm mới
          </Button>
        </Space>
      </Row>

      <Row gutter={20} style={{ marginBottom: 25 }}>
        <Col span={8}>
          <Input.Search
            allowClear
            enterButton
            value={valueSearch}
            onChange={_search}
            placeholder="Tìm kiếm theo tên thiết bị in"
          />
        </Col>
        <Col span={6}>
          <Select
            style={{ width: '100%' }}
            suffixIcon={<SuffixIconCustom />}
            value={paramsFilter.warehouse_id}
            showSearch
            allowClear
            placeholder="Lọc theo kho"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(value) => {
              if (value) paramsFilter.warehouse_id = value
              else delete paramsFilter.warehouse_id
              setParamsFilter({ ...paramsFilter })
            }}
          >
            {dataWarehouses.map((warehouse) => {
              return <Select.Option value={warehouse.warehouse_id}>{warehouse.name}</Select.Option>
            })}
          </Select>
        </Col>
        <Col style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            style={{ display: Object.keys(paramsFilter).length <= 2 && 'none' }}
            icon={<DeleteOutlined />}
            type="primary"
            danger
            onClick={_clearFilter}
          >
            Xoá bộ lọc
          </Button>
        </Col>
      </Row>
      <Table
        scroll={{ y: '50vh' }}
        style={{ width: '100%' }}
        size={SIZE_TABLE}
        dataSource={dataPrints}
        loading={loading}
        pagination={{
          total: countPrints,
          position: POSITION_TABLE,
          current: paramsFilter.page,
          pageSize: paramsFilter.page_size,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          showQuickJumper: true,
          onChange: (page, pageSize) => {
            paramsFilter.page = page
            paramsFilter.page_size = pageSize
            setParamsFilter({ ...paramsFilter })
          },
        }}
        columns={columns.map((column) => {
          if (column.key === 'warehouse') {
            return {
              ...column,
              render: (warehouseId) => {
                const warehouse = dataWarehouses.filter(
                  (warehouse) => warehouse.warehouse_id === warehouseId
                )[0]
                return warehouse ? warehouse.name : 'Kho đã bị xóa'
              },
            }
          }
          if (column.key === 'action') {
            return {
              ...column,
              render: (text, record) => (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', padding: '0 10px' }}
                >
                  {record.ip && (
                    <ModalOptionsPrint
                      printWithInternet={() => _printTest(record.ip)}
                      records={[record]}
                    >
                      <Button
                        className={styles['printer-management-button--orange']}
                        style={{ minWidth: '100px' }}
                        type="primary"
                      >
                        In Thử
                      </Button>
                    </ModalOptionsPrint>
                  )}
                </div>
              ),
            }
          }
          if (column.key === 'stt') {
            return {
              ...column,
              render: (text, record, index) =>
                (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
            }
          }
          if (column.key === 'name') {
            return {
              ...column,
              render: (text, record, index) => (
                <span
                  style={{ color: '#0017E3', cursor: 'pointer' }}
                  onClick={() => {
                    setPrintId(record.print_id)
                    form.setFieldsValue(record)
                    _showModal()
                  }}
                >
                  {text}
                </span>
              ),
            }
          }

          return column
        })}
      />

      <Modal
        visible={isModalVisible}
        width={600}
        title={<div style={{ textAlign: 'center' }}>CẬP NHẬT THIẾT BỊ IN</div>}
        onCancel={handleCancel}
        footer={[
          <Button type="primary" onClick={handleCancel} danger style={{ width: 100 }}>
            Đóng
          </Button>,
          <Permission permissions={[PERMISSIONS.cap_nhat_thiet_bi_in]}>
            <Button
              style={{ color: 'white', marginRight: '15px', minWidth: 100 }}
              className={styles['printer-management-button--orange']}
              onClick={_updatePrint}
            >
              Cập nhật
            </Button>
          </Permission>,
        ]}
      >
        <Form
          form={form}
          style={{
            width: '80%',
            margin: 'auto',
          }}
        >
          <Form.Item name="ip" label="Ip">
            <Input placeholder="Nhập Ip" onPressEnter={_updatePrint} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        visible={isModalVisible1}
        width={700}
        footer={null}
        title={<div style={{ textAlign: 'center' }}>HƯỚNG DẪN LẤY IP</div>}
        onCancel={handleCancel1}
      >
        <h3 style={{ marginBottom: '10px', fontWeight: '700' }}>
          Nếu bạn không tìm thấy IP trên máy in, vui lòng làm theo thao tác sau để lấy IP
        </h3>
        <ul style={{ listStyle: 'none' }}>
          <li>Bước 1: Tắt máy in</li>
          <li>Bước 2: Đè nút power 3 giây</li>
          <li>Bước 3: Tiếp tục giữ, bật nút nguôn</li>
          <li>Bước 4: Tiếp tục giữ 3 giây</li>
          <li>Bước 5: Thả nút power</li>
          <li>Một tờ giấy thông số sẽ in ra, bạn vui lòng xem địa chỉ IP ở mục "IP address"</li>
        </ul>
      </Modal>
    </div>
  )
}
