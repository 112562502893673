import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import styles from './productInputdetail.module.scss'
import { ACTION, ROUTES, SIZE_TABLE, PRINTER, PERMISSIONS, IMAGE_DEFAULT } from 'consts'
import { formatCash, _addLog } from 'utils'
import { useHotkeys } from 'react-hotkeys-hook'

//components
import columns from './columns'
import printToThermalPrinter from 'components/thermal-printer/print'
import { barcode } from 'components/thermal-printer/templates'
import ModalReason from 'components/modal-reason-order'
import ModalOptionsPrint from 'components/modal-opions-print'
import Permission from 'components/permission'
import ZoomImage from 'components/zoom-image'
import QrCodeProduct from 'components/qr-code-product'

//antd
import {
  Table,
  Button,
  Row,
  Col,
  notification,
  Popconfirm,
  Input,
  Modal,
  Space,
  Tag,
  DatePicker,
} from 'antd'

//icons
import { ArrowLeftOutlined } from '@ant-design/icons'

//apis
import { getOrders, updateOrder } from 'apis/order'
import { getPrint } from 'apis/print'
import { enumImport } from 'apis/enum'
import { getWarehouses } from 'apis/warehouse'

export default function ProductImportDetail() {
  const history = useHistory()
  const dispatch = useDispatch()

  const [reasons, setReasons] = useState([])
  const [loading, setLoading] = useState(false)
  const [orderData, setOrderData] = useState({})
  const [productsData, setProductsData] = useState([])

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [nameProductWarning, setNameProductWarning] = useState([])

  const typingTimeoutRef = useRef(null)
  const [valueSearch, setValueSearch] = useState('')
  const [productsDataSearch, setProductsDataSearch] = useState([])
  const [dataWarehouses, setDataWarehouses] = useState([])

  const [isModalBucket, setIsModalBucket] = useState(false)
  const [infoBucket, setInfoBucket] = useState([])
  const [isScannerVisible, setIsScannerVisible] = useState(false)
  const inputSearch = useRef(null)

  useHotkeys('f1', () => {
    if (inputSearch && inputSearch.current) inputSearch.current.focus()
  })

  const _getOrderImport = async () => {
    let idOrderImport = history.location.state
    if (idOrderImport) localStorage.setItem('idOrderImport', idOrderImport)
    else idOrderImport = localStorage.getItem('idOrderImport')
    try {
      if (idOrderImport) {
        dispatch({ type: ACTION.LOADING, data: true })
        const res = await getOrders({ order_id: idOrderImport })
        dispatch({ type: ACTION.LOADING, data: false })
        if (res.status === 200) {
          if (res.data.success) {
            const data = res.data.data && res.data.data.length === 1 && res.data.data[0]
            if (data) {
              const products = data.products
              setOrderData(data)
              setProductsData(products)
              setProductsDataSearch(products)
            }
          } else {
            notification.error({ message: 'Không tìm thấy đơn hàng này!' })
            history.push(ROUTES.POSITION_INPUT)
          }
        } else {
          notification.error({ message: 'Không tìm thấy đơn hàng này!' })
          history.push(ROUTES.POSITION_INPUT)
        }
      } else {
        notification.error({ message: 'Không tìm thấy đơn hàng này!' })
        history.push(ROUTES.POSITION_INPUT)
      }
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const _handleBack = async (params) => {
    dispatch({ type: ACTION.LOADING, data: true })
    // nếu trạng thái đơn hàng đang nhập hàng thì chuyển về đang chờ
    let orderUpdate = { ...params }
    if (orderUpdate.import_status === 'PROCESSING') {
      orderUpdate.import_status = 'WAITING'
    }

    try {
      const res = await updateOrder(orderUpdate.order_id, orderUpdate)
      if (res.status === 200) {
        if (res.data.success) {
          localStorage.removeItem('idOrderImport')
        }
      }
      history.push({ pathname: ROUTES.POSITION_INPUT })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
      history.push({ pathname: ROUTES.POSITION_INPUT })
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const _onCheckQuantity = () => {
    const products = [...orderData.products]

    //kiểm tra số lượng bàn giao và nhập kho thực tế
    const listNameProduct = []
    products.map((item) => {
      const quantity = item?.import_details.reduce((acc, curr) => acc + curr.quantity, 0)
      if (item.inventory_quantity > quantity) {
        listNameProduct.push({ name: item.name })
      }
      return item.inventory_quantity > quantity
    })
    //nếu số lượng bàn giao lớn hơn thì mở modal, nếu nhỏ hơn thì tiếp tục xử lý
    if (listNameProduct.length > 0) {
      setNameProductWarning([...listNameProduct])
      setIsModalVisible(true)
    } else _handleImportOrder()
  }

  const _handleImportOrder = async () => {
    try {
      const body = { ...orderData }
      body.import_status = 'COMPLETE'
      body.processing = 'is_imported'

      dispatch({ type: ACTION.LOADING, data: true })
      const res = await updateOrder(orderData.order_id, body)
      dispatch({ type: ACTION.LOADING, data: false })

      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Nhập hàng thành công' })
          history.push({ pathname: ROUTES.POSITION_INPUT })
        } else
          notification.error({
            message: res.data.message || 'Hoàn tất nhập hàng có lỗi, vui lòng thử lại',
          })
      } else
        notification.error({
          message: res.data.message || 'Hoàn tất nhập hàng có lỗi, vui lòng thử lại',
        })
    } catch (error) {
      _addLog(`Import order: ${JSON.stringify(error)}`)
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const _onReport = async (reason) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const body = { import_status: 'REPORT', processing: 'is_imported', import_reason: reason }
      const res = await updateOrder(orderData.order_id, body)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Báo cáo đơn hàng thành công' })
          history.goBack()
        } else
          notification.success({
            message: res.data.message || 'Báo cáo đơn hàng thất bại, vui lòng thử lại',
          })
      } else
        notification.error({
          message: res.data.message || 'Báo cáo đơn hàng thất bại, vui lòng thử lại',
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      _addLog(`Report order: ${JSON.stringify(error)}`)
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const _search = (e) => {
    const value = e.target.value
    setValueSearch(value)

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }

    typingTimeoutRef.current = setTimeout(() => {
      if (value) {
        const product = productsData.find((p) => p.barcode == value)
        if (orderData.import_status !== 'COMPLETE') {
          if (product) {
            setProductsDataSearch([product])
            history.push({ pathname: ROUTES.PRODUCT_IMPORT_SCAN, state: product })
          } else notification.warning({ message: 'Không tìm thấy sản phẩm này' })
        } else setProductsDataSearch(product ? [product] : [])
      } else setProductsDataSearch(productsData)
    }, 650)
  }

  const _printBarcode = async (record) => {
    const params = {
      warehouse_id: orderData.warehouse_id,
      name: PRINTER.NHAP_KHO,
    }
    const dataPrinter = await _getPrint(params)
    printToThermalPrinter(
      barcode,
      {
        barcode: record.barcode,
        title: 'SP: ' + record.slug_name?.replace(/-{1,}/g, ' '),
      },
      dataPrinter && dataPrinter.ip
    )
  }

  const _onShowBucket = (record) => {
    setIsModalBucket(true)
    if (record.import_details) setInfoBucket(record.import_details)
  }

  const _getWarehouses = async () => {
    try {
      const res = await getWarehouses()
      if (res.status === 200) {
        setDataWarehouses(res.data.data)
      }
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _getEnumOrder = async () => {
    try {
      const res = await enumImport()
      if (res.status === 200) if (res.data.success) setReasons(res.data.data.report_reason)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _getPrint = async (params) => {
    try {
      const res = await getPrint(params)
      if (res.status === 200) {
        return res.data.data[0]
      }
      return ''
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
      return ''
    }
  }

  useEffect(() => {
    _getOrderImport()
    _getWarehouses()
    _getEnumOrder()
  }, [])

  return (
    <div className={styles['product-check-detail']}>
      <Row className="page-title" justify="space-between" style={{ marginBottom: '25px' }}>
        <Col style={{ display: 'flex', gap: '15px' }}>
          <h3
            style={{ cursor: 'pointer' }}
            onClick={() => {
              // if (orderData.import_status === 'COMPLETE' || orderData.import_status === 'REPORT') {
              //   localStorage.removeItem('idOrderImport')
              history.push({ pathname: ROUTES.POSITION_INPUT })
              // } else _handleBack(orderData)
            }}
          >
            <ArrowLeftOutlined style={{ marginRight: '10px' }} />
            Chi tiết đơn hàng <b>#{orderData.code ? orderData.code : orderData.order_id}</b> (Nhập
            hàng vào vị trí)
          </h3>
        </Col>
        {orderData.import_status === 'COMPLETE' ? (
          <h4 style={{ color: '#0017E3' }}>Đã nhập vào vị trí</h4>
        ) : (
          <Space>
            <ModalReason reasons={reasons} onReport={_onReport} />
            <Popconfirm
              cancelText="Từ chối"
              okText="Đồng ý"
              onConfirm={() => _onCheckQuantity()}
              title="Bạn có muốn hoàn tất nhập hàng không?"
            >
              <Button type="primary">Hoàn tất nhập hàng</Button>
            </Popconfirm>
          </Space>
        )}
      </Row>
      <Row style={{ marginBottom: 15 }} gutter={20}>
        <Col span={8}>
          <Input.Group compact className={styles['employee-management-input-item']}>
            <Input.Search
              autoFocus
              allowClear
              enterButton
              value={valueSearch}
              onChange={_search}
              placeholder="Nhấn vào đây để quét mã sản phẩm (F1)"
              onBlur={() => setIsScannerVisible(false)}
              onFocus={() => setIsScannerVisible(true)}
              ref={inputSearch}
            />
          </Input.Group>
        </Col>
        {orderData.import_status !== 'COMPLETE' && (
          <Col span={16}>
            <h4 style={{ lineHeight: '32px', marginBottom: 0, fontSize: 20, fontWeight: 600 }}>
              {isScannerVisible
                ? 'Bắt đầu quét mã sản phẩm'
                : 'Máy quét đang tắt (F1 để bật máy quét)'}
            </h4>
          </Col>
        )}
      </Row>
      <Table
        loading={loading}
        style={{ width: '100%' }}
        dataSource={productsDataSearch}
        columns={columns.map((column) => {
          if (column.key === 'STT') {
            return {
              ...column,
              render: (text, record, index) => index + 1,
            }
          }
          if (column.key === 'image') {
            return {
              ...column,
              render: (src, record) =>
                record.images &&
                record.images.length && (
                  <ZoomImage image={record.images[0] || IMAGE_DEFAULT}>
                    <img
                      src={record.images[0] || IMAGE_DEFAULT}
                      alt=""
                      style={{ width: 50, height: 50 }}
                    />
                  </ZoomImage>
                ),
            }
          }
          if (column.key === 'inventory_quantity') {
            return {
              ...column,
              render: (text) => text && formatCash(text),
            }
          }
          if (column.key === 'quantity') {
            return {
              ...column,
              render: (text, record) =>
                record.import_details
                  ? formatCash(
                      record.import_details.reduce((acc, curr) => acc + curr.import_quantity, 0)
                    )
                  : 0,
            }
          }
          if (column.key === 'warehouse_id') {
            return {
              ...column,
              render: () => {
                const warehouse = dataWarehouses.find(
                  (e) => e.warehouse_id === orderData.warehouse_id
                )
                return warehouse && warehouse.name
              },
            }
          }
          if (column.key === 'position') {
            return {
              ...column,
              render: (text, record) => {
                return (
                  <ul
                    style={{ listStyleType: 'none', margin: '5px 0', cursor: 'pointer' }}
                    onClick={() => _onShowBucket(record)}
                  >
                    {record.import_details &&
                      record.import_details.map((item, index) => (
                        <li key={index} style={{ margin: '5px 0' }}>
                          <Tag>{item.bucket_name}</Tag>
                        </li>
                      ))}
                  </ul>
                )
              },
            }
          }
          if (column.key === 'require_quantity') {
            return {
              ...column,
              render: (text) => text && formatCash(text),
            }
          }
          if (column.key === 'CBM') {
            return {
              ...column,
              render: (text) => text && formatCash(text),
            }
          }
          if (column.key === 'area') {
            return {
              ...column,
              render: (text) => text && formatCash(text),
            }
          }
          if (column.key === 'exp') {
            return {
              ...column,
              render: (text) => text && moment(text).format('DD/MM/YYYY'),
            }
          }
          if (column.key === 'barcode/code') {
            return {
              ...column,
              render: (text, record) => (record?.barcode ? record?.barcode : record?.code),
            }
          }
          if (column.key === 'print_barcode') {
            return {
              ...column,
              render: (text, record) => (
                <div>
                  <ModalOptionsPrint
                    printWithInternet={() => _printBarcode(record)}
                    records={[{ ...record, code: record.barcode || record.code }]}
                  >
                    <Button type="primary">In barcode SP</Button>
                  </ModalOptionsPrint>
                  <div style={{ marginTop: 5 }}>
                    <QrCodeProduct value={record.barcode} />
                  </div>
                </div>
              ),
            }
          }
          return column
        })}
        pagination={false}
        size={SIZE_TABLE}
      />

      <Modal
        visible={isModalVisible}
        onOk={() => _handleImportOrder(orderData.order_id)}
        onCancel={() => setIsModalVisible(false)}
      >
        <h4 style={{ fontSize: 18 }}>
          {nameProductWarning.map((item) => (
            <div>
              Sản phẩm{' '}
              <b style={{ color: 'red', fontSize: 17 }} key={item.name}>
                {' '}
                "{item.name}",{' '}
              </b>{' '}
              có số lượng nhập kho thực tế thấp hơn số lượng bàn giao.
            </div>
          ))}
          <br />
          <b>Bạn có muốn tiếp tục hoàn tất nhập hàng không?</b>
        </h4>
      </Modal>

      <Modal
        title="Thông tin ô kệ"
        visible={isModalBucket}
        footer={false}
        onCancel={() => setIsModalBucket(false)}
      >
        <Table
          dataSource={infoBucket}
          columns={[
            { title: 'Tên ô kệ', dataIndex: 'bucket_name', align: 'center' },
            { title: 'Số lượng sản phẩm', dataIndex: 'quantity', align: 'center' },
          ]}
          pagination={false}
          size={SIZE_TABLE}
        />
      </Modal>
    </div>
  )
}
