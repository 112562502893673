import React, { useEffect, useRef, useState } from 'react'
import {
  Row,
  Col,
  Form,
  Input,
  notification,
  List,
  Switch,
  InputNumber,
  Table,
  Popconfirm,
  Space,
  Button,
  Modal,
} from 'antd'
import { formatCash, _addLog } from 'utils'
import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons'
import { useHotkeys } from 'react-hotkeys-hook'
import { IMAGE_DEFAULT, ROUTES } from 'consts'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

//components
import ZoomImage from 'components/zoom-image'

// apis
import { scanExportOrder, updateExportOrders } from 'apis/export-order'

export default function ProductExportScanBucket() {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const formRef = useRef(null)
  const {
    order_id,
    type,
    order_bucket,
    order_status,
    processing,
    filters,
    default_basket,
    order_code,
  } = location.state || {}

  const [bucketsScan, setBucketsScan] = useState([]) //ds bucket đã và đang quét
  const [bucketScan, setBucketScan] = useState({}) // bucket đang quét
  const [isHiddenInputScan, setIsHiddenInputScan] = useState(false)
  const [productsNotEnoughCondition, setProductsNotEnoughCondition] = useState([])

  const indicators = [
    { title: 'Chưa quét', bgColor: '#FFCE62' },
    { title: 'Đang quét', bgColor: '#80E9EF' },
    { title: 'Đã quét', bgColor: '#79CF4F' },
  ]

  const columns = [
    {
      title: 'STT',
      align: 'center',
      width: 50,
      render: (data, record, index) => index + 1,
    },
    {
      width: 200,
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: 'Mã hàng/Barcode',
      dataIndex: 'barcode',
      align: 'center',
    },
    {
      width: 55,
      title: 'Hình ảnh',
      align: 'center',
      render: (images, record) =>
        record.images?.length && (
          <ZoomImage image={images[0] || IMAGE_DEFAULT}>
            <img src={images[0] || IMAGE_DEFAULT} alt="" style={{ width: 50, height: 50 }} />
          </ZoomImage>
        ),
    },
    {
      title: 'Cbm (cm³)',
      dataIndex: 'cbm',
      align: 'center',
      render: (data) => formatCash(data),
    },
    {
      title: 'D.Tích mặt phẳng',
      dataIndex: 'area',
      align: 'center',
      render: (data) => formatCash(data),
    },
    {
      title: 'Quy cách',
      dataIndex: 'convention',
      align: 'center',
    },
    {
      title: 'Đơn vị tính',
      render: (text, record) => record.unit_info && record.unit_info.name,
    },
    {
      title: 'Đơn vị nhỏ nhất',
      render: (text, record) => formatCash(record.smallest_unit || 0),
    },
    {
      title: 'SL yêu cầu xuất',
      align: 'center',
      render: (text, record) => formatCash(record.require_quantity || 0),
    },
    {
      width: 115,
      title: 'SL xuất kho',
      key: 'quantity',
      align: 'center',
      render: (text, record) => (
        <div>
          <InputNumber
            style={{ width: 100 }}
            value={record.export_quantity}
            min={0}
            onBlur={(e) => _changeQuantity(+e.target.value, record.product_id)}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            onPressEnter={(e) => _changeQuantity(+e.target.value, record.product_id)}
          />
          <b style={{ lineHeight: 0, fontSize: 15, color: 'red' }}>
            {record.require_quantity > record.export_quantity && '*Chưa đủ SL cần xuất'}
            {record.require_quantity < record.export_quantity && '*Vượt quá SL cần xuất'}
          </b>
        </div>
      ),
    },
    {
      width: 30,
      key: 'action',
      align: 'center',
      render(data, record) {
        return (
          <Popconfirm
            onConfirm={() => _removeProduct(record.product_id)}
            title="Bạn có muốn xóa sản phẩm này không?"
          >
            <DeleteOutlined style={{ color: 'red', cursor: 'pointer', fontSize: 17 }} />
          </Popconfirm>
        )
      },
    },
  ]

  const _removeProduct = (productId = '') => {
    const bucketIndex = bucketsScan.findIndex((e) => e.bucket_id === bucketScan.bucket_id)
    if (bucketIndex !== -1) {
      const bucketsScanNew = [...bucketsScan]
      const productsScanned = bucketsScanNew[bucketIndex].productsScanned
      const productsScannedIndex = productsScanned.findIndex(
        (product) => product.product_id === productId
      )

      if (productsScannedIndex !== -1)
        bucketsScanNew[bucketIndex].productsScanned.splice(productsScannedIndex, 1)
      setBucketsScan([...bucketsScanNew])
    }

    focusInputScan()
  }

  const _changeQuantity = async (value = '', productId = '') => {
    const bucketIndex = bucketsScan.findIndex((e) => e.bucket_id === bucketScan.bucket_id)
    if (bucketIndex !== -1) {
      const bucketsScanNew = [...bucketsScan]
      const productsScanned = bucketsScanNew[bucketIndex].productsScanned
      const productIndex = productsScanned.findIndex((product) => product.product_id === productId)
      if (productIndex !== -1)
        bucketsScanNew[bucketIndex].productsScanned[productIndex].export_quantity = value
      setBucketsScan([...bucketsScanNew])
    }

    focusInputScan()
  }

  const handleCompleteExport = () => {
    Modal.confirm({
      style: { top: 20 },
      width: 700,
      icon: '',
      content: (
        <div style={{ fontSize: 16 }}>
          {productsNotEnoughCondition.map((product, index) => (
            <div key={product.barcode} style={{ lineHeight: 1.4, marginBottom: 10 }}>
              {index + 1}. Sản phẩm{' '}
              <b style={{ color: 'red' }}>
                {product.name} - {product.barcode}
              </b>{' '}
              có số lượng xuất{' '}
              <b style={{ color: 'red' }}>
                {product.export_quantity}/{product.require_quantity}
              </b>{' '}
              {product.export_quantity < product.require_quantity
                ? 'chưa đủ số lượng'
                : 'vượt quá số lượng'}
            </div>
          ))}
          {bucketsScan.map((bucket) =>
            bucket.products.map((pd) => {
              const product = bucket.productsScanned.find((p) => p.product_id === pd.product_id)
              if (!product)
                return (
                  <div key={pd.barcode} style={{ lineHeight: 1.4, marginBottom: 10 }}>
                    Sản phẩm{' '}
                    <b style={{ color: 'red' }}>
                      {pd.name} - {pd.barcode}
                    </b>{' '}
                    chưa được xuất hàng
                  </div>
                )
            })
          )}

          <div style={{ fontSize: 18 }}>
            Bạn có muốn hoàn tất xuất hàng đơn hàng <b>#{order_code}</b> không?
          </div>
        </div>
      ),
      onOk: () => completeExport(),
      onCancel: () => focusInputScan(),
    })
  }

  const completeExport = async () => {
    try {
      const listPromise = bucketsScan.map(async (bucket) => {
        const body = {
          order_id: order_id,
          warehouse_id: bucket.warehouse_id,
          bucket_id: bucket.bucket_id,
          scans: bucket.productsScanned.map((product) => ({
            product_id: product.product_id,
            export_quantity: +product.export_quantity,
          })),
        }
        const res = await scanExportOrder(body)
        return res
      })
      dispatch({ type: 'LOADING', data: true })
      const arrRes = await Promise.all(listPromise)
      dispatch({ type: 'LOADING', data: false })
      if (arrRes.length) {
        if (arrRes[0].status === 200) {
          if (arrRes[0].data.success) {
            await updateExportOrders(order_id, { export_status: 'COMPLETE' })
            notification.success({ message: 'Hoàn tất xuất hàng thành công!' })
            history.push({ pathname: ROUTES.PRODUCT_EXPORT, state: { filters } })
          } else
            notification.error({
              message: arrRes[0].data.message || 'Hoàn tất xuất hàng thất bại, vui lòng thử lại!',
            })
        } else
          notification.error({
            message: arrRes[0].data.message || 'Hoàn tất xuất hàng thất bại, vui lòng thử lại!',
          })
      }
    } catch (error) {
      dispatch({ type: 'LOADING', data: false })
    }
  }

  const scanNextBucket = () => {
    const bucketIndex = bucketsScan.findIndex((bucket) => bucket.bucket_id === bucketScan.bucket_id)
    if (bucketIndex !== -1 && bucketsScan[bucketIndex + 1]) {
      setBucketScan(bucketsScan[bucketIndex + 1])
      notification.success({
        message: `Tiếp tục quét ô kệ ${bucketsScan[bucketIndex + 1].hotname}`,
        duration: 4,
      })
    } else notification.warning({ message: 'Bạn đang quét ô kệ cuối cùng', duration: 4 })
    focusInputScan()
  }

  const getColorBucket = (bucketId = '') => {
    const bucketScanned = bucketsScan.find(
      (e) => e.bucket_id === bucketId && e.productsScanned.length
    )
    const bucketScanning = bucketScan.bucket_id === bucketId
    return bucketScanning ? '#80E9EF' : bucketScanned ? '#79CF4F' : '#FFCE62'
  }

  const onFinish = async (value) => {
    form.resetFields()
    formRef.current.focus()

    try {
      if (!value.barcode) return

      const productScan = bucketScan.products.find((p) => p.barcode === value.barcode)
      if (!productScan)
        notification.error({
          message: (
            <div>
              Không tìm thấy mã hàng <b>#{value.barcode}</b> trong ô kệ <b>{bucketScan.hotname}</b>
            </div>
          ),
          duration: 4,
        })
      else {
        const bucketIndex = bucketsScan.findIndex((e) => e.bucket_id === bucketScan.bucket_id)
        if (bucketIndex !== -1) {
          const bucketsScanNew = [...bucketsScan]
          const productsScanned = bucketsScanNew[bucketIndex].productsScanned

          const productScannedIndex = productsScanned.findIndex(
            (product) => product.barcode === value.barcode
          )

          if (productScannedIndex !== -1)
            bucketsScanNew[bucketIndex].productsScanned[productScannedIndex].export_quantity += 1
          else
            bucketsScanNew[bucketIndex].productsScanned.unshift({
              ...productScan,
              export_quantity: 1,
            })

          notification.success({
            message: (
              <div>
                Quét mã hàng <b>{value.barcode}</b> thành công
              </div>
            ),
            key: 'scan-product-success',
            duration: 4,
          })

          setBucketsScan([...bucketsScanNew])
        }
      }
    } catch (err) {
      _addLog(JSON.stringify(err))
      notification.error({ message: 'Quét mã hàng thất bại' })
      console.log(err)
    }
  }

  const handleCheckProductNotEnoughCondition = () => {
    let products = []
    bucketsScan.map((bucket) =>
      bucket.productsScanned.map((pd) => {
        const productIndex = products.findIndex((p) => p.product_id === pd.product_id)
        if (productIndex !== -1) {
          const quantityPackage = pd.require_quantity
          products[productIndex].require_quantity += quantityPackage
        } else products.push({ ...pd })
      })
    )
    setProductsNotEnoughCondition(
      products.filter((pd) => pd.export_quantity !== pd.require_quantity)
    )
  }

  const focusInputScan = () => {
    form.resetFields()
    if (formRef && formRef.current) formRef.current.focus()
  }

  const showOrHiddenInputScan = () => {
    setIsHiddenInputScan(!isHiddenInputScan)
    focusInputScan()
  }

  useHotkeys('f2', () => {
    showOrHiddenInputScan()
  })

  useHotkeys('f1', () => {
    scanNextBucket()
  })

  useEffect(() => {
    handleCheckProductNotEnoughCondition()
  }, [bucketsScan])

  useEffect(() => {
    if (location.state) {
      console.log(location.state)
      setBucketScan(location.state.order_bucket?.length ? location.state.order_bucket[0] : {})
      setBucketsScan(
        location.state.order_bucket?.map((e) => ({
          ...e,
          productsScanned: e.products.filter((product) => product.export_quantity > 0),
        }))
      )

      focusInputScan()
    } else history.push({ pathname: ROUTES.PRODUCT_EXPORT, state: { filters } })
  }, [])

  return (
    <>
      <Row className="page-title" justify="space-between" style={{ marginBottom: 15 }}>
        <Col>
          <h3
            onClick={() => history.push({ pathname: ROUTES.PRODUCT_EXPORT, state: { filters } })}
            style={{ cursor: 'pointer' }}
          >
            <ArrowLeftOutlined /> Quét xuất sản phẩm đơn hàng <b>#{order_code}</b>
          </h3>
        </Col>
        <Row>
          <div>Nhập mã hàng thủ công (F2)</div>
          <Switch
            checked={isHiddenInputScan}
            style={{ marginLeft: 5 }}
            onChange={() => showOrHiddenInputScan()}
          />
        </Row>
      </Row>
      <div>
        <Row justify="space-between">
          <Col span={16}>
            <div>
              <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                {indicators.map((indicator) => {
                  return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      <div
                        style={{
                          width: '15px',
                          height: '15px',
                          backgroundColor: indicator.bgColor,
                          borderRadius: '50%',
                        }}
                      ></div>
                      <div>{indicator.title}</div>
                    </div>
                  )
                })}
              </div>
              <div>
                <div>
                  <h3 style={{ marginBottom: 0 }}>Danh sách ô kệ cần xuất hàng</h3>
                  <div style={{ color: 'red' }}>*Nhấn vào tên ô kệ để quét ô kệ đó</div>
                </div>
                <List
                  size="small"
                  style={{ maxWidth: 300, maxHeight: 450, overflow: 'auto' }}
                  bordered
                  dataSource={bucketsScan}
                  renderItem={(item) => (
                    <List.Item
                      style={{
                        paddingTop: 3,
                        paddingBottom: 3,
                        color: getColorBucket(item.bucket_id),
                        fontWeight: 700,
                        fontSize: 17,
                      }}
                    >
                      <Row
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          if (item.bucket_id !== bucketScan.bucket_id) setBucketScan(item)
                          focusInputScan()
                        }}
                      >
                        {item.hotname}
                        {bucketScan.bucket_id === item.bucket_id && '(Đang quét)'}
                      </Row>
                    </List.Item>
                  )}
                />
              </div>
            </div>
          </Col>
          <Col span={8}>
            <Form onFinish={onFinish} form={form} layout="vertical">
              <Form.Item
                name="barcode"
                label="Nhập mã hàng và enter"
                style={{ opacity: !isHiddenInputScan && 0 }}
              >
                <Input
                  onKeyDown={(e) => {
                    if (e.key === 'F2') showOrHiddenInputScan()
                    if (e.key === 'F1') scanNextBucket()
                    if (e.key === ' ') handleCompleteExport()
                  }}
                  autoFocus
                  ref={formRef}
                  style={{ width: '100%' }}
                  placeholder="Nhập mã hàng và enter"
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <br />
        {bucketScan.hotname && (
          <div style={{ fontSize: 17 }}>
            <Row style={{ marginBottom: 5 }} justify="space-between" align="middle">
              <div>
                Danh sách sản phẩm đang quét của ô kệ <b>{bucketScan.hotname}</b>
              </div>
              <Space>
                <Button type="primary" onClick={scanNextBucket}>
                  Quét ô kệ tiếp theo (F1)
                </Button>
                <Button
                  onClick={handleCompleteExport}
                  type="primary"
                  style={{ backgroundColor: 'green', borderColor: 'green' }}
                >
                  Hoàn tất xuất hàng (Space)
                </Button>
              </Space>
            </Row>
            <Table
              size="small"
              style={{ width: '100%' }}
              pagination={false}
              columns={columns}
              dataSource={[
                ...(bucketsScan.find((bucket) => bucket.bucket_id === bucketScan.bucket_id)
                  ?.productsScanned || []),
              ]}
              scroll={{ y: '50vh' }}
            />
          </div>
        )}
      </div>
    </>
  )
}
