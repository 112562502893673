import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Modal, Input, notification } from 'antd'
import { LockOutlined } from '@ant-design/icons'

//apis
import { login } from 'apis/auth'

export default function ModalConfirmDelete({ children, onDelete, record = {} }) {
  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)

  const [password, setPassword] = useState('')
  const [note, setNote] = useState('')

  const dataUser = useSelector((state) => state.user)
  const dispatch = useDispatch()

  const _deleteOrder = async () => {
    try {
      if (!password) {
        notification.error({ message: 'Vui lòng nhập mật khẩu' })
        return
      }
      if (!note) {
        notification.error({ message: 'Vui lòng nhập lý do xóa đơn hàng' })
        return
      }
      dispatch({ type: 'LOADING', data: true })
      const body = { username: dataUser.username, password: password }
      const res = await login(body)
      dispatch({ type: 'LOADING', data: false })
      if (res.status === 200) {
        if (res.data.success) {
          if (onDelete) onDelete(note)
          toggle()
        } else notification.error({ message: res.data.message || 'Mật khẩu không chính xác' })
      } else notification.error({ message: res.data.message || 'Mật khẩu không chính xác' })
    } catch (error) {
      dispatch({ type: 'LOADING', data: false })
      console.log(error)
    }
  }

  useEffect(() => {
    if (!visible) {
      setPassword('')
      setNote('')
    }
  }, [visible])

  return (
    <>
      <div onClick={toggle}>{children}</div>
      <Modal
        title={
          <div>
            Nhập mật khẩu để xóa đơn hàng #<b>{record.code}</b>
          </div>
        }
        visible={visible}
        width={600}
        onCancel={toggle}
        okText="Đồng ý"
        cancelText="Đóng"
        onOk={_deleteOrder}
      >
        <b>Mật khẩu</b>
        <Input.Password
          autoFocus
          allowClear
          onPressEnter={() => _deleteOrder()}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          size="large"
          placeholder="Nhập mật khẩu"
          prefix={<LockOutlined />}
          style={{ borderRadius: 5 }}
        />
        <br />
        <br />
        <b>Lý do xóa đơn hàng</b>
        <Input
          allowClear
          onPressEnter={() => _deleteOrder()}
          value={note}
          onChange={(e) => setNote(e.target.value)}
          size="large"
          placeholder="Nhập lý do xóa đơn hàng"
          style={{ borderRadius: 5 }}
        />
      </Modal>
    </>
  )
}
