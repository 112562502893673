import React, { useEffect, useState, useRef } from 'react'
import styles from './activityhistory.module.scss'
import moment from 'moment'
import { IMAGE_DEFAULT, PAGE_SIZE_OPTIONS, SIZE_TABLE } from 'consts'
import { _addLog, saveFiltersToLocalStorage, formatCash, getKeyName } from 'utils'
import { isEqual, uniq } from 'lodash'
import { getAllCustomer } from 'apis/customer'
//components
import SuffixIconCustom from 'components/suffixIconCustom'
import columnsActivity from './columnsActivity'
import BackToTop from 'components/back-to-top'
import SettingColumns from 'components/setting-columns'
import FilterDateCreateOrder from 'components/filter-date-create-order'
import FilterDateDeleteOrder from 'components/filter-date-delete-order'

// antd
import { Col, Row, Button, Input, Select, Table, Space, Modal } from 'antd'

//icons
import { CloseCircleOutlined, ReloadOutlined } from '@ant-design/icons'

//apis
import { getPoDeleteList } from 'apis/po-delete'

export default function PODeleteList() {
  const KEY_FILTER = 'PODeleteListFilters'
  const filters = localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))

  const typingTimeoutRef = useRef(null)
  const [paramsFilter, setParamsFilter] = useState(
    filters ? { ...filters } : { page: 1, page_size: 100 }
  )
  const [customers, setCustomers] = useState([])

  const [valueSearch, setValueSearch] = useState(
    filters ? filters.relative_code || filters.relative_customer_order_code || '' : ''
  )
  const [optionSearch, setOptionSearch] = useState(
    filters
      ? (filters.relative_code && 'relative_code') ||
          (filters.relative_customer_order_code && 'relative_customer_order_code') ||
          'relative_customer_order_code'
      : 'relative_customer_order_code'
  )
  const [columns, setColumns] = useState([])

  const [countPODelete, setCountPODelete] = useState(0)
  const [loading, setLoading] = useState(false)
  const [PODeleteList, setPODeleteList] = useState([])

  const _getPoDeleteList = async () => {
    try {
      setLoading(true)
      const res = await getPoDeleteList(paramsFilter)
      setLoading(false)
      if (res.status === 200) {
        setPODeleteList(res.data.data)
        setCountPODelete(res.data.count)
      }
    } catch (error) {
      console.log(error)
      _addLog(`get action history: ${JSON.stringify(error)}`)
      setLoading(false)
    }
  }

  const _selectOption = (value) => {
    setOptionSearch(value)
    delete paramsFilter[optionSearch]

    if (valueSearch) {
      //lấy giá trị select mới để lọc lại dữ liệu
      setParamsFilter({ ...paramsFilter, [value]: valueSearch, page: 1 })
    }
  }

  const _onSearch = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      const value = e.target.value

      //khi search hoac filter thi reset page ve 1
      paramsFilter.page = 1

      if (value) paramsFilter.name = value
      else delete paramsFilter.name

      setParamsFilter({ ...paramsFilter })
    }, 450)
  }

  const _filterByOption = (attribute = '', value = '') => {
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter, page: 1 })
  }

  const _clearFilters = () => {
    Object.keys(paramsFilter).map((key) => delete paramsFilter[key])
    setParamsFilter({ page: 1, page_size: 20 })
    setValueSearch('')
  }

  const _getCustomers = async () => {
    try {
      const res = await getAllCustomer({ get_all: true })
      if (res.status === 200) setCustomers(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  useEffect(() => {
    _getPoDeleteList()
    saveFiltersToLocalStorage(paramsFilter, KEY_FILTER)
  }, [paramsFilter])
  useEffect(() => {
    _getCustomers()
  }, [])

  return (
    <div className={styles['activityhistory']}>
      <BackToTop />
      <Row className="page-title" justify="space-between">
        <h3>Quản lý phiếu xóa</h3>
        <Space>
          <Button
            danger
            type="primary"
            onClick={_clearFilters}
            icon={<CloseCircleOutlined />}
            style={{
              display: Object.keys(paramsFilter).length <= 2 && 'none',
            }}
          >
            Xoá bộ lọc
          </Button>
          <Button onClick={_getPoDeleteList} type="primary" icon={<ReloadOutlined />}>
            Làm mới
          </Button>
          <SettingColumns
            columnsRender={columnsActivity}
            columns={columns}
            setColumns={setColumns}
            nameColumn="columnsActivityHistory"
          />
        </Space>
      </Row>
      <div className={styles['activityhistory-content']}>
        <Row gutter={20} style={{ marginBottom: 15 }}>
          <Col span={9}>
            <Input.Group compact>
              <Input
                style={{ width: '70%' }}
                enterButton
                allowClear
                value={valueSearch}
                onChange={_onSearch}
                placeholder="Tìm kiếm phiếu xóa"
              />
              <Select
                style={{ width: '30%' }}
                suffixIcon={<SuffixIconCustom />}
                value={optionSearch}
                onChange={(value) => _selectOption(value)}
              >
                <Select.Option value="relative_code">Mã phiếu</Select.Option>
                <Select.Option value="relative_customer_order_code">Số phiếu</Select.Option>
              </Select>
            </Input.Group>
          </Col>
          <Col span={5}>
            <Select
              allowClear
              suffixIcon={<SuffixIconCustom />}
              placeholder="Lọc theo loại phiếu"
              style={{ width: '100%' }}
              value={paramsFilter['type'] || undefined}
              onChange={(value) => _filterByOption('type', value)}
            >
              <Select.Option value="import-order">Phiếu nhập</Select.Option>
              <Select.Option value="export-order">Phiếu xuất</Select.Option>
            </Select>
          </Col>
          <Col span={5}>
            <FilterDateCreateOrder
              placeholder="Lọc theo thời gian tạo phiếu"
              paramsFilter={paramsFilter}
              setParamsFilter={setParamsFilter}
            />
          </Col>
          <Col span={5}>
            <FilterDateDeleteOrder
              placeholder="Lọc theo thời gian xóa phiếu"
              paramsFilter={paramsFilter}
              setParamsFilter={setParamsFilter}
            />
          </Col>

          <Col lg={5}>
            <Select
              value={paramsFilter['customer_id'] || undefined}
              onChange={(value) => _filterByOption('customer_id', value)}
              showSearch
              allowClear
              placeholder="Lọc theo doanh nghiệp"
              style={{ width: '100%' }}
              dropdownClassName="dropdown-select-custom"
              suffixIcon={<SuffixIconCustom />}
            >
              {customers.map((customer, index) => (
                <Select.Option key={index} value={customer.customer_id}>
                  {customer.fullname}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>

        <Table
          scroll={{ y: '53vh' }}
          size={SIZE_TABLE}
          dataSource={PODeleteList}
          loading={loading}
          columns={columns.map((column) => {
            if (column.key === 'stt')
              return {
                ...column,
                render: (text, record, index) =>
                  (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
              }
            if (column.key === 'type')
              return {
                ...column,
                render: (text, record) =>
                  record.type === 'import-order' ? 'Phiếu nhập' : 'Phiếu xuất',
              }
            if (column.key === 'create_date')
              return {
                ...column,
                render: (text, record) =>
                  record.create_date && moment(record.create_date).format('DD-MM-YYYY HH:mm'),
              }
            if (column.key === 'remove_date')
              return {
                ...column,
                render: (text, record) =>
                  record.remove_date && moment(record.remove_date).format('DD-MM-YYYY HH:mm'),
              }
            return column
          })}
          pagination={{
            current: paramsFilter.page,
            pageSize: paramsFilter.page_size,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            showQuickJumper: true,
            total: countPODelete,
            onChange: (page, pageSize) => {
              setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize })
            },
          }}
        />
      </div>
    </div>
  )
}
