import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useDispatch } from 'react-redux'
import { filterOptionSelect, formatCash, _addLog } from 'utils'
import styles from 'views/storage-fee/storagefee.module.scss'

//components
import Permission from 'components/permission'

//antd
import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  Row,
  Form,
  Input,
  notification,
  InputNumber,
  Space,
  Checkbox,
  Select,
  Radio,
  Spin,
  Table,
  Popconfirm,
} from 'antd'

//consts
import { ACTION, PERMISSIONS } from 'consts'

//apis
import { createStorageFee, updateStorageFee } from 'apis/storage-fee'
import { getAllCustomer } from 'apis/customer'
import { getWarehouses } from 'apis/warehouse'
import { enumFeeGroup } from 'apis/enum'
import { getOrders } from 'apis/order'
import { getExportOrders } from 'apis/export-order'
import { getExpense } from 'apis/expense'
import { getFees } from 'apis/fee'
import { getStorageFees } from 'apis/storage-fee'
import { createFeeInOrder } from 'apis/fee-in-order'

export default function StorageFeeAdd() {
  const [form] = Form.useForm()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const typingTimeoutRef = useRef(null)

  const INIT_FEE = { group: '', fees: [], objFee: {} }

  const [customers, setCustomers] = useState([])
  const [warehouses, setWarehouses] = useState([])
  const [feeGroup, setFeeGroup] = useState([])
  const [orders, setOrders] = useState([])
  const [feesTable, setFeesTable] = useState([INIT_FEE])

  const [loadingOrder, setLoadingOrder] = useState(false)
  const [typeOrder, setTypeOrder] = useState('')
  const [customerId, setCustomerId] = useState('')

  const columns = [
    {
      title: 'STT',
      width: 60,
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Loại phát sinh',
      width: 230,
      render: (text, record, index) => (
        <Select
          onChange={async (value) => {
            const feesTableNew = [...feesTable]

            feesTableNew[index].objFee = {}

            feesTableNew[index].group = value

            const fees = await _getFees(value)
            feesTableNew[index].fees = fees

            setFeesTable([...feesTableNew])
          }}
          value={record.group || undefined}
          filterOption={filterOptionSelect}
          style={{ width: '100%' }}
          placeholder="Chọn loại phát sinh"
        >
          {feeGroup.map((value) => (
            <Select.Option value={value.value} key={value.value}>
              {value.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      width: 300,
      title: 'Tên chi phí',
      render: (text, record, index) => (
        <Select
          filterOption={filterOptionSelect}
          style={{ width: '100%' }}
          placeholder="Chọn tên chi phí"
          showSearch
          value={
            record.objFee.fee_id ||
            record.objFee.expense_id ||
            record.objFee.storage_fee_id ||
            undefined
          }
          onChange={(value) => {
            const objFee = record.fees.find(
              (fee) =>
                value === fee.fee_id || value === fee.expense_id || value === fee.storage_fee_id
            )

            if (objFee) {
              const feesTableNew = [...feesTable]
              feesTableNew[index].objFee = { ...objFee }
              setFeesTable([...feesTableNew])
            }
          }}
        >
          {record.fees.map((value) => (
            <Select.Option
              value={value.fee_id || value.expense_id || value.storage_fee_id}
              key={value._id}
            >
              {value.fee_name || value.expense_name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Đơn giá',
      render: (text, record) => formatCash(record.objFee.fee || 0),
    },
    {
      width: 140,
      title: 'Số lượng',
      render: (text, record, index) =>
        Object.keys(record.objFee).length ? (
          <InputNumber
            style={{ width: '100%' }}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            onBlur={(e) => {
              const value = e.target.value.replaceAll(',', '')
              if (!value) {
                const feesTableNew = [...feesTable]
                feesTableNew[index].objFee.unit_quantity = 0
                feesTableNew[index].objFee.payment_value = 0
                setFeesTable([...feesTableNew])
              }
            }}
            onChange={(value) => {
              if (value) {
                const feesTableNew = [...feesTable]
                feesTableNew[index].objFee.unit_quantity = +value
                feesTableNew[index].objFee.payment_value = +value * +(record.objFee.fee || 0)
                setFeesTable([...feesTableNew])
              }
            }}
            value={record.objFee.unit_quantity || 0}
            placeholder="Nhập số lượng"
            min={0}
          />
        ) : (
          ''
        ),
    },
    {
      title: 'Thành tiền',
      render: (text, record) => formatCash(record.objFee.payment_value || 0),
    },
    {
      title: '',
      width: 35,
      render: (text, record, index) => (
        <Popconfirm
          onConfirm={() => {
            const feesTableNew = [...feesTable]
            feesTableNew.splice(index, 1)
            setFeesTable([...feesTableNew])
          }}
          title="Bạn có muốn xóa chi phí này không?"
        >
          <DeleteOutlined style={{ color: 'red', fontSize: 18, cursor: 'pointer' }} />
        </Popconfirm>
      ),
    },
  ]

  const _getFees = async (type = '') => {
    try {
      if (type) {
        let res
        if (type === 'expense-fee') res = await getExpense({ get_all: true })
        if (type === 'storage-fee') res = await getStorageFees({ get_all: true })
        if (type === 'warehouse-fee') res = await getFees({ get_all: true })

        if (res.status === 200) if (res.data.success) return res.data.data
      }

      return []
    } catch (error) {
      console.log(error)
      return []
    }
  }

  const _getCustomers = async () => {
    try {
      const res = await getAllCustomer({ get_all: true })
      if (res.status === 200) setCustomers(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const _getOrders = async (search = '') => {
    try {
      setOrders([])
      setLoadingOrder(true)
      if (typeOrder === 'import') form.setFieldsValue({ import_order_id: undefined })
      else form.setFieldsValue({ export_order_id: undefined })
      let res
      if (typeOrder === 'import')
        res = await getOrders({ search: search, page: 1, page_size: 50, customer_id: customerId })
      else
        res = await getExportOrders({
          search: search,
          page: 1,
          page_size: 50,
          customer_id: customerId,
        })
      setLoadingOrder(false)
      console.log(res)
      if (res.status === 200) setOrders(res.data.data)
    } catch (error) {
      setLoadingOrder(false)
      console.log(error)
    }
  }

  const _getFeeGroup = async () => {
    try {
      const res = await enumFeeGroup({ get_all: true })
      if (res.status === 200) setFeeGroup(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const _getWarehouses = async () => {
    try {
      const res = await getWarehouses({ get_all: true })
      if (res.status === 200) setWarehouses(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const _addFee = async () => {
    await form.validateFields()
    try {
      const dataForm = form.getFieldsValue()
      if (!dataForm.import_order_id && !dataForm.export_order_id) {
        notification.error({ message: 'Vui lòng chọn đơn phát sinh' })
        return
      }

      dispatch({ type: ACTION.LOADING, data: true })
      const body = {
        ...dataForm,
        import_order_id: dataForm.import_order_id ? +dataForm.import_order_id : null,
        export_order_id: dataForm.export_order_id ? +dataForm.export_order_id : null,
        fee_infos: feesTable
          .filter((fee) => fee.group)
          .map((fee) => ({
            ...fee.objFee,
            group: fee.group,
            unit_quantity: fee.objFee.unit_quantity || 0,
            payment_value: fee.objFee.payment_value || 0,
          })),
      }

      const res = await createFeeInOrder(body)

      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Thêm chi phí khác thành công!' })
          history.goBack()
        } else notification.error({ message: res.data.message || 'Thêm chi phí khác thất bại!' })
      } else notification.error({ message: res.data.message || 'Thêm chi phí khác thất bại!' })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      _addLog(`Create or update other fee: ${JSON.stringify(error)}`)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  useEffect(() => {
    if (location.state) form.setFieldsValue(location.state)

    _getCustomers()
    _getWarehouses()
    _getFeeGroup()
  }, [])

  useEffect(() => {
    if (typeOrder && customerId) _getOrders()
  }, [typeOrder, customerId])

  return (
    <div>
      <Row className="page-title" justify="space-between" style={{ marginBottom: '25px' }}>
        <h3 style={{ cursor: 'pointer' }} onClick={() => history.goBack()}>
          <ArrowLeftOutlined style={{ marginRight: '10px' }} />
          Thêm chi phí khác vào bảng kê chi phí
        </h3>
        <Space size="large">
          <Button
            type="primary"
            danger
            style={{ minWidth: '100px' }}
            onClick={() => history.goBack()}
          >
            Hủy
          </Button>

          <Button
            style={{ minWidth: '100px' }}
            onClick={_addFee}
            className={styles['storage-fee-add-button--orange']}
          >
            Lưu
          </Button>
        </Space>
      </Row>
      <Form form={form} layout="vertical" style={{ padding: '0 20px' }}>
        <Row gutter={40}>
          <Col span={12}>
            <Form.Item
              rules={[{ message: 'Vui lòng chọn doanh nghiệp', required: true }]}
              name="customer_id"
              label="Doanh nghiệp"
            >
              <Select
                onChange={setCustomerId}
                value={customerId}
                showSearch
                filterOption={filterOptionSelect}
                style={{ width: '100%' }}
                placeholder="Chọn doanh nghiệp"
              >
                {customers.map((customer) => (
                  <Select.Option value={customer.customer_id} key={customer.customer_id}>
                    {customer.fullname}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{ message: 'Vui lòng chọn kho phát sinh', required: true }]}
              name="warehouse_id"
              label="Kho phát sinh"
            >
              <Select
                showSearch
                filterOption={filterOptionSelect}
                style={{ width: '100%' }}
                placeholder="Chọn kho phát sinh"
              >
                {warehouses.map((warehouse) => (
                  <Select.Option value={warehouse.warehouse_id} key={warehouse.warehouse_id}>
                    {warehouse.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Row align="middle" style={{ marginTop: 10 }}>
              <Row style={{ fontWeight: 600, fontSize: 16, marginRight: 5 }}>
                Loại đơn phát sinh:{' '}
              </Row>
              <div>
                <Radio.Group onChange={(e) => setTypeOrder(e.target.value)} value={typeOrder}>
                  <Radio value="import">Đơn nhập</Radio>
                  <Radio value="export">Đơn xuất</Radio>
                </Radio.Group>
              </div>
            </Row>
            {typeOrder && (
              <Form.Item
                rules={[{ message: 'Vui lòng chọn đơn phát sinh', required: true }]}
                name={typeOrder === 'import' ? 'import_order_id' : 'export_order_id'}
                label="Đơn phát sinh"
              >
                <Select
                  notFoundContent={loadingOrder ? <Spin /> : null}
                  showSearch
                  filterOption={filterOptionSelect}
                  style={{ width: '100%' }}
                  placeholder="Tìm kiếm theo mã đơn"
                  onSearch={(value) => {
                    if (typingTimeoutRef.current) {
                      clearTimeout(typingTimeoutRef.current)
                    }
                    typingTimeoutRef.current = setTimeout(async () => {
                      _getOrders(value)
                    }, 650)
                  }}
                >
                  {orders.map((order) => (
                    <Select.Option order={order.order_id} key={order.order_id}>
                      {order.code}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>
          <Col span={24}>
            <br />
            <Row justify="space-between" align="middle">
              <b>Danh sách chi phí</b>
              <Button
                onClick={() => {
                  const feesTableNew = [...feesTable]
                  feesTableNew.unshift(INIT_FEE)
                  setFeesTable([...feesTableNew])
                }}
                type="primary"
                style={{ marginBottom: 2 }}
              >
                Thêm chi phí
              </Button>
            </Row>
            <Table dataSource={feesTable} columns={columns} size="small" pagination={false} />
          </Col>
        </Row>
      </Form>
    </div>
  )
}
