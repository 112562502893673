const columnsActivity = [
  {
    key: 'stt',
    title: 'STT',
    align: 'center',
    width: 50,
  },
  {
    title: 'Mã phiếu',
    dataIndex: 'code',
  },
  {
    dataIndex: 'customer_order_code',
    title: 'Số phiếu',
  },
  {
    title: 'Loại phiếu',
    key: 'type',
  },
  {
    key: 'create_date',
    title: 'Ngày tạo phiếu',
  },
  {
    key: 'remove_date',
    title: 'Ngày xóa phiếu',
  },
]
export default columnsActivity
