const columns = [
  {
    key: 'code',
    title: 'Mã đơn hàng',
    dataIndex: 'code',
    align: 'center',
    fixed: 'left',
  },
  {
    key: 'customer_order_code',
    title: 'Số phiếu',
    align: 'center',
  },
  {
    key: 'shipping_company_name',
    title: 'Tên ĐVVC',
    dataIndex: 'shipping_info',
  },
  {
    title: 'Nền tảng',
    dataIndex: 'platform',
    width: 120,
  },
  {
    key: 'car_nummber',
    title: 'Biển số xe',
  },
  {
    key: 'sender',
    title: 'Tên người gửi',
    dataIndex: 'customer_info',
  },
  {
    key: 'to_name',
    title: 'Tên người nhận',
    dataIndex: 'shipping_info',
  },
  {
    key: 'fullname',
    title: 'Tên doanh nghiệp',
    dataIndex: 'customer_info',
  },
  {
    key: 'shipping_code',
    title: 'Mã vận đơn',
    dataIndex: 'shipping_info',
  },
  {
    key: 'cod',
    title: 'Thu hộ',
    dataIndex: 'shipping_info',
  },
  {
    key: 'print_count',
    title: 'Số lần in phiếu',
    width: 70,
  },
  {
    key: 'transport_status',
    title: 'Trạng thái',
    width: 200,
  },
  // {
  //   key: 'action',
  //   title: 'Hành động',
  //   align: 'center',
  // },
  {
    key: 'bill',
    title: 'Biên lai',
    width: 90,
  },
]
export default columns
