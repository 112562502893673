export const barcode = `
  <?xml version="1.0" encoding="UTF-8"?>
  <document>
    <line-feed />
    <align mode="center">
      <text-line size="1:1">{{title}}</text-line>
      <barcode system="CODE_39" width="DOT_250">{{barcode}}</barcode>
    </align>
    <line-feed />
    <paper-cut/>
  </document>`

export const barcodeTest = `
  <?xml version="1.0" encoding="UTF-8"?>
  <document>
    <line-feed />
    <align mode="center">
      <barcode system="CODE_39" width="DOT_250">{{barcode}}</barcode>
    </align>
    <line-feed />
    <paper-cut/>
  </document>`

export const qrcode = `
<?xml version="1.0" encoding="UTF-8"?>
  <document>
    <align mode="center">
      <qrcode ecl="H">{{qrcode}}</qrcode>
    </align>
    <line-feed/>
    <paper-cut/>
  </document>
`

export const qrcodeImage = `
<?xml version="1.0" encoding="UTF-8"?>
  <document>
    <align mode="center">
      <image density="d24">
        {{qrcodeBase64}}
      </image>
    </align>
    <line-feed/>
    <paper-cut/>
  </document>
`

export const printTest = `
  <?xml version="1.0" encoding="UTF-8"?>
  <document>
    <align mode="center">
      <bold>
        <text-line size="4:4">{{text}}</text-line>
      </bold>
    </align>
    <line-feed />
    <paper-cut />
  </document>
`
