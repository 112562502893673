import React, { useEffect, useState } from 'react'
//styles
import styles from './transport-transfer.module.scss'
//ants
import { Button, Col, Form, Input, Modal, Row, Select, Space } from 'antd'
import SuffixIconCustom from 'components/suffixIconCustom'
import Permission from 'components/permission'
import { PERMISSIONS } from 'consts'

export default function ModalPersonalInfo({
  record,
  _updateExportOrders,
  isModalVisible2,
  setIsModalVisible2,
  provinces = [],
  wards = [],
  districts = [],
}) {
  const [preFix, setPreFix] = useState('to_')

  const [valueProvince, setValueProvince] = useState()
  const [valueDistrict, setValueDistrict] = useState()

  const [form] = Form.useForm()

  useEffect(() => {
    if (record.title === 'NGƯỜI GỬI') {
      const pre = ''
      setPreFix(pre)
      _setInitialValues(pre)
    }
    if (record.title === 'NGƯỜI NHẬN') {
      const pre = 'to_'
      setPreFix(pre)
      _setInitialValues(pre)
    }
  }, [isModalVisible2])
  const _setInitialValues = (prefix) => {
    const object = prefix === 'to_' ? record.shipping_info : record.customer_info
    form.setFieldsValue(object)
    const location = ['ward', 'district', 'province']
    const locationWPrefix = location.map((item) => {
      return prefix + item
    })
    locationWPrefix.forEach((item) => {
      if (!object[item]) {
        if (item.includes('province')) {
          form.setFieldsValue({ [item]: provinces[0] && provinces[0].province_name })
        }
        if (item.includes('district')) {
          form.setFieldsValue({ [item]: districts[0] && districts[0].district_name })
        }
        if (item.includes('ward')) {
          form.setFieldsValue({ [item]: wards[0] && wards[0].ward_name })
        }
      }
    })
  }

  const _handleSubmit = () => {
    const dataForm = form.getFieldsValue()
    const body = preFix
      ? { shipping_info: { ...record.shipping_info, ...dataForm } }
      : { customer_info: { ...record.customer_info, ...dataForm } }
    _updateExportOrders(record.order_id, body)
    setIsModalVisible2(false)
  }
  return (
    <Modal
      visible={isModalVisible2}
      width={800}
      footer={
        preFix && (
          <Space>
            <Button
              type="primary"
              danger
              style={{ minWidth: 100 }}
              onClick={() => {
                setIsModalVisible2(false)
              }}
            >
              Hủy
            </Button>
            <Permission permissions={[PERMISSIONS.cap_nhat_ban_giao_van_chuyen]}>
              <Button type="primary" style={{ minWidth: 100 }} onClick={_handleSubmit}>
                Lưu
              </Button>
            </Permission>
          </Space>
        )
      }
      title={
        <div style={{ textAlign: 'center' }} onClick={() => console.log(form.getFieldsValue())}>
          THÔNG TIN {record.title}
        </div>
      }
      onCancel={() => setIsModalVisible2(false)}
    >
      <Form form={form} layout="vertical" className={styles['transport-transfer-form']}>
        <Row>
          <Col span={12} style={{ padding: '0 40px' }}>
            <Form.Item name={preFix ? 'to_name' : 'fullname'} label="Họ và tên">
              {/* customer : fullname; shipping : to_name */}
              <Input allowClear disabled={!preFix} placeholder="Nhập họ và tên" />
            </Form.Item>

            <Form.Item label="Số điện thoại" name={preFix + 'phone'}>
              <Input
                allowClear
                disabled={!preFix}
                maxLength={15}
                placeholder="Nhập số điện thoại"
              />
            </Form.Item>

            <Form.Item name={preFix + 'email'} label="Email">
              <Input allowClear disabled={!preFix} placeholder="Nhập email" />
            </Form.Item>
          </Col>
          <Col span={12} style={{ padding: '0 40px' }}>
            <Form.Item name={preFix + 'address'} label="Địa chỉ">
              <Input allowClear disabled={!preFix} placeholder="Nhập địa chỉ" />
            </Form.Item>

            <Form.Item name={preFix + 'province'} label="Tỉnh/Thành phố">
              <Select
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: '100%' }}
                placeholder="Chọn tỉnh/thành phố"
                suffixIcon={<SuffixIconCustom />}
                value={valueProvince}
                onChange={(value) => setValueProvince(value)}
                disabled={!preFix}
              >
                {provinces.map((item, index) => (
                  <Select.Option key={index} value={item.province_name}>
                    {item.province_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name={preFix + 'district'}
              label="Quận/Huyện"
              rules={[{ message: 'Vui lòng chọn quận/huyện' }]}
            >
              <Select
                allowClear
                disabled={!preFix}
                showSearch
                filterOption={(inputValue, option) => {
                  return option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                }}
                style={{ width: '100%' }}
                placeholder="Chọn quận/huyện"
                suffixIcon={<SuffixIconCustom />}
                onChange={(value) => setValueDistrict(value)}
              >
                {districts.map((item, index) => (
                  <Select.Option key={index} value={item.district_name}>
                    {item.district_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name={preFix + 'ward'}
              label="Phường/Xã"
              rules={[{ message: 'Vui lòng chọn phường xã' }]}
            >
              <Select
                allowClear
                disabled={!preFix}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: '100%' }}
                placeholder="Chọn phường/xã"
                suffixIcon={<SuffixIconCustom />}
              >
                {wards.map((item, index) => (
                  <Select.Option key={index} value={item.ward_name}>
                    {item.ward_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
