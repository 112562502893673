let optionProperties = [
    'Thùng đựng',
    'Ô chứa',
    'Loại sản phẩm',
    'Khách hàng',
    'Phụ liệu',
    'Chi phí kho',
    'Phiếu nhập hàng',
    'Phiếu xuất hàng',
    'Thông số ô kệ',
    'Thiết bị in',
    'Sản phẩm',
    'Vai trò',
    'Kệ hàng',
    'Đơn vị vận chuyển',
    'Loại khách hàng',
    'Nhân viên',
    'Kho hàng',
]

export default optionProperties
