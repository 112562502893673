import React, { useState, useEffect, useRef } from 'react'
import styles from './transported-transfer.module.scss'
import { SIZE_TABLE, POSITION_TABLE, PAGE_SIZE_OPTIONS } from 'consts'
import moment from 'moment'
import { formatCash, compare, _addLog, saveFiltersToLocalStorage, filterOptionSelect } from 'utils'

//components
import columns from './columns'
import TableExpand from './TableExpand'
import SuffixIconCustom from 'components/suffixIconCustom'
import ButtonPrintAllExpense from '../transport-transfer/ButtonPrintAllExpense'
import FilterDate from 'components/filter-date'

//ants
import { CloseCircleOutlined, ReloadOutlined } from '@ant-design/icons'
import { Button, Col, Input, Row, Select, Table, Popover, Form, Space } from 'antd'

//apis
import { getAllCustomer } from 'apis/customer'
import { getExportOrders, updateExportOrders } from 'apis/export-order'
import { getShippingCompany } from 'apis/shipping-company'

export default function TransportTransferred() {
  const KEY_FILTER = 'transportedTransferFilters'
  const filters = localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))

  const [loading, setLoading] = useState(false)
  const [dataCustomer, setDataCustomer] = useState([])
  const [dataExportOrder, setDataExportOrder] = useState([])
  const [exportOrderCount, setExportOrderCount] = useState(0)
  const [expandedRows, setExpandedRows] = useState([])
  const [dataShippingCompany, setDataShippingCompany] = useState([])

  const [paramsFilter, setParamsFilter] = useState(
    filters
      ? { ...filters }
      : {
          page: 1,
          page_size: 100,
          shipping_company_id: '',
          is_transported: true,
          transport_status: 'COMPLETE',
        }
  )
  const [valueSearch, setValueSearch] = useState(filters ? filters.search || '' : '')
  const typingTimeoutRef = useRef()

  const [selectKeys, setSelectKeys] = useState([])
  const [selectRows, setSelectRows] = useState([])

  const [shippingForm] = Form.useForm()

  const _getShippingCompany = async (params) => {
    try {
      const res = await getShippingCompany(params)
      if (res.status === 200) {
        setDataShippingCompany(res.data.data)
        shippingForm.setFieldsValue({ shipping_company_id: res.data.data[0].shipping_company_id })
        return res.data.data[0].shipping_company_id
      }
      return ''
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
      return ''
    }
  }

  const _onSearch = (e) => {
    e.target.value = String(e.target.value).replace(/#/gi, '')
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value

      //khi search hoac filter thi reset page ve 1
      paramsFilter.page = 1

      if (value) paramsFilter.search = value
      else delete paramsFilter.search

      setParamsFilter({ ...paramsFilter })
    }, 650)
  }

  const _getCustomers = async () => {
    try {
      const res = await getAllCustomer()
      if (res.status === 200) {
        const uniqueFullnameData = res.data.data.filter(
          (item, index, self) => index === self.findIndex((t) => t.fullname === item.fullname)
        )
        setDataCustomer(uniqueFullnameData)
      }
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  const _filterByOption = (attribute = '', value = '') => {
    paramsFilter.page = 1

    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]

    setParamsFilter({ ...paramsFilter })
  }

  const _clearFilters = () => {
    setValueSearch('')
    const currentShippingId = paramsFilter.shipping_company_id
    setParamsFilter({
      page: 1,
      page_size: 100,
      is_transported: true,
      shipping_company_id: currentShippingId,
      transport_status: 'COMPLETE',
    })
  }

  const _getOrderDetail = async (orderId = '') => {
    try {
      const res = await getExportOrders({ order_id: orderId })
      if (res.status === 200) {
        const order = res.data.data && res.data.data.length && res.data.data[0]
        if (order) return order
      }

      return null
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const _getExportOrders = async (params = paramsFilter, isTranfering = false) => {
    if (isTranfering) {
      delete params.page
      delete params.page_size
    }

    try {
      setLoading(true)
      const res = await getExportOrders(params)
      if (res.status === 200) {
        const listPromise = res.data.data.map(async (order) => {
          const resOrder = await _getOrderDetail(order.order_id)
          return resOrder
        })

        const orders = await Promise.all(listPromise)
        const ordersNew = orders.filter((order) => order)

        if (!isTranfering) {
          setDataExportOrder(ordersNew)
          setExportOrderCount(res.data.count)
        }
        setLoading(false)
        return ordersNew
      }
      setLoading(false)
      return []
    } catch (error) {
      _addLog(JSON.stringify(error))
      setLoading(false)
      return []
    }
  }

  const _updateExportOrders = async (order_id, params, getExport = true) => {
    params.processing = 'is_transported'
    try {
      setLoading(true)
      const res = await updateExportOrders(order_id, params)
      //console.log(res)
      if (res.status === 200) {
        if (res.data.success) {
          if (getExport) {
            _getExportOrders()
          }
          setLoading(false)
          return res.data.data
        }
      }
      setLoading(false)
      return []
    } catch (error) {
      _addLog(`Update export order: ${JSON.stringify(error)}`)
      setLoading(false)
      return []
    }
  }

  useEffect(() => {
    setSelectKeys([])
    setSelectRows([])
    _getCustomers()
    _getShippingCompany()
  }, [])

  useEffect(() => {
    _getExportOrders()
    saveFiltersToLocalStorage(paramsFilter, KEY_FILTER)
  }, [paramsFilter])

  return (
    <div className={styles['transported-transfer']}>
      <Row className="page-title" justify="space-between" style={{ marginBottom: '25px' }}>
        <Space>
          <h3 style={{ marginRight: '30px' }}>Đã bàn giao vận chuyển</h3>
          <div>
            <div>Lọc theo đơn vị vận chuyển</div>
            <Select
              onChange={(value) => _filterByOption('shipping_company_id', value)}
              placeholder="Lọc theo đơn vị vận chuyển"
              showSearch
              style={{ minWidth: 250 }}
              filterOption={filterOptionSelect}
              value={paramsFilter['shipping_company_id'] || ''}
              suffixIcon={<SuffixIconCustom size="default" />}
              allowClear
            >
              <Select.Option value="">Tất cả</Select.Option>
              {dataShippingCompany.map((item, index) => (
                <Select.Option key={index} value={item.shipping_company_id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Space>
        {dataExportOrder.length ? (
          <ButtonPrintAllExpense
            isTransported={true}
            _getExportOrders={_getExportOrders}
            text="In toàn bộ phiếu xuất hàng kiêm biên bản bàn giao"
          />
        ) : (
          ''
        )}
      </Row>
      <Row
        wrap={false}
        gutter={16}
        style={{ marginTop: 25, marginBottom: 20 }}
        className={styles['employee-management-input-group']}
      >
        <Col span={7}>
          <Input.Search
            allowClear
            enterButton
            value={valueSearch}
            onChange={_onSearch}
            placeholder="Tìm kiếm theo mã đơn hàng"
            style={{ width: '100%', boxShadow: '0px 2px 6px rgba(19, 216, 229, 0.13)' }}
          />
        </Col>
        <Col span={5}>
          <Select
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
            }
            style={{ width: '100%' }}
            value={paramsFilter['customer_id'] || undefined}
            onChange={(value) => _filterByOption('customer_id', value)}
            placeholder="Lọc theo doanh nghiệp"
            suffixIcon={<SuffixIconCustom size="default" />}
          >
            {dataCustomer.map((item, index) => (
              <Select.Option key={index} value={item.customer_id}>
                {item.fullname}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={5}>
          <FilterDate paramsFilter={paramsFilter} setParamsFilter={setParamsFilter} />
        </Col>
        <Col span={7}>
          {Object.keys(paramsFilter).length > 5 && (
            <Button type="primary" danger icon={<CloseCircleOutlined />} onClick={_clearFilters}>
              Xóa bộ lọc
            </Button>
          )}
        </Col>
      </Row>
      <Row justify="space-between" style={{ marginBottom: 5 }}>
        <ButtonPrintAllExpense
          isTransported={true}
          _getExportOrders={_getExportOrders}
          text="In biên bản bàn giao hàng loạt"
          printShippingCode={true}
        />
        <Space>
          {selectKeys.length ? (
            <ButtonPrintAllExpense
              isTransported={true}
              tableRecords={dataExportOrder.filter((order) => selectKeys.includes(order.order_id))}
              text={'In phiếu xuất hàng kiêm biên bản bàn giao được chọn'}
            />
          ) : (
            ''
          )}

          <Button onClick={() => _getExportOrders()} type="primary" icon={<ReloadOutlined />}>
            Làm mới
          </Button>
        </Space>
      </Row>

      <div /* className={styles['transport-transfer-content']} */>
        <Table
          scroll={{ x: 1600, y: '65vh' }}
          rowKey="order_id"
          expandable={{
            expandedRowRender: (record) => {
              return <TableExpand record={record} _updateExportOrders={_updateExportOrders} />
            },
            expandedRowKeys: expandedRows,
            onExpandedRowsChange: (rows) => setExpandedRows(rows),
          }}
          pagination={{
            position: POSITION_TABLE,
            current: paramsFilter.page,
            pageSize: paramsFilter.page_size,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            total: exportOrderCount,
            showQuickJumper: true,
            onChange: (page, pageSize) =>
              setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize }),
          }}
          rowSelection={{
            selectedRowKeys: selectKeys,
            onChange: (keys, rows) => {
              setSelectKeys(keys)
              setSelectRows(rows)
            },
          }}
          loading={loading}
          dataSource={dataExportOrder}
          columns={columns.map((column) => {
            if (column.key === 'code') {
              return {
                ...column,
                sorter: (a, b) => compare(a, b, 'code'),
                render: (text) => '#' + text,
              }
            }
            if (column.key === 'customer_order_code') {
              return {
                ...column,
                sorter: (a, b) => compare(a, b, 'customer_order_code'),
                render: (text, record) => '#' + record.customer_order_code,
              }
            }
            if (column.key === 'shipping_company_name') {
              return {
                ...column,
                sorter: (a, b) =>
                  compare(a.shipping_info, b.shipping_info, 'shipping_company_name'),
                render: (shipping_info) => {
                  return shipping_info.shipping_company_name || ''
                },
              }
            }
            if (column.key === 'car_number') {
              return {
                ...column,
                render: (text, record) => {
                  return (record.shipping_info && record.shipping_info.car_number) || ''
                },
              }
            }
            if (column.key === 'return_name') {
              return {
                ...column,
                sorter: (a, b) => compare(a.shipping_info, b.shipping_info, 'return_name'),
                render: (text) => text.return_name || '',
              }
            }
            if (column.key === 'to_name') {
              return {
                ...column,
                sorter: (a, b) => compare(a.shipping_info, b.shipping_info, 'to_name'),
                render: (text) => text.to_name || '',
              }
            }
            if (column.key === 'fullname') {
              //Tên khách hàng
              return {
                ...column,
                sorter: (a, b) => compare(a.customer_info, b.customer_info, 'fullname'),
                render: (customer_info) => customer_info && customer_info.fullname,
              }
            }

            if (column.key === 'transport_date') {
              return {
                ...column,
                sorter: (a, b) => {
                  const aMoment = a.transport_date ? moment(a.transport_date).unix() : 0
                  const bMoment = b.transport_date ? moment(b.transport_date).unix() : 0
                  return aMoment - bMoment
                },
                render: (text) => {
                  return (
                    text &&
                    moment(text).format('DD/MM/YYYY HH:mm') /* .format('DD/MM/YYYY HH:mm') */
                  )
                },
              }
            }
            if (column.key === 'shipping_code') {
              return {
                ...column,
                sorter: (a, b) => compare(a.shipping_info, b.shipping_info, 'shipping_code'),
                render: (shipping_info) => {
                  return shipping_info.shipping_code || ''
                },
              }
            }
            if (column.key === 'cod') {
              return {
                ...column,
                sorter: (a, b) => compare(a.shipping_info, b.shipping_info, 'cod'),
                render: (shipping_info) => {
                  return shipping_info.cod && formatCash(shipping_info.cod) + ' VNĐ'
                },
              }
            }
            if (column.key === 'shipping_bill') {
              return {
                ...column,
                render: (text, record) => {
                  const shippingBill =
                    record.shipping_info && typeof record.shipping_info.shipping_bill === 'string'
                      ? [record.shipping_info.shipping_bill]
                      : record.shipping_info.shipping_bill
                  return (
                    shippingBill &&
                    shippingBill.map(
                      (img) =>
                        img && (
                          <Popover
                            placement="right"
                            content={<img src={img} style={{ height: 400, width: 400 }} alt="" />}
                          >
                            <img alt="" src={img} style={{ width: 40, height: 40 }} />
                          </Popover>
                        )
                    )
                  )
                },
              }
            }
            // if (column.key === 'action') {
            //   return {
            //     ...column,
            //     render: (text, record) => {
            //       return (
            //         <ButtonPrintAllExpense
            //           isTransported={true}
            //           tableRecords={[record]}
            //           text="In phiếu xuất hàng kiêm biên bản bàn giao"
            //         />
            //       )
            //     },
            //   }
            // }
            return column
          })}
          size={SIZE_TABLE}
          summary={() => (
            <Table.Summary fixed="bottom">
              <Table.Summary.Row></Table.Summary.Row>
            </Table.Summary>
          )}
          sticky
        />
      </div>
    </div>
  )
}
