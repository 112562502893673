import React, { useEffect, useState, useRef } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { _addLog, saveFiltersToLocalStorage, formatCash, isAcceptPermissions } from 'utils'
import styles from './storagefee.module.scss'
import moment from 'moment'
import { PAGE_SIZE_OPTIONS, PERMISSIONS, POSITION_TABLE, ROUTES, SIZE_TABLE } from 'consts'

//components
import SettingColumnsButton from 'components/setting-columns'
import columnsStorageFee from './columns'
import Permission from 'components/permission'
import FilterDate from 'components/filter-date'

// antd
import { Col, Row, Button, Input, Table, notification, Popconfirm, Space } from 'antd'
import { CloseCircleOutlined, DeleteOutlined, ReloadOutlined } from '@ant-design/icons'

//apis
import { deleteFee, getFees } from 'apis/fee'

export default function StorageFee() {
  const history = useHistory()
  const typingTimeoutRef = useRef(null)
  const KEY_FILTER = 'storageFeeFilters'
  const filters = localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))

  const [columns, setColumns] = useState([])
  const [countFee, setCountFee] = useState(0)
  const [selectKeys, setSelectKeys] = useState([])
  const [loading, setLoading] = useState(false)

  const [fees, setFees] = useState([])
  const [paramsFilter, setParamsFilter] = useState(
    filters ? { ...filters } : { page: 1, page_size: 20 }
  )

  const [valueSearch, setValueSearch] = useState(filters ? filters.fee_name || '' : '')

  const _getFees = async () => {
    try {
      setSelectKeys([])
      setLoading(true)
      const res = await getFees(paramsFilter)
      if (res.status === 200) {
        const counts = res.data.counts
        setFees(res.data.data)
        setCountFee(counts)
      }
      setLoading(false)
    } catch (error) {
      _addLog(JSON.stringify(error))
      setLoading(false)
    }
  }

  const _deleteFee = async (id) => {
    try {
      setLoading(true)
      const res = await deleteFee({ fee_id: [id] })
      setLoading(false)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Xóa chi phí thành công!' })
          _getFees()
        } else notification.error({ message: res.data.message || 'Xóa chi phí thất bại' })
      } else notification.error({ message: res.data.message || 'Xóa chi phí thất bại' })
    } catch (err) {
      _addLog(`Delete fee: ${JSON.stringify(err)}`)
      setLoading(false)
    }
  }

  const _search = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      const value = e.target.value

      if (value) paramsFilter.fee_name = value
      else delete paramsFilter.fee_name

      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 450)
  }

  const _clearFilters = () => {
    setSelectKeys([])
    setValueSearch('')
    setParamsFilter({ page: 1, page_size: 20 })
  }

  useEffect(() => {
    _getFees()
    saveFiltersToLocalStorage(paramsFilter, KEY_FILTER)
  }, [paramsFilter])

  return (
    <div className={styles['storage-fee']}>
      <Row className="page-title" justify="space-between" style={{ marginBottom: '25px' }}>
        <h3>Danh sách chi phí dịch vụ vận kho</h3>
        <Space>
          <Button onClick={_getFees} type="primary" icon={<ReloadOutlined />}>
            Làm mới
          </Button>
          <SettingColumnsButton
            columnsRender={columnsStorageFee}
            columns={columns}
            setColumns={setColumns}
            nameColumn="columnsFee"
          />
          <Permission permissions={[PERMISSIONS.tao_chi_phi_kho]}>
            <Button
              className={styles['storage-fee-btn--create-fee']}
              onClick={() => history.push(ROUTES.STORAGE_FEE_ADD)}
            >
              Tạo chi phí
            </Button>
          </Permission>
        </Space>
      </Row>
      <div className={styles['storage-fee-content']}>
        <Row gutter={20} style={{ marginBottom: 25 }}>
          <Col span={8}>
            <Input.Search
              value={valueSearch}
              allowClear
              onChange={_search}
              enterButton
              placeholder="Tìm kiếm theo tên chi phí"
              style={{ boxShadow: '0px 2px 6px rgba(19, 216, 229, 0.13)' }}
            />
          </Col>
          <Col span={6}>
            <FilterDate paramsFilter={paramsFilter} setParamsFilter={setParamsFilter} />
          </Col>
          <Col>
            {Object.keys(paramsFilter).length > 2 && (
              <Button onClick={_clearFilters} type="primary" danger icon={<CloseCircleOutlined />}>
                Xóa bộ lọc
              </Button>
            )}
          </Col>
        </Row>
        <Table
          loading={loading}
          rowKey="fee_id"
          scroll={{ y: '48vh' }}
          style={{ width: '100%' }}
          dataSource={fees}
          columns={columns.map((column) => {
            if (column.key === 'fee_name')
              return {
                ...column,
                render: (text, record, index) =>
                  isAcceptPermissions([PERMISSIONS.cap_nhat_chi_phi_kho]) ? (
                    <Link
                      to={{ pathname: ROUTES.STORAGE_FEE_ADD, state: record }}
                      style={{ color: '#0017E3' }}
                    >
                      {text}
                    </Link>
                  ) : (
                    <b>{text}</b>
                  ),
              }
            if (column.key === 'stt')
              return {
                ...column,
                render: (text, record, index) =>
                  (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
              }
            if (column.key === 'fee')
              return {
                ...column,
                render: (text) =>
                  text ? <span> {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span> : '',
              }
            if (column.key === 'create_date')
              return {
                ...column,
                render: (text) => moment(text).format('DD/MM/YYYY'),
              }
            if (column.key === 'action')
              return {
                ...column,
                render: (text, record) => (
                  <Permission permissions={[PERMISSIONS.xoa_chi_phi_kho]}>
                    <Popconfirm
                      placement="topRight"
                      title="Bạn có muốn xóa chi phí này không?"
                      okText="Đồng ý"
                      cancelText="Từ chối"
                      onConfirm={() => _deleteFee(record.fee_id)}
                    >
                      <Button danger type="primary">
                        <DeleteOutlined />
                      </Button>
                    </Popconfirm>
                  </Permission>
                ),
              }
            if (column.key === 'default_export') {
              return {
                ...column,
                render: (text, record) => (record.default_in_export_order ? 'Có' : ''),
              }
            }
            if (column.key === 'fee_exceeding') {
              return {
                ...column,
                render: (text, record) => formatCash(record.fee_exceeding || 0),
              }
            }
            if (column.key === 'default_import') {
              return {
                ...column,
                render: (text, record) => (record.default_in_import_order ? 'Có' : ''),
              }
            }
            if (column.key === 'unit') {
              return {
                ...column,
                render: (text, record) =>
                  record.unit_info &&
                  `${record.unit_info.name} ${
                    record.unit_info.extension ? `(${record.unit_info.extension})` : ''
                  }`,
              }
            }
            return column
          })}
          size={SIZE_TABLE}
          pagination={{
            position: POSITION_TABLE,
            current: paramsFilter.page,
            pageSize: paramsFilter.page_size,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            total: countFee,
            showQuickJumper: true,
            onChange: (page, pageSize) => {
              paramsFilter.page = page
              paramsFilter.page_size = pageSize
              setParamsFilter({ ...paramsFilter })
            },
          }}
        />
      </div>
    </div>
  )
}
