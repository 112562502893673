const columns = [
  {
    key: 'stt',
    title: 'STT',
    dataIndex: 'stt',
    align: 'center',
    width: 50,
  },
  {
    key: 'expense_name',
    title: 'Tên chi phí',
    dataIndex: 'expense_name',
  },
  {
    title: 'Đơn vị áp dụng',
    key: 'unit',
    width: 150,
  },
  {
    title: 'Phím tắt',
    dataIndex: 'key',
    width: 50,
  },
  {
    key: 'fee',
    title: 'Đơn giá',
    dataIndex: 'fee',
  },
  {
    key: 'fee_exceeding',
    title: 'Chi phí vượt mức',
    dataIndex: 'fee_exceeding',
  },
  {
    key: 'default_import',
    title: 'Mặc định sử dụng trên phiếu nhập',
    width: 100,
  },
  {
    key: 'default_export',
    title: 'Mặc định sử dụng trên phiếu xuất',
    width: 100,
  },
  {
    key: 'create_date',
    title: 'Ngày tạo',
    dataIndex: 'create_date',
  },

  {
    key: 'action',
    title: 'Hành động',
    width: 150,
  },
]

export default columns
