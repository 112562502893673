export const ACTION = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  LOADING: 'LOADING',
}

export const ROUTES = {
  DASHBOARD: '/dashboard',
  PRODUCT: '/product',
  UNIT: '/unit',
  CUSTOMERS: '/customers',
  CUSTOMERS_ADD: '/customers-add',
  EMPLOYEES_MANAGEMENT: '/employees-management',
  CREATE_EMPLOYEE: '/create-employee',
  PRODUCT_DETAIL: '/product-detail',
  LOGIN: '/login',
  WAREHOUSE: '/warehouse',
  WAREHOUSE_ADD: '/warehouse/add',
  THE_SHELVE: '/the-shelve',
  THE_SHELVE_ADD: '/the-shelve-add',
  BUCKET: '/bucket',
  PRODUCT_CHECK_DETAIL: '/product-check-detail',
  POSITION_INPUT: '/position-input',
  AUTO_IMPORT: '/auto-import',
  PRODUCT_IMPORT_DETAIL: '/product-import-detail',
  PRODUCT_IMPORT_SCAN: '/product-import-scan',
  ROLE_MANAGEMENT: '/role-management',
  PRODUCT_EXPORT: '/product-export',
  PRODUCT_EXPORT_LIST: '/product-export-list',
  PRODUCT_IMPORT_LIST: '/product-import-list',
  PRODUCT_EXPORT_SCAN: '/product-export-scan',
  PRODUCT_EXPORT_SCAN_BUCKET: '/product-export-scan-bucket',
  PRODUCT_EXPORT_SCAN_PRODUCT: '/product-export-scan-product',
  PACKAGE: '/package',
  PACKAGE_SCAN: '/package-scan',
  PACKAGE_E_COMMERCE: '/package-e-commerce',
  PACKAGE_SCAN_E_COMMERCE: '/package-scan-e-commerce',
  INGREDIENT: '/ingredient',
  INGREDIENT_ADD: '/ingredient-add',
  SETTING_BOX: '/setting-box',
  STORAGE_FEE: '/storage-fee',
  STORAGE_FEE_ADD: '/storage-fee-add',
  STORAGE_COST: '/storage-cost',
  ORDER_FEE: '/order-fee',
  ADD_FEE_TO_ORDER: '/add-fee-to-order',
  ADD_STORAGE_FEE_OF_ORDER_FEE: '/add-storage-fee-of-order-fee',
  ADD_STORAGE_OTHER_OF_ORDER_FEE: '/add-storage-other-of-order-fee',
  STORAGE_COST_ADD: '/storage-cost-add',
  COST_INVENTORY: '/cost-inventory',
  COST_INVENTORY_ADD: '/cost-inventory-add',
  SHIPPING_COMPANY: '/shipping-company',
  SHIPPING: '/shipping',
  SHIPPING_COMPANY_ADD: '/shipping-company-add',
  REPORT: '/report',
  REPORT_INVENTORY: '/report-inventory',
  REPORT_INVENTORY_DETAIL: '/report-inventory-detail',
  REPORT_IMPORT_WAREHOUSE: '/report-import-warehouse',
  REPORT_EXPORT_WAREHOUSE: '/report-export-warehouse',
  REPORT_PRODUCT_CHECKING: '/report-product-checking',
  REPORT_IMPORT_ORDER: '/report-import-order',
  REPORT_IMPORT_EXPORT: '/report-import-export',
  REPORT_EXPORT_ORDER: '/report-export-order',
  REPORT_PACK_ORDER: '/report-pack-order',
  REPORT_TRANSPORT_ORDER: '/report-transport-order',
  ACTIVITY_HISTORY: '/activity-history',
  PRINTER_MANAGEMENT: '/printer-management',
  MANAGEMENT_IMPORT_EXPORT: '/management-import-export',
  HISTORY_IMPORT_EXPORT_INVENTORY: '/history-import-export-inventory',
  ORDER_COLLECTION_EXPORT: '/order-collection-export',
  ORDER_COLLECTION_IMPORT: '/order-collection-import',
  ORDER_DETAIL_IMPORT: '/order-detail-import',
  ORDER_DETAIL_EXPORT: '/order-detail-export',
  PRODUCT_CHECKING: '/product-checking',
  TRANSPORT_TRANSFER: '/transport-transfer',
  TRANSPORTED_TRANSFER: '/transported-transfer',
  PRIVACY: '/privacy',
  TERM_OF_SERVICE: '/term-of-service',
  PO_DELETE_LIST: '/po-delete-list',
  IMPORT_WAREHOUSE_CREATE: '/import-warehouse-create',
  AUTO_EXPORT: '/auto-export',
}

export const PERMISSIONS = {
  giao_dien_san_pham: 'giao_dien_san_pham',
  giao_dien_quan_ly_quy_cach_san_pham: 'giao_dien_quan_ly_quy_cach_san_pham',
  giao_dien_tong_hop_don_hang_xuat: 'giao_dien_tong_hop_don_hang_xuat',
  giao_dien_tong_hop_don_hang_nhap: 'giao_dien_tong_hop_don_hang_nhap',
  giao_dien_kiem_hang: 'giao_dien_kiem_hang',
  giao_dien_nhap_hang_vao_vi_tri: 'giao_dien_nhap_hang_vao_vi_tri',
  giao_dien_quan_ly_doanh_nghiep: 'giao_dien_quan_ly_doanh_nghiep',
  giao_dien_danh_sach_xuat_hang: 'giao_dien_danh_sach_xuat_hang',
  giao_dien_danh_sach_nhap_hang: 'giao_dien_danh_sach_nhap_hang',
  giao_dien_dong_goi: 'giao_dien_dong_goi',
  giao_dien_dong_goi_tmdt: 'giao_dien_dong_goi_tmdt',
  giao_dien_ban_giao_van_chuyen: 'giao_dien_ban_giao_van_chuyen',
  giao_dien_da_ban_giao_van_chuyen: 'giao_dien_da_ban_giao_van_chuyen',
  giao_dien_kho_hang: 'giao_dien_kho_hang',
  giao_dien_o_chua_hang: 'giao_dien_o_chua_hang',
  giao_dien_thung_dung: 'giao_dien_thung_dung',
  giao_dien_danh_sach_phu_lieu: 'giao_dien_danh_sach_phu_lieu',
  giao_dien_chi_phi_kho: 'giao_dien_chi_phi_kho',
  giao_dien_chi_phi_luu_kho: 'giao_dien_chi_phi_luu_kho',
  giao_dien_bang_ke_chi_phi: 'giao_dien_bang_ke_chi_phi',
  giao_dien_don_vi_van_chuyen: 'giao_dien_don_vi_van_chuyen',
  giao_dien_quan_ly_nhan_vien: 'giao_dien_quan_ly_nhan_vien',
  giao_dien_quan_ly_phan_quyen: 'giao_dien_quan_ly_phan_quyen',
  giao_dien_lich_su_thao_tac: 'giao_dien_lich_su_thao_tac',
  giao_dien_quan_ly_xuat_nhap: 'giao_dien_quan_ly_xuat_nhap',
  giao_dien_quan_ly_thiet_bi_in: 'giao_dien_quan_ly_thiet_bi_in',
  giao_dien_bao_cao_ton_kho_tong_hop: 'giao_dien_bao_cao_ton_kho_tong_hop',
  giao_dien_bao_cao_nhap_kho: 'giao_dien_bao_cao_nhap_kho',
  giao_dien_bao_cao_xuat_kho: 'giao_dien_bao_cao_xuat_kho',
  giao_dien_bao_cao_kiem_hang: 'giao_dien_bao_cao_kiem_hang',
  giao_dien_bao_cao_nhap_hang: 'giao_dien_bao_cao_nhap_hang',
  giao_dien_bao_cao_xuat_hang: 'giao_dien_bao_cao_xuat_hang',
  giao_dien_bao_cao_dong_goi: 'giao_dien_bao_cao_dong_goi',
  giao_dien_bao_cao_ban_giao_van_chuyen: 'giao_dien_bao_cao_ban_giao_van_chuyen',
  giao_dien_quan_ly_phieu_xoa: 'giao_dien_quan_ly_phieu_xoa',
  giao_dien_chuyen_layout: 'giao_dien_chuyen_layout',

  xoa_don_hang_nhap: 'xoa_don_hang_nhap',
  xoa_don_hang_xuat: 'xoa_don_hang_xuat',

  them_quy_cach_san_pham: 'them_quy_cach_san_pham',
  xoa_quy_cach_san_pham: 'xoa_quy_cach_san_pham',
  cap_nhat_quy_cach_san_pham: 'cap_nhat_quy_cach_san_pham',

  // Chức năng
  tao_san_pham: 'tao_san_pham',
  xem_san_pham: 'xem_san_pham',
  them_anh_san_pham: 'them_anh_san_pham',
  cap_nhat_san_pham: 'cap_nhat_san_pham',
  cap_nhat_ma_san_pham: 'cap_nhat_ma_san_pham',
  xoa_san_pham: 'xoa_san_pham',

  tao_kiem_hang: 'tao_kiem_hang',
  cap_nhat_bien_lai_phieu_nhap: 'cap_nhat_bien_lai_phieu_nhap',

  cap_nhat_nhap_hang_vao_vi_tri: 'cap_nhat_nhap_hang_vao_vi_tri',

  tao_doanh_nghiep: 'tao_doanh_nghiep',
  cap_nhat_doanh_nghiep: 'cap_nhat_doanh_nghiep',
  xoa_doanh_nghiep: 'xoa_doanh_nghiep',

  tao_danh_sach_xuat_hang: 'tao_danh_sach_xuat_hang',
  cap_nhat_danh_sach_xuat_hang: 'cap_nhat_danh_sach_xuat_hang',

  cap_nhat_dong_goi: 'cap_nhat_dong_goi',

  tao_don_vi_van_chuyen: 'tao_don_vi_van_chuyen',
  cap_nhat_don_vi_van_chuyen: 'cap_nhat_don_vi_van_chuyen',
  xoa_don_vi_van_chuyen: 'xoa_don_vi_van_chuyen',

  cap_nhat_ban_giao_van_chuyen: 'cap_nhat_ban_giao_van_chuyen',

  tao_kho_hang: 'tao_kho_hang',
  tao_ke_hang: 'tao_ke_hang',
  cap_nhat_kho_hang: 'cap_nhat_kho_hang',
  xoa_kho_hang: 'xoa_kho_hang',

  cap_nhat_o_chua_hang: 'cap_nhat_o_chua_hang',

  tao_thung_dung: 'tao_thung_dung',
  cap_nhat_thung_dung: 'cap_nhat_thung_dung',
  xoa_thung_dung: 'xoa_thung_dung',

  tao_phu_lieu: 'tao_phu_lieu',
  cap_nhat_phu_lieu: 'cap_nhat_phu_lieu',
  xoa_phu_lieu: 'xoa_phu_lieu',

  tao_chi_phi_kho: 'tao_chi_phi_kho',
  cap_nhat_chi_phi_kho: 'cap_nhat_chi_phi_kho',
  xoa_chi_phi_kho: 'xoa_chi_phi_kho',

  tao_chi_phi_luu_kho: 'tao_chi_phi_luu_kho',
  cap_nhat_chi_phi_luu_kho: 'cap_nhat_chi_phi_luu_kho',
  xoa_chi_phi_luu_kho: 'xoa_chi_phi_luu_kho',

  xoa_bang_ke_chi_phi: 'xoa_bang_ke_chi_phi',
  them_chi_phi_luu_kho_vao_bang_ke_chi_phi: 'them_chi_phi_luu_kho_vao_bang_ke_chi_phi',
  them_chi_phi_khac_vao_bang_ke_chi_phi: 'them_chi_phi_khac_vao_bang_ke_chi_phi',
  them_chi_phi_vao_phieu_xuat: 'them_chi_phi_vao_phieu_xuat',
  them_chi_phi_vao_phieu_nhap: 'them_chi_phi_vao_phieu_nhap',
  kiem_duyet_bang_ke_chi_phi: 'kiem_duyet_bang_ke_chi_phi',

  tao_nhan_vien: 'tao_nhan_vien',
  cap_nhat_nhan_vien: 'cap_nhat_nhan_vien',
  xoa_nhan_vien: 'xoa_nhan_vien',

  tao_phan_quyen: 'tao_phan_quyen',
  cap_nhat_phan_quyen: 'cap_nhat_phan_quyen',
  xoa_phan_quyen: 'xoa_phan_quyen',

  cap_nhat_thiet_bi_in: 'cap_nhat_thiet_bi_in',

  tao_phieu_nhap_kho_thu_cong: 'tao_phieu_nhap_kho_thu_cong',

  tao_nhom_po: 'tao_nhom_po',
}

export const FIXEDROLES = [
  'giamdoc',
  'ketoan',
  'quanlyvanhanh',
  'phoquanlyvanhanh',
  'nhanviennhaphang',
  'nhanvienxuathang',
  'nhanviensoanhang',
  'nhanvienbangiao',
]

export const PRINTER = {
  SAN_PHAM: 'Máy in barcode sản phẩm',
  XUAT_KHO: 'Máy in barcode xuất kho',
  NHAP_KHO: 'Máy in barcode nhập kho',
  PHU_LIEU: 'Máy in barcode phụ liệu',
  THUNG_DUNG: 'Máy in barcode thùng đựng',
  O_KE: 'Máy in barcode ô kệ',
}

export const IMAGE_DEFAULT =
  'https://s3.ap-northeast-1.wasabisys.com/admin-order/2022/01/08/aac1e09f-0278-4430-9912-be8261e25b60/2022-01-08%2012.17%201.png'

export const VERSION_APP = '2.4.39'
export const TYPE_DEPLOY = 'LIVE'
export const OPEN_MERCHANT = 'https://merchant.saigonlogistics.vn/'

export const regexPhone = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/

export const POSITION_TABLE = ['bottomRight']
export const SIZE_TABLE = 'small'
export const PAGE_SIZE_OPTIONS = [10, 20, 30, 40, 50, 60, 70, 80, 90]
