import React, { useEffect, useState, useRef, memo, useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { ACTION, PERMISSIONS, PRINTER } from 'consts'
import { formatCash, _addLog } from 'utils'
import delay from 'delay'

//components
import printToThermalPrinter from 'components/thermal-printer/print'
import { barcode, qrcode } from 'components/thermal-printer/templates'
import SuffixIconCustom from 'components/suffixIconCustom'
import BackToTop from 'components/back-to-top'
import ModalOptionsPrint from 'components/modal-opions-print'
import Permission from 'components/permission'

//antd
import {
  Row,
  Input,
  Space,
  Button,
  Spin,
  Select,
  notification,
  Modal,
  Table,
  InputNumber,
  Form,
} from 'antd'

//icons
import { PlusOutlined, PrinterOutlined, ReloadOutlined } from '@ant-design/icons'

//apis
import { getBuckets, addBucket, deleteBuckets } from 'apis/bucket'
import { getTheShelve, updateTheShelve } from 'apis/shelve'
import { getWarehouses } from 'apis/warehouse'
import { getPrint } from 'apis/print'
import { getParameters, updateParamter, addParamter } from 'apis/parameter'

export default function Bucket() {
  const dispatch = useDispatch()
  const dataUser = useSelector((state) => state.user)
  const typingTimeoutRef = useRef()

  const [paramsFilter, setParamsFilter] = useState({ from_column: 1, to_column: 100 })

  const [fromColumn, setFromColumn] = useState(1)
  const [toColumn, setToColumn] = useState(100)
  const [maxSpace, setMaxSpace] = useState(0)

  const [maxRow, setMaxRow] = useState(0)
  const [maxColumn, setMaxColumn] = useState(0)
  const [shelves, setShelves] = useState([])
  const [loadingShelve, setLoadingShelve] = useState(false)
  const [warehouses, setWarehouses] = useState([])
  const [parameters, setParameters] = useState([])

  const [openBuckets, setOpenBuckets] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [productsView, setProductsView] = useState([])
  const [buckets, setBuckets] = useState([])
  const [bucketsSearch, setBucketsSearch] = useState([])
  const [selectBuckets, setSelectBuckets] = useState([])
  // const [bucketsRender, setBucketsRender] = useState([])
  const [btnAddBucketRender, setBtnAddBucketRender] = useState([])

  const [visibleModalView, setVisibleModalView] = useState(false)
  const [visibleModalView1, setVisibleModalView1] = useState(false)
  const [visibleModalView2, setVisibleModalView2] = useState(false)
  const [SelectedBucket, setSelectedBucket] = useState({})
  const [form] = Form.useForm()

  const _onFilters = (attribute = '', value = '') => {
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter })
  }

  const _filterByColumns = (value, attribute = '') => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      if (value) paramsFilter[attribute] = value
      else delete paramsFilter[attribute]
      setParamsFilter({ ...paramsFilter })
    }, 550)
  }
  const loadingScreen = useSelector((state) => state.loading)

  const ModalViewProducts = () => {
    const columns = [
      {
        title: 'STT',
        align: 'center',
        width: 50,
        render: (text, record, index) => index + 1,
      },

      {
        title: 'Mã sản phẩm',
        render: (text, record) =>
          record.product_info ? record.product_info?.barcode : record.product_info?.code,
      },
      {
        title: 'Tên sản phẩm',
        render: (text, record) => (record.product_info ? record.product_info.name : ''),
      },
      {
        title: 'Loại sản phẩm',
        render: (text, record) =>
          record.product_info?.group_info ? record.product_info.group_info.name : '',
      },
      {
        title: 'Nhóm sản phẩm',
        render: (text, record) =>
          record.product_info?.type_info ? record.product_info.type_info.name : '',
      },

      {
        title: 'Giá tiền',
        render: (text, record) => formatCash(record.product_info?.price || 0),
        align: 'center',
      },
      {
        title: 'Chiều dài',
        render: (text, record) => formatCash(record.product_info?.length || 0),
        align: 'center',
      },
      {
        title: 'Chiều cao',
        render: (text, record) => formatCash(record.product_info?.height || 0),
        align: 'center',
      },
      {
        title: 'Chiều rộng',
        render: (text, record) => formatCash(record.product_info?.weight || 0),
        align: 'center',
      },
      {
        title: 'CBM',
        render: (text, record) => formatCash(record.product_info?.cbm || 0),
        align: 'center',
      },
      {
        title: 'Thể tích',
        render: (text, record) => formatCash(record.product_info?.area || 0),
        align: 'center',
      },
      {
        title: 'Tên khách hàng',
        render: (text, record) =>
          record.product_info?.customer_info ? record.product_info.customer_info.fullname : '',
      },
      {
        title: 'Số lượng trong ô kệ',
        render: (text, record) => formatCash(record.quantity || 0),
        align: 'center',
      },
    ]

    return (
      <Modal
        width="98%"
        footer={null}
        onCancel={() => setVisibleModalView(false)}
        visible={visibleModalView}
        title={`Danh sách sản phẩm ô chứa ${SelectedBucket.hotname && SelectedBucket.hotname}`}
      >
        <Table
          scroll={{ y: 400 }}
          size="small"
          dataSource={productsView}
          columns={columns}
          style={{ width: '100%' }}
          pagination={false}
        />
      </Modal>
    )
  }

  const _checkIsEmptyShelveInWarehouse = async (params) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getTheShelve(params)
      dispatch({ type: ACTION.LOADING, data: false })
      if (res.status === 200) {
        if (res.data.data && res.data.data.length) return true
        else {
          notification.warning({
            message: 'Kho hàng này chưa có kệ hàng, vui lòng chọn kho hàng khác',
          })
          return false
        }
      } else {
        notification.warning({ message: 'Chọn kho hàng đang bị lỗi, vui lòng chọn kho hàng khác' })
        return false
      }
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
      _addLog(`checkIsEmptyShelveInWarehouse: ${JSON.stringify(error)}`)
      return false
    }
  }
  const _getPrint = async (params) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getPrint(params)
      if (res.status === 200) {
        dispatch({ type: ACTION.LOADING, data: false })
        return res.data.data[0]
      }
      dispatch({ type: ACTION.LOADING, data: false })
      return ''
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
      _addLog(`get print: ${JSON.stringify(error)}`)
      return ''
    }
  }

  const _deleteBuckets = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })

      const res = await deleteBuckets({ bucket_id: selectBuckets })
      if (res.status === 200) {
        if (res.data.success) {
          _getBuckets()
          notification.success({ message: 'Xóa ô chứa hàng thành công!' })
          setSelectBuckets([])
        } else
          notification.error({
            message: res.data.mess || res.data.message || 'Xóa ô chứa hàng thất bại!',
          })
      } else
        notification.error({
          message: res.data.mess || res.data.message || 'Xóa ô chứa hàng thất bại!',
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      _addLog(`delete bucket: ${JSON.stringify(error)}`)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const _addBucket = async (buckets) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await addBucket({ buckets: buckets })
      dispatch({ type: ACTION.LOADING, data: false })

      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Tạo các ô chứa hàng thành công' })
          _getBuckets() //reload
        } else
          notification.error({
            message: res.data.message || 'Tạo ô chứa hàng thất bại, vui lòng thử lại',
          })
      } else
        notification.error({
          message: res.data.message || 'Tạo ô chứa hàng thất bại, vui lòng thử lại',
        })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      _addLog(`add bucket: ${JSON.stringify(error)}`)
      console.log(error)
    }
  }

  const _getBucketsSearch = async (search = '') => {
    try {
      const res = await getBuckets({ search: search })
      if (res.status === 200) {
        const dataList = res.data.data
        setBucketsSearch(dataList)
      }
    } catch (error) {
      console.log(error)
      _addLog(`get bucket: ${JSON.stringify(error)}`)
    }
  }

  const _getBuckets = async () => {
    //get danh sách bucket luôn phải có ít nhất 2 query là warehouse_id và shelve_id
    if (paramsFilter.length < 2) return
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getBuckets(paramsFilter)
      dispatch({ type: ACTION.LOADING, data: false })
      if (res.status === 200) {
        const dataList = res.data.data
        setBuckets(dataList)

        if (dataList && dataList.length) {
          setMaxSpace(dataList[0].parameter_info?.max_space || [])
        } else window.scrollTo(0, document.body.scrollHeight)
      }
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
      _addLog(`get bucket: ${JSON.stringify(error)}`)
    }
  }

  const _updateTheShelve = async (max_row, max_column) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const body = { max_row, max_column }
      const res = await updateTheShelve(paramsFilter.shelve_id, body)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Cập nhật thành công' })
          await _getShelves({ warehouse_id: paramsFilter.warehouse_id || '' })
          await _getBuckets()
        } else
          notification.error({ message: res.data.message || 'Cập nhật thất bại, vui lòng thử lại' })
      } else
        notification.error({ message: res.data.message || 'Cập nhật thất bại, vui lòng thử lại' })

      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
      _addLog(`update bucket: ${JSON.stringify(error)}`)
    }
  }
  const [shelveFirst, setShelveFirst] = useState(null)
  const bucketsRender = useMemo(() => {
    let bucketsNew = []
    if (shelveFirst) {
      let btnAddBucketRender = []
      for (let j = 0; j < shelveFirst.max_column; j++)
        btnAddBucketRender.push({ row: shelveFirst.max_row, column: j + 1 })

      for (let i = 0; i < shelveFirst.max_row; i++) {
        bucketsNew.push([])
        for (let j = 0; j < shelveFirst.max_column; j++) {
          const findBucketColumn = btnAddBucketRender.find(
            (e) => e.row === i + 1 && e.column === j + 1
          )

          const findBucket = buckets.find((b) => b.row == i + 1 && b.column == j + 1)

          bucketsNew[i].push({
            row: i + 1,
            column: j + 1,
            isRenderBtnAdd: findBucketColumn ? true : false,
            bucket: findBucket ? findBucket : null,
          })
        }
      }
    }
    return [...bucketsNew]
  }, [JSON.stringify({ ...shelveFirst }), JSON.stringify([...buckets])])

  const _getShelves = async (params) => {
    try {
      setLoadingShelve(true)
      const res = await getTheShelve(params)
      if (res.status === 200) {
        setShelves(res.data.data.reverse())

        //default filter shelve_id first in array shelve
        if (res.data.data && res.data.data.length) {
          const shelveFirst = res.data.data[0]
          setShelveFirst(shelveFirst)

          setMaxColumn(shelveFirst.max_column)
          setMaxRow(shelveFirst.max_row)

          // let btnAddBucketRender = []
          // for (let j = 0; j < shelveFirst.max_column; j++)
          //   btnAddBucketRender.push({ row: shelveFirst.max_row, column: j + 1 })

          // setBtnAddBucketRender([...btnAddBucketRender])

          paramsFilter.shelve_id = shelveFirst.shelve_id
        }
      }
      setParamsFilter({ ...paramsFilter, ...params })
      setLoadingShelve(false)
    } catch (error) {
      setLoadingShelve(false)
      console.log(error)
      _addLog(`get shelve: ${JSON.stringify(error)}`)
    }
  }

  const ModalUpdateSpace = () => {
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const toggle = () => setVisible(!visible)

    const shelve = shelves.find((s) => paramsFilter.shelve_id == s.shelve_id)
    const [parameterList, setParameterList] = useState([...parameters.map((p) => ({ ...p }))])
    const [selectedRowKeys, setSelectedRowKeys] = useState(shelve ? [shelve.parameter_id] : [])

    const _updateShelve = async () => {
      try {
        if (shelve && selectedRowKeys.length) {
          const body = { parameter_id: selectedRowKeys[0] }
          const res = await updateTheShelve(shelve.shelve_id, body)
          if (res.status === 200) _getShelves({ warehouse_id: paramsFilter.warehouse_id })
        }
      } catch (error) {
        console.log(error)
        _addLog(`update shelve: ${JSON.stringify(error)}`)
      }
    }

    const _updateParameter = async () => {
      try {
        await form.validateFields()

        if (shelve) {
          setLoading(true)

          const listPromise = parameterList.map(async (parameter) => {
            const body = {
              width: parameter.width || 0,
              length: parameter.length || 0,
              height: parameter.height || 0,
            }
            let res = await updateParamter(parameter.parameter_id, body)
            return res
          })
          const resList = await Promise.all(listPromise)

          //update lại kệ hàng
          await _updateShelve()

          setLoading(false)
          for (let i = 0; i < resList.length; i++) {
            if (resList[i].status === 200) {
              if (resList[i].data.success) {
              } else {
                notification.error({
                  message: resList[i].data.message || 'Cập nhật kệ hàng thất bại',
                })
                return
              }
            } else {
              notification.error({
                message: resList[i].data.message || 'Cập nhật kệ hàng thất bại',
              })
              return
            }
          }
        }
        toggle()
        notification.success({ message: 'Cập nhật kệ hàng thành công' })
      } catch (error) {
        setLoading(false)
        console.log(error)
        _addLog(`update parameter: ${JSON.stringify(error)}`)
      }
    }

    const onChangeParameter = (attribute = '', value = '', index) => {
      const parametersNew = [...parameterList]
      parametersNew[index][attribute] = value || 0
      setParameterList([...parametersNew])
    }

    const columns = [
      {
        title: 'Tên thông số',
        dataIndex: 'name',
      },
      {
        title: 'Chiều dài',
        render: (text, record, index) => (
          <InputNumber
            onChange={(value) => onChangeParameter('length', value, index)}
            value={record.length}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            placeholder="Nhập chiều dài"
            style={{ width: '100%' }}
            min={0}
            allowClear
          />
        ),
      },
      {
        title: 'Chiều rộng',
        render: (text, record, index) => (
          <InputNumber
            onChange={(value) => onChangeParameter('width', value, index)}
            value={record.width}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            placeholder="Nhập chiều dài"
            style={{ width: '100%' }}
            min={0}
            allowClear
          />
        ),
      },
      {
        title: 'Chiều cao',
        render: (text, record, index) => (
          <InputNumber
            onChange={(value) => onChangeParameter('height', value, index)}
            value={record.height}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            placeholder="Nhập chiều dài"
            style={{ width: '100%' }}
            min={0}
            allowClear
          />
        ),
      },
    ]

    const ModalCreateParameter = () => {
      const [visible, setVisible] = useState(false)
      const [loading, setLoading] = useState(false)
      const toggle = () => setVisible(!visible)
      const [formParameter] = Form.useForm()

      const _addParamter = async () => {
        try {
          await formParameter.validateFields()
          const body = formParameter.getFieldsValue()
          setLoading(true)
          let res = await addParamter(body)
          if (res.status === 200) {
            if (res.data.success) {
              await _getParameters()
              setVisible(false)
              notification.success({ message: 'Tạo thông số thành công' })
            } else notification.error({ message: res.data.message || 'Tạo thông số thất bại' })
          } else notification.error({ message: res.data.message || 'Tạo thông số thất bại' })
          setLoading(false)
        } catch (error) {
          setLoading(false)
          console.log(error)
          _addLog(`add parameter: ${JSON.stringify(error)}`)
        }
      }

      useEffect(() => {
        if (!visible) formParameter.resetFields()
      }, [visible])

      return (
        <>
          <Button
            onClick={toggle}
            style={{ marginBottom: 5 }}
            icon={<PlusOutlined />}
            type="primary"
            loading={loading}
          >
            Tạo thông số
          </Button>
          <Modal
            footer={
              <Row justify="end">
                <Space>
                  <Button onClick={toggle}>Đóng</Button>
                  <Button type="primary" loading={loading} onClick={_addParamter}>
                    Tạo
                  </Button>
                </Space>
              </Row>
            }
            title="Tạo thông số"
            visible={visible}
            onCancel={toggle}
          >
            <Form form={formParameter} layout="vertical">
              <Form.Item
                label="Tên thông số"
                name="name"
                rules={[{ message: 'Vui lòng nhập tên thông số', required: true }]}
              >
                <Input placeholder="Nhập tên thông số" style={{ width: '100%' }} allowClear />
              </Form.Item>
              <Form.Item
                label="Chiều dài"
                name="length"
                rules={[{ message: 'Vui lòng nhập chiều dài', required: true }]}
              >
                <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  placeholder="Nhập chiều dài"
                  style={{ width: '100%' }}
                  min={0}
                />
              </Form.Item>
              <Form.Item
                label="Chiều rộng"
                name="width"
                rules={[{ message: 'Vui lòng nhập chiều rộng', required: true }]}
              >
                <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  placeholder="Nhập chiều rộng"
                  style={{ width: '100%' }}
                  min={0}
                />
              </Form.Item>
              <Form.Item
                label="Chiều cao"
                name="height"
                rules={[{ message: 'Vui lòng nhập chiều cao', required: true }]}
              >
                <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  placeholder="Nhập chiều cao"
                  style={{ width: '100%' }}
                  min={0}
                />
              </Form.Item>
            </Form>
          </Modal>
        </>
      )
    }

    useEffect(() => {
      if (visible) setParameterList([...parameters.map((p) => ({ ...p }))])
    }, [visible])

    return (
      <>
        <Permission permissions={[PERMISSIONS.cap_nhat_o_chua_hang]}>
          <Button onClick={toggle} type="primary">
            Cập nhật thông số kệ hàng
          </Button>
        </Permission>
        <Modal
          style={{ top: 20 }}
          width="68%"
          footer={
            <Row justify="end">
              <Space>
                <Button onClick={toggle}>Hủy</Button>
                <Button type="primary" loading={loading} onClick={_updateParameter}>
                  Lưu
                </Button>
              </Space>
            </Row>
          }
          title={`Cập nhật thông số của kệ hàng ${shelve && shelve.hotname}`}
          visible={visible}
          onCancel={toggle}
        >
          <Row justify="space-between" align="middle">
            <b>Danh sách thông số</b>
            <ModalCreateParameter />
          </Row>
          <Table
            scroll={{ y: '58vh' }}
            rowKey="parameter_id"
            rowSelection={{
              type: 'radio',
              selectedRowKeys: selectedRowKeys,
              onChange: (keys) => setSelectedRowKeys(keys),
            }}
            pagination={false}
            style={{ width: '100%' }}
            dataSource={parameterList}
            columns={columns}
          />
        </Modal>
      </>
    )
  }

  const _getParameters = async () => {
    try {
      const res = await getParameters()
      if (res.status === 200) setParameters(res.data.data)
    } catch (error) {
      console.log(error)
      _addLog(`get parameter: ${JSON.stringify(error)}`)
    }
  }

  const _getWarehouses = async () => {
    try {
      const res = await getWarehouses()
      if (res.status === 200) {
        setWarehouses(res.data.data)

        //default filter by warehouse_id of user info
        const findWarehouse = res.data.data.find(
          (e) => e.warehouse_id === paramsFilter.warehouse_id
        )
        if (findWarehouse) _getShelves({ warehouse_id: findWarehouse.warehouse_id })
        else {
          if (res.data.data && res.data.data.length)
            _getShelves({ warehouse_id: res.data.data[0].warehouse_id })
        }
      }
    } catch (error) {
      console.log(error)
      _addLog(`get warehouse: ${JSON.stringify(error)}`)
    }
  }
  const _waitting = async (time) => {
    await new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true)
      }, time)
    })
  }

  const _renderBuckets = useMemo(() => {
    return (
      <Space direction="vertical" size="middle">
        {bucketsRender.map((b) => (
          <Space wrap={false} size="middle">
            {b.map((bucket) => {
              const findBucket = bucket.bucket

              if (findBucket)
                return (
                  <div
                    style={{
                      width: 165,
                      height: 130,
                      backgroundColor: '#c1f3fb',
                      boxShadow: '0px 0px 7px rgba(19, 216, 229, 0.3)',
                      borderRadius: '5px',
                      padding: 5,
                    }}
                  >
                    <Row justify="space-between" wrap={false}>
                      {/* <div>Số lượng: {formatCash(+findBucket.product_quantity || 0)}</div> */}
                    </Row>

                    <p
                      style={{
                        textAlign: 'center',
                        fontWeight: 600,
                        fontSize: 15.5,
                        marginBottom: 0,
                      }}
                    >
                      {findBucket.hotname || ''}
                    </p>
                    <p
                      style={{
                        textAlign: 'center',
                        fontWeight: 600,
                        fontSize: 14,
                        marginBottom: 0,
                      }}
                    >
                      {findBucket.code || ''}
                    </p>

                    <Row justify="center">
                      <ModalOptionsPrint
                        isQRCodeBucket
                        printWithInternet={async () => {
                          const params = {
                            warehouse_id: findBucket.warehouse_id,
                            name: PRINTER.O_KE,
                          }
                          const dataPrinter = await _getPrint(params)
                          printToThermalPrinter(
                            barcode,
                            {
                              barcode: findBucket.code,
                              title: 'O ke: ' + findBucket.hotname?.replace(/-{1,}/g, ' '),
                            },
                            dataPrinter && dataPrinter.ip
                          )
                        }}
                        records={[findBucket]}
                      >
                        <PrinterOutlined
                          style={{
                            color: 'orange',
                            fontSize: 18,
                            marginBottom: 7,
                            cursor: 'pointer',
                          }}
                        />
                      </ModalOptionsPrint>
                    </Row>
                    <Row justify="center">
                      <Button
                        onClick={() => _getProductsByBucket(findBucket.bucket_id)}
                        size="small"
                        type="primary"
                      >
                        DS sản phẩm
                      </Button>
                    </Row>
                  </div>
                )

              return (
                <div
                  onClick={() => {
                    let buckets = []
                    for (let i = 0; i < maxRow; i++)
                      buckets.push({
                        warehouse_id: paramsFilter.warehouse_id || '',
                        shelve_id: paramsFilter.shelve_id || '',
                        column: bucket.column,
                        row: i + 1,
                      })
                    _addBucket(buckets)
                  }}
                  type="dashed"
                  style={{
                    maxWidth: 150,
                    maxHeight: 110,
                    minWidth: 150,
                    minHeight: 110,
                    fontSize: 20,
                    backgroundColor: 'rgba(216, 216, 216, 0.75)',
                    cursor: 'pointer',
                    visibility: bucket.isRenderBtnAdd ? '' : 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px dashed #000000',
                    borderRadius: '5px',
                  }}
                >
                  +
                </div>
              )
            })}
          </Space>
        ))}
      </Space>
    )
  }, [JSON.stringify([...bucketsRender])])

  const _printBarcode = async (buckets) => {
    if (!Array.isArray(buckets)) return false
    const params = {
      warehouse_id: buckets.length ? buckets[0].warehouse_id : '',
      name: PRINTER.O_KE,
    }
    const dataPrinter = await _getPrint(params)
    for (let bucket in buckets) {
      printToThermalPrinter(
        barcode,
        {
          barcode: buckets[bucket].code,
          title: 'O ke: ' + buckets[bucket].hotname?.replace(/-{1,}/g, ' '),
        },
        dataPrinter && dataPrinter.ip
      )
      await _waitting(1000)
    }
  }

  const _getProductsByBucket = async (bucket_id) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getBuckets({ bucket_id: bucket_id, product_info: true })
      if (res.status === 200)
        if (res.data.data && res.data.data.length) {
          setProductsView(res.data.data[0].product_info)
          setSelectedBucket(res.data.data[0])
        }
      setVisibleModalView(true)

      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
      _addLog(`get products by bucket: ${JSON.stringify(error)}`)
    }
  }

  const _handleModalPrint = async () => {
    const formData = form.getFieldsValue()
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getBuckets({ ...paramsFilter, ...formData })
      if (res.status === 200) {
        if (res.data.data && res.data.data.length) {
          await _printBarcode(res.data.data)
        }
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
      _addLog(`handle modal print: ${JSON.stringify(error)}`)
    }
  }

  const [recordsPrint, setRecordsPrint] = useState([])
  const _handleModalPrints = () => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      const formData = form.getFieldsValue()
      try {
        if (formData.from_column && formData.to_column) {
          const res = await getBuckets({ ...paramsFilter, ...formData })
          if (res.status === 200) if (res.data.data) setRecordsPrint(res.data.data || [])
        }
      } catch (error) {
        console.log(error)
        _addLog(`handle modal print: ${JSON.stringify(error)}`)
      }
    }, 550)
  }

  useEffect(() => {
    _getBuckets()
  }, [paramsFilter])

  useEffect(() => {
    _getWarehouses()
    _getParameters()
    _getBucketsSearch()
  }, [])

  return (
    <div>
      <BackToTop />
      <Modal
        visible={visibleModalView1}
        width={500}
        title="In QR Code theo cột"
        onCancel={() => setVisibleModalView1(false)}
        okButtonProps={{ disabled: loadingScreen }}
        footer={
          <Row justify="end">
            <Space>
              <Button onClick={() => setVisibleModalView1(false)}>Đóng</Button>
              <ModalOptionsPrint
                isQRCodeBucket
                printWithInternet={_handleModalPrint}
                records={recordsPrint}
              >
                <Button type="primary">Bắt đầu in</Button>
              </ModalOptionsPrint>
            </Space>
          </Row>
        }
      >
        <Form layout="vertical" form={form}>
          <Form.Item label="Từ cột" name="from_column">
            <InputNumber
              onChange={_handleModalPrints}
              min={0}
              style={{ width: '100%', textAlign: 'center' }}
              placeholder="Tối thiểu"
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item label="Đến cột" name="to_column">
            <InputNumber
              onChange={_handleModalPrints}
              min={0}
              style={{ width: '100%', textAlign: 'center' }}
              placeholder="Tối đa"
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={visibleModalView2}
        width={500}
        title="In QR Code theo hàng"
        onCancel={() => {
          setVisibleModalView2(false)
          setRecordsPrint([])
        }}
        footer={
          <Row justify="end">
            <Space>
              <Button
                onClick={() => {
                  setVisibleModalView2(false)
                  setRecordsPrint([])
                }}
              >
                Đóng
              </Button>
              <ModalOptionsPrint
                isQRCodeBucket
                printWithInternet={_handleModalPrint}
                records={recordsPrint}
              >
                <Button type="primary">Bắt đầu in</Button>
              </ModalOptionsPrint>
            </Space>
          </Row>
        }
      >
        <div>
          <b style={{ fontWeight: 600, marginBottom: 10, fontSize: 18 }}>
            * Nhập số thứ tự của hàng muốn in ra, ví dụ nhập "1" để in hàng thứ 1, nhập "2" để in
            hàng thứ 2.
          </b>
          <InputNumber
            autoFocus
            onBlur={async (e) => {
              const value = e.target.value.replaceAll(',', '')
              if (value) {
                if (bucketsRender && bucketsRender.length) {
                  const records = bucketsRender[+value - 1]
                  if (records) {
                    setRecordsPrint(
                      records.map((record) => record.bucket).filter((record) => record)
                    )
                    await delay(300)
                  } else notification.warning({ message: 'Không tìm thấy hàng ô chứa hàng này' })
                }
              }
            }}
            min={0}
            style={{ width: '100%', textAlign: 'center' }}
            placeholder="Nhập số thứ tự hàng"
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          />
        </div>
      </Modal>
      <ModalViewProducts />
      <Row className="page-title" justify="space-between">
        <h3>Ô chứa hàng</h3>
        <Space>
          <Button
            icon={<PrinterOutlined />}
            type="primary"
            style={{ backgroundColor: 'green', borderColor: 'green' }}
            onClick={() => setVisibleModalView2(true)}
          >
            In QR Code theo hàng
          </Button>
          <Button
            icon={<PrinterOutlined />}
            type="primary"
            style={{ backgroundColor: 'green', borderColor: 'green' }}
            onClick={() => setVisibleModalView1(true)}
          >
            In QR Code theo cột
          </Button>
          <ModalOptionsPrint
            isQRCodeBucket
            printWithInternet={() => _printBarcode(buckets)}
            records={buckets}
          >
            <Button
              disabled={loadingScreen}
              // onClick={() => _printBarcode(buckets)}
              icon={<PrinterOutlined />}
              type="primary"
              style={{ backgroundColor: 'green', borderColor: 'green' }}
            >
              In toàn bộ QR Code ô kệ bên dưới
            </Button>
          </ModalOptionsPrint>
        </Space>
      </Row>
      <div>
        <Space size="middle" wrap={false} style={{ marginTop: 10 }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            Tìm kiếm theo tên ô chứa hàng
            <Select
              value=""
              style={{ width: 380 }}
              allowClear={false}
              showSearch
              suffixIcon={<SuffixIconCustom />}
              placeholder="Nhập tên ô chứa hàng"
              onSearch={setSearchValue}
              searchValue={searchValue}
              filterOption={false}
              onInputKeyDown={(e) => {
                if (e.key === 'Enter') _getBucketsSearch(searchValue)
              }}
              open={openBuckets}
              onBlur={() => setOpenBuckets(false)}
              onFocus={() => setOpenBuckets(true)}
            >
              {bucketsSearch.map((data, index) => (
                <Select.Option value={data.hotname} key={data.hotname + index + ''}>
                  <Row
                    align="middle"
                    wrap={false}
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  >
                    <div style={{ width: '100%', marginLeft: 15 }}>
                      <Row wrap={false} justify="space-between">
                        <span
                          style={{
                            maxWidth: 200,
                            marginBottom: 0,
                            fontWeight: 600,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: 'vertical',
                            display: '-webkit-box',
                          }}
                        >
                          {data.hotname}
                        </span>
                        <p
                          style={{ marginBottom: 0, fontWeight: 500, color: '#1890ff' }}
                          onClick={() => _getProductsByBucket(data.bucket_id)}
                        >
                          Xem danh sách sản phẩm
                        </p>
                      </Row>
                      <Row wrap={false} justify="space-between">
                        <p style={{ marginBottom: 0, color: 'gray' }}>
                          Số lượng: {formatCash(+data.product_quantity || 0)}
                        </p>
                        <ModalOptionsPrint
                          isQRCodeBucket
                          printWithInternet={async () => {
                            const params = {
                              warehouse_id: paramsFilter.warehouse_id,
                              name: PRINTER.O_KE,
                            }
                            const dataPrinter = await _getPrint(params)
                            printToThermalPrinter(
                              barcode,
                              {
                                barcode: data.code,
                                title: 'O ke: ' + data.hotname?.replace(/-{1,}/g, ' '),
                              },
                              dataPrinter && dataPrinter.ip
                            )
                          }}
                          records={[data]}
                        >
                          <PrinterOutlined
                            style={{ color: 'green', fontSize: 18, marginTop: 7, marginBottom: 3 }}
                          />
                        </ModalOptionsPrint>
                      </Row>
                    </div>
                  </Row>
                </Select.Option>
              ))}
            </Select>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            Lọc theo kho
            <Select
              value={paramsFilter.warehouse_id || undefined}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Chọn kho"
              style={{ width: 260 }}
              onChange={async (value) => {
                const findWarehouse = warehouses.find((e) => e.warehouse_id === value)
                if (findWarehouse) {
                  const result = await _checkIsEmptyShelveInWarehouse({
                    warehouse_name: findWarehouse.warehouse_name,
                  })
                  if (!result) return

                  _onFilters('warehouse_id', value)
                  _getShelves({ warehouse_id: findWarehouse.warehouse_id })
                }
              }}
            >
              {warehouses.map((warehouse, index) => (
                <Select.Option value={warehouse.warehouse_id} key={index}>
                  {warehouse.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            Lọc theo kệ hàng
            <Select
              notFoundContent={loadingShelve ? <Spin /> : null}
              value={paramsFilter.shelve_id}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Chọn kệ hàng"
              style={{ width: 200 }}
              onChange={(value) => {
                _onFilters('shelve_id', value)
                const shelve = shelves.find((e) => e.shelve_id === value)
                if (shelve) {
                  setMaxColumn(shelve.max_column)
                  setMaxRow(shelve.max_row)
                }
              }}
            >
              {shelves.map((shelve, index) => (
                <Select.Option value={shelve.shelve_id} key={index}>
                  {shelve.hotname}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div>
            <div>Lọc theo số cột</div>
            <Input.Group compact style={{ display: 'flex' }}>
              <InputNumber
                value={fromColumn}
                onChange={(value) => {
                  setFromColumn(value)
                  _filterByColumns(value, 'from_column')
                }}
                min={0}
                style={{ width: 100, textAlign: 'center' }}
                placeholder="Tối thiểu"
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
              <Input
                style={{ width: 30, borderLeft: 0, borderRight: 0, pointerEvents: 'none' }}
                placeholder="~"
                disabled
              />
              <InputNumber
                value={toColumn}
                onChange={(value) => {
                  setToColumn(value)
                  _filterByColumns(value, 'to_column')
                }}
                min={0}
                style={{ width: 100, textAlign: 'center' }}
                placeholder="Tối đa"
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Input.Group>
          </div>
        </Space>
        <div style={{ marginTop: 15 }}>
          <Row wrap={false} justify="space-between" align="middle" style={{ marginBottom: 7 }}>
            <Row wrap={false}>
              <Row wrap={false} style={{ color: 'orange', marginRight: 20 }}>
                Số hàng tối đa:
                <div style={{ marginLeft: 5, fontWeight: 700 }}>{maxRow}</div>
              </Row>
              <Row wrap={false} style={{ color: 'green', marginRight: 20 }}>
                Số cột tối đa:
                <div style={{ marginLeft: 5, fontWeight: 700 }}>{maxColumn}</div>
              </Row>
              <Row wrap={false}>
                Thể tích mỗi ô chứa hàng:
                <div style={{ marginLeft: 5, fontWeight: 700 }}>{formatCash(maxSpace || 0)}</div>
              </Row>
            </Row>
            <Space>
              <Button onClick={_getBuckets} type="primary" icon={<ReloadOutlined />}>
                Làm mới
              </Button>
              <ModalUpdateSpace />
            </Space>
          </Row>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          boxShadow: '0px 0px 4px #13D8E5',
          borderRadius: '5px',
          padding: 10,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        }}
      >
        {_renderBuckets}
      </div>
    </div>
  )
}
