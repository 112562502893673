import React, { useState, useEffect, useRef } from 'react'
import styles from './reportInventory.module.scss'
import { useHistory, useLocation } from 'react-router-dom'
import { SIZE_TABLE, POSITION_TABLE, PAGE_SIZE_OPTIONS, ROUTES } from 'consts'
import { filterOptionSelect, formatCash, getFileName, _addLog } from 'utils'
import moment from 'moment'
import { saveAs } from 'file-saver'

// antd
import { Col, Input, Row, Select, Table, Space, Button } from 'antd'

//icons
import { ArrowLeftOutlined, VerticalAlignTopOutlined } from '@ant-design/icons'

//components
import SuffixIconCustom from 'components/suffixIconCustom'
import SettingColumns from 'components/setting-columns'
import reportInventoryColumns from './columns'
import FilterDate from 'components/filter-date'

//apis
import { getReportInventoryDetail, exportReportInventoryDetail } from 'apis/report'
import { getCategories } from 'apis/categories-product'

export default function ReportInventoryDetail() {
  const location = useLocation()
  const typingTimeoutRef = useRef()
  const history = useHistory()
  const queries = new URLSearchParams(location.search)
  const productId = queries.get('product_id')
  const barcode = queries.get('barcode')

  const [valueSearch, setValueSearch] = useState('')
  const [columns, setColumns] = useState([])
  const [dataInventory, setDataInventory] = useState([])
  const [loading, setLoading] = useState(false)
  const [paramsFilter, setParamsFilter] = useState({
    page: 1,
    page_size: 20,
    product_id: productId,
  })
  const [inventoryCount, setInventoryCount] = useState(0)
  const [groups, setGroups] = useState([])
  const [types, setTypes] = useState([])

  const _onSearch = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value
      if (value) paramsFilter.fullname = value
      else delete paramsFilter.fullname
      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 650)
  }

  const _getReportInventoryDetailExport = async (getAll = false) => {
    try {
      const query = { ...paramsFilter }
      if (getAll) {
        const keysQueryTime = [
          'today',
          'yesterday',
          'this_week',
          'last_week',
          'last_month',
          'this_month',
          'this_year',
          'last_year',
          'from_date',
          'to_date',
        ]
        Object.keys(query).map((key) => !keysQueryTime.includes(key) && delete query[key])
      }

      delete query.page
      delete query.page_size
      setLoading(true)
      const res = await exportReportInventoryDetail({ ...query, product_id: productId })
      setLoading(false)
      if (res.status === 200) {
        const link = res.data.data
        if (link) saveAs(link, getFileName(link))
      }
    } catch (error) {
      console.log(error)
      _addLog(JSON.stringify(error))
      setLoading(false)
    }
  }

  const _getReportInventoryDetail = async () => {
    try {
      setLoading(true)
      const res = await getReportInventoryDetail(paramsFilter)
      if (res.status === 200) {
        setInventoryCount(res.data.count)
        setDataInventory(res.data.data)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
      _addLog(JSON.stringify(error))
    }
  }

  const _filterByOption = (attribute = '', value = '') => {
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter, page: 1 })
  }

  const _getGroups = async () => {
    try {
      const res = await getCategories({ group: true, detach: true })
      if (res.status === 200) setGroups(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }
  const _getTypes = async () => {
    try {
      const res = await getCategories({ type: true, detach: true })
      if (res.status === 200) setTypes(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))
      console.log(error)
    }
  }

  useEffect(() => {
    const keys = localStorage.getItem('reportInventoryFilters')
    const filters = keys && JSON.parse(keys)
    if (filters) {
      const keysQueryTime = [
        'today',
        'yesterday',
        'this_week',
        'last_week',
        'last_month',
        'this_month',
        'this_year',
        'last_year',
        'from_date',
        'to_date',
      ]
      Object.keys(filters).map((key) => !keysQueryTime.includes(key) && delete filters[key])

      setParamsFilter({ ...paramsFilter, ...filters })
    }
  }, [])

  useEffect(() => {
    _getGroups()
    _getTypes()
  }, [])

  useEffect(() => {
    if (productId) _getReportInventoryDetail()
    else history.push(ROUTES.REPORT_INVENTORY)
  }, [paramsFilter])

  return (
    <div className={styles['reportInventory']}>
      <Row className="page-title" justify="space-between" style={{ marginBottom: '25px' }}>
        <Row
          onClick={() => history.push(ROUTES.REPORT_INVENTORY)}
          wrap={false}
          align="middle"
          style={{ fontSize: 17, cursor: 'pointer' }}
        >
          <ArrowLeftOutlined />
          <p style={{ marginBottom: 0, marginLeft: 9 }}>
            Báo cáo xuất/nhập tồn chi tiết <b>#{barcode}</b>
          </p>
        </Row>
        <Space>
          <Button
            onClick={() => _getReportInventoryDetailExport()}
            icon={<VerticalAlignTopOutlined />}
            type="primary"
            className="btn-export"
          >
            Xuất theo bộ lọc
          </Button>
          <Button
            onClick={() => _getReportInventoryDetailExport(true)}
            icon={<VerticalAlignTopOutlined />}
            type="primary"
            className="btn-export"
          >
            Xuất toàn bộ
          </Button>
          <SettingColumns
            columns={columns}
            columnsRender={reportInventoryColumns}
            setColumns={setColumns}
            nameColumn="columnsReportInventoryDetail"
          />
        </Space>
      </Row>
      <div className={styles['reportInventory-content']}>
        <Row gutter={[15, 15]} style={{ marginBottom: 20 }}>
          <Col span={8}>
            <Input.Search
              value={valueSearch}
              onChange={_onSearch}
              allowClear
              placeholder="Tìm kiếm theo tên khách hàng"
            />
          </Col>
          <Col span={6}>
            <FilterDate
              allowClear={false}
              paramsFilter={paramsFilter}
              setParamsFilter={setParamsFilter}
            />
          </Col>
          <Col span={5}>
            <Select
              suffixIcon={<SuffixIconCustom />}
              placeholder="Chọn nhóm hàng"
              style={{ width: '100%' }}
              allowClear
              showSearch
              filterOption={filterOptionSelect}
              value={paramsFilter['group_id'] || undefined}
              onChange={(value) => _filterByOption('group_id', value)}
            >
              {groups.map((group, index) => (
                <Select.Option value={group.category_id} key={index}>
                  {group.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={5}>
            <Select
              value={paramsFilter['type_id'] || undefined}
              onChange={(value) => _filterByOption('type_id', value)}
              suffixIcon={<SuffixIconCustom />}
              style={{ width: '100%' }}
              placeholder="Chọn loại hàng"
              allowClear
              showSearch
              filterOption={filterOptionSelect}
            >
              {types.map((type, index) => (
                <Select.Option value={type.category_id} key={index}>
                  {type.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      </div>
      <Table
        style={{ width: '100%' }}
        scroll={{ x: 1500, y: '67vh' }}
        loading={loading}
        size={SIZE_TABLE}
        dataSource={dataInventory}
        columns={columns.map((column) => {
          if (column.key === 'stt')
            return {
              ...column,
              render: (text, record, index) =>
                (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
            }
          if (column.key === 'customer')
            return {
              ...column,
              render: (text, record) => {
                const order_info = record.import_order_info || record.export_order_info
                return order_info && order_info.customer_info && order_info.customer_info.fullname
              },
            }
          if (column.key === 'type')
            return {
              ...column,
              render: (text, record) =>
                (record.import_order_info && 'Phiếu nhập') ||
                (record.export_order_info && 'Phiếu xuất') ||
                '',
            }

          if (column.key === 'address')
            return {
              ...column,
              render: (text, record) => {
                const order_info = record.import_order_info || record.export_order_info
                const customer = order_info && order_info.customer_info
                return customer
                  ? `${customer.address}, ${customer.ward}, ${customer.district}, ${customer.province}`
                  : ''
              },
            }

          if (column.key === 'date')
            return {
              ...column,
              render: (text, record) =>
                record.import_date && moment(record.import_date).format('DD/MM/YYYY'),
            }

          if (column.key === 'date-ct')
            return {
              ...column,
              render: (text, record) =>
                record.create_date && moment(record.create_date).format('DD/MM/YYYY'),
            }

          if (column.key === 'code')
            return {
              ...column,
              render: (text, record) => {
                const order_info = record.import_order_info || record.export_order_info
                return order_info && order_info.customer_order_code
              },
            }

          if (column.key === 'code-ct')
            return {
              ...column,
              render: (text, record) => {
                const order_info = record.import_order_info || record.export_order_info
                return order_info && order_info.code
              },
            }

          if (column.key === 'import-quantity')
            return {
              ...column,
              render: (text, record) => formatCash(record.import_quantity || 0),
            }

          if (column.key === 'export-quantity')
            return {
              ...column,
              render: (text, record) => formatCash(record.export_quantity || 0),
            }

          if (column.key === 'inventory-quantity')
            return {
              ...column,
              render: (text, record) => formatCash(record.inventory_quantity || 0),
            }

          if (column.key === 'note')
            return {
              ...column,
              render: (text, record) => {
                const order_info = record.import_order_info || record.export_order_info
                return order_info && order_info.note
              },
            }

          return column
        })}
        pagination={{
          position: POSITION_TABLE,
          current: paramsFilter.page,
          pageSize: paramsFilter.page_size,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          total: inventoryCount,
          showQuickJumper: true,
          onChange: (page, pageSize) => {
            setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize })
          },
        }}
        summary={() => (
          <Table.Summary fixed="bottom">
            <Table.Summary.Row></Table.Summary.Row>
          </Table.Summary>
        )}
        sticky
      />
    </div>
  )
}
