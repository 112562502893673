import { get, post, destroy, patch } from './axiosClient'

export const getFeeInOrder = (query) => get('/fee-in-order', query)
export const getStorageFeesDefault = (query) => get('/fee-in-order/storage-fee-default', query)
export const getExportOrderFeeDefault = (query) =>
  get('/fee-in-order/export-order-fee-default', query)
export const getImportOrderFeeDefault = (query) =>
  get('/fee-in-order/import-order-fee-default', query)
export const createFeeInOrder = (body) =>
  post('/fee-in-order/create', { ...body, from_platform: 'SGL' })
export const updateFeeInOrder = (id, body) =>
  patch('/fee-in-order/update/' + id, { ...body, from_platform: 'SGL' })
export const deleteFeeInOrder = (body) =>
  destroy('/fee-in-order/delete', { ...body, from_platform: 'SGL' })
