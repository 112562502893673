const initialState = {
  districts: [],
  wards: [],
  provinces: [],
}

var addressReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DISTRICTS': {
      const districtsNew = [...action.data]
      return { ...state, districts: districtsNew }
    }
    case 'SET_PROVINCES': {
      const provincesNew = [...action.data]
      return { ...state, provinces: provincesNew }
    }
    case 'SET_WARDS': {
      const wardsNew = [...action.data]
      return { ...state, wards: wardsNew }
    }

    default:
      return state
  }
}
export default addressReducer
