import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import styles from './export-product.module.scss'
import { IMAGE_DEFAULT, PAGE_SIZE_OPTIONS, PERMISSIONS, ROUTES } from 'consts'
import { saveFiltersToLocalStorage, _addLog } from 'utils'
import { useHistory } from 'react-router-dom'

//components
import SuffixIconCustom from 'components/suffixIconCustom'
import FilterDate from 'components/filter-date'
import ZoomImage from 'components/zoom-image'
import Permission from 'components/permission'
import ModalConfirmDelete from 'components/modal-confirm-delete'
import QrCodeProduct from 'components/qr-code-product'

// antd
import {
  Col,
  Row,
  Space,
  Input,
  Select,
  Button,
  DatePicker,
  Table,
  Collapse,
  Tag,
  notification,
  Popconfirm,
  Modal,
  Upload,
} from 'antd'
import ImportFile from 'components/import-csv'

//icons
import { DeleteOutlined, ReloadOutlined } from '@ant-design/icons'

// apis
import {
  deleteExportOrders,
  getExportOrders,
  readyToShipLazada,
  createGroupExport,
  fixDoubleOrderExport,
} from 'apis/export-order'
import { getAllCustomer } from 'apis/customer'
import { getWarehouses } from 'apis/warehouse'
import { findBucket, exportOrderProcessing, importFilePDfEcommerce } from 'apis/export-order'

export default function ExportProduct() {
  const [exportOrdersList, setExportOrdersList] = useState([])
  const KEY_FILTER = 'productExportListFilters'
  const filterList =
    localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))
  const history = useHistory()
  const [customers, setCustomers] = useState([])
  const [valueSearch, setValueSearch] = useState(filterList ? filterList.search || '' : '')
  const [bucketsList, setBucketsList] = useState({})
  const [exportOrdersChecked, setExportOrdersChecked] = useState([])
  const [listPDF, setListPDF] = useState([])
  const [visiblePDF, setVisiblePDF] = useState(false)
  const [warehouses, setWarehouses] = useState([])

  const [filters, setFilters] = useState(
    filterList ? { ...filterList } : { is_exported: true, page: 1, page_size: 20, sort_time: null }
  )
  const [countOrder, setCountOrder] = useState(0)
  const [loading, setLoading] = useState(false)
  const typingTimeoutRef = useRef(null)
  const [loadingExportProcessing, setLoadingExportProcessing] = useState(false)

  const [visibleResult, setVisibleResult] = useState(false)
  const [countImportSuccess, setCountImportSuccess] = useState(0)
  const [errorsImport, setErrorsImport] = useState([])

  const indicators = [
    {
      title: 'Chưa xuất vị trí',
      bgColor: '#FFCE62',
      order_status: 'WAITING',
    },
    {
      title: 'Đang thực hiện',
      bgColor: '#80E9EF',
      order_status: 'PROCESSING',
    },
    {
      title: 'Đã xuất vị trí',
      bgColor: '#79CF4F',
      order_status: 'COMPLETE',
    },
    {
      title: 'Có sự cố',
      bgColor: '#D63F40',
      order_status: 'REPORT',
    },
  ]

  const _getBucketProduct = async (order_ids) => {
    try {
      const newID = order_ids[order_ids.length - 1]
      if (newID && !bucketsList[newID]) {
        const res = await findBucket({ order_id: newID })
        if (res.data.success) {
          let tmp = { ...bucketsList, [newID]: res.data.data }
          setBucketsList(tmp)
        }
      }

      setExportOrdersChecked(order_ids)
    } catch (err) {
      _addLog(JSON.stringify(err))
      console.log(err)
    }
  }

  const _getCustomers = async () => {
    try {
      const res = await getAllCustomer()
      if (res.status === 200) setCustomers(res.data.data)
    } catch (error) {
      _addLog(JSON.stringify(error))

      console.log(error)
    }
  }

  const _onReadyToShip = async (order_id) => {
    try {
      const res = await readyToShipLazada({ order_id: order_id })
      if (res.status === 200) {
        notification.success({ message: 'Gọi sẵn sàng giao thành công' })
      } else {
        notification.error({ message: res.data?.message ?? 'Gọi sẵn sàng giao thất bại' })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _getWarehouses = async () => {
    try {
      const res = await getWarehouses()
      if (res.status === 200) setWarehouses(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const _getExportOrders = async () => {
    try {
      setLoading(true)
      const res = await getExportOrders(filters)
      console.log(res)
      if (res.status === 200) {
        setCountOrder(res.data.count)
        setExportOrdersList(res.data.data)
      }
      setLoading(false)
    } catch (err) {
      _addLog(JSON.stringify(err))
      console.log(err)
      setLoading(false)
    }
  }

  const _deleteExport = async (id, note = '') => {
    try {
      const res = await deleteExportOrders({ order_id: [id], note: note })
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Xóa đơn hàng thành công' })
          _getExportOrders()
        } else notification.error({ message: res.data.message || 'Xóa đơn hàng không thành công' })
      } else notification.error({ message: res.data.message || 'Xóa đơn hàng không thành công' })
    } catch (err) {
      console.log(err)
    }
  }

  const _search = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value
      if (value) filters.search = value
      else delete filters.search
      setFilters({ ...filters, page: 1 })
    }, 650)
  }

  const onSubmitPDF = async () => {
    setLoading(true)
    var dataForm = new FormData()
    listPDF.map((info) => {
      dataForm.append('files', info.originFileObj)
    })

    var response = await importFilePDfEcommerce(dataForm)
    setVisiblePDF(false)
    setLoading(false)
    if (response && response.status == 200) {
      notification.success({ message: 'Import thành công, vui lòng kiểm tra kết quả' })
      if (response.data.count_success) setCountImportSuccess(response.data.count_success)
      if (response.data.errors) setErrorsImport(response.data.errors)
      setVisibleResult(true)
    }
  }

  const columns = [
    {
      key: 1,
      render(data, record) {
        return (
          <Row>
            <Col>
              {record.code && <Row>#{record.code}</Row>}
              <Row>Số phiếu: #{record.customer_order_code}</Row>
              {record.shipping_info && (
                <div>
                  <div>Mã vận đơn: {record?.shipping_info?.shipping_code}</div>
                  <div>ĐVVC: {record?.shipping_info?.shipping_company_name}</div>
                  {record.shipping_info.car_number && (
                    <div style={{ fontSize: 16.5, fontWeight: 700 }}>
                      Biển số xe: {record.shipping_info.car_number}
                    </div>
                  )}
                </div>
              )}
              <div>Nguồn: {record.platform}</div>
            </Col>
          </Row>
        )
      },
    },
    {
      key: 2,
      render(data, record) {
        return (
          <Row>
            <Col>
              <Row>{record.warehouse_info && record.warehouse_info.name}</Row>
              <Row>Doanh nghiệp: {record.customer_info && record.customer_info.fullname}</Row>
              {record.export_status != 'WAITING' && (
                <Row>Lấy hàng: {record.user_export_info && record.user_export_info.fullname}</Row>
              )}
              <Space>
                <Permission permissions={[PERMISSIONS.them_chi_phi_vao_phieu_xuat]}>
                  <Button
                    onClick={() =>
                      history.push(
                        `${ROUTES.ADD_FEE_TO_ORDER}?order_id=${record.order_id}&type=export`
                      )
                    }
                    type="primary"
                  >
                    Thêm chi phí
                  </Button>
                </Permission>
                {record.platform == 'Lazada' && (
                  <Button
                    onClick={() => {
                      _onReadyToShip(record.order_id)
                    }}
                    type="primary"
                    style={{ backgroundColor: 'orange', borderColor: 'orange' }}
                  >
                    Sẵn sàn giao
                  </Button>
                )}

                <Permission permissions={[PERMISSIONS.xoa_don_hang_xuat]}>
                  <ModalConfirmDelete
                    record={record}
                    onDelete={(note) => _deleteExport(record.order_id, note)}
                  >
                    <Button type="primary" icon={<DeleteOutlined />} danger />
                  </ModalConfirmDelete>
                </Permission>
              </Space>
            </Col>
          </Row>
        )
      },
    },
    {
      key: 3,
      render(data, record) {
        if (data.export_status !== 'COMPLETE')
          return (
            <div>
              <div style={{ marginTop: 5 }}>
                <div style={{ display: data.basket_info && !data.basket_info.length && 'none' }}>
                  Danh sách thùng đựng
                </div>
                <Row>
                  {data.basket_info &&
                    data.basket_info.map((e) => (
                      <Tag color="#FF6731" style={{ padding: '4px 15px' }}>
                        {e.code}
                      </Tag>
                    ))}
                </Row>
              </div>
            </div>
          )
        else return ''
      },
    },
    {
      width: 120,
      key: 3,
      render: (data, record) => (
        <Row>
          <QrCodeProduct value={record.code} />
        </Row>
      ),
    },
  ]

  const _filterByOption = (attribute = '', value = '') => {
    filters.page = 1

    if (value) filters[attribute] = value
    else delete filters[attribute]

    setFilters({ ...filters })
  }

  const onDownloadExportProcessing = async () => {
    try {
      setLoadingExportProcessing(true)
      var response = await exportOrderProcessing()
      setLoadingExportProcessing(false)
      if (response && response.status == 200) {
        window.open(response.data.data)
      } else {
        notification.error({ message: 'Có lỗi xảy ra, vui lòng thử lại' })
      }
    } catch (e) {
      setLoadingExportProcessing(false)
      notification.error({ message: 'Có lỗi xảy ra, vui lòng thử lại' })
    }
  }

  const onCreateGroup = async () => {
    try {
      var responseFix = await fixDoubleOrderExport()
      var response = await createGroupExport()
      if (response && response.status == 200) {
        notification.success({ message: 'Tạo nhóm PO thành công, vui lòng xem nhóm trên PDA' })
      } else {
        notification.error({ message: 'Có lỗi xảy ra, vui lòng thử lại' })
      }
    } catch (e) {
      notification.error({ message: 'Có lỗi xảy ra, vui lòng thử lại' })
    }
  }

  useEffect(() => {
    _getCustomers()
    _getWarehouses()
  }, [])

  useEffect(() => {
    _getExportOrders()
    saveFiltersToLocalStorage(filters, KEY_FILTER)
  }, [filters])

  return (
    <div className={styles['importProduct']}>
      <Modal
        onOk={(e) => {
          onSubmitPDF()
        }}
        confirmLoading={loading}
        onCancel={(e) => {
          setVisiblePDF(false)
        }}
        visible={visiblePDF}
      >
        <Upload
          fileList={listPDF}
          onChange={(e) => {
            console.log(e.fileList)
            setListPDF(e.fileList)
          }}
          multiple={true}
        >
          <Button>Upload PDF cho Phiếu Xuất</Button>
        </Upload>
      </Modal>
      <Row className="page-title" justify="space-between" style={{ marginBottom: 10 }}>
        <h3>Danh sách phiếu xuất</h3>

        <Modal
          visible={visibleResult}
          onCancel={(e) => {
            setVisibleResult(false)
          }}
        >
          <h3>{'Kết quả Import PDF'}</h3>
          <Row>
            <span
              style={{ color: 'green', fontWeight: 'bold' }}
            >{`Số lượng PDF Import thành công: ${countImportSuccess}`}</span>
          </Row>

          <Row>
            <span
              style={{ color: 'red', fontWeight: 'bold' }}
            >{`Danh sách PDF không tìm thấy phiếu trong hệ thống: ${errorsImport.length}`}</span>
          </Row>
          <Col>
            {errorsImport.map((link) => {
              return (
                <Row>
                  <a href={link}>{link}</a>
                </Row>
              )
            })}
          </Col>
        </Modal>
        <Space>
          <Button
            onClick={(e) => {
              setVisiblePDF(true)
            }}
          >
            Upload PDF cho Phiếu Xuất
          </Button>
          {/* <ImportFile
            multiple={true}
            upload={onImportFilePDF}
            // reload={_getProducts}
            showNote={false}
            title="Nhập PDF cho Phiếu Xuất"
            txt="Nhập PDF cho Phiếu Xuất"
            action_name={'Nhập danh sách file PDF'}
          /> */}
          <Button
            disabled={loadingExportProcessing}
            loading={loadingExportProcessing}
            onClick={(e) => {
              onDownloadExportProcessing()
            }}
            type="primary"
            color={'red'}
            icon={<ReloadOutlined />}
          >
            Xuất Excel theo bộ lọc
          </Button>
          <Popconfirm
            onConfirm={(e) => {
              onCreateGroup()
            }}
            title={'Xác nhận tạo nhóm PO?'}
          >
            <Button type="primary" color={'red'} icon={<ReloadOutlined />}>
              Tạo nhóm PO
            </Button>
          </Popconfirm>

          <Button onClick={_getExportOrders} type="primary" icon={<ReloadOutlined />}>
            Làm mới
          </Button>
        </Space>
      </Row>

      <div className={styles['importProduct-content']}>
        <Row style={{ marginBottom: 20 }} gutter={[20, 10]}>
          <Col span={7}>
            <Input.Search
              allowClear
              enterButton
              value={valueSearch}
              placeholder="Tìm kiếm theo mã đơn hàng"
              onChange={_search}
            />
          </Col>
          <Col span={7}>
            <Select
              allowClear
              style={{ width: '100%' }}
              placeholder="Lọc theo doanh nghiệp"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              suffixIcon={<SuffixIconCustom />}
              onChange={(value) => _filterByOption('customer_id', value)}
              value={filters['customer_id'] || undefined}
            >
              {customers.map((e) => (
                <Select.Option value={e.customer_id}>{e.fullname}</Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={5}>
            <Select
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
              }
              style={{ width: '100%' }}
              placeholder="Lọc theo trạng thái"
              suffixIcon={<SuffixIconCustom />}
              onChange={(value) => _filterByOption('export_status', value)}
              value={filters['export_status'] || undefined}
            >
              <Select.Option value="WAITING">Chưa xuất vị trí</Select.Option>
              <Select.Option value="PROCESSING">Đang thực hiện</Select.Option>
              <Select.Option value="COMPLETE">Đã xuất vị trí</Select.Option>
              <Select.Option value="REPORT">Có sự cố</Select.Option>
            </Select>
          </Col>
          <Col span={5}>
            <FilterDate paramsFilter={filters} setParamsFilter={setFilters} />
          </Col>
          <Col span={5}>
            <Select
              value={filters['warehouse_id'] || undefined}
              onChange={(value) => _filterByOption('warehouse_id', value)}
              showSearch
              allowClear
              placeholder="Lọc theo kho"
              style={{ width: '100%' }}
              dropdownClassName="dropdown-select-custom"
              suffixIcon={<SuffixIconCustom />}
            >
              {warehouses.map((warehouse, index) => (
                <Select.Option key={index} value={warehouse.warehouse_id}>
                  {warehouse.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={7}>
            <Select
              suffixIcon={<SuffixIconCustom />}
              style={{ width: '100%' }}
              value={filters['sort_time'] || null}
              onChange={(value) => _filterByOption('sort_time', value)}
            >
              <Select.Option value={null}>Tất cả</Select.Option>
              <Select.Option value="exported">Sắp xếp theo thời gian hoàn thành xuất</Select.Option>
              <Select.Option value="packed">
                Sắp xếp theo thời gian hoàn thành đóng gói
              </Select.Option>
              <Select.Option value="transported">
                Sắp xếp theo thời gian hoàn thành bàn giao
              </Select.Option>
            </Select>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col>
            <div style={{ display: 'flex', gap: '10px', marginLeft: '10px', alignItems: 'center' }}>
              {indicators.map((indicator, index) => {
                return (
                  <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <div
                      style={{
                        width: '15px',
                        height: '15px',
                        backgroundColor: indicator.bgColor,
                        borderRadius: '50%',
                      }}
                    ></div>
                    <div>{indicator.title}</div>
                  </div>
                )
              })}
            </div>
          </Col>
        </Row>
      </div>
      <Table
        rowKey="order_id"
        size="small"
        showHeader={false}
        columns={columns}
        loading={loading}
        scroll={{ y: '70vh' }}
        rowClassName={(record) => styles[record.export_status]}
        dataSource={exportOrdersList}
        rowSelection={{
          selectedRowKeys: exportOrdersChecked,
          onChange: _getBucketProduct,
        }}
        expandable={{
          expandedRowRender: (record) => (
            <>
              {bucketsList[record.order_id] && bucketsList[record.order_id].bucket_map.length ? (
                <b style={{ fontSize: 16 }}>Danh sách ô kệ xuất hàng</b>
              ) : (
                ''
              )}
              <Collapse
                defaultActiveKey={Array.from(
                  Array(
                    bucketsList[record.order_id]
                      ? bucketsList[record.order_id].bucket_map.length
                      : 0
                  ).keys()
                )}
                style={{ marginBottom: 10 }}
              >
                {bucketsList[record.order_id] &&
                  bucketsList[record.order_id]?.bucket_map?.map((e, index) => (
                    <Collapse.Panel key={index} header={`Ô kệ: ${e.hotname}`}>
                      <Table
                        size="small"
                        columns={[
                          {
                            title: 'STT',
                            align: 'center',
                            width: 50,
                            render(data, record, index) {
                              return index + 1
                            },
                          },
                          {
                            title: 'Mã sản phẩm',
                            render: (text, record) =>
                              record?.barcode ? record?.barcode : record?.code,
                          },
                          {
                            title: 'Tên sản phẩm',
                            dataIndex: 'name',
                          },
                          {
                            title: 'Hình ảnh',
                            dataIndex: 'images',
                            render(data) {
                              return (
                                <ZoomImage
                                  image={(data && data[0] && IMAGE_DEFAULT) || IMAGE_DEFAULT}
                                >
                                  <img
                                    alt=""
                                    src={(data && data[0] && IMAGE_DEFAULT) || IMAGE_DEFAULT}
                                    width="50"
                                    height="50"
                                  />
                                </ZoomImage>
                              )
                            },
                          },
                          {
                            title: 'Số lượng cần xuất',
                            dataIndex: 'require_export_quantity',
                            align: 'center',
                          },
                          {
                            title: 'Số lượng đã xuất',
                            dataIndex: 'export_quantity',
                            align: 'center',
                          },
                        ]}
                        dataSource={e.products}
                        pagination={false}
                      />
                    </Collapse.Panel>
                  ))}
              </Collapse>
            </>
          ),
          expandIconColumnIndex: -1,
          expandedRowKeys: exportOrdersChecked,
        }}
        pagination={{
          current: filters.page,
          pageSize: filters.page_size,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          showQuickJumper: true,
          onChange: (page, pageSize) => {
            setFilters({ ...filters, page: page, page_size: pageSize })
          },
          total: countOrder,
        }}
      />
    </div>
  )
}
