import React, { useEffect, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import { formatCash, _addLog } from 'utils'
import KeyboardEventHandler from 'react-keyboard-event-handler'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import delay from 'delay'
import moment from 'moment-timezone'
import printJS from 'print-js'

//icons
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  ReloadOutlined,
  PrinterOutlined,
} from '@ant-design/icons'

//antd
import {
  Row,
  Input,
  Table,
  Button,
  Form,
  Col,
  Space,
  notification,
  Modal,
  List,
  Collapse,
  Popconfirm,
  Affix,
  message,
  Spin,
  InputNumber,
} from 'antd'
import { ACTION, IMAGE_DEFAULT, ROUTES } from 'consts'

// apis
import {
  updateExportOrders,
  getExportOrders,
  getTrackingNumber,
  cancelBaskets,
} from 'apis/export-order'
import { getExpense } from 'apis/expense'

//component
import styles from './packageScan.module.scss'
import { ComponentPrintBill } from '../package/ButtonPrintPackage'
import QrCodeProduct from 'components/qr-code-product'

export default function PackageScan() {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const inputRef = useRef(null)
  const printRef = useRef()
  const scanRef = useRef()
  const inputCodeRef = useRef()
  const [formScan] = Form.useForm()
  const typingTimeoutRef = useRef()
  const scanTimeoutRef = useRef()

  const ID_DIV_PRINT = 'print-tracking-number'
  const INVALID_INPUT_NUMBER = ['e', 'E', '+', '-']
  const [listExpense, setListExpense] = useState([])

  const [visibleConfirm, setVisibleConfirm] = useState(false)
  const toggleVisibleConfirm = () => setVisibleConfirm(!visibleConfirm)
  const [disableBtnConfirm, setDisableBtnConfirm] = useState(true)
  const [indexExpense, setIndexExpense] = useState(0)
  const [InfoOrder, setInfoOrder] = useState({})
  const [packages, setPackages] = useState([])
  const [products, setProducts] = useState([])
  const [productsNotEnoughCondition, setProductsNotEnoughCondition] = useState([])
  const [productsScan, setProductsScan] = useState([]) // ds sản phẩm đã đóng gói
  const [productFocus, setProductFocus] = useState(null)
  const [packageFocus, setPackageFocus] = useState(null)
  const [reportTransfer, setTransferOrder] = useState({})
  const [invoiceOrder, setInvoiceOrder] = useState({})
  const [packageScan, setPackageScan] = useState(null)
  const [valueScan, setValueScan] = useState('')
  const [pkgFind, setPkgFind] = useState(null)
  const [productsOfPackage, setProductsOfPackage] = useState('')
  const [packagesPrint, setPackagesPrint] = useState([])

  const _print = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => {
      setPackagesPrint([])
      if (packages.length) setPackageScan(packages[0])
      if (scanRef && scanRef.current) scanRef.current.focus()
    },
  })

  const getPackageCode = (code = '') => {
    const pkgCodes = code.split('-')
    const pkgCode = pkgCodes[pkgCodes.length - 1]
    return pkgCode || ''
  }

  const _updateOrder = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })

      let res

      //nếu đơn hàng status là progressing thì back về cập nhật status cho order đó là waiting
      if (location.state.order.pack_status === 'PROCESSING') {
        const body = { pack_status: 'WAITING', processing: 'is_packed', packages: packages }
        res = await updateExportOrders(location.state.order.order_id, body)
      } else {
        const body = { packages: packages }
        res = await updateExportOrders(location.state.order.order_id, body)
      }

      if (res.status === 200) {
        if (res.data.success) notification.success({ message: 'Cập nhật đơn hàng thành công' })
      } else notification.error({ message: res.data.message || 'Cập nhật đơn hàng thất bại!' })

      history.push(ROUTES.PACKAGE)
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      _addLog(`Update order: ${JSON.stringify(error)}`)
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const _reloadOrder = async () => {
    try {
      dispatch({ type: 'LOADING', data: true })
      const res = await getExportOrders({
        order_id: location.state.order.order_id,
        is_packed: true,
      })
      dispatch({ type: 'LOADING', data: false })
      if (res.status === 200) {
        const order = res.data.data && res.data.data.length && res.data.data[0]
        if (order) {
          notification.success({ message: 'Làm mới đơn hàng thành công', duration: 2 })
          if (packages.length) setPackageScan(packages[0])

          setProducts(order.products || [])
          setInfoOrder(order)

          const packagesNew = packages.map((pkg) => ({
            ...pkg,
            products: pkg.products.map((product) => {
              const productFind = order.products.find((p) => p.product_id === product.product_id)
              if (productFind) return { ...product, export_quantity: productFind.export_quantity }
              return { ...product }
            }),
          }))

          setPackages([...packagesNew])

          await delay(200)
          if (scanRef && scanRef.current) scanRef.current.focus()
        }
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: 'LOADING', data: false })
    }
  }

  const printTrackingNumberByHtml = (link = '') => {
    const printEl = document.getElementById(ID_DIV_PRINT)
    if (printEl) {
      var iframeEl = document.createElement('iframe')
      iframeEl.src = link
      iframeEl.frameBorder = 0
      iframeEl.style.minHeight = '100vh'
      iframeEl.style.width = '100%'
      iframeEl.style.borderColor = 'none'
      iframeEl.style.outline = 'none'
      iframeEl.style.boxShadow = 'none'
      printEl.appendChild(iframeEl)
      printJS(ID_DIV_PRINT, 'html')
      iframeEl.remove()
    }
  }

  const _getTrackingNumber = async (isCallback = false) => {
    const order = location.state.order
    const keyNoti = 'loading-get-tracking-number'
    try {
      let linkTrackingNumber = ''
      if (order.link_tracking_number && order.link_tracking_number.includes('wasabi'))
        linkTrackingNumber = order.link_tracking_number
      else {
        const body = {
          customer_order_code: order.customer_order_code,
          customer_id: order.customer_id,
        }
        notification.open({
          message: 'Đang thực hiện lấy mã vận đơn',
          key: keyNoti,
          icon: <Spin />,
          duration: 0,
        })
        const res = await getTrackingNumber(body)
        notification.close(keyNoti)
        if (res.status === 200) {
          if (res.data.success) {
            notification.success({ message: 'Lấy mã vận đơn thành công' })
            linkTrackingNumber = res.data.data
          } else notification.error({ message: res.data.message || 'Lấy mã vận đơn có lỗi xảy ra' })
        } else notification.error({ message: res.data.message || 'Lấy mã vận đơn có lỗi xảy ra' })
      }

      if (linkTrackingNumber) {
        printJS({
          printable: linkTrackingNumber,
          type: 'pdf',
          showModal: true,
          modalMessage: 'Đang in...',
          onError: () => {
            if (!isCallback) _getTrackingNumber(true)
          },
        })
      }
    } catch (error) {
      notification.close(keyNoti)
      console.log(error)
    }
  }

  const _acceptPackageScan = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const body = { pack_status: 'COMPLETE', processing: 'is_packed', packages: packages }
      const res = await updateExportOrders(location.state.order.order_id, body)
      dispatch({ type: ACTION.LOADING, data: false })
      if (res.status === 200) {
        if (res.data.success) {
          await cancelBaskets({ order_code: location.state.order.code })
          notification.success({ message: 'Xác nhận đóng gói thành công' })
          // await _getTrackingNumber()
          history.push(ROUTES.PACKAGE)
        } else notification.error({ message: res.data.message || 'Đóng gói không thành công' })
      } else notification.error({ message: res.data.message || 'Đóng gói không thành công ' })
    } catch (error) {
      _addLog(`accept package: ${JSON.stringify(error)}`)
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const handlePrint = async (pkgList = []) => {
    setPackagesPrint(() => pkgList)
    await delay(100)
    _print()

    //Cập nhật mã màu đã in hay chưa cho package
    const packagesNew = packages.map((pkg) => {
      const packageUpdate = pkgList.find((p) => p.package_code === pkg.package_code)
      if (packageUpdate && !packageUpdate.is_print_bill) return { ...pkg, is_print_bill: true }
      return { ...pkg }
    })

    setPackages([...packagesNew])
  }

  const HandlerKeyboard = () => {
    return (
      <KeyboardEventHandler
        handleKeys={['f1', 'esc', 'f2', 'f3', 'f4']}
        onKeyEvent={async (key, e) => {
          switch (key) {
            case 'f1': {
              _updateOrder()
              break
            }
            case 'esc': {
              _showConfirmAcceptPackage()
              break
            }

            case 'f2': {
              handlePrint(packages)
              break
            }

            case 'f3': {
              _reloadOrder()
              break
            }

            case 'f4': {
              _getTrackingNumber()
              break
            }

            default:
              break
          }
        }}
      />
    )
  }

  const findProduct = (barcode = '') => {
    const product = products.find((p) => p.barcode === barcode || p.code === barcode)
    if (product) {
      const productsOfPackageNew = [...productsOfPackage]
      const productOfPackage = productsOfPackageNew.find((p) => p.code === product.code)
      if (!productOfPackage) productsOfPackageNew.unshift(product)
      setProductsOfPackage([...productsOfPackageNew])
      onScanProduct(barcode, pkgFind, true)
    } else
      message.open({
        content: (
          <div style={{ fontSize: 22 }}>
            Không tìm thấy mã sản phẩm <b>{barcode}</b> này,{' '}
            <a onClick={() => message.destroy('noti-scan-product')}>xác nhận kiểm tra lại ngay</a>
          </div>
        ),
        duration: 10,
        key: 'noti-scan-product',
      })

    if (inputCodeRef && inputCodeRef.current) inputCodeRef.current.focus()
  }

  const columnsProductOfPackage = [
    {
      title: 'STT',
      key: 'stt',
    },
    {
      title: 'Mã Sản phẩm',
      render: (text, record) => (record?.barcode ? record?.barcode : record?.code),
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
    },
    {
      title: 'Quy cách',
      dataIndex: 'convention',
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'images',
      render: (text) =>
        text &&
        text.length && (
          <img src={text[0] || IMAGE_DEFAULT} style={{ width: 50, height: 50 }} alt="" />
        ),
    },
    {
      width: 100,
      title: 'SL yêu cầu đóng gói',
      align: 'center',
      render: (text, record) => {
        const productCurrent = productsNotEnoughCondition.find(
          (p) => p.product_id === record.product_id
        )
        return (
          <b style={{ fontSize: 22 }}>
            {productCurrent
              ? formatCash(productCurrent.export_quantity || 0)
              : record.export_quantity}
          </b>
        )
      },
    },
    {
      title: 'Số lượng đóng gói',
      key: 'quantity_package',
      align: 'center',
    },
    {
      title: 'QR code',
      render: (data, record) => (
        <Row>
          <QrCodeProduct value={record.barcode} />
        </Row>
      ),
      align: 'center',
    },
    {
      title: '',
      align: 'center',
      key: 'delete_product',
    },
  ]

  const columnsExpense = [
    {
      title: 'Mã phụ liệu',
      dataIndex: 'expense_code',
    },
    {
      title: 'Tên phụ liệu',
      dataIndex: 'expense_name',
    },
    // {
    //   title: 'Chi phí',
    //   render: (text, record) => formatCash(record.fee || 0),
    // },
    // {
    //   width: 250,
    //   title: 'Mô tả',
    //   dataIndex: 'description',
    // },
    {
      title: 'SL phụ liệu',
      key: 'unit_quantity',
    },
    {
      title: '',
      key: 'action',
    },
  ]

  const _getExpenses = async () => {
    try {
      const res = await getExpense()
      if (res.status === 200) setListExpense(res.data.data)
    } catch (error) {
      console.log(error)
      _addLog(JSON.stringify(error))
    }
  }

  const _onBack = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })

      //nếu đơn hàng status là progressing thì back về cập nhật status cho order đó là waiting
      if (location.state.order.pack_status === 'PROCESSING') {
        const body = { pack_status: 'WAITING', processing: 'is_packed' }
        await updateExportOrders(location.state.order.order_id, body)
      }
      dispatch({ type: ACTION.LOADING, data: false })
      history.push(ROUTES.PACKAGE)
    } catch (err) {
      _addLog(`update status order: ${JSON.stringify(err)}`)
      console.log(err)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const _showConfirmAcceptPackage = () => {
    toggleVisibleConfirm()
    setTimeout(() => setDisableBtnConfirm(false), 5000)
  }

  const componentRefTransfer = useRef()
  const componentRefInvoice = useRef()

  const _handlePrintTransfer = useReactToPrint({
    content: () => componentRefTransfer.current,
  })
  const _handlePrintInvoice = useReactToPrint({
    content: () => componentRefInvoice.current,
  })

  const onScanProduct = async (value, pkg = null, isFocusInput = false) => {
    try {
      if (value) {
        const pkgScan = pkg || packageScan
        if ((value + '').length > 1 && pkgScan) {
          const productScan = products.find((p) => p.barcode === value || p.code === value)
          const keyScan = 'keyScan'
          if (productScan) {
            const packagesNew = [...packages]
            const indexPackage = packagesNew.findIndex(
              (pkg) => pkg.package_code === pkgScan.package_code
            )
            if (indexPackage !== -1) {
              const indexProduct = packagesNew[indexPackage].products.findIndex(
                (p) => p.product_id === productScan.product_id
              )
              if (indexProduct !== -1) {
                const quantity =
                  packagesNew[indexPackage].products[indexProduct].quantity_in_package || 0
                packagesNew[indexPackage].products[indexProduct].quantity_in_package = quantity + 1
              } else {
                packagesNew[indexPackage].products.unshift({
                  ...productScan,
                  quantity_in_package: 1,
                  expense: [],
                })
              }

              notification.success({
                key: keyScan,
                message: (
                  <div>
                    Quét sản phẩm <b>{productScan.name}</b> thành công
                  </div>
                ),
              })

              setPackages(packagesNew.map((pkg) => ({ ...pkg, products: [] })))
              await delay(50)
              setPackages([...packagesNew])
            }
          } else
            message.open({
              content: (
                <div style={{ fontSize: 22 }}>
                  Sản phẩm <b>{value}</b> không nằm trong danh sách sản phẩm cần đóng gói
                </div>
              ),
              duration: 6,
              key: 'noti-scan-product',
            })
        }
      }
      formScan.resetFields()
      if (scanRef && scanRef.current) scanRef.current.focus()

      if (isFocusInput) {
        await delay(100)
        if (inputCodeRef && inputCodeRef.current) inputCodeRef.current.focus()
      }
    } catch (error) {
      formScan.resetFields()
      if (scanRef && scanRef.current) scanRef.current.focus()
      _addLog(`scan package: ${JSON.stringify(error)}`)
    }
  }

  const handleCheckProductNotEnoughCondition = () => {
    let products = []
    packages.map((pkg) =>
      pkg.products.map((pd) => {
        const productIndex = products.findIndex((p) => p.product_id === pd.product_id)
        if (productIndex !== -1) {
          const quantityPackage = pd.quantity_in_package
          const quantityRequire = pd.export_quantity
          products[productIndex].quantity_in_package =
            products[productIndex].quantity_in_package + quantityPackage
          products[productIndex].export_quantity = quantityRequire
        } else products.push({ ...pd })
      })
    )
    setProductsScan(products)
    setProductsNotEnoughCondition(
      products.filter((pd) => pd.export_quantity !== pd.quantity_in_package)
    )
  }

  const addPackage = async (isFocusScan = false) => {
    const packagesNew = [...packages]
    const initPackage = {
      create_date: moment().tz('Asia/Ho_Chi_Minh').format(),
      package_code: `${location.state.order.code}-KH${String(packagesNew.length + 1).padStart(
        3,
        '0'
      )}`,
      products: [],
    }
    setPackages([])
    await delay(100)
    setPackages([initPackage, ...packagesNew])
    setPackageScan(initPackage)
    await delay(100)
    if (isFocusScan) if (scanRef && scanRef.current) scanRef.current.focus()
  }

  const initPackage = async () => {
    const packagesNew = location.state.order.packages.map((pkg) => ({
      ...pkg,
      products: pkg.products.map((product) => {
        const productFind = location.state.order.products.find(
          (p) => p.product_id === product.product_id
        )
        if (productFind) return { ...product, export_quantity: productFind.export_quantity }
        return { ...product }
      }),
    }))

    //nếu chưa có pkg nào thì tạo mới 1 pkg
    if (packagesNew.length === 0) {
      const objPackage = {
        create_date: moment().tz('Asia/Ho_Chi_Minh').format(),
        package_code: `${location.state.order.code}-KH${String(packagesNew.length + 1).padStart(
          3,
          '0'
        )}`,
        products: [],
      }
      setPackageScan(objPackage)
      packagesNew.unshift(objPackage)
    } else setPackageScan(packagesNew[0])

    setPackages([...packagesNew])

    await delay(200)
    if (scanRef && scanRef.current) scanRef.current.focus()
  }

  useEffect(() => {
    _getExpenses()
    if (!location.state) {
      history.push(ROUTES.PACKAGE)
    } else {
      initPackage()

      setInfoOrder(location.state.order)
      setProducts(location.state.order.products || [])
    }
  }, [])

  useEffect(() => {
    if (!pkgFind) {
      setProductsOfPackage([])
      if (inputCodeRef && inputCodeRef.current) inputCodeRef.current.value = ''

      //focus lại input scan của package đầu tiên khi tăt modal nhập mã sp thủ công
      if (scanRef && scanRef.current) scanRef.current.focus()
      if (packages.length) setPackageScan(packages[0])
    } else {
      const focusInputBarcode = async () => {
        await delay(200)
        if (inputCodeRef && inputCodeRef.current) inputCodeRef.current.focus()
      }
      focusInputBarcode()
    }
  }, [pkgFind])

  useEffect(() => {
    handleCheckProductNotEnoughCondition()
  }, [packages])

  return (
    <div>
      <div id={ID_DIV_PRINT}></div>
      <Modal
        width="50%"
        footer={
          <Row justify="end">
            <Space>
              <Button
                onClick={() => {
                  toggleVisibleConfirm()
                  setDisableBtnConfirm(true)
                }}
              >
                Hủy (Esc)
              </Button>
              <Button
                autoFocus
                onClick={() => {
                  if (
                    products.filter((pd) =>
                      productsScan.find((p) => p.product_id === pd.product_id) ? false : true
                    ).length === 0 &&
                    productsNotEnoughCondition.length === 0
                  ) {
                    _acceptPackageScan()
                    toggleVisibleConfirm()
                  } else
                    Modal.confirm({
                      content: 'Xác nhận đóng gói',
                      onOk: () => {
                        _acceptPackageScan()
                        toggleVisibleConfirm()
                      },
                      cancelText: 'Hủy bỏ',
                    })
                }}
                // disabled={disableBtnConfirm}
                type="primary"
              >
                OK (Enter)
              </Button>
            </Space>
          </Row>
        }
        visible={visibleConfirm}
        onCancel={() => {
          toggleVisibleConfirm()
          setDisableBtnConfirm(true)
        }}
      >
        <div>
          <div style={{ fontSize: 18, maxHeight: '75vh', overflowY: 'auto' }}>
            {productsNotEnoughCondition.map((product, index) => (
              <div key={product.barcode} style={{ lineHeight: 1.4, marginBottom: 10 }}>
                {index + 1}. Sản phẩm{' '}
                <b style={{ color: 'red' }}>
                  {product.name} - {product.barcode}
                </b>{' '}
                có số lượng đóng gói{' '}
                <b style={{ color: 'red' }}>
                  {product.quantity_in_package}/{product.export_quantity}
                </b>{' '}
                {product.quantity_in_package < product.export_quantity
                  ? 'chưa đủ số lượng'
                  : 'vượt quá số lượng'}
              </div>
            ))}
            {products
              .filter((pd) =>
                productsScan.find((p) => p.product_id === pd.product_id) ? false : true
              )
              .map((product, index) => (
                <div key={product.barcode} style={{ lineHeight: 1.4, marginBottom: 10 }}>
                  {productsNotEnoughCondition.length + index + 1}. Sản phẩm{' '}
                  <b style={{ color: 'red' }}>
                    {product.name} - {product.barcode}
                  </b>{' '}
                  chưa được đóng gói
                </div>
              ))}
          </div>
          <br />
          <b>Bạn có muốn xác nhận đóng gói đơn hàng này không?</b>
        </div>
      </Modal>
      <Modal
        width="50%"
        style={{ top: 20 }}
        footer={
          <Row justify="end">
            <Button onClick={() => setPkgFind(null)}>Đóng</Button>
          </Row>
        }
        onCancel={() => setPkgFind(null)}
        visible={pkgFind}
        title={
          <div>
            Thêm sản phẩm vào kiện hàng <b>{pkgFind ? pkgFind.package_code : ''}</b>
          </div>
        }
      >
        {pkgFind && (
          <div>
            <div style={{ fontSize: 18, color: 'red' }}>* Nhập mã sản phẩm và nhấn phím Enter</div>
            <Row wrap={false}>
              <input
                className={styles['input-barcode-product']}
                ref={inputCodeRef}
                onKeyPress={(keyCode) => {
                  if (keyCode.key === 'Enter') {
                    findProduct(keyCode.target.value)
                    if (inputCodeRef && inputCodeRef.current) inputCodeRef.current.value = ''
                  }
                }}
                autoFocus
                placeholder="Vui lòng nhập mã sản phẩm"
              />
              <Button
                style={{ borderRadius: 0 }}
                type="primary"
                size="large"
                onClick={() => {
                  const barcode = inputCodeRef.current.value
                  findProduct(barcode)
                  if (inputCodeRef && inputCodeRef.current) inputCodeRef.current.value = ''
                }}
              >
                Tìm
              </Button>
            </Row>
            {productsOfPackage.length ? (
              <div style={{ marginTop: 20, fontSize: 15 }}>
                Các sản phẩm đã được thêm vào kiện hàng <b>{pkgFind.package_code || ''}</b>:
                <div>
                  {productsOfPackage.map((p) => (
                    <div key={p.barcode}>- {p.name}</div>
                  ))}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        )}
      </Modal>
      <HandlerKeyboard />
      <div style={{ display: 'none' }}>
        <ComponentPrintBill
          order={location.state && location.state.order}
          pkgList={packagesPrint}
          ref={printRef}
        />
      </div>
      <Row justify="space-between" wrap={false}>
        <Row
          align="middle"
          onClick={_onBack}
          style={{ cursor: 'pointer', width: 'max-content', fontSize: 18 }}
        >
          <ArrowLeftOutlined style={{ marginRight: 5 }} />
          <div>
            Đóng gói đơn hàng <b>#{(location.state && location.state.order.code) || ''}</b>
          </div>
        </Row>
        <Space>
          {packages.length ? (
            <Button
              onClick={async (e) => {
                handlePrint(packages)
                e.stopPropagation()
              }}
              type="primary"
              style={{ backgroundColor: '#03a6a6', borderColor: '#03a6a6' }}
            >
              In phiếu dán kiện hàng loạt (F2)
            </Button>
          ) : (
            ''
          )}

          <Button onClick={_updateOrder}>Cập nhật đơn hàng (F1)</Button>

          <Button type="primary" onClick={() => _showConfirmAcceptPackage()}>
            Xác nhận đóng gói (Esc)
          </Button>
        </Space>
      </Row>
      <Row justify="space-between" style={{ marginTop: 10 }}>
        <div style={{ fontSize: 18 }}>
          Số phiếu: <b>#{(location.state && location.state.order.customer_order_code) || ''}</b>
        </div>
        <Space>
          <Button icon={<ReloadOutlined />} onClick={_reloadOrder}>
            Làm mới đơn hàng (F3)
          </Button>
          <Button icon={<PrinterOutlined />} type="primary" onClick={_getTrackingNumber}>
            In vận đơn (F4)
          </Button>
        </Space>
      </Row>

      <br />
      <Row gutter={[15, 0]}>
        <Col span={17}>
          <div>
            <div style={{ marginBottom: 5 }}>
              <Row justify="space-between" align="middle" wrap={false}>
                <Button></Button>
                <Form form={formScan}>
                  <Form.Item name="value">
                    <Input
                      style={{ width: 0, height: 0, padding: 0, margin: 0, border: 0, opacity: 0 }}
                      onChange={(e) => {
                        if (typingTimeoutRef.current) {
                          clearTimeout(typingTimeoutRef.current)
                        }
                        typingTimeoutRef.current = setTimeout(async () => {
                          onScanProduct(e.target.value)
                        }, 100)
                      }}
                      onKeyDown={async (event) => {
                        if (scanTimeoutRef.current) {
                          clearTimeout(scanTimeoutRef.current)
                        }
                        scanTimeoutRef.current = setTimeout(async () => {
                          if (!+event.key) {
                            if (packageScan) {
                              const indexPackage = packages.findIndex(
                                (pkg) => pkg.package_code === packageScan.package_code
                              )

                              const packagesNew = [...packages]

                              if (event.key === ' ') {
                                handlePrint([packageScan])
                                return
                              }

                              if (event.key === 'F1') {
                                _updateOrder()
                                return
                              }

                              if (event.key === 'F2') {
                                handlePrint(packages)
                                return
                              }

                              if (event.key === 'F3') {
                                _reloadOrder()
                                return
                              }

                              if (event.key === 'F4') {
                                _getTrackingNumber()
                                return
                              }

                              if (event.key === 'Escape') {
                                _showConfirmAcceptPackage()
                                return
                              }

                              if (event.key === 'Control') {
                                if (packageScan && packageScan.products.length) addPackage()
                                return
                              }

                              const expense = listExpense.find(
                                (e) => e.key.toLowerCase() == event.key.toLowerCase()
                              )
                              if (expense) {
                                if (indexPackage !== -1) {
                                  if (packagesNew[indexPackage].products.length) {
                                    packagesNew[indexPackage].products = packagesNew[
                                      indexPackage
                                    ].products.map((product) => {
                                      const indexExpense = product.expense.findIndex(
                                        (e) => e.key.toLowerCase() == expense.key.toLowerCase()
                                      )

                                      return indexExpense !== -1
                                        ? {
                                            ...product,
                                            expense: product.expense.map((e, index) =>
                                              index === indexExpense
                                                ? { ...e, unit_quantity: e.unit_quantity + 1 }
                                                : e
                                            ),
                                          }
                                        : {
                                            ...product,
                                            expense: [
                                              ...product.expense,
                                              { ...expense, unit_quantity: 1 },
                                            ],
                                          }
                                    })

                                    setPackages([...packagesNew])
                                    notification.success({
                                      key: 'scanExpense',
                                      message: 'Thêm phụ liệu cho toàn bộ sản phẩm thành công',
                                    })
                                  }
                                }
                              }
                            }
                          }
                        }, 100)
                      }}
                      ref={scanRef}
                      onBlur={() => setPackageScan(null)}
                    />
                  </Form.Item>
                </Form>

                {packageScan && (
                  <h1 style={{ marginBottom: 0, fontSize: 21 }}>
                    Bắt đầu quét sản phẩm cho kiện hàng{' '}
                    <b style={{ fontSize: 19 }}>{packageScan.package_code}</b>
                  </h1>
                )}
              </Row>

              {packages.length ? (
                <Space>
                  <Row align="middle" wrap={false}>
                    <div
                      style={{
                        backgroundColor: '#55da6b',
                        height: 15,
                        width: 15,
                        borderRadius: '50%',
                        marginRight: 5,
                      }}
                    ></div>
                    <div>Đã in tem</div>
                  </Row>
                  <Row align="middle" wrap={false}>
                    <div
                      style={{
                        backgroundColor: '#ffdb00',
                        height: 15,
                        width: 15,
                        borderRadius: '50%',
                        marginRight: 5,
                      }}
                    ></div>
                    <div>Chưa in tem</div>
                  </Row>
                </Space>
              ) : (
                ''
              )}
            </div>

            <div>
              {[...packages].map((pkg, index) => (
                <Collapse
                  defaultActiveKey={packages.map((e) => e.package_code)}
                  key={pkg.package_code}
                  style={{ marginBottom: 10 }}
                >
                  <Collapse.Panel
                    className={!pkg.is_print_bill ? 'panel-bg-orange' : 'panel-bg-green'}
                    key={pkg.package_code}
                    header={
                      <Row
                        style={{ width: '100%' }}
                        align="middle"
                        justify="space-between"
                        wrap={false}
                      >
                        <div>
                          Mã kiện hàng: <b>{pkg.package_code}</b>
                        </div>

                        <Space size="small">
                          <Button
                            onClick={(e) => {
                              setPkgFind(pkg)
                              e.stopPropagation()
                            }}
                          >
                            Nhập mã thủ công
                          </Button>

                          <Button
                            onClick={(e) => {
                              if (scanRef && scanRef.current) scanRef.current.focus()
                              setPackageScan(pkg)
                              e.stopPropagation()
                            }}
                          >
                            Quét kiện hàng này
                          </Button>
                          <Button
                            onClick={async (e) => {
                              e.stopPropagation()
                              handlePrint([pkg])
                            }}
                            type="primary"
                            style={{ backgroundColor: '#03a6a6', borderColor: '#03a6a6' }}
                          >
                            {`In kiện hàng này ${
                              (packageScan && packageScan.package_code === pkg.package_code) ||
                              (packageFocus && packageFocus.package_code === pkg.package_code)
                                ? '(space)'
                                : ''
                            }`}
                          </Button>
                          <div onClick={(e) => e.stopPropagation()}>
                            <Popconfirm
                              onConfirm={() => {
                                const packagesNew = [...packages]
                                packagesNew.splice(index, 1)
                                setPackages([...packagesNew])
                              }}
                              title="Bạn có muốn xóa kiện hàng này không?"
                            >
                              <DeleteOutlined
                                style={{
                                  color: 'red',
                                  cursor: 'pointer',
                                  fontSize: 17,
                                  display: packages.length < 2 && 'none',
                                }}
                              />
                            </Popconfirm>
                          </div>
                        </Space>
                      </Row>
                    }
                  >
                    <Table
                      className="table-product-scan"
                      style={{ width: '100%' }}
                      size="small"
                      columns={columnsProductOfPackage.map((column) => {
                        if (column.key === 'stt')
                          return {
                            ...column,
                            render: (text, record, indexProduct) => (
                              <Col>
                                {' '}
                                <div>{indexProduct + 1}</div>
                                <DeleteOutlined
                                  style={{ color: 'red', cursor: 'pointer', fontSize: 17 }}
                                  onClick={() => {
                                    const packagesNew = [...packages]
                                    packagesNew[index].products.splice(indexProduct, 1)
                                    setPackages([...packagesNew])
                                  }}
                                />
                              </Col>
                            ),
                          }
                        if (column.key === 'quantity_package')
                          return {
                            ...column,
                            render: (text, record, indexProduct) => {
                              const productCurrent = productsNotEnoughCondition.find(
                                (p) => p.product_id === record.product_id
                              )

                              return (
                                <div>
                                  <input
                                    id={record.product_id}
                                    type="number"
                                    min={0}
                                    max={record.export_quantity}
                                    style={{
                                      width: 170,
                                      border: '1px solid gray',
                                      borderRadius: 5,
                                      padding: '3px 6px',
                                      fontWeight: 600,
                                      fontSize: 21,
                                    }}
                                    onKeyDown={async (event) => {
                                      if (!+event.key) {
                                        //các phím đặc biệt
                                        if (INVALID_INPUT_NUMBER.includes(event.key)) {
                                          event.preventDefault()
                                          return
                                        }

                                        if (event.key === 'F1') {
                                          _updateOrder()
                                          return
                                        }

                                        if (event.key === 'F2') {
                                          handlePrint(packages)
                                          return
                                        }

                                        if (event.key === 'F3') {
                                          _reloadOrder()
                                          return
                                        }

                                        if (event.key === 'F4') {
                                          _getTrackingNumber()
                                          return
                                        }

                                        if (event.key === 'Escape') {
                                          _showConfirmAcceptPackage()
                                          return
                                        }

                                        if (event.key === 'Control') {
                                          if (packageFocus && packageFocus.products.length)
                                            addPackage(true)
                                          return
                                        }

                                        if (event.key === ' ') {
                                          const packagesNew = [...packages]
                                          packagesNew[index].products[
                                            indexProduct
                                          ].quantity_in_package = +event.target.value
                                          setPackages([...packagesNew])

                                          await delay(100)
                                          if (packageFocus) handlePrint([packageFocus])

                                          return
                                        }

                                        //thêm phụ liệu cho sản phẩm
                                        const expense = listExpense.find(
                                          (e) => e.key.toLowerCase() == event.key.toLowerCase()
                                        )
                                        if (expense) {
                                          const packagesNew = [...packages]
                                          const expensesProduct = [
                                            ...packagesNew[index].products[indexProduct].expense,
                                          ]
                                          const indexExpense = expensesProduct.findIndex(
                                            (k) => k.expense_id === expense.expense_id
                                          )
                                          if (indexExpense === -1) {
                                            expensesProduct.unshift({
                                              ...expense,
                                              unit_quantity: 1,
                                            })
                                            packagesNew[index].products[indexProduct].expense = [
                                              ...expensesProduct,
                                            ]
                                          } else {
                                            packagesNew[index].products[indexProduct].expense =
                                              expensesProduct.map((e, index) =>
                                                index === indexExpense
                                                  ? { ...e, unit_quantity: e.unit_quantity + 1 }
                                                  : e
                                              )
                                          }

                                          setPackages([...packagesNew])
                                          notification.success({
                                            key: 'add-expense-success',
                                            message: 'Thêm phụ liệu thành công',
                                          })
                                        }
                                      }
                                    }}
                                    defaultValue={record.quantity_in_package || 0}
                                    onWheel={(e) => {
                                      e.target.blur()
                                      e.stopPropagation()
                                      setTimeout(() => e.target.focus(), 0)
                                    }}
                                    onFocus={() => {
                                      setProductFocus(record)
                                      setPackageFocus(pkg)
                                    }}
                                    onBlur={(e) => {
                                      const packagesNew = [...packages]
                                      packagesNew[index].products[
                                        indexProduct
                                      ].quantity_in_package = +e.target.value
                                      setPackages([...packagesNew])

                                      setProductFocus(null)
                                      setPackageFocus(null)
                                    }}
                                  />
                                  <div
                                    style={{
                                      color: 'red',
                                      fontSize: 15,
                                      lineHeight: 1.1,
                                      marginTop: 3,
                                    }}
                                  >
                                    {productCurrent
                                      ? (productCurrent.export_quantity <
                                          productCurrent.quantity_in_package &&
                                          '* VƯỢT QUÁ SỐ LƯỢNG ĐÓNG GÓI') ||
                                        (productCurrent.export_quantity >
                                          productCurrent.quantity_in_package &&
                                          '* CHƯA ĐỦ SỐ LƯỢNG ĐÓNG GÓI')
                                      : ''}
                                  </div>
                                </div>
                              )
                            },
                          }

                        return column
                      })}
                      dataSource={[...pkg.products]}
                      pagination={false}
                      rowKey="code"
                      expandable={{
                        expandedRowRender: (recordProduct, indexProduct) => {
                          if (recordProduct.expense && recordProduct.expense.length === 0)
                            return <b>CHƯA CÓ PHỤ LIỆU</b>

                          return (
                            <div>
                              <b>DANH SÁCH PHỤ LIỆU</b>
                              <Table
                                style={{ width: '100%' }}
                                dataSource={[...recordProduct.expense]}
                                size="small"
                                pagination={false}
                                columns={columnsExpense.map((column) => {
                                  if (column.key === 'unit_quantity')
                                    return {
                                      ...column,
                                      render: (text, record, indexExpense) => {
                                        const InputQuantity = () => (
                                          <input
                                            style={{
                                              width: 125,
                                              border: '1px solid gray',
                                              borderRadius: 5,
                                              padding: '3px 6px',
                                              fontWeight: 600,
                                            }}
                                            defaultValue={record.unit_quantity || 1}
                                            min={1}
                                            onBlur={(e) => {
                                              const value = +e.target.value

                                              const packagesNew = [...packages]
                                              const expensesProduct =
                                                packagesNew[index].products[indexProduct].expense

                                              packagesNew[index].products[indexProduct].expense =
                                                expensesProduct.map((e, index) =>
                                                  index === indexExpense
                                                    ? { ...e, unit_quantity: value || 1 }
                                                    : e
                                                )

                                              setPackages([...packagesNew])

                                              if (scanRef && scanRef.current)
                                                scanRef.current.focus()
                                              setPackageScan(pkg)
                                            }}
                                          />
                                        )
                                        return <InputQuantity />
                                      },
                                    }
                                  if (column.key === 'action')
                                    return {
                                      ...column,
                                      render: (text, record, indexExpense) => (
                                        <DeleteOutlined
                                          style={{ color: 'red', cursor: 'pointer', fontSize: 17 }}
                                          onClick={() => {
                                            const packagesNew = [...packages]
                                            packagesNew[index].products[
                                              indexProduct
                                            ].expense.splice(indexExpense, 1)
                                            setPackages([...packagesNew])
                                          }}
                                        />
                                      ),
                                    }
                                  return column
                                })}
                              />
                            </div>
                          )
                        },
                        expandedRowKeys: pkg.products ? pkg.products.map((p) => p.code) : [],
                      }}
                    />
                  </Collapse.Panel>
                </Collapse>
              ))}
            </div>
          </div>
        </Col>
        <Col span={7} align="center">
          <Affix offsetTop={80}>
            <List
              size="small"
              header={<b>DANH SÁCH PHỤ LIỆU</b>}
              bordered
              dataSource={listExpense}
              renderItem={(expense) => (
                <List.Item>
                  <Row justify="space-between" style={{ width: '100%' }}>
                    <div>
                      <b style={{ marginRight: 0 }}>Tên phụ liệu: </b> {expense.expense_name}
                    </div>
                    <div>
                      <b style={{ marginRight: 0 }}>Phím tắt: </b> {expense.key}
                    </div>
                  </Row>
                </List.Item>
              )}
            />
            <br />
            <div style={{ fontWeight: 600, fontSize: 20, lineHeight: 1 }}>
              {packageScan && packageScan.products.length ? (
                <div>
                  <h1>
                    * Nhấn phím tắt để thêm phụ liệu vào toàn bộ sản phẩm trong kiện hàng{' '}
                    <b style={{ fontSize: 20 }}>{getPackageCode(packageScan.package_code)}</b>
                  </h1>
                </div>
              ) : (
                ''
              )}
              {productFocus && (
                <div>
                  <h1>
                    * Nhấn phím tắt để thêm phụ liệu vào sản phẩm{' '}
                    <b style={{ fontSize: 20 }}>{productFocus.name}</b>
                  </h1>
                </div>
              )}
              {(packageFocus || packageScan) && (
                <div>
                  <h1>
                    * Nhấn phím space để in kiện hàng{' '}
                    <b style={{ fontSize: 19 }}>
                      {packageScan
                        ? getPackageCode(packageScan.package_code)
                        : packageFocus
                        ? getPackageCode(packageFocus.package_code)
                        : ''}
                    </b>
                  </h1>
                </div>
              )}
              {(packageScan && packageScan.products.length) ||
              (packageFocus && packageFocus.products.length) ? (
                <div>
                  <h1>
                    * Nhấn phím control để hoàn tất kiện hàng{' '}
                    <b style={{ fontSize: 20 }}>
                      {(packageScan && getPackageCode(packageScan.package_code)) ||
                        (packageFocus && getPackageCode(packageFocus.package_code))}
                    </b>{' '}
                    và tiếp tục quét kiện hàng tiếp theo
                  </h1>
                </div>
              ) : (
                ''
              )}
              <div style={{ color: 'red' }}>
                {!packageScan && !packageFocus && '* Máy quét đang tắt'}
              </div>
            </div>
          </Affix>
        </Col>
      </Row>
    </div>
  )
}
