const columns = [
  {
    key: 'stt',
    title: 'STT',
    align: 'center',
    width: 50,
    fixed: 'left',
  },
  {
    key: 'order',
    title: 'Đơn phát sinh',
    align: 'center',
    width: '17%',
    fixed: 'left',
  },
  {
    key: 'fee_name',
    title: 'Tên chi phí',
    align: 'center',
    width: '17%',
  },
  {
    key: 'group_info',
    title: 'Loại phát sinh',
    align: 'center',
  },
  {
    key: 'customer_info',
    title: 'Doanh nghiệp',
    align: 'center',
  },
  {
    key: 'warehouse_info',
    title: 'Kho phát sinh',
    align: 'center',
  },
  {
    key: 'fee_cost',
    title: 'Đơn giá',
    align: 'center',
  },
  {
    key: 'quantity',
    title: 'Số lượng',
    align: 'center',
  },
  {
    key: 'final_cost',
    title: 'Thành tiền',
    align: 'center',
  },
  {
    key: 'create_date',
    title: 'Ngày tạo',
    dataIndex: 'create_date',
    align: 'center',
    width: 100,
  },
  {
    key: 'creator_info',
    title: 'Người tạo',
    align: 'center',
  },
  {
    key: 'verify',
    title: 'Trạng thái kiểm duyệt',
    align: 'center',
    width: 100,
  },
  {
    key: 'action',
    title: 'Hành động',
    align: 'center',
    fixed: 'right',
  },
]
export default columns
