import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
//styles
import styles from './create-employee.module.scss'
//components
import SuffixIconCustom from 'components/suffixIconCustom'
import Permission from 'components/permission'

//consts
import { ACTION, PERMISSIONS, regexPhone } from 'consts'
import { filterOptionSelect, _addLog } from 'utils'

//apis
import { createUser, updateUser } from 'apis/user'
import { getWarehouses } from 'apis/warehouse'
import { getRoles } from 'apis/role'
import { getDistrict, getProvince, getWard } from 'apis/address'

//antd
import { Row, Col, Form, Input, Select, Button, notification, Space, Radio } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'

export default function CreateEmployee() {
  const history = useHistory()
  const location = useLocation()
  const [form] = Form.useForm()

  const [dataWarehouses, setDataWarehouses] = useState([])
  const [dataProvince, setDataProvince] = useState([])
  const [dataDistrict, setDataDistrict] = useState([])
  const [roles, setRoles] = useState([])
  const [dataWard, setDataWard] = useState([])

  const [valueProvince, setValueProvince] = useState('Lào Cai')
  const [valueDistrict, setValueDistrict] = useState('Huyện Xi Ma Cai')

  const dispatch = useDispatch()

  const _createOrUpdateUser = async () => {
    await form.validateFields()
    const formData = form.getFieldsValue()
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      let res
      if (location.state) {
        res = await updateUser(location.state.user_id, formData)
      } else {
        res = await createUser(formData)
      }

      if (res.status === 200) {
        notification.success({
          message: location.state ? 'Cập nhật nhân viên thành công!' : 'Tạo nhân viên thành công!',
        })
        history.goBack()
      } else {
        notification.error({
          message:
            res.data.message ||
            (location.state ? 'Cập nhật nhân viên thất bại!' : 'Tạo nhân viên thất bại!'),
        })
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
      _addLog(`add or update user: ${JSON.stringify(error)}`)
    }
  }

  const _getProvince = async () => {
    try {
      const res = await getProvince()
      if (res.status === 200) {
        setDataProvince(res.data.data)
        if (!location.state) form.setFieldsValue({ province: res.data.data[0].province_name })
      }
    } catch (error) {
      console.log(error)
      _addLog(`get province: ${JSON.stringify(error)}`)
    }
  }

  const _getDistrict = async (value) => {
    try {
      if (value) {
        const res = await getDistrict(value)
        if (res.status === 200) {
          const data = res.data.data
          setDataDistrict(data)
          setValueDistrict(data[0]?.district_name)
          if (!location.state) form.setFieldsValue({ district: data[0]?.district_name })
        }
      }
    } catch (error) {
      console.log(error)
      _addLog(`get district: ${JSON.stringify(error)}`)
    }
  }

  const _getWard = async (value) => {
    try {
      const res = await getWard(value)
      if (res.status === 200) {
        setDataWard(res.data.data)
        if (!location.state) form.setFieldsValue({ ward: res.data.data[0].ward_name })
      }
    } catch (error) {
      console.log(error)
      _addLog(`get ward: ${JSON.stringify(error)}`)
    }
  }

  const _getRoles = async () => {
    try {
      const res = await getRoles()
      if (res.status === 200) {
        const data = res.data.data.filter((role) => role.role_id !== -1 && role.role_id !== -2)
        setRoles(data)
        if (!location.state) form.setFieldsValue({ role_id: res.data.data[0].role_id })
      }
    } catch (error) {
      console.log(error)
      _addLog(`get roles: ${JSON.stringify(error)}`)
    }
  }

  useEffect(() => {
    if (location.state) {
      form.setFieldsValue(location.state)
      const { province, district } = location.state
      setValueProvince(province)
      setValueDistrict(district)
    }
    _getProvince()
    _getWarehouses()
    _getRoles()
  }, [])

  useEffect(() => {
    _getDistrict({ province_name: valueProvince })
    _getWard({ province_name: valueProvince })
  }, [valueProvince])

  useEffect(() => {
    _getWard({ district_name: valueDistrict })
  }, [valueDistrict])

  const _getWarehouses = async () => {
    try {
      const res = await getWarehouses()
      if (res.status === 200) {
        setDataWarehouses(res.data.data)
        if (!location.state) form.setFieldsValue({ warehouse_id: res.data.data[0].warehouse_id })
      }
    } catch (error) {
      _addLog(`get warehouse: ${JSON.stringify(error)}`)
    }
  }
  return (
    <div className={styles['employee-management']}>
      <Row className="page-title" justify="space-between" style={{ marginBottom: '15px' }}>
        <h3 style={{ cursor: 'pointer' }} onClick={() => history.goBack()}>
          <ArrowLeftOutlined style={{ marginRight: '10px' }} />
          {location.state ? 'Cập nhật nhân viên' : 'Tạo nhân viên'}
        </h3>
      </Row>
      <Form form={form} layout="vertical" onFinish={_createOrUpdateUser}>
        <Row style={{ padding: '0 30px' }}>
          <Col md={12} style={{ paddingRight: '50px' }}>
            {!location.state && (
              <Form.Item
                name="username"
                label="Tên đăng nhập"
                rules={[{ required: true, message: 'Vui lòng nhập tên tài khoản' }]}
              >
                <Input placeholder="Nhập tên tài khoản" />
              </Form.Item>
            )}
            <Form.Item
              name="fullname"
              label="Tên nhân viên"
              rules={[{ required: true, message: 'Vui lòng nhập tên nhân viên' }]}
            >
              <Input allowClear placeholder="Nhập tên nhân viên" />
            </Form.Item>

            {!location.state && (
              <Form.Item
                name="password"
                label="Mật khẩu"
                rules={[
                  { required: true, message: 'Vui lòng nhập mật khẩu' },
                  {
                    min: 6,
                    message: 'Vui lòng nhập mật khẩu từ 6 kí tự trở lên',
                  },
                ]}
              >
                <Input.Password allowClear placeholder="Nhập mật khẩu" />
              </Form.Item>
            )}
            {!location.state && (
              <Form.Item
                name="confirmpassword"
                label="Xác nhận mật khẩu"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập xác nhận mật khẩu',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }

                      return Promise.reject(new Error('Hai mật khẩu không trùng nhau'))
                    },
                  }),
                ]}
              >
                <Input.Password allowClear placeholder="Nhập xác nhận mật khẩu" />
              </Form.Item>
            )}
            <Form.Item
              name="warehouse_id"
              label="Kho làm việc"
              rules={[{ required: true, message: 'Vui lòng chọn kho làm việc' }]}
            >
              <Select
                style={{ width: '100%' }}
                suffixIcon={<SuffixIconCustom />}
                showSearch
                allowClear
                placeholder="Kho"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {dataWarehouses.map((warehouse) => {
                  return (
                    <Select.Option value={warehouse.warehouse_id}>{warehouse.name}</Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="role_id"
              label="Vai trò"
              rules={[{ required: true, message: 'Vui lòng chọn vai trò' }]}
            >
              <Select placeholder="Chọn vai trò" showSearch filterOption={filterOptionSelect}>
                {roles.map((role) => {
                  return (
                    <Select.Option
                      style={{ textTransform: 'capitalize' }}
                      value={role.role_id}
                      key={role.role_id}
                    >
                      {role.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12} style={{ paddingLeft: '50px' }}>
            <Form.Item
              name="address"
              label={<label style={{ fontSize: 16, fontWeight: 500 }}>Địa chỉ</label>}
              rules={[
                {
                  message: 'Vui lòng nhập địa chỉ',
                },
              ]}
            >
              <Input placeholder="Nhập dịa chỉ" />
            </Form.Item>

            <Form.Item
              name="province"
              label={<label style={{ fontSize: 16, fontWeight: 500 }}>Tỉnh/Thành phố</label>}
            >
              <Select
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: '100%' }}
                placeholder="Chọn tỉnh/thành phố"
                suffixIcon={<SuffixIconCustom />}
                onChange={(value) => setValueProvince(value)}
              >
                {dataProvince.map((item, index) => (
                  <Select.Option key={index} value={item.province_name}>
                    {item.province_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="district"
              label={<label style={{ fontSize: 16, fontWeight: 500 }}>Quận/Huyện</label>}
            >
              <Select
                allowClear
                showSearch
                filterOption={(inputValue, option) => {
                  return option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                }}
                style={{ width: '100%' }}
                placeholder="Chọn quận/huyện"
                suffixIcon={<SuffixIconCustom />}
                value={valueDistrict}
                onChange={(value) => setValueDistrict(value)}
              >
                {dataDistrict.map((item, index) => (
                  <Select.Option key={index} value={item.district_name}>
                    {item.district_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="ward"
              label={<label style={{ fontSize: 16, fontWeight: 500 }}>Phường/Xã</label>}
            >
              <Select
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: '100%' }}
                placeholder="Chọn phường/xã"
                suffixIcon={<SuffixIconCustom />}
              >
                {dataWard.map((item, index) => (
                  <Select.Option key={index} value={item.ward_name}>
                    {item.ward_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Row justify="end" align="middle" wrap={false} style={{ marginTop: 45 }}>
              <Space size="large">
                <Button
                  type="primary"
                  danger
                  style={{ minWidth: 100 }}
                  onClick={() => history.goBack()}
                >
                  Hủy
                </Button>
                <Permission
                  permissions={
                    location.state ? [PERMISSIONS.cap_nhat_nhan_vien] : [PERMISSIONS.tao_nhan_vien]
                  }
                >
                  <Button
                    type="primary"
                    style={{ backgroundColor: '#FF9C64', borderColor: '#FF9C64', minWidth: 100 }}
                    htmlType="submit"
                  >
                    {location.state ? 'Lưu' : 'Tạo'}
                  </Button>
                </Permission>
              </Space>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
