import { Link, useHistory } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styles from './ingredient.module.scss'
import moment from 'moment'
import { isAcceptPermissions, saveFiltersToLocalStorage, _addLog } from 'utils'

//const
import { PAGE_SIZE_OPTIONS, POSITION_TABLE, ROUTES, SIZE_TABLE, PRINTER, PERMISSIONS } from 'consts'

//apis
import { deleteExpense, getExpense } from 'apis/expense'
import { getPrint } from 'apis/print'

//components
import SuffixIconCustom from 'components/suffixIconCustom'
import SettingColumnsButton from 'components/setting-columns'
import BackToTop from 'components/back-to-top'
import columnsExpense from './columns'
import ModalOptionsPrint from 'components/modal-opions-print'
import FilterDate from 'components/filter-date'
import printToThermalPrinter from 'components/thermal-printer/print'
import { barcode } from 'components/thermal-printer/templates'
import Permission from 'components/permission'

// antd
import { Col, Row, Button, Input, Table, notification, Select, Popconfirm, Space } from 'antd'
import {
  CloseCircleOutlined,
  DeleteOutlined,
  PrinterOutlined,
  ReloadOutlined,
} from '@ant-design/icons'

export default function Ingredient() {
  const history = useHistory()
  const KEY_FILTER = 'ingredientFilters'
  const filters = localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))

  const [loading, setLoading] = useState(false)
  const [dataExpense, setDataExpense] = useState([])
  const [selectKeys, setSelectKeys] = useState([])

  const [optionSearch, setOptionSearch] = useState(
    filters
      ? (filters.expense_name && 'expense_name') || (filters.key && 'key') || 'expense_name'
      : 'expense_name'
  )
  const [valueSearch, setValueSearch] = useState(
    filters ? filters.expense_name || filters.key || '' : ''
  )
  const [paramsFilter, setParamsFilter] = useState(
    filters ? { ...filters } : { page: 1, page_size: 10 }
  )
  const [countExpense, setCountExpense] = useState(0)
  const [columns, setColumns] = useState([])

  const typingTimeoutRef = useRef(null)

  const dataUser = useSelector((state) => state.user)

  const _getExpenses = async () => {
    try {
      setLoading(true)
      const res = await getExpense(paramsFilter)
      if (res.status === 200) {
        setCountExpense(res.data.count)
        setDataExpense(res.data.data)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      _addLog(JSON.stringify(error))
      setLoading(false)
    }
  }

  const _getPrint = async (params) => {
    try {
      const res = await getPrint(params)
      if (res.status === 200) {
        return res.data.data[0]
      }
      return ''
    } catch (error) {
      console.log(error)
      _addLog(JSON.stringify(error))
      return ''
    }
  }

  const _deleteExpense = async (id) => {
    try {
      setLoading(true)
      let res = await deleteExpense({ expense_id: [id] })
      setLoading(false)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Xóa phụ liệu thành công' })
          _getExpenses()
        } else notification.error({ message: res.data.message || 'Xóa phụ liệu thất bại' })
      } else notification.error({ message: res.data.message || 'Xóa phụ liệu thất bại' })
    } catch (error) {
      _addLog(`Delete expense: ${JSON.stringify(error)}`)
      setLoading(false)
    }
  }

  const _search = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      const value = e.target.value

      //khi search hoac filter thi reset page ve 1
      paramsFilter.page = 1

      if (value) paramsFilter[optionSearch] = value
      else delete paramsFilter[optionSearch]

      setParamsFilter({ ...paramsFilter })
    }, 450)
  }

  const _printBarcode = async (record) => {
    const params = {
      warehouse_id: dataUser.warehouse_id,
      name: PRINTER.PHU_LIEU,
    }
    const dataPrinter = await _getPrint(params)
    printToThermalPrinter(
      barcode,
      {
        barcode: record.expense_code,
        title: 'Phu lieu: ' + record.slug_name?.replace(/-{1,}/g, ' '),
      },
      dataPrinter && dataPrinter.ip
    )
  }

  const _selectOption = (value) => {
    setOptionSearch(value)
    delete paramsFilter[optionSearch]

    if (valueSearch) {
      paramsFilter.page = 1

      //lấy giá trị select mới để lọc lại dữ liệu
      setParamsFilter({
        ...paramsFilter,
        [value]: valueSearch,
      })
    }
  }

  const _clearFilters = () => {
    Object.keys(paramsFilter).map((key) => delete paramsFilter[key])
    paramsFilter.page = 1
    paramsFilter.page_size = 10
    setValueSearch('')
    setSelectKeys([])
    setOptionSearch('expense_name')
    setParamsFilter({ ...paramsFilter })
  }

  useEffect(() => {
    _getExpenses()
    saveFiltersToLocalStorage(paramsFilter, KEY_FILTER)
  }, [paramsFilter])

  return (
    <div className={styles['ingredient']}>
      <BackToTop />
      <Row className="page-title" justify="space-between" style={{ marginBottom: '25px' }}>
        <h3>Danh sách phụ liệu</h3>
        <Space>
          <Button onClick={_getExpenses} type="primary" icon={<ReloadOutlined />}>
            Làm mới
          </Button>
          <SettingColumnsButton
            columnsRender={columnsExpense}
            columns={columns}
            setColumns={setColumns}
            nameColumn="columnsExpense"
          />
          <Permission permissions={[PERMISSIONS.tao_phu_lieu]}>
            <Button
              className={styles['ingredient-btn--create-ingredient']}
              onClick={() => history.push(ROUTES.INGREDIENT_ADD)}
            >
              Tạo phụ liệu
            </Button>
          </Permission>
        </Space>
      </Row>
      <div className={styles['ingredient-content']}>
        <Row gutter={20} style={{ marginBottom: 25 }}>
          <Col span={10}>
            <Input.Group compact>
              <Input
                style={{ width: '65%' }}
                enterButton
                allowClear
                value={valueSearch}
                onChange={_search}
                placeholder="Tìm kiếm theo tên chi phí/phím tắt"
              />
              <Select
                style={{ width: '35%' }}
                suffixIcon={<SuffixIconCustom />}
                value={optionSearch}
                onChange={(value) => _selectOption(value)}
              >
                <Select.Option value="expense_name">Tên chi phí</Select.Option>
                <Select.Option value="key">Phím tắt</Select.Option>
              </Select>
            </Input.Group>
          </Col>
          <Col span={6}>
            <FilterDate paramsFilter={paramsFilter} setParamsFilter={setParamsFilter} />
          </Col>
          <Col>
            {Object.keys(paramsFilter).length > 2 && (
              <Button type="primary" danger icon={<CloseCircleOutlined />} onClick={_clearFilters}>
                Xóa bộ lọc
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Table
            rowKey="expense_id"
            loading={loading}
            size={SIZE_TABLE}
            pagination={{
              position: POSITION_TABLE,
              current: paramsFilter.page,
              pageSize: paramsFilter.page_size,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              total: countExpense,
              showQuickJumper: true,
              onChange: (page, pageSize) => {
                paramsFilter.page = page
                paramsFilter.page_size = pageSize
                setParamsFilter({ ...paramsFilter })
              },
            }}
            columns={columns.map((column) => {
              if (column.key === 'stt') {
                return {
                  ...column,
                  render: (text, record, index) =>
                    (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
                }
              }
              if (column.key === 'expense_name') {
                return {
                  ...column,
                  render: (text, record, index) =>
                    isAcceptPermissions([PERMISSIONS.xoa_phu_lieu]) ? (
                      <Link to={{ pathname: ROUTES.INGREDIENT_ADD, state: record }}>
                        <span style={{ color: '#0017E3', cursor: 'pointer' }}>{text}</span>
                      </Link>
                    ) : (
                      <b>{text}</b>
                    ),
                }
              }
              if (column.key === 'fee') {
                return {
                  ...column,
                  render: (text) =>
                    text && text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' VND',
                }
              }
              if (column.key === 'default_export') {
                return {
                  ...column,
                  render: (text, record) => (record.default_in_export_order ? 'Có' : ''),
                }
              }
              if (column.key === 'default_import') {
                return {
                  ...column,
                  render: (text, record) => (record.default_in_import_order ? 'Có' : ''),
                }
              }
              if (column.key === 'unit') {
                return {
                  ...column,
                  render: (text, record) =>
                    record.unit_info &&
                    `${record.unit_info.name} ${
                      record.unit_info.extension ? `(${record.unit_info.extension})` : ''
                    }`,
                }
              }
              if (column.key === 'fee_exceeding') {
                return {
                  ...column,
                  render: (text) =>
                    text && text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' VND',
                }
              }
              if (column.key === 'action') {
                return {
                  ...column,
                  render: (text, record) => (
                    <Space>
                      <ModalOptionsPrint
                        printWithInternet={() => _printBarcode(record)}
                        records={[record]}
                      >
                        <Button
                          icon={<PrinterOutlined />}
                          type="primary"
                          className={styles['ingredient-btn--print']}
                        >
                          In QR Code
                        </Button>
                      </ModalOptionsPrint>

                      <Permission permissions={[PERMISSIONS.xoa_phu_lieu]}>
                        <Popconfirm
                          placement="topRight"
                          title="Bạn có muốn xóa phụ liệu này không?"
                          okText="Đồng ý"
                          cancelText="Từ chối"
                          onConfirm={() => _deleteExpense(record.expense_id)}
                        >
                          <Button danger type="primary">
                            <DeleteOutlined />
                          </Button>
                        </Popconfirm>
                      </Permission>
                    </Space>
                  ),
                }
              }
              if (column.key === 'create_date') {
                return {
                  ...column,
                  render: (text) => moment(text).format('DD/MM/YYYY'),
                }
              }
              return column
            })}
            dataSource={dataExpense}
            style={{ width: '100%' }}
            scroll={{ y: '48vh', x: 'unset' }}
            className={styles['table']}
          />
        </Row>
      </div>
    </div>
  )
}
