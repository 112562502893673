const inventoryColumns = [
  {
    key: 'stt',
    title: 'STT',
    align: 'center',
    width: 50,
  },
  {
    title: 'Số phiếu',
    key: 'code',
  },
  {
    title: 'Loại phiếu',
    key: 'type',
  },
  {
    title: 'Ngày',
    key: 'date',
  },
  {
    title: 'Số CT',
    key: 'code-ct',
  },
  {
    title: 'Ngày CT',
    key: 'date-ct',
  },
  {
    title: 'Nhập SL',
    key: 'import-quantity',
  },
  {
    title: 'Xuất SL',
    key: 'export-quantity',
  },
  {
    title: 'Tồn cuối SL',
    key: 'inventory-quantity',
  },
  {
    title: 'Khách hàng',
    key: 'customer',
  },
  {
    title: 'Địa chỉ',
    key: 'address',
  },
  {
    title: 'Nội dung',
    key: 'note',
  },
  {
    title: 'Nội dung chi tiết',
    dataIndex: 'Nội dung chi tiết',
  },
]

export default inventoryColumns
