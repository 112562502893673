import React from 'react'
import QR from 'qrcode-base64'
import { Row } from 'antd'

const PrintQRCode = ({ record }) => {
  var imgData = QR.drawImg(record.code || '', {
    typeNumber: 4,
    errorCorrectLevel: 'M',
    size: 500,
  })

  return (
    <div style={{ width: '100%', height: '100%', padding: 5 }}>
      <Row align="middle" justify="center" style={{ width: '100%', height: '100%' }}>
        <img src={imgData} alt="" style={{ height: 65, width: 65 }} />
        <div style={{ marginLeft: 5 }}>
          <div style={{ fontSize: 11 }}>{record.name || ''}</div>
          <div style={{ fontSize: 27, fontWeight: 700 }}>{record.basket_id}</div>
        </div>
      </Row>
    </div>
  )
}
class ComponentPrint extends React.Component {
  render() {
    const { records } = this.props
    let printingPages = []
    for (let i = 0; i < records.length; i++) {
      const tempTemplate = <PrintQRCode record={records[i]} />
      printingPages.push(tempTemplate)
    }

    return <div>{printingPages}</div>
  }
}

function QRCodePrinter({ records, componentRef }) {
  return (
    <>
      <div style={{ display: 'none' }}>
        <ComponentPrint records={records} ref={componentRef} />
      </div>
    </>
  )
}

export default QRCodePrinter
