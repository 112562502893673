import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styles from './dashboard.module.scss'
import { ACTION, IMAGE_DEFAULT, VERSION_APP } from 'consts'
import { formatCash, _addLog } from 'utils'

//components
import SuffixIconCustom from 'components/suffixIconCustom'
import ZoomImage from 'components/zoom-image'

// antd
import { Col, DatePicker, Empty, Row, Select, Modal } from 'antd'

//chart
import { LineChart } from 'react-chartkick'
import 'chartkick/chart.js'

//apis
import { getReport } from 'apis/report'
import { getAppInfo, updateAppInfo } from 'apis/app-info'

export default function Dashboard() {
  const dispatch = useDispatch()

  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)

  const [dataReport, setDataReport] = useState({})
  const [dataAppInfo, setDataAppInfo] = useState({})
  const [paramsFilter, setParamsFilter] = useState({ today: true })

  const [isOpenSelect, setIsOpenSelect] = useState(false)
  const [valueTime, setValueTime] = useState('today')
  const [valueDateSearch, setValueDateSearch] = useState(null)
  const [valueDateTimeSearch, setValueDateTimeSearch] = useState({ today: true })

  const _getReport = async (params) => {
    dispatch({ type: ACTION.LOADING, data: true })
    try {
      const res = await getReport(params)
      console.log(res)
      if (res.status === 200) {
        setDataReport(res.data)
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
      _addLog(JSON.stringify(error))
    }
  }

  const _getAppInfo = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getAppInfo()
      if (res.status === 200) {
        if (res.data.success) {
          setDataAppInfo(res.data.data)
          // if (res.data.data.version_number !== VERSION_APP) toggle()
        }
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
      _addLog(JSON.stringify(error))
    }
  }

  const ModalDownloadApp = () => {
    return (
      <Modal footer={null} onCancel={toggle} visible={visible}>
        <h3>Ứng dụng desktop app đã có phiến bản mới, bạn có muốn tải về không?</h3>
        <a target="_blank" href={dataAppInfo?.link_download}>
          Nhấn vào đây để tải về
        </a>
      </Modal>
    )
  }

  const toggleOpenSelect = () => setIsOpenSelect(!isOpenSelect)

  const _changeValueFilterByTime = (value) => {
    delete paramsFilter[valueTime]
    if (value) paramsFilter[value] = true
    else delete paramsFilter[value]
    setValueTime(value)

    //xoa params search date hien tai
    const p = Object.keys(valueDateTimeSearch)
    if (p.length) delete paramsFilter[p[0]]

    setValueDateSearch(null)
    delete paramsFilter.from_date
    delete paramsFilter.to_date

    if (isOpenSelect) toggleOpenSelect()

    if (value) {
      const searchDate = Object.fromEntries([[value, true]]) // them params search date moi
      setParamsFilter({ ...paramsFilter, ...searchDate })
      setValueDateTimeSearch({ ...searchDate })
    } else {
      setParamsFilter({ ...paramsFilter })
      setValueDateTimeSearch({})
    }
    setParamsFilter({ ...paramsFilter })
    if (isOpenSelect) setIsOpenSelect(!isOpenSelect)
  }
  const _filterByTime = (dates, dateStrings) => {
    if (isOpenSelect) toggleOpenSelect()

    //nếu search date thì xoá các params date
    delete paramsFilter.today
    delete paramsFilter.yesterday
    delete paramsFilter.this_week
    delete paramsFilter.last_week
    delete paramsFilter.last_month
    delete paramsFilter.this_month
    delete paramsFilter.this_year
    delete paramsFilter.last_year

    //Kiểm tra xem date có được chọn ko
    //Nếu ko thì thoát khỏi hàm, tránh cash app
    //và get danh sách order
    if (!dates) {
      delete paramsFilter.from_date
      delete paramsFilter.to_date

      setValueDateSearch(null)
      setValueTime()
    } else {
      const dateFirst = dateStrings[0]
      const dateLast = dateStrings[1]

      setValueDateSearch(dates)
      setValueTime(`${dateFirst} -> ${dateLast}`)

      dateFirst.replace(/-/g, '/')
      dateLast.replace(/-/g, '/')

      paramsFilter.from_date = dateFirst
      paramsFilter.to_date = dateLast
    }

    setParamsFilter({ ...paramsFilter })
  }
  useEffect(() => {
    _getAppInfo()
  }, [])

  useEffect(() => {
    _getReport(paramsFilter)
  }, [paramsFilter])

  return (
    <div className={styles['dashboard']}>
      <Row className="page-title">
        <h3>Tổng quan</h3>
        <ModalDownloadApp />
        <Col span={7}>
          <Select
            allowClear
            open={isOpenSelect}
            onBlur={() => {
              if (isOpenSelect) toggleOpenSelect()
            }}
            onClick={() => {
              if (!isOpenSelect) toggleOpenSelect()
            }}
            value={valueTime}
            placeholder="Lọc theo thời gian"
            style={{ width: '100%' }}
            dropdownClassName="dropdown-select-custom"
            suffixIcon={<SuffixIconCustom />}
            onChange={_changeValueFilterByTime}
            dropdownRender={(menu) => (
              <div>
                <DatePicker.RangePicker
                  onFocus={() => {
                    if (!isOpenSelect) toggleOpenSelect()
                  }}
                  onBlur={() => {
                    if (isOpenSelect) toggleOpenSelect()
                  }}
                  value={valueDateSearch}
                  onChange={_filterByTime}
                  style={{ width: '100%' }}
                />
                {menu}
              </div>
            )}
          >
            <Select.Option value="today">Hôm nay</Select.Option>
            <Select.Option value="yesterday">Hôm qua</Select.Option>
            <Select.Option value="this_week">Tuần này</Select.Option>
            <Select.Option value="last_week">Tuần trước</Select.Option>
            <Select.Option value="this_month">Tháng này</Select.Option>
            <Select.Option value="last_month">Tháng trước</Select.Option>
            <Select.Option value="this_year">Năm nay</Select.Option>
            <Select.Option value="last_year">Năm trước</Select.Option>
          </Select>
        </Col>
      </Row>
      <div className={styles['dashboard-content']}>
        <div className={styles['dashboard-content--slogan']}>
          <marquee direction="right" scrollamount="10" style={{ color: 'black' }}>
            {dataAppInfo && dataAppInfo.slogan}
          </marquee>
        </div>
        <Row style={{ marginBottom: 20 }}>
          <Col span={5}>
            {/* <DateSelector
              defaultValue="this_month"
              size="large"
              selectProps={{
                style: {
                  width: '100%',
                  boxShadow: ' 0px 2px 6px rgba(19, 216, 229, 0.3)',
                },
              }}
            /> */}
          </Col>
        </Row>
        <Row justify="space-between" style={{ marginBottom: 20 }}>
          <Col span={11}>
            <div className={styles['dashboard-content--statis']}>
              Số đơn hàng được tạo <br />
              <span>{formatCash(dataReport.order_quantity)}</span>
            </div>
          </Col>
          <Col span={11}>
            <div className={styles['dashboard-content--statis']}>
              Số đơn hàng chưa được giải quyết <br />
              <span>{formatCash(dataReport.order_processing)}</span>
            </div>
          </Col>
          {/* <Col span={7}>
            <div className={styles['dashboard-content--statis']}>
              Tổng chi phí thu hộ
              <br />
              <span>{dataReport.order_cost && dataReport.order_cost.toLocaleString('en')} VNĐ</span>
            </div>
          </Col> */}
        </Row>
        <Row gutter={30}>
          <Col span={14}>
            <div className={styles['dashboard-content--chart']}>
              <Row justify="space-between">
                <Col style={{ fontSize: 18, fontWeight: 600 }}>Doanh thu</Col>
                <Col>
                  <Row style={{ fontSize: 24, fontWeight: 500, color: '#359978' }}></Row>
                  <Row style={{ fontSize: 18, fontWeight: 500 }}></Row>
                </Col>
              </Row>
              <LineChart data={dataReport.chart} height="310px" />
            </div>
          </Col>
          <Col span={10}>
            <div className={styles['dashboard-content--product']} style={{ height: '360px' }}>
              <Row justify="space-between">
                <Col style={{ fontSize: 18, fontWeight: 600 }}>
                  Danh sách sản phẩm xuất kho nhiều nhất
                </Col>
                <Col>
                  {/* <Row
                    align="middle"
                    style={{
                      color: '#0017E3',
                      cursor: 'pointer',
                      fontSize: 16,
                    }}
                    onClick={() => history.push(ROUTES.PRODUCT)}>
                    Xem chi tiết &nbsp;
                    <ArrowRightOutlined />
                  </Row> */}
                </Col>
              </Row>
              <div
                style={{ overflowY: 'auto', height: '90%' }}
                className={styles['dashboard-content--list-product']}
              >
                {dataReport.top_export_product ? (
                  dataReport.top_export_product.map((product, index) => (
                    <Row
                      key={index}
                      className={styles['dashboard-content--product-item']}
                      justify="space-between"
                    >
                      <Col span={4} className={styles['dashboard-content--product-item-image']}>
                        <ZoomImage image={(product.images && product.images[0]) || IMAGE_DEFAULT}>
                          <img
                            style={{ width: 50, height: 50 }}
                            src={(product.images && product.images[0]) || IMAGE_DEFAULT}
                            alt=""
                          />
                        </ZoomImage>
                      </Col>
                      <Col span={12} className={styles['dashboard-content--product-item-content']}>
                        <Row>{product.name}</Row>
                      </Col>
                      <Col span={8} className={styles['dashboard-content--product-item-price']}>
                        {formatCash(product.exported_quantity)} sản phẩm xuất kho
                      </Col>
                    </Row>
                  ))
                ) : (
                  <Empty
                    style={{ marginTop: '60px' }}
                    description={<span>Chưa có sản phẩm nào</span>}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
