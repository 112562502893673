const columns = [
  {
    key: 'stt',
    title: 'STT',
    dataIndex: 'stt',
    align: 'center',
    width: 50,
  },
  {
    key: 'warehouse',
    title: 'Kho',
    dataIndex: 'warehouse_id',
    align: 'center',
  },
  {
    key: 'name',
    title: 'Tên thiết bị in',
    dataIndex: 'name',
    width: '300px',
  },
  {
    title: 'IP',
    dataIndex: 'ip',
    align: 'center',
  },
  {
    key: 'action',
    align: 'center',
    width: 250,
  },
]
export default columns
