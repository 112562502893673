import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Modal, Input, notification, Select } from 'antd'

//apis
import { updateExportOrders } from 'apis/export-order'
import { filterOptionSelect } from 'utils'

export default function ModalUpdateShipping({
  children,
  reload,
  record = {},
  type = '',
  shippings = [],
}) {
  const dispatch = useDispatch()

  const [shippingCompanyId, setShippingCompanyId] = useState('')
  const [shippingCode, setShippingCode] = useState('')
  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)

  const _updateOrder = async () => {
    try {
      const shipping = shippings.find((e) => e.shipping_company_id === shippingCompanyId)

      let shipping_company_info = {
        shipping_company_id: shippingCompanyId || '',
        shipping_company_address: shipping ? shipping.address : '',
        shipping_company_code: shipping ? shipping.code : '',
        shipping_company_district: shipping ? shipping.district : '',
        shipping_company_email: shipping ? shipping.email : '',
        shipping_company_name: shipping ? shipping.name : '',
        shipping_company_phone: shipping ? shipping.phone : '',
        shipping_company_province: shipping ? shipping.province : '',
        shipping_company_ward: shipping ? shipping.ward : '',
      }

      let shipping_info = {}

      if (type === 'shipping_code')
        shipping_info = { ...record.shipping_info, shipping_code: shippingCode }
      else shipping_info = { ...record.shipping_info, ...shipping_company_info }

      let body = { shipping_info }

      dispatch({ type: 'LOADING', data: true })
      const res = await updateExportOrders(record.order_id, body)
      dispatch({ type: 'LOADING', data: false })
      if (res.status === 200) {
        if (res.data.success) {
          if (reload) reload()
          toggle()
          notification.success({
            message: `Cập nhật ${
              type === 'shipping_code' ? 'mã vận đơn' : 'đơn vị vận chuyển'
            } thành công`,
          })
        } else
          notification.error({
            message:
              res.data.message ||
              `Cập nhật ${
                type === 'shipping_code' ? 'mã vận đơn' : 'đơn vị vận chuyển'
              } thất bại, vui lòng thử lại`,
          })
      } else
        notification.error({
          message:
            res.data.message ||
            `Cập nhật ${
              type === 'shipping_code' ? 'mã vận đơn' : 'đơn vị vận chuyển'
            } thất bại, vui lòng thử lại`,
        })
    } catch (error) {
      dispatch({ type: 'LOADING', data: false })
      console.log(error)
    }
  }

  useEffect(() => {
    if (!visible) {
      setShippingCompanyId(record.shipping_info ? record.shipping_info.shipping_company_id : '')
      setShippingCode(record.shipping_info ? record.shipping_info.shipping_code : '')
    }
  }, [visible])

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}
    >
      <div onClick={toggle}>{children}</div>
      <Modal
        title={
          <div>
            Cập nhật {type === 'shipping_code' ? 'mã vận đơn' : 'đơn vị vận chuyển'} #
            <b>{record.code}</b>
          </div>
        }
        visible={visible}
        width={600}
        onCancel={toggle}
        okText="Lưu"
        cancelText="Hủy"
        onOk={_updateOrder}
      >
        <div>
          {type === 'shipping_code' ? (
            <div>
              <b>Mã vận đơn</b>
              <Input
                value={shippingCode}
                onChange={(e) => setShippingCode(e.target.value)}
                placeholder="Nhập mã vận đơn"
                allowClear
                size="large"
              />
            </div>
          ) : (
            <div>
              <b>Đơn vị vận chuyển</b>
              <Select
                value={shippingCompanyId}
                onChange={setShippingCompanyId}
                filterOption={filterOptionSelect}
                showSearch
                size="large"
                allowClear
                placeholder="Chọn đơn vị vận chuyển"
                style={{ width: '100%' }}
              >
                {shippings.map((shipping) => (
                  <Select.Option
                    value={shipping.shipping_company_id}
                    key={shipping.shipping_company_id}
                  >
                    {shipping.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          )}
        </div>
      </Modal>
    </div>
  )
}
