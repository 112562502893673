import React, { useEffect, useState } from 'react'
import { PAGE_SIZE_OPTIONS, POSITION_TABLE, ROUTES, SIZE_TABLE } from 'consts'
import { formatCash, _addLog, getFileName } from 'utils'
import moment from 'moment'
import { ArrowLeftOutlined, VerticalAlignTopOutlined } from '@ant-design/icons'
import { saveAs } from 'file-saver'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

//antd
import { Button, Row, Space, Table } from 'antd'

//components
import columns from 'views/report-inventory-detail/columns'
import FilterDate from 'components/filter-date'

//apis
import { getReportInventoryDetail, exportReportInventoryDetail } from 'apis/report'

export default function ReportInventoryDetail({ record, filters }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [inventoryList, setInventoryList] = useState([])
  const [inventoryCount, setInventoryCount] = useState(0)
  const [paramsFilter, setParamsFilter] = useState({ page: 1, page_size: 20 })

  const _getReportInventoryDetailExport = async (getAll = false) => {
    try {
      const query = { ...paramsFilter }
      if (getAll) {
        const keysQueryTime = [
          'today',
          'yesterday',
          'this_week',
          'last_week',
          'last_month',
          'this_month',
          'this_year',
          'last_year',
          'from_date',
          'to_date',
        ]
        Object.keys(query).map((key) => !keysQueryTime.includes(key) && delete query[key])
      }

      delete query.page
      delete query.page_size
      dispatch({ type: 'LOADING', data: true })
      const res = await exportReportInventoryDetail({ ...query, product_id: record.product_id })
      dispatch({ type: 'LOADING', data: false })
      if (res.status === 200) {
        const link = res.data.data
        if (link) saveAs(link, getFileName(link))
      }
    } catch (error) {
      console.log(error)
      _addLog(JSON.stringify(error))
      dispatch({ type: 'LOADING', data: false })
    }
  }

  const _getReportInventoryDetail = async () => {
    try {
      setLoading(true)
      setInventoryList([])
      const query = { ...paramsFilter, product_id: record.product_id }
      const res = await getReportInventoryDetail(query)
      if (res.status === 200) {
        setInventoryCount(res.data.count)
        setInventoryList(res.data.data)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
      _addLog(JSON.stringify(error))
    }
  }

  useEffect(() => {
    setParamsFilter(filters ? filters : { page: 1, page_size: 20 })
  }, [filters])

  useEffect(() => {
    if (record) _getReportInventoryDetail()
  }, [record, paramsFilter])

  return (
    <div>
      <Row justify="space-between" align="middle">
        <div>
          {record && (
            <div style={{ fontSize: 16 }}>
              Báo cáo xuất/nhập tồn chi tiết{' '}
              <b>#{record.product_info && record.product_info.barcode}</b>
            </div>
          )}
        </div>
        <Space>
          <Button
            onClick={() => _getReportInventoryDetailExport(true)}
            icon={<VerticalAlignTopOutlined />}
            type="primary"
            className="btn-export"
          >
            Xuất báo cáo chi tiết toàn bộ
          </Button>
          <div style={{ width: 300 }}>
            <FilterDate
              allowClear={false}
              paramsFilter={paramsFilter}
              setParamsFilter={setParamsFilter}
            />
          </div>
        </Space>
      </Row>

      <Table
        style={{ width: '100%', marginTop: 3 }}
        scroll={{ y: '40vh' }}
        loading={loading}
        size={SIZE_TABLE}
        dataSource={inventoryList}
        columns={columns.map((column) => {
          if (column.key === 'stt')
            return {
              ...column,
              render: (text, record, index) => index + 1,
            }
          if (column.key === 'customer')
            return {
              ...column,
              render: (text, record) => {
                const order_info = record.import_order_info || record.export_order_info
                return order_info && order_info.customer_info && order_info.customer_info.fullname
              },
            }
          if (column.key === 'type')
            return {
              ...column,
              render: (text, record) =>
                (record.import_order_info && 'Phiếu nhập') ||
                (record.export_order_info && 'Phiếu xuất') ||
                '',
            }

          if (column.key === 'address')
            return {
              ...column,
              render: (text, record) => {
                const order_info = record.import_order_info || record.export_order_info
                const customer = order_info && order_info.customer_info
                return customer
                  ? `${customer.address}, ${customer.ward}, ${customer.district}, ${customer.province}`
                  : ''
              },
            }

          if (column.key === 'date')
            return {
              ...column,
              render: (text, record) =>
                record.import_date && moment(record.import_date).format('DD/MM/YYYY'),
            }

          if (column.key === 'date-ct')
            return {
              ...column,
              render: (text, record) =>
                record.create_date && moment(record.create_date).format('DD/MM/YYYY'),
            }

          if (column.key === 'code')
            return {
              ...column,
              render: (text, record) => {
                const order_info = record.import_order_info || record.export_order_info
                const order_type = record.import_order_info ? 'import' : 'export'
                return (
                  order_info && (
                    <Link
                      target="_blank"
                      to={`${
                        order_type === 'import'
                          ? ROUTES.ORDER_DETAIL_IMPORT
                          : ROUTES.ORDER_DETAIL_EXPORT
                      }?order_id=${order_info.order_id}&status=${
                        order_type === 'import' ? 'is_imported' : 'is_packed'
                      }&type=${order_type}`}
                    >
                      {order_info.customer_order_code}
                    </Link>
                  )
                )
              },
            }

          if (column.key === 'code-ct')
            return {
              ...column,
              render: (text, record) => {
                const order_info = record.import_order_info || record.export_order_info
                return order_info && order_info.code
              },
            }

          if (column.key === 'import-quantity')
            return {
              ...column,
              render: (text, record) => formatCash(record.import_quantity || 0),
            }

          if (column.key === 'export-quantity')
            return {
              ...column,
              render: (text, record) => formatCash(record.export_quantity || 0),
            }

          if (column.key === 'inventory-quantity')
            return {
              ...column,
              render: (text, record) => formatCash(record.inventory_quantity || 0),
            }

          if (column.key === 'note')
            return {
              ...column,
              render: (text, record) => {
                const order_info = record.import_order_info || record.export_order_info
                return order_info && order_info.note
              },
            }

          return column
        })}
        pagination={{
          position: POSITION_TABLE,
          current: paramsFilter.page,
          pageSize: paramsFilter.page_size,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          total: inventoryCount,
          showQuickJumper: true,
          onChange: (page, pageSize) => {
            setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize })
          },
        }}
        summary={() => (
          <Table.Summary fixed="bottom">
            <Table.Summary.Row></Table.Summary.Row>
          </Table.Summary>
        )}
        sticky
      />
      <Row justify="end">
        <Button onClick={() => history.goBack()} icon={<ArrowLeftOutlined />}>
          Quay về
        </Button>
      </Row>
    </div>
  )
}
